import React, { useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import { setIsDrawer, setFilterView, getReviews, getLastViewed } from '../../redux/catalogSlicer'
import { DrawerComponent } from '@components/DrawerComponent'
import { CatalogMain } from '../CatalogMain'
import { CatalogContent } from '../CatalogContent'
import { CatalogProduct } from './components/CatalogProduct'
import { CatalogDescription } from './components/CatalogDescription'
import { CatalogCharacteristic } from './components/CatalogCharacteristic'
import { CatalogCard } from './components/CatalogCard'
import { CatalogList } from '../CatalogList'
// import { CatalogMenuMobile } from './components/CatalogMenuMobile'
import { CatalogFeedbackModal } from './components/CatalogFeedback/components/CatalogFeedbackModal'
import Styles from './style.module.scss'
import { useParams } from 'react-router-dom'
import { Loader } from '../../../../pages/doctor/components/UI/Loader'
import { CatalogFeedback } from './components/CatalogFeedback'
import { getProductById } from '../../redux/productSlice'
import sctollToTop from '@helpers/scrollToTop'
import { CatalogTogether } from './components/CatalogTogether'

export const CatalogPageNutraceutical: React.FC = () => {
  const component = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const { reviews, isDrawer, listLastViewed, products } = useAppSelector((state) => state.catalog)
  const product = useAppSelector((state) => state.product.product)
  const reviewsFilter = useAppSelector((state) => state.catalog.reviewsFilter)

  const sameBrandsArray =
    products && products.results.filter((item) => item.brand === product?.brand && item.id !== product?.id)
  const similarProductsArray =
    products && products.results.filter((item) => item.name.includes(product?.name && item.id !== product?.id))
  useEffect(() => {
    product && sctollToTop(component, 0)
  }, [product?.id])

  type Params = {
    id: string
  }

  const { id } = useParams<Params>()

  useEffect(() => {
    dispatch(getProductById(id))
    dispatch(getLastViewed())
    // dispatch(setUpdateCount(false))
  }, [])

  useEffect(() => {
    dispatch(getReviews({ id, ...reviewsFilter }))
  }, [reviewsFilter])

  const closeModal = () => {
    dispatch(setIsDrawer(false))
    dispatch(setFilterView('all'))
  }

  return product ? (
    <CatalogMain>
      <div className={Styles.page} ref={component}>
        <div className={Styles.column}>
          <div className={Styles.container}>
            <CatalogProduct product={product} />
            {product.set_info?.in_stock && (
              <CatalogContent title='Вместе дешевле'>
                <CatalogTogether product={product} />
              </CatalogContent>
            )}
            {product.description && (
              <CatalogContent title='Описание'>
                <CatalogDescription />
              </CatalogContent>
            )}
            <CatalogContent title='Характеристики'>
              <CatalogCharacteristic />
            </CatalogContent>
          </div>
          {width > 1310 && (
            <div className={Styles.container}>
              <CatalogCard product={product} />
            </div>
          )}
        </div>
        {similarProductsArray.length !== 0 && (
          <CatalogContent title='Похожие товары'>
            <CatalogList items={similarProductsArray} />
          </CatalogContent>
        )}
        {reviews.results && (
          <CatalogContent title='Отзывы о товаре' count={reviews.length} id='feedback'>
            <CatalogFeedback reviews={reviews} product={product} />
          </CatalogContent>
        )}
        {listLastViewed.length > 0 && (
          <CatalogContent title='Вы недавно смотрели'>
            <CatalogList items={listLastViewed} />
          </CatalogContent>
        )}
        {sameBrandsArray.length > 0 && (
          <CatalogContent title='Товары этого же бренда'>
            <CatalogList items={sameBrandsArray} />
          </CatalogContent>
        )}
      </div>
      {/* {width <= 803 && <CatalogMenuMobile />} */}
      {isDrawer && (
        <DrawerComponent open={isDrawer} closeModal={closeModal}>
          <CatalogFeedbackModal product={product} />
        </DrawerComponent>
      )}
    </CatalogMain>
  ) : (
    <Loader />
  )
}
