import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { formateDate } from '../../../../utils/formateDate'
import Styles from './style.module.scss'

export const ProfileCartOrder = ({ order, closeModal }) => {
  const [images, setImages] = useState([])

  const renderTitle = () => {
    if (order.products_in_order.length !== 0 && order.analyzes_in_order.length !== 0) {
      return `${order.products_in_order.length} товаров, ${order.analyzes_in_order.length} обследования`
    } else if (order.products_in_order.length !== 0) {
      return `${order.products_in_order.length} товаров`
    } else if (order.analyzes_in_order.length !== 0) {
      return `${order.analyzes_in_order.length} обследования`
    } else {
      return null
    }
  }

  useEffect(() => {
    if (order.products_in_order.length !== 0) {
      const array = []

      order.products_in_order.forEach((order) => {
        if (order.image) {
          array.push(order.image)
        }
      })

      setImages(array)
    }
  }, [order])

  return (
    <Link className={Styles.order} to={`/order/${order.id}`} onClick={closeModal}>
      <div className={Styles.block}>
        <div className={Styles.row}>
          <span>{renderTitle()}</span>
        </div>
        <span className={Styles.date}>от {formateDate(order.created_at, 'D MMMM')}</span>
      </div>
      <div className={Styles.images}>
        {images.length !== 0 &&
          images.slice(0, 4).map((image, index) => (
            <div key={index} className={Styles.image}>
              <img src={`${image}`} alt='Фото' />
              {index === 3 && <span>+{images.length - images.slice(0, 4).length}</span>}
            </div>
          ))}
      </div>
      {order.delivery.status && <span className={Styles.label}>{order.delivery.status}</span>}
    </Link>
  )
}
