import React from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { DoctorCanceledDays } from '../../../PersonalInfo/components/DoctorCanceledDays'
import { DoctorPopupMobile } from '../../../../../DoctorModal/components/DoctorPopupMobile'
import { Confirm } from '../Confirm'

interface CanceledVisitProps {
  reopenCancel: any
  displayConfirm: boolean
  closePopup: any
  selectedCancel: any
  reopenConfirm: any
}

export const CanceledVisit: React.FC<CanceledVisitProps> = (props) => {
  const { reopenCancel, displayConfirm, closePopup, selectedCancel, reopenConfirm } = props

  const blackList = useAppSelector((state) => state.doctor.blackList)

  return (
    <div className={Styles.canceled}>
      <ul className={Styles.dates}>
        {blackList?.map((cancel) => (
          <DoctorCanceledDays key={cancel.id} cancel={cancel} reopenCancel={reopenCancel} />
        ))}
      </ul>
      {displayConfirm && (
        <DoctorPopupMobile closePopup={closePopup}>
          <Confirm selectedCancel={selectedCancel} reopenConfirm={reopenConfirm} closePopup={closePopup} />
        </DoctorPopupMobile>
      )}
    </div>
  )
}
