import React, { useEffect, useRef } from 'react'
import Styles from './style.module.scss'
import { CardContainer } from '@components/ui/CardContainer'
import clock from '@icons/clock.svg'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getConsultationsList, getDataById } from '../../appointment-page/redux/consultationsListSlicer'
import { formatPrice } from '@helpers/formatPrice'
import { Spinner } from '@components/Spinner'
import { TransitionsModal } from '@components/Modal'
import { ModalAppointment } from '../ModalAppointment'
import useWindowSize from '@helpers/useWindowSizeHook'
import { DrawerAppointment } from '../DraverAppoimtment'
import { DrawerPanel } from '@components/Drawer'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { setData, setSavingUserInput } from '../../../pages/appointment-page/redux/consultationSlicer'

SwiperCore.use([Pagination, Navigation])

export const AppointmentLending = ({ setShowAppointment, showAppointment }) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getConsultationsList())
  }, [])
  const data = useAppSelector((state) => state.consultationsList.data)
  const status = useAppSelector((state) => state.consultationsList.status)
  const tooltipText = 'Точная стоимость появится после выбора типа приема и консультации'

  const { width } = useWindowSize()
  const desktop = width >= 829
  const swiperBreakPoints: any = {
    320: {
      slidesPerView: 1,
      slidesPerColumn: 2,
      slidesPerColumnFill: 'row',
    },
    500: {
      slidesPerView: 1,
      slidesPerColumn: 2,
      slidesPerColumnFill: 'row',
    },
    768: {
      slidesPerView: 2,
      slidesPerColumn: 2,
      slidesPerColumnFill: 'row',
    },
    1024: {
      slidesPerView: 2,
      slidesPerColumn: 2,
      slidesPerColumnFill: 'row',
    },
    1440: {
      slidesPerColumn: 2,
      slidesPerColumnFill: 'row',
      slidesPerView: 3,
    },
    1920: {
      slidesPerView: 3,
      slidesPerColumn: 2,
      slidesPerColumnFill: 'row',
    },
  }

  const component = useRef<HTMLDivElement>(null)
  const activeConsultation = useAppSelector((state) => state.consultationPage.data.savingUserInput)
  const saveUserDataInput = (id) => {
    if (!(id in activeConsultation)) {
      dispatch(setSavingUserInput({ key: id, value: {} }))
    }
  }
  return (
    <div ref={component}>
      <Swiper
        pagination={{ clickable: true }}
        spaceBetween={20}
        breakpoints={swiperBreakPoints}
        style={{ paddingBottom: 45 }}
      >
        <div className={`${Styles.reference__container} ${Styles.reference__mobile}`}>
          {status === 'success' &&
            data?.map((item) => (
              <SwiperSlide key={item.id}>
                <CardContainer
                  onClick={() => {
                    dispatch(getDataById(item.id))
                    setShowAppointment(true)
                    saveUserDataInput(item.id)
                    dispatch(setData({ key: 'title', value: item.title }))
                  }}
                  className={Styles.card__container}
                  title={item.title}
                  src={item.image}
                  key={item.id}
                  price={formatPrice(item.types[0].price)}
                  startOf
                  lending
                  tooptipText={tooltipText}
                  content={
                    <>
                      <div className={Styles.wrapper}>
                        {item.types[0].duration && (
                          <div className={Styles.duration__container}>
                            <img src={clock} className={Styles.icon} alt='clock' />
                            <div className={Styles.duration}>{item.types[0].duration}</div>
                          </div>
                        )}

                        {item.mark && <div className={Styles.mark}>{item.mark}</div>}
                      </div>

                      <p className={Styles.text}>{item.description}</p>
                    </>
                  }
                />
              </SwiperSlide>
            ))}
          {status === 'error' && <div>Произошла ошибка при загрузке...</div>}
          {status === 'loading' && <Spinner />}
        </div>
      </Swiper>
      {desktop ? (
        <TransitionsModal
          isOpenModal={showAppointment}
          setModalOpened={() => setShowAppointment((isOpen) => !isOpen)}
          onClose={() => setShowAppointment((isOpen) => !isOpen)}
        >
          <ModalAppointment />
        </TransitionsModal>
      ) : (
        <DrawerPanel
          width='100%'
          anchor='left'
          open={showAppointment}
          onClose={() => setShowAppointment((isOpen) => !isOpen)}
        >
          <DrawerAppointment setShowAppountment={setShowAppointment} />
        </DrawerPanel>
      )}
    </div>
  )
}
