/* eslint-disable camelcase */
import React, { FC } from 'react'
import styles from './style.module.scss'

import { ContainerWithTag } from '@components/Containers/ContainerWithTag'
import { ComplexTestItems } from './components/ComplexTestItems'
import { OutlinedContainer } from '@components/Containers/OutlinedContainer'
import { BadgeWithLogo } from '@components/ui/BadgeWithLogo'

import { ordersTypes } from '../../../../redux/orderTypes'
import { formatPriceWithRuble } from '../../../../utils/formatPriceWithRuble'

import calendarIcon from '@icons/calendarBlank.svg'
import { Swiper, SwiperSlide } from 'swiper/react'

interface OrderTestItemDetailsProps {
  item: ordersTypes.IAnalysisInOrder
  complex?: boolean
}

export const OrderTestItemDetails: FC<OrderTestItemDetailsProps> = ({ item, complex }) => {
  const { discount_price, execution_time, price, title } = item

  return complex ? (
    <div className={styles.orderTestItemDetails}>
      <ContainerWithTag tagName='Комплекс анализов' customStyle={styles.containerWithBadge}>
        <div className={styles.titleBlock}>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.price}>
            <span className={styles.basePrice}>{formatPriceWithRuble(price)}</span>
            <span className={styles.finalPrice}>{formatPriceWithRuble(discount_price)}</span>
          </p>
        </div>

        <ComplexTestItems contents={item.contents} />

        <Swiper className={styles.infoBlock} slidesPerView={2.5} spaceBetween={10}>
          <SwiperSlide className={styles.slide}>
            <div className={styles.performancePeriodBlock}>
              <img src={calendarIcon} alt='Календарь' className={styles.calendarIcon} />
              <span className={styles.performancePeriod}>Срок исполнения {execution_time} день</span>
            </div>
          </SwiperSlide>
          {item.labs &&
            item.labs.length !== 0 &&
            item.labs.map((lab: string, index: number) => (
              <SwiperSlide className={styles.slide} key={index}>
                <div className={styles.image}>
                  <img src={lab} alt='Иконка' />
                </div>
              </SwiperSlide>
            ))}
        </Swiper>

        <p className={styles.priceMobileView}>
          <span className={styles.basePrice}>{formatPriceWithRuble(price)}</span>
          <span className={styles.finalPrice}>{formatPriceWithRuble(discount_price)}</span>
        </p>
      </ContainerWithTag>
    </div>
  ) : (
    <div className={styles.orderTestItemDetails}>
      <OutlinedContainer customStyle={styles.outlinedContainer}>
        <div className={styles.titleBlock}>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.price}>
            <span className={styles.basePrice}>{formatPriceWithRuble(price)}</span>
            <span className={styles.finalPrice}>{formatPriceWithRuble(discount_price)}</span>
          </p>
        </div>

        <div className={styles.infoBlock}>
          <div className={styles.performancePeriodBlock}>
            <img src={calendarIcon} alt='Иконка календарь' className={styles.calendarIcon} />
            <span className={styles.performancePeriod}>Срок исполнения {execution_time} дн.</span>
          </div>

          <div className={styles.logos}>
            {item.labs &&
              item.labs.length !== 0 &&
              item.labs.map((lab: string, index: number) => (
                <BadgeWithLogo image={`${lab}`} customStyle={styles.badgeWithLogo} key={index} />
              ))}
          </div>
        </div>

        <p className={styles.priceMobileView}>
          <span className={styles.basePrice}>{formatPriceWithRuble(price)}</span>
          <span className={styles.finalPrice}>{formatPriceWithRuble(discount_price)}</span>
        </p>
      </OutlinedContainer>
    </div>
  )
}
