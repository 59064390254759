import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import { Field, useField } from 'react-final-form'
import breakfast from '@icons/reports-breakfast.svg'
import lunch from '@icons/reports-lunch.svg'
import dinner from '@icons/reports-dinner.svg'
import products from '@icons/reports-products.svg'

export const DietItem = ({ name }) => {
  const required = (value) => (value ? undefined : 'Это обязательное поле')
  const titleInput = useField(`${name}.title`)
  const titleValue = titleInput.input.value
  const [srcValue, setSrcValue] = useState(null)
  const srcSetValue = () => {
    switch (titleValue) {
      case 'Завтрак':
        setSrcValue(breakfast)
        break
      case 'Обед':
        setSrcValue(lunch)
        break
      case 'Ужин':
        setSrcValue(dinner)
        break
      case 'Продукты':
        setSrcValue(products)
    }
  }
  const setTitle = (title) => {
    switch (title) {
      case 'Завтрак':
        return 'Пример завтрака'
      case 'Обед':
        return 'Пример обеда'
      case 'Ужин':
        return 'Пример ужина'
      case 'Продукты':
        return 'Рекомендуемые продукты'
    }
  }
  useEffect(() => {
    srcSetValue()
  }, [])
  return (
    <>
      <Field name={`${name}.description`} validate={required}>
        {({ input, meta }) => (
          <div className={Styles.label__container}>
            <Field name={`${name}.title`}>
              {({ input }) => (
                <div className={Styles.label__wrap}>
                  <img src={srcValue} alt={`${titleValue}`} />
                  <label className={Styles.label}>{setTitle(input.value)}*</label>
                </div>
              )}
            </Field>

            <textarea
              className={Styles.textarea}
              {...input}
              placeholder={`Введите рекомендации для ${
                titleValue === 'Продукты' ? 'продуктов' : `${titleValue.toLowerCase()}а`
              }`}
            />
            {meta.error && <span className={Styles.error}>{meta.error}</span>}
          </div>
        )}
      </Field>
      {titleValue === 'Продукты' && (
        <Field name={`${name}.products_exclusions`} validate={required}>
          {({ input, meta }) => (
            <div className={Styles.label__container}>
              <label className={Styles.labelRemark}>Продукты исключения*</label>
              <textarea {...input} placeholder='Введите продукты исключения' className={Styles.textareaRemark} />
              {meta.error && <span className={Styles.error}>{meta.error}</span>}
            </div>
          )}
        </Field>
      )}
      <Field name={`${name}.remark`}>
        {({ input }) => (
          <div className={Styles.label__container}>
            <label className={Styles.labelRemark}>Примечание (необязательно)</label>
            <textarea {...input} className={Styles.textareaRemark} placeholder='Введите примечание к рекомендации' />
          </div>
        )}
      </Field>
    </>
  )
}
