import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import { BorderLine } from '@components/ui/BorderLine'
import { DoctorSurveysDrawerById } from '../DoctorSurveysDrawerById'
import { getSurveysById } from '../../../../pages/authorization/redux/authSlice'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import { LinkToCatalog } from '@components/Conclusion/ui/LinkToCatalog'
import { getMyCart, postCart, setDataSmallCatalogAnalisys } from '../../../../pages/cart/redux/cartSlicer'
import { getAnalyzesById } from '../../../../pages/catalog/redux/catalogSlicer'
import declination from '@helpers/declination'
import { AnalysisDrawer } from '../AnalisysDrawer'
import useWindowSize from '@helpers/useWindowSizeHook'
import { clearPlannedDataAnalysisToBy } from '../../../../pages/my-appointment/redux/appointmentByIdSlicer'

export const DoctorSurveysDrawer = (props) => {
  const dispatch = useAppDispatch()
  const dataAnalysis = useAppSelector((state) => state.cart.dataSmallCatalogAnalisys)
  const plannedDataAnalysisToBy = useAppSelector((state) => state.myAppointmentById.plannedDataAnalysisToBy)
  const [inCart, setInCart] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [detailItem, setDetailItem] = useState(null)
  const [showDrawerAnalysis, setShowDrawerAnalysis] = useState(false)
  const [totalPriceAnalysis, setTotalPriceAnalysis] = useState(0)
  const [totalDiscountPriceAnalysis, setDiscountTotalPriceAnalysis] = useState(0)
  const [totalAnalysis, setTotalAnalysis] = useState({ price: 0, discountPrise: 0 })
  const { width } = useWindowSize()
  const desktop = width >= 829
  const countTotal = (items) => items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0)
  const countTotalDiscount = (items) => items.reduce((acc, curr) => acc + curr.quantity * curr.discount_price, 0)
  const openDetails = (e) => {
    dispatch(getSurveysById(e)).then((res) => {
      setShowDetail(true)
      setDetailItem(res.payload.data)
    })
  }
  const getAnalysis = () => {
    props.data.map((item) => {
      if (item.analysis_id) {
        dispatch(getAnalyzesById(item.analysis_id)).then((res) => dispatch(setDataSmallCatalogAnalisys(res.payload)))
      }
    })
  }
  useEffect(() => {
    if (dataAnalysis.length === 0) {
      getAnalysis()
    }
  }, [])
  useEffect(() => {
    if (plannedDataAnalysisToBy.length > 0) {
      setTotalPriceAnalysis(countTotal(plannedDataAnalysisToBy))
      setDiscountTotalPriceAnalysis(countTotalDiscount(plannedDataAnalysisToBy))
    }
  })
  const addProduct = () => {
    if (plannedDataAnalysisToBy.length > 0) {
      plannedDataAnalysisToBy.map((item) => {
        if (item.checked) {
          dispatch(
            postCart({
              analysis: item.id,
            }),
          ).then(() => setInCart(true))
        }
      })
      dispatch(getMyCart())
      dispatch(clearPlannedDataAnalysisToBy())
      if (desktop) {
        setShowDrawerAnalysis(false)
      }
    }
  }
  useEffect(() => {
    if (dataAnalysis.length > 0) {
      setTotalAnalysis({
        price: dataAnalysis.reduce((acc, curr) => acc + curr.price, 0),
        discountPrise: dataAnalysis.reduce((acc, curr) => acc + curr.discount_price, 0),
      })
    }
  }, [dataAnalysis])

  return (
    <>
      <div className={Styles.mealtime__container}>
        {props.data.map((item) => (
          <div key={item.id} className={Styles.container}>
            <h5 className={Styles.title}>{item?.name}</h5>
            <div className={Styles.disc}>{item.small_description}</div>
            <ButtonTransparent onClick={() => openDetails(item.research_id)} value='Читать подробнее' />
            <BorderLine />
          </div>
        ))}
        {dataAnalysis && dataAnalysis.length > 0 && (
          <LinkToCatalog
            price={totalAnalysis.price}
            discountPrice={totalAnalysis.discountPrise}
            quantity={declination(dataAnalysis.length, 'обследование', 'обследования', 'обследований')}
            onClick={() => {
              setShowDrawerAnalysis(true)
            }}
            context=''
            fixed
          />
        )}
      </div>
      <AnalysisDrawer
        setShowDrawerCatalog={setShowDrawerAnalysis}
        showDrawerCatalog={showDrawerAnalysis}
        // activeCatalog='analysis'
        dataAnalysis={dataAnalysis}
        addProduct={addProduct}
        totalPrice={totalPriceAnalysis}
        totalDiscountPrice={totalDiscountPriceAnalysis}
        plannedDataAnalysisToBy={plannedDataAnalysisToBy}
        inCart={inCart}
      />
      {detailItem && (
        <DoctorSurveysDrawerById showDetail={showDetail} setShowDetail={setShowDetail} detailItem={detailItem} />
      )}
    </>
  )
}
