/* eslint-disable camelcase */
import React from 'react'
import Styles from './style.module.scss'
import { useAppSelector } from '@app/redux/hooks'

export const CatalogCharacteristic: React.FC = () => {
  const selectedModification = useAppSelector((state) => state.product.selectedModification)
  const property_groups = selectedModification?.property_groups

  return property_groups ? (
    <div className={Styles.characteristic}>
      <>
        {property_groups.map((property) => {
          return (
            <div className={Styles.block} key={property.index + property.title}>
              <span className={Styles.title}>{property.title}</span>
              {property.subtitle && <p className={Styles.text}>{property.subtitle}</p>}
              <ul className={Styles.list}>
                {property.property_values.map((item) => {
                  return (
                    <li key={item.index + item.value} className={Styles.item}>
                      {item.property === 'Пусто' ? (
                        <>
                          <div className={Styles.row}>{}</div>
                          <div className={Styles.wrap}>
                            <span className={`${Styles.grammar} ${Styles.grammar_mod}`}>{item.value}</span>
                            <span className={`${Styles.grammar} ${Styles.grammar_mod}`}>{item.extra_value}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={Styles.row}>
                            <span className={Styles.name}>{item.property}</span>
                            <span className={Styles.border} />
                          </div>
                          <div className={Styles.wrap}>
                            {item.extra_value ? (
                              <>
                                <span className={`${Styles.grammar} ${Styles.grammar_length}`}>{item.value}</span>
                                <span className={`${Styles.grammar} ${Styles.grammar_length}`}>{item.extra_value}</span>
                              </>
                            ) : (
                              <span className={Styles.grammar}>{item.value}</span>
                            )}
                          </div>
                        </>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </>
    </div>
  ) : (
    <p>No properties</p>
  )
}
