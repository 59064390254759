import React from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'

import backIcon from '@icons/back.svg'

interface ContainerProps {
  title: string
  subtitle?: string
  count?: number
  back?: string
}

export const Container: React.FC<ContainerProps> = ({ title, subtitle, count, back, children }) => {
  return (
    <div className={Styles.container}>
      {back && (
        <Link className={Styles.link} to={back}>
          <img src={backIcon} alt='Иконка' />
          <span>{back}</span>
        </Link>
      )}
      {count && title ? (
        <>
          <div className={Styles.caption}>
            <span className={Styles.title}>{title}</span>
            <strong className={Styles.count}>{count}</strong>
          </div>
          {subtitle && <p className={Styles.subtitle}>{subtitle}</p>}
        </>
      ) : (
        <>
          <div className={Styles.caption}>
            <span className={Styles.title}>{title}</span>
          </div>
          {subtitle && <p className={Styles.subtitle}>{subtitle}</p>}
        </>
      )}
      {children}
    </div>
  )
}
