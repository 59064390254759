import React from 'react'
import Styles from './style.module.scss'

interface CatalogContentProps {
  title: string
  count?: number
  id?: string
}

export const CatalogContent: React.FC<CatalogContentProps> = (props) => {
  const { title, count, children, id } = props

  return (
    <div className={Styles.content} id={id || ''}>
      <div className={Styles.wrapper}>
        <h4 className={Styles.title}>{title}</h4>
        {count && <span className={Styles.count}>{count}</span>}
      </div>
      {children}
    </div>
  )
}
