import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'

import { DietItem } from './DietItem'
import { FormStorage } from '../../../utils/form-storage'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import arrayMutators from 'final-form-arrays'
import { getMedicalReportById, patchDietReport, postDietReport } from '../redux/reportsSlice'
import { FieldArray } from 'react-final-form-arrays'
import { arrayUniqueBy } from '@helpers/arrayUniqueBy'

export const DietReport = ({ setActiveContent }) => {
  const dispatch = useAppDispatch()
  const medicalReportId = useAppSelector((state) => state.reports.medicalReportId)
  const dietList = [
    {
      title: 'Завтрак',
      description: '',
      products_exclusions: '',
      remark: '',
      medical_report: medicalReportId,
      id: null,
    },
    {
      title: 'Обед',
      description: '',
      products_exclusions: '',
      remark: '',
      medical_report: medicalReportId,
      id: null,
    },
    {
      title: 'Ужин',
      description: '',
      products_exclusions: '',
      remark: '',
      medical_report: medicalReportId,
      id: null,
    },
    {
      title: 'Продукты',
      description: '',
      products_exclusions: '',
      remark: '',
      medical_report: medicalReportId,
      id: null,
    },
  ]
  const [triggerByPatch, getTriggerByPatch] = useState(null)
  const [initialState, setInitialState] = useState([...dietList])
  const onSubmit = async (values) => {
    if (triggerByPatch && triggerByPatch.length > 0) {
      dispatch(patchDietReport(values.diet)).then((res) => {
        if (res.payload.status < 400) {
          setActiveContent('lifeStyle')
        }
      })
    } else {
      const newArr = [...values.diet].map((item) => {
        delete item.id
        return item
      })
      dispatch(postDietReport(newArr)).then((res) => {
        if (res.payload.status < 400) {
          setActiveContent('lifeStyle')
        }
      })
    }
  }

  useEffect(() => {
    if (medicalReportId) {
      dispatch(getMedicalReportById(medicalReportId)).then((res) => {
        getTriggerByPatch(res.payload.data.diet_reports)
        setInitialState(arrayUniqueBy(res.payload.data.diet_reports.concat(initialState), (item) => item.title))
      })
    }
  }, [medicalReportId])

  const initial = {
    diet: initialState,
  }

  return (
    <div className={Styles.fieldset}>
      <FormStorage
        formId='diet'
        onSubmit={onSubmit}
        initialValues={initial}
        mutators={{
          ...arrayMutators,
        }}
      >
        <div className={Styles.form}>
          <FieldArray name='diet'>
            {({ fields }) =>
              fields.map((name, index) => (
                <>
                  <div key={index}>
                    <DietItem name={`${name}`} />
                  </div>
                </>
              ))
            }
          </FieldArray>
        </div>
      </FormStorage>
    </div>
  )
}
