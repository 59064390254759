import { useAppSelector } from '@app/redux/hooks'
import { Loader } from '../../../../pages/doctor/components/UI/Loader'
import React from 'react'
import { BlogPagination } from '../BlogPagination'
import Styles from './style.module.scss'
import { Link } from 'react-router-dom'
import { turnProductPage } from '../../../../pages/catalog/redux/catalogSlicer'

export const BlogArticles = () => {
  const posts = useAppSelector((state) => state.blog.posts)
  const isLoading = useAppSelector((state) => state.blog.loading)
  const currentPostsQuantity = useAppSelector((state) => state.blog.currentPostsQuantity)
  const postsLeft = useAppSelector((state) => state.blog.postsLeft)
  const createdAt = (date) =>
    Intl.DateTimeFormat('ru-RU', {
      // weekday: 'long',
      // year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(new Date(date))

  return (
    <div className={Styles.container}>
      {posts.results?.length ? (
        <>
          <ul className={Styles.list}>
            {posts.results.map((post) => {
              return (
                <Link to={`blog/${post.slug}`} key={post.id} className={Styles.post}>
                  <div className={Styles.post__imageContainer}>
                    <img src={post.header_image} alt='Изображение' className={Styles.post__image} />
                  </div>
                  <h3 className={Styles.post__title}>{post.title}</h3>
                  <p className={Styles.post__short}>{post.short_title}</p>
                  <div className={Styles.post__footer}>
                    <span className={Styles.post__date}>{createdAt(post.created_at)}</span>
                    {post.tags.map((tag) => (
                      <div className={Styles.post__tag} key={tag}>
                        {tag}
                      </div>
                    ))}
                  </div>
                </Link>
              )
            })}
          </ul>
          <BlogPagination
            items={posts}
            currentQuantity={currentPostsQuantity}
            itemsLeft={postsLeft}
            turnPage={turnProductPage}
          />
        </>
      ) : isLoading ? (
        <Loader />
      ) : (
        'Статей, соответствующих вашему выбору не обнаружено, попробуйте изменить запрос'
      )}
    </div>
  )
}
