import React from 'react'
import Styles from './style.module.scss'
import { Link } from 'react-router-dom'
import arrow from '@icons/arrow-left-blue.svg'

interface ReturnButtonProps {
  buttonName: string
  path: string
}

export const ReturnButton: React.FC<ReturnButtonProps> = ({ buttonName, path }) => {
  return (
    <Link to={path} className={Styles.link}>
      <img className={Styles.link__arrow} src={arrow} alt='<' />
      {buttonName}
    </Link>
  )
}
