import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { Form, Field } from 'react-final-form'
import { ButtonColor } from '@components/ui/ButtonColor'
import {
  getAnalysisResult,
  getMedicalReportById,
  patchAnalysisResult,
  postAnalysisResultMeasure,
  patchAnalysisResultTitle,
  postAnalysisResult,
} from '../redux/reportsSlice'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { FieldCustom } from './Field'
import { arrayUniqueBy } from '@helpers/arrayUniqueBy'

export const SecondStepFieldSet = ({ setStatusReport }) => {
  const dispatch = useAppDispatch()
  const medicalReportId = useAppSelector((state) => state.reports.medicalReportId)
  const [initialState, setInitialState] = useState(null)

  useEffect(() => {
    dispatch(getAnalysisResult())
      .then((res) => {
        const a = [...res.payload.data.results].map((item) => {
          return Object.assign({}, item, {
            value: '',
            static: !item.title,
            update: false,
            analysis_results_title: item.id,
          })
        })
        setInitialState(a)
        return a
      })
      .then((a) => {
        dispatch(getMedicalReportById(medicalReportId)).then((res) => {
          if (res.payload.data.analyzes_reports && res.payload.data.analyzes_reports.length > 0) {
            const b = [...res.payload.data.analyzes_reports].map((item) => {
              return Object.assign({}, item, {
                title: item?.analysis_results_title[0]?.title,
                medical_report: medicalReportId,
                static: !item?.analysis_results_title[0]?.title,
                measure: item?.analysis_results_title[0]?.measure,
                analysis_results_title: item?.analysis_results_title[0]?.id,
                update: true,
                id: item.id,
              })
            })
            setInitialState(arrayUniqueBy(b.concat(a), (item) => item.analysis_results_title))
          } else {
            setInitialState(a)
          }
        })
      })
  }, [])

  const onSubmit = async (values) => {
    await createdReport(values)
  }
  const createdReport = (val) => {
    val.data.map((item) => {
      if (item.static && item.analysis_results_title) {
        dispatch(
          patchAnalysisResultTitle({
            id: item.analysis_results_title,
            value: {
              id: item.analysis_results_title,
              title: item.title,
            },
          }),
        ).then(() => {
          if (typeof item.measure === 'string' && item.measure.length > 0) {
            dispatch(
              postAnalysisResultMeasure({
                id: item.analysis_results_title,
                measure: item.measure,
                analysis_result_title: item.analysis_results_title,
              }),
            )
          }
        })
      }
      if (item.update === false && item.value) {
        dispatch(
          postAnalysisResult({
            analysis_results_title: [item.analysis_results_title],
            medical_report: medicalReportId,
            value: item.value,
          }),
        )
      }
      if (item.update === true && item.value) {
        dispatch(
          patchAnalysisResult({
            id: item.id,
            value: {
              analysis_results_title: [item.analysis_results_title],
              medical_report: medicalReportId,
              value: item.value,
            },
          }),
        )
      }
    })
    setStatusReport('create-report')
  }

  const initial = {
    data: initialState,
  }

  return (
    <div className={Styles.container}>
      <Form
        onSubmit={onSubmit}
        formId='data'
        initialValues={initial}
        mutators={{
          ...arrayMutators,
        }}
      >
        {({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit} id='form-SecondStepFieldSet'>
              <FieldArray name='data'>
                {({ fields }) =>
                  fields.map((name, index) => (
                    <>
                      <div key={index}>
                        <div className={Styles.wrapper}>
                          <Field name={`${name}.title`} type='text'>
                            {({ input }) => {
                              return (
                                <div className={Styles.block}>
                                  <div className={Styles.input}>
                                    {values.data[index].static ? (
                                      <input type='text' {...input} className={Styles.input__option} />
                                    ) : (
                                      <div className={Styles.text}>{values.data[index].title}</div>
                                    )}
                                  </div>
                                </div>
                              )
                            }}
                          </Field>

                          <FieldCustom
                            // arr={initial.data[index]?.measure}
                            name={`${name}`}
                            staticField={initial.data[index]?.static}
                            indexField={index}
                            initialState={initialState}
                          />
                        </div>
                      </div>
                    </>
                  ))
                }
              </FieldArray>
              <div className={Styles.button__container}>
                <ButtonColor
                  disabled={form.getState().invalid}
                  className={Styles.button_width}
                  value='Перейти к медзаключению'
                  type='submit'
                />
              </div>
            </form>
          )
        }}
      </Form>
    </div>
  )
}
