import React from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'
import icon from '@icons/medical-report-icon.svg'
import { useAppSelector } from '@app/redux/hooks'

export const MedicalReportNotification = ({ notification }) => {
  const userName = useAppSelector((state) => state.auth.user.info.first_name)

  return (
    <div className={Styles.content}>
      <div className={Styles.event}>
        <img src={icon} alt='icon' />
        <div className={Styles.eventContent}>
          <h3>{`${userName}, ваше медзаключение готово`}</h3>
          <p>Составьте меню на неделю и мы сформируем для вас список продуктов</p>
          {notification.content_object && (
            <Link className={Styles.link} to={`/my-appointment/${notification.content_object.appointment}`}>
              Смотреть медзаключение
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
