import React from 'react'
import { TitleComponent } from '../TitleComponent'
import Styles from './style.module.scss'
import emoji from '@icons/emoji.svg'
import { ButtonColor } from '@components/ui/ButtonColor'
import closeButton from '@icons/close-gray.svg'

export const ConfirmComponent = (props) => {
  return (
    <>
      <button className={Styles.close}>
        <img alt='close' src={closeButton} className={Styles.close__img} onClick={() => props.setShowConfirm(false)} />
      </button>
      <div className={Styles.container}>
        <TitleComponent
          title='Хотите завершить опрос?'
          text='Мы сохраним полученные ответы. Вы всегда сможете вернуться и ответить на оставшиеся вопросы'
          src={emoji}
        />
        <div className={Styles.buttons__wrapper}>
          <ButtonColor
            value='Вернуться к опросу'
            className={`${Styles.button__confirm} ${Styles.button_withBorder}`}
            onClick={() => props.setShowConfirm(false)}
          />
          <ButtonColor value='Завершить' className={Styles.button__confirm} onClick={() => props.setShowQuiz(false)} />
        </div>
      </div>
    </>
  )
}
