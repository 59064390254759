import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import calendar from '@icons/calendar.svg'
import { ButtonColor } from '@components/ui/ButtonColor'
import useWindowSize from '@helpers/useWindowSizeHook'
import moment from 'moment'
import { useAppSelector } from '@app/redux/hooks'
import { TransitionsModal } from '@components/Modal'
import { CalendarSmall } from '@components/CalendarSmall'
import { DrawerPanel } from '@components/Drawer'
import { InputCheckbox } from './components/InputCheckbox'
// import { ProgressComponent } from './components/ProgressComponent'
import { TitleComponent } from './components/TitleComponent'
import { ConfirmComponent } from './components/ConfirmComponent'
// import { getQuizList, getRecommendation, postQuiz } from './redux/quizSlicer'

export const Quiz = (props) => {
  // const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.auth.user.info)
  const birthDay = () => {
    if (typeof user.birthday === 'string') {
      return moment(user.birthday, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }
    return null
  }
  const { width } = useWindowSize()
  const desktop = width >= 829
  const [step, setStep] = useState(0)
  const [values, setValues] = useState(user.birthday)
  const [dayOfBirth, setDayOfBirth] = useState(birthDay)
  const [quizList] = useState(null)
  const [habits, setHabits] = useState([])
  const [finish, setFinish] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [value, setValue] = useState('')
  const sendDate = moment(dayOfBirth, 'DD.MM.YYYY').format('YYYY-MM-DD')
  const [gender, setGender] = useState(null)
  const [age, setAge] = useState(null)
  const [donePage] = useState(false)
  /* useEffect(() => {
    dispatch(getQuizList())
      .then((res) => {
        setQuizList(res.payload)
      })
      .then(() => setDonePage(true))
  }, []) */

  useEffect(() => {
    setDayOfBirth(dayOfBirth)
  }, [birthDay])

  /* const sendAnswer = (value) => {
    dispatch(postQuiz({ user: user.id, ...value }))
    setValue('')
  } */

  const handler = () => {
    if (step < quizList.length - 1) {
      setStep((prev) => prev + 1)
    } else {
      setStep(null)
      setFinish(true)
    }
  }

  const handlerHabit = (e) => {
    const { id, checked } = e.currentTarget
    if (checked) {
      setHabits([...habits, id])
    } else {
      const index = habits.indexOf(id)
      habits.splice(index, 1)
    }
  }

  /* const goRecommendation = () => {
    props.setShowQuiz(false)
    dispatch(getRecommendation())
  } */
  const condition = (item) => {
    if (
      item &&
      item.question_condition.length > 0 &&
      item.question_condition[0].condition.gender === gender &&
      item.question_condition[0].condition.max_age > age &&
      item.question_condition[0].condition.min_age < age
    ) {
      return true
    }
    if (item.question_condition.length === 0) {
      return true
    } else {
      setStep((prev) => prev + 1)
      return false
    }
  }
  const numberIsInteger = (value) => {
    return value === '' || value % 1 !== 0
  }

  return (
    <>
      <TransitionsModal isOpenModal={props.showQuiz} setModalOpened={props.setShowQuiz}>
        <div className={Styles.container}>
          <button
            className={Styles.close}
            onClick={() => {
              quizList.length > 0 ? setShowConfirm(true) : props.setShowQuiz(false)
            }}
          />
          <div className={Styles.content}>
            {donePage && (
              <>
                {quizList.length > 0 ? (
                  quizList.map((item, i) => (
                    <div key={i}>
                      {step === i && condition(item) && (
                        <div className={Styles.content__item}>
                          <TitleComponent title={item.title} text={item.description} />
                          <div className={item.choices.length <= 2 ? Styles.main__wrapper : Styles.wrapper}>
                            {/* type === 4 - один из списка */}
                            {item.type === 4 && (
                              <>
                                {item.choices.map((choice, index) => (
                                  <div key={index} className={Styles.button__wrapper}>
                                    {choice.image && (
                                      <img className={Styles.button__img} src={choice.image} alt='img' />
                                    )}
                                    <input
                                      type='button'
                                      className={Styles.button}
                                      onClick={() => {
                                        handler()
                                        // sendAnswer({ choices: [choice.id], question: item.id })
                                        if (item.title === 'Ваш пол?') {
                                          choice.value === 'Мужской' ? setGender('male') : setGender('female')
                                        }
                                      }}
                                      value={choice.value}
                                    />
                                    {choice.description && <p className={Styles.text__clarify}>{choice.description}</p>}
                                  </div>
                                ))}
                              </>
                            )}
                            {/* type === 3 - дата */}
                            {item.type === 3 && condition(item) && (
                              <div className={Styles.wrapper}>
                                <div>
                                  <input
                                    type='text'
                                    name='birthday'
                                    width='200px'
                                    value={dayOfBirth === null ? '' : dayOfBirth}
                                    onClick={() => setShowCalendar(true)}
                                    onChange={(e) => setDayOfBirth(e.target.value)}
                                    className={Styles.input__birthday}
                                  />
                                  <img src={calendar} alt='icon' className={Styles.birthday__icon} />
                                </div>

                                <div>
                                  {desktop ? (
                                    <TransitionsModal
                                      isOpenModal={showCalendar}
                                      setModalOpened={() => setShowCalendar((isOpen) => !isOpen)}
                                      className={Styles.modal__container}
                                      opacity='0'
                                      style={{ position: 'absolute', top: '0', left: '0', inset: 'auto' }}
                                      disablePortal
                                    >
                                      <CalendarSmall
                                        birth={setDayOfBirth}
                                        changeShowCalendar={setShowCalendar}
                                        setValues={setValues}
                                        values={values}
                                      />
                                    </TransitionsModal>
                                  ) : (
                                    <DrawerPanel
                                      closeIcon
                                      width='100%'
                                      anchor='bottom'
                                      open={showCalendar}
                                      onClose={() => setShowCalendar((isOpen) => !isOpen)}
                                    >
                                      <CalendarSmall
                                        birth={setDayOfBirth}
                                        changeShowCalendar={setShowCalendar}
                                        setValues={setValues}
                                        values={values}
                                      />
                                    </DrawerPanel>
                                  )}
                                </div>

                                <ButtonColor
                                  value='Продолжить'
                                  onClick={() => {
                                    handler()
                                    // sendAnswer({ value: sendDate, question: item.id })
                                    setAge(moment().diff(sendDate, 'years', false))
                                  }}
                                  className={Styles.button_next}
                                  disabled={dayOfBirth === null}
                                />
                              </div>
                            )}
                            {/* type === 1 - число с плавающей точкой */}
                            {quizList[step].type === 1 && condition(item) && (
                              <>
                                <div className={Styles.input__wrapper}>
                                  <input
                                    className={Styles.input}
                                    type='number'
                                    onChange={(e) => setValue(e.target.value)}
                                    value={value}
                                  />
                                  <ButtonColor
                                    onClick={() => {
                                      handler()
                                      // sendAnswer({ value, question: item.id })
                                    }}
                                    value='Продолжить'
                                    className={Styles.button_next}
                                    disabled={value === ''}
                                  />
                                </div>
                              </>
                            )}
                            {/* type ===  0- целое число  */}
                            {quizList[step].type === 0 && condition(item) && (
                              <>
                                <div className={Styles.input__wrapper}>
                                  <input
                                    className={Styles.input}
                                    type='number'
                                    onChange={(e) => setValue(e.target.value)}
                                    value={value}
                                  />
                                  <ButtonColor
                                    onClick={() => {
                                      handler()
                                      // sendAnswer({ value, question: item.id })
                                    }}
                                    value='Продолжить'
                                    className={Styles.button_next}
                                    disabled={numberIsInteger(value)}
                                  />
                                </div>
                              </>
                            )}
                            {/* type === 2 - string */}
                            {quizList[step].type === 2 && condition(item) && (
                              <>
                                <div className={Styles.input__wrapper}>
                                  <input
                                    className={`${Styles.input} ${Styles.input__str}`}
                                    type='text'
                                    onChange={(e) => setValue(e.target.value)}
                                    value={value}
                                  />
                                  <ButtonColor
                                    onClick={() => {
                                      handler()
                                      // sendAnswer({ value, question: item.id })
                                    }}
                                    value='Продолжить'
                                    className={Styles.button_next}
                                    disabled={value === ''}
                                  />
                                </div>
                              </>
                            )}

                            {/* type === 5 - несколько из списка */}
                            {quizList[step].type === 5 && condition(item) && (
                              <>
                                <div className={Styles.checkbox_wrapper}>
                                  {quizList[step].choices.map((item, index) => (
                                    <InputCheckbox key={index} item={item} index={item.id} onChange={handlerHabit} />
                                  ))}
                                </div>
                                <ButtonColor
                                  onClick={() => {
                                    handler()
                                    // sendAnswer({ choices: habits, question: item.id })
                                  }}
                                  value='Продолжить'
                                  className={Styles.button_next}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>Вы уже прошли опрос</p>
                )}
              </>
            )}
            {finish && (
              <div className={Styles.content__item}>
                <TitleComponent
                  title='Ура, готово 🎉'
                  text='Мы уже составили для вас индивидуальные рекомендации. Перейдите и посмотрите на них!'
                  className={Styles.text_width4}
                />
                {/* TODO добавить переход на рекомендации, после ответа заказчика */}
                {/* <ButtonColor value='Смотреть рекомендации' className={Styles.button_next} onClick={goRecommendation} /> */}
              </div>
            )}
          </div>
        </div>
      </TransitionsModal>
      {showConfirm && (
        <DrawerPanel
          width='100%'
          closeIcon={!desktop}
          anchor={desktop ? 'right' : 'bottom'}
          open={showConfirm}
          onClose={() => setShowConfirm((isOpen) => !isOpen)}
        >
          <ConfirmComponent setShowConfirm={setShowConfirm} setShowQuiz={props.setShowQuiz} />
        </DrawerPanel>
      )}
    </>
  )
}
