import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { useSnackbar } from 'notistack'
import { setDataAppointment, setDateAppointment, createAppointment } from '../../../../redux/doctorSlicer'
import { formateDate } from '../../../../../../utils/formateDate'
import Styles from './style.module.scss'
import { Calendar } from '../../../UI/Calendar'
import { InputRadio } from '../../../UI/InputRadio'
import caretIcon from '@icons/caret.svg'

interface RecordingSetupProps {
  completeRecord: any
}

export const RecordingSetup: React.FC<RecordingSetupProps> = ({ completeRecord }) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const listConsultationDirection = useAppSelector((state) => state.doctor.listConsultationDirection)
  const dataAppointment = useAppSelector((state) => state.doctor.dataAppointment)
  const currentPatient = useAppSelector((state) => state.doctor.currentPatient)
  const currentDoctorId = useAppSelector((state) => state.auth.user.info.id)
  // const appoinmentsMonth = useAppSelector((state) => state.doctor.appointmentsMonth)
  const [currentMonthDate, setCurrentMonthDate] = useState(formateDate(new Date(), 'YYYY-MM-DD'))
  const [selectedDate, setSelectedDate] = useState(null)
  const [formatedDate, setFormatedDate] = useState(null)
  const [formatedDateShort, setFormatedDateShort] = useState(null)
  const [formatedDateDay, setDormatedDateDay] = useState(null)
  const [timeAppointment, setTimeAppointment] = useState(null)
  const [selectedDirection, setSelectedDirection] = useState(listConsultationDirection[0])
  const [displaySelection, setDisplaySelection] = useState(false)

  const changeMonth = (date) => {
    setCurrentMonthDate(date)
    setSelectedDate(null)
  }

  const selectDay = (date) => {
    setSelectedDate(date)
    dispatch(setDataAppointment({ ...dataAppointment, day_reception: date.day }))
  }

  const selectTime = (event) => {
    setTimeAppointment(event.target.id)
    dispatch(setDataAppointment({ ...dataAppointment, time_reception: event.target.value }))
  }

  const toggleSelection = () => setDisplaySelection((prev) => !prev)

  const selectDirection = (direction) => {
    setSelectedDirection(direction)
    dispatch(setDataAppointment({ ...dataAppointment, direction: direction.id }))
    toggleSelection()
  }

  const selectType = (event) =>
    dispatch(setDataAppointment({ ...dataAppointment, consultation_type: Number(event.target.value) }))

  const onChangeFree = (event) =>
    dispatch(
      setDataAppointment({
        ...dataAppointment,
        is_free: event.target.checked,
      }),
    )

  const addAppointment = async () => {
    try {
      const res = await dispatch(
        createAppointment({ ...dataAppointment, patient: currentPatient.id, doctor: currentDoctorId }),
      )

      dispatch(setDataAppointment({ ...dataAppointment, id: res.payload.data.id }))
      dispatch(setDateAppointment(`${formatedDateShort}, ${formatedDateDay}, ${timeAppointment}`))

      completeRecord()
    } catch (error) {
      const message = error
      const type = 'error'

      enqueueSnackbar(
        { message, type },
        {
          autoHideDuration: 1500,
        },
      )
    }
  }

  useEffect(() => {
    dispatch(
      setDataAppointment({
        doctor: 1,
        consultation_type: null,
        is_free: false,
        doctor_appointed: true,
        description: '',
        is_online: true,
        direction: null,
        time_reception: '',
        day_reception: '',
      }),
    )
  }, [currentMonthDate])

  useEffect(() => {
    if (selectedDate) {
      const date = formateDate(selectedDate.day, 'DD MMMM')
      const dateShort = formateDate(selectedDate.day, 'DD MMM')
      const dateDay = formateDate(selectedDate.day, 'dddd')

      setFormatedDate(date)
      setFormatedDateShort(dateShort)
      setDormatedDateDay(dateDay)
    }
  }, [selectedDate])

  return (
    <div className={Styles.container}>
      <Calendar
        currentMonthDate={currentMonthDate}
        changeMonth={changeMonth}
        selectedDate={selectedDate}
        selectDay={selectDay}
      />
      {selectedDate && (
        <div className={Styles.oneday}>
          {new Date().getDate() === new Date(selectedDate.day).getDate() ? (
            <span className={Styles.today}>сегодня, {formatedDate}</span>
          ) : (
            <span className={Styles.today}>{formatedDate}</span>
          )}
          <div className={Styles.visits}>
            {selectedDate?.receptions?.map((reception) => {
              return (
                <>
                  {!reception.is_blacklist && !reception.is_past && !reception.appointment ? (
                    <label key={reception.id} className={Styles.visit}>
                      <InputRadio
                        id={reception.time}
                        className={Styles.inputDate}
                        onChange={selectTime}
                        name='date'
                        value={reception.id}
                      />
                      <span className={Styles.time}>{reception.time}</span>
                    </label>
                  ) : (
                    <>
                      {!reception.is_blacklist && reception.appointment ? (
                        <div key={reception.id} className={`${Styles.visit} ${Styles.visit_expired}`}>
                          <i
                            className={
                              reception.appointment.is_online
                                ? `${Styles.icon}`
                                : `${Styles.icon} ${Styles.icon_offline}`
                            }
                          >
                            {}
                          </i>
                          <span className={Styles.time}>{reception.time}</span>
                        </div>
                      ) : (
                        <div key={reception.id} className={`${Styles.visit} ${Styles.visit_expired}`}>
                          <span className={Styles.time}>{reception.time}</span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )
            })}
          </div>
        </div>
      )}
      <div className={Styles.content}>
        <form className={Styles.form}>
          <div className={Styles.block}>
            <span className={Styles.field}>Выберите направление консультации</span>
            <div className={Styles.select}>
              <div className={Styles.row} onClick={toggleSelection}>
                <span className={Styles.title}>{selectedDirection.title}</span>
                <button className={Styles.caret}>
                  <img
                    className={displaySelection ? `${Styles.arrow} ${Styles.icon_arrow}` : `${Styles.arrow}`}
                    src={caretIcon}
                    alt='Иконка'
                  />
                </button>
              </div>
              {displaySelection && (
                <div className={Styles.selection}>
                  {listConsultationDirection?.map((option) => (
                    <span key={option.id} className={Styles.option} onClick={() => selectDirection(option)}>
                      {option.title}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className={Styles.block}>
            <span className={Styles.field}>Выберите тип консультации</span>
            <div className={Styles.inputs}>
              <label className={Styles.label}>
                Первичная
                <InputRadio className={Styles.inputType} onChange={selectType} name='type' value={1} />
              </label>
              <label className={Styles.label}>
                Вторичная
                <InputRadio className={Styles.inputType} onChange={selectType} name='type' value={2} />
              </label>
            </div>
          </div>
          <div className={Styles.block}>
            <label className={Styles.free}>
              <input
                className={Styles.input}
                onChange={onChangeFree}
                type='checkbox'
                checked={dataAppointment.is_free}
              />
              <span className={Styles.checkbox}>{}</span>
              Бесплатный прием
            </label>
          </div>
        </form>
      </div>
      <div className={Styles.confirm}>
        <div className={Styles.denomination}>
          {selectedDate ? (
            <div className={Styles.choosed}>
              <span>Запись на прием</span>
              <span>
                {formatedDateShort}, {formatedDateDay}, {timeAppointment}
              </span>
            </div>
          ) : (
            <div className={Styles.choosed}>
              <span>Запись на прием</span>
              <span>выберите дату</span>
            </div>
          )}
        </div>
        <button
          className={Styles.revoke}
          onClick={addAppointment}
          disabled={
            !dataAppointment.day_reception || !dataAppointment.time_reception || !dataAppointment.consultation_type
          }
        >
          Записать
        </button>
      </div>
    </div>
  )
}
