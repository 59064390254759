import React, { useState } from 'react'
import Styles from './style.module.scss'
import { Nutraceutical } from '@components/Conclusion/components/Nutraceutical'
import { CardContainer } from '@components/ui/CardContainer'
import { Nutrition } from '../../../../components/Conclusion/components/Nutrition'
import reference1 from '@icons/reference-full1.svg'
import reference2 from '@icons/reference-full2.svg'
import reference3 from '@icons/reference-full3.svg'
import { LifeStyle } from '@components/Conclusion/components/LifeStyle'
import { MedicalReport } from './components/MedicalReport'
import { NutraceuticalsDrawer } from '@components/Conclusion/components/NutraceuticalsDrawer'
import { DrawerPanel } from '@components/Drawer'
import closeButton from '@icons/close.svg'
import { NutritionDrawer } from '@components/Conclusion/components/NutritionDrawer'
import { LifeStyleDrawer } from '@components/Conclusion/components/LifeStyleDrawer'
import { Link } from 'react-router-dom'
import { useAppSelector } from '@app/redux/hooks'

export const ReferenceFull = () => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [nutraceuticalsDrawer, setNutraceuticalsDrawer] = useState(false)
  const [nutritionDrawer, setNutritionDrawer] = useState(false)
  const [lifeStyleDrawer, setLifeStyleDrawer] = useState(false)
  const lastMedicalReport = useAppSelector((state) => state.userSpace.lastReport)

  return (
    <>
      <div className={Styles.reference__container}>
        {lastMedicalReport.nutraceutical_reports.length > 0 && (
          <CardContainer
            src={reference1}
            title='Нутрицевтики'
            backgroundColor='#FFE4EE;'
            main
            onClickMain={() => {
              setShowDrawer(true)
              setNutraceuticalsDrawer(true)
              setNutritionDrawer(false)
              setLifeStyleDrawer(false)
            }}
            content={
              <div className={Styles.wrapper}>
                <Nutraceutical data={lastMedicalReport.nutraceutical_reports} />
              </div>
            }
          />
        )}

        {lastMedicalReport.diet_reports.length > 0 && (
          <CardContainer
            src={reference2}
            title='Питание'
            main
            onClickMain={() => {
              setShowDrawer(true)
              setNutraceuticalsDrawer(false)
              setNutritionDrawer(true)
              setLifeStyleDrawer(false)
            }}
            content={
              <>
                <Nutrition data={lastMedicalReport.diet_reports} />
              </>
            }
          />
        )}
        {lastMedicalReport.lifestyle_reports.length > 0 && (
          <CardContainer
            src={reference3}
            title='Образ жизни'
            main
            onClickMain={() => {
              setShowDrawer(true)
              setNutraceuticalsDrawer(false)
              setNutritionDrawer(false)
              setLifeStyleDrawer(true)
            }}
            content={
              <div className={Styles.wrapper}>
                <LifeStyle main data={lastMedicalReport.lifestyle_reports} />
              </div>
            }
          />
        )}
      </div>
      {lastMedicalReport && <MedicalReport id={lastMedicalReport.appointment} medicalReports={lastMedicalReport} />}
      <div className={Styles.reference__mobile}>
        <button
          className={`${Styles.button} ${Styles.nutraceuticals__mobile}`}
          onClick={() => {
            setShowDrawer(true)
            setNutraceuticalsDrawer(true)
            setNutritionDrawer(false)
            setLifeStyleDrawer(false)
          }}
        >
          <img src={reference1} className={Styles.reference__img} alt='reference' />
          <h6 className={Styles.reference__title}>Нутрицевтики</h6>
        </button>
        <button
          className={`${Styles.button} ${Styles.nutrition__mobile}`}
          onClick={() => {
            setShowDrawer(true)
            setNutraceuticalsDrawer(false)
            setNutritionDrawer(true)
            setLifeStyleDrawer(false)
          }}
        >
          <img src={reference2} className={Styles.reference__img} alt='reference' />
          <h6 className={Styles.reference__title}>Питание</h6>
        </button>
        <button
          className={`${Styles.button} ${Styles.life__mobile}`}
          onClick={() => {
            setShowDrawer(true)
            setNutraceuticalsDrawer(false)
            setNutritionDrawer(false)
            setLifeStyleDrawer(true)
          }}
        >
          <img src={reference3} className={Styles.reference__img} alt='reference' />
          <h6 className={Styles.reference__title}>Образ жизни</h6>
        </button>
      </div>
      <DrawerPanel
        width='100%'
        anchor='right'
        open={showDrawer}
        onClose={() => {
          setShowDrawer((isOpen) => !isOpen)
        }}
      >
        <div className={Styles.container}>
          <div className={Styles.title__container}>
            <img
              className={Styles.logo}
              src={
                (nutraceuticalsDrawer && reference1) ||
                (nutritionDrawer && reference2) ||
                (lifeStyleDrawer && reference3)
              }
              alt='icon'
            />
            <h1 className={Styles.title}>
              {(nutritionDrawer && 'Питание') ||
                (lifeStyleDrawer && 'Образ жизни') ||
                (nutraceuticalsDrawer && 'Нутрицевтики')}
            </h1>
            <img
              src={closeButton}
              alt='img'
              className={Styles.closeButton}
              onClick={() => {
                setShowDrawer(false)
              }}
            />
          </div>
          <div className={Styles.content__container}>
            <Link className={Styles.link} to={`/my-appointment/${lastMedicalReport.appointment}`}>
              Перейти к медзаключению
            </Link>
            <div className={Styles.content}>
              {nutraceuticalsDrawer && <NutraceuticalsDrawer data={lastMedicalReport.nutraceutical_reports} />}
              {nutritionDrawer && <NutritionDrawer data={lastMedicalReport.diet_reports} />}
              {lifeStyleDrawer && <LifeStyleDrawer data={lastMedicalReport.lifestyle_reports} />}
            </div>
          </div>
        </div>
      </DrawerPanel>
    </>
  )
}
