import React from 'react'
import useWindowSize from '../../helpers/useWindowSizeHook'
import { DrawerPanel } from '@components/Drawer'
import Styles from './style.module.scss'
import { MOBILE_BREAKPOINT } from '../../global-constants'

interface ModalWindowProps {
  open: boolean
  closeModal: any
}

export const DrawerComponent: React.FC<ModalWindowProps> = ({ open, closeModal, children }) => {
  const { width } = useWindowSize()

  return (
    <>
      {width > MOBILE_BREAKPOINT ? (
        <DrawerPanel width='100%' anchor='right' open={open} onClose={closeModal}>
          <div className={`${Styles.modal} ${Styles.modal_desktop}`}>
            <div className={Styles.content}>
              <button className={Styles.close} onClick={closeModal}>
                {}
              </button>
              {children}
            </div>
          </div>
        </DrawerPanel>
      ) : (
        <DrawerPanel width='100%' anchor='right' open={open} onClose={closeModal}>
          <div className={`${Styles.modal} ${Styles.modal_mobile}`}>
            <div className={Styles.content}>{children}</div>
          </div>
        </DrawerPanel>
      )}
    </>
  )
}
