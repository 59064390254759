/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import { setIsDrawer, setFilterView, setFilterProps } from '../../../../../../redux/catalogSlicer'
import { FilterBlock } from '../../../../../CatalogFilter/components/FilterBlock'
import { FilterAllItem } from './components/FilterAllItem'
import { MOBILE_BREAKPOINT } from '../../../../../../../../global-constants'
import 'rc-slider/assets/index.css'
import Styles from './style.module.scss'
import { FilterKeys } from './components/FilterKeys'
import Slider from '@mui/material/Slider'

export const FilterAll: React.FC = () => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const productFilter = useAppSelector((state) => state.catalog.productFilter)
  const filterList = useAppSelector((state) => state.catalog.filterList)
  const [filter, setFilter] = useState({ ...productFilter })

  const closeModal = () => {
    dispatch(setIsDrawer(false))
    dispatch(setFilterView('all'))
  }

  const changeFilter = (view) => dispatch(setFilterView(view))

  const clearFilter = () => {
    setFilter({
      brand: '',
      rating: '',
      category: '',
      form: '',
      min_price: '',
      max_price: '',
    })
  }

  const applyFilter = () => {
    dispatch(setFilterProps(filter))
    closeModal()
  }

  const handlePriceRange = (e, value) => {
    setFilter((prev) => ({
      ...prev,
      min_price: value[0],
      max_price: value[1],
    }))
  }

  return (
    <>
      <div className={Styles.filter}>
        {MOBILE_BREAKPOINT < width ? (
          <div className={Styles.header}>
            <span className={Styles.title}>Все фильтры</span>
          </div>
        ) : (
          <div className={`${Styles.header} ${Styles.header_mobile}`}>
            <button className={Styles.back}>{}</button>
            <span className={Styles.title}>Все фильтры</span>
            <button className={Styles.close} onClick={closeModal}>
              {}
            </button>
          </div>
        )}
        <div className={Styles.body}>
          <FilterBlock title='Цена'>
            <div className={Styles.wrapper}>
              <div className={Styles.inputs}>
                <div className={Styles.input}>
                  <span>от</span>
                  <input
                    onChange={(e) => {
                      setFilter((prev) => ({ ...prev, min_price: e.target.value }))
                    }}
                    type='number'
                    value={filter.min_price}
                    placeholder='0'
                  />
                </div>
                <div className={Styles.line}>
                  <span>{}</span>
                </div>
                <div className={Styles.input}>
                  <span>до</span>
                  <input
                    onChange={(e) => {
                      setFilter((prev) => ({ ...prev, max_price: e.target.value }))
                    }}
                    type='number'
                    value={filter.max_price}
                    placeholder='100000'
                  />
                </div>
              </div>
              <Slider
                value={[filter.min_price, filter.max_price]}
                onChange={handlePriceRange}
                valueLabelDisplay='auto'
                min={0}
                max={100000}
              />
            </div>
          </FilterBlock>
          <FilterBlock
            title='Категория здоровья'
            btnClear={() => {
              setFilter((prev) => ({ ...prev, category: '' }))
            }}
            btnAll={() => changeFilter('category')}
          >
            <div className={Styles.wrapper}>
              {/* <div className={Styles.search}>
                <input onChange={() => {}} type='text' value='' placeholder='Поиск бренда' />
                <button>{}</button>
              </div> */}
              <div className={Styles.content}>
                {filterList.categories &&
                  filterList.categories.map((category) => (
                    <FilterKeys
                      key={category.index}
                      title={category.name}
                      count={category.products}
                      setFilter={setFilter}
                      select={filter.category.includes(category.name)}
                      filteredKey='category'
                    />
                  ))}
              </div>
            </div>
          </FilterBlock>
          <FilterBlock
            title='Бренды'
            btnClear={() => {
              setFilter((prev) => ({ ...prev, brand: '' }))
            }}
            btnAll={() => changeFilter('brand')}
          >
            <div className={Styles.wrapper}>
              {/* <div className={Styles.search}>
                <input onChange={() => {}} type='text' value='' placeholder='Поиск бренда' />
                <button>{}</button>
              </div> */}
              <div className={Styles.content}>
                {filterList.brands &&
                  filterList.brands.map((brand) => (
                    <FilterKeys
                      key={brand.index}
                      title={brand.name}
                      count={brand.products}
                      setFilter={setFilter}
                      select={filter.brand.includes(brand.name)}
                      filteredKey='brand'
                    />
                  ))}
              </div>
            </div>
          </FilterBlock>
          <FilterBlock
            title='Рейтинг'
            btnClear={() => {
              setFilter((prev) => ({ ...prev, rating: '' }))
            }}
          >
            <FilterAllItem rating={1} select={filter.rating.includes('1')} setFilter={setFilter} />
            <FilterAllItem rating={2} select={filter.rating.includes('2')} setFilter={setFilter} />
            <FilterAllItem rating={3} select={filter.rating.includes('3')} setFilter={setFilter} />
            <FilterAllItem rating={4} select={filter.rating.includes('4')} setFilter={setFilter} />
            <FilterAllItem rating={5} select={filter.rating.includes('5')} setFilter={setFilter} />
          </FilterBlock>
          <FilterBlock
            title='Форма выпуска'
            btnClear={() => {
              setFilter((prev) => ({ ...prev, form: '' }))
            }}
            btnAll={() => changeFilter('form')}
          >
            <div className={Styles.wrapper}>
              {/* <div className={Styles.search}>
                <input onChange={() => {}} type='text' value='' placeholder='Поиск бренда' />
                <button>{}</button>
              </div> */}
              <div className={Styles.content}>
                {filterList.formtypes &&
                  filterList.formtypes.map((form) => (
                    <FilterKeys
                      key={form.index}
                      title={form.name}
                      count={form.products}
                      setFilter={setFilter}
                      select={filter.form.includes(form.name)}
                      filteredKey='form'
                    />
                  ))}
              </div>
            </div>
          </FilterBlock>
        </div>
      </div>
      <div className={Styles.confirm}>
        <button className={Styles.clear} onClick={clearFilter}>
          Очистить все
        </button>
        {/* {MOBILE_BREAKPOINT < width ? (
          <button className={Styles.view}>Показать 1 350 товаров</button>
        ) : (
          <button className={Styles.view}>1 350 товаров</button>
        )} */}
        {MOBILE_BREAKPOINT < width ? (
          <button className={Styles.view} onClick={applyFilter}>
            Показать
          </button>
        ) : (
          <button className={Styles.view} onClick={applyFilter}>
            Показать
          </button>
        )}
      </div>
    </>
  )
}
