import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
// import { SearchHistory } from './components/SearchHistory'
import { SearchPatient } from './components/SearchPatient'
import { SearchNutraceutical } from './components/SearchNutraceutical'
import { SearchResearch } from './components/SearchResearch'
import { MOBILE_BREAKPOINT } from '../../global-constants'
import Styles from './style.module.scss'
import { resetSearchResults, searchCatalog } from '../../pages/catalog/redux/catalogSlicer'
import { useDebounce } from '@helpers/useDebounce'
import { Spinner } from '@components/Spinner'
import { Loader } from '../../pages/doctor/components/UI/Loader'

interface IComponents {
  isModalOpen: boolean
  setIsModalOpen: any
  isActiveSearch?: boolean
  showSearch?: any
  hideSearch?: any
}

export const LKSearch: React.FC<IComponents> = (props) => {
  const { isModalOpen, setIsModalOpen, isActiveSearch, showSearch, hideSearch } = props
  const dispatch = useAppDispatch()

  const { width } = useWindowSize()
  const user = useAppSelector((state) => state.auth.user.info)
  const { listPatients } = useAppSelector((state) => state.doctor)
  const searchResults = useAppSelector((state) => state.catalog.searchResults)
  const [value, setValue] = useState('')
  const [historyResults] = useState([])
  const [patientsResults, setPatientsResults] = useState([])
  const [nutraceuticalsResults, setNutraceuticalsResults] = useState([])
  const [researchResults, setResearchResults] = useState([])
  const [isRoleDoctor, setIsRoleDoctor] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formRef: any = useRef()
  const debouncedSearchTerm = useDebounce(value, 500)

  useEffect(() => {
    if (debouncedSearchTerm.trimStart()) {
      dispatch(searchCatalog(debouncedSearchTerm.trimStart())).then(() => setIsLoading(false))
    }
  }, [debouncedSearchTerm])

  const handleChange = (event) => {
    setIsLoading(true)
    setValue(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const resetValue = (isWithModal) => {
    setValue('')
    dispatch(resetSearchResults())

    if (isWithModal) {
      setIsModalOpen(false)
    }
  }

  const checkIfClickedOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      setValue('')
      hideSearch()
      dispatch(resetSearchResults())
    }
  }

  useEffect(() => {
    if (user.role === 'doctor') {
      setIsRoleDoctor(true)
    } else {
      setIsRoleDoctor(false)
    }
  }, [user])

  useEffect(() => {
    if (value === '') {
      setPatientsResults([])
      setNutraceuticalsResults([])
      setResearchResults([])
    } else {
      if (isRoleDoctor) {
        if (listPatients.length !== 0) {
          setPatientsResults(
            listPatients.filter((patient) => {
              return (
                patient.first_name?.toLowerCase().includes(value.toLowerCase()) ||
                patient.last_name?.toLowerCase().includes(value.toLowerCase())
              )
            }),
          )
        }
      }
      setNutraceuticalsResults(searchResults.products)
      setResearchResults(searchResults.analyzes)
    }
  }, [value, listPatients, searchResults])

  useEffect(() => {
    if (width > MOBILE_BREAKPOINT) {
      document.addEventListener('mousedown', checkIfClickedOutside)

      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside)
      }
    }
  }, [width, value])

  return (
    <>
      {width > MOBILE_BREAKPOINT ? (
        <form className={Styles.form} onSubmit={handleSubmit} ref={formRef}>
          {isActiveSearch ? (
            <>
              <input className={Styles.input} onChange={handleChange} type='text' value={value} placeholder='Поиск' />
              {value && <button className={Styles.reset} onClick={() => resetValue(false)} />}
              <button className={Styles.search} onClick={hideSearch} />
            </>
          ) : (
            <>
              <button className={Styles.search} onClick={showSearch} />
            </>
          )}
          {value !== '' && (
            <ul className={Styles.result}>
              {historyResults.length !== 0 && (
                <div className={Styles.wrapper}>
                  <span className={Styles.caption}>История</span>
                  <div className={Styles.container}>
                    {historyResults.map(() => {
                      // console.log(history)
                    })}
                  </div>
                </div>
              )}
              {patientsResults.length !== 0 && (
                <div className={Styles.wrapper}>
                  <span className={Styles.caption}>Пациенты</span>
                  <div className={Styles.container}>
                    {patientsResults.map((patient) => (
                      <SearchPatient key={patient.id} item={patient} resetValue={() => resetValue(isModalOpen)} />
                    ))}
                  </div>
                </div>
              )}
              {nutraceuticalsResults.length !== 0 && (
                <div className={Styles.wrapper}>
                  <span className={Styles.caption}>Нутрицевтики</span>
                  <div className={Styles.container}>
                    {nutraceuticalsResults.map((nutraceutical) => (
                      <SearchNutraceutical
                        key={nutraceutical.id}
                        item={nutraceutical}
                        resetValue={() => resetValue(isModalOpen)}
                      />
                    ))}
                  </div>
                </div>
              )}
              {researchResults.length !== 0 && (
                <div className={Styles.wrapper}>
                  <span className={Styles.caption}>Обследование</span>
                  <div className={Styles.container}>
                    {researchResults.map((research) => (
                      <SearchResearch key={research.id} item={research} resetValue={() => resetValue(isModalOpen)} />
                    ))}
                  </div>
                </div>
              )}
              {patientsResults.length === 0 &&
                nutraceuticalsResults.length === 0 &&
                researchResults.length === 0 &&
                (isLoading ? <Loader /> : <span className={Styles.caption}>Ничего не найдено</span>)}
            </ul>
          )}
        </form>
      ) : (
        <>
          <form className={Styles.form} onSubmit={handleSubmit} ref={formRef}>
            <input className={Styles.input} onChange={handleChange} type='text' value={value} placeholder='Поиск' />
            <button className={Styles.reset} onClick={() => resetValue(false)} type='reset' />
          </form>
          {value !== '' && (
            <ul className={Styles.result}>
              {historyResults.length !== 0 && (
                <div className={Styles.wrapper}>
                  <span className={Styles.caption}>История</span>
                  <div className={Styles.container}>
                    {historyResults.map(() => {
                      // console.log(history)
                    })}
                  </div>
                </div>
              )}
              {patientsResults.length !== 0 && (
                <div className={Styles.wrapper}>
                  <span className={Styles.caption}>Пациенты</span>
                  <div className={Styles.container}>
                    {patientsResults.map((patient) => (
                      <SearchPatient key={patient.id} item={patient} resetValue={() => resetValue(isModalOpen)} />
                    ))}
                  </div>
                </div>
              )}
              {nutraceuticalsResults.length !== 0 && (
                <div className={Styles.wrapper}>
                  <span className={Styles.caption}>Нутрицевтики</span>
                  <div className={Styles.container}>
                    {nutraceuticalsResults.map((nutraceutical) => (
                      <SearchNutraceutical
                        key={nutraceutical.id}
                        item={nutraceutical}
                        resetValue={() => resetValue(isModalOpen)}
                      />
                    ))}
                  </div>
                </div>
              )}
              {researchResults.length !== 0 && (
                <div className={Styles.wrapper}>
                  <span className={Styles.caption}>Обследование</span>
                  <div className={Styles.container}>
                    {researchResults.map((research) => (
                      <SearchResearch key={research.id} item={research} resetValue={() => resetValue(isModalOpen)} />
                    ))}
                  </div>
                </div>
              )}
              {patientsResults.length === 0 &&
                nutraceuticalsResults.length === 0 &&
                researchResults.length === 0 &&
                (isLoading ? <Spinner /> : <span className={Styles.caption}>Ничего не найдено</span>)}
            </ul>
          )}
        </>
      )}
    </>
  )
}
