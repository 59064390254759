import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'

import { formateDate } from '../../../../../../../../../../utils/formateDate'

interface CancelDaysDayProps {
  day: any
  month: string
  onSelectDay: any
  selectedDates: any
}

export const CancelDaysDay: React.FC<CancelDaysDayProps> = (props) => {
  const { day, month, onSelectDay, selectedDates } = props

  const [className, setClassName] = useState([`${Styles.day}`])

  const currnetMonth = formateDate(month, 'MM-YYYY')
  const currentDay = formateDate(day.day, 'MM-YYYY')

  useEffect(() => {
    if (day.day === selectedDates.from_date) {
      setClassName((prev) => [...prev, `${Styles.day_first}`])
    } else {
      setClassName([`${Styles.day}`])
    }

    if (day.day > selectedDates.from_date && day.day < selectedDates.to_date) {
      setClassName((prev) => [...prev, `${Styles.day_select}`])
    }

    if (day.day === selectedDates.to_date) {
      setClassName((prev) => [...prev, `${Styles.day_last}`])
    }
  }, [selectedDates])

  return (
    <>
      {!day.is_available || currnetMonth !== currentDay ? (
        <div key={day.day} className={`${Styles.day} ${Styles.day_disabled}`}>
          <span className={Styles.inner}>{formateDate(day.day, 'DD')}</span>
        </div>
      ) : (
        <div key={day.day} className={className.join(' ')} onClick={() => onSelectDay(day)}>
          <span className={Styles.inner}>{formateDate(day.day, 'DD')}</span>
        </div>
      )}
    </>
  )
}
