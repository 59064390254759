import React from 'react'
import Styles from './style.module.scss'

interface FilterBlockProps {
  title: string
  btnClear?: any
  btnAll?: any
}

export const FilterBlock: React.FC<FilterBlockProps> = (props) => {
  const { title, btnClear, btnAll, children } = props

  return (
    <div className={Styles.block}>
      <div className={Styles.header}>
        <span className={Styles.title}>{title}</span>
        {btnClear && (
          <button className={Styles.clear} onClick={btnClear}>
            Очистить
          </button>
        )}
      </div>
      <div className={Styles.body}>{children}</div>
      {btnAll && (
        <div className={Styles.footer}>
          <button className={Styles.all} onClick={btnAll}>
            Смотреть все
          </button>
        </div>
      )}
    </div>
  )
}
