import React from 'react'
import Styles from './style.module.scss'

interface DoctorReceptionProps {
  reception: any
  onClickReception: any
}

export const DoctorReception: React.FC<DoctorReceptionProps> = ({ reception, onClickReception }) => {
  return (
    <>
      {reception.appointment !== null && !reception.is_past && (
        <div
          className={`${Styles.reception} ${Styles.reception_existing}`}
          onClick={() => onClickReception(reception.appointment.id)}
        >
          {reception.appointment?.is_online ? (
            <i className={Styles.icon}>{}</i>
          ) : (
            <i className={`${Styles.icon} ${Styles.icon_offline}`}>{}</i>
          )}
          <span className={Styles.time}>{reception.time}</span>
        </div>
      )}
      {reception.appointment !== null && reception.is_past && (
        <div
          className={`${Styles.reception} ${Styles.reception_past}`}
          onClick={() => onClickReception(reception.appointment.id)}
        >
          {reception.appointment?.is_online ? (
            <i className={Styles.icon}>{}</i>
          ) : (
            <i className={`${Styles.icon} ${Styles.icon_offline}`}>{}</i>
          )}
          <span className={Styles.time}>{reception.time}</span>
        </div>
      )}
    </>
  )
}
