import React from 'react'
import Styles from './style.module.scss'
import arrow from '@icons/arrow.svg'
import useWindowSize from '@helpers/useWindowSizeHook'
import classNames from 'classnames'

interface TabProps {
  activeField?: number
  openDrawer?: (e: any) => void
  fieldFull: boolean
  changeHandler?: () => void
  title: string
  text: string
  fieldNumber: number
  button?: any
  form?: any
  result?: any
  tabId?: string
  pay?: boolean
}

export const Tab: React.FC<TabProps> = (props) => {
  const cx = classNames.bind(Styles)
  const { width } = useWindowSize()
  const desktop = width >= 829
  const stepStyle = cx(Styles.step, {
    [`${Styles.step_active}`]: props.activeField === props.fieldNumber,
  })
  const logoStyle = cx(Styles.step__logo, {
    [`${Styles.step__logo_active}`]: props.activeField === props.fieldNumber,
  })
  const titleStyle = cx(Styles.step__title, {
    [`${Styles.step__title_active}`]: props.activeField === props.fieldNumber,
  })
  const textStyle = cx(Styles.step__text, {
    [`${Styles.step__text_active}`]: props.activeField === props.fieldNumber,
  })
  return (
    <>
      <div
        data-id={props.fieldNumber}
        className={stepStyle}
        onClick={props.openDrawer}
        id={props.tabId ? props.tabId : null}
      >
        <div className={Styles.wrapper}>
          <div className={Styles.step__header}>
            <div className={Styles.step__wrapper}>
              <div className={Styles.step__logo_wrap}>
                {props.fieldFull ? (
                  <div className={Styles.is__ok} />
                ) : (
                  <div className={logoStyle}>{props.fieldNumber}</div>
                )}
              </div>
              <div className={Styles.step__desc}>
                <h5 className={titleStyle}>{props.title}</h5>
                {!desktop && !props.fieldFull && <p className={textStyle}>{props.text}</p>}
                {desktop && props.activeField !== props.fieldNumber && !props.fieldFull && (
                  <p className={textStyle}>{props.text}</p>
                )}
                {props.fieldFull && <>{props.result}</>}
                {props.fieldFull && (
                  <button onClick={props.changeHandler} className={Styles.button}>
                    Изменить
                  </button>
                )}
              </div>
            </div>

            {!props.fieldFull && !props.pay && <img className={Styles.arrow} src={arrow} alt='arrow' />}
          </div>
          {desktop && props.activeField === props.fieldNumber && <>{props.form}</>}
        </div>

        {props.activeField === props.fieldNumber && desktop && <div>{props.button}</div>}
      </div>
    </>
  )
}
