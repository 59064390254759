import React from 'react'
import Styles from './style.module.scss'

import { CancelDaysDay } from './CancelDaysDay'

import { formateDate } from '../../../../../../../../../utils/formateDate'
import { compareDate } from '../../../../../../../../../utils/compareDate'

interface CancelDaysProps {
  currentHalfYears: any
  onSelectDay: any
  selectedDates: any
  onConfirmCancelDays: any
}

export const CancelDays: React.FC<CancelDaysProps> = (props) => {
  const { currentHalfYears, onSelectDay, selectedDates, onConfirmCancelDays } = props

  return (
    <div className={Styles.months}>
      {currentHalfYears?.map((month) => (
        <div key={month.date} className={Styles.month}>
          <span className={Styles.may}>{formateDate(month.date, 'MMMM YYYY')}</span>
          <div className={Styles.days}>
            {month?.data?.map((day) => (
              <CancelDaysDay
                key={day.day}
                day={day}
                month={month.date}
                onSelectDay={onSelectDay}
                selectedDates={selectedDates}
              />
            ))}
          </div>
        </div>
      ))}
      {selectedDates.from_date && (
        <div className={Styles.block}>
          <div className={Styles.designation}>
            {selectedDates.from_date && !selectedDates.to_date && (
              <>
                <span className={Styles.denotation}>Выбран 1 день</span>
                <span className={Styles.choosed}>{formateDate(selectedDates.from_date, 'DD MMMM')}</span>
              </>
            )}
            {selectedDates.from_date && selectedDates.to_date && (
              <>
                <span className={Styles.denotation}>
                  Выбрано {compareDate(selectedDates.to_date, selectedDates.from_date) + 1} дня
                </span>
                <span className={Styles.choosed}>
                  {formateDate(selectedDates.from_date, 'DD MMMM')} - {formateDate(selectedDates.to_date, 'DD MMMM')}
                </span>
              </>
            )}
          </div>
          <button className={Styles.confirm} onClick={onConfirmCancelDays}>
            Отменить прием
          </button>
        </div>
      )}
    </div>
  )
}
