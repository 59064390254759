import React from 'react'
import Styles from './style.module.scss'
import { TooltipComponent } from '@components/TooltipComponent'
import { BorderLine } from '@components/ui/BorderLine'
import moment from 'moment'

export const TotalAppointment = (props) => {
  return (
    <div className={Styles.container}>
      <h3 className={Styles.title}>Запись на прием</h3>

      <div className={Styles.item}>
        <h6 className={Styles.subtitle}>Консультация</h6>
        <span className={Styles.item__title}>{props.item.consultation_type.direction.title}</span>
      </div>

      <div className={Styles.item}>
        <h6 className={Styles.subtitle}>Дата и время приема</h6>
        <span className={Styles.item__title}>{`${moment(props.item.day_reception, 'YYYY-MM-DD').format('D MMMM')} ${
          props.item.time_reception
        }`}</span>
      </div>

      {!props.item.is_online && (
        <div className={Styles.item}>
          <h6 className={Styles.subtitle}>Место приема</h6>
          <span className={Styles.item__title}>{props.item.doctor.doctor_settings.address}</span>
        </div>
      )}

      {props.isBorder && <BorderLine />}
      <ul className={Styles.wrap}>
        <li>
          <span className={Styles.item__title}>{props.item.consultation_type.title}</span>

          <span className={Styles.item__price}>
            <span />
            {props.item.consultation_type.price}
            <span className={Styles.span}> ₽</span>
          </span>
        </li>

        <li>
          {props.item.is_online ? (
            <span className={Styles.item__title}>
              Онлайн
              <TooltipComponent text='При онлайн приеме вы платите только за консультацию' />
            </span>
          ) : (
            <span className={Styles.item__title}>
              Лично
              <TooltipComponent text='Стоимость личного приема' />
            </span>
          )}
          <span className={Styles.item__price}>
            <span />
            {props.item.is_online ? 0 : 1500}
            <span className={Styles.span}>₽</span>
          </span>
        </li>
      </ul>

      <>
        <BorderLine />
        <ul className={Styles.wrap}>
          <li>
            <span className={Styles.total__item}>Итого</span>

            <span className={Styles.total__price}>
              <span />
              {+props.item.consultation_type.price + (props.item.is_online ? 0 : 1500)}
              <span className={Styles.span}> ₽</span>
            </span>
          </li>
        </ul>
      </>
    </div>
  )
}
