import React from 'react'
import Styles from './style.module.scss'
// import { styled } from '@mui/material/styles'
// import Select from '@mui/material/Select'
// import MenuItem from '@mui/material/MenuItem'
import { Field } from 'react-final-form'

// TODO не работает селект, при каждом клике срабатывает ререндер и возвращает дефолтное значение
/* const StyledSelect = styled(Select)(() => ({
  position: 'absolute',
  left: '49px',
  width: '78px',

  '& .MuiSelect-select': {
    fontFamily: 'inherit',
    background: '#DCF6FF',
    borderRadius: '12px',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '150%',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ' #7D9DFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-outlined': {
    paddingRight: '0!important',
    borderRadius: '12px',
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },
})) */
export const FieldCustom = ({ name, staticField, initialState, indexField }) => {
  /* const SelectConponent = () => {
    const form = useForm()
    const handleChange = (e) => {
      form.change(`${name}.measure`, e.target.value)
    }

    return (
      <StyledSelect
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        defaultValue={initialState[0].measure[0].measure}
        onChange={handleChange}
      >
        {initialState[indexField].measure.map((item) => (
          <MenuItem value={item.measure} key={item.id}>
            {item.measure}
          </MenuItem>
        ))}
      </StyledSelect>
    )
  } */

  return (
    <Field name={`${name}.value`} type='text'>
      {({ input }) => {
        return (
          <div className={Styles.block}>
            <div className={Styles.input}>
              <input type='text' {...input} />
              {/* initialState[indexField].measure.length > 1 && (
                <Field name={`${name}.measure`} component={SelectConponent} />
              ) */}
            </div>
            {initialState[indexField].measure && initialState[indexField].measure.length > 0 && (
              <div className={Styles.measure}>({initialState[indexField].measure[0].measure})</div>
            )}
            {staticField && (
              <Field name={`${name}.measure`} type='text'>
                {({ input }) => {
                  return (
                    <div className={Styles.block}>
                      <div className={Styles.input}>
                        <input type='text' {...input} className={Styles.input__option} />
                      </div>
                    </div>
                  )
                }}
              </Field>
            )}
          </div>
        )
      }}
    </Field>
  )
}
