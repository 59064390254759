import { useAppSelector, useAppDispatch } from '@app/redux/hooks'
import React, { useEffect } from 'react'
import { Notifications } from '@components/Notifications'
import { NoEvents } from '@components/Notifications/components/NoEvents'
import Styles from './style.module.scss'
import { useSnackbar } from 'notistack'
import useWindowSize from '@helpers/useWindowSizeHook'
import { showNewNotification } from '../../../pages/authorization/redux/authSlice'
import { Chat } from '@components/Chat'

export const SideMenuBody = ({ activeContent }) => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.auth.user.info)
  const notifications = useAppSelector((state) => state.auth.notifications)
  const notificationTrigger = useAppSelector((state) => state.auth.isNotification)
  const { width } = useWindowSize()
  const desktop = width >= 829
  useEffect(() => {
    if (desktop) {
      const message = `У вас новое уведомление`
      const type = 'success'
      notifications?.length &&
        user.role === 'patient' &&
        enqueueSnackbar(
          { message, type },
          {
            autoHideDuration: 1500,
          },
        )
      dispatch(showNewNotification(true))
    }
  }, [notificationTrigger])

  return (
    <div className={Styles.content}>
      {activeContent === 'bell' &&
        (notifications?.length ? <Notifications notifications={notifications} /> : <NoEvents />)}
      {activeContent === 'message' && <Chat />}
    </div>
  )
}
