import React from 'react'
import { Field, useForm } from 'react-final-form'
import Styles from './style.module.scss'
interface ResearchItemProps {
  item: any
  selected?: boolean
  children?
  index?: number
}

export const ResearchItem: React.FC<ResearchItemProps> = ({ item, selected = false, children }) => {
  const form = useForm()
  const add = () => {
    form.change(`${item}.selected`, true)
  }
  const del = () => {
    form.change(`${item}.selected`, false)
  }
  return (
    <div className={Styles.wrapper}>
      <div className={selected ? `${Styles.listItem} ${Styles.listItem_selected}` : `${Styles.listItem}`}>
        <span>{item.name}</span>
        <Field name={`${item}.name`}>
          {({ input }) => (
            <div>
              <label>{input.value}</label>
            </div>
          )}
        </Field>
        {selected ? (
          <button type='button' className={Styles.deleteButton} onClick={del} />
        ) : (
          <button type='button' className={Styles.button} onClick={add} />
        )}
      </div>
      {children}
    </div>
  )
}
