import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getAllAppointments: any = createAsyncThunk('appointment/getAllAppointments', async function () {
  const response = await api.get(`appointments/`)
  return response.data
})
export const getAppointmentListPlanned: any = createAsyncThunk('appointment/getAppointmentList', async function () {
  const response = await api.get(`appointments/?status=planned`)
  return response.data
})
export const getAppointmentListWaitingPaying: any = createAsyncThunk(
  'appointment/getAppointmentListWaitingPaying',
  async function () {
    const response = await api.get(`appointments/?status=waiting_paying`)
    return response.data
  },
)
export const getAppointmentListNotPaying: any = createAsyncThunk(
  'appointment/getAppointmentListNotPaying',
  async function () {
    const response = await api.get(`appointments/?status=not_payed`)
    return response.data
  },
)
export const getAppointmentListDoneForMain: any = createAsyncThunk(
  'appointment/getAppointmentListDoneForMain',
  async () => {
    const response = await api.get(`appointments/?status=done/`)
    return response.data
  },
)

export const getAppointmentListDone: any = createAsyncThunk(
  'appointment/getAppointmentListDone',
  async (payload: any) => {
    const response = await api.get(
      `appointments/?day_reception_after=${payload.after}&day_reception_before=${payload.before}`,
    )
    return response.data
  },
)
export const getAppointmentListPlannedMore: any = createAsyncThunk(
  'appointment/getAppointmentListPlannedMore',
  async function (page) {
    const response = await api.get(`appointments/?page=${page}&status=planned`)
    return response.data
  },
)
export const getAppointmentListDoneMore: any = createAsyncThunk(
  'appointment/getAppointmentListDoneMore',
  async function (page) {
    const response = await api.get(`appointments/?page=${page}&status=done`)
    return response.data
  },
)
const appointmentSlice = createSlice({
  name: 'appointment',
  initialState: {
    /* dataPlanned: [
      {
        consultation_type: null,
        created_at: '',
        day_reception: '',
        description: '',
        doctor: null,
        files: [],
        id: null,
        is_online: null,
        is_pay: null,
        medical_report: null,
        meeting_link: null,
        patient: null,
        status: '',
        time_reception: '',
      },
    ], */
    // dataDone: [],
    allAppointments: null,
    statusPlanned: null,
    statusDone: null,
    showMainPage: true,
    paginationPlanned: null,
    paginationDone: null,
    page: 2,
    pageDone: 2,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllAppointments.fulfilled, (state, { payload }) => {
      state.allAppointments = payload
    })
    builder.addCase(getAppointmentListPlanned.fulfilled, (state, action) => {
      // state.dataPlanned = action.payload.results
      state.paginationPlanned = action.payload.next
      state.statusPlanned = 'success'
    })
    builder.addCase(getAppointmentListPlanned.pending, (state) => {
      state.statusPlanned = 'loading'
    })
    builder.addCase(getAppointmentListPlanned.rejected, (state) => {
      state.statusPlanned = 'error'
    })
    builder.addCase(getAppointmentListDone.fulfilled, (state, action) => {
      // state.dataDone = action.payload.results
      state.paginationDone = action.payload.next
      state.statusDone = 'success'
    })
    builder.addCase(getAppointmentListDone.pending, (state) => {
      state.statusDone = 'loading'
    })
    builder.addCase(getAppointmentListDone.rejected, (state) => {
      state.statusDone = 'error'
    })
    builder.addCase(getAppointmentListPlannedMore.fulfilled, (state, action) => {
      // state.dataPlanned.push(...action.payload.results)
      state.paginationPlanned = action.payload.next
      if (state.paginationPlanned !== null) {
        state.page = state.page + 1
      }
    })
    builder.addCase(getAppointmentListDoneMore.fulfilled, (state, action) => {
      // state.dataDone.push(...action.payload.results)
      state.paginationDone = action.payload.next
      if (state.paginationDone !== null) {
        state.pageDone = state.pageDone + 1
      }
    })
  },
})

export default appointmentSlice.reducer
