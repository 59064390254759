import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '@app/redux/hooks'
import {
  getDoctorCalendarMonth,
  createBlackList,
  getBlackLists,
  setDisplayProfileContent,
} from '../../../../../../redux/doctorSlicer'
import Styles from './style.module.scss'

import { CancelDays } from './CancelDays'
import { CancelHours } from './CancelHours'

import { formateDate, getMonth } from '../../../../../../../../utils/formateDate'

export const CancelVisits: React.FC = () => {
  const dispatch = useAppDispatch()
  const [displayContent, setDisplayContent] = useState('days')
  const [currentMonthDate, setCurrentMonthDate] = useState(formateDate(new Date(), 'YYYY-MM-DD'))
  const [currentMonth, setCurrentMonth] = useState([])
  const [currentHalfYears, setCurrentHalfYears] = useState([])
  const [selectedDates, setSelectedDates] = useState({ from_date: '', to_date: '' })
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedTime, setSelectedTime] = useState({ time: [], day: null })
  const [loading, setLoading] = useState(false)

  const changeDisplayView = (view) => dispatch(setDisplayProfileContent(view))

  const changeView = (view) => setDisplayContent(view)

  const changeMonth = (date) => setCurrentMonthDate(date)

  const onSelectDay = (obj) => {
    if (!selectedDates.from_date) {
      setSelectedDates((prev) => ({ ...prev, from_date: obj.day }))
    }

    if (selectedDates.from_date && !selectedDates.to_date) {
      if (new Date(selectedDates.from_date) > new Date(obj.day)) {
        setSelectedDates((prev) => ({ ...prev, from_date: obj.day }))
      } else {
        setSelectedDates((prev) => ({ ...prev, to_date: obj.day }))
      }
    }

    if (selectedDates.from_date && selectedDates.to_date) {
      setSelectedDates({ from_date: obj.day, to_date: '' })
    }
  }

  const onClickDay = (date) => {
    setSelectedDate(date)
    setSelectedTime({ time: [], day: date.day })
  }

  const handlerChange = (event) => {
    const { name, value, checked } = event.target

    if (selectedTime.time.length !== 0) {
      const state = { status: true }

      selectedTime.time.forEach((time) => {
        if (time.id === Number(value)) {
          const arrayTime = selectedTime.time.filter((time) => time.id !== Number(value))
          setSelectedTime((prev) => ({ ...prev, time: [...arrayTime] }))
          state.status = false
        }
      })

      if (state.status) {
        setSelectedTime((prev) => ({ ...prev, time: [...prev.time, { id: Number(value), time: name, checked }] }))
      }
    } else {
      setSelectedTime((prev) => ({ ...prev, time: [...prev.time, { id: Number(value), time: name, checked }] }))
    }
  }

  const onConfirmCancelHours = async () => {
    try {
      const timesId = selectedTime?.time?.map((time) => time.id)
      const data = { day: selectedTime.day, time: timesId }

      await dispatch(createBlackList(data))
      await dispatch(getBlackLists())

      changeDisplayView('info')
    } catch (error) {
      throw new Error(error)
    }
  }

  const onConfirmCancelDays = async () => {
    try {
      await dispatch(createBlackList(selectedDates))
      await dispatch(getBlackLists())

      changeDisplayView('info')
    } catch (error) {
      throw new Error(error)
    }
  }

  const loadMonth = async (date) => {
    setLoading(true)

    try {
      const dataMonth = getMonth(date)

      const response = await dispatch(
        getDoctorCalendarMonth({ start_date: dataMonth.firstDayMonth, end_date: dataMonth.lastDayMonth }),
      )

      setCurrentMonth(response.payload.data)
      setLoading(false)
    } catch (error) {
      throw new Error(error)
    }
  }

  const loadHalfYear = async () => {
    const halfYears = []
    const arrMonths = []

    for (let i = 0; i < 6; i++) {
      const date = new Date(currentMonthDate)

      if (i === 0) {
        arrMonths.push(formateDate(date, 'YYYY-MM-DD'))
      } else {
        const nextDate = formateDate(date.setMonth(date.getMonth() + i), 'YYYY-MM-DD')
        arrMonths.push(nextDate)
      }
    }

    try {
      setLoading(true)

      const processArray = async (array) => {
        for (const date of array) {
          const dataMonth = getMonth(date)

          const response = await dispatch(
            getDoctorCalendarMonth({ start_date: dataMonth.firstDayMonth, end_date: dataMonth.lastDayMonth }),
          )

          halfYears.push({ date, data: response.payload.data })
        }

        setCurrentHalfYears(halfYears)
        setLoading(false)
      }

      processArray(arrMonths)
    } catch (error) {
      throw new Error(error)
    }
  }

  const closePopup = () => setSelectedDate(null)

  useEffect(() => {
    loadHalfYear()
  }, [])

  useEffect(() => {
    loadMonth(currentMonthDate)
  }, [currentMonthDate])

  useEffect(() => {
    if (displayContent === 'days') {
      setSelectedDate(null)
      setSelectedTime({ time: [], day: null })
    }

    if (displayContent === 'hours') {
      setSelectedDate(null)
      setSelectedDates({ from_date: '', to_date: '' })
    }
  }, [displayContent, currentMonthDate])

  return (
    <div className={Styles.cancel}>
      <div className={Styles.select}>
        <p className={Styles.text}>Выберите дни или часы, которые будут недоступны для записи пациентам</p>
        <ul className={Styles.list}>
          <li
            className={displayContent === 'days' ? `${Styles.item} ${Styles.item_active}` : `${Styles.item}`}
            onClick={() => changeView('days')}
          >
            <span>Дни</span>
          </li>
          <li
            className={displayContent === 'hours' ? `${Styles.item} ${Styles.item_active}` : `${Styles.item}`}
            onClick={() => changeView('hours')}
          >
            <span>Часы</span>
          </li>
        </ul>
      </div>
      <div className={Styles.calendar}>
        <div className={Styles.head}>
          <div className={Styles.shell}>
            <span className={Styles.daytime}>Пн</span>
            <span className={Styles.daytime}>Вт</span>
            <span className={Styles.daytime}>Ср</span>
            <span className={Styles.daytime}>Чт</span>
            <span className={Styles.daytime}>Пт</span>
            <span className={Styles.daytime}>Сб</span>
            <span className={Styles.daytime}>Вс</span>
          </div>
        </div>
        <div className={Styles.content}>
          {!loading && displayContent === 'days' && (
            <CancelDays
              currentHalfYears={currentHalfYears}
              onSelectDay={onSelectDay}
              selectedDates={selectedDates}
              onConfirmCancelDays={onConfirmCancelDays}
            />
          )}
          {!loading && displayContent === 'hours' && (
            <CancelHours
              currentMonthDate={currentMonthDate}
              currentMonth={currentMonth}
              changeMonth={changeMonth}
              selectedDate={selectedDate}
              onClickDay={onClickDay}
              handlerChange={handlerChange}
              onConfirmCancelHours={onConfirmCancelHours}
              closePopup={closePopup}
              selectedTime={selectedTime}
            />
          )}
        </div>
      </div>
    </div>
  )
}
