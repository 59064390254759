import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { useSnackbar } from 'notistack'
import {
  setModal,
  setDataAppointment,
  setDateAppointment,
  createAppointment,
  getDoctorCalendarMonth,
} from '../../../../redux/doctorSlicer'
import Styles from './style.module.scss'

import { RecordingSetup } from './components/RecordingSetup'
import { CalendarMobile } from '../../../UI/Calendar/CalendarMobile'
import { DoctorPopupMobile } from '../../../DoctorModal/components/DoctorPopupMobile'
import { DoctorPopupMobileVH } from '../../../DoctorModal/components/DoctorPopupMobileVH'

import backIcon from '@icons/back-mobile.svg'
import closeIcon from '@icons/close-blue.svg'

import { formateDate, getMonth } from '../../../../../../utils/formateDate'

interface DoctorRecordingMobileProps {
  title: string
}

export const DoctorRecordingMobile: React.FC<DoctorRecordingMobileProps> = ({ title }) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const listConsultationDirection = useAppSelector((state) => state.doctor.listConsultationDirection)
  const dataAppointment = useAppSelector((state) => state.doctor.dataAppointment)
  const currentPatient = useAppSelector((state) => state.doctor.currentPatient)
  const currentDoctorId = useAppSelector((state) => state.auth.user.info.id)
  const dateAppointment = useAppSelector((state) => state.doctor.dateAppointment)
  const [currentMonthDate, setCurrentMonthDate] = useState(formateDate(new Date(), 'YYYY-MM-DD'))
  const [currentMonth, setCurrentMonth] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [formatedDateShort, setFormatedDateShort] = useState(null)
  const [formatedDateDay, setDormatedDateDay] = useState(null)
  const [timeAppointment, setTimeAppointment] = useState(null)
  const [selectedDirection, setSelectedDirection] = useState(listConsultationDirection[0])
  const [selectedIdDirection, setSelectedIdDirection] = useState(selectedDirection.id)
  const [displaySelection, setDisplaySelection] = useState(false)
  const [loading, setLoading] = useState(false)
  const [completeRecord, setCompleteRecord] = useState(false)

  const closeModal = () => dispatch(setModal(false))

  const changeMonth = (date) => setCurrentMonthDate(date)

  const onClickDay = (date) => {
    setSelectedDate(date)
    dispatch(setDataAppointment({ ...dataAppointment, day_reception: date.day, direction: selectedDirection.id }))
  }

  const selectTime = (event) => {
    setTimeAppointment(event.target.value)
    dispatch(setDataAppointment({ ...dataAppointment, time_reception: event.target.id }))
  }

  const toggleSelection = () => setDisplaySelection((prev) => !prev)

  const handlerChangeDirection = (event) => setSelectedIdDirection(Number(event.target.name))

  const selectDirection = () => {
    const direction = listConsultationDirection.filter((direction) => direction.id === selectedIdDirection)

    setSelectedDirection(direction[0])
    dispatch(setDataAppointment({ ...dataAppointment, direction: direction[0].id }))
    toggleSelection()
  }

  const closePopupVH = () => {
    setSelectedIdDirection(selectedDirection.id)
    toggleSelection()
  }

  const selectType = (event) =>
    dispatch(setDataAppointment({ ...dataAppointment, consultation_type: Number(event.target.value) }))

  const onChangeFree = (event) => dispatch(setDataAppointment({ ...dataAppointment, is_free: event.target.checked }))

  const addAppointment = async () => {
    try {
      const res = await dispatch(
        createAppointment({ ...dataAppointment, patient: currentPatient.id, doctor: currentDoctorId }),
      )

      dispatch(setDataAppointment({ ...dataAppointment, id: res.payload.data.id }))
      dispatch(setDateAppointment(`${formatedDateShort}, ${formatedDateDay}, ${timeAppointment}`))

      setCompleteRecord(true)
    } catch (error) {
      const message = error
      const type = 'error'

      enqueueSnackbar(
        { message, type },
        {
          autoHideDuration: 1500,
        },
      )
    }
  }

  const loadMonth = async (date) => {
    setLoading(true)

    try {
      const dataMonth = getMonth(date)

      const response = await dispatch(
        getDoctorCalendarMonth({ start_date: dataMonth.firstDayMonth, end_date: dataMonth.lastDayMonth }),
      )

      setCurrentMonth(response.payload.data)
      setLoading(false)
    } catch (error) {
      throw new Error(error)
    }
  }

  useEffect(() => {
    loadMonth(currentMonthDate)
  }, [currentMonthDate])

  useEffect(() => {
    dispatch(
      setDataAppointment({
        doctor: 1,
        consultation_type: null,
        is_free: false,
        doctor_appointed: true,
        description: '',
        is_online: true,
        direction: null,
        time_reception: '',
        day_reception: '',
      }),
    )
  }, [currentMonthDate])

  useEffect(() => {
    if (selectedDate) {
      const dateShort = formateDate(selectedDate.day, 'DD MMM')
      const dateDay = formateDate(selectedDate.day, 'dddd')

      setFormatedDateShort(dateShort)
      setDormatedDateDay(dateDay)
    } else {
      setFormatedDateShort(null)
      setDormatedDateDay(null)

      setSelectedDirection(listConsultationDirection[0])
      setTimeAppointment(null)

      dispatch(
        setDataAppointment({
          doctor: 1,
          consultation_type: null,
          is_free: false,
          doctor_appointed: true,
          description: '',
          is_online: true,
          direction: null,
          time_reception: '',
          day_reception: '',
        }),
      )
    }
  }, [selectedDate])

  useEffect(() => {
    setSelectedIdDirection(selectedDirection.id)
  }, [selectedDirection])

  return (
    <div className={Styles.recording}>
      <div className={Styles.header}>
        <img src={backIcon} alt='Иконка' onClick={closeModal} />
        <span className={Styles.title}>{title}</span>
        <img src={closeIcon} alt='Иконка' onClick={closeModal} />
      </div>
      {!loading && (
        <CalendarMobile
          currentMonthDate={currentMonthDate}
          currentMonth={currentMonth}
          changeMonth={changeMonth}
          selectedDate={selectedDate}
          onClickDay={onClickDay}
        />
      )}
      {selectedDate && (
        <DoctorPopupMobile closePopup={() => setSelectedDate(null)}>
          <RecordingSetup
            selectedDate={selectedDate}
            selectTime={selectTime}
            toggleSelection={toggleSelection}
            selectedDirection={selectedDirection}
            selectType={selectType}
            addAppointment={addAppointment}
            formatedDateShort={formatedDateShort}
            formatedDateDay={formatedDateDay}
            timeAppointment={timeAppointment}
            onChangeFree={onChangeFree}
          />
        </DoctorPopupMobile>
      )}
      {displaySelection && (
        <DoctorPopupMobileVH title='Направление консультации' backPopup={closePopupVH} closePopup={closePopupVH}>
          <div className={Styles.container}>
            <div className={Styles.wrapper}>
              <span className={Styles.field}>Выберите направление консультации</span>
              <div className={Styles.radios}>
                {listConsultationDirection?.map((direction) => (
                  <label key={direction.id} className={Styles.lab}>
                    <input
                      className={Styles.input}
                      type='radio'
                      name={direction.id}
                      onChange={handlerChangeDirection}
                      checked={selectedIdDirection === direction.id}
                    />
                    <span className={Styles.radio}>{}</span>
                    {direction.title}
                  </label>
                ))}
              </div>
            </div>
            <button className={Styles.choose} onClick={() => selectDirection()}>
              Выбрать консультацию
            </button>
          </div>
        </DoctorPopupMobileVH>
      )}
      {completeRecord && (
        <DoctorPopupMobileVH title='Запись на прием' closePopup={closePopupVH}>
          <div className={Styles.container}>
            <div className={Styles.wrapperrr}>
              <span className={Styles.warning}>Пациент записан на прием</span>
              <span className={Styles.desc}>
                {currentPatient.last_name} {currentPatient.first_name.substring(0, 1)}.{' '}
                {currentPatient.middle_name?.substring(0, 1)} записан(а) на прием
              </span>
              <span className={Styles.date}>{dateAppointment}</span>
            </div>
            <Link className={Styles.choose} to={`/appointments/${dataAppointment.id}`}>
              Перейти к записи
            </Link>
          </div>
        </DoctorPopupMobileVH>
      )}
    </div>
  )
}
