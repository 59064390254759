import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import { apiTypes } from '@app/apiTypes'
import { api } from '@app/api'

export const getListAppointments: any = createAsyncThunk('doctor/getListAppointments', async () => {
  return await api.get('appointments/')
})

export const getListAppointmentPatient: any = createAsyncThunk(
  'doctor/getListAppointmentPatient',
  async (patientId: number) => {
    return await api.get(`appointments/?patient=${patientId}/`)
  },
)

export const getListAppointmentsDate: any = createAsyncThunk('doctor/getListAppointmentsDate', async (data: any) => {
  return await api.get(`appointments/?day_reception_after=${data.day_after}&day_reception_before=${data.day_before}`)
})

export const getListAppointmentsPatientDate: any = createAsyncThunk(
  'doctor/getListAppointmentsPatientDate',
  async (data: any) => {
    return await api.get(
      `appointments/?patient=${data.patientId}&day_reception_after=${data.dayAfter}&day_reception_before=${data.dayBefore}`,
    )
  },
)

export const getListAppointmentsStatusOrdering: any = createAsyncThunk(
  'doctor/getListAppointmentsStatusOrdering',
  async (data: any) => {
    return await api.get(`appointments/?status=${data.status}&status=cancel&ordering=${data.ordering}`)
  },
)

export const getListAppointmentPatientOrdering: any = createAsyncThunk(
  'doctor/getListAppointmentPatientOrdering',
  async (data: any) => {
    return await api.get(`appointments/?patient=${data.patientId}&ordering=${data.ordering}`)
  },
)

export const createAppointment: any = createAsyncThunk('doctor/createAppointment', async (data: any) => {
  return await api.post('appointments/', data)
})

export const getAppointment: any = createAsyncThunk('doctor/getAppointment', async (appointmentId: number) => {
  return await api.get(`appointments/${appointmentId}/`)
})

export const changeAppointment: any = createAsyncThunk('doctor/changeAppointment', async (obj: any) => {
  return await api.put(`appointments/${obj.id}/`, obj.data)
})

export const changeFieldAppointment: any = createAsyncThunk('doctor/changeFieldAppointment', async (data: any) => {
  return await api.patch(`appointments/${data}/`, data)
})

export const deleteAppointment: any = createAsyncThunk('doctor/deleteAppointment', async (appointmentId: number) => {
  return await api.delete(`appointments/${appointmentId}/`)
})

export const startAppointment: any = createAsyncThunk('doctor/startAppointment', async (appointmentId: number) => {
  return await api.post(`appointments/${appointmentId}/start/`, appointmentId)
})

export const doneAppointment: any = createAsyncThunk('doctor/doneAppointment', async (appointmentId: number) => {
  return await api.post(`appointments/${appointmentId}/done/`, appointmentId)
})

export const cancelAppointment: any = createAsyncThunk('doctor/cancelAppointment', async (appointmentId: number) => {
  return await api.post(`appointments/${appointmentId}/cancel/`, appointmentId)
})

export const uploadFilesAppointment: any = createAsyncThunk('doctor/uploadFilesAppointment', async (data: any) => {
  return await api.post(`appointments/${data}/upload/`, data)
})

export const getListPatients: any = createAsyncThunk('doctor/getListPatients', async () => {
  return await api.get('user/users/')
})

export const getPatient: any = createAsyncThunk('doctor/getPatient', async (patientId: number) => {
  return await api.get(`user/users/${patientId}/`)
})

export const createMedicalReport: any = createAsyncThunk('doctor/createMedicalReport', async (data: any) => {
  return await api.post('medical-report/', data)
})

export const getDoctorCalendarToday: any = createAsyncThunk('doctor/getDoctorCalendarToday', async (data: any) => {
  return await api.get('time/calendar/doctor/', data)
})

export const getDoctorCalendarWeek: any = createAsyncThunk('doctor/getDoctorCalendarWeek', async (data: any) => {
  return await api.get('time/calendar/doctor/', data)
})

export const getDoctorCalendarMonth: any = createAsyncThunk('doctor/getDoctorCalendarMonth', async (data: any) => {
  return await api.get('time/calendar/doctor/', data)
})

export const getConsultationDirection: any = createAsyncThunk('doctor/getConsultationDirection', async () => {
  return await api.get('consultations/')
})

export const getConsultationType: any = createAsyncThunk('doctor/getConsultationType', async () => {
  return await api.get('consultations/types/')
})

export const getListNotifications: any = createAsyncThunk('doctor/getListNotifications', async () => {
  return await api.get('notifications/')
})

export const getBlackLists: any = createAsyncThunk('doctor/getBlackList', async () => {
  return await api.get('time/blacklist/')
})

export const deleteBlackList: any = createAsyncThunk('doctor/deleteBlackList', async (blacklistId: number) => {
  return await api.delete(`time/blacklist/${blacklistId}/`)
})

export const createBlackList: any = createAsyncThunk('doctor/createBlackList', async (data: any) => {
  return await api.post('time/blacklist/', data)
})

export const getDietReport: any = createAsyncThunk('doctor/getDietReport', async (reportId: number) => {
  return await api.get(`diet-report/${reportId}/`)
})

export const getLifestyleReport: any = createAsyncThunk('doctor/getLifestyleReport', async (reportId: number) => {
  return await api.get(`lifestyle-report/${reportId}/`)
})

export const getNutraceuticalReport: any = createAsyncThunk(
  'doctor/getNutraceuticalReport',
  async (reportId: number) => {
    return await api.get(`nutraceutical-report/${reportId}/`)
  },
)

export const getResearchReport: any = createAsyncThunk('doctor/getResearchReport', async (reportId: number) => {
  return await api.get(`research-report/${reportId}/`)
})

export const doctorSlice = createSlice({
  name: 'doctor',
  initialState: {
    status: null,
    error: null,
    loading: false,
    displayModal: false,
    isTransferModal: false,
    transferRecord: null,
    completeTransferRecord: null,
    listAppointments: [],
    appointmentsDay: [],
    appointmentsToday: [],
    appointmentsWeek: [],
    appointmentsMonth: [],
    listAppointmentsDone: [],
    currentAppointment: {},
    lastAppointmentPatient: {},
    currentAppointmentsPatient: [],
    dataAppointment: {
      doctor: 1,
      consultation_type: null,
      is_free: false,
      doctor_appointed: true,
      description: '',
      is_online: true,
      direction: null,
      time_reception: '',
      day_reception: '',
    },
    dateAppointment: '',
    allPatients: [],
    listPatients: [],
    listUniqPatients: [],
    currentPatient: {},
    listConsultationType: [],
    listConsultationDirection: [],
    filterIndex: 0,
    filter: ['day', 'week', 'month'],
    selectedAppointmentId: null,
    selectedDayMonth: null,
    blackList: [],
    displayProfileContent: 'info',
    dietReports: [],
    lifestyleReports: [],
    nutraceuticalsReports: [],
    researchReports: [],
    isShowDrawer: false,
  } as unknown as any, // тут будет тип из apiTypes
  reducers: {
    setStatus: (state, { payload }) => {
      state.status = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setModal: (state, { payload }) => {
      state.displayModal = payload
    },
    setIsTransferModal: (state, { payload }) => {
      state.isTransferModal = payload
    },
    setTransferRecord: (state, { payload }) => {
      state.transferRecord = payload
    },
    setCompleteTransferRecord: (state, { payload }) => {
      state.completeTransferRecord = payload
    },
    filterChange: (state, { payload }) => {
      state.filterIndex = payload
    },
    filterForward: (state) => {
      if (state.filterIndex === state.filter.length - 1) {
        state.filterIndex = 0
      } else {
        state.filterIndex++
      }
    },
    filterBackward: (state) => {
      if (state.filterIndex === 0) {
        state.filterIndex = state.filter.length - 1
      } else {
        state.filterIndex--
      }
    },
    setLastAppointmentPatient: (state, { payload }) => {
      state.lastAppointmentPatient = payload
    },
    setCurrentAppointmentsPatient: (state, { payload }) => {
      state.currentAppointmentsPatient = payload
    },
    setDataAppointment: (state, { payload }) => {
      state.dataAppointment = payload
    },
    setDateAppointment: (state, { payload }) => {
      state.dateAppointment = payload
    },
    setAllPatients: (state, { payload }) => {
      state.allPatients = payload
    },
    setListUniqPatients: (state, { payload }) => {
      state.listUniqPatients = payload
    },
    setSelectedAppointmentId: (state, { payload }) => {
      state.selectedAppointmentId = payload
    },
    setSelectedDayMonth: (state, { payload }) => {
      state.selectedDayMonth = payload
    },
    setDisplayProfileContent: (state, { payload }) => {
      state.displayProfileContent = payload
    },
    setDietReports: (state, { payload }) => {
      state.dietReports = payload
    },
    setLifestyleReports: (state, { payload }) => {
      state.lifestyleReports = payload
    },
    setNutraceuticalsReports: (state, { payload }) => {
      state.nutraceuticalsReports = payload
    },
    setResearchReports: (state, { payload }) => {
      state.researchReports = payload
    },
    setIsShowDrawer: (state, { payload }) => {
      state.isShowDrawer = payload
    },
    setCurrentPatient: (state, { payload }) => {
      state.currentPatient = payload
    },
  },
  extraReducers: {
    [getListAppointments.pending]: (state) => {
      state.status = 'loading'
    },
    [getListAppointments.fulfilled]: (state, { payload }) => {
      state.listAppointments = payload.data.results

      const array = payload.data.results.filter(
        (appointments) => new Date(appointments.day_reception).getDate() === new Date().getDate(),
      )

      const activeArray = array.filter((appointments) => appointments.status !== 'done')
      const doneArray = array.filter((appointments) => appointments.status === 'done')

      const activeSortedArray = activeArray.sort((a, b) => a.time_reception.localeCompare(b.time_reception))
      const doneSortedArray = doneArray.sort((a, b) => a.time_reception.localeCompare(b.time_reception))

      state.appointmentsDay = [...activeSortedArray, ...doneSortedArray]
      state.status = 'success'
    },
    [getListAppointments.rejected]: (state) => {
      state.status = 'error'
    },
    [getListAppointmentPatient.fulfilled]: (state) => {
      state.status = 'success'
    },
    [getListAppointmentPatient.rejected]: (state) => {
      state.status = 'error'
    },
    [getListAppointmentsDate.fulfilled]: (state, { payload }) => {
      const activeArray = payload.data.results.filter((appointments) => appointments.status !== 'done')
      const doneArray = payload.data.results.filter((appointments) => appointments.status === 'done')

      const activeSortedArray = activeArray.sort((a, b) => a.time_reception.localeCompare(b.time_reception))
      const doneSortedArray = doneArray.sort((a, b) => a.time_reception.localeCompare(b.time_reception))

      state.appointmentsDay = [...activeSortedArray, ...doneSortedArray]
      state.status = 'success'
    },
    [getListAppointmentsDate.rejected]: (state) => {
      state.status = 'error'
    },
    [getListAppointmentsPatientDate.fulfilled]: (state) => {
      state.status = 'success'
    },
    [getListAppointmentsPatientDate.rejected]: (state) => {
      state.status = 'error'
    },
    [getListAppointmentsStatusOrdering.fulfilled]: (state, { payload }) => {
      state.listAppointmentsDone = payload.data.results
      state.status = 'success'
    },
    [getListAppointmentsStatusOrdering.rejected]: (state) => {
      state.status = 'error'
    },
    [getListAppointmentPatientOrdering.fulfilled]: (state) => {
      state.status = 'success'
    },
    [getListAppointmentPatientOrdering.rejected]: (state) => {
      state.status = 'error'
    },
    [createAppointment.fulfilled]: (state) => {
      state.status = 'success'
    },
    [createAppointment.rejected]: (state) => {
      state.status = 'error'
    },
    [getAppointment.fulfilled]: (state, { payload }) => {
      state.currentAppointment = payload.data
      state.status = 'success'
    },
    [getAppointment.rejected]: (state) => {
      state.status = 'error'
    },
    [changeAppointment.fulfilled]: (state) => {
      state.status = 'success'
    },
    [changeAppointment.rejected]: (state) => {
      state.status = 'error'
    },
    [changeFieldAppointment.fulfilled]: (state) => {
      state.status = 'success'
    },
    [changeFieldAppointment.rejected]: (state) => {
      state.status = 'error'
    },
    [deleteAppointment.fulfilled]: (state) => {
      state.status = 'success'
    },
    [deleteAppointment.rejected]: (state) => {
      state.status = 'error'
    },
    [startAppointment.fulfilled]: (state) => {
      state.status = 'success'
    },
    [startAppointment.rejected]: (state) => {
      state.status = 'error'
    },
    [uploadFilesAppointment.fulfilled]: (state) => {
      state.status = 'success'
    },
    [uploadFilesAppointment.rejected]: (state) => {
      state.status = 'error'
    },
    [getListPatients.fulfilled]: (state, { payload }) => {
      state.listPatients = payload.data.results

      const obj = {}

      payload.data.results.forEach((patient) => {
        obj[patient.id] = patient
      })
      state.listUniqPatients = Object.keys(obj)?.map((id) => {
        return obj[id]
      })
      state.status = 'success'
    },
    [getListPatients.rejected]: (state) => {
      state.status = 'error'
    },
    [getPatient.fulfilled]: (state, { payload }) => {
      state.currentPatient = payload.data
      state.status = 'success'
    },
    [getPatient.rejected]: (state) => {
      state.status = 'error'
    },
    [createMedicalReport.fulfilled]: (state) => {
      state.status = 'success'
    },
    [createMedicalReport.rejected]: (state) => {
      state.status = 'error'
    },
    [getDoctorCalendarToday.fulfilled]: (state, { payload }) => {
      state.appointmentsToday = payload.data
      state.status = 'success'
    },
    [getDoctorCalendarToday.rejected]: (state) => {
      state.status = 'error'
    },
    [getDoctorCalendarWeek.fulfilled]: (state, { payload }) => {
      state.appointmentsWeek = payload.data
      state.status = 'success'
    },
    [getDoctorCalendarWeek.rejected]: (state) => {
      state.status = 'error'
    },
    [getDoctorCalendarMonth.fulfilled]: (state, { payload }) => {
      state.appointmentsMonth = payload.data
      state.status = 'success'
    },
    [getDoctorCalendarMonth.rejected]: (state) => {
      state.status = 'error'
    },
    [getConsultationDirection.fulfilled]: (state, { payload }) => {
      state.listConsultationDirection = payload.data.results
      state.status = 'success'
    },
    [getConsultationDirection.rejected]: (state) => {
      state.status = 'error'
    },
    [getConsultationType.fulfilled]: (state, { payload }) => {
      state.listConsultationType = payload.data.results
      state.status = 'success'
    },
    [getConsultationType.rejected]: (state) => {
      state.status = 'error'
    },
    [getListNotifications.fulfilled]: (state) => {
      state.status = 'success'
    },
    [getListNotifications.rejected]: (state) => {
      state.status = 'error'
    },
    [getBlackLists.fulfilled]: (state, { payload }) => {
      state.blackList = payload.data.results
      state.status = 'success'
    },
    [getBlackLists.rejected]: (state) => {
      state.status = 'error'
    },
    [deleteBlackList.fulfilled]: (state) => {
      state.status = 'success'
    },
    [deleteBlackList.rejected]: (state) => {
      state.status = 'error'
    },
    [createBlackList.fulfilled]: (state) => {
      state.status = 'success'
    },
    [createBlackList.rejected]: (state) => {
      state.status = 'error'
    },
    [getDietReport.fulfilled]: (state) => {
      state.status = 'success'
    },
    [getDietReport.rejected]: (state) => {
      state.status = 'error'
    },
    [getLifestyleReport.fulfilled]: (state) => {
      state.status = 'success'
    },
    [getLifestyleReport.rejected]: (state) => {
      state.status = 'error'
    },
    [getNutraceuticalReport.fulfilled]: (state) => {
      state.status = 'success'
    },
    [getNutraceuticalReport.rejected]: (state) => {
      state.status = 'error'
    },
    [getResearchReport.fulfilled]: (state) => {
      state.status = 'success'
    },
    [getResearchReport.rejected]: (state) => {
      state.status = 'error'
    },
  },
})

const { actions, reducer } = doctorSlice

export const {
  setStatus,
  setLoading,
  setModal,
  filterChange,
  filterForward,
  filterBackward,
  setLastAppointmentPatient,
  setCurrentAppointmentsPatient,
  setDataAppointment,
  setDateAppointment,
  setAllPatients,
  setListUniqPatients,
  setSelectedAppointmentId,
  setSelectedDayMonth,
  setDisplayProfileContent,
  setDietReports,
  setLifestyleReports,
  setNutraceuticalsReports,
  setResearchReports,
  setIsShowDrawer,
  setIsTransferModal,
  setTransferRecord,
  setCompleteTransferRecord,
  setCurrentPatient,
} = actions

export default reducer
