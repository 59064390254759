import sctollToTop from '@helpers/scrollToTop'
import React, { useEffect, useRef } from 'react'
import { GoBackButton } from '../GoBackButton'
import Styles from './style.module.scss'

export const ReturnPage = (): React.ReactElement => {
  const component = useRef<HTMLDivElement>(null)
  useEffect(() => {
    sctollToTop(component, 90)
  }, [])
  return (
    <div className={Styles.container} ref={component}>
      <GoBackButton />
      <h2 className={Styles.header}>Возврат</h2>
      <h4>Лекарственные препараты и медицинские изделия</h4>
      <p>
        В связи с Постановлением Правительства РФ ОТ 19 января 1998 г. № 55 лекарственные препараты и медицинские
        изделия надлежащего качества нельзя обменять и/или вернуть. Во избежании недоразумений, пожалуйста, вскрывайте и
        проверяйте комплектацию вашего заказа в присутствии курьера. В противоположном случае претензии к рассмотрению
        не принимаются. Если вы обнаружите недостачу продукта или его ненадлежащий товарный вид, обратитесь к курьеру,
        который в вашем присутствии составит Акт об обнаружении недостачи или повреждении товара. Затем мы выполним
        замену товара за счет компании. Если у вас возникли вопросы, свяжитесь с нашим клиентским сервисом по телефону
        или электронной почте.
      </p>
      <p>
        В соответствии с п.4. ст.26.1. Закона РФ № 2300-I &Prime;О Защите прав потребителей&Prime;, Покупатель вправе
        отказаться от заказанного Товара в любое время до момента исполнения Заказа.
      </p>
      <p>
        Покупатель не вправе отказаться от оплаченного Заказа (или его части) надлежащего качества, имеющего
        индивидуально определённые свойства.
      </p>
      <p>
        В соответствии со ст. 22 Закона РФ № 2300-I &Prime;О Защите прав потребителей&Prime;, уплаченная Покупателем
        сумма за Товар ненадлежащего качества подлежит возврату Покупателю в течение 10 календарных дней с момента
        предъявления соответствующего требования. На некоторые группы Товаров срок возврата денежных средств может быть
        увеличен в связи установлением характера несоответствия Товара установленным стандартам качества. Уплаченная
        Покупателем сумма за Товар ненадлежащего качества подлежит возврату только после получения Товара ненадлежащего
        качества Продавцом. Пересылка Товара ненадлежащего качества осуществляется за счет Покупателя.
      </p>
      <p>
        В случае доставки Продавцом Товара ненадлежащего качества, Покупатель обязуется предоставить Товар Продавцу в
        максимально короткие сроки с момента покупки для осуществления проверки качества Товара, т.е. не позднее 4-х
        рабочих дней с момента получения Товара Покупателем.
      </p>
      <p>
        В случае если Покупателю передается Товар с нарушением условий Договора, касающихся количества, ассортимента,
        качества, комплектности, упаковки Товара, Покупатель может не позднее 14 дней после получения Товара известить
        Продавца об этих нарушениях.
      </p>
      <p>
        В соответствии с п.3 &Prime;Перечня непродовольственных товаров надлежащего качества, не подлежащих возврату или
        обмену на аналогичный товар других размера, формы, габарита, фасона, расцветки или комплектации&Prime;,
        утвержденного Постановлением Правительства РФ от 19.01.1998 г. № 55 лекарственные препараты и медицинские
        изделия возврату и обмену не подлежат.
      </p>
      <p>
        Возврат или обмен Товара надлежащего качества возможен при условии сохранности его товарного вида,
        потребительских свойств, а также документа, подтверждающего факт и условия покупки указанного Товара.
      </p>
      <p>
        Возврат денежных средств, уплаченных за Товар ненадлежащего качества, а также обмен Товара или возврат денежных
        средств, оплаченных путем безналичного перевода на расчетный счет Продавца осуществляется любым способом по
        договоренности между Продавцом и Покупателем.
      </p>
      <h4>Услуги</h4>
      <p>
        Пользователь вправе до начала оказания услуги Администрацией отказаться от выполнения условий Договора-оферты,
        направив Администрации заявление об отказе в исполнении Договора-оферты на электронную почту Администрации
        info@persolab.ru. В случае отказа Пользователя от выбранной и оплаченной Услуги после заключения договора-оферты
        от получения услуг договор расторгается.
      </p>
      <p>
        Заявление об отказе в исполнении настоящего Договора-оферты Пользователь составляет в простой письменной форме
        на имя генерального директора ООО «Вивамед», удостоверяет личной подписью и указывает следующие данные:
      </p>
      <ul>
        <li>– ФИО пользователя</li>
        <li>– реквизиты документа, удостоверяющего личность гражданина РФ (паспорта);</li>
        <li>– адрес регистрации по месту жительства;</li>
        <li>– данные карты (последние 4 цифры) с которой осуществлялось списание денежных средств за услугу;</li>
        <li>– адрес электронной почты Пользователя.</li>
      </ul>
      <p>
        При условии направленного в адрес Администрации сайта заявления об отказе от исполнения настоящего договора
        более чем за 72 часа до назначенной даты, Пользователь вправе предъявить требование к Администрации о возврате
        полной суммы предварительно оплаченной услуги.
      </p>
      <p>
        Пользователь, отказавшийся от исполнения условий договора менее чем за 72 часа до назначенной даты, оплачивает
        Администрации фактически понесенные расходы, связанные с исполнением обязательств по договору. К таким расходам
        относится оплаченное Пользователем рабочее время специалиста, ожидающего Пользователя на консультативный прием.
      </p>
    </div>
  )
}
