import declination from '@helpers/declination'
import React from 'react'
import Styles from './style.module.scss'

interface CatalogViewItemProps {
  title: string
  count: number
  image: any
  active: boolean
  onClick: any
}

export const CatalogViewItem: React.FC<CatalogViewItemProps> = (props) => {
  const { title, count, image, active, onClick } = props

  return (
    <div className={active ? `${Styles.item} ${Styles.item_active}` : `${Styles.item}`} onClick={onClick}>
      <span className={Styles.title}>{title}</span>
      <span className={Styles.subtitle}>
        {count ? declination(count, 'товар', 'товара', 'товаров') : 'Нет товаров'}
      </span>
      <img className={Styles.image} src={image} alt='Изображение' />
    </div>
  )
}
