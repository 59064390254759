import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getPointsByCity, setDataDelivery, defaultState, setCityCode, setCity } from './redux/deliverySlice'
import { searchCity, postDeliveryCalc } from '../../../redux/cartSlicer'
import { Spinner } from '@components/Spinner'
import { CustomRadioInput } from '../../../../../pages/appointment-page/components/TypeOfConsultation/components/CustomRadioInput'
import { PointDelivery } from './components/PointDelivery'
import { CourierDelivery } from './components/CourierDelivery'
// import { PostDelivery } from './components/PostDelivery'
import store from '@icons/store.svg'
import Styles from './style.module.scss'
import { changeUserInfo } from '../../../../authorization/redux/authSlice'

interface DeliveryCartProps {
  goToPay?: () => void
  analysis?: number
  goToResearch?: () => void
  savedDataDelivery?: () => void
}

export const DeliveryCart: React.FC<DeliveryCartProps> = ({ goToPay, analysis, goToResearch, savedDataDelivery }) => {
  const dispatch = useAppDispatch()
  const { dataDelivery } = useAppSelector((state) => state.delivery)
  const user = useAppSelector((state) => state.auth.user.info)
  const [value, setValue] = useState(user.city)
  const [foundCities, setFoundCities] = useState([])
  const [isCitiesList, setIsCitiesList] = useState(false)
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handlerChangeRadio = (event) => {
    dispatch(setDataDelivery({ ...dataDelivery, type: event.target.id }))
  }

  const deliveryCounterPvz = () => {
    const a = dataDelivery.period.filter((item) => item.type === 'pvz')
    return `${a[0].period_min} - ${a[0].period_max} дней, от ${a[0].delivery_sum}`
  }
  const deliveryCounterCur = () => {
    const a = dataDelivery.period.filter((item) => item.type === 'cur')
    return `${a[0].period_min} - ${a[0].period_max} дней, от ${a[0].delivery_sum}`
  }
  useEffect(() => {
    if (user.city_code) {
      const resPoints = dispatch(getPointsByCity({ cityCode: user.city_code }))
      const resPeriod = dispatch(postDeliveryCalc({ location_code: user.city_code }))
      Promise.all([resPoints, resPeriod]).then((res) => {
        dispatch(
          setDataDelivery({
            ...defaultState,
            type: dataDelivery.type,
            pointData: {
              location: dataDelivery.pointData.location,
            },
            courierData: {
              street: dataDelivery.courierData.street,
              building: dataDelivery.courierData.building,
              korpus: dataDelivery.courierData.korpus,
              appartment: dataDelivery.courierData.appartment,
              postal: dataDelivery.courierData.postal,
              door: dataDelivery.courierData.door,
              floor: dataDelivery.courierData.floor,
            },
            city: user.city,
            points: res[0].payload,
            period: res[1].payload,
            comment: dataDelivery.comment,
          }),
        )
      })
    }
  }, [])
  const showCitiesList = () => {
    setIsCitiesList(true)
  }

  const hideCitiesList = () => {
    setIsCitiesList(false)
  }

  const getCities = async (value) => {
    try {
      const res = await dispatch(searchCity(value))
      setFoundCities(res.payload)
    } catch (error) {
      // console.log(error)
    }
  }
  const handlerChangeCity = async (e) => {
    const { value } = e.target
    setValue(value)
    if (value.length > 1) {
      getCities(value)
    }
  }

  const handlerFocusCity = () => {
    showCitiesList()
  }

  const handlerClear = () => {
    setValue('')
    setFoundCities([])
    setErrors([])
    dispatch(setDataDelivery({ ...defaultState }))
  }

  const handlerChoose = async (city) => {
    setValue(city.title)
    setIsLoading(true)
    hideCitiesList()

    try {
      const resPoints = await dispatch(getPointsByCity({ cityCode: city.code }))
      const resPeriod = await dispatch(postDeliveryCalc({ location_code: city.code }))

      const errors = []

      for (const item of resPeriod.payload) {
        if (Object.keys(item).includes('errors')) {
          errors.push(item.type)
        }
      }

      if (errors.length !== 0) {
        setErrors(errors)
      } else {
        setErrors([])
      }

      dispatch(
        setDataDelivery({
          ...defaultState,
          city,
          points: resPoints.payload,
          period: resPeriod.payload,
        }),
      )
      dispatch(setCityCode(city.code))
      dispatch(setCity(city.title))
      dispatch(changeUserInfo({ city: city.title, city_code: city.code }))
    } catch (error) {
      // console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={Styles.container}>
      <label className={Styles.label}>Населенный пункт</label>
      <div className={Styles.search}>
        <input
          onChange={handlerChangeCity}
          onFocus={handlerFocusCity}
          type='text'
          value={value}
          placeholder='Поиск города'
        />
        {value ? (
          <button className={Styles.clearBtn} onClick={handlerClear}>
            {}
          </button>
        ) : (
          <button className={Styles.searchBtn}>{}</button>
        )}
        {foundCities.length !== 0 && isCitiesList && (
          <>
            <div className={Styles.outListClickCatcher} onClick={hideCitiesList}>
              {}
            </div>
            <ul className={Styles.citiesList}>
              {foundCities.map((city) => (
                <li key={city.code} className={Styles.citiesItem}>
                  <button className={Styles.citiesItemBtn} onClick={() => handlerChoose(city)}>
                    {`${city.title} (${city.region})`}
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      {isLoading ? (
        <div className={Styles.loading}>
          <Spinner />
        </div>
      ) : (
        <>
          {dataDelivery.city && (
            <div className={Styles.checkbox__container}>
              {!errors.includes('pvz') &&
                dataDelivery.points &&
                dataDelivery.points.length > 0 &&
                dataDelivery.period &&
                dataDelivery.period.length > 0 && (
                  <div className={Styles.checkbox}>
                    <CustomRadioInput
                      onChange={handlerChangeRadio}
                      name='delivery'
                      htmlFor='point'
                      id='point'
                      title='Пункт выдачи'
                      src={store}
                      price={deliveryCounterPvz()}
                      smallSize
                      checked={dataDelivery.type === 'point'}
                    />
                  </div>
                )}
              {!errors.includes('сur') && dataDelivery.period && dataDelivery.period.length > 0 && (
                <div className={Styles.checkbox}>
                  <CustomRadioInput
                    onChange={handlerChangeRadio}
                    name='delivery'
                    htmlFor='courier'
                    id='courier'
                    title='Курьером'
                    src={store}
                    price={deliveryCounterCur()}
                    smallSize
                    checked={dataDelivery.type === 'courier'}
                  />
                </div>
              )}
              {/* {!errors.includes('pst') && (
                <div className={Styles.checkbox}>
                  <CustomRadioInput
                    onChange={handlerChangeRadio}
                    name='delivery'
                    htmlFor='post'
                    id='post'
                    title='Почтой'
                    src={store}
                    price='5−6 дней, от 600'
                    smallSize
                  />
                </div>
              )} */}
              {errors.includes('pvz') && errors.includes('сur') && (
                <span className={Styles.error}>
                  По этому адресу доставка не осуществляется, попробуйте выбрать другой
                </span>
              )}
            </div>
          )}
          {dataDelivery.city && !errors.includes('pvz') && dataDelivery.type === 'point' && (
            <PointDelivery
              goToPay={goToPay}
              analysis={analysis}
              goToResearch={goToResearch}
              savedDataDelivery={savedDataDelivery}
            />
          )}
          {dataDelivery.city && !errors.includes('сur') && dataDelivery.type === 'courier' && (
            <CourierDelivery
              goToPay={goToPay}
              analysis={analysis}
              goToResearch={goToResearch}
              savedDataDelivery={savedDataDelivery}
            />
          )}
          {/* {dataDelivery.city && !errors.includes('pst') && dataDelivery.type === 'post' && (
            <PostDelivery goToPay={goToPay} />
          )} */}
        </>
      )}
    </div>
  )
}
