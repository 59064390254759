import React from 'react'
import Select from '@mui/material/Select'
import icon from '@icons/arrow-small.svg'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'

const StyledSelect = styled(Select)(() => ({
  width: '199px',
  display: 'flex',
  '& .MuiOutlinedInput-notchedOutline': {
    display: 'flex',
    border: 'none',
  },
  '&.Mui-focused': {
    outlineStyle: 'none',
  },
  '&.MuiSelect-select .MuiSelect-icon': {
    transform: 'rotate(90deg)',
  },
  '& .MuiSelect-icon': {
    transform: 'rotate(-90deg)',
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  '& .MuiSelect-select': {
    width: '100%',
    hight: '40px',
    padding: '10px 20px',
    color: ' #4B5B75',
    fontFamily: 'inherit',
    background: 'transparent',
    border: '2px solid #DEE7F8',
    borderRadius: '130px',
    '&:hover': {
      backgroundColor: '#F6FAFF',
      borderRadius: '130px',
    },
    '&$selected': {
      backgroundColor: 'transparent',
      borderRadius: '130px',
    },
    '&:focus': {
      backgroundColor: '#F6FAFF',
      borderRadius: '130px',
    },
    '&.Mui-focused': {
      backgroundColor: 'red',
      border: 'none',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'green',
      },
    },
  },
}))

export const SelectComponent = (props) => {
  const [value, setValue] = React.useState(props.value1)
  const handleChange = (event) => {
    setValue(event.target.value)
    props.setValueSelect(event.target.value)
  }
  const iconComponent = ({ className }) => {
    className = className.replace('MuiSelect-iconOpen', '')
    return <img src={icon} className={className} alt='img' />
  }
  return (
    <StyledSelect
      IconComponent={iconComponent}
      value={value}
      onChange={handleChange}
      disableUnderline
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      defaultValue={value}
    >
      {props.value1 && (
        <MenuItem value={props.value1} selected>
          {props.value1}
        </MenuItem>
      )}
      {props.value2 && <MenuItem value={props.value2}>{props.value2}</MenuItem>}
      {props.value3 && <MenuItem value={props.value3}>{props.value3}</MenuItem>}
      {props.value4 && <MenuItem value={props.value4}>{props.value4}</MenuItem>}
    </StyledSelect>
  )
}
