import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'
import createIcon from '@icons/clock.svg'
import ok from '@icons/ok-white.svg'
import moment from 'moment'
import { DrawerPanel } from '@components/Drawer'
import close from '@icons/close-blue.svg'
import arrow from '@icons/arrow-large-blue.svg'

interface DoctorVisitProps {
  title: string
  subtitle: string
  date: any
  conclusionIsReady: boolean
  time: string
  id: any
  status: string
  detail: string
  absence: boolean
  notPaid: boolean
}

export const PastAppointment: React.FC<DoctorVisitProps> = (props) => {
  const { title, subtitle, date, time, conclusionIsReady, id, status, detail, absence, notPaid } = props
  const dateFomat = moment(date, 'YYYY-MM-DD').format('DD MMMM, dddd')
  const [reasonDefault, setReasonDefault] = useState('')
  const [reasonCustomText, setReasonCustomText] = useState('')
  const [reasonCustomButton, setReasonCustomButton] = useState(false)
  const [showReasonDrawer, setShowReasonDrawer] = useState(false)

  const reason = () => {
    if (absence) {
      setReasonDefault('неявка')
    }
    if (notPaid) {
      setReasonDefault('нет оплаты')
    }
    if (notPaid && absence) {
      setReasonDefault('неявка, нет оплаты')
    }
    if (detail.length > 0) {
      setReasonCustomText(detail)
      setReasonCustomButton(true)
    }
  }
  useEffect(() => {
    reason()
  }, [])
  return (
    <>
      <li className={Styles.visit}>
        <div className={Styles.block}>
          <span className={`${Styles.title} ${Styles.title_mod}`}>{title}</span>
          <span className={Styles.subtitle}>{subtitle}</span>
        </div>
        <div className={Styles.block}>
          <span className={Styles.title}>{dateFomat}</span>
          <span className={Styles.subtitle}>{time}</span>
        </div>
        <div className={Styles.block}>
          <div className={Styles.conclusion}>
            {!conclusionIsReady ? (
              <>
                {status === 'done' ? (
                  <span className={Styles.text}>
                    <img src={createIcon} alt='Иконка' />
                    Врач готовит заключение
                  </span>
                ) : (
                  <div className={Styles.reason}>
                    <span>Причина: {reasonDefault}</span>
                    {reasonCustomButton && (
                      <button onClick={() => setShowReasonDrawer(true)}>узнать подробности…</button>
                    )}
                  </div>
                )}
              </>
            ) : (
              <>
                <Link className={`${Styles.text} ${Styles.text_mod}`} to={`/my-appointment/${id}`}>
                  Смотреть заключение
                </Link>
              </>
            )}
          </div>
          {conclusionIsReady && (
            <span className={Styles.conclusionIsReady}>
              <img src={ok} alt='ok' />
              Заключение готово
            </span>
          )}
          {(status === 'cancel' || status === 'not_payed') && <span className={Styles.cancel}>Прием отменен</span>}
        </div>
      </li>
      <DrawerPanel width='100%' anchor='right' open={showReasonDrawer} onClose={() => setShowReasonDrawer(false)}>
        <div className={Styles.container}>
          <div className={Styles.navigation}>
            <button className={Styles.arrow} onClick={() => setShowReasonDrawer(false)}>
              <img src={arrow} alt='img' />
            </button>

            <h3 className={Styles.title__drawer}>Причина отказа</h3>
            <button className={Styles.close} onClick={() => setShowReasonDrawer(false)}>
              <img src={close} alt='close' />
            </button>
          </div>
          <h2>Отказ</h2>
          <p>от {dateFomat}</p>
          <div className={Styles.reason__text}>{reasonCustomText}</div>
        </div>
      </DrawerPanel>
    </>
  )
}
