import React from 'react'
import { AttachedFile } from '../AttachedFile'
import Styles from './style.module.scss'

export const MessageAttachment = ({ message, noMargin = false }) => {
  const FILE_TYPES = {
    image: 1,
  }
  return (
    message.files.length > 0 &&
    message?.files?.map((file) => {
      return (
        <div className={Styles.attachment} key={file.id} style={noMargin ? { marginLeft: '0' } : null}>
          <AttachedFile
            name={file.filename}
            size={file.size}
            date={file.created_at}
            file={file.file}
            type={file.type}
            downloadable={file.type !== FILE_TYPES.image}
          />
        </div>
      )
    })
  )
}
