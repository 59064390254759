import React from 'react'
import Styles from './style.module.scss'
import { useAppSelector, useAppDispatch } from '@app/redux/hooks'
import { openProfileModal } from './redux/profileSlice'
import { setDisplayProfileContent } from '../../pages/doctor/redux/doctorSlicer'
import closeButton from '@icons/close.svg'
import { DrawerPanel } from '@components/Drawer'
import { ProfileModalHeader } from './components/ProfileModalHeader'
import { EditProfileForm } from '@components/EditProfileForm'
import { ProfileModalInput } from './components/ProfileModalInput'
import { DoctorProfile } from '../../pages/doctor/components/DoctorProfile'
import { ProfileCart } from '../../pages/profile/components/ProfileCart'
import moment from 'moment'

export const ProfileModal = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.auth.user.info)
  const isProfileModalOpen: boolean = useAppSelector((state) => state.profile.modal.isModalOpen)

  const closeDoctorProfileModal = () => {
    dispatch(openProfileModal())
    dispatch(setDisplayProfileContent('info'))
  }

  return (
    <DrawerPanel width='100%' anchor='right' open={isProfileModalOpen} onClose={() => dispatch(openProfileModal())}>
      <div className={Styles.container}>
        <div>
          <img
            alt='close'
            src={closeButton}
            className={Styles.closeButton}
            onClick={user.role === 'doctor' ? closeDoctorProfileModal : () => dispatch(openProfileModal())}
          />
        </div>
        <ProfileModalHeader />
        {user.role === 'patient' && (
          <>
            <ProfileCart />
            <EditProfileForm formTitle='Личные данные'>
              <ProfileModalInput
                placeholder={user.first_name}
                labelName='Имя*'
                inputName='first_name'
                inputType='text'
              />
              <ProfileModalInput
                placeholder={user.last_name}
                labelName='Фамилия'
                inputName='last_name'
                inputType='text'
              />
              <ProfileModalInput
                placeholder={user.birthday ? moment(user.birthday).format('DD.MM.YYYY') : '__.__.____'}
                labelName='Дата рождения*'
                inputName='birthday'
                inputType='date'
                defaultValue={user.birthday}
              />
            </EditProfileForm>
            <EditProfileForm formTitle='Контактные данные'>
              <ProfileModalInput placeholder={user.phone} labelName='Телефон*' inputName='phone' inputType='tel' />
              <ProfileModalInput placeholder={user.email} labelName='Email' inputName='email' inputType='email' />
            </EditProfileForm>
          </>
        )}
        {user.role === 'doctor' && <DoctorProfile />}
      </div>
    </DrawerPanel>
  )
}
