import React, { useState } from 'react'
import Styles from './style.module.scss'
import { MapModal } from '../../GoCheckout/components/DeliveryCart/components/Map'
import { ButtonNavigation } from '@components/ui/ButtonNavigation'
import { useAppSelector } from '@app/redux/hooks'
import classNames from 'classnames'

interface MapFragmentProps {
  filter1?: number
  filter2?: number
  modal?: boolean
  mobile?: boolean
}
export const MapFragment: React.FC<MapFragmentProps> = (props) => {
  const cx = classNames.bind(Styles)
  const { dataDelivery } = useAppSelector((state) => state.delivery)
  const [isOpenModal, setModalOpened] = useState(false)

  const containerInputStyle = cx({
    [`${Styles.input__container}`]: !props.modal,
    [`${Styles.input__container_modal}`]: props.modal,
  })

  const openModalMap = (e) => {
    e.preventDefault()
    setModalOpened(!isOpenModal)
  }

  return (
    <div className={Styles.container}>
      {/* <label className={Styles.label}>Введите адрес и мы покажем ближайшие пункты выдачи</label> */}
      <div className={props.modal && Styles.wrapper}>
        <div className={containerInputStyle}>
          <div className={Styles.search}>
            <input type='text' value={dataDelivery.pointData.location.address_full} placeholder='Адрес' readOnly />
            {/* props.mobile && <button /> */}
          </div>
          {!props.modal && <ButtonNavigation onClick={openModalMap} />}
        </div>
      </div>
      <MapModal isOpenModal={isOpenModal} setModalOpened={setModalOpened} />
    </div>
  )
}
