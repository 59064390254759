import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAppDispatch } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import { ProfileCartOrder } from '../ProfileCartOrder'
import { getOrders } from '../../../../pages/orders/redux/ordersSlice'
import { closeProfileModal } from '@components/ProfileModal/redux/profileSlice'
import { MOBILE_BREAKPOINT } from '../../../../global-constants'
import Styles from './style.module.scss'
import 'swiper/swiper.scss'

export const ProfileCart: React.FC = () => {
  const { width } = useWindowSize()

  const dispatch = useAppDispatch()
  const [orders, setOrders] = useState([])
  // console.log(orders)
  const [firstOrder, setFirstOrder] = useState<any>([])

  const closeModal = () => {
    dispatch(closeProfileModal())
  }

  const loadOrders = async () => {
    try {
      const res: any = await dispatch(getOrders(''))

      if (res.payload.results.length !== 0) {
        setFirstOrder(res.payload.results[0])
      }

      setOrders(res.payload.results)
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => {
    loadOrders()
  }, [])

  return (
    <>
      {orders.length !== 0 && (
        <>
          {width > MOBILE_BREAKPOINT ? (
            <div className={Styles.container}>
              <ProfileCartOrder order={firstOrder} closeModal={closeModal} />
              {orders.length > 1 && (
                <div className={Styles.wrapper}>
                  <Link className={Styles.link} to='/orders' onClick={closeModal}>
                    Смотреть еще {orders.length - 1} заказа
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <div className={Styles.slider}>
              <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={10}>
                {orders.map((order) => (
                  <SwiperSlide key={order.id} className={Styles.slide}>
                    <ProfileCartOrder order={order} closeModal={closeModal} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </>
      )}
    </>
  )
}
