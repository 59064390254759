import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import { setView } from '../../redux/catalogSlicer'
import { CatalogViewItem } from './components/CatalogViewItem'
import preview1 from '@images/preview-1.png'
import preview2 from '@images/preview-2.png'
import { MOBILE_BREAKPOINT } from '../../../../global-constants'
import 'swiper/swiper.scss'
import Styles from './style.module.scss'

export const CatalogView: React.FC = () => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const products = useAppSelector((state) => state.catalog.products)
  const analyzes = useAppSelector((state) => state.catalog.analyzes)

  const view = useAppSelector((state) => state.catalog.view)

  return (
    <div className={Styles.view}>
      {width > MOBILE_BREAKPOINT ? (
        <>
          <CatalogViewItem
            title='Нутрицевтики'
            count={products.count}
            image={preview1}
            active={view === 'nutraceuticals'}
            onClick={() => dispatch(setView('nutraceuticals'))}
          />
          <CatalogViewItem
            title='Лабораторные обследования'
            count={analyzes.count}
            image={preview2}
            active={view === 'research'}
            onClick={() => dispatch(setView('research'))}
          />
        </>
      ) : (
        <Swiper className={Styles.swiper} slidesPerView={1.5} spaceBetween={16}>
          <SwiperSlide className={Styles.slide}>
            <CatalogViewItem
              title='Нутрицевтики'
              count={products.count}
              image={preview1}
              active={view === 'nutraceuticals'}
              onClick={() => dispatch(setView('nutraceuticals'))}
            />
          </SwiperSlide>
          <SwiperSlide className={Styles.slide}>
            <CatalogViewItem
              title='Лабораторные обследования'
              count={analyzes.count}
              image={preview2}
              active={view === 'research'}
              onClick={() => dispatch(setView('research'))}
            />
          </SwiperSlide>
        </Swiper>
      )}
    </div>
  )
}
