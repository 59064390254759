import React from 'react'
import Styles from './style.module.scss'
import { TooltipComponent } from '@components/TooltipComponent'
import { BorderLine } from '@components/ui/BorderLine'
import { useAppDispatch } from '@app/redux/hooks'
import { getPaymentLink } from '../../../../../appointment-page/redux/paymentSlice'
import { useSnackbar } from 'notistack'
import { ButtonColor } from '@components/ui/ButtonColor'

export const TotalDetails = (props) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const priceWay = props.data?.is_online ? '0' : '1500'
  const total = Number(props.data?.consultation_type?.price) + Number(priceWay)

  const handlePayment = () => {
    dispatch(getPaymentLink({ appointment: props.data?.id }))
      .then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          window.open(res.payload.url)
        } else {
          const message = `Что-то пошло не так.`
          const type = 'error'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        }
      })
      .catch(() => {
        const message = `Что-то пошло не так. Проверьте соединение с интернетом`
        const type = 'error'
        enqueueSnackbar(
          { message, type },
          {
            autoHideDuration: 1500,
          },
        )
      })
  }

  return (
    <div className={Styles.container}>
      <ul className={Styles.wrap}>
        <li>
          <span className={Styles.item__title}>{props.data?.consultation_type?.title}</span>
          <span className={Styles.item__price}>
            <span />
            {props.data?.consultation_type?.price}
            <span className={Styles.span}>₽</span>
          </span>
        </li>
        <li>
          {props.data?.is_online ? (
            <span className={Styles.item__title}>
              Онлайн
              <TooltipComponent text='При онлайн приеме вы платите только за консультацию' />
            </span>
          ) : (
            <span className={Styles.item__title}>
              Лично
              <TooltipComponent text='Стоимость личного приема' />
            </span>
          )}
          <span className={Styles.item__price}>
            <span />
            {priceWay}
            <span className={Styles.span}>₽</span>
          </span>
        </li>
      </ul>
      <BorderLine />
      {props.data.is_free ? (
        <ul className={Styles.wrap}>
          <li>
            <span className={Styles.total__item}>Бесплатный прием</span>
          </li>
        </ul>
      ) : (
        <ul className={Styles.wrap}>
          <li>
            <span className={Styles.total__item}>{props.data.is_pay ? 'Оплачено' : 'К оплате'}</span>
            <span className={Styles.total__price}>
              <span />
              {total}
              <span className={Styles.span}>₽</span>
            </span>
          </li>
          {!props.data.is_pay && <ButtonColor value='Оплатить' onClick={handlePayment} className={Styles.payment} />}
        </ul>
      )}
    </div>
  )
}
