/* eslint-disable camelcase */
import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getPosts: any = createAsyncThunk('blog/getPosts', async (props: any, { rejectWithValue }) => {
  const { scope, ordering } = props
  try {
    const posts: any = await api.get(`blog/posts/?scope=${scope}&ordering=${ordering}`)
    if (posts.status === 200) {
      return posts.data
    } else {
      throw new Error(posts)
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const getScopes: any = createAsyncThunk('blog/getScopes', async (_, { rejectWithValue }) => {
  try {
    const scopes: any = await api.get(`blog/scopes/`)
    if (scopes.status === 200) {
      return scopes.data
    } else {
      throw new Error(scopes)
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const getStories: any = createAsyncThunk('blog/getStories', async (_, { rejectWithValue }) => {
  try {
    const stories: any = await api.get(`blog/stories/`)
    if (stories.status === 200) {
      return stories.data
    } else {
      throw new Error(stories)
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})
export const getArticle: any = createAsyncThunk('blog/getArticle', async (id) => {
  const response = await api.get<any>(`blog/posts/${id}/`)
  return response as any
})

export const blogSlice = createSlice({
  name: 'blog',
  initialState: {
    posts: {
      count: 0,
      results: [],
    },
    scopes: [],
    loading: false,
    filter: {
      scope: '',
    },
    ordering: 'popularity',
    postsPage: 1,
    postsLimit: 16,
    postsLeft: 0,
    currentPostsQuantity: 0,
    stories: {
      count: 0,
      results: [],
    },
  } as unknown as any, // тут будет тип из apiTypes
  reducers: {
    setOrdering: (state, { payload }) => {
      state.posts = {
        count: 0,
        results: [],
      }
      state.postsPage = 1
      state.ordering = payload
    },
    setFilter: (state, { payload }) => {
      state.loading = true
      state.posts = {
        count: 0,
        results: [],
      }
      state.postsPage = 1
      state.filter.scope = payload
    },
    turnPostPage: (state, { payload }) => {
      state.postsPage += payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPosts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPosts.fulfilled, (state, { payload }) => {
      state.loading = false
      // state.posts = payload
      state.posts = { count: payload.count, results: [...state.posts.results, ...payload.results] }

      if (state.posts.count > state.postsLimit * state.postsPage) {
        state.currentPostsQuantity = state.postsLimit * state.postsPage
        state.postsLeft = state.posts.count - state.currentPostsQuantity
      } else if (state.posts.count < state.postsLimit * state.postsPage) {
        state.currentPostsQuantity =
          state.postsLimit * state.postsPage + (state.posts.count - state.postsLimit * state.postsPage)
        state.postsLeft = state.posts.count - state.currentPostsQuantity
      }
    })
    builder.addCase(getScopes.fulfilled, (state, { payload }) => {
      state.loading = false
      state.scopes = payload
    })
    builder.addCase(getStories.fulfilled, (state, { payload }) => {
      state.loading = false
      state.stories = payload
    })
  },
})

const { actions, reducer } = blogSlice

export const { setOrdering, setFilter } = actions

export default reducer
