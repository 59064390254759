import React, { FC } from 'react'

import styles from './style.module.scss'
import classNames from 'classnames/bind'

interface RegistryLineProps {
  name: string
  value: string
  bold?: boolean
  toltip?: any
}

export const RegistryLine: FC<RegistryLineProps> = ({ name, value, bold, toltip }) => {
  const cx = classNames.bind(styles)
  return (
    <div className={cx(styles.registryLine, bold && styles.registryLine_totalAmount)}>
      <p className={cx(styles.title, bold && styles.title_bold, toltip && styles.title_toltip)}>{name}</p>
      {toltip}
      <div className={styles.dashedSpace} />
      <p className={styles.value}>{value}</p>
    </div>
  )
}
