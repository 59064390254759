/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import { getReviews, postReview, setIsDrawer } from '../../../../../../redux/catalogSlicer'
import cmt from '@icons/commentFB.svg'
import plus from '@icons/plusFB.svg'
import minus from '@icons/minusFB.svg'
import { MOBILE_BREAKPOINT } from '../../../../../../../../global-constants'
import Styles from './style.module.scss'
import { Rating } from '@mui/material'

export const CatalogFeedbackModal = ({ product }) => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const { first_name, last_name, id } = useAppSelector((state) => state.auth.user.info)
  const [loading, setLoading] = useState(false)
  const formdata = new FormData()
  const [rating, setRating] = useState({
    pluses: '',
    minuses: '',
    detail: '',
    user_name: `${first_name} ${last_name || ''}`,
    product: product.id,
    consider_useful: 1,
    consider_not_useful: 2,
    images: [],
    user: id,
    rating: null,
  })

  const [errors, setErrors] = useState(null)
  const buttonDisabled = rating.pluses === '' || rating.minuses === '' || rating.detail === '' || rating.rating === null

  const handleChange = (e) => {
    const { name, value } = e.target
    setRating({ ...rating, [name]: value })
    setErrors({ ...errors, [name]: '' })
  }

  const handlePhotoUpload = (e) => {
    setRating({ ...rating, images: Object.values(e.target.files) })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    formdata.append('pluses', rating.pluses)
    formdata.append('minuses', rating.minuses)
    rating.images.map((image) => formdata.append('images', image))
    formdata.append('detail', rating.detail)
    formdata.append('rating', rating.rating)

    dispatch(postReview({ data: formdata, id: product.id })).then((res) => {
      if (res.payload.status === 200) {
        dispatch(getReviews({ id: product.id })).then(() => closeModal())
      } else if (res.payload.status === 401) {
        setErrors({ unauthorized: 'Только зарегистрированнные пользователи могут оставлять отзывы' })
      } else {
        setErrors(res.payload.data)
      }
      setLoading(false)
    })
  }

  const removeImg = (index) => {
    setRating({ ...rating, images: rating.images.filter((el, _, array) => el !== array[index]) })
  }

  const closeModal = () => dispatch(setIsDrawer(false))

  return (
    <div className={Styles.container}>
      {width > MOBILE_BREAKPOINT ? (
        <div className={Styles.header}>
          <span className={Styles.title}>Оставить отзыв</span>
        </div>
      ) : (
        <div className={`${Styles.header} ${Styles.header_mobile}`}>
          <button className={Styles.back}>{}</button>
          <span className={Styles.title}>Оставить отзыв</span>
          <button className={Styles.close} onClick={closeModal}>
            {}
          </button>
        </div>
      )}
      <div className={Styles.body}>
        <div className={Styles.rating}>
          <div className={Styles.row}>
            <span className={Styles.caption}>Ваша оценка*</span>
          </div>
          <Rating name='rating' defaultValue={0} precision={1} onChange={handleChange} />
          {errors?.rating && <p className={Styles.error}>{errors.rating[0]}</p>}
        </div>
        <form className={Styles.form} onSubmit={handleSubmit}>
          <div className={Styles.blocks}>
            <div className={Styles.block}>
              <div className={Styles.row}>
                <i className={Styles.icon}>
                  <img src={plus} alt='Иконка' />
                </i>
                <span className={Styles.caption}>Плюсы*</span>
              </div>
              <textarea
                className={Styles.textarea}
                placeholder='Что понравилось в товаре?'
                onChange={handleChange}
                name='pluses'
              >
                {}
              </textarea>
              {errors?.pluses && <p className={Styles.error}>{errors.pluses[0]}</p>}
            </div>
            <div className={Styles.block}>
              <div className={Styles.row}>
                <i className={Styles.icon}>
                  <img src={minus} alt='Иконка' />
                </i>
                <span className={Styles.caption}>Минусы*</span>
              </div>
              <textarea
                className={Styles.textarea}
                placeholder='Что не понравилось в товаре?'
                onChange={handleChange}
                name='minuses'
              >
                {}
              </textarea>
              {errors?.minuses && <p className={Styles.error}>{errors.minuses[0]}</p>}
            </div>
            <div className={Styles.block}>
              <div className={Styles.row}>
                <i className={Styles.icon}>
                  <img src={cmt} alt='Иконка' />
                </i>
                <span className={Styles.caption}>Комментарий*</span>
              </div>
              <textarea
                className={Styles.textarea}
                placeholder='Ваши впечатления'
                onChange={handleChange}
                name='detail'
              >
                {}
              </textarea>
              {errors?.detail && <p className={Styles.error}>{errors.detail[0]}</p>}
            </div>
            {width <= 830 && (
              <div className={Styles.block}>
                <div className={Styles.row}>
                  <span className={Styles.caption}>Добавить фото</span>
                </div>
                {rating.images && (
                  <div className={Styles.images}>
                    {rating.images.map((img, index) => {
                      return (
                        <div key={index} className={Styles.image}>
                          <img src={URL.createObjectURL(img)} alt='Изображение' />
                          <i onClick={() => removeImg(index)} className={Styles.remove}>
                            {}
                          </i>
                        </div>
                      )
                    })}
                    <label className={Styles.file}>
                      <input
                        type='file'
                        accept='image/gif, image/jpeg, image/png'
                        multiple
                        name='review_images'
                        onChange={handlePhotoUpload}
                      />
                    </label>
                  </div>
                )}
              </div>
            )}
          </div>
          {width > 830 && (
            <>
              <div className={Styles.photos}>
                <div className={Styles.wrapper}>
                  <label className={Styles.file}>
                    Выберите фото
                    <input
                      type='file'
                      accept='image/gif, image/jpeg, image/png'
                      multiple
                      name='review_images'
                      onChange={handlePhotoUpload}
                      className={Styles.fileInput}
                    />
                  </label>
                  &nbsp;
                  {/* <span className={Styles.or}>или перетащите его сюда</span> */}
                </div>
              </div>
              {rating.images && (
                <div className={Styles.images}>
                  {rating.images.map((img, index) => {
                    return (
                      <div key={index} className={Styles.image}>
                        <img src={URL.createObjectURL(img)} alt='Изображение' />
                        <i onClick={() => removeImg(index)} className={Styles.remove}>
                          {}
                        </i>
                      </div>
                    )
                  })}
                </div>
              )}
            </>
          )}
          <label className={Styles.name}>
            Имя
            <input
              className={Styles.input}
              type='text'
              placeholder='Введите ваше имя'
              onChange={handleChange}
              name='user_name'
              value={rating.user_name}
            />
          </label>
          {errors?.error && <p className={Styles.error}>Вы уже отзывались об этом товаре</p>}
          {errors?.unauthorized && <p className={Styles.error}>{errors.unauthorized}</p>}
          <button disabled={buttonDisabled || loading} className={Styles.button}>
            {loading ? '...Загрузка' : 'Отправить'}
          </button>
        </form>
        <div className={Styles.wrapper}>
          <span className={Styles.text}>Нажимая на кнопку, я даю</span>&nbsp;
          <a className={Styles.link} href='#'>
            согласие на обработку персональных данных
          </a>
        </div>
      </div>
    </div>
  )
}
