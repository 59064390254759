import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getDataById } from '../../../pages/appointment-page/redux/consultationsListSlicer'
import React from 'react'
import Styles from './style.module.scss'
import { setData, setSavingUserInput } from '../../../pages/appointment-page/redux/consultationSlicer'

export const MedServices = (props) => {
  const activeConsultation = useAppSelector((state) => state.consultationPage.data.savingUserInput)
  const data = useAppSelector((state) => state.consultationsList.data)

  const saveUserDataInput = (id) => {
    if (!(id in activeConsultation)) {
      dispatch(setSavingUserInput({ key: id, value: {} }))
    }
  }
  const dispatch = useAppDispatch()
  const saveTitleConsultation = () => {
    const selectConsultation = data.find((item) => item.id === props.consultationId)
    dispatch(setData({ key: 'title', value: selectConsultation.title }))
  }
  return (
    <div
      className={Styles.service}
      onClick={() => {
        dispatch(getDataById(props.consultationId))
        props.setShowAppointment(true)
        saveUserDataInput(props.consultationId)
        saveTitleConsultation()
      }}
    >
      <img className={Styles.service__img} src={props.img} alt='медицинские услуги' />
      <p className={Styles.service__p}>{props.paragraph}</p>
    </div>
  )
}
