import React, { useState, useRef, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import { setIsPopup } from '../../../../redux/catalogSlicer'
import { CatalogProductMobile } from './components/CatalogProductMobile'
import { CatalogProductSelect } from './components/CatalogProductSelect'
// import { CatalogPhotos } from '../CatalogPhotos'
import { CatalogCard } from '../CatalogCard'
// import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import { PopupMobile } from '@components/PopupMobile'
import { CatalogProductSelectCount } from './components/CatalogProductSelect/components/CatalogProductSelectCount'
import { CatalogProductSelectTaste } from './components/CatalogProductSelect/components/CatalogProductSelectTaste'
import 'swiper/swiper.scss'
import Styles from './style.module.scss'
import { setSelectedModification, setSelectedVariant } from '../../../../redux/productSlice'

export const CatalogProduct = ({ product }) => {
  const { width } = useWindowSize()
  const dispatch = useAppDispatch()
  const isPopup = useAppSelector((state) => state.catalog.isPopup)
  const popupContent = useAppSelector((state) => state.catalog.popupContent)

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const selectedVariant = useAppSelector((state) => state.product.selectedVariant)
  const selectedModification = useAppSelector((state) => state.product.selectedModification)
  const [mainImage, setMainImage] = useState(selectedVariant?.images[0]?.image)

  useEffect(() => {
    setMainImage(selectedVariant?.images[0]?.image)
  }, [selectedVariant?.images])

  const setImageIndex = (index) => {
    setMainImage(selectedVariant.images[index].image)
  }

  const closePopup = () => dispatch(setIsPopup(false))

  return (
    <div className={Styles.product}>
      <div className={Styles.wrapper}>
        <div className={Styles.container}>
          {width > 830 ? (
            <>
              <div className={Styles.image}>
                <img src={mainImage} alt='Изображение' />
                {selectedVariant?.image_labels.length > 0 && (
                  <span style={{ backgroundColor: `${selectedVariant.image_labels[0].color}` }} className={Styles.new}>
                    {selectedVariant.image_labels[0].title}
                  </span>
                )}
              </div>
              <div className={Styles.slider}>
                <button className={`prev-card ${`${Styles.button} ${Styles.button_prev}`}`} ref={navigationPrevRef} />
                <Swiper
                  className={Styles.swiper}
                  slidesPerView='auto'
                  spaceBetween={14}
                  navigation={{ prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current }}
                  onSwiper={(swiper) => {
                    setTimeout(() => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      swiper.params.navigation.prevEl = navigationPrevRef.current
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      swiper.params.navigation.nextEl = navigationNextRef.current
                      swiper.navigation.destroy()
                      swiper.navigation.init()
                      swiper.navigation.update()
                    })
                  }}
                >
                  {selectedVariant?.images.map((img, index) => {
                    return (
                      <SwiperSlide key={index} className={Styles.slide} onClick={() => setImageIndex(index)}>
                        <div className={Styles.img}>
                          <img src={img.image} alt='Изображение' />
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
                <button className={`next-card ${Styles.button} ${Styles.button_next}`} ref={navigationNextRef} />
              </div>
            </>
          ) : (
            <CatalogProductMobile
              product={product}
              selectedVariant={selectedVariant}
              selectedModification={selectedModification}
            />
          )}
        </div>
        {width > 830 && (
          <div className={Styles.container}>
            {(product.variants.length > 1 || selectedVariant.modifications.length > 1) && (
              <>
                <div className={Styles.column}>
                  {selectedVariant?.name !== '' && (
                    <span className={Styles.title}>
                      {selectedVariant?.name}:&nbsp;
                      <strong>{selectedVariant?.value}</strong>
                    </span>
                  )}
                  <div className={Styles.select}>
                    {product.variants.map((item, index) => (
                      <div
                        key={index}
                        className={
                          item.id === selectedVariant.id ? `${Styles.taste} ${Styles.taste_select}` : `${Styles.taste}`
                        }
                        onClick={() => {
                          dispatch(setSelectedVariant(index))
                        }}
                      >
                        <img src={item.images[0]?.image} alt='Изображение' />
                      </div>
                    ))}
                  </div>
                </div>
                <div className={Styles.column}>
                  <span className={Styles.title}>
                    {selectedModification?.name}:&nbsp;
                    <strong>{selectedModification?.value}</strong>
                  </span>
                  <div className={Styles.select}>
                    {selectedVariant?.modifications.map((item, index) => (
                      <div
                        key={index}
                        className={
                          item.id === selectedModification.id
                            ? `${Styles.counts} ${Styles.counts_select}`
                            : `${Styles.counts}`
                        }
                        onClick={() => dispatch(setSelectedModification(index))}
                      >
                        <span>{item.value}</span>
                        <strong>{item.discount_price} ₽</strong>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            <ul className={Styles.list}>
              <li className={Styles.item}>
                <div className={Styles.row}>
                  <span className={Styles.name}>Категория</span>
                  <span className={Styles.border} />
                </div>
                <div className={Styles.wrap}>
                  <span className={Styles.grammar}>{product.health_category}</span>
                </div>
              </li>
              <li className={Styles.item}>
                <div className={Styles.row}>
                  <span className={Styles.name}>Количество в упаковке</span>
                  <span className={Styles.border} />
                </div>
                <div className={Styles.wrap}>
                  <span className={Styles.grammar}>{selectedModification?.value}</span>
                </div>
              </li>
              <li className={Styles.item}>
                <div className={Styles.row}>
                  <span className={Styles.name}>Форма выпуска</span>
                  <span className={Styles.border} />
                </div>
                <div className={Styles.wrap}>
                  <span className={Styles.grammar}>{product.form_type}</span>
                </div>
              </li>

              {/* <li className={Styles.item}>
                <ButtonTransparent value='Смотреть все характеристики' />
              </li> */}
            </ul>
          </div>
        )}
      </div>
      {/* <CatalogPhotos title='Фото покупателей' button='смотреть еще 23 отзыва с фото' /> */}
      {width <= 830 && (product.variants.length > 1 || selectedVariant.modifications.length > 1) && (
        <CatalogProductSelect selectedVariant={selectedVariant} selectedModification={selectedModification} />
      )}
      {width <= 1310 && (
        <div className={Styles.card}>
          <CatalogCard product={product} />
          <span className={Styles.produced}>
            Произведено&nbsp;
            <strong>{product.brand}</strong>
          </span>
        </div>
      )}
      {isPopup && popupContent === 'count' && (
        <PopupMobile closePopup={closePopup}>
          <CatalogProductSelectCount selectedVariant={selectedVariant} selectedModification={selectedModification} />
        </PopupMobile>
      )}
      {isPopup && popupContent === 'taste' && (
        <PopupMobile closePopup={closePopup}>
          <CatalogProductSelectTaste product={product} selectedVariant={selectedVariant} />
        </PopupMobile>
      )}
    </div>
  )
}
