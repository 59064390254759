import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getListAppointmentsPatientDate } from '../../../../redux/doctorSlicer'
import Styles from './style.module.scss'
import { DoctorPatientVisit } from './components/DoctorPatientVisit'
import { Tooltip } from '../../../UI/Tooltip'
import questionIcon from '@icons/question.svg'
import { ButtonPagination } from '../../../../../../pages/my-appointment/components/AppointmentsList/components/ButtonPagination'
import moment from 'moment'

export const DoctorPatientHistory: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentPatient = useAppSelector((state) => state.doctor.currentPatient)
  const [currentAppointmentsPatient, setCurrentAppointmentsPatient] = useState([])
  const [today, setToday] = useState(moment())

  useEffect(() => {
    dispatch(
      getListAppointmentsPatientDate({
        dayAfter: today.startOf('month').format('YYYY-MM-DD'),
        dayBefore: today.endOf('month').format('YYYY-MM-DD'),
        patientId: currentPatient.id,
      }),
    )
      .then((res) => {
        setCurrentAppointmentsPatient(res.payload.data.results)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }, [today])
  return (
    <div className={Styles.history}>
      <span className={Styles.caption}>Все записи пациента</span>
      <ButtonPagination setToday={setToday} today={today} />
      <div className={Styles.table}>
        <div className={Styles.header}>
          <div className={Styles.wrap}>
            <span className={Styles.head}>Консультация</span>
          </div>
          <div className={Styles.wrap}>
            <span className={Styles.head}>Дата</span>
          </div>
          <div className={Styles.wrap}>
            <span className={`${Styles.head} ${Styles.head_mod}`}>Мед. заключение</span>
            <div className={Styles.icon}>
              <img src={questionIcon} alt='Иконка' />
              <Tooltip className={Styles.tooltip}>Описание заключения</Tooltip>
            </div>
          </div>
        </div>
        <ul className={Styles.content}>
          {currentAppointmentsPatient && currentAppointmentsPatient.length !== 0 ? (
            <>
              {currentAppointmentsPatient.map((appointment) => (
                <DoctorPatientVisit
                  key={appointment.id}
                  appointmentId={appointment.id}
                  consultationType={appointment.consultation_type}
                  dayReception={appointment.day_reception}
                  isOnline={appointment.is_online}
                  medicalReport={appointment.medical_report}
                  patient={appointment.patient}
                  status={appointment.status}
                  timeReception={appointment.time_reception}
                />
              ))}
            </>
          ) : (
            <span className={Styles.epmty}>Пусто</span>
          )}
        </ul>
      </div>
    </div>
  )
}
