import React from 'react'
import Styles from './style.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import arrow from '@icons/arrow-right-blue.svg'
import arrowRight from '@icons/arrow-right.svg'
import { useAppSelector } from '@app/redux/hooks'
import { Spinner } from '@components/Spinner'
import { Link } from 'react-router-dom'
SwiperCore.use([Navigation])

interface StoriesProps {
  vertical?: boolean
}

export const Stories: React.FC<StoriesProps> = ({ vertical }) => {
  const stories = useAppSelector((state) => state.blog.stories)

  return (
    <div className={Styles.container}>
      <Swiper
        className={Styles.slider__container}
        loop
        // direction='vertical'
        breakpoints={{
          300: vertical
            ? {
                direction: 'vertical',
                slidesPerView: 4,
                spaceBetween: 20,
                loop: false,
              }
            : {
                spaceBetween: 20,
                slidesPerView: 1.1,
              },
          768: {
            spaceBetween: 10,
            slidesPerView: 2,
          },
          830: {
            spaceBetween: 10,
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        navigation={{
          nextEl: '.next-card',
        }}
      >
        {stories.results.length > 0 ? (
          stories.results.map((story) => {
            return (
              <SwiperSlide
                key={story.id}
                className={Styles.foryou}
                style={{
                  background: `url(${story.image}) no-repeat bottom right`,
                  backgroundColor: story.background_color,
                  backgroundSize: '50%',
                }}
              >
                <h6 className={Styles.foryou__title}>{story.title}</h6>
                <p className={Styles.foryou__text}>{story.sub_title}</p>
                {story.link && (
                  <a href={story.link} target='_blank' rel='noreferrer' className={Styles.foryou__button}>
                    Читать <img className={Styles.arrow} src={arrow} alt='arrow' />
                  </a>
                )}
                {story.post && (
                  <Link to={`/blog/${story.post?.slug}`} className={Styles.foryou__button}>
                    Читать <img className={Styles.arrow} src={arrow} alt='arrow' />
                  </Link>
                )}
              </SwiperSlide>
            )
          })
        ) : (
          <Spinner />
        )}
      </Swiper>
      <button className={`next-card ${Styles.navigation__button}`}>
        <img src={arrowRight} alt='img' />
      </button>
    </div>
  )
}
