import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const createAppointment: any = createAsyncThunk('consultationPage/createAppointment', async (data: any) => {
  const response = await api.post<any>('appointments/', data)
  return response as any
})

export const editAppointmentById: any = createAsyncThunk('appointment/getAppointmentList', async (payload: any) => {
  const response = await api.patch(`appointments/${payload.id}/`, payload.data)
  return response.data
})

export const addAppointmentFile: any = createAsyncThunk('appointment/upload', async (payload: any) => {
  const response = await api.sendFile(`appointments/${payload.id}/upload/`, payload.data, payload.onUploadProgress)
  return response.data
})

export const getDraftAppointment: any = createAsyncThunk('consultationPage/getDraftAppointment', async (id: any) => {
  const response = await api.get<any>(`appointment_drafts/${id}/`)
  return response as any
})

export const createDraftAppointment: any = createAsyncThunk(
  'consultationPage/createDraftAppointment',
  async (data: any) => {
    const response = await api.post<any>(`appointment_drafts/`, data)
    return response as any
  },
)
export const patchDraftAppointment: any = createAsyncThunk(
  'consultationPage/patchDraftAppointment',
  async (payload: any) => {
    const response = await api.patch<any>(`appointment_drafts/${payload.id}/`, payload.data)
    return response as any
  },
)

/* export const deleteDraftAppointment: any = createAsyncThunk(
  'consultationPage/deleleDraftAppointment',
  async (id: any) => {
    const response = await api.delete<any>(`appointment_drafts/${id}/`)
    return response as any
  },
) */
export const postDraftAppointmentFile: any = createAsyncThunk(
  'consultationPage/postDraftAppointmentFile',
  async (payload: any) => {
    const response = await api.sendFileDraft<any>(
      `appointment_drafts/${payload.id}/`,
      payload.data,
      payload.onUploadProgress,
    )
    return response as any
  },
)
const consultationSlice = createSlice({
  name: 'consultationPage',
  initialState: {
    data: {
      id: null,
      priceType: null,
      title: '',
      priceWay: null,
      valueOptional: '',
      selectDay: '',
      selectTime: null,
      selectTimeForTotal: null,
      is_online: null,
      consultation_type: null,
      consultation_type_title: null,
      selectDayForTitle: '',
      dayOfBirth: null,
      doctor: null,
      doctorData: null,
      day_reception: null,
      email: null,
      address: null,
      region: null,
      coordinateX: null,
      coordinateY: null,
      files: [],
      type: '',
      savingUserInput: {},
      appointment_id: null,
      draft_id: null,
    },
    responseData: {
      id: null,
      consultation_type: 1,
      day_reception: null,
      description: '',
      doctor: null,
      is_online: null,
      time_reception: null,
    },
    dataUser: {
      birthday: null,
      first_name: '',
      gender: null,
    },
    status: null,
  },
  reducers: {
    setData(state, { payload }) {
      return {
        ...state,
        data: {
          ...state.data,
          [payload.key]: payload.value,
        },
      }
    },
    setSavingUserInput(state, { payload }) {
      return {
        ...state,
        data: {
          ...state.data,
          savingUserInput: {
            ...state.data.savingUserInput,
            [payload.key]: payload.value,
          },
        },
      }
    },
    updateSavingUserInput(state, { payload }) {
      return {
        ...state,
        data: {
          ...state.data,
          savingUserInput: {
            ...state.data.savingUserInput,
            [payload.id]: {
              ...state.data.savingUserInput[payload.id],
              ...payload.value,
            },
          },
        },
      }
    },
    setUserData(state, { payload }) {
      return {
        ...state,
        dataUser: {
          ...state.dataUser,
          [payload.key]: payload.value,
        },
      }
    },
    resetAppointmentData(state) {
      return {
        ...state,
        data: {
          ...state.data,
          priceType: null,
          title: '',
          priceWay: null,
          valueOptional: '',
          selectDay: '',
          selectTime: null,
          selectTimeForTotal: null,
          is_online: null,
          consultation_type: null,
          selectDayForTitle: '',
          dayOfBirth: null,
          doctor: null,
          doctorData: null,
          day_reception: null,
          email: null,
          address: null,
          coordinateX: null,
          coordinateY: null,
          files: [],
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAppointment.fulfilled, (state, action) => {
      state.responseData = action.payload.data
      state.status = 'success'
    })
    builder.addCase(createAppointment.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(createAppointment.rejected, (state) => {
      state.status = 'error'
    })
  },
})

export const { setData, setUserData, resetAppointmentData, setSavingUserInput, updateSavingUserInput } =
  consultationSlice.actions

export default consultationSlice.reducer
