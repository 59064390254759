import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { FilterAll } from './components/FilterAll'
import { FilterModal } from './components/FilterModal'
import { getFilterList } from '../../../../../../pages/catalog/redux/catalogSlicer'

export const CatalogNutraceuticalsFilters: React.FC = () => {
  const dispatch = useAppDispatch()
  const filterView = useAppSelector((state) => state.catalog.filterView)

  useEffect(() => {
    dispatch(getFilterList())
  }, [])

  return (
    <div className={Styles.container}>
      {filterView === 'all' && <FilterAll />}
      {filterView === 'brand' && <FilterModal title='Бренды' filterView={filterView} />}
      {filterView === 'price' && <FilterModal title='Цены' filterView={filterView} />}
      {filterView === 'form' && <FilterModal title='Формы выпуска' filterView={filterView} />}
      {filterView === 'category' && <FilterModal title='Категории' filterView={filterView} />}
    </div>
  )
}
