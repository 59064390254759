import React, { useState } from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { DoctorTodayMobile } from '../../../DoctorMonth/components/DoctorToday/components/DoctorTodayMobile'
import { DoctorPopupMobile } from '../../../../../DoctorModal/components/DoctorPopupMobile'
import { DoctorRecordInfo } from '../../../DoctorRecordInfo'

import caretIcon from '@icons/caret-down.svg'

import { formateDate } from '../../../../../../../../utils/formateDate'

export const DoctorWeekMobile: React.FC = () => {
  const appointmentsWeek = useAppSelector((state) => state.doctor.appointmentsWeek)
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [selectedDay, setSelectedDay] = useState(null)

  const selectingDay = (day) => {
    if (day === selectedDay) {
      setSelectedDay(null)
    } else {
      setSelectedDay(day)
    }
  }

  return (
    <div className={Styles.schedule}>
      <div className={Styles.header}>
        <span className={Styles.head}>Дата</span>
        <span className={Styles.head}>Записи</span>
      </div>
      <div className={Styles.table}>
        {appointmentsWeek.map((week) => (
          <div
            key={week.day}
            className={week.day === selectedDay ? `${Styles.line} ${Styles.line_active}` : `${Styles.line}`}
          >
            <div className={Styles.wrap} onClick={() => selectingDay(week.day)}>
              <span className={Styles.date}>{formateDate(week.day, 'DD MMMM, dd')}</span>
              <span className={Styles.count}>{week.count_appointment}</span>
              <img className={Styles.caret} src={caretIcon} alt='Иконка' />
            </div>
            {week.day === selectedDay && (
              <DoctorTodayMobile
                day={week.day}
                receptions={week.receptions}
                setSelectedAppointment={setSelectedAppointment}
              />
            )}
          </div>
        ))}
      </div>
      {selectedAppointment && (
        <DoctorPopupMobile closePopup={() => setSelectedAppointment(null)}>
          <DoctorRecordInfo selectedAppointment={selectedAppointment} />
        </DoctorPopupMobile>
      )}
    </div>
  )
}
