import React, { useState } from 'react'
import Styles from './style.module.scss'
import MaskedInput from 'react-text-mask'
import useWindowSize from '@helpers/useWindowSizeHook'
import { DrawerPanel } from '@components/Drawer'
import { TransitionsModal } from '@components/Modal'
import { ButtonColor } from '@components/ui/ButtonColor'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { patchMedicalReport } from '../redux/reportsSlice'
import moment from 'moment'
import { Field, Form } from 'react-final-form'
import { CalendarWrapper } from '../FirstStepFieldset/calendarWrapper'
import { getAppointment } from '../../../pages/doctor/redux/doctorSlicer'

export const ControlReception = ({ setStatusReport, initialState }) => {
  const medicalReportId = useAppSelector((state) => state.reports.medicalReportId)
  const currentAppointment = useAppSelector((state) => state.doctor.currentAppointment)
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const desktop = width >= 829
  const [showCalendar, setShowCalendar] = useState(false)

  const [dayOfBirth, setDayOfBirth] = useState(
    moment(initialState?.control_appointment_date, 'YYYY-MM-DD').format('DD.MM.YYYY') || '',
  )

  const date = moment()
  const valid = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [values, setValues] = useState({
    control_appointment_date: dayOfBirth,
  })
  const dateFormat = (value) => (value && value.match(valid) ? undefined : 'Формат "ДД.ММ.ГГГГ"')

  const required = (value) => (value ? undefined : 'Это обязательное поле')

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce((error, validator) => error || validator(value), undefined)
  const onSubmit = async (values) => {
    await finishedReport(values)
  }
  const finishedReport = (values) => {
    const formatDate = moment(values.control_appointment_date, 'DD.MM.YYYY').format('YYYY-MM-DD')

    dispatch(
      patchMedicalReport({
        id: medicalReportId,
        arr: {
          control_appointment_date: formatDate,
          diagnosis: values.diagnosis,
          done_date: date,
        },
      }),
    ).then((res) => {
      if (res.payload.status < 300) {
        setStatusReport('done')
        dispatch(getAppointment(currentAppointment.id))
      }
    })
  }
  const initial = {
    ...initialState,
    control_appointment_date: moment(initialState?.control_appointment_date).format('DD.MM.YYYY'),
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initial}>
      {({ handleSubmit, values, form }) => {
        return (
          <form onSubmit={handleSubmit} className={Styles.container}>
            <Field name='control_appointment_date' validate={composeValidators(required, dateFormat)}>
              {({ input, meta }) => {
                return (
                  <div className={Styles.label__container}>
                    <label className={Styles.label}>Дата повторного приёма</label>
                    <MaskedInput
                      mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                      onClick={() => setShowCalendar(true)}
                      className={Styles.custom__input}
                      placeholder='___.___.______'
                      {...input}
                    />
                    {desktop ? (
                      <TransitionsModal
                        isOpenModal={showCalendar}
                        setModalOpened={() => setShowCalendar((isOpen) => !isOpen)}
                        className={Styles.modal__container}
                        opacity='0'
                        style={{ position: 'absolute', top: '0', left: '0', inset: 'auto' }}
                        disablePortal
                      >
                        <CalendarWrapper
                          dayOfBirth={dayOfBirth}
                          birth={setDayOfBirth}
                          changeShowCalendar={setShowCalendar}
                          setValues={setValues}
                          values={values}
                          label='control_appointment_date'
                        />
                      </TransitionsModal>
                    ) : (
                      <DrawerPanel
                        closeIcon
                        width='100%'
                        anchor='bottom'
                        open={showCalendar}
                        onClose={() => setShowCalendar((isOpen) => !isOpen)}
                      >
                        <CalendarWrapper
                          dayOfBirth={dayOfBirth}
                          birth={setDayOfBirth}
                          changeShowCalendar={setShowCalendar}
                          setValues={setValues}
                          values={values}
                          label='control_appointment_date'
                        />
                      </DrawerPanel>
                    )}
                    {meta.touched && meta.error && <span className={Styles.error__handler}>{meta.error}</span>}
                  </div>
                )
              }}
            </Field>
            <Field name='diagnosis' validate={required}>
              {({ input, meta }) => (
                <div>
                  <label className={Styles.label}>Диагноз</label>
                  <textarea type='text' {...input} placeholder='Введите диагноз' className={Styles.optional__input} />
                  {meta.touched && meta.error && <span className={Styles.error__handler}>{meta.error}</span>}
                </div>
              )}
            </Field>
            <div className={Styles.button__container}>
              <ButtonColor
                disabled={form.getState().invalid}
                className={Styles.button_width}
                value='Создать медзаключение'
                type='submit'
              />
            </div>
          </form>
        )
      }}
    </Form>
  )
}
