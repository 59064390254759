import React from 'react'
import Styles from './style.module.scss'
import icon from '@icons/download-icon.svg'
import { getPdf } from '@components/Notifications/utils/getPdf'

export const GetPdfButton = ({ medicalReportId }) => {
  const downloadPdf = () => {
    getPdf(medicalReportId)
  }
  return (
    <div className={Styles.container} onClick={downloadPdf}>
      <img src={icon} alt='download' />
      <span>Скачать pdf</span>
    </div>
  )
}
