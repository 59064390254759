import React, { useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import { BorderLine } from '@components/ui/BorderLine'
import clock from '@icons/clock.svg'
import monitor from '@icons/monitor.svg'
import users from '@icons/users.svg'
import logo from '@icons/zoom.svg'
import { TooltipComponent } from '@components/TooltipComponent'
import { NearestReception } from './components/NearestReception'
import { Total } from '../Total'
import { DoctorInformation } from './components/DoctorInfo'
import { AddDetailInfo } from './components/AddDetailInfo'
import { ButtonColor } from '@components/ui/ButtonColor'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import {
  changeTitle,
  consultationActive,
  mobileState,
  mobileStatePagination,
  setLoadContainer,
  setStateTotalComponent,
} from '../../redux/choiсeConsultationSlicer'
import {
  createDraftAppointment,
  getDraftAppointment,
  patchDraftAppointment,
  setData,
  updateSavingUserInput,
} from '../../redux/consultationSlicer'
import { CustomRadioInput } from './components/CustomRadioInput'
import { MapModal } from '@components/ui/MapModal'
import { Description } from './components/Description'
import { MobileHeader } from '../MobileHeader'
import useWindowSize from '@helpers/useWindowSizeHook'
import { Sidebar } from '../SideBar'
import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import { ButtonNavigation } from '@components/ui/ButtonNavigation'
import sctollToTop from '@helpers/scrollToTop'
import { LoadingFileContainer } from './components/LoadingFileContainer'
import { AuthorizationForm } from '../../../../pages/authorization/components/AuthorizationForm'
import { GoBackButton } from '@components/Footer/components/GoBackButton'

export const TypeOfConsultation = () => {
  const dispatch = useAppDispatch()
  const loadContainer = useAppSelector((state) => state.choieConsultationPage.loadContainer)
  const idConsultation = useAppSelector((state) => state.consultationsList.selectConsultationById)
  const data = useAppSelector((state) => state.consultationsList.data)
  const selectConsultation = data.find((item) => item.id === idConsultation)
  const dataHandler = useAppSelector((state) => state.consultationPage.data)
  const component = useRef<HTMLDivElement>(null)
  const type = dataHandler.consultation_type_title
  const way = dataHandler.is_online ? 'Онлайн' : 'Лично'
  const activeConsultation = useAppSelector((state) => state.consultationPage.data.savingUserInput)
  const [errorType, setErrorType] = useState(false)
  const [errorWay, setErrorWay] = useState(false)
  const [isFirstConsultation, setisFirstConsultation] = useState(true)
  const [mobileStep2, setMobileStep2] = useState(false)
  const { width } = useWindowSize()
  const desktop = width >= 829
  const topSize = desktop ? 0 : 70
  const loggedIn = useAppSelector((state) => state.auth.user.isLoggedIn)
  useEffect(() => {
    sctollToTop(component, topSize)
  }, [])
  const saveUserDataInput = (value) => {
    dispatch(updateSavingUserInput({ id: idConsultation, value }))
  }
  const select = (e) => {
    if (e.target.id === 'radio-1') {
      dispatch(setData({ key: 'priceType', value: selectConsultation.types[0].price }))
      dispatch(setData({ key: 'consultation_type', value: Number(e.target.value) }))
      dispatch(setData({ key: 'type', value: selectConsultation.types[0].title }))
      dispatch(setLoadContainer(false))
      setErrorType(false)
      dispatch(setStateTotalComponent(false))
      setisFirstConsultation(true)
      dispatch(setData({ key: 'consultation_type_title', value: selectConsultation.types[0].title }))
      saveUserDataInput({ type: selectConsultation.types[0].title })
      saveUserDataInput({ typePrice: selectConsultation.types[0].price })
    }
    if (e.target.id === 'radio-2') {
      dispatch(setData({ key: 'priceType', value: selectConsultation.types[1].price }))
      dispatch(setData({ key: 'consultation_type', value: Number(e.target.value) }))
      dispatch(setData({ key: 'type', value: selectConsultation.types[1].title }))
      dispatch(setLoadContainer(true))
      setErrorType(false)
      dispatch(setStateTotalComponent(false))
      setisFirstConsultation(false)
      dispatch(setData({ key: 'consultation_type_title', value: selectConsultation.types[1].title }))
      saveUserDataInput({ type: selectConsultation.types[1].title })
      saveUserDataInput({ typePrice: selectConsultation.types[1].price })
    }
    if (e.target.id === 'online') {
      dispatch(setData({ key: 'priceWay', value: '0' }))
      dispatch(setData({ key: 'is_online', value: true }))
      setErrorWay(false)
      dispatch(setStateTotalComponent(false))
      saveUserDataInput({ way: 'online' })
      saveUserDataInput({ wayPrice: '0' })
    }
    if (e.target.id === 'offline') {
      dispatch(setData({ key: 'priceWay', value: '1500' }))
      dispatch(setData({ key: 'is_online', value: false }))
      setErrorWay(false)
      dispatch(setStateTotalComponent(false))
      saveUserDataInput({ way: 'offline' })
      saveUserDataInput({ wayPrice: '1500' })
    }
  }

  const handle = () => {
    if (!activeConsultation[idConsultation].type && !activeConsultation[idConsultation].way) {
      setErrorType(true)
      setErrorWay(true)
    }
    if (activeConsultation[idConsultation].type && !activeConsultation[idConsultation].way) {
      setErrorWay(true)
      setErrorType(false)
    }
    if (!activeConsultation[idConsultation].type && activeConsultation[idConsultation].way) {
      setErrorType(true)
      setErrorWay(false)
    }
    if (activeConsultation[idConsultation].type && activeConsultation[idConsultation].way) {
      dispatch(consultationActive(2))
      dispatch(changeTitle({ id: 1, value: `${way}, ${type}` }))
      dispatch(setData({ key: 'doctor', value: selectConsultation.doctors[0].id }))
      dispatch(mobileState({ id: 2, value: true }))
      dispatch(
        patchDraftAppointment({
          id: idConsultation,
          data: {
            consultation_type: dataHandler.consultation_type,
            consultation_direction: idConsultation,
          },
        }),
      )
    }
  }

  const [isOpenModal, setModalOpened] = useState(false)
  const openModalMap = (e) => {
    e.preventDefault()
    setModalOpened(!isOpenModal)
  }
  const pagination = useAppSelector((state) => state.choieConsultationPage.list)
  const address = selectConsultation.doctors[0].doctor_settings

  useEffect(() => {
    dispatch(setData({ key: 'address', value: address.address }))
    dispatch(setData({ key: 'region', value: address.region }))
    dispatch(setData({ key: 'coordinateX', value: Number(address.lat_position) || 0 }))
    dispatch(setData({ key: 'coordinateY', value: Number(address.long_position) || 0 }))
  }, [address])
  const doctor = selectConsultation.doctors[0]

  useEffect(() => {
    dispatch(setData({ key: 'doctor', value: doctor.id }))
    dispatch(setData({ key: 'doctorData', value: doctor }))
  }, [doctor])

  useEffect(() => {
    sctollToTop(component, topSize)
  }, [mobileStep2])

  useEffect(() => {
    dispatch(getDraftAppointment(idConsultation)).then((res) => {
      if (res.payload.status < 300) {
        dispatch(setData({ key: 'files', value: res.payload.data.files }))
      } else {
        dispatch(
          createDraftAppointment({
            consultation_direction: idConsultation,
          }),
        ).then((res) => {
          console.log(res)
        })
        dispatch(setData({ key: 'files', value: [] }))
      }
    })
  }, [dataHandler.consultation_type])
  return (
    <div ref={component}>
      {!loggedIn && (
        <div className={Styles.input__wrap}>
          {!desktop && (
            <div className={Styles.input__wrap_buttonWrapper}>
              <GoBackButton />
            </div>
          )}
          <AuthorizationForm appointment />
        </div>
      )}
      {loggedIn && (
        <div className={`${Styles.reference__container} ${pagination[1].mobile && Styles.reference__mobile}`}>
          <MobileHeader
            title='Подробнее о консультации'
            onClick={() => {
              dispatch(mobileState({ id: 1, value: false }))
              dispatch(mobileStatePagination(false))
              setMobileStep2(false)
            }}
          />
          <MapModal isOpenModal={isOpenModal} setModalOpened={setModalOpened} />
          <div className={Styles.button_back}>
            <ButtonTransparent
              value='Назад к выбору направления'
              onClick={() => {
                dispatch(consultationActive(0))
                dispatch(setData({ key: 'is_online', value: false }))
                dispatch(setData({ key: 'consultation_type', value: null }))
              }}
              before
            />
          </div>
          <div className={Styles.main__conteiner}>
            <div className={Styles.wrapper__container}>
              {!mobileStep2 && (
                <div className={Styles.details__container}>
                  <header className={Styles.header}>
                    <div className={Styles.header__wrap}>
                      <img src={selectConsultation.image} className={Styles.img} alt='reference' />
                      <h4 className={Styles.title}>{selectConsultation.title}</h4>
                    </div>
                    {desktop && (
                      <div className={Styles.wrapper}>
                        <img src={clock} className={Styles.icon} alt='clock' />
                        <div className={Styles.duration}>
                          {isFirstConsultation
                            ? selectConsultation.types[0].duration
                            : selectConsultation.types[1].duration}
                        </div>
                      </div>
                    )}
                  </header>
                  {desktop && <BorderLine />}

                  <Description selectConsultation={selectConsultation} />
                </div>
              )}
              {(desktop || mobileStep2) && (
                <form className={Styles.form}>
                  <h3 className={Styles.checkbox__title}>Выберите тип консультации</h3>
                  <div className={Styles.checkbox__container}>
                    <CustomRadioInput
                      onChange={select}
                      htmlFor='radio-1'
                      id='radio-1'
                      title={selectConsultation.types[0].title}
                      price={Number(selectConsultation.types[0].price)}
                      text={selectConsultation.types[0].chosen_text}
                      name='type-consultation'
                      value={selectConsultation.types[0].id}
                      error={errorType}
                      checked={activeConsultation[idConsultation]?.type === selectConsultation.types[0].title}
                    />
                    <CustomRadioInput
                      onChange={select}
                      htmlFor='radio-2'
                      id='radio-2'
                      title={selectConsultation.types[1].title}
                      price={selectConsultation.types[1].price}
                      text={selectConsultation.types[1].chosen_text}
                      name='type-consultation'
                      value={selectConsultation.types[1].id}
                      error={errorType}
                      checked={activeConsultation[idConsultation]?.type === selectConsultation.types[1].title}
                    />
                  </div>
                  {(selectConsultation.direction_type === 'genetic' ||
                    selectConsultation.direction_type === 'check-up') &&
                    loadContainer && <LoadingFileContainer idConsultation={idConsultation} />}
                  <h3 className={Styles.checkbox__title}>Как будем проводить консультацию?</h3>
                  <div className={Styles.checkbox__container}>
                    {(selectConsultation.doctors[0].doctor_settings.consultation_kind === 0 ||
                      selectConsultation.doctors[0].doctor_settings.consultation_kind === 1) && (
                      <div className={Styles.checkbox__wrap}>
                        <CustomRadioInput
                          onChange={select}
                          name='way-consultation'
                          htmlFor='online'
                          id='online'
                          title='Онлайн'
                          src={monitor}
                          error={errorWay}
                          checked={activeConsultation[idConsultation]?.way === 'online'}
                        />
                        <div className={Styles.checkbox__mark}>
                          Онлайн консультация не отличается от личного приема. В день консультации мы отправим вам
                          ссылку в Zoom
                          <TooltipComponent
                            text='Zoom — программа для организации видеоконференций'
                            src={logo}
                            marginTop
                          />
                        </div>
                      </div>
                    )}
                    {(selectConsultation.doctors[0].doctor_settings.consultation_kind === 0 ||
                      selectConsultation.doctors[0].doctor_settings.consultation_kind === 2) && (
                      <div className={Styles.checkbox__wrap}>
                        <CustomRadioInput
                          onChange={select}
                          name='way-consultation'
                          htmlFor='offline'
                          id='offline'
                          title='Лично'
                          src={users}
                          price='+ 1 500'
                          error={errorWay}
                          checked={activeConsultation[idConsultation]?.way === 'offline'}
                        />
                        <div className={Styles.checkbox__mark}>
                          <p className={Styles.checkbox__text}>
                            <span>{address.region}</span> Консультация пройдет по адресу:{' '}
                            <span className={Styles.checkbox__text_bold}>{dataHandler.address}</span>
                          </p>
                          <ButtonNavigation onClick={openModalMap} />
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              )}
              {(desktop || mobileStep2) && selectConsultation.direction_type === 'common' && (
                <AddDetailInfo list={selectConsultation.helped_answers} idConsultation={idConsultation} />
              )}
              {mobileStep2 && (
                <>
                  <NearestReception
                    item={selectConsultation.available_time}
                    way={selectConsultation.doctors[0].doctor_settings.consultation_kind}
                  />
                  <Total selectConsultation={selectConsultation} consultation={false} date={false} isBorder={false} />
                </>
              )}
              {(desktop || mobileStep2) && (
                <div className={`${Styles.buttons__container} ${Styles.button_mob}`}>
                  <ButtonColor value='Перейти к выбору даты' onClick={handle} type='button' />
                  <div className={Styles.error__handler}>
                    {errorType && errorWay && 'Выберите тип приема и консультации'}
                    {errorType && !errorWay && 'Выберите тип приема'}
                    {!errorType && errorWay && 'Выберите тип консультации'}
                  </div>
                </div>
              )}
            </div>
            {desktop && (
              <Sidebar>
                <Total selectConsultation={selectConsultation} consultation={false} date={false} isBorder={false} />
                <NearestReception
                  item={selectConsultation.available_time}
                  way={selectConsultation.doctors[0].doctor_settings.consultation_kind}
                />
                {dataHandler.doctorData && (
                  <DoctorInformation
                    name={`${selectConsultation?.doctors[0]?.first_name} ${selectConsultation?.doctors[0]?.last_name}`}
                    prof={selectConsultation?.doctors[0]?.specialty}
                    expir={selectConsultation?.doctors[0]?.years_experience}
                    count={selectConsultation?.doctors[0]?.number_conducted_consultations}
                    avatar={selectConsultation?.doctors[0]?.avatar || ''}
                  />
                )}
              </Sidebar>
            )}
            {!desktop && (
              <>
                {!mobileStep2 && (
                  <>
                    {dataHandler.doctorData && (
                      <DoctorInformation
                        name={`${selectConsultation?.doctors[0]?.first_name} ${selectConsultation?.doctors[0]?.last_name}`}
                        prof={selectConsultation?.doctors[0]?.specialty}
                        expir={selectConsultation?.doctors[0]?.years_experience}
                        count={selectConsultation?.doctors[0]?.number_conducted_consultations}
                        avatar={selectConsultation?.doctors[0]?.avatar || ''}
                      />
                    )}
                    <NearestReception item={selectConsultation.available_time} />
                    <Total selectConsultation={selectConsultation} consultation={false} date={false} isBorder={false} />
                    <ButtonColor
                      className={Styles.button_mob}
                      value='Записаться'
                      onClick={() => setMobileStep2(true)}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
