import React from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { DoctorRecordWeek } from './components/DoctorRecordWeek'

export const DoctorWeek: React.FC = () => {
  const appointmentsWeek = useAppSelector((state) => state.doctor.appointmentsWeek)

  return (
    <div className={Styles.schedule}>
      <div className={Styles.header}>
        <span className={Styles.head}>Дата</span>
        <span className={Styles.head}>Кол-во</span>
        <span className={Styles.head}>Записи</span>
      </div>
      <div className={Styles.wrapper}>
        {appointmentsWeek.map((week) => (
          <DoctorRecordWeek
            key={week.day}
            countAppointment={week.count_appointment}
            day={week.day}
            isAvailable={week.is_available}
            receptions={week.receptions}
          />
        ))}
      </div>
    </div>
  )
}
