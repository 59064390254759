import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { formateDate } from '../../../../../../utils/formateDate'

interface DoctorPatientLastRecordProps {
  className?: string
}

export const DoctorPatientLastRecord: React.FC<DoctorPatientLastRecordProps> = (props) => {
  const { className } = props

  const lastAppointmentPatient = useAppSelector((state) => state.doctor.lastAppointmentPatient)

  let classNameRecord =
    lastAppointmentPatient.status === 'done' ? `${Styles.record} ${Styles.record_complete}` : `${Styles.record}`
  let classNameHeader = lastAppointmentPatient.is_online ? `${Styles.header}` : `${Styles.header} ${Styles.header_mod}`

  if (className) {
    classNameRecord = `${classNameRecord} ${className}`
  }

  if (lastAppointmentPatient.status === 'done') {
    classNameHeader = `${classNameHeader} ${Styles.header_complete}`
  }

  if (Object.keys(lastAppointmentPatient).length === 0) {
    return null
  }

  return (
    <div className={classNameRecord}>
      <span className={Styles.caption}>Последнее обращение</span>
      <div className={classNameHeader}>
        <div className={Styles.container}>
          <span className={Styles.time}>{lastAppointmentPatient.time_reception}</span>
          <span className={Styles.type}>{lastAppointmentPatient.is_online ? 'Онлайн прием' : 'Личный прием'}</span>
        </div>
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>Консультация</span>
        <p className={Styles.description}>{lastAppointmentPatient.consultation_type.direction.title}</p>
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>Тип консультации</span>
        <div className={Styles.wrap}>
          <p className={Styles.description}>{lastAppointmentPatient.consultation_type.title}</p>
        </div>
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>Дата и время</span>
        <div className={Styles.wrap}>
          <p className={Styles.description}>
            {formateDate(lastAppointmentPatient.day_reception, 'DD MMMM')}, {lastAppointmentPatient.time_reception}
          </p>
          <Link className={Styles.link} to={`/appointments/${lastAppointmentPatient.id}`}>
            {}
          </Link>
        </div>
      </div>
    </div>
  )
}
