import React from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'

export const SearchResearch = ({ item, resetValue }) => {
  return (
    <Link key={item.id} className={Styles.link} to={`/catalog/research/${item.id}`} onClick={resetValue}>
      <li className={Styles.item}>
        <span className={Styles.name}>{item.title}</span>
      </li>
    </Link>
  )
}
