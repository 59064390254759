import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import {
  getMedicalReportById,
  getNutraceuticals,
  getNutraceuticalSettings,
  patchNutraceuticalReport,
  postNutraceuticalReport,
} from '../redux/reportsSlice'
import { FormStorage } from '../../../utils/form-storage'
import Styles from './style.module.scss'
import { NutraceuticalItem } from '../components/NutraceuticalItem'
import { NutraceuticalForm } from '../components/NutraceuticalForm'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'
import { arrayUniqueBy } from '@helpers/arrayUniqueBy'

export const FieldValue = ({ name, children }: any) => {
  const value = useFieldValue(name)
  return <>{typeof children === 'function' ? children(value) : value}</>
}

export function useFieldValue(name: string) {
  const { input } = useField(name, { subscription: { value: true } })
  return input.value
}

export const NutraceuticalReport = ({ setActiveContent }) => {
  const medicalReportId = useAppSelector((state) => state.reports.medicalReportId)
  const [savedData, setSavedData] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useAppDispatch()
  const nutraceuticals = useAppSelector((state) => state.reports.nutraceuticals)
  const [initialState, setInitialState] = useState(null)

  useEffect(() => {
    dispatch(getMedicalReportById(medicalReportId)).then((res) => {
      setInitialState(res.payload.data.nutraceutical_reports)
    })
  }, [])
  const onSubmit = (formValues) => {
    const filterArr = formValues.nutraceutical.filter((item) => item.selected)
    const filterArrPatch = filterArr.filter((item) => item.update)
    const arrPatch = filterArrPatch.map((item) => {
      return Object.assign({}, item, {
        medical_report: medicalReportId,
        nutraceutical: item.nutraceutical.id,
      })
    })
    const arrPatchFil = arrPatch.filter((item) => {
      delete item.selected
      delete item.update
      delete item.updated_at
      return item
    })
    const filterArrPost = filterArr.filter((item) => !item.update)
    const arrPost = filterArrPost.map((item) => {
      return Object.assign({}, item, {
        medical_report: medicalReportId,
      })
    })
    const arrPostNoId = arrPost.filter((item) => {
      delete item.id
      delete item.selected
      delete item.created_at
      return item
    })
    if (arrPatch) {
      dispatch(patchNutraceuticalReport(arrPatchFil)).then((res) => {
        if (arrPostNoId.length > 0) {
          dispatch(postNutraceuticalReport(arrPostNoId))
        }
        if (res.payload.status < 400) {
          setActiveContent('research')
        }
      })
    }
  }
  useEffect(() => {
    dispatch(getNutraceuticals())
    dispatch(getNutraceuticalSettings())
  }, [])

  useEffect(() => {
    if (nutraceuticals) {
      const nutraceuticalsData = [...nutraceuticals].map((item) => {
        return Object.assign({}, item, {
          medical_report: medicalReportId,
          selected: false,
          nutraceutical: item.id,
        })
      })

      if (initialState) {
        const initialStateData = [...initialState].map((item) => {
          return Object.assign({}, item, {
            name: item.nutraceutical.name,
            meal: item.meal.id,
            periodicity: item.periodicity.id,
            reception: item.reception.id,
            id: item.id,
            selected: true,
            update: true,
          })
        })
        const arrConcatData = arrayUniqueBy(initialStateData.concat(nutraceuticalsData), (item) => item.name)
        setSavedData(arrConcatData)
      } else {
        setSavedData(nutraceuticalsData)
      }
    }
  }, [nutraceuticals, initialState])
  const initial = {
    nutraceutical: savedData,
  }

  return (
    <FormStorage
      formId='nutraceutical'
      onSubmit={onSubmit}
      initialValues={initial}
      mutators={{
        ...arrayMutators,
      }}
    >
      <div className={Styles.container}>
        <div className={Styles.column}>
          <h2 className={Styles.heading}>Поиск лекарств и нутрицевтиков</h2>
          <input
            className={Styles.search}
            type='search'
            placeholder='Начните вводить название'
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <div>
            <FieldArray name='nutraceutical'>
              {({ fields }) =>
                fields.map((name, index) => {
                  return (
                    <FieldValue key={name} name={name}>
                      {(value) => {
                        if (value?.id && value.name.match(new RegExp(searchValue, 'i'))) {
                          return <NutraceuticalItem key={index} item={`${name}`} selected={value.selected} />
                        }
                      }}
                    </FieldValue>
                  )
                })
              }
            </FieldArray>
          </div>
        </div>
        <div className={Styles.column}>
          <h2 className={Styles.heading}>Выбранные лекарства и нутрицевтики</h2>

          <FieldArray name='nutraceutical'>
            {({ fields }) =>
              fields.map((name, index) => {
                return (
                  <FieldValue key={name} name={name}>
                    {(value) => {
                      return (
                        <div key={index}>
                          {value.selected && (
                            <NutraceuticalForm name={`${name}`}>
                              <NutraceuticalItem item={`${name}`} index={index} selected={value.selected} />
                            </NutraceuticalForm>
                          )}
                        </div>
                      )
                    }}
                  </FieldValue>
                )
              })
            }
          </FieldArray>
        </div>
      </div>
    </FormStorage>
  )
}
