import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import { DoctorSurveys } from './components/DoctorSurveys'
import viewIcon from '@icons/view.svg'
import closeButton from '@icons/close.svg'
import moment from 'moment'
import { Nutrition } from './components/Nutrition'
import useWindowSize from '@helpers/useWindowSizeHook'
import { LifeStyle } from './components/LifeStyle'
import { Nutraceutical } from './components/Nutraceutical'
import { getPdf } from '@components/Notifications/utils/getPdf'
import { ButtonArrow } from '@components/ui/ButtonArrow'
import { NutraceuticalsDrawer } from './components/NutraceuticalsDrawer'
import { NutritionDrawer } from './components/NutritionDrawer'
import { LifeStyleDrawer } from './components/LifeStyleDrawer'
import { DrawerPanel } from '@components/Drawer'
import { DoctorSurveysDrawer } from './components/DoctorSurveysDrawer'
import { getAnalyzesById, getProductById } from '../../pages/catalog/redux/productSlice'
import { LinkToCatalog } from './ui/LinkToCatalog'
import declination from '@helpers/declination'
import {
  getMyCart,
  postCart,
  setDataSmallCatalog,
  setDataSmallCatalogAnalisys,
} from '../../pages/cart/redux/cartSlicer'
import { ProductDrawer } from './components/ProductDrawer'
import { AnalysisDrawer } from './components/AnalisysDrawer'
import {
  clearPlannedDataAnalysisToBy,
  clearPlannedDataProdactsToBy,
} from '../../pages/my-appointment/redux/appointmentByIdSlicer'

interface ConclusionData {
  date: any
  data: any
}
export const Conclusion: React.FC<ConclusionData> = (props) => {
  const dispatch = useAppDispatch()
  const array = [
    { id: 1, title: 'Питание' },
    { id: 2, title: 'Образ жизни' },
    { id: 3, title: 'Нутрицевтики' },
    { id: 4, title: 'Обследования' },
  ]
  const [showDrawer, setShowDrawer] = useState(false)
  const [showDrawerCatalog, setShowDrawerCatalog] = useState(false)
  const [showDrawerAnalysis, setShowDrawerAnalysis] = useState(false)
  const [activeArray, setActiveArray] = useState({ id: null, title: '' })
  const [activeCatalog, setactiveCatalog] = useState('')
  const [total, setTotal] = useState({ price: 0, discountPrise: 0 })
  const [totalAnalysis, setTotalAnalysis] = useState({ price: 0, discountPrise: 0 })
  const dataProduct = useAppSelector((state) => state.cart.dataSmallCatalog)
  const dataAnalysis = useAppSelector((state) => state.cart.dataSmallCatalogAnalisys)
  const plannedDataProdactsToBy = useAppSelector((state) => state.myAppointmentById.plannedDataProdactsToBy)
  const plannedDataAnalysisToBy = useAppSelector((state) => state.myAppointmentById.plannedDataAnalysisToBy)
  useEffect(() => {
    setActiveArray(array[0])
    if (dataProduct.length === 0) {
      getProduct()
    }
  }, [])
  useEffect(() => {
    if (dataAnalysis.length === 0) {
      getAnalysis()
    }
  }, [])

  const dateFormat = moment(props.date, 'YYYY-MM-DD').format('D MMMM, YYYY')
  const userRole = useAppSelector((state) => state.auth.user.info.role)
  const { width } = useWindowSize()
  const desktop = width >= 829
  const idMedicalReport = useAppSelector((state) => state.myAppointmentById.medicalReportId)
  const openDrawer = () => {
    setShowDrawer(true)
  }

  const getProduct = () => {
    props.data.nutraceutical_reports.map((item) => {
      if (item.nutraceutical.product) {
        dispatch(getProductById(item.nutraceutical.product)).then((res) => dispatch(setDataSmallCatalog(res.payload)))
      }
    })
  }
  const getAnalysis = () => {
    props.data.research_reports.map((item) => {
      if (item.analysis_id) {
        dispatch(getAnalyzesById(item.analysis_id)).then((res) => dispatch(setDataSmallCatalogAnalisys(res.payload)))
      }
    })
  }
  useEffect(() => {
    if (dataProduct.length > 0) {
      setTotal({
        price: dataProduct.reduce((acc, curr) => acc + curr.variants[0].modifications[0].price, 0),
        discountPrise: dataProduct.reduce((acc, curr) => acc + curr.variants[0].modifications[0].discount_price, 0),
      })
    }
  }, [dataProduct])

  useEffect(() => {
    if (dataAnalysis.length > 0) {
      setTotalAnalysis({
        price: dataAnalysis.reduce((acc, curr) => acc + curr.price, 0),
        discountPrise: dataAnalysis.reduce((acc, curr) => acc + curr.discount_price, 0),
      })
    }
  }, [dataAnalysis])

  const addProduct = () => {
    if (plannedDataProdactsToBy.length > 0) {
      plannedDataProdactsToBy.map((item) => {
        if (item.checked) {
          dispatch(
            postCart({
              product: item.prodact,
              quantity: item.quantity,
            }),
          )
        }
      })
      dispatch(getMyCart())
      dispatch(clearPlannedDataProdactsToBy())
      setShowDrawerCatalog(false)
    }
  }
  const addAnalisis = () => {
    if (plannedDataAnalysisToBy.length > 0) {
      plannedDataAnalysisToBy.map((item) => {
        if (item.checked) {
          dispatch(
            postCart({
              analysis: item.id,
            }),
          )
        }
      })
      dispatch(getMyCart())
      dispatch(clearPlannedDataAnalysisToBy())
      setShowDrawerAnalysis(false)
    }
  }
  const countTotal = (items) => items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0)
  const countTotalDiscount = (items) => items.reduce((acc, curr) => acc + curr.quantity * curr.discount_price, 0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalDiscountPrice, setDiscountTotalPrice] = useState(0)
  const [totalPriceAnalysis, setTotalPriceAnalysis] = useState(0)
  const [totalDiscountPriceAnalysis, setDiscountTotalPriceAnalysis] = useState(0)
  useEffect(() => {
    if (plannedDataProdactsToBy.length > 0) {
      setTotalPrice(countTotal(plannedDataProdactsToBy))
      setDiscountTotalPrice(countTotalDiscount(plannedDataProdactsToBy))
    }
  })
  useEffect(() => {
    if (plannedDataAnalysisToBy.length > 0) {
      setTotalPriceAnalysis(countTotal(plannedDataAnalysisToBy))
      setDiscountTotalPriceAnalysis(countTotalDiscount(plannedDataAnalysisToBy))
    }
  })
  return (
    desktop && (
      <>
        <div className={Styles.consequence}>
          <div className={Styles.header}>
            <div className={Styles.wrap}>
              <span className={Styles.conclusion}>Заключение&nbsp;</span>
              <span className={Styles.date}>{`от ${dateFormat}`}</span>
            </div>
            {/* {userRole === 'doctor' && (
            <button className={Styles.button}>
              <img src={createIcon} alt='Иконка' />
              <span className={Styles.edit}>Редактировать</span>
            </button>
          )} */}
            {userRole === 'patient' && (
              <button className={Styles.button} onClick={() => getPdf(idMedicalReport)}>
                <img src={viewIcon} alt='Иконка' />
                <span className={Styles.edit}>Смотреть PDF</span>
              </button>
            )}
          </div>

          <ul className={Styles.list}>
            {array?.map((item) => (
              <li
                key={item.id}
                className={activeArray.id === item.id ? `${Styles.item} ${Styles.item_active}` : `${Styles.item}`}
                onClick={() => setActiveArray(item)}
              >
                {item.title}
              </li>
            ))}
          </ul>

          {activeArray.id === 1 && (
            <>
              <Nutrition data={props.data.diet_reports} />
              {props.data.diet_reports.length > 0 && <ButtonArrow onClick={openDrawer} className={Styles.arrow} />}
            </>
          )}
          {activeArray.id === 2 && (
            <>
              <LifeStyle data={props.data.lifestyle_reports} />
              {props.data.lifestyle_reports.length > 0 && <ButtonArrow onClick={openDrawer} className={Styles.arrow} />}
            </>
          )}
          {activeArray.id === 3 && (
            <>
              <Nutraceutical data={props.data.nutraceutical_reports} />
              {dataProduct && dataProduct.length > 0 && userRole === 'patient' && (
                <div className={Styles.market}>
                  <LinkToCatalog
                    price={total.price}
                    discountPrice={total.discountPrise}
                    quantity={declination(dataProduct.length, 'товар', 'товара', 'товаров')}
                    onClick={() => {
                      setShowDrawerCatalog(true)
                      setactiveCatalog('product')
                    }}
                  />
                </div>
              )}
              {props.data.nutraceutical_reports.length > 0 && (
                <ButtonArrow onClick={openDrawer} className={Styles.arrow} />
              )}
            </>
          )}
          {activeArray.id === 4 && (
            <>
              <DoctorSurveys data={props.data?.research_reports} />
              {dataAnalysis && dataAnalysis.length > 0 && userRole === 'patient' && (
                <div className={Styles.market}>
                  <LinkToCatalog
                    price={totalAnalysis.price}
                    discountPrice={totalAnalysis.discountPrise}
                    quantity={declination(dataAnalysis.length, 'обследование', 'обследования', 'обследований')}
                    onClick={() => {
                      setShowDrawerAnalysis(true)
                      setactiveCatalog('analysis')
                    }}
                  />
                </div>
              )}
              {props.data.research_reports.length > 0 && <ButtonArrow onClick={openDrawer} className={Styles.arrow} />}
            </>
          )}
        </div>
        <DrawerPanel
          width='100%'
          anchor='right'
          open={showDrawer}
          onClose={() => {
            setShowDrawer((isOpen) => !isOpen)
          }}
        >
          <div className={Styles.container}>
            <div className={Styles.title__container}>
              <h1 className={Styles.title}>
                {(activeArray.id === 1 && 'Питание') ||
                  (activeArray.id === 2 && 'Образ жизни') ||
                  (activeArray.id === 3 && 'Нутрицевтики') ||
                  (activeArray.id === 4 && 'Обследование')}
              </h1>
              <img
                src={closeButton}
                alt='img'
                className={Styles.closeButton}
                onClick={() => {
                  setShowDrawer(false)
                }}
              />
            </div>
            <div className={Styles.content__container}>
              <div className={Styles.content}>
                {activeArray.id === 1 && <NutritionDrawer data={props.data.diet_reports} />}
                {activeArray.id === 2 && <LifeStyleDrawer data={props.data.lifestyle_reports} />}
                {activeArray.id === 3 && <NutraceuticalsDrawer data={props.data.nutraceutical_reports} />}
                {activeArray.id === 4 && <DoctorSurveysDrawer data={props.data.research_reports} />}
              </div>
            </div>
          </div>
        </DrawerPanel>
        <ProductDrawer
          setShowDrawerCatalog={setShowDrawerCatalog}
          showDrawerCatalog={showDrawerCatalog}
          activeCatalog={activeCatalog}
          dataProduct={dataProduct}
          addProduct={addProduct}
          totalPrice={totalPrice}
          totalDiscountPrice={totalDiscountPrice}
          plannedDataProdactsToBy={plannedDataProdactsToBy}
        />
        <AnalysisDrawer
          setShowDrawerCatalog={setShowDrawerAnalysis}
          showDrawerCatalog={showDrawerAnalysis}
          // activeCatalog={activeCatalog}
          dataAnalysis={dataAnalysis}
          addProduct={addAnalisis}
          totalPrice={totalPriceAnalysis}
          totalDiscountPrice={totalDiscountPriceAnalysis}
          plannedDataAnalysisToBy={plannedDataAnalysisToBy}
        />
      </>
    )
  )
}
