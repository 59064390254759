import React from 'react'
import Styles from './style.module.scss'
import { InputRadio } from '../../../../../../../UI/InputRadio'
import { formateDate } from '../../../../../../../../../../utils/formateDate'

interface RecordingSetupProps {
  selectedDate: any
  selectTime: any
  transferAppointment: any
  formatedDateShort: string
  formatedDateDay: string
  timeAppointment: string
}

export const TransferSetup: React.FC<RecordingSetupProps> = (props) => {
  const { selectedDate, selectTime, transferAppointment, formatedDateShort, formatedDateDay, timeAppointment } = props

  return (
    <>
      <div className={Styles.container}>
        <span className={Styles.caption}>{formateDate(selectedDate.day, 'DD MMMM')}</span>
        <form className={Styles.form}>
          <div className={Styles.records}>
            {selectedDate?.receptions?.map((reception) => (
              <>
                {!reception.is_blacklist && !reception.is_past && !reception.appointment ? (
                  <label key={reception.id} className={Styles.record}>
                    <InputRadio
                      id={reception.id}
                      className={Styles.radio}
                      onChange={selectTime}
                      name='date'
                      value={reception.time}
                    />
                    <span className={Styles.time}>{reception.time}</span>
                  </label>
                ) : (
                  <>
                    {!reception.is_blacklist && reception.appointment ? (
                      <div key={reception.id} className={`${Styles.record} ${Styles.record_expired}`}>
                        <i
                          className={
                            reception.appointment.is_online ? `${Styles.icon}` : `${Styles.icon} ${Styles.icon_offline}`
                          }
                        >
                          {}
                        </i>
                        <span className={Styles.time}>{reception.time}</span>
                      </div>
                    ) : (
                      <div key={reception.id} className={`${Styles.record} ${Styles.record_expired}`}>
                        <span className={Styles.time}>{reception.time}</span>
                      </div>
                    )}
                  </>
                )}
              </>
            ))}
          </div>
        </form>
      </div>
      <div className={Styles.confirm}>
        {selectedDate ? (
          <div className={Styles.wrap}>
            <span>Запись на прием</span>
            <span>
              {formatedDateShort}, {formatedDateDay}, {timeAppointment}
            </span>
          </div>
        ) : (
          <div className={Styles.wrap}>
            <span>Запись на прием</span>
            <span>выберите дату</span>
          </div>
        )}
        <button onClick={transferAppointment} disabled={!selectedDate || !timeAppointment}>
          Перенести
        </button>
      </div>
    </>
  )
}
