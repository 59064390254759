/* eslint-disable camelcase */
import React, { useState } from 'react'
import useWindowSize from '@helpers/useWindowSizeHook'
import { CatalogRating } from '../../../../../CatalogRating'
import { CatalogPhotos } from '../../../CatalogPhotos'
import cmt from '@icons/commentFB.svg'
import plus from '@icons/plusFB.svg'
import minus from '@icons/minusFB.svg'
import Styles from './style.module.scss'
import moment from 'moment'
import { useAppDispatch } from '@app/redux/hooks'
import { postVote } from '../../../../../../../../pages/catalog/redux/catalogSlicer'
import { useSnackbar } from 'notistack'

interface CatalogFeedbackUserProps {
  name: string
  date: Date
  dignity: string
  flaw: string
  comment: string
  rating: number
  review_images: []
  productId: number
  reviewId: number
  useful: number
  useless: number
}

export const CatalogFeedbackUser: React.FC<CatalogFeedbackUserProps> = (props) => {
  const { name, date, dignity, flaw, comment, rating, review_images, productId, reviewId, useful, useless } = props
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [usefulness, setUsefulness] = useState({ useful, useless })

  const { width } = useWindowSize()
  const handleCountClick = (direction) => {
    dispatch(postVote({ productId, reviewId, direction })).then((res) => {
      if (res.payload.status === 200) {
        setUsefulness(res.payload.data)
      } else {
        const message = res.payload.data.error
        const type = 'error'
        enqueueSnackbar(
          { message, type },
          {
            autoHideDuration: 1500,
          },
        )
      }
    })
  }

  return (
    <div className={Styles.user}>
      <div className={Styles.info}>
        <div className={Styles.wrap}>
          <span className={Styles.name}>{name}</span>
          {width <= 830 && <CatalogRating rating={rating} withComments={false} />}
        </div>
        <div className={Styles.wrap}>
          <span className={Styles.date}>{moment(date).format('DD.MM.YYYY')}</span>
        </div>
      </div>
      {width > 830 && (
        <div className={Styles.wrapper}>
          <CatalogRating rating={rating} withComments={false} />
        </div>
      )}
      <div className={Styles.wrapper}>
        <CatalogPhotos images={review_images} small />
      </div>
      <div className={Styles.description}>
        <div className={Styles.block}>
          <i className={Styles.icon}>
            <img src={plus} alt='Иконка' />
          </i>
          <span className={Styles.text}>{dignity}</span>
        </div>
        <div className={Styles.block}>
          <i className={Styles.icon}>
            <img src={minus} alt='Иконка' />
          </i>
          <span className={Styles.text}>{flaw}</span>
        </div>
        <div className={Styles.block}>
          <i className={Styles.icon}>
            <img src={cmt} alt='Иконка' />
          </i>
          <span className={Styles.text}>{comment}</span>
        </div>
      </div>
      <div className={Styles.useful}>
        <span className={Styles.text}>Это полезный отзыв?</span>
        <div className={Styles.buttons}>
          <button className={Styles.button} onClick={() => handleCountClick('up')}>
            <strong>Да</strong>
            <span>{usefulness.useful}</span>
          </button>
          <button className={Styles.button} onClick={() => handleCountClick('down')}>
            <strong>Нет</strong>
            <span>{usefulness.useless}</span>
          </button>
        </div>
      </div>
    </div>
  )
}
