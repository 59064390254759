import React, { useEffect } from 'react'
import Styles from './style.module.scss'
import close from '@icons/mobile-close.svg'
import { DrawerPanel } from '@components/Drawer'
import { useLocation } from 'react-router-dom'

export const MobileModal = ({ title, children, isOpen, setIsModalOpen, className = '' }) => {
  const location = useLocation()
  useEffect(() => {
    setIsModalOpen(false)
  }, [location])
  return (
    <DrawerPanel width='100%' anchor='right' open={isOpen} onClose={setIsModalOpen}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h2 className={`${Styles.title} ${className}`}>{title}</h2>
          <img className={Styles.icon} src={close} alt='закрыть' onClick={() => setIsModalOpen(false)} />
        </div>
        <div className={Styles.body}>{children}</div>
      </div>
    </DrawerPanel>
  )
}
