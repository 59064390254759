import sctollToTop from '@helpers/scrollToTop'
import React, { useEffect, useRef } from 'react'
import { GoBackButton } from '../GoBackButton'
import Styles from './style.module.scss'

export const ConfidentialPage = (): React.ReactElement => {
  const component = useRef<HTMLDivElement>(null)
  useEffect(() => {
    sctollToTop(component, 90)
  }, [])
  return (
    <div className={Styles.container} ref={component}>
      <GoBackButton />
      <h2 className={Styles.header}>Политика конфиденциальности</h2>
      <p className={Styles.text}>
        Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в
        отношении всей информации, размещенной на сайте в сети Интернет по адресу: https://persolab.ru/ (далее – Сайт),
        которую Пользователи могут получить, предоставить во время использования Сайта, его сервисов, программ и
        продуктов.
      </p>
      <p className={Styles.text}>
        Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в
        ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен
        воздержаться от использования сервисов.
      </p>
      <h4>1. ОБЩИЕ ПОЛОЖЕНИЯ</h4>
      <p className={Styles.text}>
        1.1. В рамках настоящей Политики под персональной информацией Пользователя понимаются:
      </p>
      <p className={Styles.text}>
        1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации
        (создании учетной записи) или в процессе использования Сервисов, включая персональные данные Пользователя.
        Обязательная для предоставления Сервисов информация помечена специальным образом. Иная информация
        предоставляется Пользователем на его усмотрение.
      </p>
      <p className={Styles.text}>
        Пользователем на его усмотрение. 1.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их
        использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе
        IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью которой
        осуществляется доступ к сервисам), технические характеристики оборудования и программного обеспечения,
        используемых Пользователем, дата и время доступа к сервисам, адреса запрашиваемых страниц и иная подобная
        информация.
      </p>
      <p>1.1.3. Иная информация о Пользователе, обработка которой предусмотрена Пользовательским соглашением.</p>
      <p>
        1.1.4. Настоящая Политика конфиденциальности применяется только к Сайту https://persolab.ru/, оператор не
        контролирует и не несет ответственности за сайты третьих лиц, на которые Пользователь может перейти по ссылкам,
        доступным на Сайте https://persolab.ru/.
      </p>
      <h4>2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ</h4>
      <p>
        2.1. Сайт собирает и хранит только ту персональную информацию, которая необходима для предоставления сервисов
        или исполнения соглашений и договоров с Пользователем, за исключением случаев, когда законодательством
        предусмотрено обязательное хранение персональной информации в течение определенного законом срока.
      </p>
      <p>2.2. Персональную информацию Пользователя Сайт обрабатывает в следующих целях:</p>

      <p>2.2.1. Идентификации Пользователя, зарегистрированного на Сайте, для дальнейшего оказания услуг.</p>
      <p>2.2.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта.</p>
      <p>
        2.2.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся
        использования Сайта, оказания услуг, обработку запросов и заявок от Пользователя.
      </p>
      <p>
        2.2.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
      </p>
      <p>2.2.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.</p>
      <p>2.2.6. Создания учетной записи, если Пользователь дал согласие на создание учетной записи.</p>
      <p>2.2.7. Уведомления Пользователя Сайта по всем вопросам, в рамках правоотношений с Администрацией Сайта.</p>
      <p>
        2.2.8. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем,
        связанных с использованием Сайта.
      </p>
      <p>2.2.9. Осуществления рекламной деятельности с согласия Пользователя.</p>
      <h4>3. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ И ЕЕ ПЕРЕДАЧИ ТРЕТЬИМ ЛИЦАМ</h4>
      <p>
        3.1. Сайт хранит персональную информацию Пользователей в соответствии с внутренними регламентами конкретных
        сервисов.
      </p>
      <p>
        3.2. В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев
        добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. При
        использовании отдельных сервисов Пользователь соглашается с тем, что определенная часть его персональной
        информации становится общедоступной.
      </p>
      <p>3.3. Сайт вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:</p>
      <p>3.3.1. Пользователь выразил согласие на такие действия.</p>
      <p>
        3.3.2. Передача необходима для использования Пользователем определенного сервиса либо для исполнения
        определенного соглашения или договора с Пользователем.
      </p>
      <p>
        3.3.3. Передача предусмотрена российским или иным применимым законодательством в рамках установленной
        законодательством процедуры.
      </p>
      <p>
        3.3.4. В случае продажи Сайта к приобретателю переходят все обязательства по соблюдению условий настоящей
        Политики применительно к полученной им персональной информации.
      </p>
      <p>
        3.4. Обработка персональных данных Пользователя осуществляется без ограничения срока любым законным способом, в
        том числе в информационных системах персональных данных с использованием средств автоматизации или без
        использования таких средств. Обработка персональных данных Пользователей осуществляется в соответствии с
        Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».
      </p>
      <p>
        3.5. При утрате или разглашении персональных данных Администрация Сайта информирует Пользователя об утрате или
        разглашении персональных данных.
      </p>
      <p>
        3.6. Администрация Сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или
        иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.
      </p>

      <p>
        3.7. Администрация Сайта принимает необходимые организационные и технические меры для защиты персональной
        информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования,
        копирования, распространения, а также от иных неправомерных действий третьих лиц.
      </p>
      <h4>4. ОБЯЗАТЕЛЬСТВА СТОРОН</h4>
      <p>4.1. Пользователь обязан:</p>
      <p>4.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом</p>
      <p>
        4.1.2. Обновлять, дополнять предоставленную информацию о персональных данных в случае изменения данной
        информации.
      </p>
      <p>4.2. Администрация Сайта обязана:</p>
      <p>
        4.2.1. Использовать полученную информацию исключительно для целей, указанных в настоящей Политике
        конфиденциальности.
      </p>
      <p>
        4.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного
        разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование либо разглашение иными возможными
        способами переданных персональных данных Пользователя, за исключением предусмотренных настоящей Политикой
        конфиденциальности.
      </p>
      <p>
        4.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно
        порядку, обычно используемому для защиты такого рода информации в существующем деловом обороте.
      </p>
      <p>
        4.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента
        обращения или запроса Пользователя, или его законного представителя либо уполномоченного органа по защите прав
        субъектов персональных данных на период проверки в случае выявления недостоверных персональных данных или
        неправомерных действий.
      </p>
      <h4>5. ОТВЕТСТВЕННОСТЬ СТОРОН</h4>
      <p>
        5.1. Администрация Сайта, не исполнившая свои обязательства, несет ответственность за убытки, понесенные
        Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством
        Российской Федерации.
      </p>

      <p>
        5.2. В случае утраты или разглашения конфиденциальной информации Администрация Сайта не несет ответственности,
        если данная конфиденциальная информация:
      </p>
      <p>5.2.1. Была получена от третьей стороны до момента ее получения Администрацией Сайта.</p>
      <p>5.2.2. Стала публичным достоянием до ее утраты или разглашения.</p>
      <p>5.2.3. Была разглашена с согласия Пользователя.</p>
      <h4>6. ОБРАЩЕНИЯ ПОЛЬЗОВАТЕЛЕЙ</h4>
      <p>
        6.1. Пользователи вправе направлять Оператору свои запросы, в том числе запросы относительно использования их
        персональных данных, предусмотренные настоящей Политикой конфиденциальности, в письменной форме по адресу:
        675000, Российская Федерация, Амурская область, г. Благовещенск, ул. Горького, 235/2 офис 107, или в форме
        электронного документа, подписанного квалифицированной электронной подписью в соответствии с законодательством
        Российской Федерации, по адресу электронной почты: info@persolab.ru.
      </p>
      <p>6.2. Запрос, направляемый Пользователем, должен содержать следующую информацию: </p>

      <p>– номер основного документа, удостоверяющего личность Пользователя или его представителя;</p>
      <p>– сведения о дате выдачи указанного документа и выдавшем его органе;</p>
      <p>– сведения, подтверждающие участие Пользователя в отношениях с Оператором (например, номер договора);</p>
      <p>– подпись Пользователя или его представителя.</p>
      <p>
        Если запрос направляется представителем, то к нему должна быть приложена доверенность, удостоверенная
        нотариально.
      </p>
      <p>
        6.3. Оператор обязуется рассмотреть и направить ответ на поступивший запрос Посетителя в течение 30 (тридцати)
        дней с момента поступления обращения.
      </p>
      <h4>7. РАЗРЕШЕНИЕ СПОРОВ</h4>
      <p>
        7.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем Сайта и Администрацией
        Сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании
        спора).
      </p>
      <p>
        7.2. Получатель претензии в течение 15 (пятнадцати) календарных дней со дня получения претензии письменно
        уведомляет заявителя претензии о результатах рассмотрения претензии.
      </p>
      <p>
        7.3. При недостижении соглашения спор будет передан на рассмотрение в суд в соответствии с действующим
        законодательством Российской Федерации.
      </p>
      <p>
        7.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией Сайта применяется
        действующее законодательство Российской Федерации.
      </p>
      <h4>8. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h4>
      <p>
        8.1. Администрация Сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия
        Пользователя.
      </p>
      <p>
        8.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не
        предусмотрено новой редакцией Политики конфиденциальности.
      </p>
      <p>8.3. Действующая Политика конфиденциальности размещена на странице по адресу: https://persolab.ru/.</p>
    </div>
  )
}
