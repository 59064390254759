import React from 'react'
import Styles from './style.module.scss'
import { doctorInfo } from './constants/doctorInfo'
import hiRezDoctor from '@images/hirez-doctor.png'
import workExperienceIcon from '@icons/workExperienceIcon.svg'
import consultationIcon from '@icons/consultationIcon.svg'
import { ExperienceInfo } from './components'
import { DropdownInfo } from './components/DropdownInfo'

export const DoctorInfo = () => {
  return (
    <div className={Styles.doctorInfoContainer} id='doctor'>
      <h1 className={Styles.doctorInfoContainer__heading1}>Врач</h1>
      <div className={Styles.wrapper}>
        <div className={Styles.doctorinfoContainer__leftSide}>
          <img className={Styles.doctorInfoContainer__img} src={hiRezDoctor} alt='img' />
        </div>
        <div className={Styles.doctorinfoContainer__rightSide}>
          <h1 className={Styles.doctorInfoContainer__heading2}>{doctorInfo.name}</h1>
          <p className={Styles.doctorInfoContainer__p1}>{doctorInfo.specs}</p>
          <span style={{ display: 'flex', marginBottom: '30px' }}>
            <ExperienceInfo icon={workExperienceIcon} info='стаж' infoNum={doctorInfo.expirience} />
            <ExperienceInfo icon={consultationIcon} info='консультаций' infoNum={doctorInfo.patients} />
          </span>
          <DropdownInfo heading='Карьера' description={doctorInfo.career} />
          <DropdownInfo heading='Образование' description={doctorInfo.education} />
        </div>
      </div>
    </div>
  )
}
