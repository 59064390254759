import { useAppSelector } from '@app/redux/hooks'
import { Container } from '@components/Container'
import { Stories } from '@components/Stories'
import React from 'react'
import { BlogArticles } from './components/BlogArticles'
import { BlogFilters } from './components/BlogFilters'
import Styles from './style.module.scss'

export const BlogPage = () => {
  const loggedIn = useAppSelector((state) => state.auth.user.isLoggedIn)
  return (
    <div className={`${Styles.wrapper} ${loggedIn && Styles.wrapper_loggedIn}`}>
      <Container title='Блог' subtitle='Пишем о здоровье для вас'>
        <Stories />
        <BlogFilters />
        <BlogArticles />
      </Container>
    </div>
  )
}
