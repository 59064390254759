import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '../../../../../../helpers/useWindowSizeHook'
import { setIsDrawer, setFilterView, setAnalyzesOrdering, setAnalyzesProps } from '../../../../redux/catalogSlicer'
import Styles from './style.module.scss'
import 'swiper/swiper.scss'

import { FilterItem } from '../../../CatalogFilter/components/FilterItem'

import removeIcon from '@icons/remove.svg'

import { MOBILE_BREAKPOINT } from '../../../../../../global-constants'
import { OrderingAnalyzesItem } from '../CatalogResearchFilters/components/OrderingAnalyzesItem'

export const CatalogResearchFilter: React.FC = () => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const analyzesFilter = useAppSelector((state) => state.catalog.analyzesFilter)

  const clearAllButtonVisible = analyzesFilter.body_system || analyzesFilter.prescribed || analyzesFilter.categories

  const cleanFilters = () => {
    dispatch(
      setAnalyzesProps({
        body_system: '',
        prescribed: null,
        categories: '',
      }),
    )
  }

  const cleanDefiniteFilter = (e, key) => {
    e.stopPropagation()
    dispatch(setAnalyzesProps(key === 'prescribed' ? { [key]: null } : { [key]: '' }))
  }

  const openModal = (view) => {
    dispatch(setFilterView(view))
    dispatch(setIsDrawer(true))
  }

  const handleOrdering = (e) => {
    dispatch(setAnalyzesOrdering(e.target.value))
  }

  return (
    <div className={Styles.filter}>
      {MOBILE_BREAKPOINT < width ? (
        <>
          <div className={Styles.up}>
            <div className={Styles.block}>
              <OrderingAnalyzesItem name='Категория' value='categories' onClick={() => openModal('categories')} />
              <OrderingAnalyzesItem
                name='Органы и системы организма'
                value='body_system'
                onClick={() => openModal('body_system')}
              />
              <FilterItem
                name='Вам выписал врач'
                onClick={() => dispatch(setAnalyzesProps({ prescribed: true }))}
                isBlue={analyzesFilter.prescribed}
              />
            </div>
            <div className={Styles.block}>
              <select className={Styles.orderingSelect} onChange={handleOrdering}>
                <option value='discount_price'>Сначала дешёвые</option>
                <option value='-discount_price'>Сначала дорогие</option>
                <option value='category'>Категория</option>
                <option value='body_system'>Органы и системы организма</option>
              </select>
            </div>
          </div>
          <div className={Styles.down}>
            <div className={Styles.block}>
              {clearAllButtonVisible && <FilterItem name='Очистить все' onClick={cleanFilters} isBlue />}
              {analyzesFilter.prescribed && (
                <FilterItem
                  name='Вам выписал врач'
                  rightImage={removeIcon}
                  cleanFilter={(e) => cleanDefiniteFilter(e, 'prescribed')}
                />
              )}
              {analyzesFilter.categories && (
                <FilterItem
                  name={`Категория: ${analyzesFilter.categories}`}
                  rightImage={removeIcon}
                  cleanFilter={(e) => cleanDefiniteFilter(e, 'categories')}
                />
              )}
              {analyzesFilter.body_system && (
                <FilterItem
                  name={`Органы и системы организма: ${analyzesFilter.body_system}`}
                  rightImage={removeIcon}
                  cleanFilter={(e) => cleanDefiniteFilter(e, 'body_system')}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={Styles.container}>
            <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={20}>
              <SwiperSlide className={Styles.slide}>
                <OrderingAnalyzesItem name='Категория' value='categories' onClick={() => openModal('categories')} />
              </SwiperSlide>
              <SwiperSlide className={Styles.slide}>
                <OrderingAnalyzesItem
                  name='Органы и системы организма'
                  value='body_system'
                  onClick={() => openModal('body_system')}
                />
              </SwiperSlide>
              <SwiperSlide className={Styles.slide}>
                <FilterItem
                  name='Вам выписал врач'
                  onClick={() => dispatch(setAnalyzesProps({ prescribed: true }))}
                  isBlue={analyzesFilter.prescribed}
                />
              </SwiperSlide>
              <SwiperSlide className={Styles.slide}>
                <select className={Styles.orderingSelect} onChange={handleOrdering}>
                  <option value='discount_price'>Сначала дешёвые</option>
                  <option value='-discount_price'>Сначала дорогие</option>
                  <option value='category'>Категория</option>
                  <option value='body_system'>Органы и системы организма</option>
                </select>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={Styles.container}>
            <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={20}>
              {clearAllButtonVisible && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem name='Очистить все' onClick={cleanFilters} isBlue />
                </SwiperSlide>
              )}
              {analyzesFilter.prescribed && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem
                    name='Вам выписал врач'
                    rightImage={removeIcon}
                    cleanFilter={(e) => cleanDefiniteFilter(e, 'prescribed')}
                  />
                </SwiperSlide>
              )}
              {analyzesFilter.categories && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem
                    name={`Категория: ${analyzesFilter.categories}`}
                    rightImage={removeIcon}
                    cleanFilter={(e) => cleanDefiniteFilter(e, 'categories')}
                  />
                </SwiperSlide>
              )}
              {analyzesFilter.body_system && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem
                    name={`Органы и системы организма: ${analyzesFilter.body_system}`}
                    rightImage={removeIcon}
                    cleanFilter={(e) => cleanDefiniteFilter(e, 'body_system')}
                  />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </>
      )}
    </div>
  )
}
