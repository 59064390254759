import React from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setIsDrawer, setFilterView, turnProductPage } from '../../redux/catalogSlicer'
import { DrawerComponent } from '@components/DrawerComponent'
import { CatalogNutraceuticalsFilter } from './components/CatalogNutraceuticalsFilter'
import { CatalogNutraceuticalsItem } from './components/CatalogNutraceuticalsItem'
import { CatalogNutraceuticalsFilters } from './components/CatalogNutraceuticalsFilters'
import Styles from './style.module.scss'
import { CatalogPagination } from '../CatalogPagination'
import { Loader } from '../../../../pages/doctor/components/UI/Loader'

export const CatalogNutraceuticals: React.FC = () => {
  const dispatch = useAppDispatch()
  const isDrawer = useAppSelector((state) => state.catalog.isDrawer)

  const products = useAppSelector((state) => state.catalog.products)
  const currentProductsQuantity = useAppSelector((state) => state.catalog.currentProductsQuantity)
  const productsLeft = useAppSelector((state) => state.catalog.productsLeft)
  const isLoading = useAppSelector((state) => state.catalog.loading)

  const closeModal = () => {
    dispatch(setIsDrawer(false))
    dispatch(setFilterView('all'))
  }

  return (
    <div className={Styles.nutraceuticals}>
      <CatalogNutraceuticalsFilter />
      {products.results.length ? (
        <>
          <ul className={Styles.list}>
            {products.results.map((product) => {
              return <CatalogNutraceuticalsItem key={product.id} product={product} />
            })}
          </ul>
          <CatalogPagination
            items={products}
            currentQuantity={currentProductsQuantity}
            itemsLeft={productsLeft}
            turnPage={turnProductPage}
          />
        </>
      ) : isLoading ? (
        <Loader />
      ) : (
        'Товаров, соответствующих вашему выбору не обнаружено, попробуйте изменить запрос'
      )}

      {isDrawer && (
        <DrawerComponent open={isDrawer} closeModal={closeModal}>
          <CatalogNutraceuticalsFilters />
        </DrawerComponent>
      )}
    </div>
  )
}
