import React, { useState } from 'react'
import Styles from './style.module.scss'
import { TagLarge } from '@components/ui/TagLarge'
import arrow from '@icons/arrow-right.svg'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { updateSavingUserInput } from '../../../../redux/consultationSlicer'
import paperclip from '@icons/clip.svg'
import { useDropzone } from 'react-dropzone'
import { OneFileFragment } from '@components/ui/OneFileFragment'
import { LoadRender } from '@components/LoadRender'

export const AddDetailInfo = (props) => {
  const dispatch = useAppDispatch()
  const [isActive, setIsActive] = useState(false)
  const dataHandler = useAppSelector((state) => state.consultationPage.data)
  const idConsultation = useAppSelector((state) => state.consultationsList.selectConsultationById)
  const activeConsultation = useAppSelector((state) => state.consultationPage.data.savingUserInput)
  const [textOptional, setTextOptional] = useState(activeConsultation[idConsultation]?.valueOptional || '')
  const addTag = (e) => {
    setTextOptional(textOptional + e.target.innerText + ', ')
    const value = { valueOptional: textOptional }
    dispatch(updateSavingUserInput({ id: idConsultation, value }))
  }

  const inputHandler = (e) => {
    setTextOptional(e.target.value)
    const value = { valueOptional: textOptional }
    dispatch(updateSavingUserInput({ id: idConsultation, value }))
  }
  const [files, setFiles] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [count, setCount] = useState(0)
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: []) => {
      const newFiles = acceptedFiles.map((file: File) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )
      setFiles([...files, ...newFiles])
    },
  })
  return (
    <div className={Styles.container}>
      <div className={`${Styles.button__wrap} ${isActive && Styles.button_active}`}>
        <button className={Styles.button} onClick={() => setIsActive(!isActive)}>
          Хотите рассказать подробнее?
          <img className={`${Styles.img} ${isActive && Styles.img_active}`} alt='arrow' src={arrow} />
        </button>
        <div className={Styles.load__wrap} onClick={() => setIsActive(true)}>
          <div {...getRootProps({ className: `${Styles.input__wrapper}` })}>
            <input {...getInputProps()} />
            <div className={`${Styles.button} ${Styles.button__transparent}`}>или добавить файл</div>
          </div>
        </div>
      </div>
      {isActive && (
        <div className={Styles.optional}>
          <textarea
            onChange={inputHandler}
            value={textOptional}
            className={Styles.optional__input}
            placeholder='Расскажите о своей проблеме подробнее'
          />
          {props.list.length > 0 && (
            <div className={Styles.tag__wrap}>
              {props?.list?.map((number, id) => {
                if (number !== '') {
                  return <TagLarge key={id} value={number} addTag={addTag} />
                }
              })}
            </div>
          )}
          <div className={`${Styles.button__wrap} ${Styles.button__wrap_mobile}`}>
            <p className={Styles.load__text}>
              Расскажите о своей проблеме подробнее или прикрепите результаты обследования. Врач получит эту информацию
              до приема
            </p>
            <div {...getRootProps({ className: `${Styles.input__wrapper}` })}>
              <input {...getInputProps()} />
              <div className={Styles.load__button}>
                <img src={paperclip} />
                Выберите файл
              </div>
            </div>
          </div>

          {dataHandler.files.length > 0 && <LoadRender editLoadDescription={false} data={dataHandler.files} />}
          {files.map((file, i) => (
            <OneFileFragment key={i} file={file} setCount={setCount} idConsultation={props.idConsultation} />
          ))}
        </div>
      )}
    </div>
  )
}
