import React from 'react'
import Styles from './style.module.scss'

interface FilterItemProps {
  name: string
  rightImage?: any
  leftImage?: any
  onClick?: any
  cleanFilter?: any
  isBlue?: boolean
}

export const FilterItem: React.FC<FilterItemProps> = (props) => {
  const { name, leftImage, rightImage, onClick, cleanFilter, isBlue } = props

  return (
    <div
      className={Styles.item}
      onClick={onClick}
      style={isBlue && { backgroundColor: '#C7F1FF', borderColor: 'transparent' }}
    >
      {leftImage && <img className={Styles.left} src={leftImage} alt='Иконка' />}
      <span className={Styles.text}>{name}</span>
      {rightImage && <img className={Styles.right} src={rightImage} alt='Иконка' onClick={cleanFilter} />}
      {/* {ordered && <span className={Styles.sign}>{}</span>} */}
    </div>
  )
}
