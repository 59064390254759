import React, { useEffect } from 'react'
import Styles from './style.module.scss'
import { ReferenceEmpty } from './components/ReferenceEmpty'
import { ReferenceFull } from './components/ReferenceFull'
import { WelcomeId } from './components/WelcomeId'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { Spinner } from '@components/Spinner'
import { ForYou } from './components/ReferenceFull/components/ForYou'
import { getLastMedicalReport } from './redux/userSpaceSlicer'

export const User = () => {
  const name = useAppSelector((state) => state.auth.user.info.first_name)
  const dispatch = useAppDispatch()
  const status = useAppSelector((state) => state.userSpace.status)
  const lastMedicalReport = useAppSelector((state) => state.userSpace.lastReport)
  const requestSended = useAppSelector((state) => state.userSpace.requestSended)

  useEffect(() => {
    if (!requestSended) {
      dispatch(getLastMedicalReport())
    }
  }, [])

  return (
    <section className={Styles.section}>
      {status === 'success' && (
        <>
          <WelcomeId name={name} reportId={lastMedicalReport} />
          {lastMedicalReport && lastMedicalReport.is_report_done ? <ReferenceFull /> : <ReferenceEmpty />}
          <ForYou />
        </>
      )}
      {status === 'loading' && <Spinner />}
    </section>
  )
}
