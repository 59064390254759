import React, { useState } from 'react'
import Styles from './style.module.scss'
import question from '@icons/question.svg'
import Tooltip from '@mui/material/Tooltip'

const StyledTooltip = ({ title, children, ...props }) => (
  <Tooltip
    {...props}
    title={title}
    placement='bottom'
    componentsProps={{
      tooltip: {
        sx: {
          bgcolor: '#FFFFFF',
          color: '#2C4358',
          boxShadow: '0px 2px 10px rgba(0, 26, 67, 0.1)',
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 'normal',
          lineHeight: '140%',
          padding: '16px',
          borderRadius: '20px',
          minWidth: '200px',
          rippleBackgroundColor: 'blue',
        },
      },
    }}
  >
    {children}
  </Tooltip>
)
export const TooltipComponent = (props) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <StyledTooltip
      title={
        <>
          {props.src ? <img src={props.src} alt='img' /> : null}
          <div>{props.text}</div>
        </>
      }
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
    >
      <button
        onClick={(e) => {
          e.preventDefault()
          setShowTooltip(!showTooltip)
        }}
        className={`${Styles.button} ${props.marginTop && Styles.question__container}`}
      >
        <img className={props.className} src={question} alt='question' />
      </button>
    </StyledTooltip>
  )
}
