/* eslint-disable camelcase */
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useWindowSize from '@helpers/useWindowSizeHook'
import Styles from './style.module.scss'
import 'swiper/swiper.scss'
import { Tooltip } from '../../../../../doctor/components/UI/Tooltip'
import plusIcon from '@icons/plus-icon.svg'
import calendarIcon from '@icons/calendar.svg'
import { MOBILE_BREAKPOINT } from '../../../../../../global-constants'
import declination from '@helpers/declination'
import { CatalogPrice } from '../../../../../../pages/catalog/components/CatalogPrice'
import { CatalogCartButton } from '../../../../../../pages/catalog/components/CatalogCartButton'

interface CatalogComplexItemProps {
  analyze: any
}

export const CatalogComplexItem: React.FC<CatalogComplexItemProps> = ({ analyze }) => {
  const { width } = useWindowSize()
  const { title, price, execution_time, labs, discount_price, contents } = analyze

  return (
    <>
      <div className={`${Styles.item} ${Styles.item_complex}`}>
        <div className={Styles.block}>
          <div className={Styles.wrap}>
            <span className={Styles.title}>{title}</span>
            <span className={Styles.subtitle}>{}</span>
          </div>
          {width <= MOBILE_BREAKPOINT && (
            <div className={Styles.wrapper}>
              <CatalogPrice discount_price={discount_price} price={price} bigText />
            </div>
          )}
          <div className={Styles.list}>
            {contents &&
              contents.map((item) => {
                return (
                  <section key={item.title}>
                    <div className={Styles.product}>
                      <span className={Styles.symbol}>{item.short_title}</span>
                      <Tooltip className={Styles.tooltip}>{item.title}</Tooltip>
                    </div>
                    <img className={Styles.plus} src={plusIcon} alt='Иконка' />
                  </section>
                )
              })}
          </div>
          {width > MOBILE_BREAKPOINT && (
            <div className={Styles.wrap}>
              <div className={Styles.date}>
                <img src={calendarIcon} alt='Иконка' />
                <span>Срок исполнения до {declination(execution_time, 'дня', 'дней', 'дней')}</span>
              </div>
              <div className={Styles.performers}>
                {labs &&
                  labs?.map((lab) => {
                    return (
                      <div key={lab} className={Styles.image}>
                        <img src={lab} alt='Иконка' />
                      </div>
                    )
                  })}
              </div>
            </div>
          )}
        </div>
        {width > MOBILE_BREAKPOINT && (
          <div className={Styles.block}>
            <div className={Styles.wrap}>
              <CatalogPrice discount_price={discount_price} price={price} bigText />
            </div>
          </div>
        )}
        <div className={Styles.block}>
          <CatalogCartButton product={analyze} type='analysis_sets' column />

          {width <= MOBILE_BREAKPOINT && (
            <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={8}>
              <SwiperSlide className={Styles.slide}>
                <div className={Styles.date}>
                  <img src={calendarIcon} alt='Иконка' />
                  <span>Срок исполнения до {declination(execution_time, 'дня', 'дней', 'дней')}</span>
                </div>
              </SwiperSlide>
              {labs &&
                labs?.map((lab) => {
                  return (
                    <SwiperSlide key={lab} className={Styles.slide}>
                      <div className={Styles.image}>
                        <img src={lab} alt='Иконка' />
                      </div>
                    </SwiperSlide>
                  )
                })}
            </Swiper>
          )}
        </div>
        <div className={Styles.badge}>
          <span>Комплекс анализов</span>
        </div>
      </div>
    </>
  )
}
