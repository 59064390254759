import { MapComponent } from '@components/ui/Map'
import React, { useState } from 'react'
import Styles from './style.module.scss'
import { BorderLine } from '@components/ui/BorderLine'
import { Link } from 'react-router-dom'
import { ButtonWithBorder } from '@components/ui/ButtonWithBorder'
import { MapModal } from '@components/ui/MapModal'
import useWindowSize from '@helpers/useWindowSizeHook'
import { Sidebar } from '../SideBar'
import { DoctorInformation } from '../TypeOfConsultation/components/DoctorInfo'
import { ReminderFormComponent } from '@components/ReminderFormComponent'
import { Logo } from '@components/Logo'
import { TotalAppointment } from './TotalAppintment/TotalAppointment'

export const Invitation = (props) => {
  const [isOpenModal, setModalOpened] = useState(false)
  const openModalMap = (e) => {
    e.preventDefault()
    setModalOpened(!isOpenModal)
  }
  const { width } = useWindowSize()
  const desktop = width >= 829

  return (
    <div className={Styles.main__content}>
      <div className={Styles.logo}>
        <Logo />
      </div>
      <MapModal isOpenModal={isOpenModal} setModalOpened={setModalOpened} />
      <h1 className={Styles.title}>{props?.item.patient?.first_name}, ждем вас на прием</h1>
      <div className={Styles.container}>
        <div className={Styles.right}>
          <div className={Styles.content}>
            {props?.item?.is_online ? (
              <p className={Styles.text}>
                За 10 минут до начала приема врач пришлет в чат приглашение на конференцию в Zoom. Не опаздывайте
              </p>
            ) : (
              <div className={Styles.map__container} onClick={openModalMap}>
                <MapComponent className={Styles.wrap} />
                <p className={Styles.text}>
                  Консультация пройдет по адресу:
                  <span className={Styles.text_bold}>{props?.item?.doctor?.doctor_settings?.address}</span>
                </p>
              </div>
            )}

            <ReminderFormComponent appointmentId={props?.item.id} />
            {/* <div className={`${Styles.wrapper} ${Styles.wrapper_mobile}`}>
              <img src={comix} alt='comix' className={Styles.comix} />
              <div className={Styles.description}>
                <h6 className={Styles.subtitle}>Чат с врачем</h6>
                <p className={`${Styles.description__text} ${Styles.description__text_mr}`}>
                  Теперь у вас есть личный чат с врачем. Он находится в панели слева. Консультации с врачем в чате
                  проходят по будням 3 раза в неделю
                </p>
              </div>
            </div> */}
            <div>
              <BorderLine />
            </div>

            <p className={`${Styles.text} ${Styles.text_mr}`}>
              Посмотреть подробности записи можно в разделе{' '}
              <Link to='/my-appointment' className={Styles.link}>
                мои приёмы
              </Link>
            </p>
            <Link to='/my-appointment' className={Styles.link}>
              <ButtonWithBorder value='Перейти в мои приемы' className={Styles.button__link} />
            </Link>
          </div>
          {!desktop && (
            <div className={Styles.sidebar_mobile}>
              <TotalAppointment item={props?.item} />
              {props?.item?.doctor && (
                <DoctorInformation
                  name={`${props?.item?.doctor?.first_name} ${props?.item?.doctor?.last_name}`}
                  prof={props?.item?.doctor?.specialty}
                  expir={props?.item?.doctor?.years_experience}
                  count={props?.item.doctor?.number_conducted_consultations}
                  avatar={props?.item?.doctor?.avatar || ''}
                />
              )}
            </div>
          )}
        </div>
        {desktop && (
          <Sidebar>
            <TotalAppointment item={props?.item} />
            {props?.item?.doctor && (
              <DoctorInformation
                name={`${props?.item?.doctor?.first_name} ${props?.item?.doctor?.last_name}`}
                prof={props?.item?.doctor?.specialty}
                expir={props?.item?.doctor?.years_experience}
                count={props?.item?.doctor?.number_conducted_consultations}
                avatar={props?.item?.doctor?.avatar || ''}
              />
            )}
          </Sidebar>
        )}
      </div>
    </div>
  )
}
