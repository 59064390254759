import { ButtonClose } from '@components/ui/ButtonClose'
import React from 'react'
import Styles from './style.module.scss'

interface NotifyProps {
  onClick: () => void
}

export const Notify: React.FC<NotifyProps> = ({ onClick }) => {
  return (
    <div className={Styles.container}>
      <h3 className={Styles.title}>Анализы в заказе</h3>
      <p className={Styles.text}>
        В корзине имеются лабораторные обследования. Сразу после оплаты мы отправим номер заказа вам на почту
      </p>
      <ButtonClose className={Styles.button_close} onClick={onClick} />
    </div>
  )
}
