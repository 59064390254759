import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import mark from '@icons/mark.svg'
import breakfastIcon from '@icons/breakfast.svg'
import dinnerIcon from '@icons/dinner.svg'
import supperIcon from '@icons/supper.svg'
import foodIcon from '@icons/food.svg'

export const DoctorNutrition: React.FC = () => {
  const dietReports = useAppSelector((state) => state.doctor.dietReports)
  const [modifiedDietReports, setModifiedDietReports] = useState([])
  const [activeReport, setActiveReport] = useState(dietReports[0])

  useEffect(() => {
    if (dietReports.length !== 0) {
      const reports = dietReports.map((report) => {
        const obj = { ...report, image: null }

        if (report.title === 'Завтрак') {
          obj.image = breakfastIcon
        }

        if (report.title === 'Обед') {
          obj.image = dinnerIcon
        }

        if (report.title === 'Ужин') {
          obj.image = supperIcon
        }

        if (report.title === 'Продукты') {
          obj.image = foodIcon
        }

        return obj
      })

      setModifiedDietReports(reports)
    }
  }, [])

  return (
    <>
      <ul className={Styles.list}>
        {modifiedDietReports.length !== 0 &&
          modifiedDietReports.map((report) => {
            return (
              <li
                key={report.id}
                className={
                  activeReport.title === report.title ? `${Styles.term} ${Styles.term_active}` : `${Styles.term}`
                }
                onClick={() => setActiveReport(report)}
              >
                <img className={Styles.icon} src={report.image} alt='Иконка' />
                <span className={Styles.designation}>{report.title}</span>
              </li>
            )
          })}
      </ul>
      <div className={Styles.block}>
        {activeReport?.description && <p className={Styles.description}>{activeReport.description}</p>}
        {activeReport?.remark && (
          <div className={Styles.obligatory}>
            <i className={Styles.asterisk}>
              <img src={mark} alt='mark' />
            </i>
            <span className={Styles.extra}>{activeReport.remark}</span>
          </div>
        )}
        {activeReport?.products_exclusions && (
          <p className={Styles.extra}>Продукты исключения: {activeReport?.products_exclusions}</p>
        )}
      </div>
    </>
  )
}
