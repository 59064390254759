import React from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

export const DoctorLife: React.FC = () => {
  const lifestyleReports = useAppSelector((state) => state.doctor.lifestyleReports)

  return (
    <>
      <ul className={Styles.list}>
        {lifestyleReports.length !== 0 &&
          lifestyleReports.map((report) => (
            <li key={report.id} className={Styles.item}>
              <img src={report.exercise.image} alt='Иконка' />
              <div className={Styles.block}>
                <span className={Styles.title}>{report.exercise.title}</span>
                <span className={Styles.subtitle}>{`${report.time} ${report.option}`}</span>
              </div>
            </li>
          ))}
      </ul>
    </>
  )
}
