import React from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { DoctorRecord } from './components/DoctorRecord'

export const DoctorDay: React.FC = () => {
  const appointmentsDay = useAppSelector((state) => state.doctor.appointmentsDay)

  return (
    <div className={Styles.schedule}>
      <div className={Styles.wrapper}>
        {appointmentsDay.length !== 0 ? (
          <>
            {appointmentsDay.map(
              (appointment) =>
                appointment.status !== 'cancel' && <DoctorRecord key={appointment.id} appointment={appointment} />,
            )}
          </>
        ) : (
          <span className={Styles.empty}>Нет записей на этот день</span>
        )}
      </div>
    </div>
  )
}
