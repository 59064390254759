/* eslint-disable camelcase */
import React from 'react'
import { FilterBlock } from '../../../../../../../CatalogFilter/components/FilterBlock'
import 'rc-slider/assets/index.css'
import Styles from './style.module.scss'
import Slider from '@mui/material/Slider'

export const FilterPrice = ({ setFilter, filter }) => {
  const handlePriceRange = (e, value) => {
    setFilter((prev) => ({
      ...prev,
      min_price: value[0],
      max_price: value[1],
    }))
  }

  return (
    <div className={Styles.filter}>
      <div className={Styles.body}>
        <FilterBlock title=''>
          <div className={Styles.wrapper}>
            <div className={Styles.inputs}>
              <div className={Styles.input}>
                <span>от</span>
                <input
                  onChange={(e) => {
                    setFilter((prev) => ({ ...prev, min_price: e.target.value }))
                  }}
                  type='number'
                  value={filter.min_price}
                  placeholder='0'
                />
              </div>
              <div className={Styles.line}>
                <span>{}</span>
              </div>
              <div className={Styles.input}>
                <span>до</span>
                <input
                  onChange={(e) => {
                    setFilter((prev) => ({ ...prev, max_price: e.target.value }))
                  }}
                  type='number'
                  value={filter.max_price}
                  placeholder='100000'
                />
              </div>
            </div>
            <Slider
              value={[filter.min_price, filter.max_price]}
              onChange={handlePriceRange}
              valueLabelDisplay='auto'
              min={0}
              max={100000}
            />
          </div>
        </FilterBlock>
      </div>
    </div>
  )
}
