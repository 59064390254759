import React from 'react'
import { Drawer } from '@mui/material'

import Styles from './style.module.scss'
import { styled } from '@mui/material/styles'
import { ButtonClose } from '@components/ui/ButtonClose'

const StyledDrawer = styled(Drawer)(() => ({
  [`& .MuiDrawer-paper`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.0);',
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'flex-end',
  },
}))
export const DrawerMobile = ({ width, height = '100%', anchor, open, children, onClose }) => {
  return (
    <>
      <StyledDrawer anchor={anchor} open={open} onClose={onClose}>
        <ButtonClose className={Styles.button_close} onClick={onClose} />
        <div style={{ width, height, overflow: 'auto' }} className={Styles.children}>
          {children}
        </div>
      </StyledDrawer>
    </>
  )
}
