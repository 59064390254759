import React, { ReactNode } from 'react'
import Style from './style.module.scss'
import classNames from 'classnames/bind'

export const BadgeWithLogo = ({ image, customStyle }: { image: string; customStyle?: ReactNode }) => {
  const cx = classNames.bind(Style)
  return (
    <div className={cx(Style.badgeWithLogo, customStyle)}>
      <img src={image} alt='Логотип поставщика услуг' className={Style.badgeWithLogo__logo} />
    </div>
  )
}
