import React, { useState } from 'react'
import { MobileMenuItem } from './MobileMenuItem'
import Styles from './style.module.scss'
import main from '@icons/mobile-menu-main.svg'
import pencil from '@icons/mobile-menu-pencil.svg'
import profile from '@icons/mobile-menu-profile.svg'
import sheet from '@icons/mobile-menu-sheet.svg'
import message from '@icons/mobile-menu-message.svg'
import patients from '@icons/doctor-patients-mobile.svg'
import events from '@icons/doctor-event-mobile.svg'
import { MobileModal } from '@components/MobileModal'
import { useAppSelector } from '@app/redux/hooks'
import { SideMenuBody } from '@components/SideMenu/SideMenuBody'
import { MobileMenuModalButton } from './MobileMenuModalButton'
import { Chat } from '@components/Chat'

export const MobileMenu = () => {
  const userRole = useAppSelector((state) => state.auth.user.info?.role)
  const isLoggedIn = useAppSelector((state) => state.auth.user.isLoggedIn)
  const [isEventsOpen, setIsEventsOpen] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false)

  return (
    <>
      <div className={Styles.container}>
        {userRole === 'patient' ? (
          <>
            <MobileMenuItem text='Главная' icon={main} url='/' />
            {isLoggedIn && (
              <>
                <MobileMenuItem text='Записаться' icon={pencil} url='/appointment-page' />
                <MobileMenuItem text='Мои приёмы' icon={sheet} url='/my-appointment' />
                <MobileMenuModalButton text='Чат' icon={message} onClick={() => setIsChatOpen(true)} />
              </>
            )}
            <MobileMenuItem
              text={isLoggedIn ? 'Профиль' : 'Вход / Регистрация'}
              icon={profile}
              url={isLoggedIn ? '/profile' : '/login'}
            />
          </>
        ) : (
          <>
            <MobileMenuItem text='Главная' icon={main} url='/' />
            {isLoggedIn && (
              <>
                <MobileMenuModalButton text='События' icon={events} onClick={() => setIsEventsOpen(true)} />
                <MobileMenuItem text='Пациенты' icon={patients} url='/patients' />
                <MobileMenuModalButton text='Чат' icon={message} onClick={() => setIsChatOpen(true)} />
              </>
            )}
            <MobileMenuItem text='Профиль' icon={profile} url={isLoggedIn ? '/profile' : '/login'} />
          </>
        )}
      </div>
      <MobileModal title='Чат' isOpen={isChatOpen} setIsModalOpen={() => setIsChatOpen(false)}>
        <Chat />
      </MobileModal>

      <MobileModal title='События' isOpen={isEventsOpen} setIsModalOpen={() => setIsEventsOpen(false)}>
        <div className={Styles.container_mobile}>
          <SideMenuBody activeContent='bell' />
        </div>
      </MobileModal>
    </>
  )
}
