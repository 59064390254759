// eslint-disable-next-line import/named
import { FormApi } from 'final-form'
import React, { PropsWithChildren, useCallback, useContext, useLayoutEffect, useRef, useState } from 'react'
// eslint-disable-next-line import/named
import { Form, FormProps } from 'react-final-form'
import { noop } from './function'

type FinalFormContextType = {
  forms: FormType
  putFormToState: (formId: string, formApi: FormApi<any>) => void
  deleteFormFromState: (formId: string) => void
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const FinalFormContext = React.createContext<FinalFormContextType>(null!)

type FormType = {
  [formId: string]: FormApi
}

export function FormApiStorage(props) {
  const [forms, setForms] = useState<FormType>({})

  const putFormToState = useCallback((formId: string, formApi: FormApi) => {
    setForms((prevState) => ({ ...prevState, [formId]: formApi }))
  }, [])

  const deleteFormFromState = useCallback((formId: string) => {
    setForms((prevState) => {
      const newForm = { ...prevState }
      delete newForm[formId]
      return newForm
    })
  }, [])

  return (
    <FinalFormContext.Provider
      value={{
        forms,
        putFormToState,
        deleteFormFromState,
      }}
    >
      {props.children}
    </FinalFormContext.Provider>
  )
}

export interface FormWrapperProps<FormValues = Record<string, any>, InitialFormValues = Partial<FormValues>>
  extends FormProps<FormValues, InitialFormValues> {
  formId: string
}

export function FormStorage<FormValues = Record<string, any>, InitialFormValues = Partial<FormValues>>(
  props: PropsWithChildren<FormWrapperProps<FormValues, InitialFormValues>>,
) {
  const { putFormToState, deleteFormFromState } = useContext(FinalFormContext)
  const formApiRef = useRef<FormApi<FormValues, InitialFormValues> | undefined>()

  useLayoutEffect(() => {
    if (formApiRef.current) {
      putFormToState(props.formId, formApiRef.current)
    }

    return () => {
      deleteFormFromState(props.formId)
    }
  }, [props.formId, putFormToState, deleteFormFromState])

  return (
    <Form {...props}>
      {({ form, handleSubmit }) => {
        formApiRef.current = form as FormApi<FormValues, InitialFormValues>
        return (
          <form id={props.formId} onSubmit={handleSubmit}>
            {props.children}
          </form>
        )
      }}
    </Form>
  )
}

interface FormContextSupplierProps {
  formId: string
}
export function FormContextSupplier(props: PropsWithChildren<FormContextSupplierProps>) {
  const { forms } = useContext(FinalFormContext)
  const currentFormApi = forms[props.formId]
  return (
    <Form key={props.formId + !!currentFormApi} form={currentFormApi} subscription={{}} onSubmit={noop}>
      {() => <>{props.children}</>}
    </Form>
  )
}

// How to usage:

//  const onSubmit = (formValues) => {
//    console.log('данные, которые введены в форме: ', formValues)
//  }

//  Компонент FormStorage - это вместо <Form> тут сразу вся апиха из final-form будет
//  <FormStorage formId='testForm' onSubmit={onSubmit}>
//    <div />
//  </FormStorage>

// А чтобы получить эту апиху вне формы и вызывать события submit,
// состояние валидности и все остальное нужно юзать вне формы так:

// Допустим кнопка:
// ВААЖНО: formId у компонента <FormContextSupplier> должен быть равен formId, который указан в форме
// <FormContextSupplier formId='testForm'>
//    <FormSpy>
//      {({ form }) => {
//        return (
//          <button onClick={form.submit}>
//            Сохранить
//          </button>
//        )
//      }}
//    </FormSpy>
// </FormContextSupplier>
