import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { DoctorTodayMobile } from '../../../DoctorMonth/components/DoctorToday/components/DoctorTodayMobile'
import { DoctorPopupMobile } from '../../../../../DoctorModal/components/DoctorPopupMobile'
import { DoctorRecordInfo } from '../../../DoctorRecordInfo'

export const DoctorDayMobile: React.FC = () => {
  const appointmentsToday = useAppSelector((state) => state.doctor.appointmentsToday)
  const [currentAppointments, setCurrentAppointments] = useState(null)
  const [selectedAppointment, setSelectedAppointment] = useState(null)

  useEffect(() => {
    setCurrentAppointments(appointmentsToday[0])
  }, [appointmentsToday])

  return (
    <div className={Styles.schedule}>
      {currentAppointments && currentAppointments.receptions.length !== 0 ? (
        <DoctorTodayMobile
          day={currentAppointments.day}
          receptions={currentAppointments.receptions}
          setSelectedAppointment={setSelectedAppointment}
        />
      ) : (
        <span className={Styles.empty}>Нет записей на этот день</span>
      )}
      {selectedAppointment && (
        <DoctorPopupMobile closePopup={() => setSelectedAppointment(null)}>
          <DoctorRecordInfo selectedAppointment={selectedAppointment} />
        </DoctorPopupMobile>
      )}
    </div>
  )
}
