import React, { ReactNode } from 'react'
import { Tag } from '@components/ui/Tag'
import Style from './style.module.scss'
import classNames from 'classnames/bind'

export const ContainerWithTag = ({
  tagName,
  children,
  customStyle,
}: {
  tagName?: string
  children?: ReactNode
  customStyle?: string
}) => {
  const cx = classNames.bind(Style)
  return (
    <div className={cx(Style.containerWithTag, customStyle)}>
      <Tag value={tagName} customStyle={Style.badge} />
      {children}
    </div>
  )
}
