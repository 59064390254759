import React, { useEffect, useState } from 'react'
import useWindowSize from '../../helpers/useWindowSizeHook'
import { DownloadMedicalReportNotification } from './components/DownloadMedicalReportNotification'
import { AppointmentNotification } from './components/AppointmentNotification'
import { MedicalReportNotification } from './components/MedicalReportNotification'
import { formateDate } from '../../utils/formateDate'
import { MOBILE_BREAKPOINT } from '../../global-constants'
import Styles from './style.module.scss'
// import { useAppDispatch } from '@app/redux/hooks'
// import { showNewNotification } from '../../pages/authorization/redux/authSlice'

/* eslint-disable camelcase */
interface NotificationsProps {
  notifications: [
    {
      id: number
      created_at: number
      content_type: string
      message: string
      content_object?: {
        id: number
        meeting_link?: string
        status: string
      }
    },
  ]
}

export const Notifications: React.FC<NotificationsProps> = ({ notifications }) => {
  // const dispatch = useAppDispatch()
  const { width } = useWindowSize()

  const [reportDownload, setReportDownload] = useState(null)
  const [displayedNotifications, setDisplayedNotifications] = useState([])
  const slicedNotifications = notifications.slice(0, 20)

  const renderDate = (date) => {
    const countDays = new Date().getDate() - new Date(date).getDate()

    if (countDays === 0) {
      return 'Сегодня'
    } else if (countDays === 1) {
      return 'Вчера'
    } else {
      return formateDate(date, 'D MMMM, dddd')
    }
  }

  useEffect(() => {
    if (width > MOBILE_BREAKPOINT) {
      const reports = slicedNotifications.filter(
        (notification) => notification.message === 'Медицинское заключения готово!',
      )

      if (reports.length !== 0) {
        setReportDownload(reports[0])
      }
    }

    const uniqDate = [
      ...new Set(slicedNotifications.map((notification) => formateDate(notification.created_at, 'YYYY-MM-DD'))),
    ]

    const array = uniqDate.map((date) => {
      const arr = slicedNotifications.filter(
        (notification) => date === formateDate(notification.created_at, 'YYYY-MM-DD'),
      )

      return { date, arr }
    })

    setDisplayedNotifications(array)
  }, [notifications, width])

  return (
    <div className={Styles.content}>
      {width > MOBILE_BREAKPOINT && reportDownload && (
        <DownloadMedicalReportNotification notification={reportDownload} />
      )}
      {displayedNotifications.length !== 0 &&
        displayedNotifications.map((notifications, index) => {
          return (
            <div key={index} className={Styles.container}>
              <span className={Styles.date}>{renderDate(notifications.date)}</span>
              {notifications.arr.map((notification) => {
                switch (notification.content_type) {
                  case 'medicalreport':
                    return <MedicalReportNotification key={notification.id} notification={notification} />
                    break
                  case 'appointment':
                    return <AppointmentNotification key={notification.id} notification={notification} />
                    break
                  case null:
                    return <AppointmentNotification key={notification.id} notification={notification} />
                    break
                  default:
                    return null
                }
              })}
            </div>
          )
        })}
    </div>
  )
}
