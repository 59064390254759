import React from 'react'
import Styles from './style.module.scss'

interface ButtonCloseProps {
  className?: string
  onClick: () => void
}
export const ButtonClose: React.FC<ButtonCloseProps> = (props) => {
  return <button className={`${Styles.button} ${props.className || ''}`} onClick={props.onClick} />
}
