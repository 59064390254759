import classNames from 'classnames'
import React from 'react'
import Styles from './style.module.scss'

interface CustomRadioInputProps {
  error?: boolean
  smallSize?: boolean
  id: string
  onChange?: (e: any) => void
  name: string
  checked?: boolean
  value?: string
  htmlFor: string
  src?: string
  title: string
  price?: any
  text?: string
}

export const CustomRadioInput: React.FC<CustomRadioInputProps> = (props) => {
  const cx = classNames.bind(Styles)
  const labelStyle = cx(Styles.label, {
    [`${Styles.label_error}`]: props.error || false,
    [`${Styles.label_small}`]: props.smallSize || false,
  })
  const markStyle = cx(Styles.mark, {
    [`${Styles.mark_small}`]: props.smallSize || false,
  })
  return (
    <div className={Styles.custom__input}>
      <input
        type='radio'
        id={props.id}
        onChange={props.onChange}
        name={props.name}
        checked={props.checked}
        value={props.value || ''}
      />
      <label className={labelStyle} htmlFor={props.htmlFor}>
        <h4 className={Styles.title}>
          {props.src && <img src={props.src} className={Styles.img} alt='img' />}
          {props.title}
        </h4>
        {props.price && (
          <div className={Styles.price}>
            {`${props.price} `}
            <span>₽</span>
          </div>
        )}
        {props.text && <p className={Styles.text}>{props.text}</p>}
        <span className={markStyle} />
      </label>
    </div>
  )
}
