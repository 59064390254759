import React from 'react'
import Styles from './style.module.scss'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setCurrentDialogueMember, setCurrentSlug } from '../redux/chatSlice'
import { Dialogue } from '../Dialogue'

export const DialoguesPage = ({ setCurrentPage }) => {
  const dispatch = useAppDispatch()
  const dialogues: any = useAppSelector((state) => state.chat.dialogues)

  const handleDialogueClick = (dialogue) => {
    setCurrentPage(2)
    dispatch(setCurrentSlug(dialogue.slug))
    dispatch(setCurrentDialogueMember(dialogue.other_user))
  }

  return (
    <div className={Styles.container}>
      {dialogues?.map((dialogue) => {
        return (
          <div key={dialogue.created_at} onClick={() => handleDialogueClick(dialogue)} className={Styles.dialogue}>
            <Dialogue
              firstName={dialogue.other_user?.first_name}
              lastName={dialogue.other_user?.last_name}
              lastMessage={dialogue.last_message?.text}
              avatar={dialogue.other_user?.avatar}
            />
          </div>
        )
      })}
    </div>
  )
}
