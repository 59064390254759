export const doctorInfo = {
  name: 'Наталья Викторовна Салимова',
  specs: 'Врач превентивной и антивозрастной медицины, врач нутрициолог, клинический фармаколог',
  expirience: '25 лет',
  patients: '313',
  education: [
    '2021 — Международный Институт Интегративной Превентивной и Антивозрастной Медицины «PreventAge», дополнительная профессиональная программа «Интегральная превентивная и антивозрастная медицина»',
    '2021 — XV Международный Конгресс ISSAM',
    '2021 — учебный центр Клиники Профессора Калинченко, сертификационный цикл повышения квалификации',
    '2020 — Stanford University, сертификационный цикл «Stanford Introduction to Food and Health»',
    '2019 — XIII Международный Крнгресс ISSAM, участник',
    '2019 — НИЦ «MyGenetics», сертификационный цикл «Нутригенетика»',
    '2019 — Академия последипломного образования ФГБУ ФНКЦ ФМБА России, повышение квалификации «Основы нутрициологии»',
    '2019 — ФПК МР РУДН, кафедра эндокринологии, повышение квалификации «Эндокринные аспекты репродуктивного здоровья», учебный цикл в Клинике Профессора Калинченко',
    '2005 — АГМА, профессиональная переподготовка «Врач-клинический фармаколог»',
    '1998 — интернатура в АГМА, «Врач-инфекционист»',
    '1997 — Амурская Государственная Медицинская Академия (АГМА), «Врач-лечебник»',
  ],

  career: [
    '2019 — настоящее время — Частная практика, врач превентивной и антивозрастной медицины, врач-нутрициолог»',
    '2014 — настоящее время, Руководитель сети ИНВИТРО в Амурской области. Руководитель медицинского центра «ВИВАМЕД»',
    '2005 — 2016 — Детская городская клиническая больница, г. Благовещенск, врач-клинический фармаколог',
    '1997 — 2005 — Врач-инфекционист, Амурская Областная инфекционная больница',
  ],
}
