/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'

import timeIcon from '@icons/time.svg'

import { formateDate } from '../../../../../../../../utils/formateDate'
import { NOTIFICATION_OF_MINUTES } from '../../../../../../constants'
import { DoctorAppointmentButton } from '../../../DoctorAppointmentButton'

interface DoctorRecordProps {
  appointment: {
    id: number
    consultation_type: any
    day_reception: string
    is_online: boolean
    medical_report: number
    meeting_link: string
    patient: any
    status: string
    time_reception: string
  }
  className?: string
}

export const DoctorRecord: React.FC<DoctorRecordProps> = (props) => {
  const { id, consultation_type, day_reception, patient, time_reception, is_online, status } = props.appointment
  const className = props
  const [notification, setNotification] = useState(null)
  const [thisStatus, setThisStatus] = useState(status)

  let classNameRecord = thisStatus === 'planned' ? `${Styles.record}` : `${Styles.record} ${Styles.record_complete}`
  let classNameHeader = is_online ? `${Styles.header}` : `${Styles.header} ${Styles.header_mod}`

  if (className) {
    classNameRecord = `${classNameRecord} ${className.className}`
  }

  if (thisStatus === 'done') {
    classNameHeader = `${classNameHeader} ${Styles.header_complete}`
  }

  if (thisStatus === 'cancel') {
    classNameHeader = `${classNameHeader} ${Styles.header_cancel}`
  }

  useEffect(() => {
    if (formateDate(new Date(), 'YYYY-MM-DD') === formateDate(new Date(day_reception), 'YYYY-MM-DD')) {
      const currentTime = new Date().getTime()
      const receptionTime = new Date(`${day_reception} ${time_reception}`).getTime()

      if (currentTime < receptionTime) {
        const diffMinutes = receptionTime - currentTime
        const minutes = diffMinutes / 60000

        if (minutes <= NOTIFICATION_OF_MINUTES) {
          setNotification(Math.ceil(minutes))
        }
      }
    }
  }, [])

  return (
    <div className={classNameRecord}>
      <div className={classNameHeader}>
        <div className={Styles.container}>
          <span className={Styles.time}>{time_reception}</span>
          <span className={Styles.online}>{is_online ? 'Онлайн прием' : 'Личный прием'}</span>
        </div>
        {notification && (
          <div className={Styles.notification}>
            <img src={timeIcon} alt='Иконка' />
            <span className={Styles.across}>Через {notification} минут</span>
          </div>
        )}
        <DoctorAppointmentButton
          appointment={props.appointment}
          thisStatus={thisStatus}
          setThisStatus={setThisStatus}
        />
      </div>
      <div className={Styles.patient}>
        {patient.avatar ? (
          <img className={Styles.avatar} src={patient.avatar} alt='Аватар' />
        ) : (
          <div className={Styles.cap}>
            <span>
              {patient?.last_name?.substring(0, 1)}
              {patient?.first_name?.substring(0, 1)}
            </span>
          </div>
        )}
        <div className={Styles.info}>
          <Link className={Styles.lastname} to={`/patients/${patient.id}`}>
            {patient.last_name}
          </Link>
          <span className={Styles.firstname}>{patient.first_name}</span>
        </div>
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>Консультация</span>
        <p className={Styles.description}>{consultation_type?.direction.title}</p>
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>Тип консультации</span>
        <div className={Styles.wrap}>
          <p className={Styles.description}>{consultation_type?.title}</p>
          <Link className={Styles.link} to={`/appointments/${id}`}>
            {}
          </Link>
        </div>
      </div>
    </div>
  )
}
