import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const postMedicalReport: any = createAsyncThunk('reports/postMedicalReport', async (values) => {
  return await api.post('medical-report/', values)
})

export const patchMedicalReport: any = createAsyncThunk('reports/patchMedicalReport', async (values: any) => {
  return await api.patch(`medical-report/${values.id}/`, values.arr)
})

export const getDietReport: any = createAsyncThunk('reports/getDietReport', async (values) => {
  return await api.get('diet-report/', values)
})

export const postDietReport: any = createAsyncThunk('reports/postDietReport', async (values) => {
  return await api.post('diet-report/', values)
})

export const patchDietReport: any = createAsyncThunk('reports/patchDietReport', async (values: any) => {
  return await api.patch(`diet-report/`, values)
})

export const postLifestyleReport: any = createAsyncThunk('reports/postLifestyleReport', async (values) => {
  return await api.post('lifestyle-report/', values)
})
export const patchLifestyleReport: any = createAsyncThunk('reports/patchLifestyleReport', async (values: any) => {
  return await api.patch(`lifestyle-report/`, values)
})

export const postNutraceuticalReport: any = createAsyncThunk('reports/postNutraceuticalReport', async (values) => {
  return await api.post('nutraceutical-report/', values)
})

export const patchNutraceuticalReport: any = createAsyncThunk(
  'reports/patchNutraceuticalReport',
  async (values: any) => {
    return await api.patch('nutraceutical-report/', values)
  },
)

export const postResearchReport: any = createAsyncThunk('reports/postResearchReport', async (values) => {
  return await api.post('research-report/', values)
})

export const patchResearchReport: any = createAsyncThunk('reports/patchResearchReport', async (payload: any) => {
  return await api.patch(`research-report/${payload.id}/`, payload.data)
})
export const deleteResearchReport: any = createAsyncThunk('reports/patchResearchReport', async (id) => {
  return await api.delete(`research-report/${id}/`)
})
export const getMedicalReportId: any = createAsyncThunk('reports/getMedicalReportId', async (appointmentId) => {
  return await api.get(`appointments/${appointmentId}/`)
})
export const getMedicalReportById: any = createAsyncThunk('reports/getMedicalReportById', async (id) => {
  return await api.get(`medical-report/${id}/`)
})
export const getExerciseList: any = createAsyncThunk('reports/getLifestyleList', async () => {
  return await api.get('exercise/')
})
export const doneReport: any = createAsyncThunk('reports/doneReport', async (id) => {
  return await api.get(`medical-report/${id}/done/`)
})

export const getNutraceuticals: any = createAsyncThunk('reports/getNutraceuticals', async () => {
  const response = await api.get(`nutraceutical/`)
  return response
})

export const getResearch: any = createAsyncThunk('reports/getResearch', async () => {
  const response = await api.get(`research/`)
  return response
})

export const getNutraceuticalSettings: any = createAsyncThunk('reports/getNutraceuticalSettings', async () => {
  const response = await api.get(`nutraceutical-settings/`)
  return response
})
export const getResearchSettings: any = createAsyncThunk('reports/getResearchSettings', async () => {
  const response = await api.get(`research-settings/`)
  return response
})
export const getAnalysisResult: any = createAsyncThunk('reports/getAnalysisResult', async () => {
  const response = await api.get(`analysis-results-title/`)
  return response
})
export const postAnalysisResult: any = createAsyncThunk('reports/getAnalysisResult', async (data) => {
  const response = await api.post(`analysis-result/`, data)
  return response
})
export const patchAnalysisResult: any = createAsyncThunk('reports/patchAnalysisResult', async (payload: any) => {
  const response = await api.patch(`analysis-result/${payload.id}/`, payload.value)
  return response
})
export const patchAnalysisResultTitle: any = createAsyncThunk(
  'reports/patchAnalysisResultTitle',
  async (payload: any) => {
    const response = await api.patch(`analysis-results-title/${payload.id}/`, payload.value)
    return response
  },
)
export const postAnalysisResultMeasure: any = createAsyncThunk(
  'reports/patchAnalysisResultMeasure',
  async (payload: any) => {
    const response = await api.post(`analysis-results-measure/`, payload)
    return response
  },
)

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    modalIsOpen: false,
    appointmentId: null,
    medicalReportId: null,
    exerciseList: null,
    nutraceuticals: null,
    nutraceuticalSettings: [],
    researchSettings: [],
    selectedOptions: [],
    research: null,
    currentMedicalReport: null,
  },
  reducers: {
    setReportsModalOpen(state, { payload }) {
      state.modalIsOpen = payload
    },
    setAppointmentId(state, { payload }) {
      state.appointmentId = payload
    },
    setMedicalReportId(state, { payload }) {
      state.appointmentId = payload
    },
  },
  extraReducers: {
    [postMedicalReport.fulfilled]: (state, { payload }) => {
      if (payload.status === 201) {
        state.medicalReportId = payload.data.id
      }
    },
    [getMedicalReportId.fulfilled]: (state, { payload }) => {
      state.medicalReportId = payload?.data?.medical_report?.id
    },

    [getExerciseList.fulfilled]: (state, { payload }) => {
      state.exerciseList = payload?.data?.results
    },
    [getNutraceuticals.fulfilled]: (state, { payload }) => {
      state.nutraceuticals = payload?.data?.results
    },
    [getNutraceuticalSettings.fulfilled]: (state, { payload }) => {
      state.nutraceuticalSettings = payload?.data
    },
    [getResearchSettings.fulfilled]: (state, { payload }) => {
      state.researchSettings = payload?.data
    },
    [getResearch.fulfilled]: (state, { payload }) => {
      state.research = payload?.data
    },
  },
})

export const { setReportsModalOpen, setAppointmentId } = reportsSlice.actions

export default reportsSlice.reducer
