import React from 'react'
import Styles from './style.module.scss'
import useWindowSize from '@helpers/useWindowSizeHook'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
// import { AuthSubmitButton } from '@components/AuthSubmitButton'

export const TabMenu = ({ activeContent, setActiveContent, isDisabled }) => {
  const { width } = useWindowSize()

  const desktop = width > 830

  const handleChange = (event) => {
    setActiveContent(event.target.value as string)
  }

  return desktop ? (
    <div className={Styles.wrapper}>
      <div className={Styles.tabMenu}>
        <button
          disabled={isDisabled}
          // disabled={disabled !== 'diet'}
          type='button'
          onClick={() => setActiveContent('diet')}
          className={activeContent !== 'diet' ? Styles.button : `${Styles.button} ${Styles.button_active}`}
        >
          Питание
        </button>
        <button
          type='button'
          // disabled={disabled !== 'lifeStyle'}
          disabled={isDisabled}
          onClick={() => setActiveContent('lifeStyle')}
          className={activeContent !== 'lifeStyle' ? Styles.button : `${Styles.button} ${Styles.button_active}`}
        >
          Образ жизни
        </button>
        <button
          type='button'
          // disabled={disabled !== 'nutraceutical'}
          disabled={isDisabled}
          onClick={() => setActiveContent('nutraceutical')}
          className={activeContent !== 'nutraceutical' ? Styles.button : `${Styles.button} ${Styles.button_active}`}
        >
          Нутрицевтики
        </button>
        <button
          type='button'
          // disabled={disabled !== 'research'}
          disabled={isDisabled}
          onClick={() => setActiveContent('research')}
          className={activeContent !== 'research' ? Styles.button : `${Styles.button} ${Styles.button_active}`}
        >
          Обследование
        </button>
      </div>
      {/* <AuthSubmitButton
        buttonText={`${
          reportsErrors && reportsErrors.appointment ? 'Заключение уже создано' : 'Создать медзаключение'
        }`}
        onClick={() => {}}
      /> */}
    </div>
  ) : (
    <Select value={activeContent} onChange={handleChange} defaultValue='diet'>
      <MenuItem value='diet'>Питание</MenuItem>
      <MenuItem value='lifeStyle'>Образ жизни</MenuItem>
      <MenuItem value='nutraceutical'>Нутрицевтики</MenuItem>
      <MenuItem value='research'>Обследование</MenuItem>
    </Select>
  )
}
