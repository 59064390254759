import { AuthSubmitButton } from '@components/AuthSubmitButton'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Styles from './style.module.scss'
import { useAppDispatch } from '@app/redux/hooks'
import { getUser, changeUserInfo } from '../../../../pages/authorization/redux/authSlice'

export const FirstTimeForm = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [value, setValue] = useState('')
  const [isValid, setIsValid] = useState(false)

  const handleChange = (e) => {
    setValue(e.target.value)
    setIsValid(e.target.closest('form').checkValidity())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (isValid) {
      dispatch(changeUserInfo({ first_name: value })).then(() => {
        dispatch(getUser())
        history.push('/onboarding')
      })
    } else {
      setIsValid(e.target.closest('form').checkValidity())
    }
  }
  return (
    <form className={Styles.form} onSubmit={handleSubmit}>
      <h3 className={Styles.form__title}>
        Вы впервые здесь! <span>&#x1f60a;</span>{' '}
      </h3>
      <p className={Styles.form__info}>Как мы можем обращаться к вам?</p>
      <label className={Styles.form__label}>Имя*</label>
      <input
        className={Styles.form__input}
        type='text'
        placeholder='Введите ваше имя'
        onChange={handleChange}
        value={value}
        required
        minLength={2}
      />
      <AuthSubmitButton buttonText='Завершить регистрацию' onClick={handleSubmit} />
    </form>
  )
}
