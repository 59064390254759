import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'

export const LoadRender = (props) => {
  // const dispatch = useAppDispatch()
  const [selectedFile, setSelectedFile] = useState()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [preview, setPreview] = useState('')
  const [data, setData] = useState([])

  const uploadFile = ({ target: { files } }) => {
    const body = new FormData()

    body.append('file', files[0])
    props.setFile(body)
    setSelectedFile(files[0])
  }
  useEffect(() => {
    if (!selectedFile) {
      return
    }
    setPreview(selectedFile)
    const a = data
    a.push(selectedFile)
    setData(a)
  }, [selectedFile])

  return (
    <>
      <div className={Styles.load__files}>
        {props.data?.map((item) => (
          <a target='_blank' href={item} rel='noreferrer' key={item.toString()}>
            {/\.(jpe?g|png|gif|bmp|svg)$/i.test(item) ? (
              <img src={item} alt='foto' className={Styles.load__img} />
            ) : (
              <div className={Styles.default__image}>{`.${item.match(/\.([^.]+)$|$/)[1]}`}</div>
            )}
          </a>
        ))}
        {data &&
          data.map((item) => (
            <a target='_blank' href={URL.createObjectURL(item)} rel='noreferrer' key={item.toString()}>
              {/\.(jpe?g|png|gif|bmp|svg)$/i.test(item.name) ? (
                <img src={URL.createObjectURL(item)} alt='foto' className={Styles.load__img} />
              ) : (
                <div className={Styles.default__image}>{`.${item.name.match(/\.([^.]+)$|$/)[1]}`}</div>
              )}
            </a>
          ))}
        {props.editLoadDescription && (
          <div className={Styles.load__wrap}>
            <input type='file' id='fileid' onChange={uploadFile} />
            <label className={Styles.buttonPlus} htmlFor='fileid' />
          </div>
        )}
      </div>
    </>
  )
}
