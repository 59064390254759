import { useAppSelector } from '@app/redux/hooks'
import React from 'react'
import Styles from './style.module.scss'
import { Field } from 'react-final-form'

export const NutraceuticalForm = ({ children, name }) => {
  const settings: any = useAppSelector((state) => state.reports.nutraceuticalSettings)
  const SelectReception = ({ input, meta }) => {
    const width = '1'
    return (
      <div className={Styles.container} style={width && { flex: width }}>
        <select {...input}>
          {settings.reception.map((option) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
        </select>
        {meta.touched && meta.error && <span className={`${Styles.error} ${Styles.error_mr}`}>{meta.error}</span>}
      </div>
    )
  }
  const SelectMeal = ({ input, meta }) => {
    return (
      <div className={Styles.container}>
        <select {...input}>
          {settings.meal.map((option) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
        </select>
        {meta.touched && meta.error && <span className={`${Styles.error} ${Styles.error_mr}`}>{meta.error}</span>}
      </div>
    )
  }
  const SelectPeriodicity = ({ input, meta }) => {
    return (
      <div className={Styles.container}>
        <label>Периодичность*</label>
        <select {...input}>
          {settings.periodicity.map((option) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
        </select>
        {meta.touched && meta.error && <span className={Styles.error}>{meta.error}</span>}
      </div>
    )
  }
  const required = (value) => (value ? undefined : 'Обязательное поле')
  const mustBeNumber = (value) => (isNaN(value) ? 'Введите число' : undefined)
  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce((error, validator) => error || validator(value), undefined)
  return (
    <div className={Styles.form}>
      {children}
      {settings && (
        <>
          <label className={Styles.label}>Приём*</label>
          <div className={Styles.reception}>
            {settings.reception && (
              <Field
                name={`${name}.reception`}
                component={SelectReception}
                validate={required}
                defaultValue={settings.reception[0].id}
              />
            )}
            {settings.meal && (
              <Field
                name={`${name}.meal`}
                component={SelectMeal}
                validate={required}
                defaultValue={settings.meal[0].id}
              />
            )}
          </div>
        </>
      )}
      <div className={Styles.periodicity}>
        <Field
          validate={composeValidators(required, mustBeNumber)}
          name={`${name}.amount`}
          render={({ input, meta }) => (
            <div className={Styles.quantity}>
              <label>Количество*</label>
              <input {...input} autoComplete='off' />
              {meta.touched && meta.error && <span className={Styles.error}>{meta.error}</span>}
            </div>
          )}
        />
        {settings.periodicity && (
          <Field name={`${name}.periodicity`} component={SelectPeriodicity} defaultValue={settings.periodicity[0].id} />
        )}
      </div>

      <Field
        name={`${name}.course_duration`}
        validate={composeValidators(required, mustBeNumber)}
        render={({ input, meta }) => (
          <div className={Styles.duration}>
            <label>Продолжительность курса*</label>
            <div>
              <input {...input} autoComplete='off' />
              <div className={Styles.thing}>Дней</div>
            </div>
            {meta.touched && meta.error && <span className={Styles.error}>{meta.error}</span>}
          </div>
        )}
      />
      <Field
        name={`${name}.remark`}
        render={({ input }) => (
          <div className={Styles.misc}>
            <label>Примечание (необязательно)</label>
            <textarea {...input} placeholder='Введите примечание' />
            <p>Например, «Нельзя употреблять с кофе»</p>
          </div>
        )}
      />
    </div>
  )
}
