import React, { FC } from 'react'
import styles from './style.module.scss'
import { Tag } from '@components/ui/Tag'
import classNames from 'classnames/bind'
import { getTagBackgroundClassname, getStatusValue } from '../../utils/getTagBackgroundClassname'
// import { translateDeliveryDateFromEnglish } from '../../utils/translateDeliveryDateFromEnglish'
import { formateDate } from '../../../../utils/formateDate'

interface OrderDeliveryStatusProps {
  deliveryInfo: any
}

export const OrderDeliveryStatus: FC<OrderDeliveryStatusProps> = ({ deliveryInfo }) => {
  const upperTitle = deliveryInfo.pvz_address === '' ? 'Доставка курьером' : 'Доставка в пункт выдачи'
  const cx = classNames.bind(styles)

  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        {/* TODO: с бэка не приходит куда доставка */}
        <p className={styles.upper_title}>{upperTitle}</p>
        <Tag
          value={getStatusValue(deliveryInfo.status)}
          customStyle={cx(styles.tag, getTagBackgroundClassname(deliveryInfo.status))}
        />
      </div>
      {deliveryInfo.delivery_dates && (
        <div className={styles.delivery}>
          <p className={styles.delivery_title}>Дата доставки:</p>
          <p className={styles.delivery_dates}>
            {`${formateDate(deliveryInfo.delivery_dates.from, 'DD MMMM')} -
            ${formateDate(deliveryInfo.delivery_dates.to, 'DD MMMM')}`}
          </p>
        </div>
      )}
    </div>
  )
}
