import React from 'react'
import Styles from './style.module.scss'
// import icon from '@icons/medical-report-icon.svg'
import bellIcon from '@icons/bell-icon.svg'
import moment from 'moment'

export const AppointmentNotification = ({ notification }) => {
  return (
    <div className={Styles.content}>
      <div className={Styles.event}>
        <img src={bellIcon} alt='icon' />
        <div className={Styles.eventContent}>
          <h3>{notification.message}</h3>
          <p>{moment(notification.created_at).format('DD.MM.YYYY HH:MM')}</p>
          {/* <p>{`${notification?.content_object.consultation_type.title} ${notification?.content_object.consultation_type.direction.title}`}</p> */}
        </div>
      </div>
    </div>
  )
}
