import React from 'react'
import Styles from './style.module.scss'

interface AuthSubmitButtonProps {
  buttonText: string
  onClick?: (e: any) => void
}

export const AuthSubmitButton: React.FC<AuthSubmitButtonProps> = ({ buttonText, onClick }) => {
  return (
    <button type='submit' className={Styles.form__button} onClick={onClick}>
      {buttonText}
    </button>
  )
}
