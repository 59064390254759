import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setOrdering } from '../../../../pages/blog/redux/blogSlice'
import React from 'react'
import { FilterItem } from './components/FilterItem'
import Styles from './style.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import useWindowSize from '@helpers/useWindowSizeHook'
import { MOBILE_BREAKPOINT } from '../../../../global-constants'

export const BlogFilters = () => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const desktop = width > MOBILE_BREAKPOINT
  const scopes = useAppSelector((state) => state.blog.scopes)
  const ordering = useAppSelector((state) => state.blog.ordering)

  const handleOrdering = (e) => {
    dispatch(setOrdering(e.target.value))
  }
  return (
    <div className={Styles.container}>
      {desktop ? (
        <div className={Styles.filters}>
          <FilterItem title='Все статьи' value='' />
          {scopes.results?.length > 0 &&
            scopes.results.map((scope) => <FilterItem key={scope.id} title={scope.title} value={scope.title} />)}
        </div>
      ) : (
        <div className={Styles.filters}>
          <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={10}>
            <SwiperSlide className={Styles.slide}>
              <FilterItem title='Все статьи' value='' />
            </SwiperSlide>
            {scopes.results?.length > 0 &&
              scopes.results.map((scope) => (
                <SwiperSlide key={scope.id} className={Styles.slide}>
                  <FilterItem title={scope.title} value={scope.title} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      )}
      <div className={Styles.ordering}>
        <select className={Styles.orderingSelect} defaultValue={ordering} onChange={handleOrdering}>
          <option value='popularity'>По популярности</option>
          <option value='-created_at'>Сначала новые</option>
          <option value='created_at'>Сначала старые</option>
        </select>
      </div>
    </div>
  )
}
