import { useAppSelector } from '@app/redux/hooks'
import React from 'react'
import Styles from './style.module.scss'

interface OrderingItemProps {
  name: string
  rightImage?: any
  leftImage?: any
  cleanFilter?: any
  value: string
  onClick: any
  aditionalValue?: string[]
}

export const OrderingItem: React.FC<OrderingItemProps> = (props) => {
  const productFilter = useAppSelector((state) => state.catalog.productFilter)
  const { name, leftImage, rightImage, cleanFilter, value, onClick, aditionalValue } = props
  const ordered = productFilter[value] || aditionalValue?.find((value) => productFilter[value])

  return (
    <div className={ordered ? `${Styles.item} ${Styles.item_active}` : `${Styles.item}`} onClick={onClick}>
      {leftImage && <img className={Styles.left} src={leftImage} alt='Иконка' />}
      <span className={Styles.text}>{name}</span>
      {rightImage && <img className={Styles.right} src={rightImage} alt='Иконка' onClick={cleanFilter} />}
      {ordered && <span className={Styles.sign}>{}</span>}
    </div>
  )
}
