import React from 'react'
import Styles from './style.module.scss'

interface IDeliveryFragmentProps {
  address: string
  workTime: string
  periodCalc: any
}

export const DeliveryFragment: React.FC<IDeliveryFragmentProps> = ({ address, workTime, periodCalc }) => {
  const dataPvz = periodCalc.filter((item) => item.type === 'pvz')
  const days = `${dataPvz[0].period_min} - ${dataPvz[0].period_max}`
  const price = dataPvz[0].delivery_sum

  return (
    <>
      <p className={Styles.point__address}>{address}</p>
      <p className={Styles.point__calendar}>
        {workTime.split(',').map((time, idx) => {
          return (
            <React.Fragment key={idx}>
              <span>{time}</span>
              <br />
            </React.Fragment>
          )
        })}
      </p>
      <p className={Styles.point__term}>
        <span className={Styles.point_mobile}>Срок доставки: </span>
        {days} дня
      </p>
      <p className={Styles.point__price}>
        <span className={Styles.point_mobile}>Стоимость </span>
        {price} ₽
      </p>
    </>
  )
}
