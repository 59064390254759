import React from 'react'
// import useWindowSize from '@helpers/useWindowSizeHook'
// import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import Styles from './style.module.scss'
import { useAppSelector } from '@app/redux/hooks'

export const CatalogDescription: React.FC = () => {
  // const { width } = useWindowSize()
  const product = useAppSelector((state) => state.product.product)

  return (
    <div className={Styles.description} dangerouslySetInnerHTML={{ __html: product.description }}>
      {/* {width > 830 ? (
        <>
          <div className={Styles.block}>
            <ul className={Styles.list}>
              <li className={Styles.item}>Комплекс натуральных питательных веществ</li>
              <li className={Styles.item}>20 г коллагена в одной порции</li>
              <li className={Styles.item}>Для здоровых волос, кожи, ногтей и суставов</li>
              <li className={Styles.item}>Экологически чистый продукт (из рогатого скота, выращенного на пастбище)</li>
              <li className={Styles.item}>Пищевая добавка</li>
              <li className={Styles.item}>Кошерный продукт</li>
              <li className={Styles.item}>Продукт соответствует требованиям программы Whole30</li>
              <li className={Styles.item}>Не содержит молочных продуктов</li>
              <li className={Styles.item}>Не содержит глютена</li>
              <li className={Styles.item}>Не содержит бисфенола А</li>
            </ul>
          </div>
          <div className={Styles.block}>
            <span className={Styles.title}>Прекрасный внешний вид и самочувствие</span>
            <p className={Styles.text}>
              Изготовленные из одного простого ингредиента, наши пептиды коллагена имеют нейтральный вкус и отлично
              подходят для добавления в воду, смузи, кофе, разные блюда и многое другое. Наши пептиды легко усваиваются,
              их можно растворятьв горячих или холодных жидкостях и принимать в любое время суток.
            </p>
          </div>
          <div className={Styles.block}>
            <span className={Styles.title}>Рекомендации по применению</span>
            <p className={Styles.text}>Смешайте 1–2 мерные ложки продукта и 8 ложек жидкости, тщательно перемешайте.</p>
          </div>
          <div className={Styles.block}>
            <span className={Styles.title}>Ингредиенты</span>
            <p className={Styles.text}>
              Пептиды из бычьего коллагена. Данный продукт производится на предприятии,где обрабатывается молоко, рыба,
              и древесные орехи.
            </p>
          </div>
          <div className={Styles.block}>
            <span className={Styles.title}>Предупреждения</span>
            <p className={Styles.text}>
              Перед применением во время беременности, лактации или при наличии каких-либо заболеваний следует
              проконсультироваться с врачом.
            </p>
          </div>
          <div className={Styles.block}>
            <span className={Styles.title}>Правила хранения</span>
            <p className={Styles.text}>
              Хранить в сухом и прохладном месте. Не следует использовать данный продукт, если защитная пленка
              повреждена или отсутствует.
            </p>
          </div>
        </>
      ) : (
        <>
          <div className={Styles.block}>
            <ul className={Styles.list}>
              <li className={Styles.item}>Комплекс натуральных питательных веществ</li>
              <li className={Styles.item}>20 г коллагена в одной порции</li>
              <li className={Styles.item}>Для здоровых волос, кожи, ногтей и суставов</li>
              <li className={Styles.item}>Экологически чистый продукт (из рогатого скота, выращенного на пастбище)</li>
              <li className={Styles.item}>Пищевая добавка</li>
              <li className={Styles.item}>Кошерный продукт</li>
              <li className={Styles.item}>Продукт соответствует требованиям программы Whole30</li>
              <li className={Styles.item}>Не содержит молочных продуктов</li>
              <li className={Styles.item}>Не содержит глютена</li>
              <li className={Styles.item}>Не содержит бисфенола А</li>
            </ul>
          </div>
          <div className={Styles.block}>
            <span className={Styles.title}>Прекрасный внешний вид и самочувствие</span>
            <p className={Styles.text}>
              Изготовленные из одного простого ингредиента, наши пептиды коллагена имеют нейтральный вкус и отлично
              подходят для добавления в воду, смузи, кофе, разные блюда и многое другое. Наши пептиды легко усваиваются,
              их можно растворятьв горячих или холодных жидкостях и принимать в любое время суток.
            </p>
          </div>
          <ButtonTransparent value='Смотреть описание полностью' />
        </>
      )} */}
    </div>
  )
}
