import React from 'react'
import { LifeStyle } from '../LifeStyle'
import Styles from './style.module.scss'

export const LifeStyleDrawer = (props) => {
  return (
    <div className={Styles.container}>
      <LifeStyle data={props.data} />
    </div>
  )
}
