import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getMyCart: any = createAsyncThunk('cart/getMyCart', async function () {
  const response = await api.get(`cart/my/`)
  return response.data
})
export const postCart: any = createAsyncThunk('cart/postCart', async function (data) {
  const response = await api.post(`cart/add-item/`, data)
  if (response.status === 200) {
    return response.data
  } else {
    throw new Error(response.statusText)
  }
})
export const clearCart: any = createAsyncThunk('cart/clearCart', async function () {
  const response = await api.delete(`cart/clear-items/`)
  return response.data
})
export const deleteCartId: any = createAsyncThunk('cart/deleteCartId', async function (data) {
  const response = await api.deleteWithBody(`cart/remove-item/`, data)
  return response.data
})
export const patchCartId: any = createAsyncThunk('cart/patchCartId', async function (data) {
  const response = await api.put(`cart/update-item/`, data)
  return response.data
})
export const getListDeliveryPoint: any = createAsyncThunk('cart/getListDelivery', async function () {
  const response = await api.get(`cdek/delivery-point/`)
  return response.data
})
export const postCartCheckout: any = createAsyncThunk('cart/postCartCheckout', async function (data: any) {
  const response = await api.post(`cart/checkout/`, data)
  return response.data
})
export const searchCity: any = createAsyncThunk('cart/searchCity', async function (data: any) {
  const response = await api.get(`cdek/cities/?search=${data}`)
  return response.data
})

export const postDeliveryCalc: any = createAsyncThunk('cart/postDeliveryCalc', async function (data: any) {
  const response = await api.post(`cart/delivery-calc/`, data)
  return response.data
})

export const postPaymentOrder: any = createAsyncThunk('cart/postPaymentOrder', async function (data: any) {
  const response = await api.post(`payment/order/`, data)
  return response.data
})

export const getOrder: any = createAsyncThunk('cart/getOrder', async function (orderId: any) {
  const response = await api.get(`orders/${orderId}/`)
  return response.data
})
export const getLabs: any = createAsyncThunk('cart/getLabs', async function (code) {
  const response = await api.get(`labs/?city=${code}`)
  return response.data
})

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    dataOrder: {
      phone: '',
      otherPhone: '',
      name: '',
      last_name: '',
      email: '',
    },
    dataSmallCatalog: [],
    dataSmallCatalogAnalisys: [],
    createdOrder: {} as any,
    cartData: {
      analysis_sets: [],
      analyzes: [],
      count: null,
      product_sets: [],
      products: [],
      // total: null,
      total_weight: null,
      analyzes_total: null,
      discount_total: null,
      order_summ: null,
      products_total: null,
      total_cost: null,
    },
  },
  reducers: {
    setDataOrder(state, { payload }) {
      state.dataOrder = payload
    },
    setCreatedOrder(state, { payload }) {
      state.createdOrder = payload
    },
    setDataSmallCatalog(state, { payload }) {
      if (state.dataSmallCatalog.length === 0) {
        state.dataSmallCatalog = [payload]
        return state
      } else {
        state.dataSmallCatalog = [...state.dataSmallCatalog, payload]
        return state
      }
    },
    setDataSmallCatalogAnalisys(state, { payload }) {
      if (state.dataSmallCatalogAnalisys.length === 0) {
        state.dataSmallCatalogAnalisys = [payload]
        return state
      } else {
        state.dataSmallCatalogAnalisys = [...state.dataSmallCatalogAnalisys, payload]
        return state
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMyCart.pending, () => {})
    builder.addCase(getMyCart.fulfilled, (state, { payload }) => {
      state.cartData = payload
    })
    builder.addCase(getMyCart.rejected, () => {})
    builder.addCase(postCart.fulfilled, (state, { payload }) => {
      state.cartData = payload
    })
    builder.addCase(deleteCartId.fulfilled, (state, { payload }) => {
      state.cartData = payload
    })
    builder.addCase(patchCartId.fulfilled, (state, { payload }) => {
      state.cartData = payload
    })
    builder.addCase(clearCart.fulfilled, (state, { payload }) => {
      state.cartData = payload
    })
  },
})
export const { setDataOrder, setDataSmallCatalog, setDataSmallCatalogAnalisys, setCreatedOrder } = cartSlice.actions
export default cartSlice.reducer
