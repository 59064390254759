import React from 'react'
import Styles from './style.module.scss'
import { useHistory } from 'react-router-dom'

export const GoBackButton = (): React.ReactElement => {
  const history = useHistory()

  return (
    <button className={Styles.button} onClick={() => history.goBack()}>
      &larr; Вернуться назад
    </button>
  )
}
