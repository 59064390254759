import React from 'react'
import Styles from './style.module.scss'

import { formateDate } from '../../../../../../../../utils/formateDate'
import { compareDate } from '../../../../../../../../utils/compareDate'

interface ConfirmProps {
  selectedCancel: any
  reopenConfirm: any
  closePopup: any
}

export const Confirm: React.FC<ConfirmProps> = ({ selectedCancel, reopenConfirm, closePopup }) => {
  return (
    <div className={Styles.wrapper}>
      <span className={Styles.caption}>Хотите возобновить прием?</span>
      {selectedCancel.day ? (
        <span className={Styles.subcaption}>
          {formateDate(selectedCancel.day, 'DD MMMM')}:&nbsp;
          {selectedCancel.time.map((time, index) => (
            <span key={index} className={Styles.time}>
              {time},&nbsp;
            </span>
          ))}
          {selectedCancel.time.length} записей
        </span>
      ) : (
        <span className={Styles.subcaption}>
          {formateDate(selectedCancel.from_date, 'DD MMMM')} - {formateDate(selectedCancel.to_date, 'DD MMMM')},&nbsp;
          {compareDate(selectedCancel.to_date, selectedCancel.from_date) + 1} дня
        </span>
      )}
      <button className={Styles.yep} onClick={() => reopenConfirm(selectedCancel.id)}>
        Да, возобновить
      </button>
      <button className={Styles.nope} onClick={closePopup}>
        Не возобновлять
      </button>
    </div>
  )
}
