import sctollToTop from '@helpers/scrollToTop'
import React, { useEffect, useRef } from 'react'
import { GoBackButton } from '../GoBackButton'
import Styles from './style.module.scss'

export const PayPage = (): React.ReactElement => {
  const component = useRef<HTMLDivElement>(null)
  useEffect(() => {
    sctollToTop(component, 90)
  }, [])
  return (
    <div className={Styles.container} ref={component}>
      <GoBackButton />
      <h2 className={Styles.header}>Способы оплаты</h2>
      <h4>Банковской картой</h4>
      <p>
        Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку
        «Оплатить заказ». Оплата происходит через ПАО СБЕРБАНК с использованием банковских карт следующих платёжных
        систем: МИР, VISA International, Mastercard Worldwide, JCB.
      </p>
      <p>
        Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз ПАО СБЕРБАНК. Соединение с
        платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования
        SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa,
        MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального
        пароля.
      </p>
      <p>
        Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации
        обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев,
        предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом
        соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.
      </p>
    </div>
  )
}
