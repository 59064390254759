import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '@app/api'
import { SUCCESS } from '@app/HTTP_CODES'
import { login } from '@app/auth'
import { removeToken, setToken } from '@app/helpers'

const token = JSON.parse(localStorage.getItem('token')) || false

export const registerUserNumber: any = createAsyncThunk('auth/registerUserNumber', async function (data) {
  const response = await api.post('user/send-code/', data)
  return response
})

export const tokenObtaining: any = createAsyncThunk('auth/tokenObtaining', async function (data) {
  return await login(data)
})

export const tokenCheck: any = createAsyncThunk('auth/tokenCheck', async function (token) {
  return await api.post('token/verify/', token)
})

export const tokenRefresh: any = createAsyncThunk('auth/tokenRefresh', async function (token) {
  return await api.post('token/refresh/', token)
})

export const getUser: any = createAsyncThunk('auth/getUser', async function () {
  return await api.get('user/me/')
})
export const getMedicalReports: any = createAsyncThunk('auth/getMedicalReports', async function () {
  return await api.get('medical-report/')
})
export const getNutraceuticalById: any = createAsyncThunk('auth/getNutraceuticalById', async function (id) {
  return await api.get(`nutraceutical/${id}/`)
})
export const getSurveysById: any = createAsyncThunk('auth/getSurveysById', async function (id) {
  return await api.get(`research/${id}/`)
})

export const getNotifications: any = createAsyncThunk('auth/getNotifications', async function () {
  return await api.get('notifications/')
})
export const readNotifications: any = createAsyncThunk('auth/readNotifications', async function (id) {
  return await api.post(`notifications/${id}/read/`, {
    is_read: true,
  })
})
export const getNotificationsAppointment: any = createAsyncThunk(
  'auth/getNotificationsAppointment',
  async function (id) {
    return await api.get(`notifications-remind/appointment/${id}/`)
  },
)
export const postNotificationsAppointment: any = createAsyncThunk(
  'auth/postNotificationsAppointment',
  async function (data) {
    return await api.post('notifications-remind/', data)
  },
)
export const patchNotificationsAppointment: any = createAsyncThunk(
  'auth/patchNotificationsAppointment',
  async function (id, data) {
    return await api.patch(`notifications-remind/${id}/`, data)
  },
)
export const changeUserInfo: any = createAsyncThunk('auth/changeUserInfo', async function (data) {
  return await api.patch('user/me/', data)
})

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {
      userId: null,
      isNewUser: false,
      isLoggedIn: !!token.access,
      token,
      info: {
        avatar: null,
        birthday: null,
        email: null,
        first_name: '',
        gender: null,
        height: 0,
        id: 0,
        last_name: '',
        lifestyle: null,
        patronymic: '',
        phone: '',
        role: '',
        weight: null,
        dynamometry: null,
        saturation: null,
        pulse: null,
        city: null,
        city_code: null,
      },
    },
    medicalReport: {
      hasMedicalReport: false,
      data: {
        count: 0,
        results: [],
      },
      firstReport: null,
    },
    isValidInput: {
      email: false,
      first_name: false,
      last_name: false,
      phone: false,
    },
    notifications: null,
    isNotification: false,
    newNotification: false,
  },
  reducers: {
    handleLogout(state) {
      removeToken()
      state.user.token = {}
      state.user.isLoggedIn = false
    },
    setRole(state, { payload }) {
      state.user.info.role = payload
    },
    setIsValidInput(state, { payload }) {
      return {
        ...state,
        isValidInput: {
          ...state.isValidInput,
          [payload.key]: payload.value,
        },
      }
    },

    updateNotifications(state, { payload }) {
      state.notifications = [...state.notifications, payload]
      state.isNotification = !state.isNotification
    },
    showNewNotification(state, { payload }) {
      state.newNotification = payload
    },
  },
  extraReducers: {
    [registerUserNumber.fulfilled]: (state, action) => {
      state.user.userId = action.payload.data.userId
      state.user.isNewUser = action.payload.data.is_new_user
    },
    [tokenObtaining.fulfilled]: (state, { payload }) => {
      if (payload.status === SUCCESS) {
        state.user.token = payload.data
        state.user.isLoggedIn = true
        setToken(payload.data)
      }
    },
    [tokenCheck.fulfilled]: (state, action) => {
      if (action.payload.status === SUCCESS) {
        state.user.isLoggedIn = true
        setToken(state.user.token)
      } else {
        state.user.isLoggedIn = false
        throw new Error('Токен просрочен')
      }
    },
    [tokenCheck.rejected]: () => {
      removeToken()
      throw new Error('access_token_invalid')
    },
    [tokenRefresh.fulfilled]: (state, action) => {
      setToken({ ...state.user.token, access: action.payload.data.access })
      state.user.isLoggedIn = true
      state.user.token = { ...state.user.token, access: action.payload.data.access }
    },
    [tokenRefresh.rejected]: () => {
      removeToken()
      window.location.reload()
    },
    [getUser.fulfilled]: (state, action) => {
      if (action.payload.status === 200) {
        state.user.info = action.payload.data
      } else {
        removeToken()
        window.location.reload()
      }
    },
    [getUser.rejected]: () => {
      removeToken()
      window.location.reload()
    },
    [getMedicalReports.fulfilled]: (state, { payload }) => {
      state.medicalReport.data = payload.data
      state.medicalReport.firstReport = payload.data
      state.medicalReport.hasMedicalReport = payload.data.count > 0
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.notifications = payload.data.results
      state.newNotification = payload.data.results.length ? !payload.data.results[0]?.is_read : false
    },
    [readNotifications.fulfilled]: (state) => {
      state.newNotification = false
    },
  },
})

export const { handleLogout, setRole, setIsValidInput, updateNotifications, showNewNotification } = authSlice.actions
export default authSlice.reducer
