import React from 'react'
import Styles from './style.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import useWindowSize from '@helpers/useWindowSizeHook'
import { MOBILE_BREAKPOINT } from '../../../global-constants'
import moment from 'moment'

interface ArticleHeaderProps {
  title: string
  date: string
  readingTime: number
  tags: string[]
  mainPicture: string
}

export const ArticleHeader: React.FC<ArticleHeaderProps> = (props) => {
  const { title, date, readingTime, tags, mainPicture } = props
  const { width } = useWindowSize()
  const desktop = width > MOBILE_BREAKPOINT
  const dateConvert = (day) => {
    return moment(day, 'YYYY-MM-DD').format('D MMMM, YYYY ')
  }
  return (
    <div className={Styles.header}>
      <h1 className={Styles.header__title}>{title}</h1>
      {desktop ? (
        <ul>
          <li>{dateConvert(date)}</li>
          <li>Чтение займёт {readingTime} мин.</li>
          {tags.map((item, idx) => (
            <li className={Styles.span} key={idx}>
              {item}
            </li>
          ))}
        </ul>
      ) : (
        <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={10}>
          <ul>
            <SwiperSlide className={Styles.slide}>
              <li>{dateConvert(date)}</li>
            </SwiperSlide>
            <SwiperSlide className={Styles.slide}>
              <li>Чтение займёт {readingTime} мин.</li>
            </SwiperSlide>
            {tags.map((item, idx) => (
              <SwiperSlide key={idx} className={Styles.slide}>
                <li className={Styles.span}>{item}</li>
              </SwiperSlide>
            ))}
          </ul>
        </Swiper>
      )}
      <img src={mainPicture} alt='Главное изображение' />
    </div>
  )
}
