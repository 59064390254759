import React from 'react'
import Styles from './style.module.scss'
import reportIcon from '@icons/report-pin.svg'
import moment from 'moment'
import { getPdf } from '../../utils/getPdf'

export const DownloadMedicalReportNotification = ({ notification }) => {
  return (
    <div key={notification?.id} className={Styles.event}>
      <img src={reportIcon} alt='icon' />
      <div className={Styles.eventContent}>
        <h3>Медицинское заключение</h3>
        {notification.content_object && (
          <button type='button' onClick={() => getPdf(notification?.content_object.id)}>
            Скачать
          </button>
        )}
      </div>
      <p>{moment(notification?.created_at).format('DD.MM.YYYY')}</p>
    </div>
  )
}
