import React from 'react'
import Styles from './style.module.scss'

export const InputCheckbox = (props) => {
  return (
    <div className={Styles.input__container}>
      <input
        className={Styles.input}
        type='checkbox'
        id={props.index}
        onChange={props.onChange}
        name={props.item.value}
      />
      <label className={Styles.label} htmlFor={props.index}>
        <img src={props.item.image} alt='img' className={Styles.img} />
        <h6 className={Styles.title}>{props.item.value}</h6>
      </label>
    </div>
  )
}
