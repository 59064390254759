import React, { FC } from 'react'
import styles from './style.module.scss'

import { Tooltip } from '../../../../../../../doctor/components/UI/Tooltip'
import classNames from 'classnames/bind'
import { ordersTypes } from '../../../../../../redux/orderTypes'
import plusIcon from '@icons/plus-icon.svg'

interface ComplexTestItemsProps {
  contents: ordersTypes.IAnalysisContentItem[]
}

export const ComplexTestItems: FC<ComplexTestItemsProps> = ({ contents }) => {
  const cx = classNames.bind(styles)
  return (
    <div className={styles.complexTestItems}>
      {contents.map((content: ordersTypes.IAnalysisContentItem, index: number) => {
        const isFirstItemClassname = index === 0 ? styles.test_color_complete : styles.test_color_active

        return (
          <div className={styles.wrapper} key={content.id}>
            <div className={cx(styles.test, isFirstItemClassname)}>
              <p className={styles.symbol}>{content.short_title}</p>
              <Tooltip className={styles.tooltip}>{content.title}</Tooltip>
            </div>
            {index !== contents.length - 1 && <img className={styles.plus} src={plusIcon} alt='Иконка' />}
          </div>
        )
      })}
    </div>
  )
}
