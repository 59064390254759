import logo from '@icons/logo.svg'
import React from 'react'
import Styles from './style.module.scss'
import { useHistory } from 'react-router-dom'

export const Logo = () => {
  const history = useHistory()
  const handleClick = () => {
    history.push('/')
  }
  return (
    <div className={Styles.logo__container} onClick={handleClick}>
      <div className={Styles.logo__wrapper}>
        <img src={logo} className={Styles.logo} alt='logo' />
      </div>
      <span className={Styles.logo__span}>Персональные лаборатории</span>
    </div>
  )
}
