import { LKSearch } from '@components/LKSearch'
import { Logo } from '@components/Logo'
import { MobileModal } from '@components/MobileModal'
import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useHistory, Link } from 'react-router-dom'
import Styles from './style.module.scss'
import search from '@icons/search-icon.svg'
import { ProfileModal } from '@components/ProfileModal'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { openProfileModal } from '@components/ProfileModal/redux/profileSlice'
import useWindowSize from '@helpers/useWindowSizeHook'
import { makeAnchorScroll } from '../../utils/makeAnchorScroll'
import { ProfileButton } from './ProfileButton'
import { CartHeaderComponent } from './CartHeaderComponent'
import { getMyCart } from '../../pages/cart/redux/cartSlicer'
import { MOBILE_BREAKPOINT } from '../../global-constants'

export const Header = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const user = useAppSelector((state) => state.auth.user.info)
  const loggedIn = useAppSelector((state) => state.auth.user.isLoggedIn)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { width } = useWindowSize()
  const desktop = width >= 829
  const cartData = useAppSelector((state) => state.cart.cartData)
  const [isActiveSearch, setIsActiveSearch] = useState(false)

  const showSearch = () => {
    setIsActiveSearch(true)
  }

  const hideSearch = () => {
    setIsActiveSearch(false)
  }

  useEffect(() => {
    if (loggedIn) {
      dispatch(getMyCart())
    }
  }, [loggedIn])

  return location.pathname === '/login' ||
    location.pathname === '/onboarding' ||
    (location.pathname === '/appointment-page' && !desktop) ? null : (
    <div
      className={`${Styles.header_container} ${
        location.pathname === '/' && !desktop && user.role !== 'doctor' && user.role !== 'patient' && Styles.header_main
      }`}
    >
      <NavLink className={Styles.logo} to='/'>
        <Logo />
      </NavLink>
      <div className={Styles.header_container_content}>
        <div className={Styles.block}>
          {!loggedIn && location.pathname === '/' && (
            <>
              <div className={Styles.navlinkLanding} onClick={() => makeAnchorScroll('#doctor')}>
                Врач
              </div>
              <div className={Styles.navlinkLanding} onClick={() => makeAnchorScroll('#howItWorks')}>
                Как это работает?
              </div>
              <div className={Styles.navlinkLanding} onClick={() => makeAnchorScroll('#consultations')}>
                Наши консультации
              </div>
              {/* <div className={Styles.navlinkLanding} onClick={() => makeAnchorScroll('#blog')}>
              Блог
            </div> */}
            </>
          )}
          {loggedIn && user.role === 'doctor' && (
            <>
              <NavLink exact className={Styles.navlink} activeClassName={Styles.navlink_active} to='/'>
                Главная
              </NavLink>
              <NavLink className={Styles.navlink} activeClassName={Styles.navlink_active} to='/patients'>
                Пациенты
              </NavLink>
            </>
          )}
          {user.role === 'patient' && (
            <>
              <NavLink exact className={Styles.navlink} activeClassName={Styles.navlink_active} to='/'>
                Главная
              </NavLink>
              <NavLink className={Styles.navlink} activeClassName={Styles.navlink_active} to='/appointment-page'>
                Запись на прием
              </NavLink>
              {!isActiveSearch && (
                <>
                  <NavLink className={Styles.navlink} activeClassName={Styles.navlink_active} to='/my-appointment'>
                    Мои приёмы
                  </NavLink>
                  <NavLink exact className={Styles.navlink} activeClassName={Styles.navlink_active} to='/catalog'>
                    Каталог
                  </NavLink>
                  <NavLink className={Styles.navlink} activeClassName={Styles.navlink_active} to='/blog'>
                    Блог
                  </NavLink>
                </>
              )}
            </>
          )}
        </div>
        {loggedIn && (
          <div className={isActiveSearch ? `${Styles.block} ${Styles.block_active}` : `${Styles.block}`}>
            <LKSearch
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isActiveSearch={isActiveSearch}
              showSearch={showSearch}
              hideSearch={hideSearch}
            />
          </div>
        )}
      </div>
      <div className={Styles.wrap}>
        {loggedIn && desktop && user.role === 'patient' && cartData && cartData.count > 0 && (
          <CartHeaderComponent cartData={cartData} />
        )}
        <ProfileButton
          avatar={user.avatar}
          loggedIn={loggedIn}
          firstName={user.first_name}
          lastName={user.last_name}
          onClick={() => {
            !loggedIn ? history.push('/login') : dispatch(openProfileModal())
          }}
        />
        <ProfileModal />
      </div>
      {loggedIn && width < MOBILE_BREAKPOINT ? (
        <button className={Styles.header_container_search_button} onClick={() => setIsModalOpen(true)}>
          <img src={search} alt='поиск' />
        </button>
      ) : (
        <Link className={Styles.mobileLogin} to='/login'>
          Войти
        </Link>
      )}
      <MobileModal title='Поиск' isOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <LKSearch isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </MobileModal>
    </div>
  )
}
