import React from 'react'
import Styles from './style.module.scss'
import { useAppSelector } from '@app/redux/hooks'
import { BorderLine } from '@components/ui/BorderLine'
import { formatPrice } from '@helpers/formatPrice'
import { RegistryLine } from '../../../../pages/orders/components/OrderDetails/components/RegistryLine'
import { TooltipComponent } from '@components/TooltipComponent'

export const Total = (props) => {
  const dataHandler = useAppSelector((state) => state.consultationPage.data)
  const idConsultation = useAppSelector((state) => state.consultationsList.selectConsultationById)
  const activeConsultation = useAppSelector((state) => state.consultationPage.data.savingUserInput)
  const a = activeConsultation[idConsultation].typePrice ? activeConsultation[idConsultation].typePrice : 0
  const b = activeConsultation[idConsultation].wayPrice ? activeConsultation[idConsultation].wayPrice : 0
  const total = +a + +b

  return (
    <div className={Styles.container}>
      <h3 className={Styles.title}>Запись на прием</h3>
      {props.consultation && (
        <div className={Styles.item}>
          <h6 className={Styles.subtitle}>Консультация</h6>
          <span className={Styles.item__title}>{dataHandler.title}</span>
        </div>
      )}
      {props.date && (
        <div className={Styles.item}>
          <h6 className={Styles.subtitle}>Дата и время приема</h6>
          <span className={Styles.item__title}>{`${dataHandler.selectDay} ${dataHandler.selectTimeForTotal}`}</span>
        </div>
      )}
      {props.place && !dataHandler.is_online && (
        <div className={Styles.item}>
          <h6 className={Styles.subtitle}>Место приема</h6>
          <span className={Styles.item__title}>{dataHandler.address}</span>
        </div>
      )}
      {!activeConsultation[idConsultation].way && !activeConsultation[idConsultation].type && !props.consultation && (
        <RegistryLine
          toltip={
            !props.lending && (
              <TooltipComponent text='Точная стоимость появится после выбора типа приема и консультации' />
            )
          }
          name='Стоимость'
          value={`от ${formatPrice(props.selectConsultation.types[0].price)} ₽`}
        />
      )}

      {props.isBorder && <BorderLine />}
      <ul className={Styles.wrap}>
        {activeConsultation[idConsultation].type && (
          <RegistryLine
            toltip={!props.lending && <TooltipComponent text='При онлайн приеме вы платите только за консультацию' />}
            name={dataHandler.consultation_type_title}
            value={`${activeConsultation[idConsultation].typePrice} ₽`}
          />
        )}
        {activeConsultation[idConsultation].way && (
          <RegistryLine
            toltip={!props.lending && <TooltipComponent text='Стоимость личного приема' />}
            name={activeConsultation[idConsultation].way === 'online' ? 'Онлайн' : 'Лично'}
            value={`${activeConsultation[idConsultation].wayPrice} ₽`}
          />
        )}
      </ul>

      {(activeConsultation[idConsultation].way || activeConsultation[idConsultation].type) && (
        <>
          <BorderLine />
          <RegistryLine name='Итого' value={`${total} ₽`} bold />
        </>
      )}
    </div>
  )
}
