import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import { LoadRender } from '@components/LoadRender'

export const DoctorPatientInformation: React.FC = () => {
  const currentAppointment = useAppSelector((state) => state.doctor.currentAppointment)
  const [allFiles, setAllFiles] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [file, setFile] = useState(null)

  useEffect(() => {
    if (currentAppointment.files && currentAppointment.files.length > 0) {
      setAllFiles(currentAppointment?.files)
    }
  }, [currentAppointment])
  return (
    <div className={Styles.information}>
      <span className={Styles.header}>Информация от пациента</span>
      {currentAppointment?.description ? (
        <p className={Styles.description}>{currentAppointment?.description}</p>
      ) : (
        <p className={Styles.description}>Отсутствует</p>
      )}
      {allFiles && <LoadRender editLoadDescription={false} data={allFiles} setFile={setFile} />}
    </div>
  )
}
