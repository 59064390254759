import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '@app/redux/hooks'
import Styles from './style.module.scss'
// import bellIcon from '@icons/bell.svg'
import createIcon from '@icons/create.svg'
import viewIcon from '@icons/view.svg'

import { createConclusion, setPhaseFunc, setNotificationFunc } from '../../../../index'
import { formateDate } from '../../../../../../utils/formateDate'
import { GetPdfButton } from '@components/GetPdfButton'

interface DoctorPatientPersonaProps {
  patientId: number
  avatar: any
  birthday: string
  firstName: string
  lastName: string
  phone: string
  lastAppointment: any
}

export const DoctorPatientPersona: React.FC<DoctorPatientPersonaProps> = (props) => {
  const { patientId, avatar, birthday, firstName, lastName, phone, lastAppointment } = props

  const dispatch = useAppDispatch()
  const [phase, setPhase] = useState(null)
  const [notification, setNotification] = useState('')
  const [expired, setExpired] = useState(null)
  const [yearsPatient, setYearsPatient] = useState(null)

  const classNameNotification = expired
    ? `${Styles.notification} ${Styles.notification_expired}`
    : `${Styles.notification}`

  useEffect(() => {
    if (birthday) {
      setYearsPatient(new Date().getFullYear() - new Date(birthday).getFullYear())
    }

    setPhaseFunc(lastAppointment.day_reception, setPhase)
    setNotificationFunc(
      lastAppointment.status,
      lastAppointment.medical_report,
      lastAppointment.day_reception,
      setNotification,
      setExpired,
    )
  }, [])

  return (
    <li className={Styles.visit}>
      <div className={Styles.block}>
        <div className={Styles.wrap}>
          {avatar ? (
            <img className={Styles.avatar} src={avatar} alt='Аватар' />
          ) : (
            <div className={Styles.cap}>
              <span>
                {lastName?.substring(0, 1)}
                {firstName?.substring(0, 1)}
              </span>
            </div>
          )}
          <Link to={`/patients/${patientId}`} className={Styles.patient}>
            <span className={Styles.lastname}>{lastName}</span>
            <span className={Styles.firstname}>
              {firstName}
              {birthday && `, ${yearsPatient} лет`}
            </span>
          </Link>
        </div>
        {/* {Object.keys(lastAppointment).length !== 0 && lastAppointment.status !== 'done' && (
          <button className={Styles.remind}>
            <img src={bellIcon} alt='Иконка' />
            <span className={Styles.about}>Напомнить о приеме</span>
          </button>
        )} */}
      </div>
      <div className={Styles.block}>
        {Object.keys(lastAppointment).length !== 0 && (
          <>
            {phase ? (
              <span className={`${Styles.title} ${Styles.title_mod}`}>
                {phase}, {formateDate(lastAppointment.day_reception, 'DD MMMM')}
              </span>
            ) : (
              <span className={`${Styles.title} ${Styles.title_mod}`}>
                {formateDate(lastAppointment.day_reception, 'DD MMMM')}
              </span>
            )}
            <span className={Styles.subtitle}>{`«${lastAppointment.consultation_type.direction.title}»`}</span>
          </>
        )}
      </div>
      <div className={Styles.block}>
        {lastAppointment.medical_report && (
          <Link className={Styles.conclusion} to={`/appointments/${lastAppointment.id}`}>
            <img src={viewIcon} alt='Иконка' />
            <span className={Styles.text}>Смотреть заключение</span>
          </Link>
        )}
        {lastAppointment.status === 'planned' && (
          <Link className={Styles.conclusion} to={`/appointments/${lastAppointment.id}`}>
            <span className={Styles.text}>Смотреть детально</span>
          </Link>
        )}
        {lastAppointment.medical_report && lastAppointment.medical_report.is_report_done && (
          <GetPdfButton medicalReportId={lastAppointment.medical_report.id} />
        )}
        {(lastAppointment.status === 'done' || lastAppointment.status === 'started') &&
          lastAppointment.medical_report === null && (
            <button className={Styles.conclusion}>
              <img src={createIcon} alt='Иконка' />
              <span className={Styles.text} onClick={() => createConclusion(lastAppointment.id, dispatch)}>
                Создать заключениe
              </span>
            </button>
          )}
        {(lastAppointment.status === 'not_payed' || lastAppointment.status === 'cancel') && (
          <span className={Styles.cancel}>Прием отменен</span>
        )}
        {lastAppointment.status === 'waiting_paying' && <span className={Styles.cancel}>Прием не оплачен</span>}
        {notification && <span className={classNameNotification}>{notification}</span>}
      </div>
      <div className={Styles.block}>
        <a href={`tel:${phone}`} className={Styles.phone}>
          {phone}
        </a>
      </div>
      <div className={Styles.block}>{/* <Chat /> */}</div>
    </li>
  )
}
