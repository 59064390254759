import React, { useCallback, useEffect, useState } from 'react'
import {
  setLoading,
  setModal,
  setCurrentAppointmentsPatient,
  setLastAppointmentPatient,
  setDietReports,
  setLifestyleReports,
  setNutraceuticalsReports,
  setResearchReports,
  getPatient,
  getAppointment,
  getListAppointmentPatientOrdering,
  getDietReport,
  getLifestyleReport,
  getNutraceuticalReport,
  getResearchReport,
} from './redux/doctorSlicer'
import {
  getExerciseList,
  getMedicalReportId,
  setAppointmentId,
  setReportsModalOpen,
  getMedicalReportById,
} from '@components/MedicalReportsModal/redux/reportsSlice'
import Styles from './style.module.scss'

import { formateDate } from '../../utils/formateDate'
import { compareDate } from '../../utils/compareDate'
import { NUMBER_DAYS_TO_CHECK } from './constants'

// import { createTheme } from '@material-ui/core/styles'
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DoctorContainer } from './components/DoctorContainer'
import { DoctorCabinet } from './components/DoctorCabinet'
import { DoctorHistory } from './components/DoctorHistory'
import { DoctorNotation } from './components/DoctorNotation'
import { DoctorPatientList } from './components/DoctorPatientList'
import { DoctorPatient } from './components/DoctorPatient'

import { useAppDispatch, useAppSelector } from '@app/redux/hooks'

/* const theme = createTheme({
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application!
    },
  },
}) */

export const loadPatient = async (patientId, dispatch) => {
  const today = formateDate(new Date(), 'YYYY-MM-DD')

  dispatch(setModal(false))

  try {
    dispatch(setLoading(true))

    const response = await dispatch(getListAppointmentPatientOrdering({ patientId, ordering: today }))

    await dispatch(getPatient(patientId))

    dispatch(setLastAppointmentPatient(response.payload.data.results[response.payload.data.results.length - 1]))
    dispatch(setCurrentAppointmentsPatient(response.payload.data.results))
  } catch (error) {
    throw new Error(error)
  } finally {
    dispatch(setLoading(false))
  }
}

export const loadAppointment = async (appointmentId, dispatch) => {
  const today = formateDate(new Date(), 'YYYY-MM-DD')

  try {
    dispatch(setLoading(true))

    const response = await dispatch(getAppointment(appointmentId))

    if (response.payload.data.medical_report) {
      if (response.payload.data.medical_report.is_report_done) {
        const getReports = async (reports, type) => {
          const patientReports = []

          if (type === 'diet') {
            for (const report of reports) {
              try {
                const response = await dispatch(getDietReport(report))
                patientReports.push(response.payload.data)
              } catch (error) {
                throw new Error(error)
              }
            }

            return patientReports
          }

          if (type === 'lifestyle') {
            for (const report of reports) {
              try {
                const response = await dispatch(getLifestyleReport(report))
                patientReports.push(response.payload.data)
              } catch (error) {
                throw new Error(error)
              }
            }

            return patientReports
          }

          if (type === 'nutraceutical') {
            for (const report of reports) {
              try {
                const response = await dispatch(getNutraceuticalReport(report))
                patientReports.push(response.payload.data)
              } catch (error) {
                throw new Error(error)
              }
            }

            return patientReports
          }

          if (type === 'research') {
            for (const report of reports) {
              try {
                const response = await dispatch(getResearchReport(report))
                patientReports.push(response.payload.data)
              } catch (error) {
                throw new Error(error)
              }
            }

            return patientReports
          }
        }

        const dietReports = await getReports(response.payload.data.medical_report.diet_reports, 'diet')
        dispatch(setDietReports(dietReports))

        const lifestyleReports = await getReports(response.payload.data.medical_report.lifestyle_reports, 'lifestyle')
        dispatch(setLifestyleReports(lifestyleReports))

        const nutraceuticalsReports = await getReports(
          response.payload.data.medical_report.nutraceutical_reports,
          'nutraceutical',
        )
        dispatch(setNutraceuticalsReports(nutraceuticalsReports))

        const researchReports = await getReports(response.payload.data.medical_report.research_reports, 'research')
        dispatch(setResearchReports(researchReports))
      }
    }

    await dispatch(getPatient(response.payload.data.patient.id))

    const res = await dispatch(
      getListAppointmentPatientOrdering({ patientId: response.payload.data.patient.id, ordering: today }),
    )

    dispatch(setCurrentAppointmentsPatient(res.payload.data.results))
  } catch (error) {
    throw new Error(error)
  } finally {
    dispatch(setLoading(false))
  }
}

export const createConclusion = (appointmentId, dispatch) => {
  dispatch(setReportsModalOpen(true))
  dispatch(setAppointmentId(appointmentId))
  dispatch(getMedicalReportId(appointmentId))
  dispatch(getExerciseList())
}

export const editConclusion = (appointmentId, dispatch) => {
  dispatch(setAppointmentId(appointmentId))
  dispatch(getMedicalReportId(appointmentId))
    .then((res) => {
      dispatch(getMedicalReportById(res.payload.data.medical_report.id))
    })
    .then(() => {
      dispatch(setReportsModalOpen(true))
    })
  dispatch(getExerciseList())
}

export const setPhaseFunc = (dayReception, setPhase) => {
  const daysAgo = new Date().getDate() - new Date(dayReception).getDate()
  const month = new Date().getMonth()
  const monthReception = new Date(dayReception).getMonth()

  if (month === monthReception) {
    if (daysAgo === 0) {
      setPhase('сегодня')
    }

    if (daysAgo === 1) {
      setPhase('вчера')
    }

    if (daysAgo === 2) {
      setPhase('позавчера')
    }
  }
}

export const setNotificationFunc = (status, medicalReport, dayReception, setNotification, setExpired) => {
  if (status === 'done' && !medicalReport) {
    const today = formateDate(new Date(), 'YYYY-MM-DD')
    const period = compareDate(today, dayReception)

    const daysString = period - NUMBER_DAYS_TO_CHECK === 1 ? 'день' : 'дня'
    const nameString = period - NUMBER_DAYS_TO_CHECK === 1 ? 'Остался' : 'Осталось'

    if (period <= NUMBER_DAYS_TO_CHECK) {
      const days = NUMBER_DAYS_TO_CHECK - period

      setNotification(days === 0 ? 'Сегодня' : `${nameString} ${days} ${daysString}`)
      setExpired(false)
    } else {
      const days = period - NUMBER_DAYS_TO_CHECK

      setExpired(true)
      setNotification(`Просрочено на ${days} ${daysString}`)
    }
  }
}

export const DoctorPage = () => {
  const dispatch = useAppDispatch()
  const userId: string | null = useAppSelector((state) => state.user?.userId)
  const defaultPage = useAppSelector((state) => state.doctor?.pagination.number)
  const pageSize = useAppSelector((state) => state.doctor?.pagination.size)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(0)

  const [rowCount, setRowCount] = useState([
    { id: 0, value: 10, active: false },
    { id: 1, value: 20, active: false },
    { id: 2, value: 50, active: false },
  ])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (event: React.ChangeEvent, newValue: number) => {
    setValue(newValue)
  }

  const getUserOrders = useCallback(() => {
    if (userId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const value = {
        userId,
        data: {
          number: defaultPage,
          size: pageSize,
        },
      }
      // dispatch(getDoctorPageData(value))
    }
  }, [dispatch, userId, pageSize, defaultPage])

  useEffect(() => {
    getUserOrders()
  }, [userId, rowCount, defaultPage])

  useEffect(() => {
    if (pageSize) {
      const newArr = rowCount.map((item) => {
        if (item.value === pageSize) {
          item.active = true
          return item
        } else {
          item.active = false
          return item
        }
      })
      setRowCount(newArr)
    }
  }, [pageSize])

  return (
    <div className={Styles.arm_main_container}>
      <DoctorContainer title='Личный кабинет' back=''>
        <DoctorCabinet />
      </DoctorContainer>
      <DoctorContainer title='Прошедшие записи' back=''>
        <DoctorHistory />
      </DoctorContainer>
      <DoctorContainer title='Запись на прием' back='Назад на главную'>
        <DoctorNotation />
      </DoctorContainer>
      <DoctorContainer title='Пациенты' count={35} back=''>
        <DoctorPatientList />
      </DoctorContainer>
      <DoctorContainer title='' back='Назад ко всем пациентам'>
        <DoctorPatient />
      </DoctorContainer>
    </div>
  )
}
