import React from 'react'
import Styles from './style.module.scss'

import removeIcon from '@icons/remove-blue.svg'

interface FilterKeysProps {
  title?: string
  rating?: number
  select?: boolean
  setFilter: any
  count: number
  filteredKey?: string
}

export const FilterKeys: React.FC<FilterKeysProps> = (props) => {
  const { title, count, select, setFilter, filteredKey } = props

  return (
    <div
      className={select ? `${Styles.item} ${Styles.item_select}` : `${Styles.item}`}
      onClick={() => {
        !select &&
          setFilter((prev) => ({
            ...prev,
            [filteredKey]: `${prev[filteredKey] && prev[filteredKey] + ','}${title}`,
          }))
      }}
    >
      {title && <span className={Styles.title}>{title}</span>}
      {/* {rating && renderRating()} */}
      {select ? (
        <img
          className={Styles.remove}
          src={removeIcon}
          alt='Иконка'
          onClick={() => {
            setFilter((prev) => ({
              ...prev,
              [filteredKey]: prev[filteredKey]
                .split(',')
                .filter((item) => item !== title)
                .join(','),
            }))
          }}
        />
      ) : (
        <span className={Styles.count}>{count}</span>
      )}
    </div>
  )
}
