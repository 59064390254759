import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'

import { ButtonSmall } from '../../../UI/ButtonSmall'
// import { ButtonPrimary } from '../../../UI/ButtonPrimary'
// import { ButtonSecondary } from '../../../UI/ButtonSecondary'

import { formateDate } from '../../../../../../utils/formateDate'
import { DoctorAppointmentButton } from '../DoctorAppointmentButton'

interface DoctorRecordInfoProps {
  selectedAppointment?: any
}

export const DoctorRecordInfo: React.FC<DoctorRecordInfoProps> = ({ selectedAppointment }) => {
  const [thisStatus, setThisStatus] = useState(selectedAppointment.status)
  return (
    <div className={Styles.content}>
      <div className={Styles.header}>
        <div className={Styles.wrap}>
          {selectedAppointment.patient.avatar ? (
            <img className={Styles.avatar} src={selectedAppointment.patient.avatar} alt='Аватар' />
          ) : (
            <div className={Styles.cap}>
              <span>
                {selectedAppointment.patient?.last_name?.substring(0, 1)}
                {selectedAppointment.patient?.first_name?.substring(0, 1)}
              </span>
            </div>
          )}
          <div className={Styles.name}>
            <Link className={Styles.lastname} to={`/patients/${selectedAppointment.patient.id}`}>
              {selectedAppointment.patient.last_name}
            </Link>
            <span className={Styles.firstname}>{selectedAppointment.patient.first_name}</span>
          </div>
        </div>
        <div
          className={selectedAppointment.is_online ? `${Styles.record}` : `${Styles.record} ${Styles.record_offline}`}
        >
          <span className={Styles.time}>{selectedAppointment.time_reception}</span>
          <span className={Styles.type}>{selectedAppointment.is_online ? 'Онлайн прием' : 'Личный прием'}</span>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.block}>
          <span className={Styles.title}>Консультация</span>
          <span className={Styles.subtitle}>{selectedAppointment.consultation_type.direction.title}</span>
        </div>
        <div className={Styles.block}>
          <span className={Styles.title}>Тип консультации</span>
          <span className={Styles.subtitle}>{selectedAppointment.consultation_type.title}</span>
        </div>
        <div className={Styles.block}>
          <span className={Styles.title}>Дата и время</span>
          <span className={Styles.subtitle}>
            {formateDate(selectedAppointment.day_reception, 'DD MMMM')}, {selectedAppointment.time_reception}
          </span>
        </div>
        <Link to={`/appointments/${selectedAppointment.id}`}>
          <ButtonSmall>Смотреть подробнее</ButtonSmall>
        </Link>
      </div>
      <div className={Styles.info}>
        <DoctorAppointmentButton
          appointment={selectedAppointment}
          thisStatus={thisStatus}
          setThisStatus={setThisStatus}
        />
        {/* {selectedAppointment.status !== 'done' && selectedAppointment.is_online && (
          <ButtonPrimary>Начать прием</ButtonPrimary>
        )}
        {selectedAppointment.status === 'done' && !selectedAppointment.medical_report && (
          <div className={Styles.warning}>
            <i className={Styles.icon}>{}</i>
            <span>Требуется заключение</span>
          </div>
        )}
        {selectedAppointment.status === 'done' && selectedAppointment.medical_report && (
          <ButtonSecondary>Заключение</ButtonSecondary>
        )} */}
      </div>
    </div>
  )
}
