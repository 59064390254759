import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '@app/redux/hooks'
import useWindowSize from '../../../../helpers/useWindowSizeHook'
import Styles from './style.module.scss'

import { DoctorDay } from './components/DoctorDay'
import { DoctorDayMobile } from './components/DoctorDay/components/DoctorDayMobile'
import { DoctorWeek } from './components/DoctorWeek'
import { DoctorWeekMobile } from './components/DoctorWeek/components/DoctorWeekMobile'
import { DoctorMonth } from './components/DoctorMonth'
import { DoctorMonthMobile } from './components/DoctorMonth/components/DoctorMonthMobile'
import { Filter } from '../UI/Filter'

import clockIcon from '@icons/clock-big.svg'
import arrowIcon from '@icons/arrow.svg'
import closeIcon from '@icons/close.svg'

import { formateDate } from '../../../../utils/formateDate'
import { MOBILE_BREAKPOINT, NOTIFICATION_OF_MINUTES } from '../../constants'

export const DoctorCabinet: React.FC = () => {
  const { width } = useWindowSize()
  const appointmentsToday = useAppSelector((state) => state.doctor.appointmentsToday)
  const filterIndex = useAppSelector((state) => state.doctor.filterIndex)
  const filter = useAppSelector((state) => state.doctor.filter)
  const [displayNotification, setDisplayNotification] = useState(true)
  const [notification, setNotification] = useState(null)
  const [displaySymbols, setDisplaySymbols] = useState(true)
  const [appointmentId, setAppointmentId] = useState(null)

  const closeNotification = () => setDisplayNotification(false)
  const closeSymbols = () => setDisplaySymbols(false)

  useEffect(() => {
    if (appointmentsToday.length !== 0) {
      const receptions = appointmentsToday[0].receptions.filter((reception) => reception.appointment !== null)

      receptions.forEach((reception) => {
        if (!reception.is_past) {
          const currentTime = new Date().getTime()
          const receptionTime = new Date(`${formateDate(new Date(), 'YYYY-MM-DD')} ${reception.time}`).getTime()

          if (currentTime < receptionTime) {
            const diffMinutes = receptionTime - currentTime
            const minutes = diffMinutes / 60000

            if (minutes <= NOTIFICATION_OF_MINUTES) {
              setNotification(Math.ceil(minutes))
              setAppointmentId(reception.appointment.id)
            }
          }
        }
      })
    }
  }, [appointmentsToday])

  return (
    <>
      {width > MOBILE_BREAKPOINT ? (
        <div className={Styles.cabinet}>
          <Filter list={['На 1 день', '7 дней', '30 дней']} />
          <>
            {filter[filterIndex] === 'day' && <DoctorDay />}
            {filter[filterIndex] === 'week' && <DoctorWeek />}
            {filter[filterIndex] === 'month' && <DoctorMonth />}
          </>
        </div>
      ) : (
        <div className={Styles.cabinet}>
          {notification && displayNotification && (
            <div className={Styles.notification}>
              <img src={clockIcon} alt='Иконка' />
              <div className={Styles.block}>
                <span className={Styles.across}>Через {notification} минут прием</span>
                <Link className={Styles.link} to={`/appointments/${appointmentId}`}>
                  <span className={Styles.text}>Перейти к записи</span>
                  <img src={arrowIcon} alt='Иконка' />
                </Link>
              </div>
              <img className={Styles.close} src={closeIcon} alt='Иконка' onClick={closeNotification} />
            </div>
          )}
          <Filter list={['На 1 день', '7 дней', '30 дней']} />
          <>
            {filter[filterIndex] === 'day' && <DoctorDayMobile />}
            {filter[filterIndex] === 'week' && <DoctorWeekMobile />}
            {filter[filterIndex] === 'month' && <DoctorMonthMobile />}
          </>
          {displaySymbols && (
            <div className={Styles.symbols}>
              <div className={Styles.symbol}>
                <i className={`${Styles.color} ${Styles.color_offline}`}>{}</i>
                <span className={Styles.type}>— личный прием</span>
              </div>
              <div className={Styles.symbol}>
                <i className={`${Styles.color} ${Styles.color_online}`}>{}</i>
                <span className={Styles.type}>— онлайн прием</span>
              </div>
              <img className={Styles.close} src={closeIcon} alt='Иконка' onClick={closeSymbols} />
            </div>
          )}
        </div>
      )}
    </>
  )
}
