export const sortingByDate = (array) => {
  const sortedArray = array.sort((a, b) => {
    const dateA = new Date(a.last_appointment.day_reception).getTime()
    const dateB = new Date(b.last_appointment.day_reception).getTime()

    return dateA > dateB ? 1 : -1
  })

  return sortedArray
}
