import React from 'react'
import Styles from './style.module.scss'

export const TitleComponent = (props) => {
  return (
    <>
      <h4 className={Styles.title}>
        {props.title}
        {props.src && <img alt='img' src={props.src} className={Styles.img} />}
      </h4>
      <p className={`${Styles.text} ${props.className}`}>{props.text}</p>
    </>
  )
}
