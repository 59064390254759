import React from 'react'
import Styles from './style.module.scss'

interface MobileMenuItemProps {
  icon: string
  text: string
  onClick: () => void
}

export const MobileMenuModalButton: React.FC<MobileMenuItemProps> = ({ icon, text, onClick }) => {
  return (
    <div onClick={onClick} className={Styles.item}>
      <img className={Styles.icon} src={icon} alt='иконка' />
      <p className={Styles.text}>{text}</p>
    </div>
  )
}
