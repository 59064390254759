import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getAppointment, setSelectedAppointmentId } from '../../../../../../../../redux/doctorSlicer'
import Styles from './style.module.scss'

import { DoctorRecord } from '../../../../../DoctorDay/components/DoctorRecord'

interface DoctorReceptionProps {
  appointment: any
  isBlacklist?: boolean
  isPast: boolean
  time: string
}

export const DoctorReception: React.FC<DoctorReceptionProps> = (props) => {
  const { appointment, isPast, time } = props

  const cls = [`${Styles.reception}`]

  const dispatch = useAppDispatch()
  const selectedAppointmentId = useAppSelector((state) => state.doctor.selectedAppointmentId)
  const [className, setClassName] = useState([`${Styles.reception}`])
  const [appointmentId, setAppointmentId] = useState(null)
  const [selectedReception, setSelectedReception] = useState(false)
  const [dataAppointment, setDataAppointment] = useState(null)

  const onClickReception = async (appointmentId) => {
    dispatch(setSelectedAppointmentId(appointmentId))

    if (appointmentId === selectedAppointmentId && selectedReception) {
      const arrClass = className

      arrClass.pop()
      setClassName(arrClass)
      setSelectedReception(false)
    } else {
      const response = await dispatch(getAppointment(appointmentId))
      const arrClass = className

      arrClass.push(`${Styles.reception_selected}`)
      setClassName(arrClass)
      setDataAppointment(response.payload.data)
      setSelectedReception(true)
    }
  }

  useEffect(() => {
    if (appointment !== null) {
      setAppointmentId(appointment.id)
    }

    if (appointment !== null && !isPast) {
      cls.push(`${Styles.reception_existing}`)
      setClassName(cls)
    }

    if (appointment === null) {
      cls.push(`${Styles.reception_free}`)
      setClassName(cls)
    }

    if (appointment !== null && isPast) {
      cls.push(`${Styles.reception_past}`)
      setClassName(cls)
    }
  }, [selectedAppointmentId])

  return (
    <>
      {appointment === null && (
        <div className={className.join(' ')}>
          <span className={Styles.time}>{time}</span>
        </div>
      )}
      {appointment !== null && !isPast && (
        <div className={className.join(' ')} onClick={() => onClickReception(appointment.id)}>
          {appointment.is_online ? (
            <i className={Styles.icon}>{}</i>
          ) : (
            <i className={`${Styles.icon} ${Styles.icon_offline}`}>{}</i>
          )}
          <span className={Styles.time}>{time}</span>
          {appointmentId === selectedAppointmentId && selectedReception && (
            <DoctorRecord appointment={dataAppointment} className={Styles.record_mod} />
          )}
        </div>
      )}
      {appointment !== null && isPast && (
        <div className={className.join(' ')} onClick={() => onClickReception(appointment.id)}>
          {appointment.is_online ? (
            <i className={Styles.icon}>{}</i>
          ) : (
            <i className={`${Styles.icon} ${Styles.icon_offline}`}>{}</i>
          )}
          <span className={Styles.time}>{time}</span>
          {appointmentId === selectedAppointmentId && selectedReception && (
            <DoctorRecord appointment={dataAppointment} className={Styles.record_mod} />
          )}
        </div>
      )}
    </>
  )
}
