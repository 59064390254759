/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import useWindowSize from '@helpers/useWindowSizeHook'
import { CatalogPhotos } from '../CatalogPhotos'
import { CatalogFeedbackFilter } from './components/CatalogFeedbackFilter'
import { CatalogFeedbackUser } from './components/CatalogFeedbackUser'
import { CatalogFeedbackReviews } from './components/CatalogFeedbackReviews'
import Styles from './style.module.scss'

export const CatalogFeedback = ({ reviews, product }) => {
  const { width } = useWindowSize()
  const [allPhotos, setAllPhotos] = useState<any>([])

  useEffect(() => {
    setAllPhotos([])
    if (reviews.results) {
      reviews?.results.map((review) => {
        setAllPhotos((prev) => [...prev, ...review.review_images])
      })
    }
  }, [reviews.results])

  return (
    <div className={Styles.feedback}>
      {reviews.results && (
        <div className={Styles.container}>
          {allPhotos.length > 0 && <CatalogPhotos images={allPhotos} title='Фото покупателей' />}
          {width <= 1310 && <CatalogFeedbackReviews reviews={reviews.results} product={product} />}
          <CatalogFeedbackFilter />
          {reviews.results.length === 0 && <p>Отзывов пока нет</p>}
          {reviews.results.map((review, idx) => {
            return (
              <CatalogFeedbackUser
                key={idx}
                name={review.user_name}
                date={review.created_at}
                dignity={review.pluses}
                flaw={review.minuses}
                comment={review.detail}
                rating={review.rating}
                review_images={review.review_images}
                productId={product.id}
                reviewId={review.id}
                useful={review.useful}
                useless={review.useless}
              />
            )
          })}
          {/* <div className={Styles.wrapper}>
          <button className={Styles.button}>Показать еще 12 отзывов</button>
        </div> */}
        </div>
      )}
      {width > 1310 && (
        <div className={Styles.container}>
          <CatalogFeedbackReviews reviews={reviews.results} product={product} />
        </div>
      )}
    </div>
  )
}
