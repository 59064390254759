import { useAppDispatch } from '@app/redux/hooks'
import { setSelectedModification } from '../../../../../../../../../../pages/catalog/redux/productSlice'
import React from 'react'
import Styles from './style.module.scss'

export const CatalogProductSelectCount = ({ selectedVariant, selectedModification }) => {
  const dispatch = useAppDispatch()
  const setModification = (index) => {
    dispatch(setSelectedModification(index))
  }
  return (
    <div className={Styles.container}>
      <span className={Styles.title}>Выберите количество</span>
      <div className={Styles.wrapper}>
        {selectedVariant.modifications?.map((mod, index) => {
          return (
            <div
              key={index}
              className={
                mod.id === selectedModification.id ? `${Styles.block} ${Styles.block_select}` : `${Styles.block}`
              }
              onClick={() => setModification(index)}
            >
              <span>{mod.value}</span>
              <strong>{mod.discount_price} ₽</strong>
            </div>
          )
        })}
      </div>
    </div>
  )
}
