import React from 'react'
import fonIcon from '@icons/pdf-fon.png'
import Styles from './style.module.scss'
import FileSaver from 'file-saver'

export const CatalogPDF = ({ file }) => {
  const downloadFile = () => {
    FileSaver.saveAs(file, 'example.txt')
  }
  return (
    <div className={Styles.pdf} onClick={downloadFile}>
      <div className={Styles.image}>
        <img src={fonIcon} alt='Иконка' />
      </div>
      <div className={Styles.info}>
        <span className={Styles.title}>Пример результатов анализа</span>
        <span className={Styles.size}>17 KB</span>
      </div>
    </div>
  )
}
