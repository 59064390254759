/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import useWindowSize from '@helpers/useWindowSizeHook'
import Styles from './style.module.scss'
import 'swiper/swiper.scss'
import calendarIcon from '@icons/calendar.svg'
import { MOBILE_BREAKPOINT } from '../../../../../../global-constants'
import declination from '@helpers/declination'
import { CatalogCartButton } from '../../../../../../pages/catalog/components/CatalogCartButton'

interface CatalogResearchItemProps {
  analyze: any
}

export const CatalogResearchItem: React.FC<CatalogResearchItemProps> = ({ analyze }) => {
  const { width } = useWindowSize()
  const { id, title, price, prices, execution_time, labs, short_description } = analyze

  return (
    <div className={Styles.item}>
      <div className={Styles.block}>
        <Link to={`/catalog/research/${id}`} className={Styles.wrap}>
          <span className={Styles.title}>{title}</span>
          <span className={Styles.subtitle} dangerouslySetInnerHTML={{ __html: short_description }} />
        </Link>
        {width > MOBILE_BREAKPOINT && (
          <div className={Styles.wrap}>
            <div className={Styles.date}>
              <img src={calendarIcon} alt='Иконка' />
              <span>Срок исполнения до {declination(execution_time, 'дня', 'дней', 'дней')}</span>
            </div>
            <div className={Styles.performers}>
              {labs.map((lab) => {
                return (
                  <div key={lab} className={Styles.image}>
                    <img src={`${lab}`} alt='Иконка' />
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <div className={Styles.block}>
        <div className={Styles.wrap}>
          <span className={Styles.price}>
            {price} <span>₽</span>
          </span>
          {prices.map((price) => {
            return (
              <div key={price.title} className={Styles.info}>
                <span>{price.title}:&nbsp;</span>
                <strong>{price.amount} ₽</strong>
              </div>
            )
          })}
        </div>
      </div>
      <div className={Styles.block}>
        <CatalogCartButton product={analyze} column type='analyzes' />
        {width < MOBILE_BREAKPOINT && (
          <Swiper className={Styles.swiper} slidesPerView={1.5} freeMode>
            <SwiperSlide className={Styles.slide}>
              <div className={Styles.date}>
                <img src={calendarIcon} alt='Иконка' />
                <span>Срок исполнения до {declination(execution_time, 'дня', 'дней', 'дней')}</span>
              </div>
            </SwiperSlide>
            <SwiperSlide className={Styles.slide}>
              <div className={Styles.performers}>
                {labs.map((lab) => {
                  return (
                    <div key={lab} className={Styles.image}>
                      <img src={`${lab}`} alt='Иконка' />
                    </div>
                  )
                })}
              </div>
            </SwiperSlide>
          </Swiper>
        )}
      </div>
    </div>
  )
}
