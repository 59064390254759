import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import React, { useEffect, useRef } from 'react'
import Styles from './style.module.scss'
import { Description } from '../../../pages/appointment-page/components/TypeOfConsultation/components/Description'
import { Total } from '../../../pages/appointment-page/components/Total'
import { NearestReception } from '../../../pages/appointment-page/components/TypeOfConsultation/components/NearestReception'
import { DoctorInformation } from '../../../pages/appointment-page/components/TypeOfConsultation/components/DoctorInfo'
import { ButtonColor } from '@components/ui/ButtonColor'
import { setData } from '../../../pages/appointment-page/redux/consultationSlicer'
import {
  changeTitle,
  consultationActive,
  mobileState,
  mobileStatePagination,
} from '../../../pages/appointment-page/redux/choiсeConsultationSlicer'
import { Link } from 'react-router-dom'
import { MobileHeader } from '../../../pages/appointment-page/components/MobileHeader'

export const DrawerAppointment = (props) => {
  const dispatch = useAppDispatch()
  const idConsultation = useAppSelector((state) => state.consultationsList.selectConsultationById)
  const data = useAppSelector((state) => state.consultationsList.data)
  const selectConsultation = data.find((item) => item.id === idConsultation)
  const dataHandler = useAppSelector((state) => state.consultationPage.data)
  const doctor = selectConsultation.doctors[0]
  useEffect(() => {
    dispatch(setData({ key: 'doctor', value: doctor.id }))
    dispatch(setData({ key: 'doctorData', value: doctor }))
  }, [doctor])
  const component = useRef<HTMLDivElement>(null)
  const handle = () => {
    dispatch(consultationActive(1))
    dispatch(changeTitle({ id: 0, value: selectConsultation.title }))
    dispatch(mobileStatePagination(true))
    dispatch(mobileState({ id: 1, value: true }))
  }
  return (
    <div ref={component}>
      <MobileHeader
        title='Запись на консультацию'
        onClick={() => {
          props.setShowAppountment(false)
        }}
      />
      <div className={Styles.main__conteiner}>
        <div className={Styles.details__container}>
          <header className={Styles.header}>
            <div className={Styles.header__wrap}>
              <img src={selectConsultation.image} className={Styles.img} alt='reference' />
              <h4 className={Styles.title}>{selectConsultation.title}</h4>
            </div>

            {/* <div className={Styles.wrapper}>
              <img src={clock} className={Styles.icon} alt='clock' />
              <div className={Styles.duration}>{selectConsultation.types[0].duration}</div>
      </div> */}
          </header>
          <Description selectConsultation={selectConsultation} />
        </div>

        {dataHandler.doctorData && (
          <DoctorInformation
            name={`${dataHandler.doctorData.first_name} ${dataHandler.doctorData.last_name}`}
            prof={dataHandler.doctorData.specialty}
            expir={dataHandler.doctorData.years_experience}
            count={dataHandler.doctorData.number_conducted_consultations}
            avatar={dataHandler?.doctorData?.avatar || ''}
          />
        )}
        <NearestReception item={selectConsultation.available_time} />
        <Total
          selectConsultation={selectConsultation}
          defaultPrice
          consultation={false}
          date={false}
          isBorder={false}
        />
      </div>
      <div className={Styles.buttons__container}>
        <Link to='/appointment-page'>
          <ButtonColor value='Перейти к записи' onClick={handle} />
        </Link>
      </div>
    </div>
  )
}
