import React from 'react'
import Styles from './style.module.scss'
import arrow from '@icons/arrow-small.svg'
import calendar from '@icons/calendar.svg'
import moment from 'moment'

export const ButtonPagination = (props) => {
  const todayForCompareInTitle = moment()
  const prevDataDone = (e) => {
    e.preventDefault()
    props.setToday((prev) => prev.clone().subtract(1, 'month'))
  }
  const nextDataDone = (e) => {
    e.preventDefault()
    props.setToday((prev) => prev.clone().add(1, 'month'))
  }
  return (
    <div className={`${Styles} ${Styles.button__pagination}`}>
      <div className={Styles.text}>
        <img src={calendar} alt='calendar' />
        {todayForCompareInTitle.format('MM') === props.today.format('MM')
          ? 'Текущий месяц'
          : props.today.format('MMMM')}
      </div>
      <button onClick={prevDataDone} className={Styles.button__prev}>
        <img src={arrow} alt='arrow' />
      </button>
      <button onClick={nextDataDone} className={Styles.button__next}>
        <img src={arrow} alt='arrow' />
      </button>
    </div>
  )
}
