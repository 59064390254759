import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'
import viewIcon from '@icons/view.svg'
import clock from '@icons/clock-white.svg'
import moment from 'moment'
import { DrawerPanel } from '@components/Drawer'
import close from '@icons/close-blue.svg'
import arrow from '@icons/arrow-large-blue.svg'

interface CardProps {
  title: string
  date: any
  conclusion: boolean
  time: string
  id: any
  status: string
  detail: string
  absence: boolean
  notPaid: boolean
}
export const AppointmentHistoryCardMobile: React.FC<CardProps> = (props) => {
  const { title, date, time, conclusion, id, status, detail, absence, notPaid } = props
  const dateFomat = moment(date, 'YYYY-MM-DD').format('DD MMMM')
  const dateTimeFormat = moment(date, 'YYYY-MM-DD').format('dddd, DD MMMM')
  const [reasonDefault, setReasonDefault] = useState('')
  const [reasonCustomText, setReasonCustomText] = useState('')
  const [reasonCustomButton, setReasonCustomButton] = useState(false)
  const [showReasonDrawer, setShowReasonDrawer] = useState(false)
  const reason = () => {
    if (absence) {
      setReasonDefault('неявка')
    }
    if (notPaid) {
      setReasonDefault('нет оплаты')
    }
    if (notPaid && absence) {
      setReasonDefault('неявка, нет оплаты')
    }
    if (detail.length > 0) {
      setReasonCustomText(detail)
      setReasonCustomButton(true)
    }
  }
  useEffect(() => {
    reason()
  }, [])
  return (
    <>
      <div className={Styles.visit}>
        <div className={Styles.wrapper}>
          <div className={Styles.wrap}>Запись от {dateFomat}</div>
          <Link to={`/my-appointment/${id}`} className={Styles.link} />
        </div>
        <div className={Styles.block}>
          <span className={Styles.label}>Консультация</span>
          <span className={Styles.text}>{title}</span>
        </div>
        <div className={Styles.block}>
          <span className={Styles.label}>Дата и время</span>
          <span className={Styles.text}>{`${dateTimeFormat} в ${time}`}</span>
        </div>
        {conclusion && (
          <Link to={`/my-appointment/${id}`} className={Styles.conclusion}>
            <img src={viewIcon} className={Styles.icon} alt='img' />
            <span className={Styles.warning}>Смотреть заключение</span>
          </Link>
        )}
        {status === 'done' && !conclusion && (
          <span className={Styles.notification}>
            <img src={clock} alt='clock' />
            Врач готовит заключение
          </span>
        )}
        {(status === 'cancel' || status === 'not_payed') && (
          <div className={Styles.reason}>
            <span>Причина: {reasonDefault}</span>
            {reasonCustomButton && <button onClick={() => setShowReasonDrawer(true)}>узнать подробности…</button>}
          </div>
        )}
        {(status === 'cancel' || status === 'not_payed') && <span className={Styles.cancel}>Прием отменен</span>}
      </div>
      <DrawerPanel width='100%' anchor='right' open={showReasonDrawer} onClose={() => setShowReasonDrawer(false)}>
        <div className={Styles.container}>
          <div className={Styles.navigation}>
            <button className={Styles.arrow} onClick={() => setShowReasonDrawer(false)}>
              <img src={arrow} alt='img' />
            </button>

            <h3 className={Styles.title__drawer}>Причина отказа</h3>
            <button className={Styles.close} onClick={() => setShowReasonDrawer(false)}>
              <img src={close} alt='close' />
            </button>
          </div>
          <h2>Отказ</h2>
          <p>от {dateFomat}</p>
          <div className={Styles.reason__text}>{reasonCustomText}</div>
        </div>
      </DrawerPanel>
    </>
  )
}
