import React from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setIsShowDrawer } from '../../../../../../redux/doctorSlicer'
import Styles from './style.module.scss'
import { DoctorItem } from '../DoctorItem'
import { DrawerPanel } from '@components/Drawer'
import { DoctorSurveysDrawer } from '@components/Conclusion/components/DoctorSurveysDrawer'
import closeButton from '@icons/close.svg'

export const DoctorSurveys: React.FC = () => {
  const dispatch = useAppDispatch()
  const researchReports = useAppSelector((state) => state.doctor.researchReports)
  const isShowDrawer = useAppSelector((state) => state.doctor.isShowDrawer)
  const openDrawer = () => dispatch(setIsShowDrawer(true))
  const closeDrawer = () => dispatch(setIsShowDrawer(false))

  return (
    <>
      <div className={Styles.block}>
        {researchReports.length !== 0 &&
          researchReports.map((report) => <DoctorItem key={report.id}>{report.name}</DoctorItem>)}
        <div className={Styles.wrap}>
          {researchReports.length !== 0 && (
            <button className={Styles.open} onClick={openDrawer}>
              {}
            </button>
          )}
        </div>
      </div>
      <DrawerPanel width='100%' anchor='right' open={isShowDrawer} onClose={closeDrawer}>
        <div className={Styles.container}>
          <div className={Styles.header}>
            <h1 className={Styles.caption}>Обследования</h1>
            <button className={Styles.close} onClick={closeDrawer}>
              <img src={closeButton} alt='Закрыть' />
            </button>
          </div>
          <div className={Styles.content}>
            <DoctorSurveysDrawer data={researchReports} />
          </div>
        </div>
      </DrawerPanel>
    </>
  )
}
