import { useAppDispatch } from '@app/redux/hooks'
import React, { useEffect, useState } from 'react'
import { Invitation } from '../../appointment-page/components/Invitation'
import Styles from './style.module.scss'
import { postDalailAboutAppointment } from '../../appointment-page/redux/paymentSlice'
import { Spinner } from '@components/Spinner'

export const UnsuccessfulPage = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const [item, setItem] = useState(null)
  useEffect(() => {
    dispatch(postDalailAboutAppointment()).then((res) => {
      setItem(res.payload)
    })
  }, [])
  return (
    <div className={Styles.container}>
      <h2 className={Styles.header}>В процессе оплаты произошла ошибка</h2>
      {item ? <Invitation item={item} /> : <Spinner />}
    </div>
  )
}
