import React from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { InputRadio } from '../../../../../UI/InputRadio'

import caretIcon from '@icons/caret.svg'

import { formateDate } from '../../../../../../../../utils/formateDate'

interface RecordingSetupProps {
  selectedDate: any
  selectTime: any
  toggleSelection: any
  selectedDirection: any
  selectType: any
  addAppointment: any
  formatedDateShort: string
  formatedDateDay: string
  timeAppointment: string
  onChangeFree: any
}

export const RecordingSetup: React.FC<RecordingSetupProps> = (props) => {
  const {
    selectedDate,
    selectTime,
    toggleSelection,
    selectedDirection,
    selectType,
    addAppointment,
    formatedDateShort,
    formatedDateDay,
    timeAppointment,
    onChangeFree,
  } = props

  const dataAppointment = useAppSelector((state) => state.doctor.dataAppointment)

  return (
    <>
      <div className={Styles.container}>
        <span className={Styles.caption}>{formateDate(selectedDate.day, 'DD MMMM')}</span>
        <form className={Styles.form}>
          <div className={Styles.records}>
            {selectedDate?.receptions?.map((reception) => (
              <>
                {!reception.is_blacklist && !reception.is_past && !reception.appointment ? (
                  <label key={reception.id} className={Styles.record}>
                    <InputRadio
                      id={reception.id}
                      className={Styles.radio}
                      onChange={selectTime}
                      name='date'
                      value={reception.time}
                    />
                    <span className={Styles.time}>{reception.time}</span>
                  </label>
                ) : (
                  <>
                    {!reception.is_blacklist && reception.appointment ? (
                      <div key={reception.id} className={`${Styles.record} ${Styles.record_expired}`}>
                        <i
                          className={
                            reception.appointment.is_online ? `${Styles.icon}` : `${Styles.icon} ${Styles.icon_offline}`
                          }
                        >
                          {}
                        </i>
                        <span className={Styles.time}>{reception.time}</span>
                      </div>
                    ) : (
                      <div key={reception.id} className={`${Styles.record} ${Styles.record_expired}`}>
                        <span className={Styles.time}>{reception.time}</span>
                      </div>
                    )}
                  </>
                )}
              </>
            ))}
          </div>
          <div className={Styles.block}>
            <span className={Styles.field}>Выберите направление консультации</span>
            <div className={Styles.select}>
              <div className={Styles.row} onClick={() => toggleSelection()}>
                <span className={Styles.title}>{selectedDirection.title}</span>
                <img src={caretIcon} alt='Иконка' />
              </div>
            </div>
          </div>
          <div className={Styles.block}>
            <span className={Styles.field}>Выберите тип консультации</span>
            <div className={Styles.inputs}>
              <label className={Styles.label}>
                Первичная
                <InputRadio className={Styles.input} onChange={selectType} name='type' value={1} />
              </label>
              <label className={Styles.label}>
                Вторичная
                <InputRadio className={Styles.input} onChange={selectType} name='type' value={2} />
              </label>
            </div>
          </div>
          <div className={Styles.block}>
            <label className={Styles.free}>
              <input
                className={Styles.check}
                onChange={onChangeFree}
                type='checkbox'
                checked={dataAppointment.is_free}
              />
              <span className={Styles.checkbox}>{}</span>
              Бесплатный прием
            </label>
          </div>
        </form>
      </div>
      <div className={Styles.confirm}>
        {selectedDate ? (
          <div className={Styles.wrap}>
            <span>Запись на прием</span>
            <span>
              {formatedDateShort}, {formatedDateDay}, {timeAppointment}
            </span>
          </div>
        ) : (
          <div className={Styles.wrap}>
            <span>Запись на прием</span>
            <span>выберите дату</span>
          </div>
        )}
        <button
          onClick={addAppointment}
          disabled={
            !dataAppointment.day_reception || !dataAppointment.time_reception || !dataAppointment.consultation_type
          }
        >
          Записать
        </button>
      </div>
    </>
  )
}
