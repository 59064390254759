import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import { getAnalyzesFilterList } from '../../../../../../pages/catalog/redux/catalogSlicer'
import { FilterAnalysesModal } from './components/FilterAnalysesModal'

export const CatalogResearchFilters: React.FC = () => {
  const filterView = useAppSelector((state) => state.catalog.filterView)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAnalyzesFilterList())
  }, [])

  return (
    <div className={Styles.container}>
      {filterView === 'categories' && <FilterAnalysesModal filterView={filterView} title='Категория' />}
      {filterView === 'body_system' && (
        <FilterAnalysesModal filterView={filterView} title='Органы и системы организма' />
      )}
    </div>
  )
}
