import React, { useState } from 'react'
import Styles from './style.module.scss'
import { useDropzone } from 'react-dropzone'
import { OneFileFragment } from '@components/ui/OneFileFragment'
import paperclip from '@icons/clip.svg'
import { LoadRender } from '@components/LoadRender'
import { useAppSelector } from '@app/redux/hooks'

export const LoadingFileContainer = (props) => {
  const [files, setFiles] = useState([])
  const [count, setCount] = useState(0)
  const dataHandler = useAppSelector((state) => state.consultationPage.data)
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: []) => {
      const newFiles = acceptedFiles.map((file: File) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )
      setFiles([...files, ...newFiles])
    },
  })
  const resetLoad = () => {
    setFiles([])
    setCount(0)
  }

  return (
    <div className={Styles.container}>
      <h6 className={Styles.title}>Результаты обследования</h6>
      <p className={Styles.text}>Прикрепите результаты обследования. Врач получит эту информацию до приема</p>
      <div className={Styles.optional}>
        <div {...getRootProps({ className: `${Styles.input__wrapper}` })}>
          <input {...getInputProps()} />
          <div className={Styles.load__button}>
            <img src={paperclip} />
            Выберите файл
          </div>
          <p className={Styles.input__text}>Или перетащите его сюда</p>
        </div>
      </div>
      <div className={Styles.loaded__container}>
        {dataHandler.files.length > 0 && <LoadRender editLoadDescription={false} data={dataHandler.files} />}
      </div>

      {files.length > 0 && (
        <div className={Styles.progress__wrap}>
          <div className={Styles.progress__text}>
            <span>{count}</span> из <span>{files.length}</span> загружено
          </div>
          <button className={`${Styles.progress__text} ${Styles.load__button_transparent}`} onClick={resetLoad}>
            Отменить
          </button>
        </div>
      )}
      {files.map((file, i) => (
        <OneFileFragment key={i} file={file} setCount={setCount} idConsultation={props.idConsultation} />
      ))}
    </div>
  )
}
