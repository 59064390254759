import React, { useState } from 'react'
import Styles from './style.module.scss'

type ClickableImageProps = {
  src: string
  filename: string
}

export const ClickableImage: React.FC<ClickableImageProps> = ({ src, filename }) => {
  const API_URL = new URL(process.env.REACT_APP_API)
  const API_IMAGE_URL = API_URL.origin
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleImageClick = () => {
    setIsModalOpen(true)
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsModalOpen(false)
    }
  }
  // временное решение, чтобы работали картинки в чате и в отзывах
  // TODO отдельный вьюер для картинок отзывов
  const imageSrc = src.startsWith('h') ? src : `${API_IMAGE_URL}${src}`

  return (
    <>
      <img src={imageSrc} alt='' className={Styles.image} onClick={handleImageClick} />
      {isModalOpen && (
        <div className={Styles.imageModal} onClick={handleOverlayClick}>
          <img className={Styles.imageModal__image} src={imageSrc} alt='' />
          <span>{filename}</span>
        </div>
      )}
    </>
  )
}
