import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import React, { useEffect } from 'react'
import Styles from './style.module.scss'
import clock from '@icons/clock.svg'
import { Description } from '../../../pages/appointment-page/components/TypeOfConsultation/components/Description'
import { Total } from '../../../pages/appointment-page/components/Total'
import { NearestReception } from '../../../pages/appointment-page/components/TypeOfConsultation/components/NearestReception'
import { DoctorInformation } from '../../../pages/appointment-page/components/TypeOfConsultation/components/DoctorInfo'
import { ButtonColor } from '@components/ui/ButtonColor'
import { setData } from '../../../pages/appointment-page/redux/consultationSlicer'
import { changeTitle, consultationActive } from '../../../pages/appointment-page/redux/choiсeConsultationSlicer'
import { Link } from 'react-router-dom'

export const ModalAppointment = () => {
  const dispatch = useAppDispatch()
  const idConsultation = useAppSelector((state) => state.consultationsList.selectConsultationById)
  const data = useAppSelector((state) => state.consultationsList.data)
  const selectConsultation = data.find((item) => item.id === idConsultation)
  const dataHandler = useAppSelector((state) => state.consultationPage.data)
  const doctor = selectConsultation.doctors[0]
  useEffect(() => {
    dispatch(setData({ key: 'doctor', value: doctor.id }))
    dispatch(setData({ key: 'doctorData', value: doctor }))
  }, [doctor])
  const handle = () => {
    dispatch(consultationActive(1))
    dispatch(changeTitle({ id: 0, value: selectConsultation.title }))
  }
  // console.log(selectConsultation)

  return (
    <div className={Styles.main__conteiner}>
      <h1 className={Styles.main__title}>Запись на консультацию</h1>
      <div className={Styles.conteiner}>
        <div className={Styles.right__block}>
          <div className={Styles.details__container}>
            <header className={Styles.header}>
              <div className={Styles.header__wrap}>
                <img src={selectConsultation.image} className={Styles.img} alt='reference' />
                <h4 className={Styles.title}>{selectConsultation.title}</h4>
              </div>

              <div className={Styles.wrapper}>
                <img src={clock} className={Styles.icon} alt='clock' />
                <div className={Styles.duration}>{selectConsultation.types[0].duration}</div>
              </div>
            </header>
            <Description selectConsultation={selectConsultation} />
          </div>
          <div className={Styles.buttons__container}>
            <Link to='/appointment-page'>
              <ButtonColor value='Перейти к записи' onClick={handle} />
            </Link>
          </div>
        </div>
        <div>
          <Total
            defaultPrice
            consultation={false}
            date={false}
            isBorder={false}
            selectConsultation={selectConsultation}
            lending
          />
          <NearestReception item={selectConsultation.available_time} />
          {dataHandler.doctorData && (
            <DoctorInformation
              name={`${selectConsultation?.doctors[0]?.first_name} ${selectConsultation?.doctors[0]?.last_name}`}
              prof={selectConsultation?.doctors[0]?.specialty}
              expir={selectConsultation?.doctors[0]?.years_experience}
              count={selectConsultation?.doctors[0]?.number_conducted_consultations}
              avatar={selectConsultation?.doctors[0]?.avatar || ''}
            />
          )}
        </div>
      </div>
    </div>
  )
}
