/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import reportIcon from '@icons/report.svg'
import prepare1Icon from '@icons/prepare1.svg'
import prepare2Icon from '@icons/prepare2.svg'
import Styles from './style.module.scss'

export const CatalogPreparation = ({ prepare_text }) => {
  return (
    <div className={Styles.preparation}>
      <div className={Styles.content}>
        <div className={Styles.block}>
          <div className={Styles.image}>
            <img src={reportIcon} alt='Иконка' />
          </div>
        </div>
        <div className={Styles.block}>
          <h4 className={Styles.caption}>Подготовка к анализам</h4>
          <pre className={Styles.text}>{prepare_text}</pre>
          {/* <p className={Styles.text}>
            Взятие крови предпочтительно проводить утром натощак, после 8-14 часов ночного периода голодания (воду пить
            можно). Допустимо днем через 4 часа после легкого приема пищи.
          </p>
          <p className={Styles.text}>
            Накануне исследования необходимо исключить повышенные психоэмоциональные и физические нагрузки (спортивные
            тренировки), приём алкоголя
          </p> */}
        </div>
      </div>
      {/* <div className={Styles.wrapper}>
        <div className={Styles.container}>
          <div className={Styles.wrap}>
            <div className={Styles.icon}>
              <img src={prepare1Icon} alt='Иконка' />
            </div>
          </div>
          <div className={Styles.wrap}>
            <span className={Styles.title}>Как берут анализ</span>
            <span className={Styles.subtitle}>Для исследования берется кровь из вены</span>
          </div>
        </div>
        <div className={Styles.container}>
          <div className={Styles.wrap}>
            <div className={Styles.icon}>
              <img src={prepare2Icon} alt='Иконка' />
            </div>
          </div>
          <div className={Styles.wrap}>
            <span className={Styles.title}>Исследуемый материал</span>
            <span className={Styles.subtitle}>Сыворотка крови</span>
          </div>
        </div>
      </div> */}
    </div>
  )
}
