import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setReviewsFilterProps } from '../../../../redux/catalogSlicer'
import React from 'react'
import Styles from './style.module.scss'

export const FilterToggle: React.FC = () => {
  const dispatch = useAppDispatch()
  const onlyPhoto = useAppSelector((state) => state.catalog.reviewsFilter.has_photo)
  const handleChange = (e) => {
    e.target.checked
      ? dispatch(setReviewsFilterProps({ has_photo: true }))
      : dispatch(setReviewsFilterProps({ has_photo: false }))
  }
  return (
    <label className={Styles.label}>
      <input className={Styles.checkbox} checked={onlyPhoto} type='checkbox' onChange={handleChange} />
      <span className={Styles.slider}>{}</span>
    </label>
  )
}
