import React, { FC } from 'react'
import styles from './style.module.scss'

import { OutlinedContainer } from '@components/Containers/OutlinedContainer'
import { ContainerWithTag } from '@components/Containers/ContainerWithTag'
import { BadgeWithLogo } from '@components/ui/BadgeWithLogo'

import classNames from 'classnames/bind'
import { ordersTypes } from '../../redux/orderTypes'

import invitro from '@images/invitro.png'
// import kdl from '@images/kdl.png'

interface OrderAnalysisItemProps {
  analysis: ordersTypes.IAnalysisInOrder
  complex?: boolean
}

export const OrderAnalysisItem: FC<OrderAnalysisItemProps> = ({ analysis, complex }) => {
  const cx = classNames.bind(styles)
  return (
    <>
      {complex ? (
        <ContainerWithTag customStyle={cx(styles.item, styles.containerWithTag)} tagName='Комплекс анализов'>
          <h4 className={styles.title}>{analysis.title}</h4>
          <div className={styles.logos}>
            <BadgeWithLogo image={invitro} />
            {/* <BadgeWithLogo image={kdl} /> */}
          </div>
        </ContainerWithTag>
      ) : (
        <OutlinedContainer customStyle={cx(styles.item, styles.outlinedContainer)}>
          <h4 className={styles.title}>{analysis.title}</h4>
          <div className={styles.logos}>
            <BadgeWithLogo image={invitro} />
            {/* <BadgeWithLogo image={kdl} /> */}
          </div>
        </OutlinedContainer>
      )}
    </>
  )
}
