import React from 'react'
import { useAppSelector } from '@app/redux/hooks'

import { PersonalInfo } from './components/PersonalInfo'
import { CancelVisits } from './components/CancelVisits'

export const DoctorProfile: React.FC = () => {
  const displayProfileContent = useAppSelector((state) => state.doctor.displayProfileContent)

  return (
    <>
      {displayProfileContent === 'info' && <PersonalInfo />}
      {displayProfileContent === 'cancel' && <CancelVisits />}
    </>
  )
}
