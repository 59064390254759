import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

export const EditData: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user.info)
  const [values, setValues] = useState({ first_name: '', last_name: '' })

  const handlerChange = (event) => setValues({ ...values, [event.target.name]: event.target.value })

  useEffect(() => {
    setValues({ ...values, first_name: user.first_name, last_name: user.last_name })
  }, [])

  return (
    <div className={Styles.edit}>
      {/* <div className={Styles.notification}>Все изменения сохранены</div> */}
      <form className={Styles.form}>
        <div className={Styles.block}>
          <label className={Styles.label}>
            <span>Имя*</span>
            <input
              className={Styles.input}
              onChange={handlerChange}
              type='text'
              name='first_name'
              placeholder='Введите ваше имя'
              value={values.first_name}
            />
          </label>
          <label className={Styles.label}>
            <span>Фамилия</span>
            <input
              className={Styles.input}
              onChange={handlerChange}
              type='text'
              name='last_name'
              placeholder='Введите вашу фамилию'
              value={values.last_name}
            />
          </label>
        </div>
        <button className={Styles.save}>Сохранить изменения</button>
      </form>
    </div>
  )
}
