import React from 'react'
import Styles from './style.module.scss'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames/bind'
import { useAppSelector } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import { useCatalog } from '@components/Notifications/utils/useCatalog'
import { useBlog } from '../../pages/blog/utils/useBlog'

export const Wrapper = ({ children }): React.ReactElement => {
  const location = useLocation()
  const loggedIn = useAppSelector((state) => state.auth.user.isLoggedIn)
  const userRole = useAppSelector((state) => state.auth.user.info.role)
  const styles = {
    main: `${Styles.components__wrapper}`,
    noPadding: `${Styles.no_padding}`,
    noPaddingAppointment: `${Styles.appointment}`,
    noMobileMenu: `${Styles.noMobileMenu}`,
    noPaddingMobile: `${Styles.no_padding_mobile}`,
  }
  const cx = classNames.bind(styles)
  const { width } = useWindowSize()
  const desktop = width >= 829
  useCatalog()
  useBlog()

  return (
    <div
      className={`${cx('main', {
        noPadding: location.pathname === '/login' || ('/' && userRole === 'patient' && !desktop),
        noPaddingMobile:
          location.pathname === '/contacts' ||
          location.pathname === '/pay' ||
          location.pathname === '/personal-data' ||
          location.pathname === '/confidential' ||
          location.pathname === '/return' ||
          location.pathname === '/login',
        noPaddingAppointment: location.pathname === '/appointment-page' || location.pathname === '/onboarding',
        noMobileMenu: !loggedIn,
      })}`}
    >
      {children}
    </div>
  )
}
