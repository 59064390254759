import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { loadAppointment } from '../../index'
import { setIsTransferModal } from '../../redux/doctorSlicer'
import { DoctorContainer } from '../DoctorContainer'
import { DoctorNotation } from '../DoctorNotation'
import { Loader } from '../UI/Loader'
import { MedicalReportsModal } from '@components/MedicalReportsModal'
import { DoctorTransferModal } from './components/DoctorTransferModal'

export const DoctorPageAppointment: React.FC = () => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.doctor.loading)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const currentMedicalReport = useAppSelector((state) => state.reports.currentMedicalReport)
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    dispatch(setIsTransferModal(false))
    loadAppointment(id, dispatch)
  }, [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <DoctorContainer title='Запись на прием' back='Назад на главную' to='/'>
            <DoctorNotation />
          </DoctorContainer>
          <MedicalReportsModal />
          <DoctorTransferModal />
        </>
      )}
    </>
  )
}
