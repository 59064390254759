import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '@app/redux/hooks'
import { getDoctorCalendarMonth } from '../../../../doctor/redux/doctorSlicer'
import Styles from './style.module.scss'

import caretIcon from '@icons/caret.svg'

import { formateDate, getMonth } from '../../../../../utils/formateDate'

interface CalendarProps {
  currentMonthDate: any
  changeMonth: any
  selectedDate: any
  selectDay: any
}

export const Calendar: React.FC<CalendarProps> = ({ currentMonthDate, changeMonth, selectedDate, selectDay }) => {
  const dispatch = useAppDispatch()

  const [currentMonth, setCurrentMonth] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)

  const directionChange = (direction) => {
    if (direction === 'backward') {
      const date = new Date(currentMonthDate)
      const prevDate = formateDate(date.setMonth(date.getMonth() - 1), 'YYYY-MM-DD')
      changeMonth(prevDate)
    }

    if (direction === 'forward') {
      const date = new Date(currentMonthDate)
      const nextDate = formateDate(date.setMonth(date.getMonth() + 1), 'YYYY-MM-DD')
      changeMonth(nextDate)
    }
  }

  const loadMonth = async (date) => {
    setLoading(true)

    try {
      const dataMonth = getMonth(date)

      const response = await dispatch(
        getDoctorCalendarMonth({ start_date: dataMonth.firstDayMonth, end_date: dataMonth.lastDayMonth }),
      )

      setCurrentMonth(response.payload.data)
      setLoading(false)
    } catch (error) {
      throw new Error(error)
    }
  }

  useEffect(() => {
    loadMonth(currentMonthDate)
  }, [currentMonthDate])

  return (
    <div className={Styles.calendar}>
      <div className={Styles.head}>
        <div className={Styles.shell}>
          <span className={Styles.daytime}>Пн</span>
          <span className={Styles.daytime}>Вт</span>
          <span className={Styles.daytime}>Ср</span>
          <span className={Styles.daytime}>Чт</span>
          <span className={Styles.daytime}>Пт</span>
          <span className={Styles.daytime}>Сб</span>
          <span className={Styles.daytime}>Вс</span>
        </div>
      </div>
      <div className={Styles.body}>
        <div className={Styles.select}>
          <img
            className={`${Styles.caret} ${Styles.caret_rotate}`}
            src={caretIcon}
            alt='Иконка'
            onClick={() => directionChange('backward')}
          />
          <span className={Styles.month}>{formateDate(currentMonth[15]?.day, 'MMMM YYYY')}</span>
          <img className={Styles.caret} src={caretIcon} alt='Иконка' onClick={() => directionChange('forward')} />
        </div>
        <div className={Styles.days}>
          {currentMonth.map((date) => {
            const inPeriod = formateDate(date.day, 'YYYY-MM') === formateDate(currentMonth[15].day, 'YYYY-MM')

            return (
              <div
                key={date.day}
                className={selectedDate?.day === date.day ? `${Styles.day} ${Styles.day_selected}` : `${Styles.day}`}
              >
                {!date.is_available || inPeriod === false ? (
                  <span className={`${Styles.inner} ${Styles.inner_disabled}`}>{formateDate(date.day, 'DD')}</span>
                ) : (
                  <span className={Styles.inner} onClick={() => selectDay(date)}>
                    {formateDate(date.day, 'DD')}
                  </span>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
