import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import {
  createDraftAppointment,
  postDraftAppointmentFile,
} from '../../../pages/appointment-page/redux/consultationSlicer'
import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'

export const OneFileFragment = (props) => {
  const dispatch = useAppDispatch()
  const idConsultation = useAppSelector((state) => state.consultationsList.selectConsultationById)
  const [progress, setProgress] = useState(null)
  const [error, setError] = useState(false)
  useEffect(() => {
    const upload = async () => {
      await uploadFile(props.file)
    }
    upload()
  }, [])

  const a = (file) => {
    const body = new FormData()
    body.append('file', file)
    dispatch(
      postDraftAppointmentFile({
        id: idConsultation,
        data: body,
        onUploadProgress: (data) => {
          setProgress(data)
        },
      }),
    ).then((res) => {
      if (res.payload.status < 300) {
        props.setCount((prev) => prev + 1)
      } else {
        setError(true)
      }
    })
  }
  const uploadFile = (file) => {
    return new Promise((resolve) => {
      resolve(a(file))
    })
  }

  const formatBytes = (bytes) => {
    if (bytes < 1024) {
      return bytes + ' Bytes'
    } else if (bytes < 1048576) {
      return (bytes / 1024).toFixed(1) + ' KB'
    } else if (bytes < 1073741824) {
      return (bytes / 1048576).toFixed(1) + ' MB'
    } else {
      return (bytes / 1073741824).toFixed(1) + ' GB'
    }
  }

  return (
    <>
      {progress && (
        <div className={Styles.load__container}>
          <div
            className={Styles.progress}
            style={{ width: `${Math.round((100 * progress.loaded) / progress.total)}%` }}
          >
            <div className={Styles.load__wrap}>
              <a
                target='_blank'
                href={URL.createObjectURL(props.file)}
                rel='noreferrer'
                key={props.file.name.toString()}
              >
                {/\.(jpe?g|png|gif|bmp|svg)$/i.test(props.file.name) ? (
                  <img src={URL.createObjectURL(props.file)} alt='foto' className={Styles.item__img} />
                ) : (
                  <div className={Styles.item__doc}>{props.file.name.match(/\.([^.]+)$|$/)[1]}</div>
                )}
              </a>
              <div className={Styles.item__desc}>
                <h6 className={Styles.item__name}>
                  {props?.file?.name.length > 11
                    ? `${props?.file?.name.substr(0, 11)}...${props?.file?.name.slice(
                        props?.file?.name.lastIndexOf('.'),
                      )}`
                    : props?.file?.name}
                </h6>
                <p className={Styles.item__weight}>{formatBytes(props.file.size)}</p>
                {error && <div className={Styles.error__handler}>Произошла ошибка</div>}
              </div>
            </div>
            {Math.round((100 * progress.loaded) / progress.total) === 100 ? (
              <div className={`${Styles.load__status} ${Styles.load__status_success}`} />
            ) : (
              <div className={`${Styles.load__status} ${Styles.load__status_progress}`}>{`${Math.round(
                (100 * progress.loaded) / progress.total,
              )}%`}</div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
