import React from 'react'
import Styles from './style.module.scss'
import image from '@images/chat-unavailable.svg'
import { Link } from 'react-router-dom'

export const UnavailableChat = () => {
  return (
    <div className={Styles.container}>
      <img src={image} alt='picture' className={Styles.image} />
      <p className={Styles.text}>Чат с врачом пока не доступен. Посетите консультацию, чтобы задавать вопросы врачу</p>
      <Link to='/appointment-page' className={Styles.link}>
        Записаться на прием
      </Link>
    </div>
  )
}
