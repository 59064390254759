import React from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import { setIsTransferModal } from '../../../../redux/doctorSlicer'
import { DrawerComponent } from '@components/DrawerComponent'
import { DoctorTransfer } from '../DoctorTransfer'
import { DoctorTransferMobile } from '../DoctorTransfer/components/DoctorTransferMobile'
import { MOBILE_BREAKPOINT } from '../../../../constants'

export const DoctorTransferModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const isTransferModal = useAppSelector((state) => state.doctor.isTransferModal)

  const closeModal = () => dispatch(setIsTransferModal(false))

  return (
    <DrawerComponent open={isTransferModal} closeModal={closeModal}>
      {width > MOBILE_BREAKPOINT ? <DoctorTransfer /> : <DoctorTransferMobile title='Перенести прием' />}
    </DrawerComponent>
  )
}
