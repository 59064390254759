import React from 'react'
import Styles from './style.module.scss'
import { DrawerPanel } from '@components/Drawer'
import closeButton from '@icons/close.svg'
import list from '@icons/list-white.svg'
import Parser from 'html-react-parser'

export const DoctorSurveysDrawerById = (props) => {
  return (
    <DrawerPanel
      width='100%'
      anchor='right'
      open={props.showDetail}
      onClose={() => {
        props.setShowDetail((isOpen) => !isOpen)
      }}
    >
      <div className={Styles.drawer}>
        <div className={Styles.title__container}>
          <button
            className={Styles.button__arrow}
            onClick={() => {
              props.setShowDetail(false)
            }}
          />
          <h1 className={Styles.title}>{props.detailItem.name}</h1>
          <img
            src={closeButton}
            alt='img'
            className={Styles.closeButton}
            onClick={() => {
              props.setShowDetail(false)
            }}
          />
        </div>
        <div className={Styles.container__details}>
          <div className={Styles.preparation}>
            <img alt='img' src={list} />
            <div className={Styles.desc}>
              <h6>Подготовка к анализам</h6>
              <div>{Parser(props.detailItem.preparation_for_analysis)}</div>
              {/* <div>{props.detailItem.preparation_for_analysis}</div> */}
            </div>
          </div>
          <div className={Styles.description}>
            <h5>Описание</h5>
            <div>{Parser(props.detailItem.description)}</div>
          </div>
        </div>
      </div>
    </DrawerPanel>
  )
}
