import { AuthSubmitButton } from '@components/AuthSubmitButton'
import React, { useState, FC } from 'react'
import Styles from './style.module.scss'
import PhoneInput from 'react-phone-number-input/input'
import { registerUserNumber } from '../../redux/authSlice'
import { useAppDispatch } from '@app/redux/hooks'
import { SUCCESS, SERVER_ERROR } from '@app/HTTP_CODES'
import { useSnackbar } from 'notistack'

interface InputPhoneFormProps {
  setCurrentPage: (number) => void
  errors: any
  setValues: (number) => void
  setErrors: any
}

export const InputPhoneForm: FC<InputPhoneFormProps> = ({ setCurrentPage, errors, setValues, setErrors }) => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState<any>('')
  const { enqueueSnackbar } = useSnackbar()

  const handlePhoneSubmit = (e) => {
    e.preventDefault()
    dispatch(registerUserNumber({ phone: value }))
      .then((res) => {
        if (res.payload.status === SUCCESS) {
          setValues({ phone: value })
          setCurrentPage(2)
        } else if (res.payload.status === SERVER_ERROR) {
          setErrors({ phone: 'Неверный номер телефона' })
          const message = `Сервер недоступен, повторите попытку позже`
          const type = 'error'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        } else {
          setErrors({ phone: 'Неверный номер телефона' })
          const message = `Неверный номер телефона`
          const type = 'error'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        }
      })
      .catch(() => {
        const message = `Что-то пошло не так. Проверьте соединение с интернетом`
        const type = 'error'
        enqueueSnackbar(
          { message, type },
          {
            autoHideDuration: 1500,
          },
        )
      })
  }

  const handlePhoneInputChange = (value) => {
    setErrors({ phone: '' })
    setValue(value)
  }

  return (
    <>
      <h3 className={Styles.form__title}>Вход или регистрация</h3>
      <label className={Styles.form__label}>Телефон*</label>
      <PhoneInput
        className={Styles.form__input}
        placeholder='+7'
        type='tel'
        value={value}
        onChange={handlePhoneInputChange}
        minLength={2}
        maxLength={17}
        name='phone'
        defaultCountry='RU'
        withCountryCallingCode
      />
      {!errors.phone ? (
        <p className={Styles.form__paragraph}>Нужен для входа по коду. Это бесплатно</p>
      ) : (
        <p className={Styles.form__error}>{errors.phone}</p>
      )}
      <AuthSubmitButton onClick={handlePhoneSubmit} buttonText='Получить код в смс' />
    </>
  )
}
