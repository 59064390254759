import { deleteCartId } from '../../redux/cartSlicer'
import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import { useAppDispatch } from '@app/redux/hooks'

interface DeleteComponentProps {
  name: string
  setActiveDeleteComponent: (boolean: boolean) => void
  // deletedItem: any
  id: number
  arrNameDelete: any
}
export const DeleteComponent: React.FC<DeleteComponentProps> = ({
  name,
  setActiveDeleteComponent,
  // deletedItem,
  id,
  arrNameDelete,
}) => {
  const [counter, setCounter] = useState(10)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (counter === 0) {
      setActiveDeleteComponent(false)
    }
  }, [counter])

  const obj = {}
  obj[arrNameDelete] = id
  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    return () => {
      clearInterval(timer)
      if (counter === 0) {
        dispatch(deleteCartId(obj))
          // .then((res) => deletedItem(res.payload))
          .then(() => setActiveDeleteComponent(false))
      }
    }
  }, [counter])

  return (
    <div className={Styles.delete}>
      <div>
        <h4>Вы удалили</h4>
        <p>{name}</p>
      </div>
      <button
        onClick={() => {
          setActiveDeleteComponent(false)
        }}
      >
        Восстановить <span>{counter}</span>
      </button>
    </div>
  )
}
