/* eslint-disable */
import React from 'react'
// import { createStyles, makeStyles, Theme } from '@mui/material/styles'
import Modal from '@mui/material/Modal'
import Backdrop from '@mui/material'
import Fade from '@mui/material/Fade'
import Styles from './style.module.scss'
import { ButtonClose } from '../ButtonClose'

interface MobileModalProps {
  className?: string
  setModalOpened: (e: any) => void
  isOpenModal: boolean
  disablePortal?: boolean
  children: any
  opacity?: string
}

/*const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
)*/

export const MobileModal: React.FC<MobileModalProps> = (props) => {
  // const classes = useStyles()

  const handleClose = () => {
    props.setModalOpened(false)
  }

  return (
    <div>
      <Modal
        disablePortal={props.disablePortal || false}
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        // className={classes.modal}
        open={props.isOpenModal}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { opacity: props.opacity || '' },
        }}
      >
        <Fade in={props.isOpenModal}>
          <div className={Styles.modal_container}>
            <div className={`${Styles.content} ${props.className}`}>{props.children}</div>
            <ButtonClose className={Styles.close} onClick={handleClose} />
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
