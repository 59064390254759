import React, { useState } from 'react'
import Styles from './style.module.scss'

import { formateDate } from '../../../../../../../../../../utils/formateDate'

interface DoctorRecordMonthMobileProps {
  monthDay: any
  selectedDay: any
  setSelectedDay: any
}

export const DoctorRecordMonthMobile: React.FC<DoctorRecordMonthMobileProps> = (props) => {
  const { monthDay, selectedDay, setSelectedDay } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [className, setClassName] = useState([`${Styles.record}`])

  /*   useEffect(() => {
    const cls = [`${Styles.record}`]

    if (monthDay) {
      const today = formateDate(new Date(), 'YYYY-MM-DD') === formateDate(monthDay.day, 'YYYY-MM-DD')

      if (today) {
        cls.push(`${Styles.record_today}`)
        setClassName(cls)
      }
    }
  }, []) */

  return (
    <>
      {monthDay.is_available ? (
        <div
          className={
            monthDay.day === selectedDay?.day ? `${className.join(' ')} ${Styles.record_active}` : `${Styles.record}`
          }
          onClick={() => setSelectedDay(monthDay)}
        >
          <div className={Styles.wrap}>
            <span className={Styles.day}>{formateDate(monthDay.day, 'DD')}</span>
            <i className={Styles.icon}>{}</i>
          </div>
        </div>
      ) : (
        <div className={`${className.join(' ')} ${Styles.record_disabled}`}>
          <div className={Styles.wrap}>
            <span className={Styles.day}>{formateDate(monthDay.day, 'DD')}</span>
          </div>
        </div>
      )}
    </>
  )
}
