import React, { useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import { Invitation } from '../../appointment-page/components/Invitation'
import { useAppDispatch } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import sctollToTop from '@helpers/scrollToTop'
import { getOrder } from '../../cart/redux/cartSlicer'
import { CreatedOrder } from '../../cart/CreatedOrder'
import { getAppointmentById } from '../../../pages/my-appointment/redux/appointmentByIdSlicer'
import { Spinner } from '@components/Spinner'

export const SuccessPage = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const component = useRef<HTMLDivElement>(null)
  const [typeMove, setTypeMove] = useState(null)
  const [order, setOrder] = useState(null)
  const [item, setItem] = useState(null)
  const [error, setError] = useState(false)
  const desktop = width >= 829
  const topSize = desktop ? 0 : 70

  const loadPage = async () => {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get('id')
    const typeItem = queryParams.get('type')
    setTypeMove(typeItem)

    try {
      if (typeItem === 'order') {
        await dispatch(getOrder(id)).then((res) => setOrder(res.payload))
        const res = await dispatch(getOrder(id))
        setOrder(res.payload)
      }
      if (typeItem === 'appointment') {
        await dispatch(getAppointmentById(id)).then((res) => {
          if (res.payload.detail !== 'Учетные данные не были предоставлены.') {
            setItem(res.payload)
          } else {
            setError(true)
          }
        })
      }
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => {
    sctollToTop(component, topSize)
  }, [])

  useEffect(() => {
    loadPage()
  }, [])

  return (
    <div className={Styles.container} ref={component}>
      {order || item || error ? (
        <>
          <h2 className={Styles.header}>Оплата прошла успешно!</h2>
          {order && typeMove === 'order' && <CreatedOrder createdOrder={order} />}
          {item && typeMove === 'appointment' && <Invitation item={item} />}
          {error && <div>Авторизируйтесь снова, пожалуйста</div>}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  )
}
