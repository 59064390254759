import React from 'react'
import Styles from './style.module.scss'
import cart from '@icons/cart-black.svg'
import { ButtonWithBorder } from '@components/ui/ButtonWithBorder'
import { Link } from 'react-router-dom'

export const CartHeaderComponent = ({ cartData }) => {
  const data = [...cartData?.products, ...cartData?.product_sets, ...cartData?.analyzes, ...cartData?.analysis_sets]
  return (
    <>
      <div className={Styles.cart}>
        <Link to='/cart' className={Styles.link__cart}>
          <span className={Styles.count}>{cartData.count}</span>
          <img src={cart} alt='cart' />
          <span className={Styles.price}>{cartData.total_cost} ₽</span>
        </Link>

        <div className={Styles.modal}>
          <div className={Styles.modal_wrap}>
            {data.map((item, id) => (
              <div key={id} className={Styles.item}>
                {item.image && (
                  <div className={Styles.link}>
                    <img src={item.image} alt='img' />
                  </div>
                )}
                <div>
                  <h6>{item.title || 'Комплекс'}</h6>
                  <div>
                    <span className={Styles.discount_sum}>{item.quantity * item.discount_price} ₽</span>
                    <span className={Styles.sum}>{item.quantity * item.price} ₽</span>
                    <span className={Styles.total}>
                      {item.quantity} шт.x {item.discount_price} ₽
                    </span>
                  </div>
                </div>
              </div>
            ))}
            <Link to='/cart/gocheckout' className={Styles.button__link}>
              <ButtonWithBorder value='Перейти к оформлению' className={Styles.modal__button} />
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
