/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '@app/redux/hooks'
import {
  cancelAppointment,
  doneAppointment,
  getListAppointmentPatient,
  startAppointment,
} from '../../../../redux/doctorSlicer'
import Styles from './style.module.scss'
import moment from 'moment'

import viewIcon from '@icons/view.svg'

import { createConclusion } from '../../../../index'
import { useSnackbar } from 'notistack'
import { ButtonWithBorder } from '@components/ui/ButtonWithBorder'
import useWindowSize from '@helpers/useWindowSizeHook'
import { ConclusionMobile } from '@components/Conclusion/ConclusionMobile'

interface DoctorAppointmentButtonProps {
  appointment: {
    id: number
    day_reception: string
    is_online: boolean
    medical_report: any
    meeting_link: string
    status: string
    time_reception: string
    patient: any
    is_free?: boolean
  }
  thisStatus: string
  setThisStatus: (string) => void
}

export const DoctorAppointmentButton: React.FC<DoctorAppointmentButtonProps> = ({
  appointment,
  thisStatus,
  setThisStatus,
}) => {
  const { id, day_reception, time_reception, is_online, medical_report, meeting_link, patient, is_free } = appointment

  useEffect(() => {
    dispatch(getListAppointmentPatient(patient))
  }, [])
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const desktop = width >= 829
  const { enqueueSnackbar } = useSnackbar()
  const [zoomLink, setZoomLink] = useState(meeting_link)
  const [isLoading, setIsLoading] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)

  const calculateTimeLeft = () => {
    const now = moment().valueOf()
    const recieptDate = moment(`${day_reception} ${time_reception}`).valueOf()
    return recieptDate - now
  }
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
  const fifteenMinutes = 900000

  const onStartAppointment = (e) => {
    e.stopPropagation()
    const windowReference = window.open()
    setIsLoading(true)
    dispatch(startAppointment(id))
      .then((res) => {
        if (res.payload.status === 200) {
          setIsLoading(false)
          windowReference.location = res.payload.data.meeting_link
          setZoomLink(res.payload.data.meeting_link)
          setThisStatus('started')
        } else {
          setIsLoading(false)
          const message = `Что-то пошло не так.`
          const type = 'error'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        }
      })
      .catch(() => {
        setIsLoading(false)
        const message = `Что-то пошло не так. Проверьте соединение с интернетом`
        const type = 'error'
        enqueueSnackbar(
          { message, type },
          {
            autoHideDuration: 1500,
          },
        )
      })
  }

  const onFinishAppointment = () => {
    dispatch(doneAppointment(id))
    setThisStatus('done')
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onCancelAppointment = () => {
    dispatch(cancelAppointment(id))
    setThisStatus('cancel')
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 10000)

    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <>
      {thisStatus === 'started' ? (
        <>
          <button className={Styles.finish} onClick={onFinishAppointment}>
            Завершить
          </button>
          {!medical_report && (
            <button className={`${Styles.start} ${Styles.start_create}`} onClick={() => createConclusion(id, dispatch)}>
              Создать заключение
            </button>
          )}
        </>
      ) : (
        <>
          {thisStatus === 'waiting_paying' && (
            <button className={Styles.finish} onClick={onCancelAppointment}>
              Отменить
            </button>
          )}
        </>
      )}
      {thisStatus !== 'done' &&
        thisStatus !== 'cancel' &&
        is_online &&
        (zoomLink ? (
          <>
            <button className={Styles.start}>
              <a href={zoomLink} target='_blank' rel='noreferrer'>
                Перейти в ZOOM
              </a>
            </button>
          </>
        ) : timeLeft <= fifteenMinutes ? (
          thisStatus !== 'planned' && !is_free ? null : (
            <button className={Styles.start} onClick={onStartAppointment} disabled={isLoading}>
              {isLoading ? 'Загрузка...' : 'Начать прием'}
            </button>
          )
        ) : null)}
      {thisStatus === 'waiting_paying' && <span className={Styles.notPayed}>Не оплачен</span>}
      {thisStatus === 'done' && medical_report && desktop ? (
        <button className={Styles.conclusion}>
          <img src={viewIcon} alt='Иконка' />
          <span className={Styles.text}>Заключение</span>
        </button>
      ) : (
        medical_report && (
          <>
            <ButtonWithBorder
              value='Заключение'
              className={Styles.button}
              before
              src={viewIcon}
              onClick={() => setShowDrawer(true)}
            />
            <ConclusionMobile
              showDrawer={showDrawer}
              setShowDrawer={setShowDrawer}
              date={medical_report?.done_date}
              data={medical_report}
            />
          </>
        )
      )}

      {thisStatus === 'done' && !medical_report && (
        <button className={`${Styles.start} ${Styles.start_create}`} onClick={() => createConclusion(id, dispatch)}>
          Создать заключение
        </button>
      )}
      {(thisStatus === 'not_payed' || thisStatus === 'cancel') && (
        <span className={Styles.notPayed}>Прием отменен</span>
      )}
    </>
  )
}
