const sctollToTop = (el, fixSize) => {
  el.current.scrollIntoView(true)
  const scrolledY = window.pageYOffset || document.documentElement.scrollTop
  if (scrolledY && document.documentElement.clientWidth < 829) {
    window.scroll(0, scrolledY - fixSize)
  } else {
    window.scroll(0, 0)
  }
}
export default sctollToTop
