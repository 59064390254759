import React from 'react'
import Styles from './style.module.scss'
import { Field, useField, useForm } from 'react-final-form'
import { styled } from '@mui/material/styles'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { CheckboxComponent } from '../CheckboxComponent'

const StyledSelect = styled(Select)(() => ({
  display: 'flex',
  position: 'absolute',
  left: '85px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-icon': {
    display: 'none',
  },

  '& .MuiSelect-select': {
    color: ' #7D9DFF',
    fontFamily: 'inherit',
    background: '#DCF6FF',
    borderRadius: '12px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '150%',
    position: 'absolute',
    padding: '6px 10px',
  },
}))
export const TimeSelect = ({ name }) => {
  const giftInput = useField(`${name}.gift`)
  const disabledValue = giftInput.input.value

  const SelectConponent = (props) => {
    const form = useForm()
    const handleChange = (e) => {
      form.change(`${name}.option`, e.target.value)
    }
    return (
      // <StyledSelect
      //   // disabled={!disabledValue}
      //   disableUnderline
      //   MenuProps={{
      //     anchorOrigin: {
      //       vertical: 'top',
      //       horizontal: 'right',
      //     },
      //     transformOrigin: {
      //       vertical: 'top',
      //       horizontal: 'left',
      //     },
      //   }}
      //   defaultValue={props.input.value}
      //   onChange={handleChange}
      // >
      //   <MenuItem value='мин' selected>
      //     мин.
      //   </MenuItem>

      //   <MenuItem value='час'>час.</MenuItem>
      // </StyledSelect>
      <select onChange={handleChange} defaultValue={props.input.value} disabled={!disabledValue}>
        <option value='час'>час</option>
        <option value='мин' selected>
          мин.
        </option>
      </select>
    )
  }

  return (
    <div className={Styles.exercise}>
      <div>
        <Field name={`${name}.gift`} component={CheckboxComponent} type='checkbox' className={Styles.input__check} />
      </div>
      <div className={Styles.wrapper}>
        <Field name={`${name}.image`}>
          {({ input }) => (
            <div>
              <img src={input.value} alt='exercise' />
            </div>
          )}
        </Field>
        <div>
          <Field name={`${name}.title`}>
            {({ input }) => (
              <div>
                <label>{input.value}</label>
              </div>
            )}
          </Field>
          <Field name={`${name}.time`} type='number'>
            {({ input }) => {
              return (
                <div className={Styles.block}>
                  <label>Длительность*</label>
                  <div className={Styles.input}>
                    <input type='text' {...input} disabled={!disabledValue} autoComplete='off' />
                    <Field name={`${name}.option`} component={SelectConponent} />
                  </div>
                </div>
              )
            }}
          </Field>
        </div>
      </div>
    </div>
  )
}
