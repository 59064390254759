import React, { useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import { Notify } from './components/Notify'
import { CartSidebar } from './components/CartSidebar'
import { ProductItem } from './components/ProductItem'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { clearCart, deleteCartId, getMyCart, postCart } from './redux/cartSlicer'
import { Spinner } from '@components/Spinner'
import { SetProductItem } from './components/SetProductItem'
import { AnalysisItem } from './components/AnalysisItem'
import sctollToTop from '@helpers/scrollToTop'
import useWindowSize from '@helpers/useWindowSizeHook'

export const Cart = () => {
  const dispatch = useAppDispatch()
  const component = useRef<HTMLDivElement>(null)
  const { width } = useWindowSize()
  const desktop = width >= 829
  const topSize = desktop ? 0 : 70
  const cartData = useAppSelector((state) => state.cart.cartData)
  const [notify, setNotify] = useState(false)

  useEffect(() => {
    sctollToTop(component, topSize)
  }, [])

  useEffect(() => {
    dispatch(getMyCart()).then((res) => {
      setNotify(
        (res.payload.analyzes && res.payload.analyzes.length > 0) ||
          (res.payload.analysis_sets && res.payload.analysis_sets.length > 0),
      )
    })
  }, [])

  const changeSelectVariant = (value, item, field, variant) => {
    cartData.products.map((i) => {
      if (i.id === item.id) {
        dispatch(deleteCartId({ product: item.id })).then(() =>
          dispatch(
            postCart({
              product: field === 'variant_value' ? variant.mods[0].mod_id : variant.mod_id,
              quantity: item.quantity,
            }),
          ),
        )
      }
      return i
    })
  }

  const clearCartData = () => {
    dispatch(clearCart())
  }

  return (
    <>
      {cartData ? (
        <div ref={component}>
          <div className={Styles.title__container}>
            <div className={Styles.title__wrapper}>
              {cartData.count > 0 && (
                <>
                  <h1 className={Styles.title}>
                    Корзина<span className={Styles.title__count}>{cartData.count}</span>
                  </h1>
                  <ButtonTransparent value='Очистить' onClick={clearCartData} />
                </>
              )}
            </div>

            {cartData.count > 0 && (
              <Link to='/catalog' className={Styles.button__link}>
                Вернуться в каталог
              </Link>
            )}
          </div>
          {cartData.count > 0 ? (
            <div className={`${Styles.container}`}>
              <div className={Styles.right}>
                <div className={Styles.content}>
                  {notify && <Notify onClick={() => setNotify(false)} />}
                  {(cartData.products.length > 0 || cartData.product_sets.length > 0) && (
                    <h2 className={Styles.subtitle}>Товары</h2>
                  )}
                  {cartData.products.length > 0 && (
                    <>
                      {cartData.products.map((item) => (
                        <ProductItem key={item.id} item={item} changeSelectVariant={changeSelectVariant} />
                      ))}
                    </>
                  )}

                  {cartData.product_sets.length > 0 &&
                    cartData.product_sets.map((item) => <SetProductItem key={item.id} item={item} />)}

                  {notify && <h2 className={Styles.subtitle}>Лабораторные обследования</h2>}
                  {cartData.analyzes.length > 0 &&
                    cartData.analyzes.map((item) => <AnalysisItem key={item.id} item={item} />)}
                  {cartData.analysis_sets.map((item) => (
                    <AnalysisItem key={item.id} item={item} set />
                  ))}
                </div>
              </div>
              <CartSidebar button />
            </div>
          ) : (
            <div className={Styles.empty}>
              <p>
                В корзине пока ничего нет. Вы можете посмотреть что-нибудь в&nbsp;
                <Link to='/catalog'>каталоге</Link>
                &nbsp;или воспользоваться поиском
              </p>
            </div>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </>
  )
}
