import React from 'react'
import { useAppSelector } from '@app/redux/hooks'

import { DoctorContainer } from '../DoctorContainer'
import { DoctorCabinet } from '../DoctorCabinet'
import { DoctorHistory } from '../DoctorHistory'
import { Loader } from '../UI/Loader'
import { MedicalReportsModal } from '@components/MedicalReportsModal'

export const DoctorPageMain: React.FC = () => {
  const loading = useAppSelector((state) => state.doctor.loading)

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <DoctorContainer title='Личный кабинет' back=''>
            <DoctorCabinet />
          </DoctorContainer>
          <DoctorContainer title='Прошедшие записи' back=''>
            <DoctorHistory />
          </DoctorContainer>
          <MedicalReportsModal />
        </>
      )}
    </>
  )
}
