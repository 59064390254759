import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '../../../../../../helpers/useWindowSizeHook'
import { setIsDrawer, setFilterView, setFilterProps, setOrdering } from '../../../../redux/catalogSlicer'
import Styles from './style.module.scss'
import 'swiper/swiper.scss'

import { FilterItem } from '../../../CatalogFilter/components/FilterItem'

import setupIcon from '@icons/setup.svg'
import removeIcon from '@icons/remove.svg'

import { MOBILE_BREAKPOINT } from '../../../../../../global-constants'
import { OrderingItem } from '../../../CatalogFilter/components/OrderingItem'

export const CatalogNutraceuticalsFilter: React.FC = () => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const productFilter = useAppSelector((state) => state.catalog.productFilter)

  const clearAllButtonVisible =
    productFilter.brand ||
    productFilter.rating ||
    productFilter.category ||
    productFilter.form ||
    productFilter.max_price ||
    productFilter.min_price === 0 ||
    productFilter.min_price

  const openModal = (view) => {
    dispatch(setFilterView(view))
    dispatch(setIsDrawer(true))
  }

  const cleanFilters = () => {
    dispatch(
      setFilterProps({
        brand: '',
        rating: '',
        category: '',
        form: '',
        min_price: '',
        max_price: '',
      }),
    )
  }

  const cleanDefiniteFilter = (e, key) => {
    e.stopPropagation()
    dispatch(
      setFilterProps({
        [key]: '',
      }),
    )
  }

  const handleOrdering = (e) => {
    dispatch(setOrdering(e.target.value))
  }

  return (
    <div className={Styles.filter}>
      {MOBILE_BREAKPOINT < width ? (
        <>
          <div className={Styles.up}>
            <div className={Styles.block}>
              <FilterItem name='Все фильтры' leftImage={setupIcon} onClick={() => openModal('all')} />
              <OrderingItem name='Категория здоровья' value='category' onClick={() => openModal('category')} />
              <OrderingItem name='Бренд' value='brand' onClick={() => openModal('brand')} />
              <OrderingItem
                name='Цена'
                value='price'
                aditionalValue={['min_price', 'max_price']}
                onClick={() => openModal('price')}
              />
              <OrderingItem name='Форма выпуска' value='form' onClick={() => openModal('form')} />
            </div>
            <div className={Styles.block}>
              <select className={Styles.orderingSelect} onChange={handleOrdering}>
                <option value='id'>По популярности</option>
                <option value='brand'>Бренд</option>
                <option value='category'>Категория</option>
                <option value='rating'>Рейтинг</option>
                <option value='min_price'>Мин. цена</option>
                <option value='max_price'>Макс. цена</option>
              </select>
            </div>
          </div>
          <div className={Styles.down}>
            <div className={Styles.block}>
              {clearAllButtonVisible && <FilterItem name='Очистить все' onClick={cleanFilters} isBlue />}
              {productFilter.brand && (
                <FilterItem
                  name={`Бренд: ${productFilter.brand}`}
                  rightImage={removeIcon}
                  cleanFilter={(e) => cleanDefiniteFilter(e, 'brand')}
                />
              )}
              {productFilter.rating && (
                <FilterItem
                  name={`Рейтинг: ${productFilter.rating}`}
                  rightImage={removeIcon}
                  cleanFilter={(e) => cleanDefiniteFilter(e, 'rating')}
                />
              )}
              {productFilter.category && (
                <FilterItem
                  name={`Категория: ${productFilter.category}`}
                  rightImage={removeIcon}
                  cleanFilter={(e) => cleanDefiniteFilter(e, 'category')}
                />
              )}
              {productFilter.form && (
                <FilterItem
                  name={`Форма выпуска: ${productFilter.form}`}
                  rightImage={removeIcon}
                  cleanFilter={(e) => cleanDefiniteFilter(e, 'form')}
                />
              )}
              {(productFilter.min_price || productFilter.min_price === 0) && (
                <FilterItem
                  name={`Цена от: ${productFilter.min_price}`}
                  rightImage={removeIcon}
                  cleanFilter={(e) => cleanDefiniteFilter(e, 'min_price')}
                />
              )}
              {productFilter.max_price && (
                <FilterItem
                  name={`Цена до: ${productFilter.max_price}`}
                  rightImage={removeIcon}
                  cleanFilter={(e) => cleanDefiniteFilter(e, 'max_price')}
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={Styles.container}>
            <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={20}>
              <SwiperSlide className={Styles.slide}>
                <FilterItem name='Все фильтры' leftImage={setupIcon} onClick={() => openModal('all')} />
              </SwiperSlide>
              <SwiperSlide className={Styles.slide}>
                <select className={Styles.orderingSelect} onChange={handleOrdering}>
                  <option value='id'>По популярности</option>
                  <option value='brand'>Бренд</option>
                  <option value='category'>Категория</option>
                  <option value='rating'>Рейтинг</option>
                  <option value='min_price'>Мин. цена</option>
                  <option value='max_price'>Макс. цена</option>
                </select>
              </SwiperSlide>
              <SwiperSlide className={Styles.slide}>
                <OrderingItem name='Категория здоровья' value='category' onClick={() => openModal('category')} />
              </SwiperSlide>
              <SwiperSlide className={Styles.slide}>
                <OrderingItem name='Бренд' value='brand' onClick={() => openModal('brand')} />
              </SwiperSlide>
              <SwiperSlide className={Styles.slide}>
                <OrderingItem
                  name='Цена'
                  value='price'
                  aditionalValue={['min_price', 'max_price']}
                  onClick={() => openModal('price')}
                />
              </SwiperSlide>
              <SwiperSlide className={Styles.slide}>
                <OrderingItem name='Форма выпуска' value='form' onClick={() => openModal('form')} />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={Styles.container}>
            <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={20}>
              {clearAllButtonVisible && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem name='Очистить все' onClick={cleanFilters} isBlue />
                </SwiperSlide>
              )}
              {productFilter.brand && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem
                    name={`Бренд: ${productFilter.brand}`}
                    rightImage={removeIcon}
                    cleanFilter={(e) => cleanDefiniteFilter(e, 'brand')}
                  />
                </SwiperSlide>
              )}
              {(productFilter.min_price || productFilter.min_price === 0) && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem
                    name={`Цена от: ${productFilter.min_price}`}
                    rightImage={removeIcon}
                    cleanFilter={(e) => cleanDefiniteFilter(e, 'min_price')}
                  />
                </SwiperSlide>
              )}
              {productFilter.max_price && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem
                    name={`Цена до: ${productFilter.max_price}`}
                    rightImage={removeIcon}
                    cleanFilter={(e) => cleanDefiniteFilter(e, 'max_price')}
                  />
                </SwiperSlide>
              )}
              {productFilter.rating && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem
                    name={`Рейтинг: ${productFilter.rating}`}
                    rightImage={removeIcon}
                    cleanFilter={(e) => cleanDefiniteFilter(e, 'rating')}
                  />
                </SwiperSlide>
              )}
              {productFilter.form && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem
                    name={`Форма выпуска: ${productFilter.form}`}
                    rightImage={removeIcon}
                    cleanFilter={(e) => cleanDefiniteFilter(e, 'form')}
                  />
                </SwiperSlide>
              )}
              {productFilter.category && (
                <SwiperSlide className={Styles.slide}>
                  <FilterItem
                    name={`Категория: ${productFilter.category}`}
                    rightImage={removeIcon}
                    cleanFilter={(e) => cleanDefiniteFilter(e, 'category')}
                  />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </>
      )}
    </div>
  )
}
