import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '../../../../../helpers/useWindowSizeHook'
import {
  filterChange,
  getListAppointmentsDate,
  getDoctorCalendarToday,
  getDoctorCalendarWeek,
  getDoctorCalendarMonth,
} from '../../../redux/doctorSlicer'
import Styles from './style.module.scss'

import calendarIcon from '@icons/calendar.svg'
import caretIcon from '@icons/caret.svg'

import { formateDate, getCurrentWeek, getMonth, getCurrentMonth } from '../../../../../utils/formateDate'
import { MOBILE_BREAKPOINT } from '../../../constants'

interface FilterProps {
  list: string[]
}

export const Filter: React.FC<FilterProps> = ({ list }) => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const filterIndex = useAppSelector((state) => state.doctor.filterIndex)
  const filter = useAppSelector((state) => state.doctor.filter)
  const [currentDate, setSurrentDate] = useState(formateDate(new Date(), 'YYYY-MM-DD'))
  const [week, setWeek] = useState(null)

  const prevRender = async () => {
    try {
      if (filter[filterIndex] === 'day') {
        const date = formateDate(new Date(currentDate).setDate(new Date(currentDate).getDate() - 1), 'YYYY-MM-DD')
        setSurrentDate(date)

        if (width > MOBILE_BREAKPOINT) {
          await dispatch(getListAppointmentsDate({ day_after: date, day_before: date }))
        } else {
          await dispatch(getDoctorCalendarToday({ start_date: date, end_date: date }))
        }
      }

      if (filter[filterIndex] === 'week') {
        const date = formateDate(new Date(currentDate).setDate(new Date(currentDate).getDate() - 1 * 7), 'YYYY-MM-DD')
        setSurrentDate(date)

        const dataWeek = getCurrentWeek(date)
        await dispatch(getDoctorCalendarWeek({ start_date: dataWeek.firstDayWeek, end_date: dataWeek.lastDayWeek }))

        setWeek(dataWeek)
      }

      if (filter[filterIndex] === 'month') {
        const date = formateDate(new Date(currentDate).setMonth(new Date(currentDate).getMonth() - 1), 'YYYY-MM-DD')
        setSurrentDate(date)

        const dataMonth = getMonth(date)
        await dispatch(
          getDoctorCalendarMonth({ start_date: dataMonth.firstDayMonth, end_date: dataMonth.lastDayMonth }),
        )
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  const nextRender = async () => {
    try {
      if (filter[filterIndex] === 'day') {
        const date = formateDate(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1), 'YYYY-MM-DD')
        setSurrentDate(date)

        if (width > MOBILE_BREAKPOINT) {
          await dispatch(getListAppointmentsDate({ day_after: date, day_before: date }))
        } else {
          await dispatch(getDoctorCalendarToday({ start_date: date, end_date: date }))
        }
      }

      if (filter[filterIndex] === 'week') {
        const date = formateDate(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1 * 7), 'YYYY-MM-DD')
        setSurrentDate(date)

        const dataWeek = getCurrentWeek(date)
        await dispatch(getDoctorCalendarWeek({ start_date: dataWeek.firstDayWeek, end_date: dataWeek.lastDayWeek }))

        setWeek(dataWeek)
      }

      if (filter[filterIndex] === 'month') {
        const date = formateDate(new Date(currentDate).setMonth(new Date(currentDate).getMonth() + 1), 'YYYY-MM-DD')
        setSurrentDate(date)

        const dataMonth = getMonth(date)
        await dispatch(
          getDoctorCalendarMonth({ start_date: dataMonth.firstDayMonth, end_date: dataMonth.lastDayMonth }),
        )
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  const resetRender = async (today) => {
    try {
      if (filter[filterIndex] === 'day') {
        setSurrentDate(today)

        if (width > MOBILE_BREAKPOINT) {
          await dispatch(getListAppointmentsDate({ day_after: today, day_before: today }))
        } else {
          await dispatch(getDoctorCalendarToday({ start_date: today, end_date: today }))
        }
      }

      if (filter[filterIndex] === 'week') {
        setSurrentDate(today)

        const dataWeek = getCurrentWeek(today)
        await dispatch(getDoctorCalendarWeek({ start_date: dataWeek.firstDayWeek, end_date: dataWeek.lastDayWeek }))
      }

      if (filter[filterIndex] === 'month') {
        setSurrentDate(today)

        const dataMonth = getCurrentMonth()
        await dispatch(
          getDoctorCalendarMonth({ start_date: dataMonth.firstDayMonth, end_date: dataMonth.lastDayMonth }),
        )
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  useEffect(() => {
    const today = formateDate(new Date(), 'YYYY-MM-DD')
    resetRender(today)
  }, [filterIndex])

  return (
    <div className={Styles.filter}>
      <ul className={Styles.list}>
        {list.map((item, index) => (
          <li
            key={index}
            className={filterIndex === index ? `${Styles.item} ${Styles.item_active}` : `${Styles.item}`}
            onClick={() => dispatch(filterChange(index))}
          >
            {item}
          </li>
        ))}
      </ul>
      {width > MOBILE_BREAKPOINT ? (
        <div className={Styles.select}>
          <div className={Styles.block}>
            <img src={calendarIcon} alt='Иконка' />
            {filter[filterIndex] === 'day' && (
              <>
                {currentDate === formateDate(new Date(), 'YYYY-MM-DD') ? (
                  <span className={Styles.designation}>Сегодня</span>
                ) : (
                  <span className={Styles.designation}>{formateDate(currentDate, 'DD MMMM')}</span>
                )}
              </>
            )}
            {filter[filterIndex] === 'week' && (
              <>
                {currentDate === formateDate(new Date(), 'YYYY-MM-DD') ? (
                  <span className={Styles.designation}>Текущая неделя</span>
                ) : (
                  <span className={Styles.designation}>
                    {formateDate(week?.firstDayWeek, 'DD.MM')} - {formateDate(week?.lastDayWeek, 'DD.MM')}
                  </span>
                )}
              </>
            )}
            {filter[filterIndex] === 'month' && (
              <>
                {currentDate === formateDate(new Date(), 'YYYY-MM-DD') ? (
                  <span className={Styles.designation}>Текущий месяц</span>
                ) : (
                  <span className={Styles.designation}>{formateDate(currentDate, 'MMMM YYYY')}</span>
                )}
              </>
            )}
          </div>
          <div className={Styles.carets}>
            <div className={`${Styles.caret} ${Styles.caret_rotate}`} onClick={() => prevRender()}>
              <img src={caretIcon} alt='Иконка' />
            </div>
            <div className={Styles.caret} onClick={() => nextRender()}>
              <img src={caretIcon} alt='Иконка' />
            </div>
          </div>
        </div>
      ) : (
        <div className={Styles.select}>
          <div className={`${Styles.caret} ${Styles.caret_rotate}`} onClick={() => prevRender()}>
            <img src={caretIcon} alt='Иконка' />
          </div>
          <div className={Styles.mark}>
            <img src={calendarIcon} alt='Иконка' />
            {filter[filterIndex] === 'day' && (
              <>
                {currentDate === formateDate(new Date(), 'YYYY-MM-DD') ? (
                  <span className={Styles.designation}>Сегодня</span>
                ) : (
                  <span className={Styles.designation}>{formateDate(currentDate, 'DD MMMM')}</span>
                )}
              </>
            )}
            {filter[filterIndex] === 'week' && (
              <>
                {currentDate === formateDate(new Date(), 'YYYY-MM-DD') ? (
                  <span className={Styles.designation}>Текущая неделя</span>
                ) : (
                  <span className={Styles.designation}>
                    {formateDate(week?.firstDayWeek, 'DD.MM')} - {formateDate(week?.lastDayWeek, 'DD.MM')}
                  </span>
                )}
              </>
            )}
            {filter[filterIndex] === 'month' && (
              <>
                {currentDate === formateDate(new Date(), 'YYYY-MM-DD') ? (
                  <span className={Styles.designation}>Текущий месяц</span>
                ) : (
                  <span className={Styles.designation}>{formateDate(currentDate, 'MMMM YYYY')}</span>
                )}
              </>
            )}
          </div>
          <div className={Styles.caret} onClick={() => nextRender()}>
            <img src={caretIcon} alt='Иконка' />
          </div>
        </div>
      )}
    </div>
  )
}
