import React from 'react'
import Styles from './style.module.scss'

import { CancelReception } from './CancelReception'

import { formateDate } from '../../../../../../../../../utils/formateDate'

interface SelectingProps {
  selectedDate: any
  onConfirmCancelHours: any
  handlerChange: any
  selectedTime: any
}

export const Selecting: React.FC<SelectingProps> = (props) => {
  const { selectedDate, onConfirmCancelHours, handlerChange, selectedTime } = props

  return (
    <>
      <div className={Styles.wrapper}>
        <span className={Styles.caption}>{formateDate(selectedDate.day, 'DD MMMM')}</span>
        <div className={Styles.visits}>
          {selectedDate.receptions.map((reception) => (
            <CancelReception key={reception.id} reception={reception} handlerChange={handlerChange} />
          ))}
        </div>
      </div>
      <div className={Styles.block}>
        <div className={Styles.designation}>
          {selectedTime.time.length === 0 ? (
            <div className={Styles.choosed}>
              <span>Выберите часы для отмены приема</span>
            </div>
          ) : (
            <>
              <span className={Styles.denotation}>Выбрано {selectedTime.time.length} записей</span>
              <div className={Styles.choosed}>
                <span>{formateDate(selectedTime.day, 'DD MMMM')}:&nbsp;</span>
                {selectedTime.time.map((time) => (
                  <span key={time.id}>{time.time},&nbsp;</span>
                ))}
              </div>
            </>
          )}
        </div>
        <button className={Styles.confirm} onClick={onConfirmCancelHours} disabled={selectedTime.time.length === 0}>
          Отменить прием
        </button>
      </div>
    </>
  )
}
