import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

import { DoctorVisitMobile } from '../DoctorVisit/components/DoctorVisitMobile'

import { STEP_DISPLAY_APPOINTMENTS } from '../../../../constants'

export const DoctorHistoryMobile: React.FC = () => {
  const listAppointmentsDone = useAppSelector((state) => state.doctor.listAppointmentsDone)
  const [displayedAppountments, setSisplayedAppountments] = useState([])
  const [currentStep, setCurrentStep] = useState(5)
  const [dispalyButton, setDispalyButton] = useState(false)

  const moreAppointments = () => setCurrentStep((prev) => prev + STEP_DISPLAY_APPOINTMENTS)

  useEffect(() => {
    if (listAppointmentsDone.length > STEP_DISPLAY_APPOINTMENTS) {
      const slicedAppointments = listAppointmentsDone.slice(0, currentStep)
      setSisplayedAppountments(slicedAppointments)

      if (listAppointmentsDone.length !== slicedAppointments.length) {
        setDispalyButton(true)
      } else {
        setDispalyButton(false)
      }
    } else {
      setSisplayedAppountments(listAppointmentsDone)
      setDispalyButton(false)
    }
  }, [listAppointmentsDone, currentStep])

  return (
    <>
      {displayedAppountments.length > 0 ? (
        <div className={Styles.history}>
          <div className={Styles.slider}>
            <Swiper slidesPerView={1} pagination={{ clickable: true }}>
              {displayedAppountments.map((appointment) => (
                <SwiperSlide key={appointment.id} className={Styles.slider__slide}>
                  <DoctorVisitMobile appointment={appointment} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {dispalyButton && (
            <button className={Styles.more} onClick={moreAppointments}>
              Смотреть еще 5 записей
            </button>
          )}
        </div>
      ) : (
        <p className={Styles.text}>У пациента пока нет прошедших записей</p>
      )}
    </>
  )
}
