import React from 'react'
import { useAppSelector } from '@app/redux/hooks'

import { DoctorContainer } from '../DoctorContainer'
import { DoctorPatientList } from '../DoctorPatientList'
import { Loader } from '../UI/Loader'
import { MedicalReportsModal } from '@components/MedicalReportsModal'

export const DoctorPagePatients: React.FC = () => {
  const loading = useAppSelector((state) => state.doctor.loading)
  const listUniqPatients = useAppSelector((state) => state.doctor.listUniqPatients)

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <DoctorContainer title='Пациенты' count={listUniqPatients.length} back=''>
            <DoctorPatientList />
          </DoctorContainer>
          <MedicalReportsModal />
        </>
      )}
    </>
  )
}
