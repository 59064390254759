import React, { useState } from 'react'
import Styles from './style.module.scss'
import backArrow from '@icons/chat-back-arrow.svg'
import { useAppSelector } from '@app/redux/hooks'
import { AttachedFile } from '../AttachedFile'
import { ClickableImage } from '../ClickableImage'

export const FilesPage = ({ setCurrentPage }) => {
  const filesFromMessages: any = useAppSelector((state) =>
    state.chat.currentMessages?.map((message: any) => {
      if (message.files.length > 0) {
        return message.files[0]
      }
    }),
  )
  const [activeTab, setActiveTab] = useState('photo')
  const handleBackClick = () => {
    setCurrentPage(2)
  }
  const imageAttachment = filesFromMessages.filter((item) => item?.type === 1)
  const fileAttachment = filesFromMessages.filter((item) => item && item?.type !== 1)

  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <span className={Styles.backArrow} onClick={handleBackClick}>
            <img src={backArrow} alt='back' />
            Назад
          </span>
          <h3>Прикреплённые файлы</h3>
        </div>
        <div className={Styles.body}>
          <div className={Styles.tabbar}>
            <span
              className={activeTab === 'photo' ? `${Styles.tab} ${Styles.tab_active}` : `${Styles.tab}`}
              onClick={() => {
                setActiveTab('photo')
              }}
            >
              Фото
            </span>
            <span
              className={activeTab === 'docs' ? `${Styles.tab} ${Styles.tab_active}` : `${Styles.tab}`}
              onClick={() => {
                setActiveTab('docs')
              }}
            >
              Документы
            </span>
          </div>
          <div className={Styles.tabContent}>
            {activeTab === 'photo' ? (
              <div className={Styles.photos}>
                {imageAttachment.length > 0 ? (
                  imageAttachment?.map((file) => (
                    <div className={Styles.image} key={file.id}>
                      <ClickableImage src={file.file} filename={file.filename} />
                    </div>
                  ))
                ) : (
                  <p className={Styles.noAttachment}>Загруженных фотографий нет</p>
                )}
              </div>
            ) : fileAttachment.length > 0 ? (
              fileAttachment?.map((item) => (
                <div className={Styles.attachment} key={item.id}>
                  <AttachedFile
                    name={item.filename}
                    size={item.size}
                    date={item.created_at}
                    downloadable
                    file={item.file}
                  />
                </div>
              ))
            ) : (
              <p className={Styles.noAttachment}>Загруженных файлов нет</p>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
