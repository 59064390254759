import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'

import { setPhaseFunc, setNotificationFunc } from '../../../../../../../../index'
import { formateDate } from '../../../../../../../../../../utils/formateDate'

interface DoctorPatientVisitMobileProps {
  appointment: any
}

export const DoctorPatientVisitMobile: React.FC<DoctorPatientVisitMobileProps> = ({ appointment }) => {
  const [phase, setPhase] = useState(null)
  const [notification, setNotification] = useState('')
  const [expired, setExpired] = useState(null)

  const classNameNotification = expired
    ? `${Styles.notification} ${Styles.notification_expired}`
    : `${Styles.notification}`

  useEffect(() => {
    setPhaseFunc(appointment.day_reception, setPhase)
    setNotificationFunc(
      appointment.status,
      appointment.medical_report,
      appointment.day_reception,
      setNotification,
      setExpired,
    )
  }, [])

  return (
    <div className={Styles.visit}>
      <div className={Styles.block}>
        <span className={Styles.label}>Консультация</span>
        <span className={Styles.text}>Нутрицевтическая подготовка к беременности</span>
        <Link className={Styles.link} to={`/appointments/${appointment.id}`}>
          {}
        </Link>
      </div>
      <div className={Styles.block}>
        <span className={Styles.label}>Дата и время</span>
        {phase ? (
          <span className={Styles.text}>
            {phase}, {formateDate(appointment.day_reception, 'DD MMMM')}
          </span>
        ) : (
          <span className={Styles.text}>{formateDate(appointment.day_reception, 'DD MMMM')}</span>
        )}
      </div>
      {appointment.status === 'done' && !appointment.medical_report && (
        <div className={Styles.conclusion}>
          <i className={Styles.icon}>{}</i>
          <span className={Styles.warning}>Требуется заключение</span>
        </div>
      )}
      {notification && <span className={classNameNotification}>{notification}</span>}
    </div>
  )
}
