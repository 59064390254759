import React, { useState } from 'react'
import Styles from './style.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import useWindowSize from '@helpers/useWindowSizeHook'
import { DrawerMobile } from '@components/ui/DrawerMobile'
import { API_IMAGE_URL } from '@app/api'
import { Link } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
import {
  deletePlannedDataProdactsToBy,
  setPlannedDataProdactsToBy,
} from '../../../../pages/my-appointment/redux/appointmentByIdSlicer'
import { useAppDispatch } from '@app/redux/hooks'

const StyledCheckbox = styled(Checkbox)(() => ({
  color: '#C9DBFD',
  '&.Mui-checked': {
    color: '#7D9DFF',
  },
}))
export const ProductItemSmall = ({ item }) => {
  const { width } = useWindowSize()
  const desktop = width >= 829
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState(false)
  const [activeVariant, setActiveVariant] = useState(item.variants[0])
  const [activeMod, setActiveMod] = useState(activeVariant.modifications[0])
  const [activeTitleMobile, setActiveTitleMobile] = useState()
  const [quantity, setQuantity] = useState(1)
  const [checked, setChecked] = useState(false)
  const changeCount = (val) => {
    setQuantity(val)
    dispatch(deletePlannedDataProdactsToBy({ id: item.id }))
    dispatch(
      setPlannedDataProdactsToBy({
        id: item.id,
        prodact: activeMod.id,
        quantity: val,
        checked,
        price: activeMod.price * quantity,
        discount_price: activeMod.discount_price * quantity,
      }),
    )
  }

  const checkedProduct = (e) => {
    setChecked(!checked)
    if (e.target.checked) {
      dispatch(deletePlannedDataProdactsToBy({ id: item.id }))
      dispatch(
        setPlannedDataProdactsToBy({
          id: item.id,
          prodact: activeMod.id,
          quantity,
          checked: e.target.checked,
          price: activeMod.price * quantity,
          discount_price: activeMod.discount_price * quantity,
        }),
      )
    } else {
      dispatch(deletePlannedDataProdactsToBy({ id: item.id }))
    }
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.wrap}>
        <div className={Styles.left__container}>
          <Link to={`/catalog/nutraceutical/${item.id}`} className={Styles.link}>
            <img className={Styles.img_main} src={`${activeVariant?.images[0]?.image}`} alt='img' />
          </Link>
          <div className={Styles.counter__container}>
            <button
              onClick={() => changeCount(quantity - 1)}
              className={`${Styles.counter} ${Styles.counter_minus}`}
              disabled={quantity === 1 || !checked}
            />
            <span className={`${Styles.quantity} ${!checked && Styles.text_disable}`}>
              {quantity < 10 ? `0${quantity}` : quantity}
            </span>
            <button
              disabled={!checked}
              onClick={() => changeCount(quantity + 1)}
              className={`${Styles.counter} ${Styles.counter_plus}`}
            />
          </div>
        </div>

        <div className={Styles.description__container}>
          <div className={Styles.block}>
            {activeMod.discount_price !== activeMod.price && (
              <span className={Styles.old}>{activeMod.price * quantity} ₽</span>
            )}
            <span className={`${Styles.price} ${!checked && Styles.text_disable}`}>
              {activeMod.discount_price * quantity} ₽
            </span>
          </div>
          {quantity > 1 && (
            <div className={Styles.result__count}>
              {quantity} шт. &times; {activeMod.discount_price} ₽
            </div>
          )}
          <Link to={`/catalog/nutraceutical/${item.id}`} className={Styles.link}>
            <h3>{item.name}</h3>
          </Link>
          {desktop ? (
            <p className={`${Styles.description} ${!checked && Styles.text_disable}`}>
              {activeVariant?.name}: <span>{activeVariant?.value}</span>
            </p>
          ) : (
            <div className={Styles.button__container}>
              <p className={Styles.description}>{activeVariant.name}:</p>
              <ButtonTransparent
                value={activeVariant.value}
                after
                onClick={() => {
                  setActiveTitleMobile(activeVariant?.name)
                  setShowModal(true)
                }}
                styleImg={Styles.arrow}
                styleButton={Styles.button_mobile}
                disabled={!checked}
              />
            </div>
          )}
          {desktop && (
            <div className={Styles.variants}>
              {item.variants.map((variant, id) => (
                <button
                  disabled={!checked}
                  key={id}
                  className={`${Styles.variant} ${
                    variant.value === activeVariant?.value && checked && Styles.variant_active
                  }`}
                  onClick={() => {
                    setActiveVariant(variant)
                    setActiveMod(variant.modifications[0])
                    dispatch(
                      deletePlannedDataProdactsToBy({
                        id: item.id,
                      }),
                    )
                    dispatch(
                      setPlannedDataProdactsToBy({
                        id: item.id,
                        prodact: variant.modifications[0].id,
                        quantity,
                        checked,
                        price: activeMod.price * quantity,
                        discount_price: activeMod.discount_price * quantity,
                      }),
                    )
                  }}
                >
                  <img className={Styles.image} src={`${variant.images[0].image}`} alt='img' />
                </button>
              ))}
            </div>
          )}
          {desktop ? (
            <p className={`${Styles.description} ${!checked && Styles.text_disable}`}>
              {activeMod.name}: <span>{activeMod.value}</span>
            </p>
          ) : (
            <div className={Styles.button__container}>
              <p className={Styles.description}>{item.mod_name}:</p>
              <ButtonTransparent
                value={activeMod.value}
                after
                onClick={() => {
                  setActiveTitleMobile(activeMod.name)
                  setShowModal(true)
                }}
                styleImg={Styles.arrow}
                styleButton={Styles.button_mobile}
                disabled={!checked}
              />
            </div>
          )}

          {desktop && (
            <div className={Styles.variants}>
              {item.variants.map((variant, id) => (
                <div key={id}>
                  {variant.value === activeVariant.value && (
                    <>
                      {variant.modifications.map((mod, id) => (
                        <button
                          disabled={!checked}
                          key={id}
                          className={`${Styles.variant} ${
                            mod.value === activeMod.value && checked && Styles.variant_active
                          }`}
                          onClick={() => {
                            setActiveMod(mod)
                            dispatch(deletePlannedDataProdactsToBy({ id: item.id }))
                            dispatch(
                              setPlannedDataProdactsToBy({
                                id: item.id,
                                prodact: mod.id,
                                quantity,
                                checked,
                                price: activeMod.price * quantity,
                                discount_price: activeMod.discount_price * quantity,
                              }),
                            )
                          }}
                        >
                          {mod.value}
                        </button>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <StyledCheckbox color='default' name='name' onChange={checkedProduct} checked={checked} />
      </div>
      <DrawerMobile
        width='100%'
        anchor='bottom'
        open={showModal}
        onClose={() => {
          setShowModal((isOpen) => !isOpen)
        }}
      >
        <div className={Styles.selecting}>
          <h2 className={Styles.selecting__title}>{`Выберите ${
            activeTitleMobile === activeVariant?.name ? activeVariant?.name : activeMod.name
          }`}</h2>
          <Swiper slideClass={Styles.slider__item} slidesPerView={3}>
            {item.variants.map((variant, id) => (
              <div key={id}>
                {activeTitleMobile === activeVariant?.name && (
                  <SwiperSlide key={id} style={{ width: 'auto' }}>
                    <button
                      onClick={() => {
                        setActiveVariant(variant)
                        setActiveMod(variant.modifications[0])
                        dispatch(
                          deletePlannedDataProdactsToBy({
                            id: item.id,
                          }),
                        )
                        dispatch(
                          setPlannedDataProdactsToBy({
                            id: item.id,
                            prodact: variant.modifications[0].id,
                            quantity,
                            checked,
                            price: activeMod.price * quantity,
                            discount_price: activeMod.discount_price * quantity,
                          }),
                        )
                      }}
                      className={`${Styles.variant} ${Styles.select__type} ${
                        variant.value === activeVariant?.value && Styles.variant_active
                      }`}
                    >
                      <img className={Styles.image} src={`${API_IMAGE_URL}${variant.image}`} alt='img' />
                      <h6>{variant.value}</h6>
                    </button>
                  </SwiperSlide>
                )}
                {activeTitleMobile === activeMod.name && variant.value === activeVariant.value && (
                  <>
                    {variant.modifications.map((mod, id) => (
                      <button
                        key={id}
                        className={`${Styles.variant} ${mod.value === activeMod.value && Styles.variant_active}`}
                        onClick={() => {
                          setActiveMod(mod)
                          dispatch(deletePlannedDataProdactsToBy({ id: item.id }))
                          dispatch(
                            setPlannedDataProdactsToBy({
                              id: item.id,
                              prodact: mod.id,
                              quantity,
                              checked,
                              price: activeMod.price * quantity,
                              discount_price: activeMod.discount_price * quantity,
                            }),
                          )
                        }}
                      >
                        {mod.value}
                        <div>{mod.discount_price} ₽</div>
                      </button>
                    ))}
                  </>
                )}
              </div>
            ))}
          </Swiper>
        </div>
      </DrawerMobile>
    </div>
  )
}
