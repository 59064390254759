import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import Styles from './style.module.scss'
import logo from '../../assets/icons/antro.svg'
import { useAppSelector } from '@app/redux/hooks'
import { makeAnchorScroll } from '../../utils/makeAnchorScroll'
import whatsapp from '@icons/whatsapp.svg'

export const Footer = (): React.ReactElement => {
  const location = useLocation()
  const loggedIn = useAppSelector((state) => state.auth.user.isLoggedIn)
  return (
    <>
      {location.pathname !== '/login' &&
        location.pathname !== '/profile' &&
        location.pathname !== '/not-found' &&
        location.pathname !== '/access-denied' &&
        location.pathname !== '/onboarding' && (
          <div className={`${Styles.footer} ${loggedIn && Styles.footer_auth}`}>
            <div className={Styles.footer__wrapper}>
              <div>
                {!loggedIn && location.pathname === '/' && (
                  <div className={Styles.footer_navigation}>
                    <div className={Styles.footer_navigation_link} onClick={() => makeAnchorScroll('#consultations')}>
                      Наши консультации
                    </div>
                    <div className={Styles.footer_navigation_link} onClick={() => makeAnchorScroll('#doctor')}>
                      Врач
                    </div>
                    <div className={Styles.footer_navigation_link} onClick={() => makeAnchorScroll('#howItWorks')}>
                      Как это работает?
                    </div>
                    {/* <div className={Styles.footer_navigation_link} onClick={() => makeAnchorScroll('#blog')}>
              Блог
            </div> */}
                  </div>
                )}
                <div className={Styles.footer_policy}>
                  <div className={Styles.footer_policy_links}>
                    <Link to='/confidential' className={Styles.footer_policy_link}>
                      Политика конфиденциальности
                    </Link>
                    <Link to='/personal-data' className={Styles.footer_policy_link}>
                      Пользовательское соглашение
                    </Link>
                  </div>

                  <div className={Styles.footer_policy_links}>
                    <Link to='/contacts' className={Styles.footer_policy_link}>
                      Реквизиты и контакты
                    </Link>
                    <Link to='/pay' className={Styles.footer_policy_link}>
                      Способы оплаты
                    </Link>
                    <Link to='/return' className={Styles.footer_policy_link}>
                      Возврат
                    </Link>
                  </div>
                </div>
              </div>
              <div className={Styles.contacts}>
                <p>С 10 до 18 по дальневосточному времени, МСК+6</p>
                <div>
                  <Link
                    to='#'
                    onClick={(e) => {
                      window.location.href = 'tel:+7 962 285-90-07'
                      e.preventDefault()
                    }}
                  >
                    +7 962 285-90-07
                    <img alt='call' src={whatsapp} />
                  </Link>
                  <Link
                    to='#'
                    onClick={(e) => {
                      window.location.href = 'mailto:support@persolab.ru'
                      e.preventDefault()
                    }}
                  >
                    support@persolab.ru
                  </Link>
                </div>
              </div>
            </div>
            <div className={Styles.footer__wrapper}>
              <p className={Styles.footer_copyright}>© 2020–2022 Persolab</p>
              <a href='https://antro.cx/' target='_blank' className={Styles.footer_logo_container} rel='noreferrer'>
                <img src={logo} alt='Лого' />
                <p>Задизайнен, разработан и поддерживается</p>
              </a>
            </div>
          </div>
        )}
    </>
  )
}
