/* eslint-disable no-useless-escape */
import { ButtonColor } from '@components/ui/ButtonColor'
import { DoctorInformation } from '../TypeOfConsultation/components/DoctorInfo'
import React, { useEffect, useRef, useState } from 'react'
import { Total } from '../Total'
import Styles from './style.module.scss'
import 'react-calendar/dist/Calendar.css'
import { TooltipComponent } from '@components/TooltipComponent'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { createAppointment, setData, setSavingUserInput, resetAppointmentData } from '../../redux/consultationSlicer'
import { DrawerPanel } from '@components/Drawer'
import useWindowSize from '../../../../helpers/useWindowSizeHook'
import { TransitionsModal } from '@components/Modal'
import moment from 'moment'
import { MobileHeader } from '../MobileHeader'
import {
  changeTitle,
  mobileState,
  mobileStatePagination,
  consultationActive,
} from '../../redux/choiсeConsultationSlicer'
import { AuthorizationForm } from '../../../authorization/components/AuthorizationForm'
import { changeUserInfo } from '../../../authorization/redux/authSlice'
import { Link } from 'react-router-dom'
import { getPaymentLink } from '../../../../pages/appointment-page/redux/paymentSlice'
import { useSnackbar } from 'notistack'
import sctollToTop from '@helpers/scrollToTop'
import MaskedInput from 'react-text-mask'
import { Field, Form } from 'react-final-form'
import { Radio } from '@mui/material'
import { CalendarWrapper } from '@components/MedicalReportsModal/FirstStepFieldset/calendarWrapper'
import { Spinner } from '@components/Spinner'

export const PersonalData = () => {
  const { enqueueSnackbar } = useSnackbar()
  const appointmentData = useAppSelector((state) => state.consultationPage.data)
  const user = useAppSelector((state) => state.auth.user.info)
  const pagination = useAppSelector((state) => state.choieConsultationPage.list)
  const statusPay = useAppSelector((state) => state.payment.status)
  const loggedIn = useAppSelector((state) => state.auth.user.isLoggedIn)
  const activeConsultation = useAppSelector((state) => state.consultationPage.data.savingUserInput)
  const idConsultation = useAppSelector((state) => state.consultationsList.selectConsultationById)
  const component = useRef<HTMLDivElement>(null)
  const valid = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/
  const { width } = useWindowSize()
  const desktop = width >= 829
  const topSize = desktop ? 0 : 70
  const dispatch = useAppDispatch()
  const [showCalendar, setShowCalendar] = useState(false)
  const [dayOfBirth, setDayOfBirth] = useState(moment(user.birthday, 'YYYY-MM-DD').format('DD.MM.YYYY') || '')
  const [load, setLoad] = useState(false)
  const [values1, setValues1] = useState({
    birthday: dayOfBirth,
  })
  useEffect(() => {
    sctollToTop(component, topSize)
  }, [])

  const dateFormat = (value) => (value && value.match(valid) ? undefined : 'Формат "ДД.ММ.ГГГГ"')

  const required = (value) => (value ? undefined : 'Это обязательное поле')
  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce((error, validator) => error || validator(value), undefined)
  const initial = { ...user, birthday: moment(user?.birthday).format('DD.MM.YYYY') }
  const onSubmit = async (values) => {
    await createdAppointment(values)
  }
  const createdAppointment = (values) => {
    setLoad(true)
    dispatch(
      changeUserInfo({
        first_name: values.first_name,
        gender: values.gender,
        phone: values.phone,
        birthday: moment(values.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD'),
        height: values.height,
        weight: values.weight,
      }),
    ).then(() => {
      dispatch(
        createAppointment({
          doctor: appointmentData.doctor,
          consultation_type: appointmentData.consultation_type,
          description: activeConsultation[idConsultation].valueOptional,
          is_online: appointmentData.is_online,
          time_reception: appointmentData.selectTime,
          day_reception: appointmentData.day_reception,
        }),
      ).then((response) => {
        if (response.payload.status < 300) {
          dispatch(setData({ key: 'appointment_id', value: response.payload.data.id }))
          dispatch(getPaymentLink({ appointment: response.payload.data.id }))
            .then((res) => {
              if (res.meta.requestStatus === 'fulfilled') {
                dispatch(setSavingUserInput({ key: idConsultation, value: {} }))
                window.location.replace(res.payload.url)
                dispatch(consultationActive(0))
                dispatch(resetAppointmentData())
                setLoad(false)
                setValues1(null)
              } else {
                const message = `Что-то пошло не так.`
                const type = 'error'
                enqueueSnackbar(
                  { message, type },
                  {
                    autoHideDuration: 1500,
                  },
                )
              }
            })

            .catch(() => {
              const message = `Что-то пошло не так. Проверьте соединение с интернетом`
              const type = 'error'
              enqueueSnackbar(
                { message, type },
                {
                  autoHideDuration: 1500,
                },
              )
            })
        }
      })
    })
  }
  return (
    <div ref={component} className={`${Styles.container} ${pagination[3].mobile && Styles.reference__mobile}`}>
      <MobileHeader
        title='Дата и время'
        onClick={() => {
          dispatch(mobileState({ id: 3, value: false }))
          dispatch(mobileStatePagination(false))
          dispatch(changeTitle({ id: 3, value: `${user.first_name}, ${user.phone}` }))
        }}
      />
      <div className={Styles.right}>
        {!loggedIn && (
          <div className={Styles.input__wrap}>
            <AuthorizationForm />
          </div>
        )}
        {loggedIn && (
          <Form onSubmit={onSubmit} initialValues={initial}>
            {({ handleSubmit, form, submitting }) => {
              return (
                <form onSubmit={handleSubmit} id='my-form'>
                  <div className={Styles.content}>
                    <h2 className={Styles.form__title}>Заполните личные данные</h2>
                    <label className={Styles.label}>Телефон&#42;</label>
                    <input className={Styles.input_save} value={user.phone} readOnly />

                    <Field name='first_name' validate={required}>
                      {({ input, meta }) => (
                        <div className={Styles.label__container}>
                          <label className={Styles.label}>Имя&#42;</label>
                          <input type='text' {...input} className={Styles.form__input} />
                          {meta.touched && meta.error && <span className={Styles.error__handler}>{meta.error}</span>}
                        </div>
                      )}
                    </Field>

                    <div className={Styles.wrapper}>
                      <Field name='birthday' validate={composeValidators(required, dateFormat)}>
                        {({ input, meta }) => {
                          return (
                            <div className={Styles.label__container}>
                              <label className={Styles.label}>
                                Дата рождения&#42;
                                <TooltipComponent
                                  text='Врач будет использовать ваш возраст для составления медзаключения'
                                  marginTop
                                />
                              </label>
                              <MaskedInput
                                mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                onClick={() => setShowCalendar(true)}
                                className={Styles.custom__input}
                                placeholder='___.___.______'
                                {...input}
                              />
                              {desktop ? (
                                <TransitionsModal
                                  isOpenModal={showCalendar}
                                  setModalOpened={() => setShowCalendar((isOpen) => !isOpen)}
                                  className={Styles.modal__container}
                                  opacity='0'
                                  style={{ position: 'absolute', top: '0', left: '0', inset: 'auto' }}
                                  disablePortal
                                >
                                  <CalendarWrapper
                                    dayOfBirth={dayOfBirth}
                                    birth={setDayOfBirth}
                                    changeShowCalendar={setShowCalendar}
                                    setValues={setValues1}
                                    values={values1}
                                    label='birthday'
                                  />
                                </TransitionsModal>
                              ) : (
                                <DrawerPanel
                                  closeIcon
                                  width='100%'
                                  anchor='bottom'
                                  open={showCalendar}
                                  onClose={() => setShowCalendar((isOpen) => !isOpen)}
                                >
                                  <CalendarWrapper
                                    dayOfBirth={dayOfBirth}
                                    birth={setDayOfBirth}
                                    changeShowCalendar={setShowCalendar}
                                    setValues={setValues1}
                                    values={values1}
                                    label='birthday'
                                  />
                                </DrawerPanel>
                              )}
                              {meta.touched && meta.error && (
                                <span className={Styles.error__handler}>{meta.error}</span>
                              )}
                            </div>
                          )
                        }}
                      </Field>
                      <label className={Styles.label}>Пол&#42;</label>
                      <div className={Styles.wrap}>
                        <Field name='gender' type='radio' value='male' validate={required}>
                          {({ input }) => (
                            <div className={Styles.radio__container}>
                              <Radio
                                {...input}
                                checked={input.checked}
                                onChange={input.onChange}
                                value='male'
                                name='gender'
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 30,
                                  },
                                  color: '#c9dbfd',
                                  '&.Mui-checked': {
                                    color: '#7d9dff',
                                  },
                                }}
                              />
                              <label htmlFor='male'>Мужской</label>
                            </div>
                          )}
                        </Field>
                        <Field name='gender' type='radio' value='female' validate={required}>
                          {({ input }) => (
                            <div className={Styles.radio__container}>
                              <Radio
                                {...input}
                                checked={input.checked}
                                onChange={input.onChange}
                                value='female'
                                name='gender'
                                sx={{
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 30,
                                  },
                                  color: '#c9dbfd',
                                  '&.Mui-checked': {
                                    color: '#7d9dff',
                                  },
                                }}
                              />
                              <label htmlFor='male'>Женский</label>
                            </div>
                          )}
                        </Field>
                      </div>
                      <Field name='height'>
                        {({ input }) => (
                          <div className={Styles.label__container}>
                            <label className={Styles.label}>Рост</label>
                            <div className={Styles.input__option_wrap}>
                              <input className={Styles.input__option} type='text' {...input} />
                              <span>см</span>
                            </div>
                          </div>
                        )}
                      </Field>
                      <Field name='weight'>
                        {({ input }) => (
                          <div className={Styles.label__container}>
                            <label className={Styles.label}>Вес</label>
                            <div className={Styles.input__option_wrap}>
                              <input className={Styles.input__option} type='text' {...input} />
                              <span>кг</span>
                            </div>
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  {!desktop && (
                    <div className={Styles.sidebar_mobile}>
                      <Total consultation date isBorder place />
                    </div>
                  )}

                  <div className={Styles.buttons__container}>
                    {load && (
                      <div className={Styles.spinner}>
                        <Spinner />
                      </div>
                    )}
                    <ButtonColor
                      disabled={submitting || form.getState().invalid || load}
                      className={Styles.button_width}
                      value='Перейти к оплате'
                      type='submit'
                    />
                    {statusPay === 'error' && (
                      <span className={Styles.error__handler}>Возникла ошибка, попробуйте перезагрузить страницу</span>
                    )}
                    <p className={Styles.text}>
                      Нажимая на кнопку, я соглашаюсь{` `}
                      <span>
                        {submitting || load ? (
                          <span className={Styles.link}> с пользовательским соглашением</span>
                        ) : (
                          <Link to='/personal-data' className={Styles.link}>
                            с пользовательским соглашением
                          </Link>
                        )}
                      </span>
                    </p>
                  </div>
                </form>
              )
            }}
          </Form>
        )}

        {!desktop && (
          <div className={Styles.sidebar_mobile}>
            {appointmentData.doctorData && (
              <DoctorInformation
                name={`${appointmentData.doctorData.first_name} ${appointmentData.doctorData.last_name}`}
                prof={appointmentData.doctorData.specialty}
                expir={appointmentData.doctorData.years_experience}
                count={appointmentData.doctorData.number_conducted_consultations}
                avatar={appointmentData?.doctorData?.avatar || ''}
              />
            )}
          </div>
        )}
      </div>
      {desktop && (
        <div>
          <Total consultation date isBorder place />
          {appointmentData.doctorData && (
            <DoctorInformation
              name={`${appointmentData.doctorData.first_name} ${appointmentData.doctorData.last_name}`}
              prof={appointmentData.doctorData.specialty}
              expir={appointmentData.doctorData.years_experience}
              count={appointmentData.doctorData.number_conducted_consultations}
              avatar={appointmentData?.doctorData?.avatar || ''}
            />
          )}
        </div>
      )}
    </div>
  )
}
