import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { DoctorNutrition } from './components/DoctorNutrition'
import { DoctorLife } from './components/DoctorLife'
import { DoctorNutraceuticals } from './components/DoctorNutraceuticals'
import { DoctorSurveys } from './components/DoctorSurveys'

import createIcon from '@icons/create.svg'

import { createConclusion } from '../../../../index'
import { formateDate } from '../../../../../../utils/formateDate'
import { GetPdfButton } from '@components/GetPdfButton'

export const DoctorConsequence: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentAppointment = useAppSelector((state) => state.doctor.currentAppointment)
  const [arrayReports] = useState([
    { title: 'Питание' },
    { title: 'Образ жизни' },
    { title: 'Нутрицевтики' },
    { title: 'Обследования' },
  ])
  const [activeReport, setActiveReport] = useState(arrayReports[0])

  return (
    <div className={Styles.consequence}>
      <div className={Styles.header}>
        <div className={Styles.wrap}>
          <span className={Styles.conclusion}>Заключение&nbsp;</span>
          {currentAppointment.medical_report.is_report_done ? (
            <span className={Styles.date}>
              от {formateDate(currentAppointment.medical_report.done_date, 'DD MMMM, YYYY')}
            </span>
          ) : (
            <span className={Styles.date}>незавершено</span>
          )}
        </div>
        {currentAppointment.medical_report.is_report_done && (
          <GetPdfButton medicalReportId={currentAppointment.medical_report.id} />
        )}
        <button className={Styles.button} onClick={() => createConclusion(currentAppointment.id, dispatch)}>
          <img src={createIcon} alt='Иконка' />
          <span className={Styles.edit}>Редактировать</span>
        </button>
      </div>
      {currentAppointment.medical_report.is_report_done && (
        <>
          <ul className={Styles.list}>
            {arrayReports.map((report) => (
              <li
                key={report.title}
                className={
                  activeReport.title === report.title ? `${Styles.item} ${Styles.item_active}` : `${Styles.item}`
                }
                onClick={() => setActiveReport(report)}
              >
                {report.title}
              </li>
            ))}
          </ul>
          {activeReport.title === 'Питание' && <DoctorNutrition />}
          {activeReport.title === 'Образ жизни' && <DoctorLife />}
          {activeReport.title === 'Нутрицевтики' && <DoctorNutraceuticals />}
          {activeReport.title === 'Обследования' && <DoctorSurveys />}
        </>
      )}
    </div>
  )
}
