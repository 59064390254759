import { ButtonColor } from '@components/ui/ButtonColor'
import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import { ButtonWithBorder } from '@components/ui/ButtonWithBorder'
import { DeliveryFragment } from '../../../../../ui/DeliveryFragment'
import useWindowSize from '@helpers/useWindowSizeHook'
import React, { useEffect, useState } from 'react'
import { MapFragment } from '../../../../../ui/MapFragment'
import { MapModal } from '../Map'
import Styles from './style.module.scss'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setDataDelivery } from '../../redux/deliverySlice'
import { MOBILE_BREAKPOINT } from '../../../../../../../global-constants'

interface PointDeliveryProps {
  goToPay: () => void
  analysis: number
  goToResearch: () => void
  savedDataDelivery?: () => void
}

export const PointDelivery: React.FC<PointDeliveryProps> = ({ goToPay, analysis, goToResearch, savedDataDelivery }) => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const { dataDelivery } = useAppSelector((state) => state.delivery)
  const [isOpenModal, setModalOpened] = useState(false)
  const [filteredPoints, setFilteredPoints] = useState([])
  const [activeFilters] = useState([])

  const choosePointHandler = (point) => {
    dispatch(
      setDataDelivery({
        ...dataDelivery,
        pointData: { ...dataDelivery.pointData, code: point.code, location: point.location },
      }),
    )
    setModalOpened(false)
  }

  const filters = [
    // указываем название фильтра, триггер(по сути название фильтра на английском, которая будет добавляться в массив с фильтрами) и фильтрующую функцию
    {
      name: 'Постамат',
      trigger: 'postamat',
      filterFunction() {
        return dataDelivery.points.filter((point) => point.type.toLowerCase() === this.trigger)
      },
    },
  ]

  // const setFiltersHandler = (trigger, prev) => {
  //   return prev.includes(trigger) ? [...prev.filter((item) => item !== trigger)] : [...prev, trigger]
  // }

  const openModal = (event) => {
    event.stopPropagation()
    setModalOpened(true)
  }

  useEffect(() => {
    let result = []

    if (activeFilters.length > 0) {
      filters.forEach((filter) => {
        if (activeFilters.includes(filter.trigger)) {
          result = filter.filterFunction()
        }
      })
    } else {
      result = dataDelivery.points
    }

    if (width > MOBILE_BREAKPOINT) {
      setFilteredPoints(result)
    } else {
      setFilteredPoints(result.slice(0, 3))
    }
  }, [activeFilters, dataDelivery.points, width])

  return (
    <>
      {dataDelivery.points.length !== 0 ? (
        <>
          <MapFragment filter1={23} filter2={45} />
          <div className={Styles.filter}>
            {/* filters.map((filter, idx) => {
              return (
                <React.Fragment key={idx}>
                  <input
                    id={idx.toString()}
                    type='checkbox'
                    onChange={(e) => setActiveFilters((prev) => setFiltersHandler(filter.trigger, prev))}
                  />
                  <label htmlFor={idx.toString()}>
                    {filter.name} <span>{filter.filterFunction().length}</span>
                  </label>
                </React.Fragment>
              )
            }) */}
          </div>
          {/* <h4 className={Styles.point__title_mobile}>Адрес самовывоза</h4> */}
          <div className={Styles.point__container}>
            {filteredPoints.length > 0 ? (
              <>
                <header>
                  <span className={Styles.headerItem}>Адрес</span>
                  <span className={Styles.headerItem}>Режим работы</span>
                  <span className={Styles.headerItem}>Срок доставки</span>
                  <span className={Styles.headerItem}>Стоимость</span>
                </header>
                <div className={Styles.point__list}>
                  {filteredPoints.map((point) => {
                    return (
                      <div key={point.code} className={Styles.point} onClick={() => choosePointHandler(point)}>
                        <input
                          name='delivery-fragment'
                          id={point.code}
                          type='radio'
                          onChange={() => choosePointHandler(point)}
                          checked={dataDelivery.pointData.code === point.code}
                        />
                        <label htmlFor={point.code} />
                        <div className={Styles.point__wrapper}>
                          <DeliveryFragment
                            address={point.location.address}
                            workTime={point.work_time}
                            periodCalc={dataDelivery.period}
                          />
                          <ButtonTransparent
                            value='Смотреть на карте'
                            onClick={openModal}
                            styleButton={Styles.point__button}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </>
            ) : null}
            <div className={Styles.buttons__container}>
              <ButtonWithBorder
                value={`Смотреть все (${dataDelivery.points.length})`}
                className={Styles.button}
                onClick={openModal}
              />
              <ButtonColor
                className={Styles.button}
                onClick={() => {
                  savedDataDelivery()
                  analysis > 0 ? goToResearch() : goToPay()
                }}
                value={analysis > 0 ? 'Перейти к выбору лаборатории' : 'Перейти к оплате'}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <h4 className={Styles.title}>Доступные пункты выдачи отсутствуют</h4>
        </>
      )}
      <MapModal
        isOpenModal={isOpenModal}
        setModalOpened={setModalOpened}
        choosePointHandler={choosePointHandler}
        periodCalc={dataDelivery.period}
      />
    </>
  )
}
