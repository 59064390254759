import React, { useEffect, useState, MouseEvent } from 'react'
import Styles from './style.module.scss'
import classNames from 'classnames/bind'
import { useAppDispatch } from '@app/redux/hooks'
import { setIsValidInput } from '../../pages/authorization/redux/authSlice'

interface InputProps {
  isInputDisabled?: boolean
  setIsInputDisabled?: (boolean) => void
  labelName?: string
  inputType?: string
  inputName?: string
  placeholder?: string
  width?: string
  inputValue?: any
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  labelChildren?: any
  Inputchildren?: any
  errorStyle?: any
  onChange?: (e: any) => void
}

export const InputCustom: React.FC<InputProps> = ({
  isInputDisabled,
  setIsInputDisabled,
  labelName,
  inputType,
  inputName,
  placeholder,
  width,
  inputValue,
  onClick,
  labelChildren,
  Inputchildren,
  onChange,
  errorStyle,
}) => {
  const cx = classNames.bind(Styles)
  const [isActiveError, setIsActiveError] = useState(null)
  const [isValid, setIsValid] = useState(false)
  const [value, setValue] = useState(null)
  const [errorText, setErrorText] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [name, setName] = useState(inputName)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (inputValue === null) {
      setValue(null)
    }
    setValue(inputValue)
  }, [inputValue])
  const validation = (e) => {
    if (e.target.name === 'email') {
      if (e.target.value.match(/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/)) {
        setIsActiveError(false)
        setIsValid(true)
        dispatch(setIsValidInput({ key: 'email', value: true }))
      } else {
        setIsActiveError(true)
        setErrorText('Неверный email')
        dispatch(setIsValidInput({ key: 'email', value: false }))
      }
    }
    if (e.target.name === 'first_name') {
      if (e.target.value.length > 2 && e.target.value.match(/^[А-Яа-яA-Za-z\- ]+$/)) {
        setIsActiveError(false)
        setIsValid(true)
        dispatch(setIsValidInput({ key: 'first_name', value: true }))
      } else {
        setIsActiveError(true)
        setErrorText('Введите имя')
        dispatch(setIsValidInput({ key: 'first_name', value: false }))
      }
    }

    if (e.target.name === 'phone') {
      // eslint-disable-next-line no-useless-escape
      if (e.target.value.match(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/)) {
        setIsActiveError(false)
        setIsValid(true)
        dispatch(setIsValidInput({ key: 'phone', value: true }))
      } else {
        setIsActiveError(true)
        setErrorText('Введите телефон')
        dispatch(setIsValidInput({ key: 'phone', value: false }))
      }
    }
    if (e.target.name === 'street' || e.target.name === 'building' || e.target.name === 'postal') {
      if (e.target.value) {
        setIsActiveError(false)
        setIsValid(true)
      } else {
        setIsActiveError(true)
        setErrorText('Это обязательное поле')
      }
    }
  }
  const resetForm = () => {
    setIsActiveError(false)
    setIsValid(false)
  }
  const inputStyle = cx(Styles.input, {
    [`${Styles.input_error}`]: isActiveError,
    [`${Styles.input_success}`]: isValid,
    [`${Styles.input__calendar}`]: name === 'birthday',
    [`${Styles.input_disabled}`]: isInputDisabled,
  })
  return (
    <div className={Styles.container}>
      <div className={Styles.label_container}>
        <label className={Styles.label}>{labelName}</label>
        {labelChildren}
      </div>
      <div>
        <div className={Styles.input_container}>
          <input
            disabled={isInputDisabled}
            style={{ width }}
            name={name}
            value={value || ''}
            className={inputStyle}
            placeholder={placeholder}
            type={inputType}
            onChange={onChange}
            onBlur={(e) => validation(e)}
            onFocus={resetForm}
            autoComplete='off'
            onClick={onClick}
          />
          {isInputDisabled && (
            <div
              className={Styles.button}
              onClick={() => {
                setIsInputDisabled(false)
              }}
            />
          )}
        </div>
        {Inputchildren}
      </div>
      {isActiveError && <span className={`${Styles.error__handler} ${errorStyle}`}>{errorText}</span>}
    </div>
  )
}
