import { InputCustom } from '@components/InputCustom'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setDataDelivery } from '../../redux/deliverySlice'
import React from 'react'
import Styles from './style.module.scss'

interface FormDataProps {
  post?: boolean
  courier?: boolean
}

export const FormDataCart: React.FC<FormDataProps> = (props) => {
  const dispatch = useAppDispatch()
  const { dataDelivery } = useAppSelector((state) => state.delivery)

  const handleChange = (event) => {
    const { name, value } = event.target
    dispatch(setDataDelivery({ ...dataDelivery, courierData: { ...dataDelivery.courierData, [name]: value } }))
  }

  return (
    <>
      <h4 className={Styles.title}>Укажите адрес получателя</h4>
      <p className={Styles.mark}>&#42; — обязательное поле</p>
      <div className={Styles.input__container}>
        <div className={Styles.wrapper}>
          <InputCustom
            placeholder='Введите улицу'
            labelName='Улица&#42;'
            inputType='text'
            inputName='street'
            width='372px'
            inputValue={dataDelivery.courierData.street}
            onChange={handleChange}
            errorStyle={Styles.error}
          />
          <div className={Styles.wrapper_row}>
            <InputCustom
              labelName='№ дома*'
              inputType='text'
              inputName='building'
              width='126px'
              inputValue={dataDelivery.courierData.building}
              onChange={handleChange}
              errorStyle={Styles.error}
            />
            <InputCustom
              labelName='Стр./корп.'
              inputType='text'
              inputName='korpus'
              width='126px'
              inputValue={dataDelivery.courierData.korpus}
              onChange={handleChange}
              errorStyle={Styles.error}
            />
          </div>
        </div>
        <div className={Styles.wrapper_row}>
          <InputCustom
            labelName='Квартира'
            inputType='text'
            inputName='appartment'
            width='100px'
            inputValue={dataDelivery.courierData.appartment}
            onChange={handleChange}
            errorStyle={Styles.error}
          />
          {props.post && (
            <InputCustom
              labelName='Индекс&#42;'
              inputType='text'
              inputName='postal'
              width='100px'
              inputValue={dataDelivery.courierData.postal}
              onChange={handleChange}
              errorStyle={Styles.error}
            />
          )}
          {props.courier && (
            <>
              <InputCustom
                labelName='Подъезд'
                inputType='text'
                inputName='door'
                width='100px'
                inputValue={dataDelivery.courierData.door}
                onChange={handleChange}
                errorStyle={Styles.error}
              />
              <InputCustom
                labelName='Этаж'
                inputType='text'
                inputName='floor'
                width='100px'
                inputValue={dataDelivery.courierData.floor}
                onChange={handleChange}
                errorStyle={Styles.error}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}
