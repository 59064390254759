/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import moment from 'moment'
import PhoneInput from 'react-phone-number-input/input'
import MaskedInput from 'react-text-mask'
import { DrawerPanel } from '@components/Drawer'
import { TransitionsModal } from '@components/Modal'
import { Form, Field } from 'react-final-form'
import { ButtonColor } from '@components/ui/ButtonColor'
import { getMedicalReportId, patchMedicalReport, postMedicalReport } from '../redux/reportsSlice'
import { Radio } from '@mui/material'
import { CalendarWrapper } from '@components/MedicalReportsModal/FirstStepFieldset/calendarWrapper'

export const FirstStepFieldSet = (props) => {
  const dispatch = useAppDispatch()
  const appointmentId = useAppSelector((state) => state.reports.appointmentId)
  const medicalReportId = useAppSelector((state) => state.reports.medicalReportId)
  const { width } = useWindowSize()
  const desktop = width >= 829
  const [currentPatient, setCurrentPatient] = useState(null)
  const [showCalendar, setShowCalendar] = useState(false)
  const [dayOfBirth, setDayOfBirth] = useState('')
  useEffect(() => {
    dispatch(getMedicalReportId(appointmentId)).then((res) => {
      setCurrentPatient(res.payload.data.patient)
      setDayOfBirth(moment(res?.payload?.data?.patient?.birthday).format('DD.MM.YYYY') || '')
    })
  }, [])

  const valid = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [values, setValues] = useState({
    birthday: dayOfBirth,
  })
  const onSubmit = async (values) => {
    await createdReport(values)
  }
  const createdReport = (val) => {
    const formatDate = moment(val.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD')
    if (medicalReportId) {
      dispatch(
        patchMedicalReport({
          id: medicalReportId,
          arr: {
            appointment: appointmentId,
            complaints_about: val.complaints_about,
            goal_of_request: val.goal_of_request,
            from_survey: val.from_survey,
            is_report_done: initial.is_report_done,
            results: val.results,
            first_name: val.first_name,
            last_name: val.last_name,
            gender: val.gender,
            phone: val.phone,
            birthday: formatDate,
            height: val.height,
            weight: val.weight,
            dynamometry: val.dynamometry,
            saturation: val.saturation,
            pulse: val.pulse,
          },
        }),
      ).then(() => {
        props.setStatusReport('results-research')
      })
    } else {
      dispatch(
        postMedicalReport({
          appointment: appointmentId,
          complaints_about: val.complaints_about,
          goal_of_request: val.goal_of_request,
          from_survey: val.from_survey,
          is_report_done: false,
          results: val.results,
          first_name: val.first_name,
          last_name: val.last_name,
          gender: val.gender,
          phone: val.phone,
          birthday: formatDate,
          height: val.height,
          weight: val.weight,
          dynamometry: val.dynamometry,
          saturation: val.saturation,
          pulse: val.pulse,
        }),
      ).then(() => {
        props.setStatusReport('results-research')
      })
    }
  }
  const dateFormat = (value) => (value && value.match(valid) ? undefined : 'Формат "ДД.ММ.ГГГГ"')

  const required = (value) => (value ? undefined : 'Это обязательное поле')
  const regExpPhone = (value) =>
    value.match(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/) ? undefined : 'Неверный формат'
  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce((error, validator) => error || validator(value), undefined)
  const PhoneField = ({ input, meta }) => {
    return (
      <div className={Styles.label__container}>
        <label className={Styles.label}>Телефон&#42;</label>
        <PhoneInput
          className={Styles.form__input}
          placeholder='+7'
          value={input.value.value}
          onChange={(value) => input.onChange(value)}
          minLength={2}
          maxLength={17}
          defaultCountry='RU'
          withCountryCallingCode
          {...input}
        />
        <div>{meta.error && <span className={Styles.error__handler}>{meta.error}</span>}</div>
      </div>
    )
  }
  const [initial, setInitial] = useState(null)

  useEffect(() => {
    props.initialState
      ? setInitial({
          ...props.initialState,
          birthday: moment(props?.initialState?.birthday).format('DD.MM.YYYY'),
        })
      : setInitial({ ...currentPatient, birthday: moment(currentPatient?.birthday).format('DD.MM.YYYY') })
  }, [props.initialState, currentPatient])

  return (
    <div className={Styles.container}>
      <Form onSubmit={onSubmit} initialValues={initial}>
        {({ handleSubmit, values, form }) => {
          return (
            <form onSubmit={handleSubmit} id='my-form'>
              <Field name='phone' component={PhoneField} validate={composeValidators(required, regExpPhone)} />
              <div className={Styles.wrapper}>
                <Field name='first_name' validate={required}>
                  {({ input, meta }) => (
                    <div className={Styles.label__container}>
                      <label className={Styles.label}>Имя&#42;</label>
                      <input type='text' {...input} className={Styles.form__input} />
                      {meta.error && <span className={Styles.error__handler}>{meta.error}</span>}
                    </div>
                  )}
                </Field>
                <Field name='last_name' validate={required}>
                  {({ input, meta }) => (
                    <div className={Styles.label__container}>
                      <label className={Styles.label}>Фамилия&#42;</label>
                      <input type='text' {...input} className={Styles.form__input} />
                      {meta.error && <span className={Styles.error__handler}>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
              <div className={Styles.wrapper}>
                <Field name='birthday' validate={composeValidators(required, dateFormat)}>
                  {({ input, meta }) => {
                    return (
                      <div className={Styles.label__container}>
                        <label className={Styles.label}>Дата рождения&#42;</label>
                        <MaskedInput
                          mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                          onClick={() => setShowCalendar(true)}
                          className={Styles.custom__input}
                          placeholder='___.___.______'
                          {...input}
                        />
                        {desktop ? (
                          <TransitionsModal
                            isOpenModal={showCalendar}
                            setModalOpened={() => setShowCalendar((isOpen) => !isOpen)}
                            className={Styles.modal__container}
                            opacity='0'
                            style={{ position: 'absolute', top: '0', left: '0', inset: 'auto' }}
                            disablePortal
                          >
                            <CalendarWrapper
                              dayOfBirth={dayOfBirth}
                              birth={setDayOfBirth}
                              changeShowCalendar={setShowCalendar}
                              setValues={setValues}
                              values={values}
                              label='birthday'
                            />
                          </TransitionsModal>
                        ) : (
                          <DrawerPanel
                            closeIcon
                            width='100%'
                            anchor='bottom'
                            open={showCalendar}
                            onClose={() => setShowCalendar((isOpen) => !isOpen)}
                          >
                            <CalendarWrapper
                              dayOfBirth={dayOfBirth}
                              birth={setDayOfBirth}
                              changeShowCalendar={setShowCalendar}
                              setValues={setValues}
                              values={values}
                              label='birthday'
                            />
                          </DrawerPanel>
                        )}
                        {meta.error && <span className={Styles.error__handler}>{meta.error}</span>}
                      </div>
                    )
                  }}
                </Field>
                <Field name='height'>
                  {({ input }) => (
                    <div className={Styles.label__container}>
                      <label className={Styles.label}>Рост</label>
                      <div className={Styles.input__option_wrap}>
                        <input className={Styles.input__option} type='text' {...input} />
                        <span>см</span>
                      </div>
                    </div>
                  )}
                </Field>
                <Field name='weight'>
                  {({ input }) => (
                    <div className={Styles.label__container}>
                      <label className={Styles.label}>Вес</label>
                      <div className={Styles.input__option_wrap}>
                        <input className={Styles.input__option} type='text' {...input} />
                        <span>кг</span>
                      </div>
                    </div>
                  )}
                </Field>
              </div>
              <div className={Styles.wrapper}>
                <Field name='gender' type='radio' value='male'>
                  {({ input }) => (
                    <div className={Styles.radio__container}>
                      <Radio
                        {...input}
                        checked={input.checked}
                        onChange={input.onChange}
                        value='male'
                        name='gender'
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 30,
                          },
                          color: '#c9dbfd',
                          '&.Mui-checked': {
                            color: '#7d9dff',
                          },
                        }}
                      />
                      <label htmlFor='male'>Мужской</label>
                    </div>
                  )}
                </Field>
                <Field name='gender' type='radio' value='female'>
                  {({ input }) => (
                    <div className={Styles.radio__container}>
                      <Radio
                        {...input}
                        checked={input.checked}
                        onChange={input.onChange}
                        value='female'
                        name='gender'
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 30,
                          },
                          color: '#c9dbfd',
                          '&.Mui-checked': {
                            color: '#7d9dff',
                          },
                        }}
                      />
                      <label htmlFor='male'>Женский</label>
                    </div>
                  )}
                </Field>
              </div>
              <div className={`${Styles.wrapper} ${Styles.mr}`}>
                <Field name='dynamometry'>
                  {({ input }) => (
                    <div className={Styles.label__container}>
                      <label className={Styles.label}>Динамометрия</label>
                      <div className={Styles.input__option_wrap}>
                        <input type='text' {...input} className={Styles.input__option} />
                        <span>кг</span>
                      </div>
                    </div>
                  )}
                </Field>
                <Field name='saturation'>
                  {({ input }) => (
                    <div className={Styles.label__container}>
                      <label className={Styles.label}>Сатурация</label>
                      <div className={Styles.input__option_wrap}>
                        <input type='text' {...input} className={Styles.input__option} />
                        <span>%</span>
                      </div>
                    </div>
                  )}
                </Field>
                <Field name='pulse'>
                  {({ input }) => (
                    <div className={Styles.label__container}>
                      <label className={Styles.label}>Пульс</label>
                      <div className={Styles.input__option_wrap}>
                        <input type='text' {...input} className={Styles.input__option} />
                        <span>уд./мин.</span>
                      </div>
                    </div>
                  )}
                </Field>
              </div>

              <Field name='results' validate={required}>
                {({ input, meta }) => (
                  <div className={Styles.optional__container}>
                    <label className={Styles.label}>Результаты осмотра&#42;</label>
                    <textarea
                      type='text'
                      {...input}
                      placeholder='Введите ваши заметки по осмотру'
                      className={Styles.optional__input}
                    />
                    {meta.error && <span className={Styles.error__handler}>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name='complaints_about' validate={required}>
                {({ input, meta }) => (
                  <div className={Styles.optional__container}>
                    <label className={Styles.label}>Обратился (-ась) с жалобами на&#42;</label>
                    <textarea type='text' {...input} className={Styles.optional__input} />
                    {meta.error && <span className={Styles.error__handler}>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name='goal_of_request' validate={required}>
                {({ input, meta }) => (
                  <div className={Styles.optional__container}>
                    <label className={Styles.label}>Цель обращения&#42;</label>
                    <textarea type='text' {...input} className={Styles.optional__input} />
                    {meta.error && <span className={Styles.error__handler}>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name='from_survey' validate={required}>
                {({ input, meta }) => (
                  <div className={Styles.optional__container}>
                    <label className={Styles.label}>Из опроса&#42;</label>
                    <textarea type='text' {...input} className={Styles.optional__input} />
                    {meta.error && <span className={Styles.error__handler}>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <div className={Styles.button__container}>
                <ButtonColor
                  disabled={form.getState().invalid}
                  className={Styles.button_width}
                  value='Перейти к медзаключению'
                  type='submit'
                />
              </div>
            </form>
          )
        }}
      </Form>
    </div>
  )
}
