import React, { FC } from 'react'
import styles from './style.module.scss'

interface OrderUserDataProps {
  phone: string
  name: string
  email: string
}

export const OrderUserData: FC<OrderUserDataProps> = ({ phone, name, email }) => {
  return (
    <div className={styles.orderUserData}>
      <h4 className={styles.title}>Данные получателя</h4>
      <div className={styles.dataBlock}>
        <p className={styles.label}>Телефон</p>
        <p className={styles.value}>{phone}</p>

        <p className={styles.label}>Имя</p>
        <p className={styles.value}>{name}</p>

        <p className={styles.label}>E-mail</p>
        <p className={styles.value}>{email}</p>
      </div>
    </div>
  )
}
