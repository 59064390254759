import React from 'react'
import howIcon from '@icons/how.svg'
import Styles from './style.module.scss'

export const CatalogHow: React.FC = () => {
  return (
    <div className={Styles.how}>
      <div className={Styles.block}>
        <div className={Styles.image}>
          <img src={howIcon} alt='Иконка' />
        </div>
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>Как это работает?</span>
        <p className={Styles.text}>
          После оплаты вы получите номер заказа. Назовите его администратору лаборатории и он выдаст обследования
        </p>
      </div>
    </div>
  )
}
