import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { useForm } from 'react-final-form'
import { styled } from '@mui/material/styles'

const StyledCheckbox = styled(Checkbox)(() => ({
  color: '#C9DBFD',
  '&.Mui-checked': {
    color: '#7D9DFF',
  },
}))
export const CheckboxComponent = (props) => {
  const form = useForm()
  return (
    <>
      <StyledCheckbox
        color='default'
        name={props.input.name}
        {...props}
        onChange={() => {
          props.input.onChange(!props.input.checked)
          if (props.input.checked) {
            form.change(`${props.input.name.substring(0, 12)}.time`, '')
          }
        }}
        checked={props.input.checked}
      />
    </>
  )
}
