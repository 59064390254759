import React from 'react'
import Styles from './style.module.scss'
import basket from '@icons/basket.svg'

export const DeleteButton = ({ onClick }) => {
  return (
    <>
      <button className={Styles.button} onClick={onClick}>
        <img src={basket} alt='logo' />
        Удалить
      </button>
    </>
  )
}
