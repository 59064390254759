import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { useSnackbar } from 'notistack'
import { changeAppointment, setDateAppointment, setCompleteTransferRecord } from '../../../../../../redux/doctorSlicer'
import { formateDate } from '../../../../../../../../utils/formateDate'
import Styles from './style.module.scss'
import { Calendar } from '../../../../../UI/Calendar'
import { InputRadio } from '../../../../../UI/InputRadio'

interface RecordingSetupProps {
  completeTransfer: any
}

export const TransferSetup: React.FC<RecordingSetupProps> = ({ completeTransfer }) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const transferRecord = useAppSelector((state) => state.doctor.transferRecord)
  const [currentMonthDate, setCurrentMonthDate] = useState(formateDate(new Date(), 'YYYY-MM-DD'))
  const [selectedDate, setSelectedDate] = useState(null)
  const [dayReception, setDayReception] = useState(null)
  const [timeAppointment, setTimeAppointment] = useState(null)
  const [timeReception, setTimeReception] = useState(null)
  const [formatedDate, setFormatedDate] = useState(null)
  const [formatedDateShort, setFormatedDateShort] = useState(null)
  const [formatedDateDay, setDormatedDateDay] = useState(null)

  const changeMonth = (date) => {
    setCurrentMonthDate(date)
    setSelectedDate(null)
  }

  const selectDay = (date) => {
    setSelectedDate(date)
    setDayReception(date.day)
  }

  const selectTime = (event) => {
    setTimeAppointment(event.target.id)
    setTimeReception(event.target.value)
  }

  const transferAppointment = async () => {
    try {
      const res = await dispatch(
        changeAppointment({
          id: transferRecord.id,
          data: {
            doctor: transferRecord.doctor.id,
            consultation_type: transferRecord.consultation_type.id,
            day_reception: dayReception,
            time_reception: timeReception,
          },
        }),
      )

      dispatch(setCompleteTransferRecord(res.payload.data))
      dispatch(setDateAppointment(`${formatedDateShort}, ${formatedDateDay}, ${timeAppointment}`))

      completeTransfer()
      window.location.reload()
    } catch (error) {
      const message = error
      const type = 'error'

      enqueueSnackbar(
        { message, type },
        {
          autoHideDuration: 1500,
        },
      )
    }
  }

  useEffect(() => {
    setSelectedDate(null)
    setDayReception(null)
    setTimeAppointment(null)
    setTimeReception(null)
  }, [currentMonthDate])

  useEffect(() => {
    if (selectedDate) {
      const date = formateDate(selectedDate.day, 'DD MMMM')
      const dateShort = formateDate(selectedDate.day, 'DD MMM')
      const dateDay = formateDate(selectedDate.day, 'dddd')

      setFormatedDate(date)
      setFormatedDateShort(dateShort)
      setDormatedDateDay(dateDay)
    }
  }, [selectedDate])

  return (
    <div className={Styles.container}>
      <Calendar
        currentMonthDate={currentMonthDate}
        changeMonth={changeMonth}
        selectedDate={selectedDate}
        selectDay={selectDay}
      />
      {selectedDate && (
        <div className={Styles.oneday}>
          {new Date().getDate() === new Date(selectedDate.day).getDate() ? (
            <span className={Styles.today}>сегодня, {formatedDate}</span>
          ) : (
            <span className={Styles.today}>{formatedDate}</span>
          )}
          <div className={Styles.visits}>
            {selectedDate?.receptions?.map((reception) => {
              return (
                <>
                  {!reception.is_blacklist && !reception.is_past && !reception.appointment ? (
                    <label key={reception.id} className={Styles.visit}>
                      <InputRadio
                        id={reception.time}
                        className={Styles.inputDate}
                        onChange={selectTime}
                        name='date'
                        value={reception.id}
                      />
                      <span className={Styles.time}>{reception.time}</span>
                    </label>
                  ) : (
                    <>
                      {!reception.is_blacklist && reception.appointment ? (
                        <div key={reception.id} className={`${Styles.visit} ${Styles.visit_expired}`}>
                          <i
                            className={
                              reception.appointment.is_online
                                ? `${Styles.icon}`
                                : `${Styles.icon} ${Styles.icon_offline}`
                            }
                          >
                            {}
                          </i>
                          <span className={Styles.time}>{reception.time}</span>
                        </div>
                      ) : (
                        <div key={reception.id} className={`${Styles.visit} ${Styles.visit_expired}`}>
                          <span className={Styles.time}>{reception.time}</span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )
            })}
          </div>
        </div>
      )}
      <div className={Styles.confirm}>
        <div className={Styles.denomination}>
          {selectedDate ? (
            <div className={Styles.choosed}>
              <span>Перенести прием</span>
              <span>
                {formatedDateShort}, {formatedDateDay}, {timeAppointment}
              </span>
            </div>
          ) : (
            <div className={Styles.choosed}>
              <span>Перенести прием</span>
              <span>выберите дату</span>
            </div>
          )}
        </div>
        <button className={Styles.revoke} onClick={transferAppointment} disabled={!selectedDate || !timeAppointment}>
          Перенести
        </button>
      </div>
    </div>
  )
}
