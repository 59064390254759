import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getMedicalReportById: any = createAsyncThunk('userSpace/getMedicalReportById', async function (id) {
  const response = await api.get(`medical-report/${id}/`)
  return response.data
})
export const getLastMedicalReport: any = createAsyncThunk('userSpace/getLastMedicalReport', async function () {
  const response = await api.get('last-medical-report/')
  return response.data
})
const userSpaceSlice = createSlice({
  name: 'userSpace',
  initialState: {
    status: null,
    requestSended: false,
    lastReport: null,
    filterNutraceutical: [],
  },
  reducers: {
    setFilterNutraceutical(state, { payload }) {
      const collection = payload.map((item) => {
        return item.periodicity.title
      })
      const a = new Set(collection)
      const b = Array.from(a)
      state.filterNutraceutical = b.map((item) => {
        return { id: item, name: [] }
      })
      payload.map((item) => {
        state.filterNutraceutical.map((i) => {
          if (i.id === item.periodicity.title) {
            return i.name.push(item.nutraceutical.name)
          }
        })
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLastMedicalReport.fulfilled, (state, action) => {
      state.lastReport = action.payload?.results[0]
      state.status = 'success'
      state.requestSended = true
    })
    builder.addCase(getLastMedicalReport.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getLastMedicalReport.rejected, (state) => {
      state.status = 'error'
    })
  },
})
export const { setFilterNutraceutical } = userSpaceSlice.actions

export default userSpaceSlice.reducer
