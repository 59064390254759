import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { deleteBlackList, getBlackLists, setDisplayProfileContent } from '../../../../redux/doctorSlicer'
import Styles from './style.module.scss'

import { DoctorCanceledDays } from './components/DoctorCanceledDays'

import { compareDate } from '../../../../../../utils/compareDate'
import { formateDate } from '../../../../../../utils/formateDate'
import { EditProfileForm } from '@components/EditProfileForm'
import { ProfileModalInput } from '@components/ProfileModal/components/ProfileModalInput'

export const PersonalInfo: React.FC = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.auth.user.info)
  const blackList = useAppSelector((state) => state.doctor.blackList)
  const [confirmReopen, setConfirmReopen] = useState(false)
  const [selectedCancel, setSelectedCancel] = useState(null)
  // const [formInputs, setFormInputs] = useState({
  //   firstname: { value: user.first_name, disabled: true },
  //   secondname: { value: user.last_name, disabled: true },
  // })

  const changeDisplayView = (view) => dispatch(setDisplayProfileContent(view))

  const reopenCancel = (cancel) => {
    setSelectedCancel(cancel)
    setConfirmReopen(true)
  }

  const reopenConfirm = async () => {
    try {
      await dispatch(deleteBlackList(selectedCancel.id))
      await dispatch(getBlackLists())

      setConfirmReopen(false)
    } catch (error) {
      throw new Error(error)
    }
  }

  // const handlerChange = (event) => {
  //   setFormInputs({
  //     ...formInputs,
  //     [event.target.name]: { ...formInputs[event.target.name], value: event.target.value },
  //   })
  // }
  //
  // const unlockInput = (event, nameInput) => {
  //   event.preventDefault()
  //
  //   setFormInputs({
  //     ...formInputs,
  //     [nameInput]: { ...formInputs[nameInput], disabled: !formInputs[nameInput].disabled },
  //   })
  // }

  return (
    <div className={Styles.info}>
      <EditProfileForm formTitle='Личные данные'>
        <ProfileModalInput placeholder={user.first_name} labelName='Имя*' inputName='first_name' inputType='text' />
        <ProfileModalInput placeholder={user.last_name} labelName='Фамилия' inputName='last_name' inputType='text' />
      </EditProfileForm>
      {confirmReopen ? (
        <div className={`${Styles.container} ${Styles.container_mod}`}>
          <div className={Styles.block}>
            <span className={Styles.caption}>Хотите возобновить прием?</span>
            <div className={Styles.confirm}>
              {selectedCancel.from_date === null ? (
                <span className={Styles.day}>
                  {formateDate(selectedCancel.day, 'DD MMMM')}:&nbsp;
                  {selectedCancel.time.map((time, index) => (
                    <span key={index}>{time},&nbsp;</span>
                  ))}
                  {selectedCancel.time.length} записей
                </span>
              ) : (
                <span className={Styles.day}>
                  {formateDate(selectedCancel.from_date, 'DD MMMM')} - {formateDate(selectedCancel.to_date, 'DD MMMM')}
                  ,&nbsp;
                  {compareDate(selectedCancel.to_date, selectedCancel.from_date)} дня
                </span>
              )}
              <div className={Styles.wrap}>
                <button className={Styles.yep} onClick={reopenConfirm}>
                  Да, возобновить
                </button>
                <button className={Styles.nope} onClick={() => setConfirmReopen(false)}>
                  Не возобновлять
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {blackList.length !== 0 ? (
            <div className={Styles.container}>
              <div className={Styles.block}>
                <span className={Styles.caption}>Прием отменен</span>
                <div className={Styles.canceled}>
                  {blackList.map((cancel) => (
                    <DoctorCanceledDays key={cancel.id} cancel={cancel} reopenCancel={reopenCancel} />
                  ))}
                  <button className={Styles.more} onClick={() => changeDisplayView('cancel')}>
                    добавить еще
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className={Styles.container}>
              <div className={Styles.block}>
                <span className={Styles.caption}>Отменить часы или дни приема</span>
                <div className={Styles.cancel}>
                  <p className={Styles.text}>
                    Вы можете выбрать дни или часы, которые будут недоступны для записи пациентам
                  </p>
                  <button className={Styles.choose} onClick={() => changeDisplayView('cancel')}>
                    Выбрать дни
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
