import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import woman from '@icons/medical-reports-woman.svg'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { doneReport, getMedicalReportById, setReportsModalOpen } from '../redux/reportsSlice'
import { loadAppointment } from '../../../pages/doctor/index'

export const DoneReport = ({ setActiveContent, setStatusReport }) => {
  const dispatch = useAppDispatch()
  const medicalReportId = useAppSelector((state) => state.reports.medicalReportId)
  const appointmentId = useAppSelector((state) => state.reports.appointmentId)
  const [statusMedicalReport, setStatusMedicalReport] = useState(false)
  useEffect(() => {
    dispatch(getMedicalReportById(medicalReportId)).then((res) =>
      setStatusMedicalReport(res.payload.data.is_report_done),
    )
  }, [medicalReportId])
  const handleDoneReport = () => {
    dispatch(setReportsModalOpen(false))
    setActiveContent('diet')
    setStatusReport('check-patient')
    loadAppointment(appointmentId, dispatch)
    if (!statusMedicalReport) {
      dispatch(doneReport(medicalReportId)).then(() => window.location.reload())
    }
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.imgWrapper}>
        <img className={Styles.img} src={woman} alt='баннер' />
      </div>
      <h2 className={Styles.title}>Медзаключение создано!</h2>
      <p className={Styles.paragraph}>Пациент получит его в ближайшее время</p>
      <button className={Styles.button} onClick={() => handleDoneReport()}>
        Завершить
      </button>
    </div>
  )
}
