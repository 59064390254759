import React, { useState } from 'react'
import Styles from './style.module.scss'
import { ButtonColor } from '@components/ui/ButtonColor'
import reference1 from '@icons/reference1.png'
import { Link } from 'react-router-dom'
import { Quiz } from '@components/Quiz'
// import { useAppDispatch } from '@app/redux/hooks'
// import { getQuizList } from '../../../../components/Quiz/redux/quizSlicer'

export const ReferenceEmpty = () => {
  // const dispatch = useAppDispatch()
  const [showQuiz, setShowQuiz] = useState(false)
  // const [quizList, setQuizList] = useState(null)
  // const [donePage, setDonePage] = useState(false)
  /* useEffect(() => {
    dispatch(getQuizList())
      .then((res) => {
        setQuizList(res.payload)
      })
      .then(() => setDonePage(true))
  }, []) */
  return (
    <div className={Styles.wrapper}>
      <div className={Styles.reference__container}>
        <div className={Styles.reference__wrapper}>
          <img className={Styles.reference__img} src={reference1} alt='reference' />
          <div className={Styles.reference__description}>
            <h3 className={Styles.reference__title}>Для вас пока нет рекомендаций</h3>
            <p className={Styles.reference__text}>
              Запишитесь на консультацию к врачу, чтобы мы могли сформировать для вас рекомендации{' '}
            </p>
          </div>
          <div className={Styles.reference__buttons}>
            <Link to='/appointment-page' className={Styles.link}>
              <ButtonColor value='Записаться на прием' className={Styles.reference__button} />
            </Link>
            {/* donePage && quizList.length > 0 && (
              <button className={Styles.button__quiz} onClick={() => setShowQuiz(true)}>
                Заполнить анкету
              </button>
            ) */}
            {showQuiz && <Quiz setShowQuiz={setShowQuiz} showQuiz={showQuiz} />}
          </div>
        </div>
      </div>
    </div>
  )
}
