import sctollToTop from '@helpers/scrollToTop'
import React, { useEffect, useRef } from 'react'
import { GoBackButton } from '../GoBackButton'
import Styles from './style.module.scss'

export const ContactsPage = (): React.ReactElement => {
  const component = useRef<HTMLDivElement>(null)
  useEffect(() => {
    sctollToTop(component, 90)
  }, [])
  return (
    <div className={Styles.container} ref={component}>
      <GoBackButton />
      <h2 className={Styles.header}>Реквизиты и контакты</h2>
      <p>Общество с ограниченной ответственностью «ВИВАМЕД»</p>
      <p>ОГРН 1142801002145</p>
      <p>ИНН/КПП 2801193943/280101001</p>
      <p>
        Юридический адрес: 675000, Российская Федерация, Амурская область, г. Благовещенск, ул. Горького, 235/2 офис 107
      </p>
      <p>Фактический адрес: 675000, Российская Федерация, Амурская Горького, 235/2 офис 107 </p>
      <p>Расчетный счет 40702810503000008773</p>
      <p>Корреспондентский счет 30101810600000000608</p>
      <p>БИК 040813608</p>
      <p>Дальневосточный банк ПАО «Сбербанк России» г. Хабаровск</p>
      <p>
        Электронная почта: <a href='mailto:info@persolab.ru'>info@persolab.ru</a>
      </p>
    </div>
  )
}
