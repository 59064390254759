import React, { useState } from 'react'
import Styles from './style.module.scss'
import { useAppSelector, useAppDispatch } from '@app/redux/hooks'
import { changeUserInfo, getUser, handleLogout } from '../../../../pages/authorization/redux/authSlice'
import { setDisplayProfileContent } from '../../../../pages/doctor/redux/doctorSlicer'
import { useHistory } from 'react-router'
import avatarOverlay from '@icons/avatar-overlay.png'
import { TransitionsModal } from '@components/Modal'
import { openAvatarPopup, openProfileModal } from '@components/ProfileModal/redux/profileSlice'
import { useSnackbar } from 'notistack'

export const ProfileModalHeader: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const user = useAppSelector((state) => state.auth.user.info)
  const displayProfileContent = useAppSelector((state) => state.doctor.displayProfileContent)
  const isAvatarPopupOpen = useAppSelector((state) => state.profile.modal.isAvatarPopupOpen)
  const [avatarFile, setAvatarFile] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  const handleOutClick = () => {
    dispatch(openProfileModal())
    dispatch(handleLogout())
    history.push('/')
  }

  const changeDisplayView = (view) => dispatch(setDisplayProfileContent(view))

  const handleAvatarClick = () => {
    dispatch(openAvatarPopup(true))
  }

  const handleAvatarInput = (e) => {
    setAvatarFile(e.target.files[0])
  }

  const uploadAvatar = (e) => {
    e.preventDefault()
    const avatarData = new FormData()
    avatarData.append('avatar', avatarFile)
    dispatch(changeUserInfo(avatarData))
      .then((res) => {
        if (res.payload.status === 200) {
          dispatch(getUser())
          dispatch(openAvatarPopup(false))
        } else {
          const message = `${Object.values(res.payload.data)}`
          const type = 'error'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        }
      })
      .catch(() => {
        const message = `Сервер недоступен, повторите попытку позже`
        const type = 'error'
        enqueueSnackbar(
          { message, type },
          {
            autoHideDuration: 1500,
          },
        )
      })
  }

  return (
    <>
      <div className={Styles.header}>
        {displayProfileContent === 'info' && (
          <>
            <div className={Styles.avatar}>
              <img src={avatarOverlay} className={Styles.overlay} alt='img' onClick={handleAvatarClick} />
              {user.avatar ? (
                <div className={Styles.image}>
                  <img className={Styles.img} src={user.avatar} alt='img' />
                </div>
              ) : (
                <div className={Styles.initials}>
                  {`${user.first_name?.substring(0, 1).toUpperCase() || ''}${
                    user.last_name?.substring(0, 1).toUpperCase() || ''
                  }`}
                </div>
              )}
            </div>
            <div className={Styles.headerWrapper}>
              <h3>{`${user.first_name || ''} ${
                (user?.last_name && user.last_name.substring(0, 1).concat('.')) || ''
              }`}</h3>
              <button className={Styles.logout} onClick={handleOutClick}>
                Выйти
              </button>
            </div>
          </>
        )}
        {displayProfileContent === 'cancel' && (
          <>
            <button className={Styles.backspace} onClick={() => changeDisplayView('info')} />
            <span className={Styles.designation}>Отменить дни приема</span>
          </>
        )}
      </div>
      <TransitionsModal
        isOpenModal={isAvatarPopupOpen}
        setModalOpened={(state) => dispatch(openAvatarPopup(state))}
        onClose={(state) => dispatch(openAvatarPopup(state))}
      >
        <form className={Styles.avatarModal} onSubmit={uploadAvatar}>
          <h3>Загрузите аватар</h3>
          <input type='file' id='avatar' accept='image/*' onChange={handleAvatarInput} />
          <button>Отправить</button>
        </form>
      </TransitionsModal>
    </>
  )
}
