import React from 'react'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Styles from './style.module.scss'
import closeIcon from '@icons/close-gray.svg'
import { styled } from '@mui/material/styles'

const PREFIX = 'TransitionsModal'

const classes = {
  modal: `${PREFIX}-modal`,
}

const StyledModal = styled(Modal)(() => ({
  [`&.${classes.modal}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '10000',
  },
}))

export const TransitionsModal = (props) => {
  const handleClose = () => {
    props.setModalOpened(false)
  }

  return (
    <div>
      <StyledModal
        disablePortal={props.disablePortal}
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={props.isOpenModal}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { opacity: props.opacity },
        }}
        style={props.style}
      >
        <Fade in={props.isOpenModal}>
          <div className={Styles.modal_container}>
            <div className={`${Styles.content} ${props.className}`}>{props.children}</div>
            {props.onClose && (
              <div
                role='presentation'
                onClick={() => {
                  props.setModalOpened(false)
                }}
                className={Styles.close_container}
              >
                <img src={closeIcon} className={Styles.close_icon} alt='close' />
              </div>
            )}
          </div>
        </Fade>
      </StyledModal>
    </div>
  )
}
