import React, { useState } from 'react'
import Styles from './style.module.scss'

interface CancelHoursReceptionProps {
  reception: any
  handlerChange: any
}

export const CancelHoursReception: React.FC<CancelHoursReceptionProps> = ({ reception, handlerChange }) => {
  const [checked, setChecked] = useState(false)

  const onChange = (event) => {
    setChecked(event.target.checked)
    handlerChange(event)
  }

  return (
    <>
      {reception.is_past && (
        <div className={`${Styles.visit} ${Styles.visit_expired}`}>
          {reception.appointment && (
            <i className={reception.appointment.is_online ? `${Styles.icon}` : `${Styles.icon} ${Styles.icon_offline}`}>
              {}
            </i>
          )}
          <span className={Styles.time}>{reception.time}</span>
        </div>
      )}
      {!reception.is_blacklist && !reception.is_past && (
        <label className={checked ? `${Styles.visit} ${Styles.visit_selected}` : `${Styles.visit}`}>
          <input
            className={Styles.input}
            onChange={onChange}
            type='checkbox'
            value={reception.id}
            name={reception.time}
            checked={checked}
          />
          <span className={Styles.checkbox}>{}</span>
          <span className={Styles.time}>{reception.time}</span>
        </label>
      )}
    </>
  )
}
