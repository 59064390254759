import { useAppSelector } from '@app/redux/hooks'
import React from 'react'
import Styles from './style.module.scss'
import { Field } from 'react-final-form'
import multiply from '@icons/multiply.svg'

export const ResearchForm = ({ children, name }) => {
  const settings: any = useAppSelector((state) => state.reports.researchSettings)
  const required = (value) => (value ? undefined : 'Обязательное поле')
  const mustBeNumber = (value) => (isNaN(value) ? 'Введите число' : undefined)
  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce((error, validator) => error || validator(value), undefined)
  const SelectOne = ({ input }) => {
    return (
      <div className={Styles.container}>
        <label>Образования в правой доле</label>
        <select {...input}>
          {settings.left_education?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
    )
  }
  const SelectTwo = ({ input }) => {
    return (
      <div className={Styles.container}>
        <label>Образования в левой доле</label>
        <select {...input}>
          {settings.right_education?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
        </select>
      </div>
    )
  }
  return (
    <>
      <div className={Styles.form}>
        {children}
        {/* <Field
          validate={composeValidators(required, mustBeNumber)}
          name={`${name}.volume`}
          defaultValue='0'
          render={({ input, meta }) => (
            <div className={Styles.volume}>
              <label className={Styles.label}>Объем*</label>
              <div>
                <input {...input} autoComplete='off' />
                <span className={Styles.mark}>см. куб.</span>
              </div>

              {meta.touched && meta.error && <span className={Styles.error}>{meta.error}</span>}
            </div>
          )}
          /> */}
        {/* settings.right_education && (
          <Field
            name={`${name}.right_education`}
            component={SelectOne}
            defaultValue={settings?.right_education[0].id}
          />
        ) */}
        {/* settings.left_education && (
          <Field name={`${name}.left_education`} component={SelectTwo} defaultValue={settings?.left_education[0].id} />
        ) */}
        {/* <div className={Styles.size}>
          <label className={Styles.label}>Размер</label>
          <div className={Styles.size__wrapper}>
            <Field
              name={`${name}.length`}
              validate={mustBeNumber}
              defaultValue='0'
              render={({ input, meta }) => (
                <div className={Styles.size__column}>
                  <div className={Styles.size__item}>
                    <input {...input} autoComplete='off' />
                  </div>
                  {meta.touched && meta.error && <span className={Styles.error}>{meta.error}</span>}
                </div>
              )}
            />
            <img src={multiply} alt='multiply' />
            <Field
              name={`${name}.weight`}
              validate={mustBeNumber}
              defaultValue='0'
              render={({ input, meta }) => (
                <div className={Styles.size__column}>
                  <div className={Styles.size__item}>
                    <input {...input} autoComplete='off' />
                  </div>
                  {meta.touched && meta.error && <span className={Styles.error}>{meta.error}</span>}
                </div>
              )}
            />
            <img src={multiply} alt='multiply' />
            <Field
              name={`${name}.height`}
              validate={mustBeNumber}
              defaultValue='0'
              render={({ input, meta }) => (
                <div className={Styles.size__column}>
                  <div className={Styles.size__item}>
                    <input {...input} autoComplete='off' />
                  </div>
                  {meta.touched && meta.error && <span className={Styles.error}>{meta.error}</span>}
                </div>
              )}
            />
          </div>
              </div> */}
        <Field
          name={`${name}.remark`}
          render={({ input }) => (
            <div className={Styles.misc}>
              <label>Заключение (необязательно)</label>
              <textarea {...input} onChange={input.onChange} />
            </div>
          )}
        />
      </div>
    </>
  )
}
