import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'

import { DoctorReception } from './components/DoctorReception'

import { formateDate } from '../../../../../../../../utils/formateDate'

interface DoctorTodayProps {
  dataDay: any
  onClickReception: any
}

export const DoctorToday: React.FC<DoctorTodayProps> = ({ dataDay, onClickReception }) => {
  const [currentReceptions, setCurrentReceptions] = useState([])

  useEffect(() => {
    const futureReception = []
    const pastReception = []

    dataDay.receptions.forEach((reception) => {
      if ((reception.is_past && reception.appointment) || (reception.is_past && !reception.appointment)) {
        pastReception.push(reception)
      }

      if ((!reception.is_past && reception.appointment) || (!reception.is_past && !reception.appointment)) {
        futureReception.push(reception)
      }
    })

    const futureSortedArray = futureReception.sort((a, b) => a.time.localeCompare(b.time))
    const doneSortedArray = pastReception.sort((a, b) => a.time.localeCompare(b.time))

    setCurrentReceptions([...futureSortedArray, ...doneSortedArray])
  }, [dataDay])

  return (
    <div className={Styles.today}>
      <span className={Styles.date}>{formateDate(dataDay.day, 'DD MMMM')}</span>
      <div className={Styles.block}>
        {currentReceptions.map((reception) => {
          return <DoctorReception key={reception.id} reception={reception} onClickReception={onClickReception} />
        })}
      </div>
    </div>
  )
}
