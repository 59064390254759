import React, { useState } from 'react'
import Styles from './style.module.scss'
import editIcon from '@icons/mobile-profile-edit-icon.svg'
import { DrawerPanel } from '@components/Drawer'
import editAvatarIcon from '@icons/mobile-avatar-edit-icon.svg'
import deleteAvatarIcon from '@icons/mobile-avatar-delete-icon.svg'
import { useAppDispatch } from '@app/redux/hooks'
import { changeUserInfo, getUser } from '../../../pages/authorization/redux/authSlice'
import { useSnackbar } from 'notistack'

interface MobileProfileAvatarProps {
  avatar?: string
  firstName?: string
  lastName?: string
}

export const MobileProfileAvatar: React.FC<MobileProfileAvatarProps> = ({ avatar, firstName, lastName }) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [isAvatarDrawerOpen, setIsAvatarDrawerOpen] = useState(false)
  const toggleAvatarDrawer = () => setIsAvatarDrawerOpen((prev) => !prev)
  const handleEditClick = () => {
    toggleAvatarDrawer()
  }
  const handleAvatarUpload = (e) => {
    const avatarData = new FormData()
    avatarData.append('avatar', e.target.files[0])
    dispatch(changeUserInfo(avatarData))
      .then((res) => {
        if (res.payload.status === 200) {
          dispatch(getUser())
          toggleAvatarDrawer()
          const message = `Аватар успешно изменён`
          const type = 'success'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        } else {
          const message = `${Object.values(res.payload.data)}`
          const type = 'error'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        }
      })
      .catch(() => {
        const message = `Сервер недоступен, повторите попытку позже`
        const type = 'error'
        enqueueSnackbar(
          { message, type },
          {
            autoHideDuration: 1500,
          },
        )
      })
  }
  const handleDeleteAvatar = () => {
    const avatarData = { avatar: null }
    dispatch(changeUserInfo(avatarData))
      .then((res) => {
        if (res.payload.status === 200) {
          dispatch(getUser())
          toggleAvatarDrawer()
          const message = `Аватар успешно удалён`
          const type = 'success'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        } else {
          const message = `${Object.values(res.payload.data)}`
          const type = 'error'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        }
      })
      .catch(() => {
        const message = `Сервер недоступен, повторите попытку позже`
        const type = 'error'
        enqueueSnackbar(
          { message, type },
          {
            autoHideDuration: 1500,
          },
        )
      })
  }
  return (
    <>
      <div className={Styles.container}>
        <div className={Styles.wrapper}>
          {avatar ? (
            <img src={avatar} alt='аватар' className={Styles.avatar} />
          ) : (
            <div className={Styles.initials}>
              {`${firstName?.substring(0, 1).toUpperCase() || ''}${lastName?.substring(0, 1).toUpperCase() || ''}`}
            </div>
          )}
          <img className={Styles.editIcon} src={editIcon} alt='Редактировать' onClick={handleEditClick} />
        </div>
        <span className={Styles.username}>
          {firstName} {lastName && `${lastName.substring(0, 1).toUpperCase()}.`}
        </span>
      </div>
      <DrawerPanel
        width='100%'
        height='fit-content'
        anchor='bottom'
        open={isAvatarDrawerOpen}
        onClose={toggleAvatarDrawer}
      >
        <div className={Styles.drawer}>
          <label className={Styles.drawer__item}>
            <img className={Styles.drawer__icon} src={editAvatarIcon} alt='icon' />
            <span className={Styles.drawer__text}>
              Изменить
              <input type='file' id='avatar' accept='image/*' onChange={handleAvatarUpload} />
            </span>
          </label>
          <div className={Styles.drawer__item} onClick={handleDeleteAvatar}>
            <img className={Styles.drawer__icon} src={deleteAvatarIcon} alt='icon' />
            <span className={Styles.drawer__text}>Удалить</span>
          </div>
        </div>
      </DrawerPanel>
    </>
  )
}
