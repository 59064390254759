import React, { useEffect } from 'react'
import { Route } from 'react-router'
import { ProtectedRoute } from '@components/Router/protected-route'
import { useAppDispatch } from '@app/redux/hooks'
import {
  setLoading,
  setListUniqPatients,
  setAllPatients,
  getListAppointmentsDate,
  getListAppointmentsStatusOrdering,
  getListAppointmentPatientOrdering,
  getListPatients,
  getConsultationDirection,
  getDoctorCalendarToday,
  getDoctorCalendarWeek,
  getDoctorCalendarMonth,
  getBlackLists,
} from '../../pages/doctor/redux/doctorSlicer'
// eslint-disable-next-line camelcase
import { USER_ROlES } from '../../global-constants'
import { DoctorPageMain } from '../../pages/doctor/components/DoctorPageMain'
import { DoctorPagePatients } from '../../pages/doctor/components/DoctorPagePatients'
import { DoctorPagePatient } from '../../pages/doctor/components/DoctorPagePatient'
import { DoctorPageAppointment } from '../../pages/doctor/components/DoctorPageAppointment'
import { DoctorProfileMobile } from '../../pages/doctor/components/DoctorProfile/components/DoctorProfileMobile'
import { ConfidentialPage } from '@components/Footer/components/ConfidentialPage'
import { PersonalDataPage } from '@components/Footer/components/PersonalDataPage'
import { PageNotFount } from '../../pages/not-found'

import { formateDate, getCurrentWeek, getCurrentMonth } from '../../utils/formateDate'
import { sortingByDate } from '../../utils/sortingByDate'
import { CatalogPageNutraceutical } from '../../pages/catalog/components/CatalogPageNutraceutical'
import { CatalogPageResearch } from '../../pages/catalog/components/CatalogPageResearch'
import { Cart } from '../../pages/cart'
import { GoCheckout } from '../../pages/cart/GoCheckout'
import { CatalogPage } from '../../pages/catalog'
import { CreatedOrder } from '../../pages/cart/CreatedOrder'
import { OrderDetails } from '../../pages/orders/components/OrderDetails'
import { Orders } from '../../pages/orders'

export const DoctorRoutes: React.FC = () => {
  const dispatch = useAppDispatch()

  const onLoadPage = async () => {
    const today = formateDate(new Date(), 'YYYY-MM-DD')
    const dataWeek = getCurrentWeek(today)
    const dataMonth = getCurrentMonth()

    try {
      dispatch(setLoading(true))

      const getPatientsWithLastAppointment = async (patients) => {
        const patientsWithLastAppointment = []

        for (const patient of patients) {
          try {
            const response = await dispatch(
              getListAppointmentPatientOrdering({ patientId: patient.id, ordering: today }),
            )
            const appointments = response.payload.data.results

            if (appointments.length === 0) {
              const patientObj = { ...patient, last_appointment: {} } // поменять на null
              patientsWithLastAppointment.push(patientObj)
            } else {
              const patientObj = { ...patient, last_appointment: appointments[appointments.length - 1] }
              patientsWithLastAppointment.push(patientObj)
            }
          } catch (error) {
            // console.log(error)
          }
        }

        const sortedPatientsByLastVisit = sortingByDate(patientsWithLastAppointment)

        dispatch(setAllPatients(sortedPatientsByLastVisit))
        dispatch(setListUniqPatients(sortedPatientsByLastVisit))
      }

      const response = await dispatch(getListPatients())

      getPatientsWithLastAppointment(response.payload.data.results)

      await dispatch(getListAppointmentsDate({ day_after: today, day_before: today }))
      await dispatch(getDoctorCalendarToday({ start_date: today, end_date: today }))
      await dispatch(getDoctorCalendarWeek({ start_date: dataWeek.firstDayWeek, end_date: dataWeek.lastDayWeek }))
      await dispatch(getDoctorCalendarMonth({ start_date: dataMonth.firstDayMonth, end_date: dataMonth.lastDayMonth }))
      await dispatch(getListAppointmentsStatusOrdering({ status: 'done', ordering: today }))
      await dispatch(getBlackLists())
      await dispatch(getConsultationDirection())
    } catch (error) {
      // console.log(error)
    } finally {
      dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    onLoadPage()
  }, [])

  return (
    <>
      <ProtectedRoute
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin]}
        path='/'
        exact
        component={DoctorPageMain}
      />
      <ProtectedRoute
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin]}
        path='/patients'
        exact
        component={DoctorPagePatients}
      />
      <ProtectedRoute
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin]}
        path='/patients/:id'
        exact
        component={DoctorPagePatient}
      />
      <ProtectedRoute
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin]}
        path='/appointments/:id'
        exact
        component={DoctorPageAppointment}
      />
      <ProtectedRoute
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin]}
        path='/profile'
        exact
        component={DoctorProfileMobile}
      />
      <Route path='/confidential' exact component={ConfidentialPage} />
      <Route path='/personal-data' exact component={PersonalDataPage} />
      <Route path='/not-found' exact component={PageNotFount} />
      <ProtectedRoute
        path='/catalog/nutraceutical/:id'
        exact
        component={CatalogPageNutraceutical}
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
      />
      <ProtectedRoute
        path='/catalog/research/:id'
        exact
        component={CatalogPageResearch}
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
      />
      <ProtectedRoute
        path='/cart'
        exact
        component={Cart}
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
      />
      <ProtectedRoute
        path='/cart/gocheckout'
        exact
        component={GoCheckout}
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
      />
      <ProtectedRoute
        path='/catalog'
        exact
        component={CatalogPage}
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
      />
      <ProtectedRoute
        path='/created-order'
        exact
        component={CreatedOrder}
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
      />
      <ProtectedRoute
        path='/order/:id'
        exact
        component={OrderDetails}
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
      />
      <ProtectedRoute
        path='/orders'
        exact
        component={Orders}
        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
      />
    </>
  )
}
