import React, { FC } from 'react'
import styles from './styles.module.scss'

import { ShadowedContainer } from '@components/Containers/ShadowedContainer'
import { OrderAnalysisItem } from '../OrderAnalysisItem'
import { OrderDeliveryStatus } from '../OrderDeliveryStatus'
import { ButtonColor } from '@components/ui/ButtonColor'
import { BorderLine } from '@components/ui/BorderLine'
import { Tag } from '@components/ui/Tag'
import { useAppDispatch } from '@app/redux/hooks'
import { Link } from 'react-router-dom'
import classNames from 'classnames/bind'
import { postPaymentOrder } from '../../../cart/redux/cartSlicer'
import useWindowSize from '@helpers/useWindowSizeHook'
import { ordersTypes } from '../../redux/orderTypes'
import { getTagBackgroundClassname } from '../../utils/getTagBackgroundClassname'
import { formatDate } from '../../utils/formatDateWithT'

interface OrderSummaryProps {
  order: any
}

export const OrderSummary: FC<OrderSummaryProps> = ({ order }) => {
  const cx = classNames.bind(styles)
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()

  const goToPay = async () => {
    try {
      localStorage.setItem('createdIdOrder', order.id)

      const resPayment = await dispatch(postPaymentOrder({ order: order.id }))

      window.location.replace(resPayment.payload.url)
    } catch (error) {
      // console.log(error)
    }
  }

  const goToTrack = () => {
    // console.log('track')
  }

  return (
    <ShadowedContainer customStyle={styles.container}>
      <div className={styles.header}>
        <Link to={`/order/${order.id}`} className={styles.number}>
          Заказ №{order.id}
        </Link>
        <p className={styles.date}>от {formatDate(order.created_at)}</p>

        <p className={styles.total}>{order.total_cost.toLocaleString()}&nbsp;₽</p>

        {width > 813 && order.status === 'not_paid' ? (
          <ButtonColor value='Оплатить' className={styles.pay} onClick={goToPay} />
        ) : width > 830 && order.delivery.tracking ? (
          <ButtonColor value='Отследить' className={styles.track} onClick={goToTrack} />
        ) : null}
      </div>

      <div className={styles.details}>
        <div className={styles.goods}>
          {/* Фейковая доставка для статуса */}
          <OrderDeliveryStatus deliveryInfo={order} />

          {order.products_in_order && order.products_in_order.length !== 0 && (
            <div className={styles.goods_images}>
              {order.products_in_order.map((product: ordersTypes.IProductInOrder) => {
                return <img src={`${product.image}`} alt='item' className={styles.goods_image} key={product.id} />
              })}
            </div>
          )}
        </div>

        {(order.analyzes_in_order.length > 0 || order.analysis_sets_in_order.length > 0) && (
          <div className={styles.tests}>
            <div className={styles.tests_header}>
              <p className={styles.tests_title}>Отправка на E-mail</p>
              <Tag value='Отправлен' customStyle={cx(styles.tag, getTagBackgroundClassname('Отправлен'))} />
            </div>
            <p className={styles.tests_subtitle}>E-mail: {order.delivery.email}</p>

            {order.analyzes_in_order.length !== 0 &&
              order.analyzes_in_order.map((analysis: ordersTypes.IAnalysisInOrder) => {
                return <OrderAnalysisItem analysis={analysis} key={analysis.id} />
              })}

            {order.analysis_sets_in_order.length !== 0 &&
              order.analysis_sets_in_order.map((analysis: ordersTypes.IAnalysisInOrder) => {
                return <OrderAnalysisItem analysis={analysis} key={analysis.id} complex />
              })}
          </div>
        )}

        {width < 814 && order.status === 'not_paid' ? (
          <>
            <BorderLine />
            <ButtonColor value='Оплатить' className={styles.pay} onClick={goToPay} />
          </>
        ) : width < 814 && order.delivery.tracking ? (
          <>
            <BorderLine />
            <ButtonColor value='Отследить' className={styles.track} onClick={goToTrack} />
          </>
        ) : null}
      </div>
    </ShadowedContainer>
  )
}
