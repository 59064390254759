import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import { CatalogRating } from '../../../../../CatalogRating'
import 'swiper/swiper.scss'
import Styles from './style.module.scss'
import { Loader } from '../../../../../../../../pages/doctor/components/UI/Loader'

SwiperCore.use([Pagination])

export const CatalogProductMobile = ({ product, selectedVariant, selectedModification }) => {
  return product && selectedVariant && selectedModification ? (
    <>
      <h3 className={Styles.title}>
        {product.brand}, {product.name}, {selectedVariant.value}, {selectedModification.value}.
      </h3>
      <CatalogRating rating={product.rating} comment={product.reviews} />
      <div className={Styles.slider}>
        {selectedVariant?.image_labels.length > 0 && (
          <span style={{ backgroundColor: `${selectedVariant.image_labels[0].color}` }} className={Styles.new}>
            {selectedVariant.image_labels[0].title}
          </span>
        )}
        <div className={Styles.wrapper}>
          <Swiper className={Styles.swiper} slidesPerView={1} pagination={{ clickable: true }}>
            {selectedVariant.images.map((image) => {
              return (
                <SwiperSlide key={image.image} className={Styles.slide}>
                  <div className={Styles.img}>
                    <img src={image.image} alt='Изображение' />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </>
  ) : (
    <Loader />
  )
}
