import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import React, { useState } from 'react'
import Styles from './style.module.scss'
import { BorderLine } from '@components/ui/BorderLine'
import useWindowSize from '@helpers/useWindowSizeHook'
import clock from '@icons/clock.svg'
import Parser from 'html-react-parser'

export const AppointmentInformation = (props) => {
  const { width } = useWindowSize()
  const desktop = width >= 829
  const [visible, setVisible] = useState(false)
  const isVisible = () => {
    setVisible(!visible)
  }

  return (
    <>
      <div className={Styles.details__container_main}>
        <header className={Styles.header}>
          <div className={Styles.header__wrap}>
            <img src={props.data?.consultation_type?.direction?.image} className={Styles.img} alt='reference' />
            <h4 className={Styles.title__appoimtment}>{props.data?.consultation_type?.direction?.title}</h4>
          </div>

          <div className={Styles.wrapper}>
            <div className={Styles.duration}>
              <img src={clock} alt='clock' />
              {props.data?.consultation_type?.duration}
            </div>
            <div className={Styles.subtitle}>{props.data?.consultation_type?.title}</div>
          </div>
        </header>
        {desktop && <BorderLine />}
        <div className={Styles.container}>
          <div className={Styles.desc}>{Parser(props.data?.consultation_type?.small_description)}</div>
          <ButtonTransparent
            styleImg={visible ? `${Styles.arrow_down} ${Styles.arrow_active}` : Styles.arrow_down}
            onClick={isVisible}
            value='Как проходит консультация'
            after
          />
          {visible && <div className={Styles.text}>{Parser(props.data?.consultation_type?.description)}</div>}
        </div>
      </div>
    </>
  )
}
