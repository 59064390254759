import React, { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setIsDrawer } from '../../../../../../redux/catalogSlicer'
import Styles from './style.module.scss'
import { Rating } from '@mui/material'
import declination from '@helpers/declination'
import { Loader } from '../../../../../../../../pages/doctor/components/UI/Loader'

export const CatalogFeedbackReviews = ({ reviews, product }) => {
  const dispatch = useAppDispatch()
  const reviewsLoading = useAppSelector((state) => state.catalog.reviewsLoading)

  const starConfig = [
    { number: 5, width: '0%', quantity: '0' },
    { number: 4, width: '0%', quantity: '0' },
    { number: 3, width: '0%', quantity: '0' },
    { number: 2, width: '0%', quantity: '0' },
    { number: 1, width: '0%', quantity: '0' },
  ]

  const openModal = () => dispatch(setIsDrawer(true))
  const reviewCounter = (rating) => reviews.filter((item) => item.rating === rating).length
  const reviewQuantity = (n) => declination(reviewCounter(n), 'отзыв', 'отзыва', 'отзывов')
  const reviewsPercent = (n) => `${(n * 100) / product.reviews}%`

  return useMemo(() => {
    return reviewsLoading ? (
      <Loader />
    ) : (
      <div className={Styles.reviews}>
        <div className={Styles.rating}>
          <Rating name='rating' value={product.rating} precision={0.1} readOnly />
          <span className={Styles.numbers}>{product.rating} из 5,0</span>
        </div>
        <div className={Styles.customer}>
          По оценке {declination(product.reviews, 'покупателя', 'покупателей', 'покупателей')}
        </div>
        <div className={Styles.counts}>
          {starConfig.map((star) => {
            return (
              <div className={Styles.block} key={star.number}>
                <span className={Styles.number}>{star.number}</span>
                <div className={Styles.bar}>
                  <div
                    style={{ width: reviewsPercent(reviewCounter(star.number)) }}
                    className={`${Styles.bar} ${Styles.bar_filled}`}
                  >
                    &nbsp;
                  </div>
                </div>
                <a className={Styles.link} href='#'>
                  {reviewQuantity(star.number)}
                </a>
              </div>
            )
          })}
        </div>
        <button className={Styles.button} onClick={openModal}>
          Оставить отзыв
        </button>
      </div>
    )
  }, [reviews, product])
}
