import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useWindowSize from '@helpers/useWindowSizeHook'
import { CatalogNutraceuticalsItem } from '../CatalogNutraceuticals/components/CatalogNutraceuticalsItem'
import { MOBILE_BREAKPOINT } from '../../../../global-constants'
import 'swiper/swiper.scss'
import Styles from './style.module.scss'

interface IComponent {
  items: any[]
}

export const CatalogList: React.FC<IComponent> = (props) => {
  const { items } = props

  const { width } = useWindowSize()

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  return (
    <>
      {items.length !== 0 && (
        <>
          {width > MOBILE_BREAKPOINT ? (
            <div className={Styles.container}>
              <button className={`prev-card ${`${Styles.button} ${Styles.button_prev}`}`} ref={navigationPrevRef} />
              <Swiper
                className={Styles.swiper}
                slidesPerView='auto'
                spaceBetween={14}
                navigation={{ prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current }}
                onSwiper={(swiper) => {
                  setTimeout(() => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    swiper.params.navigation.prevEl = navigationPrevRef.current
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    swiper.params.navigation.nextEl = navigationNextRef.current
                    swiper.navigation.destroy()
                    swiper.navigation.init()
                    swiper.navigation.update()
                  })
                }}
              >
                {items.map((item) => (
                  <SwiperSlide key={item.id} className={Styles.slide}>
                    <CatalogNutraceuticalsItem product={item.product || item} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <button className={`next-card ${Styles.button} ${Styles.button_next}`} ref={navigationNextRef} />
            </div>
          ) : (
            <div className={Styles.container}>
              <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={10}>
                {items.map((item) => (
                  <SwiperSlide key={item.id} className={Styles.slide}>
                    <CatalogNutraceuticalsItem product={item.product || item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </>
      )}
    </>
  )
}
