import React from 'react'
import ReactDOM from 'react-dom'

import './index.scss'

import { Provider } from 'react-redux'
import App from './components/App'
import { store } from '@app/redux/store'
import { SnackbarProvider } from 'notistack'
import { SlideNotification } from '@components/Notification'
import { FormApiStorage } from './utils/form-storage'

ReactDOM.render(
  <Provider store={store}>
    <FormApiStorage>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <SnackbarProvider
        maxSnack={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        content={(key, message: any) => <SlideNotification id={key} {...message} />}
      >
        <App />
      </SnackbarProvider>
    </FormApiStorage>
  </Provider>,
  document.getElementById('root'),
)
