import React from 'react'
import Styles from './style.module.scss'

interface DoctorPopupMobileProps {
  closePopup: any
}

export const DoctorPopupMobile: React.FC<DoctorPopupMobileProps> = ({ closePopup, children }) => {
  return (
    <div className={Styles.popup}>
      <div className={Styles.container}>
        <button className={Styles.exit} onClick={closePopup}>
          {}
        </button>
        {children}
      </div>
    </div>
  )
}
