import React, { useState } from 'react'
import Styles from './style.module.scss'
import { InputPhoneForm } from '../InputPhoneForm'
import { InputSmsForm } from '../InputSmsForm'
import { FirstTimeForm } from '../FirstTimeForm'
import { useAuthorizationValidation } from '../../../../utils/validation'
import { Logo } from '@components/Logo'

interface AuthorizationFormProps {
  appointment?: boolean
}
export const AuthorizationForm: React.FC<AuthorizationFormProps> = (props) => {
  const { values, errors, setValues, setErrors } = useAuthorizationValidation()
  const [currentPage, setCurrentPage] = useState(1)
  return (
    <form className={Styles.form} noValidate>
      {!props.appointment && (
        <div className={Styles.form__logoContainer}>
          <Logo />
        </div>
      )}
      <fieldset className={Styles.fieldset}>
        {currentPage === 1 && (
          <InputPhoneForm setCurrentPage={setCurrentPage} errors={errors} setValues={setValues} setErrors={setErrors} />
        )}
        {currentPage === 2 && <InputSmsForm setCurrentPage={setCurrentPage} values={values} />}
        {currentPage === 3 && <FirstTimeForm />}
      </fieldset>
    </form>
  )
}
