import React from 'react'
import Styles from './style.module.scss'

import { compareDate } from '../../../../../../../../utils/compareDate'
import { formateDate } from '../../../../../../../../utils/formateDate'

interface DoctorCanceledDaysProps {
  cancel: any
  reopenCancel: any
}

export const DoctorCanceledDays: React.FC<DoctorCanceledDaysProps> = ({ cancel, reopenCancel }) => {
  return (
    <div className={Styles.visit}>
      {cancel.from_date === null ? (
        <>
          <span className={Styles.date}>{formateDate(cancel.day, 'DD MMMM')}:&nbsp;</span>
          {cancel.time.map((time, index) => (
            <span key={index} className={Styles.time}>
              {time},&nbsp;
            </span>
          ))}
          <span className={Styles.count}>{cancel.time.length} записей</span>
          <button className={Styles.back} onClick={() => reopenCancel(cancel)}>
            {}
          </button>
        </>
      ) : (
        <>
          <span className={Styles.date}>
            {formateDate(cancel.from_date, 'DD MMMM')} - {formateDate(cancel.to_date, 'DD MMMM')},&nbsp;
          </span>
          <span className={Styles.count}>{compareDate(cancel.to_date, cancel.from_date) + 1} дня</span>
          <button className={Styles.back} onClick={() => reopenCancel(cancel)}>
            {}
          </button>
        </>
      )}
    </div>
  )
}
