import { useAppDispatch } from '@app/redux/hooks'
import { setSelectedVariant } from '../../../../../../../../../../pages/catalog/redux/productSlice'
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import Styles from './style.module.scss'

export const CatalogProductSelectTaste = ({ product, selectedVariant }) => {
  const dispatch = useAppDispatch()
  const setVariant = (index) => {
    dispatch(setSelectedVariant(index))
  }
  return (
    <div className={Styles.container}>
      <span className={Styles.title}>Выберите вкус</span>
      <div className={Styles.wrapper}>
        <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={10}>
          {product.variants?.map((variant, index) => {
            return (
              <SwiperSlide key={index} className={Styles.slide} onClick={() => setVariant(index)}>
                <div
                  className={
                    variant.id === selectedVariant.id ? `${Styles.block} ${Styles.block_select}` : `${Styles.block}`
                  }
                >
                  <div className={Styles.image}>
                    <img src={variant.images[0].image} alt='Изображение' />
                  </div>
                  <span>{variant.value}</span>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}
