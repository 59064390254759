import React from 'react'
import Styles from './style.module.scss'
import noEventIcon from '@icons/no-event-icon.svg'

export const NoEvents = () => {
  return (
    <div className={Styles.content}>
      <p>Сегодня</p>
      <div className={Styles.event}>
        <img src={noEventIcon} alt='icon' />
        <div className={Styles.eventContent}>
          <h3>Что такое события?</h3>
          <p>
            Здесь мы будем показывать уведомления о ваших медзаключениях, напоминать о приемах и показывать обновления
            сервиса
          </p>
        </div>
      </div>
    </div>
  )
}
