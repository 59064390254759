import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { useSnackbar } from 'notistack'
import {
  setDataAppointment,
  setDateAppointment,
  changeAppointment,
  getDoctorCalendarMonth,
  setCompleteTransferRecord,
  setIsTransferModal,
} from '../../../../../../redux/doctorSlicer'
import Styles from './style.module.scss'
import { TransferSetup } from './components/TransferSetup'
import { CalendarMobile } from '../../../../../UI/Calendar/CalendarMobile'
import { DoctorPopupMobile } from '../../../../../DoctorModal/components/DoctorPopupMobile'
import { DoctorPopupMobileVH } from '../../../../../DoctorModal/components/DoctorPopupMobileVH'
import backIcon from '@icons/back-mobile.svg'
import closeIcon from '@icons/close-blue.svg'
import { formateDate, getMonth } from '../../../../../../../../utils/formateDate'

interface DoctorRecordingMobileProps {
  title: string
}

export const DoctorTransferMobile: React.FC<DoctorRecordingMobileProps> = ({ title }) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const completeTransferRecord = useAppSelector((state) => state.doctor.completeTransferRecord)
  const transferRecord = useAppSelector((state) => state.doctor.transferRecord)
  const currentPatient = useAppSelector((state) => state.doctor.currentPatient)
  const dateAppointment = useAppSelector((state) => state.doctor.dateAppointment)
  const [currentMonthDate, setCurrentMonthDate] = useState(formateDate(new Date(), 'YYYY-MM-DD'))
  const [currentMonth, setCurrentMonth] = useState([])
  const [selectedDate, setSelectedDate] = useState(null)
  const [formatedDateShort, setFormatedDateShort] = useState(null)
  const [formatedDateDay, setDormatedDateDay] = useState(null)
  const [timeAppointment, setTimeAppointment] = useState(null)
  const [loading, setLoading] = useState(false)
  const [completeRecord, setCompleteRecord] = useState(false)
  const [dayReception, setDayReception] = useState(null)
  const [timeReception, setTimeReception] = useState(null)

  const closeModal = () => dispatch(setIsTransferModal(false))

  const changeMonth = (date) => setCurrentMonthDate(date)

  const onClickDay = (date) => {
    setSelectedDate(date)
    setDayReception(date.day)
  }

  const selectTime = (event) => {
    setTimeAppointment(event.target.value)
    setTimeReception(event.target.id)
  }

  const transferAppointment = async () => {
    try {
      const res = await dispatch(
        changeAppointment({
          id: transferRecord.id,
          data: {
            doctor: transferRecord.doctor.id,
            consultation_type: transferRecord.consultation_type.id,
            day_reception: dayReception,
            time_reception: timeReception,
          },
        }),
      )

      dispatch(setCompleteTransferRecord(res.payload.data))
      dispatch(setDateAppointment(`${formatedDateShort}, ${formatedDateDay}, ${timeAppointment}`))

      setCompleteRecord(true)
    } catch (error) {
      const message = error
      const type = 'error'

      enqueueSnackbar(
        { message, type },
        {
          autoHideDuration: 1500,
        },
      )
    }
  }

  const loadMonth = async (date) => {
    setLoading(true)

    try {
      const dataMonth = getMonth(date)

      const response = await dispatch(
        getDoctorCalendarMonth({ start_date: dataMonth.firstDayMonth, end_date: dataMonth.lastDayMonth }),
      )

      setCurrentMonth(response.payload.data)
      setLoading(false)
    } catch (error) {
      throw new Error(error)
    }
  }

  useEffect(() => {
    loadMonth(currentMonthDate)
  }, [currentMonthDate])

  useEffect(() => {
    setSelectedDate(null)
    setDayReception(null)
    setTimeAppointment(null)
    setTimeReception(null)
  }, [currentMonthDate])

  useEffect(() => {
    if (selectedDate) {
      const dateShort = formateDate(selectedDate.day, 'DD MMM')
      const dateDay = formateDate(selectedDate.day, 'dddd')

      setFormatedDateShort(dateShort)
      setDormatedDateDay(dateDay)
    } else {
      setFormatedDateShort(null)
      setDormatedDateDay(null)

      setTimeAppointment(null)

      dispatch(
        setDataAppointment({
          doctor: 1,
          consultation_type: null,
          is_free: false,
          doctor_appointed: true,
          description: '',
          is_online: true,
          direction: null,
          time_reception: '',
          day_reception: '',
        }),
      )
    }
  }, [selectedDate])

  return (
    <div className={Styles.recording}>
      <div className={Styles.header}>
        <img src={backIcon} alt='Иконка' onClick={closeModal} />
        <span className={Styles.title}>{title}</span>
        <img src={closeIcon} alt='Иконка' onClick={closeModal} />
      </div>
      {!loading && (
        <CalendarMobile
          currentMonthDate={currentMonthDate}
          currentMonth={currentMonth}
          changeMonth={changeMonth}
          selectedDate={selectedDate}
          onClickDay={onClickDay}
        />
      )}
      {selectedDate && (
        <DoctorPopupMobile closePopup={() => setSelectedDate(null)}>
          <TransferSetup
            selectedDate={selectedDate}
            selectTime={selectTime}
            transferAppointment={transferAppointment}
            formatedDateShort={formatedDateShort}
            formatedDateDay={formatedDateDay}
            timeAppointment={timeAppointment}
          />
        </DoctorPopupMobile>
      )}
      {completeRecord && (
        <DoctorPopupMobileVH title='Перенести прием' closePopup={closeModal}>
          <div className={Styles.container}>
            <div className={Styles.wrapperrr}>
              <span className={Styles.warning}>Прием перенесен</span>
              <span className={Styles.desc}>
                {currentPatient.last_name} {currentPatient.first_name.substring(0, 1)}.{' '}
                {currentPatient.middle_name?.substring(0, 1)} записан(а) на прием
              </span>
              <span className={Styles.date}>{dateAppointment}</span>
            </div>
            <Link className={Styles.choose} to={`/appointments/${completeTransferRecord.id}`}>
              Перейти к записи
            </Link>
          </div>
        </DoctorPopupMobileVH>
      )}
    </div>
  )
}
