import React from 'react'
import Styles from './style.module.scss'

interface PopupMobileProps {
  closePopup: any
}

export const PopupMobile: React.FC<PopupMobileProps> = ({ closePopup, children }) => {
  return (
    <div className={Styles.popup}>
      <div className={Styles.container}>
        <button className={Styles.exit} onClick={closePopup}>
          {}
        </button>
        {children}
      </div>
    </div>
  )
}
