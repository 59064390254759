import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAppSelector } from '@app/redux/hooks'
import useWindowSize from '../../../../helpers/useWindowSizeHook'
import Styles from './style.module.scss'
import 'swiper/swiper.scss'

import { DoctorPatientPersona } from './components/DoctorPatientPersona'
import { DoctorPatientPersonaMobile } from './components/DoctorPatientPersona/components/DoctorPatientPersonaMobile'
import { Tooltip } from '../UI/Tooltip'

import caretIcon from '@icons/caret-down.svg'
import VectorDownIcon from '../../../../assets/icons/VectorDownIcon'
import VectorUpIcon from '../../../../assets/icons/VectorUpIcon'
import questionIcon from '@icons/question.svg'

import { formateDate } from '../../../../utils/formateDate'
import { compareDate } from '../../../../utils/compareDate'
import { MOBILE_BREAKPOINT } from '../../constants'

export const DoctorPatientList: React.FC = () => {
  const { width } = useWindowSize()
  const allPatients = useAppSelector((state) => state.doctor.allPatients)
  const [displayPatients, setDisplayPatients] = useState(allPatients)
  const [filter] = useState(['Все', 'Недавно зарегистрированные', 'Без заключения'])
  const [filterIndex, setFilterIndex] = useState(0)
  const [mobileFilter] = useState(['По последнему обращению', 'По имени'])
  const [mobileFilterIndex, setMobileFilterIndex] = useState(0)
  const [displayMobileType, setDisplayMobileType] = useState(false)
  const [sortType, setSortType] = useState('')
  const [sortMobileType, setSortMobileType] = useState('asc')
  // const [currentPage, setCurrentPage] = useState(1)
  // const [patientsPerPage, setPatientsPerPage] = useState(2)

  // const indexOfLastPatient = currentPage * patientsPerPage
  // const indexOfFirstPatient = indexOfLastPatient - patientsPerPage

  const filteringPatients = (index) => {
    setSortType('')
    setSortMobileType('asc')

    if (index === 0) {
      setFilterIndex(index)
      setDisplayPatients(allPatients)
    }

    if (index === 1) {
      const filteredArray = allPatients.filter((item) => {
        const today = formateDate(new Date(), 'YYYY-MM-DD')
        const period = compareDate(today, item.created_at)

        if (period <= 7) {
          return item
        }
      })

      setFilterIndex(index)
      setDisplayPatients(filteredArray)
    }

    if (index === 2) {
      const filteredArray = allPatients.filter((item) => item.last_appointment.medical_report === null)

      setFilterIndex(index)
      setDisplayPatients(filteredArray)
    }
  }

  const sortingPatientsByName = (sortType) => {
    const copyArray = [...displayPatients]

    const isReversed = sortType === 'asc' ? 1 : -1
    const sortedArray = copyArray.sort((a, b) => isReversed * a.first_name.localeCompare(b.first_name))

    const type = sortType === 'asc' ? 'desc' : 'asc'

    setSortType(type)
    setSortMobileType(type)
    setDisplayPatients(sortedArray)
  }

  const sortingPatientsByAppointment = (sortType) => {
    const copyArray = [...displayPatients]

    const isReversed = sortType === 'asc' ? 1 : -1
    const sortedArray = copyArray.sort(
      (a, b) => isReversed * a.last_appointment.day_reception.localeCompare(b.last_appointment.day_reception),
    )

    const type = sortType === 'asc' ? 'desc' : 'asc'

    setSortMobileType(type)
    setDisplayPatients(sortedArray)
  }

  const filteringMobilePatients = (index) => {
    setMobileFilterIndex(index)
    setDisplayMobileType(false)
  }

  const sortingMobilePatients = () => {
    if (mobileFilter[mobileFilterIndex] === 'По последнему обращению') {
      sortingPatientsByAppointment(sortMobileType)
    }

    if (mobileFilter[mobileFilterIndex] === 'По имени') {
      sortingPatientsByName(sortMobileType)
    }
  }

  useEffect(() => {
    setSortMobileType('asc')
    sortingMobilePatients()
  }, [filterIndex, mobileFilterIndex])

  return (
    <>
      {width > MOBILE_BREAKPOINT ? (
        <div className={Styles.patients}>
          <div className={Styles.choose}>
            <ul className={Styles.list}>
              {filter.map((item, index) => (
                <li
                  key={index}
                  className={index === filterIndex ? `${Styles.item} ${Styles.item_active}` : `${Styles.item}`}
                  onClick={() => filteringPatients(index)}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <div className={Styles.table}>
            <div className={Styles.header}>
              <div className={Styles.wrap} onClick={() => sortingPatientsByName(sortType)}>
                <span className={Styles.head}>Имя</span>
                <div className={Styles.vectors}>
                  {sortType === '' && (
                    <>
                      <VectorDownIcon />
                      <VectorUpIcon />
                    </>
                  )}
                  {sortType === 'asc' && (
                    <>
                      <VectorDownIcon active='active' />
                      <VectorUpIcon />
                    </>
                  )}
                  {sortType === 'desc' && (
                    <>
                      <VectorDownIcon />
                      <VectorUpIcon active='active' />
                    </>
                  )}
                </div>
              </div>
              <div className={Styles.wrap}>
                <span className={Styles.head}>Последнее обращение</span>
              </div>
              <div className={Styles.wrap}>
                <span className={`${Styles.head} ${Styles.head_mod}`}>Мед. заключение</span>
                <div className={Styles.icon}>
                  <img src={questionIcon} alt='Иконка' />
                  <Tooltip className={Styles.tooltip}>Описание заключения</Tooltip>
                </div>
              </div>
              <div className={Styles.wrap}>
                <span className={Styles.head}>Номер телефона</span>
              </div>
              <div className={Styles.wrap}>{}</div>
            </div>
            <ul className={Styles.content}>
              {displayPatients.length !== 0 ? (
                <>
                  {displayPatients.map((patient) => (
                    <DoctorPatientPersona
                      key={patient.id}
                      patientId={patient.id}
                      avatar={patient.avatar}
                      birthday={patient.birthday}
                      firstName={patient.first_name}
                      lastName={patient.last_name}
                      phone={patient.phone}
                      lastAppointment={patient.last_appointment}
                    />
                  ))}
                </>
              ) : (
                <span className={Styles.empty}>Пациентов пока нет</span>
              )}
            </ul>
          </div>
        </div>
      ) : (
        <div className={Styles.patients}>
          <div className={Styles.slider}>
            <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={8}>
              {filter.map((item, index) => (
                <SwiperSlide key={index} className={Styles.slide} onClick={() => filteringPatients(index)}>
                  <span className={index === filterIndex ? `${Styles.item} ${Styles.item_active}` : `${Styles.item}`}>
                    {item}
                  </span>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={Styles.select}>
            <div className={Styles.vectors}>
              {sortMobileType === 'asc' && (
                <>
                  <VectorDownIcon active='active' />
                  <VectorUpIcon />
                </>
              )}
              {sortMobileType === 'desc' && (
                <>
                  <VectorDownIcon />
                  <VectorUpIcon active='active' />
                </>
              )}
            </div>
            <span className={Styles.head} onClick={sortingMobilePatients}>
              {mobileFilter[mobileFilterIndex]}
            </span>
            <img
              className={displayMobileType ? `${Styles.caret} ${Styles.caret_rotate}` : `${Styles.caret}`}
              src={caretIcon}
              alt='Иконка'
              onClick={() => setDisplayMobileType(!displayMobileType)}
            />
            {displayMobileType && (
              <ul className={Styles.type}>
                {mobileFilter.map((item, index) => (
                  <li
                    key={item}
                    className={index === mobileFilterIndex ? `${Styles.key} ${Styles.key_active}` : `${Styles.key}`}
                    onClick={() => filteringMobilePatients(index)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <ul className={Styles.content}>
            {displayPatients.map((patient) => (
              <DoctorPatientPersonaMobile
                key={patient.id}
                patientId={patient.id}
                avatar={patient.avatar}
                birthday={patient.birthday}
                firstName={patient.first_name}
                lastName={patient.last_name}
                phone={patient.phone}
                lastAppointment={patient.last_appointment}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  )
}
