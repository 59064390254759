import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { TransitionsModal } from '@components/Modal'
import { getMedicalReportById, getMedicalReportId, setReportsModalOpen } from './redux/reportsSlice'
import closeButton from '@icons/close-gray.svg'
import { FirstStepFieldSet } from './FirstStepFieldset'
import { DietReport } from './DietReport'
import { TabMenu } from './TabMenu'
import { LifestyleReport } from './LifestyleReport'
import { NutraceuticalReport } from './NutraceuticalReport'
import { ResearchReport } from './ResearchReport'
import { FormContextSupplier } from '../../utils/form-storage'
import { FormSpy } from 'react-final-form'
import { ControlReception } from './ControlReception'
import { DoneReport } from './DoneReport'
import { SecondStepFieldSet } from './SecondStepFieldset'

export const MedicalReportsModal = () => {
  const dispatch = useAppDispatch()
  const isModalOpen = useAppSelector((state) => state.reports.modalIsOpen)
  const [medicalReportId, setMedicalReportId] = useState(null)
  const [isAttentionModalOpen, setIsAttentionModalOpen] = useState(false)
  const [activeContent, setActiveContent] = useState('diet')
  const appointmentId = useAppSelector((state) => state.reports.appointmentId)
  const [initialState, setInitialState] = useState(null)
  const [statusReport, setStatusReport] = useState('check-patient')

  useEffect(() => {
    if (appointmentId) {
      dispatch(getMedicalReportId(appointmentId)).then((res) => {
        if (res.payload.data.medical_report) {
          setMedicalReportId(res.payload.data.medical_report.id)
        } else {
          setInitialState(null)
        }
      })
    }
  }, [appointmentId])
  useEffect(() => {
    if (medicalReportId) {
      dispatch(getMedicalReportById(medicalReportId)).then((res) => {
        setInitialState(res.payload.data)
      })
    } else {
      setInitialState(null)
    }
  }, [medicalReportId])

  return (
    <>
      <TransitionsModal isOpenModal={isModalOpen} setModalOpened={() => setIsAttentionModalOpen(true)}>
        <div className={Styles.container}>
          <img
            className={Styles.closeButton}
            onClick={() => setIsAttentionModalOpen(true)}
            src={closeButton}
            alt='close'
          />
          {statusReport === 'check-patient' && <h2>Проверка данных о пациенте</h2>}
          {statusReport === 'results-research' && <h2>Результаты лабораторных обследований</h2>}
          {statusReport === ('create-report' || 'control-reception') && <h2>Создание медицинского заключения</h2>}
          {statusReport === 'check-patient' && (
            <FirstStepFieldSet initialState={initialState} setStatusReport={setStatusReport} />
          )}
          {statusReport === 'results-research' && <SecondStepFieldSet setStatusReport={setStatusReport} />}
          {statusReport === 'done' && (
            <DoneReport setActiveContent={setActiveContent} setStatusReport={setStatusReport} />
          )}
          {statusReport === 'control-reception' && (
            <ControlReception setStatusReport={setStatusReport} initialState={initialState} />
          )}
          {statusReport === 'create-report' && (
            <FormContextSupplier formId={activeContent}>
              <FormSpy>
                {({ form }) => {
                  const isDisabled = form.getState().invalid || !form.getState().pristine
                  return (
                    <TabMenu
                      activeContent={activeContent}
                      isDisabled={isDisabled}
                      setActiveContent={setActiveContent}
                    />
                  )
                }}
              </FormSpy>
            </FormContextSupplier>
          )}
          {statusReport === 'create-report' && (
            <>
              <div className={Styles.note}>
                {activeContent === 'diet' && 'Заполните поля рекомендаций для питания'}
                {activeContent === 'lifeStyle' && 'Выберите рекомендации по образу жизни'}
                {activeContent === 'nutraceutical' && 'Выберите нутрицевтики'}
                {activeContent === 'research' && 'Выберите обследования'}
              </div>
              <>
                {activeContent === 'diet' && <DietReport setActiveContent={setActiveContent} />}
                {activeContent === 'lifeStyle' && <LifestyleReport setActiveContent={setActiveContent} />}
                {activeContent === 'nutraceutical' && <NutraceuticalReport setActiveContent={setActiveContent} />}
                {activeContent === 'research' && <ResearchReport setStatusReport={setStatusReport} />}
                <FormContextSupplier formId={activeContent}>
                  <FormSpy>
                    {({ form }) => {
                      return (
                        <button
                          disabled={form.getState().invalid}
                          onClick={form.submit}
                          className={Styles.button__submit}
                        >
                          Сохранить
                        </button>
                      )
                    }}
                  </FormSpy>
                </FormContextSupplier>
              </>
            </>
          )}
        </div>
      </TransitionsModal>
      <TransitionsModal
        isOpenModal={isAttentionModalOpen}
        setModalOpened={() => setIsAttentionModalOpen(false)}
        onClose={() => setIsAttentionModalOpen(false)}
        style={{ maxWidth: '90%' }}
      >
        <div className={Styles.attentionContainer}>
          <h3>Прекратить создание медицинского заключения?</h3>
          <p>Все несохранённые данные будут потеряны</p>
          <button
            type='button'
            onClick={() => {
              dispatch(setReportsModalOpen(false))
              setIsAttentionModalOpen(false)
              setStatusReport('check-patient')
            }}
          >
            Прекратить
          </button>
          <button className={Styles.cancelButton} type='button' onClick={() => setIsAttentionModalOpen(false)}>
            Отмена
          </button>
        </div>
      </TransitionsModal>
    </>
  )
}
