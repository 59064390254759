import React from 'react'
import Styles from './style.module.scss'

export const ResearchFragment = ({ address, workTimeFrom, workTimeTo, phones }) => {
  return (
    <>
      <p className={Styles.point__address}>{address}</p>
      <p className={Styles.point__calendar}>
        <div>{workTimeFrom}</div>
        <div>{workTimeTo}</div>
      </p>
      <p className={Styles.point__term}>
        {phones.map((time, idx) => {
          return (
            <React.Fragment key={idx}>
              <span>{time}</span>
              <br />
            </React.Fragment>
          )
        })}
      </p>
    </>
  )
}
