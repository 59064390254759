import { Avatar } from '@components/Avatar'
import React from 'react'
import Styles from './styles.module.scss'

export const Dialogue = ({ firstName, lastName, lastMessage, avatar }) => {
  return (
    <div className={Styles.container}>
      <Avatar avatar={avatar} firstName={firstName} lastName={lastName} size='44px' fontSize='20px' />
      <div className={Styles.info}>
        <div className={Styles.name}>{firstName}</div>
        <div className={Styles.status}>{lastMessage}</div>
      </div>
    </div>
  )
}
