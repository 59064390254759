import React from 'react'
import Styles from './style.module.scss'
import arrow from '@icons/arrow-right-blue.svg'

export const ButtonTransparent = (props) => {
  return (
    <div className={Styles.container}>
      {props.before && (
        <img className={`${Styles.arrow_before} ${props.styleImg}`} src={arrow} alt='arrow' onClick={props.onClick} />
      )}
      <button
        className={`${Styles.button} ${props.styleButton || ''}`}
        onClick={props.onClick}
        type={props.type}
        disabled={props.disabled}
      >
        {props.value}
      </button>
      {!props.disabled && props.after && (
        <img className={props.styleImg} src={arrow} alt='arrow' onClick={props.onClick} />
      )}
    </div>
  )
}
