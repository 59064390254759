import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useWindowSize from '@helpers/useWindowSizeHook'
import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import arrowIcon from '@icons/arrow-right-blue.svg'
import 'swiper/swiper.scss'
import Styles from './style.module.scss'
import { ClickableImage } from '@components/Chat/ClickableImage'

interface CatalogPhotosProps {
  title?: string
  button?: string
  small?: boolean
  images: any
}

export const CatalogPhotos: React.FC<CatalogPhotosProps> = (props) => {
  const { title, button, small, images = [] } = props
  const { width } = useWindowSize()

  return (
    <div className={Styles.photos}>
      {title && (
        <div className={Styles.block}>
          {title && <h2 className={Styles.title}>{title}</h2>}
          {button && (
            <>
              {width > 830 ? (
                <ButtonTransparent value={button} />
              ) : (
                <div className={Styles.row}>
                  <ButtonTransparent value={button} />
                  <img src={arrowIcon} alt='Иконка' />
                </div>
              )}
            </>
          )}
        </div>
      )}
      {images?.length > 0 ? (
        width > 830 ? (
          <ul className={Styles.list}>
            {images.slice(0, 8).map((image: { image: string; review: number }) => {
              return (
                <li key={image.image} className={small ? `${Styles.item} ${Styles.item_small}` : `${Styles.item}`}>
                  {/* <img src={`${API_IMAGE_URL}${image.image}`} alt='Изображение' /> */}
                  <ClickableImage key={image.image} src={image.image} filename='' />
                </li>
              )
            })}

            {/* <li
              className={
                small
                  ? `${Styles.item} ${Styles.item_small} ${Styles.item_background}`
                  : `${Styles.item} ${Styles.item_background}`
              }
            >
              <img src={images[8].image.image} alt='Изображение' />
              {small ? <span>+23</span> : <span>+23 фото</span>}
            </li> */}
          </ul>
        ) : (
          <ul className={Styles.list}>
            <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={10}>
              {images.map((image: { image: string; review: number }) => {
                return (
                  <SwiperSlide key={image.image} className={Styles.slide}>
                    <li className={small ? `${Styles.item} ${Styles.item_small}` : `${Styles.item}`}>
                      <img src={`${image.image}`} alt='Изображение' />
                      {/* <ClickableImage key={image.image} src={image.image} filename='' /> */}
                    </li>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </ul>
        )
      ) : null}
    </div>
  )
}
