import React from 'react'
import { FilterToggle } from '../../../../../CatalogFilter/components/FilterToggle'
// import caretIcon from '@icons/vector-down.svg'
import Styles from './style.module.scss'

export const CatalogFeedbackFilter: React.FC = () => {
  return (
    <div className={Styles.filter}>
      <div className={Styles.block}>
        {/* <div className={Styles.select}>
          <span className={Styles.title}>По популярности</span>
          <button className={Styles.caret}>
            <img src={caretIcon} alt='Иконка' />
          </button>
        </div> */}
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>Только с фото</span>
        <div className={Styles.wrapper}>
          <FilterToggle />
        </div>
      </div>
    </div>
  )
}
