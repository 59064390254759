import useWindowSize from '@helpers/useWindowSizeHook'
import React, { useEffect, useState } from 'react'
import { CartSidebar } from '../components/CartSidebar'
import Styles from './style.module.scss'
import { PersonalDataCart } from './components/PersonalDataCart'
import { ButtonColor } from '@components/ui/ButtonColor'
import { Link } from 'react-router-dom'
import { DeliveryCart } from './components/DeliveryCart'
import { DrawerPanel } from '@components/Drawer'
import { Tab } from '../ui/Tab'
import { FormContextSupplier } from '../../../utils/form-storage'
import { FormSpy } from 'react-final-form'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getMyCart, postCartCheckout, postPaymentOrder, deleteCartId } from '../redux/cartSlicer'
import { incrementDate } from '../../../utils/formateDate'
import { getUser } from '../../authorization/redux/authSlice'
import { AnalysisCart } from './components/AnalysisCard'
import { makeAnchorScroll } from '../../../utils/makeAnchorScroll'

export const GoCheckout = () => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const desktop = width >= 829
  const { dataOrder } = useAppSelector((state) => state.cart)
  const { dataDelivery } = useAppSelector((state) => state.delivery)
  const cartData = useAppSelector((state) => state.cart.cartData)
  const [addressDelivery, setAddressDelivery] = useState('')
  const [dateDelivery, setDateDelivery] = useState('')
  const [sumDelivery, setSumDelivery] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)

  const [activeField, setActiveField] = useState(1)
  const [firstFieldFull, setFirstFieldFull] = useState(false)
  const [secondFieldFull, setSecondFieldFull] = useState(false)
  const [thirdFieldFull, setThirdFieldFull] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fourthFieldFull, setFourthFieldFull] = useState(false)
  const [showMobileStep, setShowMobileStep] = useState(false)
  const [preparation, setPreparation] = useState([])
  const [product, setProduct] = useState([])
  const [analysis, setAnalysis] = useState([])

  useEffect(() => {
    if (!desktop) {
      if (activeField === 1) {
        makeAnchorScroll('#recipient')
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
      if (activeField === 2) {
        makeAnchorScroll('#delivery')
      }
      if (activeField === 3) {
        makeAnchorScroll('#lab')
      }
      if (activeField === 4) {
        makeAnchorScroll('#payment')
      }
    }
  }, [activeField])

  const goToDelivery = () => {
    setActiveField(2)
    setFirstFieldFull(true)
    setShowMobileStep(false)
  }
  const data: any = {
    full_name: `${dataOrder.name} ${dataOrder.last_name}`,
    email: dataOrder.email || '',
    phone: dataOrder.phone,
  }
  const [labAdress, setLabAdress] = useState(null)
  const goToPay = async () => {
    if (product.length > 0 && analysis.length > 0) {
      setThirdFieldFull(true)
      setActiveField(4)
    } else {
      setSecondFieldFull(true)
      setActiveField(3)
    }
    if (analysis.length > 0) {
      data.lab = dataDelivery.lab
      setLabAdress(dataDelivery.lab_adress)
    }
    savedDataDelivery()
    try {
      const resOrder = await dispatch(postCartCheckout(data))
      const resPayment = await dispatch(postPaymentOrder({ order: resOrder.payload.id }))
      window.location.replace(resPayment.payload.url)
    } catch (error) {
      // console.log(error)
    }
  }
  const goToResearch = () => {
    setActiveField(3)
    if (product.length > 0) {
      setSecondFieldFull(true)
    }
    setShowMobileStep(false)
  }
  const savedDataDelivery = () => {
    data.comment = dataDelivery.comment || ''
    if (product.length > 0) {
      if (dataDelivery.type === 'courier') {
        const addressCourier = `ул. ${dataDelivery.courierData.street}, д. ${dataDelivery.courierData.building}${
          dataDelivery.courierData.korpus && '/'
        }${dataDelivery.courierData.korpus}${dataDelivery.courierData.door && ', подъезд'} ${
          dataDelivery.courierData.door
        }${dataDelivery.courierData.floor && ', этаж'} ${dataDelivery.courierData.floor}${
          dataDelivery.courierData.appartment && ', кв.'
        } ${dataDelivery.courierData.appartment}`

        setAddressDelivery(addressCourier)

        data.to_address = addressCourier
        data.to_pvz = ''
        data.pvz_address = ''
        data.type = dataDelivery.period[1].type
        data.period_min = dataDelivery.period[1].period_min
        data.period_max = dataDelivery.period[1].period_max
        data.delivery_sum = dataDelivery.period[1].delivery_sum
        const periodMin = incrementDate(dataDelivery.period[1].period_min, 'DD MMMM')
        const periodMax = incrementDate(dataDelivery.period[1].period_max, 'DD MMMM')

        setDateDelivery(`${periodMin} - ${periodMax}`)
        setSumDelivery(dataDelivery.period[1].delivery_sum)
      } else {
        const addressPoint = `СДЕК, ${dataDelivery.pointData.location.address}`

        setAddressDelivery(addressPoint)

        data.to_address = ''
        data.to_pvz = dataDelivery.pointData.code
        data.pvz_address = addressPoint
        data.type = dataDelivery.period[0].type
        data.period_min = dataDelivery.period[0].period_min
        data.period_max = dataDelivery.period[0].period_max
        data.delivery_sum = dataDelivery.period[0].delivery_sum
        const periodMin = incrementDate(dataDelivery.period[1].period_min, 'DD MMMM')
        const periodMax = incrementDate(dataDelivery.period[1].period_max, 'DD MMMM')

        setDateDelivery(`${periodMin} - ${periodMax}`)
        setSumDelivery(dataDelivery.period[0].delivery_sum)
      }
    }
  }
  const renderTitle = () => {
    if (dataDelivery.type === 'point') {
      return 'Доставка в пункт выдачи'
    } else if (dataDelivery.type === 'courier') {
      return 'Доставка курьером'
    } else if (dataDelivery.type === 'post') {
      return 'Доставка почтой'
    } else {
      return ''
    }
  }

  const changeHandlerPersonalData = () => {
    dispatch(getUser())
    setShowMobileStep(true)
    setActiveField(1)
    if (desktop) {
      setFirstFieldFull(false)
      setSecondFieldFull(false)
      setThirdFieldFull(false)
    }
  }

  const changeHandlerDelivery = () => {
    setShowMobileStep(true)
    setActiveField(2)
    if (desktop) {
      setSecondFieldFull(false)
      setThirdFieldFull(false)
    }
  }
  const changeHandlerAnalysis = () => {
    setShowMobileStep(true)
    setActiveField(3)
    if (desktop) {
      setThirdFieldFull(false)
    }
  }
  const openDrawer = (e) => {
    if (
      (!desktop && e.currentTarget.dataset.id === activeField.toString()) ||
      e.currentTarget.dataset.id === activeField.toString()
    ) {
      setShowMobileStep(true)
    }
  }

  useEffect(() => {
    if (dataDelivery.type === '') {
      setIsDisabled(true)
    } else if (dataDelivery.type === 'point' && (!dataDelivery.pointData.code || !dataDelivery.pointData.location)) {
      setIsDisabled(true)
    } else if (
      dataDelivery.type === 'courier' &&
      (!dataDelivery.courierData.street || !dataDelivery.courierData.building)
    ) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [dataDelivery])

  useEffect(() => {
    dispatch(getMyCart())
  }, [])

  useEffect(() => {
    dispatch(getUser())
  }, [])
  const [trigger, setTriger] = useState(false)
  useEffect(() => {
    if (cartData) {
      const a = [...cartData?.analyzes, ...cartData?.analysis_sets]
      a.map((item) => {
        if (item.preparation_needed) {
          setPreparation([...preparation, item])
        }
      })
      setProduct([...cartData?.products, ...cartData?.product_sets])
      setAnalysis([...cartData?.analyzes, ...cartData?.analysis_sets])
    }
  }, [cartData, trigger])
  const deleteAnalysis = () => {
    setTriger(!trigger)
    if (cartData.analysis_sets.length > 0) {
      cartData.analysis_sets.map((item) => {
        dispatch(deleteCartId({ analysis_set: item.id }))
      })
    }
    if (cartData.analyzes.length > 0) {
      cartData.analyzes.map((item) => {
        dispatch(deleteCartId({ analysis: item.id }))
      })
    }
    setShowMobileStep(false)
  }

  return (
    <>
      {product.length > 0 || analysis.length > 0 ? (
        <>
          <div className={Styles.title}>
            <h1>Оформление заказа</h1>
            <Link to='/cart' className={Styles.button__link}>
              Вернуться в корзину
            </Link>
          </div>
          <div className={Styles.container}>
            <div className={Styles.right}>
              <div className={Styles.content}>
                <Tab
                  tabId='recipient'
                  activeField={activeField}
                  fieldFull={firstFieldFull}
                  title='Данные получателя'
                  text='укажите личные данные'
                  fieldNumber={1}
                  changeHandler={changeHandlerPersonalData}
                  openDrawer={openDrawer}
                  button={
                    <div className={Styles.buttons__container}>
                      <FormContextSupplier formId='personal_data'>
                        <FormSpy>
                          {({ form }) => {
                            return (
                              <ButtonColor
                                value={product.length > 0 ? 'Перейти к доставке' : 'Перейти к выбору лаборатории'}
                                disabled={form.getState().invalid}
                                onClick={() => {
                                  form.submit()
                                  goToDelivery()
                                }}
                                className={Styles.button__submit}
                              />
                            )
                          }}
                        </FormSpy>
                      </FormContextSupplier>
                      <p className={Styles.text}>
                        Нажимая на кнопку, я соглашаюсь{` `}
                        <span>
                          <Link to='/personal-data' className={Styles.link}>
                            с пользовательским соглашением
                          </Link>
                        </span>
                      </p>
                    </div>
                  }
                  form={<PersonalDataCart />}
                  result={
                    <div className={Styles.fullField}>
                      <label className={Styles.fullField__label}>Телефон</label>
                      <p className={Styles.fullField__text}>
                        {dataOrder.otherPhone ? dataOrder.otherPhone : dataOrder.phone}
                      </p>
                      <label className={Styles.fullField__label}>Имя Фамилия</label>
                      <p className={Styles.fullField__text}>{`${dataOrder.name} ${dataOrder.last_name}`}</p>
                      <label className={Styles.fullField__label}>Email</label>
                      <p className={Styles.fullField__text}>{dataOrder.email}</p>
                    </div>
                  }
                />

                {product.length > 0 && (
                  <Tab
                    tabId='delivery'
                    activeField={activeField}
                    fieldFull={secondFieldFull}
                    title={renderTitle() || 'Доставка'}
                    text='выберите способ и адрес доставки'
                    fieldNumber={2}
                    openDrawer={openDrawer}
                    changeHandler={changeHandlerDelivery}
                    button={
                      <div className={Styles.buttons__container}>
                        <ButtonColor
                          onClick={() => {
                            savedDataDelivery()
                            analysis.length > 0 ? goToResearch() : goToPay()
                          }}
                          value={analysis.length > 0 ? 'Перейти к выбору лаборатории' : 'Перейти к оплате'}
                          disabled={isDisabled}
                        />
                        <p className={Styles.text}>
                          {analysis.length > 0
                            ? ''
                            : 'После нажатия на кнопку вы попадете на страницу банка для оплаты'}
                        </p>
                      </div>
                    }
                    form={<DeliveryCart />}
                    result={
                      <div className={Styles.fullField}>
                        <label className={Styles.fullField__label}>Адрес</label>
                        <p className={Styles.fullField__text}>{addressDelivery}</p>
                        <label className={Styles.fullField__label}>Дата и интервал доставки</label>
                        <p className={Styles.fullField__text}>{dateDelivery}</p>
                        <label className={Styles.fullField__label}>Стоимость</label>
                        <p className={Styles.fullField__text}>{sumDelivery} ₽</p>
                      </div>
                    }
                  />
                )}
                {analysis.length > 0 && (
                  <Tab
                    tabId='lab'
                    activeField={activeField}
                    fieldFull={product.length > 0 ? thirdFieldFull : secondFieldFull}
                    title='Выбор лаборатории'
                    text='выберите лабораторию в которой хотите пройти обследование'
                    fieldNumber={product.length > 0 ? 3 : 2}
                    openDrawer={openDrawer}
                    changeHandler={product.length > 0 ? changeHandlerAnalysis : changeHandlerDelivery}
                    button={
                      <div className={Styles.buttons__container}>
                        <ButtonColor onClick={goToPay} value='Перейти к оплате' disabled={!dataDelivery.lab} />
                        <p className={Styles.text}>После нажатия на кнопку вы попадете на страницу банка для оплаты</p>
                      </div>
                    }
                    form={<AnalysisCart deleteAnalysis={deleteAnalysis} />}
                    result={
                      <div className={Styles.fullField}>
                        <label className={Styles.fullField__label}>Адрес</label>
                        <p className={Styles.fullField__text}>{labAdress}</p>
                      </div>
                    }
                  />
                )}

                <Tab
                  tabId='payment'
                  activeField={activeField}
                  fieldFull={
                    product.length > 0 && analysis.length > 0
                      ? fourthFieldFull
                      : (product.length > 0 || analysis.length) && thirdFieldFull
                  }
                  title='Оплата'
                  text='оплата происходит на странице банка партнера'
                  pay
                  fieldNumber={
                    product.length > 0 && analysis.length > 0 ? 4 : product.length > 0 || analysis.length ? 3 : 2
                  }
                  form={<p className={Styles.text}>После нажатия на кнопку вы попадете на страницу банка для оплаты</p>}
                  button={
                    <div className={Styles.buttons__container}>
                      <ButtonColor className={Styles.button} onClick={goToPay} value='Перейти к оплате' />
                    </div>
                  }
                />
              </div>
            </div>
            {!desktop && (
              <div className={Styles.button_mob}>
                <ButtonColor
                  className={Styles.button}
                  onClick={goToPay}
                  value='Оплатить'
                  disabled={!data.full_name || !data.email || !data.phone || isDisabled}
                />
              </div>
            )}
            {cartData && <CartSidebar activeField={activeField} />}
          </div>
          {!desktop && (
            <DrawerPanel
              width='100%'
              anchor='right'
              open={showMobileStep}
              onClose={() => setShowMobileStep((isOpen) => !isOpen)}
            >
              <div className={Styles.drawer__container}>
                <div className={Styles.navigation}>
                  <button className={Styles.arrow} onClick={goToDelivery} />
                  <h3 className={Styles.title}>
                    {(activeField === 1 && 'Данные получателя') ||
                      (product.length > 0 && activeField === 2 && 'Доставка') ||
                      ((product.length === 0 || activeField === 3) && 'Выбор лаборатории')}
                  </h3>
                </div>
                <div className={Styles.drawer__content}>
                  {activeField === 1 && (
                    <>
                      <PersonalDataCart />
                      <div className={Styles.buttons__container}>
                        <FormContextSupplier formId='personal_data'>
                          <FormSpy>
                            {({ form }) => {
                              return (
                                <ButtonColor
                                  value='Перейти к доставке'
                                  disabled={form.getState().invalid}
                                  onClick={() => {
                                    form.submit()
                                    goToDelivery()
                                  }}
                                  className={Styles.button__submit}
                                />
                              )
                            }}
                          </FormSpy>
                        </FormContextSupplier>
                        <p className={Styles.text}>
                          Нажимая на кнопку, я соглашаюсь{` `}
                          <span>
                            <Link to='/personal-data' className={Styles.link}>
                              с пользовательским соглашением
                            </Link>
                          </span>
                        </p>
                      </div>
                    </>
                  )}
                  {product.length > 0 && activeField === 2 && (
                    <DeliveryCart
                      goToPay={goToPay}
                      goToResearch={goToResearch}
                      analysis={analysis.length}
                      savedDataDelivery={savedDataDelivery}
                    />
                  )}
                  {activeField === (product.length > 0 ? 3 : 2) && (
                    <AnalysisCart goToPay={goToPay} deleteAnalysis={deleteAnalysis} />
                  )}
                </div>
              </div>
            </DrawerPanel>
          )}
        </>
      ) : (
        <div className={Styles.empty}>
          <p>
            В корзине пока ничего нет. Вы можете посмотреть что-нибудь в&nbsp;
            <Link to='/catalog'>каталоге</Link>
            &nbsp;или воспользоваться поиском
          </p>
        </div>
      )}
    </>
  )
}
