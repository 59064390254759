import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import paperclip from '@icons/chat-paperclip.svg'
import { useChat } from '../utils/useChat'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { uploadFile, setUploadedFile } from '../redux/chatSlice'
import { AttachedFile } from '../AttachedFile'

export const MessageInput = () => {
  const dispatch = useAppDispatch()
  const [message, setMessage] = useState('')
  const [attachedFile, setAttachedFile] = useState(null)
  const currentSlug = useAppSelector((state) => state.chat.currentSlug)
  const uploading = useAppSelector((state) => state.chat.uploading)
  const { sendGroupMessage, getGroups } = useChat()

  const handleChange = (e) => setMessage(e.target.value)

  const handleFileInput = async (e) => {
    setAttachedFile(e.target.files[0])
  }

  const upload = async () => {
    const data = new FormData()
    data.append('file', attachedFile)
    dispatch(uploadFile(data))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    sendGroupMessage(currentSlug, message)
    setMessage('')
    setAttachedFile(null)
    dispatch(setUploadedFile([]))
    getGroups()
  }

  useEffect(() => {
    if (attachedFile) {
      upload()
    }
  }, [attachedFile])

  return (
    <div className={Styles.wrapper}>
      {attachedFile && !uploading && (
        <AttachedFile name={attachedFile.name} size={attachedFile.size} date={attachedFile.last_modified} />
      )}
      <form className={Styles.container} onSubmit={handleSubmit}>
        <label>
          <input type='file' onChange={handleFileInput} style={{ display: 'none' }} />
          <img src={paperclip} alt='add' className={Styles.image} />
        </label>
        <input
          type='text'
          name='message'
          value={message}
          onChange={handleChange}
          className={Styles.input}
          placeholder={uploading ? 'Загрузка файла...' : 'Введите сообщение'}
        />
        {(message || attachedFile) && (
          <button disabled={uploading} type='submit' className={Styles.sendButton} onClick={handleSubmit} />
        )}
      </form>
    </div>
  )
}
