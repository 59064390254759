import React from 'react'
import { useHistory } from 'react-router-dom'
import backIcon from '@icons/back.svg'
import Styles from './style.module.scss'

export const CatalogMain: React.FC = (props) => {
  const { children } = props
  const history = useHistory()

  const goBack = () => {
    history.goBack()
  }

  return (
    <div className={Styles.main}>
      <div className={Styles.wrapper}>
        <div className={Styles.link} onClick={goBack}>
          <img src={backIcon} alt='Иконка' />
          <span>Назад</span>
        </div>
      </div>
      {children}
    </div>
  )
}
