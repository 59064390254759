import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

/* export const getRegions: any = createAsyncThunk('cdek/getRegions', async function () {
  const response = await api.get(`cdek/regions/regions-api/`)
  return response.data
}) */

/* export const getCitiesByRegion: any = createAsyncThunk('cdek/getCitiesByRegion', async function (data: any) {
  const response = await api.get(`cdek/cities/cities-api/?region_code=${data.regionCode}`)
  return response.data
}) */

export const getPointsByCity: any = createAsyncThunk('cdek/getPointsByCity', async function (data: any) {
  const response = await api.get(`cdek/delivery-point/delivery-point-api/?city_code=${data.cityCode}`)
  return response.data
})

export const getPoint: any = createAsyncThunk('cdek/getPoint', async function (code: any) {
  const response = await api.get(`cdek/delivery-point/?${code}`)
  return response.data
})

export const defaultState = {
  type: '',
  city: '',
  points: [],
  period: [],
  lab: null,
  pointData: {
    code: '',
    location: '',
  },
  courierData: {
    street: '',
    building: '',
    korpus: '',
    appartment: '',
    postal: '',
    door: '',
    floor: '',
  },
  comment: '',
}

const deliverySlice = createSlice({
  name: 'cdek',
  initialState: {
    dataDelivery: { ...defaultState } as any,
    cityCode: null,
    city: null,
  },
  reducers: {
    setDataDelivery(state, { payload }) {
      state.dataDelivery = payload
    },
    setCityCode(state, { payload }) {
      state.cityCode = payload
    },
    setCity(state, { payload }) {
      state.city = payload
    },
  },
  extraReducers: () => {},
})
export const { setDataDelivery, setCityCode, setCity } = deliverySlice.actions
export default deliverySlice.reducer
