import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getPosts, getScopes, getStories } from '../redux/blogSlice'
import { useEffect } from 'react'

export const useBlog = () => {
  const dispatch = useAppDispatch()
  const filter = useAppSelector((state) => state.blog.filter)
  const ordering = useAppSelector((state) => state.blog.ordering)

  useEffect(() => {
    dispatch(getPosts({ ...filter, ordering }))
  }, [filter, ordering])

  useEffect(() => {
    dispatch(getScopes())
  }, [])

  useEffect(() => {
    dispatch(getStories())
  }, [])

  return useBlog
}
