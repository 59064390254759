import React, { useRef } from 'react'
import Styles from './style.module.scss'
import { YMaps, Map, ObjectManager } from 'react-yandex-maps'
// import icon from '@icons/map.svg'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setDataDelivery } from '../../../redux/deliverySlice'

const modules = ['layout.ImageWithContent', 'GeoObjectCollection', 'Placemark']

const iconContent = (routeId) =>
  `<div style='color:black;
     display: inline-block;
     font-size: 11px;
     font-weight: bold;
     line-height: 20px;
     padding: 0 5px;
     background: #FFF;
     margin-top: 1px;
     margin-left: 30px;
     opacity: 1;
     border-radius: 5px;
     -moz-border-radius: 5px;
     -webkit-border-radius: 5px;'>` +
  routeId +
  `</div>`

interface IComponent {
  setModalOpened?: any
}

export const MapComponent: React.FC<IComponent> = (props) => {
  const { setModalOpened } = props
  const { dataDelivery } = useAppSelector((state) => state.delivery)
  const defaultState = {
    center: [dataDelivery.points[0].location.latitude, dataDelivery.points[0].location.longitude],
    zoom: 12,
  }
  const dispatch = useAppDispatch()

  // Используем хук для получения ссылки на карту.
  const ymaps = useRef(null)

  const choosePointHandler = (event) => {
    if (!event._defaultPrevented) {
      dispatch(
        setDataDelivery({
          ...dataDelivery,
          pointData: {
            ...dataDelivery.pointData,
            code: event._sourceEvent._sourceEvent.originalEvent.objectId,
            location: event._sourceEvent._sourceEvent.originalEvent.overlay._data.point.location,
          },
        }),
      )

      setModalOpened()
    }
  }

  function CustomSearchProvider(points) {
    this.points = points
  }

  CustomSearchProvider.prototype.geocode = function (request, options) {
    const deferred = ymaps.current && ymaps.current.vow.defer()
    const geoObjects = ymaps.current && new ymaps.current.GeoObjectCollection()

    const offset = options.skip || 0
    const limit = options.results || 20

    let points = []
    for (let i = 0, l = this.points.length; i < l; i++) {
      const point = this.points[i]

      if (point.name.toLowerCase().indexOf(request.toLowerCase()) !== -1) {
        points.push(point)
      }
    }

    // При формировании ответа можно учитывать offset и limit.
    points = points.splice(offset, limit)

    // Добавляем точки в результирующую коллекцию.
    for (let i = 0, l = points.length; i < l; i++) {
      const point = points[i]
      // eslint-disable-next-line camelcase
      const { longitude, latitude, address_full } = point.location
      const coordinates = [latitude, longitude]
      const { name, code } = point

      geoObjects.add(
        new ymaps.current.Placemark(coordinates, {
          name: `${code} ${name} `,
          // eslint-disable-next-line camelcase
          description: `${address_full}`,
          balloonContentBody: `<p>${name}</p>`,
          boundedBy: [coordinates, coordinates],
        }),
      )
    }

    deferred.resolve({
      // Геообъекты поисковой выдачи.
      geoObjects,
      // Метаинформация ответа.
      metaData: {
        geocoder: {
          // Строка обработанного запроса.
          request,
          // Количество найденных результатов.
          found: geoObjects.getLength(),
          // Количество возвращенных результатов.
          results: limit,
          // Количество пропущенных результатов.
          skip: offset,
        },
      },
    })
    // Возвращаем объект-обещание.
    return deferred.promise()
  }

  const dataConvert = (points) => {
    const features = []
    points &&
      points.map((point) => {
        const lat = point.location.latitude
        const lon = point.location.longitude
        const tmpObj = {
          type: 'Feature',
          id: point.code,
          point,
          geometry: {
            type: 'Point',
            coordinates: [lat, lon],
          },
          properties: {
            iconContent: iconContent(point.name),
          },
        }
        return features.push(tmpObj)
      })
    return features
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <YMaps>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Map
          defaultState={defaultState}
          className={Styles.map}
          modules={modules}
          onLoad={(api) => {
            ymaps.current = api
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {/* <SearchControl
            options={{
              float: 'left',
              maxWidth: 190,
              noPlacemark: true,
              provider: new CustomSearchProvider(points),
              resultsPerPage: 5,
            }}
          /> */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <ObjectManager
            options={{
              clusterize: true,
              gridSize: 150,
            }}
            features={dataConvert(dataDelivery.points)}
            onClick={choosePointHandler}
          />
        </Map>
      </YMaps>
    </>
  )
}
