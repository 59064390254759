import React, { useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { useParams } from 'react-router'
import { CatalogMain } from '../CatalogMain'
import { CatalogPreparation } from './components/CatalogPreparation'
import { CatalogContent } from '../CatalogContent'
import { CatalogDescription } from './components/CatalogDescription'
// import { CatalogInterpretation } from './components/CatalogInterpretation'
import { CatalogHow } from './components/CatalogHow'
import { CatalogCard } from './components/CatalogCard'
// import { CatalogInvitro } from './components/CatalogInvitro'
import { CatalogList } from '../CatalogList'
import Styles from './style.module.scss'
import { getAnalyzesById } from '../../../../pages/catalog/redux/productSlice'
import { getLastViewed } from '../../../../pages/catalog/redux/catalogSlicer'
import { Loader } from '../../../../pages/doctor/components/UI/Loader'
import sctollToTop from '@helpers/scrollToTop'

export const CatalogPageResearch: React.FC = () => {
  const component = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const analyze = useAppSelector((state) => state.product.analyze)
  const { listLastViewed } = useAppSelector((state) => state.catalog)
  useEffect(() => {
    analyze && sctollToTop(component, 0)
  }, [analyze])

  type Params = {
    id: string
  }

  const { id } = useParams<Params>()

  useEffect(() => {
    dispatch(getAnalyzesById(id))
    dispatch(getLastViewed())
  }, [])

  return analyze ? (
    <CatalogMain>
      <div className={Styles.page} ref={component}>
        <div className={Styles.column}>
          <div className={Styles.container}>
            <CatalogPreparation prepare_text={analyze.prepare_text} />
            <CatalogContent title='Описание'>
              <CatalogDescription description={analyze.description} />
            </CatalogContent>
            {/* <CatalogContent title='Интерпретация результатов'>
              <CatalogInterpretation />
            </CatalogContent> */}
            {/* <CatalogContent title='Исследование входит в комплекс'>
              <CatalogIncluded />
            </CatalogContent> */}
          </div>
          <div className={Styles.container}>
            <CatalogHow />
            <CatalogCard analyze={analyze} />
            {/* <CatalogInvitro /> */}
          </div>
        </div>
        {listLastViewed.length > 0 && (
          <CatalogContent title='Вы недавно смотрели'>
            <CatalogList items={listLastViewed} />
          </CatalogContent>
        )}
      </div>
    </CatalogMain>
  ) : (
    <Loader />
  )
}
