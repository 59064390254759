import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'

import { DoctorReception } from './components/DoctorReception'

import { formateDate } from '../../../../../../../../utils/formateDate'

interface DoctorRecordWeekProps {
  countAppointment: number
  day: string
  isAvailable: boolean
  receptions: any
}

export const DoctorRecordWeek: React.FC<DoctorRecordWeekProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { countAppointment, day, isAvailable, receptions } = props

  const [currentReceptions, setCurrentReceptions] = useState([])

  useEffect(() => {
    const futureReception = []
    const pastReception = []

    receptions.forEach((reception) => {
      if ((reception.is_past && reception.appointment) || (reception.is_past && !reception.appointment)) {
        pastReception.push(reception)
      }

      if ((!reception.is_past && reception.appointment) || (!reception.is_past && !reception.appointment)) {
        futureReception.push(reception)
      }
    })

    const futureSortedArray = futureReception.sort((a, b) => a.time.localeCompare(b.time))
    const doneSortedArray = pastReception.sort((a, b) => a.time.localeCompare(b.time))

    setCurrentReceptions([...futureSortedArray, ...doneSortedArray])
  }, [])

  return (
    <div className={Styles.record}>
      <div className={Styles.block}>
        <span className={Styles.date}>
          {formateDate(day, 'DD MMMM')}, {formateDate(day, 'dd')}
        </span>
      </div>
      <div className={Styles.block}>
        <span className={Styles.count}>{countAppointment}</span>
      </div>
      <div className={Styles.block}>
        {currentReceptions?.map((reception) => (
          <DoctorReception
            key={reception.id}
            appointment={reception.appointment}
            isBlacklist={reception.is_blacklist}
            isPast={reception.is_past}
            time={reception.time}
          />
        ))}
      </div>
    </div>
  )
}
