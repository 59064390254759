import sctollToTop from '@helpers/scrollToTop'
import React, { useEffect, useRef } from 'react'
import { GoBackButton } from '../GoBackButton'
import Styles from './style.module.scss'

export const PersonalDataPage = (): React.ReactElement => {
  const component = useRef<HTMLDivElement>(null)
  useEffect(() => {
    sctollToTop(component, 90)
  }, [])
  return (
    <div className={Styles.container} ref={component}>
      <GoBackButton />
      <h2 className={Styles.header}>Пользовательское соглашение</h2>
      <p>
        Настоящий документ «Пользовательское соглашение» представляет собой предложение Общества с ограниченной
        ответственностью «ВИВАМЕД» (далее — ООО «ВИВАМЕД») на основании статьи 428 Гражданского Кодекса Российской
        Федерации (далее –«ГК РФ») заключить договор на изложенных ниже условиях. ООО «ВИВАМЕД» именуемое в дальнейшем
        «Владелец», в лице генерального директора Салимовой Натальи Викторовны, действующего на основании Устава,
        настоящим предлагает любому правоспособному и дееспособному физическому лицу, чья воля будет выражена им лично
        либо через представителя (ст. 182, 185 Гражданского кодекса), именуемому в дальнейшем «Пользователь, при
        совместном упоминании в дальнейшем по тексту Соглашения именуемые «Стороны», а по отдельности — «Сторона»,
        принять данное соглашение на условиях, изложенных ниже (далее — «Соглашение»).
      </p>
      <p>В соответствии со ст. 435, 437 ГК РФ, настоящее Пользовательское соглашение признается офертой.</p>
      <p>
        Настоящее Соглашение, заключаемое путем акцепта настоящей оферты, не требует двустороннего подписания и
        действительно в электронном виде.
      </p>
      <p>
        В соответствии со статьей 438 ГК РФ, безусловным принятием (акцептом) условий настоящего Соглашения считается
        факт регистрации на сайте в сети Интернет по адресу: persolab.ru.
      </p>
      <p>
        Также, настоящее Соглашение определяет условия использования материалов и сервисов, размещенных на сайте в сети
        Интернет по адресу: persolab.ru посетителями и пользователями данного интернет-Сайта (далее – Сайт).
      </p>
      <h4>1. Основные понятия и определения</h4>
      <p>
        В Соглашении, если из текста Соглашения прямо не вытекает иное, следующие слова и выражения будут иметь
        указанные ниже значения:
      </p>
      <p>
        <span>Сайт/Сайты</span> – совокупность размещенных в сети Интернет веб-страниц, объединенных единой темой,
        дизайном и единым адресным пространством домена persolab.ru.
      </p>
      <p>
        1.1. Стартовая страница Сайта, посредством которой может быть осуществлен доступ ко всем остальным веб-страницам
        Сайта, размещена в сети Интернет по адресу persolab.ru, а также любая из автоматизированных информационных
        систем, доступных в сети Интернет по сетевым адресам в следующих доменах (включая поддомены): ru.
      </p>
      <p>
        1.2. <span>Устройство</span> — персональный компьютер, планшет, мобильный телефон, коммуникатор, смартфон, иное
        устройство, позволяющее использовать Сервис по его функциональному назначению.
      </p>
      <p>
        1.3. <span>Контент</span> — изображения, текстовые, аудио- и видеоматериалы, а также прочие объекты авторских и
        (или) смежных прав, а равно не являющиеся таковыми информация и сообщения любого характера.
      </p>
      <p>
        1.4. <span>Пользователь</span> – дееспособное физическое лицо, присоединившееся к настоящему Соглашению в
        собственном интересе либо выступающее от имени и в интересах представляемого им юридического лица.
      </p>
      <p>
        1.5. <span>Администратор/Владелец Сайта</span> – Общество с ограниченной ответственностью «ВИВАМЕД».
      </p>
      <p>
        1.6. <span>Администрация Сайта (Администрация)</span> – лица, уполномоченные Администратором Сайта на
        осуществление управления Сайтом и иные действия, связанные с его использованием. Администрация Сайта действует
        от имени Администратора Сайта, если иное не будет указано отдельно.
      </p>
      <p>
        1.7. <span>Персональная страница Пользователя</span> – раздел Сайта, который может быть доступен Пользователю
        после регистрации, посредством которого Пользователь осуществляет управление своей учетной записью (аккаунтом),
        информации, отправку и получение личных сообщений и иные действия, связанные с использованием Сайта.
      </p>
      <p>
        1.8. <span>Личные сообщения</span> – электронные сообщения, передаваемые одним Пользователем другому и не
        доступные третьим лицам, отправка и получение которых осуществляется с использованием Персональной страницы
        Пользователя.
      </p>
      <p>1.9. Понятия «учетная запись» и «аккаунт» равнозначны.</p>
      <p>
        1.10. <span>Соглашение</span> — настоящий документ со всеми дополнениями, изменениями и указанными в нем
        обязательными документами, а также договор, заключенный на его основании.
      </p>
      <p>
        1.11. В настоящем Соглашении могут быть использованы иные термины и определения, не указанные в настоящей статье
        Соглашения. В этом случае толкование такого термина производится в соответствии с текстом Соглашения.
      </p>
      <h4>2. Общие условия</h4>
      <p>
        2.1. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской
        Федерации.
      </p>
      <p>
        2.2. Настоящее Соглашение является публичной офертой. Получая доступ к личного кабинета Сайта, Пользователь
        считается присоединившимся к настоящему Соглашению.
      </p>
      <p>
        Обязательным условием использования Сайта и предоставления на его основе Сервиса является полное и
        безоговорочное принятие Пользователем условий следующих документов (по тексту Соглашения — «обязательные
        документы»):
      </p>
      <p>— Политики конфиденциальности, размещенной и/или доступной в сети Интернет по адресу https://persolab.ru/</p>
      <p>— Условия возврата, размещенной и/или доступной в сети Интернет по адресу https://persolab.ru/</p>
      <p>
        2.3. Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения,
        включая относящиеся к нему обязательные документы, без какого-либо специального уведомления. Такие изменения
        вступают в силу с момента размещения новой версии Соглашения, включая относящиеся к нему обязательные документы,
        на сайте. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту,
        прекратить использование материалов и сервисов Сайта.
      </p>
      <p>Действующая редакция Соглашения опубликована на странице по адресу https://persolab.ru/.</p>
      <p>
        2.4. Настоящее Соглашение регулирует отношения между Администратором Сайта в лице Администрации Сайта и
        Пользователями Сайта, возникающие в связи с использованием Сайта.
      </p>
      <p>
        2.5. В случае, если отдельные вопросы не урегулированы настоящим Соглашением или иным соглашением сторон, к
        отношениям сторон подлежит применению право Российской Федерации.
      </p>
      <p>
        2.6. Консультанты и Администрация Сайта ведут работу над тем, чтобы как можно более грамотно, четко, полно и
        своевременно донести информацию до Пользователей. Администрация регулярно рассматривает научно-практические
        новости, касающиеся данной тематики, консультируется со специалистами — в целом, старается делать все, чтобы
        предоставить вам как можно более актуальную и достоверную информацию. Однако при этом мы не можем в полной мере
        гарантировать, что в изложенной информации в полной мере отражаются все сведения факты. Существуют самые
        различные причины, в том числе имеющие чисто технический характер, по которым в информации могут встречаться
        упущения, неточности.
      </p>
      <p>
        2.7. Пользователь, располагая предоставленной Администрацией информацией о возможности получения соответствующих
        видов и объёмов консультативной помощи, своей волей поручает, а Администрация обязуется оказывать Пользователю
        платные консультативные услуги (далее – «услуги»). Перечень услуг Администрации, подлежащих оказанию по
        настоящему пользовательскому соглашению, устанавливается на основании прайс-листа Администрации, с которым
        Заказчик может ознакомиться на официальном сайте: persolab.ru.
      </p>
      <p>
        2.8. Администрация осуществляет оказание услуг Пользователю и выдаёт подготовленную в рамках пользовательского
        соглашения необходимую документацию в сроки и порядке, установленные в соответствии с действующим
        законодательством РФ. Услуга является оказанной Администрацией с момента ее фактического оказания / выдачи
        Администрацией документации (медицинского заключения).
      </p>
      <p>
        2.9. До заключения правоотношений Пользователь уведомляет Администрацию о том, что несоблюдение указаний
        (рекомендаций) Администрации (работающих у него сотрудников), в том числе назначенного режима лечения, могут
        снизить качество предоставляемой платной консультативной услуги, повлечь за собой невозможность ее завершения в
        срок или отрицательно сказаться на состоянии здоровья Пользователя.
      </p>
      <p>
        2.10. На Сайте имеются материалы, в которых содержится медицинская информация. Вся информация медицинского
        характера носит сугубо рекомендательный характер и не должна использоваться без очной консультации с врачом или
        другим специалистом медицинского профиля. Сайт не может, а также не ставит перед собой целью заменить
        диагностику, лечение или профилактику заболеваний в лечебном учреждении или у частнопрактикующего специалиста.
        Описываемые средства диагностики, лечения и профилактики заболеваний непредназначенных для самостоятельного
        использования. Администрация Сайта не берет на себя ответственности в случае возникновения деликатных
        последствий любого поступка, наступившего в результате применения любого метода без предварительной очной
        консультации с врачом или другим специалистом медицинского профиля.
      </p>
      <p>
        2.11. Порядок и условия предоставления Администраций услуг (в том числе, в применимых случаях, порядок и условия
        осуществления медицинского вмешательства) представлены Исполнителем на официальном сайте по адресу persolab.ru.
        Нажимая кнопку «С условиями настоящего договора согласен и их принимаю», расположенную в заключении настоящего
        договора, Пользователь подтверждает ознакомление и свое согласие с порядком и условиями предоставления услуг.
      </p>
      <p>
        2.12. Администрация предоставляет услуги, качество которых должно соответствовать условиям договора, а при
        отсутствии в договоре условий об их качестве - требованиям, предъявляемым к услугам соответствующего вида. В
        случае если федеральным законом, иными нормативными правовыми актами Российской Федерации предусмотрены
        обязательные требования к качеству медицинских услуг, качество предоставляемых платных медицинских услуг должно
        соответствовать этим требованиям.
      </p>
      <h4>3. Администратор/владелец Сайта. Права и обязанности.</h4>
      <p>
        3.1. Администратор/Владелец Сайта является управляющей стороной и обладателем исключительных прав на
        использование Сайта, в том числе входящих в его состав программ для ЭВМ и баз данных, информационных материалов,
        графических изображений, являющихся элементами пользовательского интерфейса, и иных охраноспособных объектов,
        входящих в состав Сайта, а также на дизайн Сайта.
      </p>
      <p>3.2. Администратор/владелец Сайта имеет право:</p>
      <p>
        3.2.1. Распоряжаться статистической информацией, связанной с функционированием Сайта, а также информацией
        Пользователей для обеспечения адресного показа рекламной информации различным аудиториям Пользователей.
      </p>
      <p>
        3.2.2. Направлять Пользователю информацию о развитии Сайта и его сервисов; отправлять sms-сообщения с целью
        информирования о кодах активации, проведения опросов о качестве работы Сайта, предоставления и запросов прочей
        информации, а также рекламировать собственную деятельность и услуги.
      </p>
      <p>
        3.2.3. Владелец Сайта имеет право производить профилактические работы в программно-аппаратном комплексе Сайта с
        временным приостановлением работы Сайта по возможности в ночное время и максимально сокращая время
        неработоспособности Сайта, уведомляя об этом Пользователя, если технически это представляется возможным.
      </p>
      <p>3.3. Администратор/Владелец Сайта обязуется:</p>
      <p>3.3.1. Оказывать Пользователю услуги, указанные в настоящем Соглашении.</p>
      <p>3.3.2. Предоставлять Пользователю возможность самостоятельно публиковать информацию о самом себе.</p>
      <h4>4. Пользователь Сайта. Права и обязанности.</h4>
      <p>
        4.1. Права и обязанности Пользователя Сайта возникают у лица с момента его первого обращения к любой из
        веб-страниц, входящих в состав Сайта.
      </p>
      <p>
        4.2. Осуществление прав Пользователя в полном объеме возможно после обязательной регистрации Пользователя на
        Сайте. Регистрация Пользователя является бесплатной, добровольной. Для регистрации Пользователь указывает имя
        пользователя (адрес электронной почты) и пароль, а также фамилию и имя. Регистрационная форма может запрашивать
        у Пользователя дополнительную информацию. С момента подтверждения регистрации и активации учетной записи
        (аккаунта) Пользователь приобретает статус зарегистрированного Пользователя, становится обладателем Учетной
        информации Пользователя, что означает, что он несет ответственность за безопасность Учетной информации.
      </p>
      <p>
        4.3. Если не будет доказано иное, лицо, авторизовавшееся на Сайте (путем указания имени пользователя (адреса
        электронной почты) и пароля, перехода по ссылке, полученной по электронной почте, в результате автоматической
        авторизации с использованием cookies и т.д.), считается надлежащим владельцем учетной записи, доступ к
        использованию и управлению которой были получены в результате такой авторизации.
      </p>
      <p>
        Если Пользователем не доказано обратное, любые действия, совершенные с использованием его логина и пароля,
        считаются совершенными соответствующим Пользователем. В случае несанкционированного доступа к логину и паролю
        и/или Профилю Пользователя или распространения логина и пароля Пользователь обязан незамедлительно сообщить об
        этом Владельцу сайта в установленном порядке.
      </p>
      <p>
        4.4. Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие Российское
        законодательство или нормы международного права, в том числе в сфере интеллектуальной собственности, авторских
        и/или смежных правах, а также любых действий, которые приводят или могут привести к нарушению нормальной работы
        Сайта и сервисов Сайта.
      </p>
      <p>
        4.5. Использование материалов Сайта без согласия правообладателей не допускается (статья 1270 ГК РФ). Для
        правомерного использования материалов Сайта необходимо заключение лицензионных договоров от Правообладателей.
      </p>
      <p>
        4.6. При цитировании материалов Сайта, включая охраняемые авторские произведения, ссылки на Сайт обязательны
        (подпункт 1 пункта 1 статьи 1274 ГК РФ). Порядок размещения ссылок на странице, содержащей цитируемый материал,
        устанавливается Администрацией Сайта.
      </p>
      <p>
        4.7. Пользователь предупрежден о том, что Администрация Сайта не несет ответственности за посещение и
        использование им внешних ресурсов, ссылки на которые могут содержаться на сайте.
      </p>
      <p>
        4.8. Пользователь согласен с тем, что Администрация Сайта не несет ответственности и не имеет прямых или
        косвенных обязательств перед Пользователем, в связи с любыми возможными или возникшими потерями или убытками,
        связанными с любым содержанием Сайта, регистрацией авторских прав и сведениями о такой регистрации, товарами или
        услугами, доступными на или полученными через внешние сайты или ресурсы, либо иные контакты Пользователя, в
        которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы.
      </p>
      <p>
        4.9. Пользователь принимает положение о том, что все материалы и сервисы Сайта или любая их часть могут
        сопровождаться рекламой. Пользователь согласен с тем, что Администрация Сайта не несет какой-либо
        ответственности и не имеет каких-либо обязательств, в связи с такой рекламой.
      </p>
      <p>
        4.10. Пользователь согласен с тем, что Администрация Сайта может отказать в предоставлении услуги без объяснения
        причин.
      </p>
      <p>4.11. Пользователь обязуется:</p>
      <p>4.11.1. Полностью ознакомиться с условиями настоящего Соглашения до момента регистрации.</p>
      <p>4.11.2. Соблюдать все условия настоящего Соглашения.</p>
      <p>4.11.3. Не передавать сведения о других Пользователях, полученные посредством Сайта, третьим лицам.</p>
      <p>4.11.4. Не передавать свою Учетную информацию третьим лицам.</p>
      <p>
        4.11.5. Не размещать персональные данные других лиц, а также не использовать персональные данные других
        Пользователей каким-либо образом, не соответствующим требованиям законодательства РФ, в противоправных или
        противозаконных целях, в целях извлечения выгоды и любых иных целях, не соответствующих целям создания Сайта.
      </p>
      <p>
        4.11.6.Предоставить все необходимые данные и информацию для прохождения процедуры регистрации, которые должны
        быть достоверны.
      </p>
      <p>
        4.11.7. Не регистрироваться в качестве Пользователя от имени или вместо другого лица или регистрировать группу
        (объединение) лиц или юридическое лицо/индивидуального предпринимателя в качестве Пользователя.
      </p>
      <p>
        4.13.8. Не использовать программное обеспечение и осуществлять действия, направленные на нарушение нормального
        функционирования сайта и его сервисов или персональных страниц Пользователей, не загружать, не хранить, не
        публиковать, не распространять и не предоставлять доступ или иным образом использовать вирусы, трояны и другие
        вредоносные программы; не использовать без специального на то разрешения Владельца Сайта автоматизированные
        скрипты (программы) для сбора информации и (или) взаимодействия с его сервисами.
      </p>
      <p>
        4.13.9. Не пытаться получить доступ к логину и паролю другого Пользователя, в том числе включая, но не
        ограничиваясь, обманом, взломом Профилей других Пользователей и прочее.
      </p>
      <p>
        4.14. <span>Пользователю запрещено:</span>
      </p>
      <p>4.14.1. Осуществлять незаконные сбор и обработку персональных данных других Пользователей.</p>
      <p>
        4.14.2. Осуществлять доступ к каким-либо услугам иным способом, кроме как через интерфейс, предоставленный
        Владельцем Сайта, за исключением случаев, когда такие действия были прямо разрешены Пользователю в соответствии
        с отдельным соглашением с Владельцем Сайта.
      </p>
      <p>
        4.14.3. Воспроизводить, дублировать, копировать, продавать, осуществлять торговые операции и перепродавать
        услуги для каких-либо целей, за исключением случаев, когда такие действия были прямо разрешены Пользователю в
        соответствии с условиями отдельного соглашения с Владельцем Сайта.
      </p>
      <p>
        4.14.4. Размещать коммерческую и политическую рекламу вне специальных разделов Сайта, установленных Владельцем
        Сайта.
      </p>
      <p>4.15. Пользователь имеет право:</p>
      <p>
        4.15.1. Круглосуточно получать доступ к серверу, на котором расположены Сайт, за исключением времени проведения
        профилактических работ.
      </p>
      <p>4.15.2. Самостоятельно редактировать ранее размещенную о себе информацию.</p>
      <p>
        4.15.3. Обратиться в службу поддержки для блокирования своего Профиля (при отсутствии действующих демо-доступов
        и подписок).
      </p>
      <p>4.16. Пользователь согласен с тем, что, осуществляя доступ к Сайту и пользуясь его контентом, он:</p>
      <p>
        4.16.1. Выражает свое безоговорочное согласие со всеми условиями настоящего Соглашения и обязуется их соблюдать
        или прекратить использование Сайта.
      </p>
      <p>
        4.16.3. В целях реализации настоящего Соглашения Пользователи дают Владельцу Сайта разрешение на использование,
        хранение, обработку, и распространение персональных данных тем способом и в той мере, в которой это необходимо
        для исполнения условий настоящего Соглашения, а также на трансграничную передачу персональных данных на
        условиях, изложенных в соглашении. Порядок использования, хранения, обработки и распространения персональных
        данных Пользователей размещен на сайте по адресу: https://persolab.ru/. Пользователь соглашается на передачу
        третьим лицам персональных и иных данных, в том числе для целей их обработки, для обеспечения функционирования
        сайта, реализации партнерских и иных программах, при условии обеспечения в отношении передаваемых данных режима,
        аналогичного режиму, существующему на сайте, в том числе, включая, но не ограничиваясь передачей персональных
        данных лицам, аффилированным с группой лиц ООО «ВИВАМЕД» или заключившим с ними договоры, а также третьим лицам
        в случаях, когда такая передача необходима для использования Пользователем определенного сервиса либо для
        исполнения определенного соглашения или договора с Пользователем.
      </p>
      <h4>5. Прочие условия</h4>
      <p>
        5.1. Ничто в Соглашении не может между пониматься как установление Пользователем и Администрации Сайта агентских
        отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то
        иных отношений, прямо не предусмотренных Соглашением.
      </p>
      <p>
        5.2 Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному
        исполнению не влечет недействительности иных положений Соглашения.
      </p>
      <p>
        5.3. Бездействие со стороны Администрации Сайта в случае нарушения кем-либо из Пользователей положений
        Соглашения не лишает Администрацию Сайта права предпринять позднее соответствующие действия в защиту своих
        интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы Сайта.{' '}
      </p>
      <p>
        5.4. Администрация Сайта обращает внимание Пользователей на то, что за разглашение (нарушение
        конфиденциальности) информации, т.е. совершение действий, в результате которых информация, доступ к которой
        ограничен в соответствии с законом (государственная, служебная, коммерческая тайна, различного рода
        профессиональные тайны и т.д.), становится известной посторонним лицам без согласия на то ее обладателя, а также
        за незаконное использование объектов интеллектуальной собственности (произведений науки, литературы, искусства,
        программ для ЭВМ и баз данных, фонограмм и т.д.), в том числе путем размещения их копий или частей в сети
        Интернет, законодательством Российской Федерации установлена гражданско-правовая, административная и уголовная
        ответственность.
      </p>
      <p>
        5.5. Юридическая ответственность (в том числе уголовная) установлена также за возбуждение ненависти либо вражды,
        унижение чести и достоинства человека или группы лиц по каким-либо признакам, оскорбление, пропаганду насилия
        над гражданами, отказа от исполнения гражданских обязанностей, совершения противоправных деяний, за
        неправомерный доступ к компьютерной информации, а также за ряд иных действий, запрещенных действующими нормами.
      </p>
      <h4>6. Гарантии и ответственность сторон</h4>
      <p>
        6.1. Пользователь гарантирует, что им будут приняты надлежащие меры для обеспечения конфиденциальности учетных
        данных, используемых им для авторизации на Сайте, и предотвращения возможности авторизации других лиц с
        использованием его учетной записи.
      </p>
      <p>
        6.2. Владелец Сайта гарантирует тайну переписки, осуществляемой Пользователями посредством личных сообщений в
        том числе фото, видео и других материалов.
      </p>
      <p>
        6.3. Владелец Сайта гарантирует, что Администрацией Сайта не будет осуществляться отправка Пользователям
        сообщений, содержащих рекламу, без предварительного согласия Пользователей.
      </p>
      <p>
        6.4. Владелец Сайта гарантирует, что указанный Пользователем при регистрации или отправки заявки номер телефона
        будет использован только для связи Администрации с Пользователем, отправки Пользователю уведомлений и сообщений,
        на получение которых он подписался, и не будет передан третьим лицам без предварительного согласия Пользователя.
      </p>
      <p>
        6.5. Владелец Сайта в лице Администрации обязуется приложить все разумные усилия для поддержания Сайта в
        работоспособном состоянии, однако не гарантирует постоянную и бесперебойную работу Сайта и не несет обязанности
        по обеспечению его непрерывного функционирования.
      </p>
      <p>
        Владелец Сайта не несет ответственности за неисполнение или ненадлежащее исполнение обязательств по Соглашению,
        а также возможные убытки, возникшие в том числе, но не ограничиваясь, в результате:
      </p>
      <p>
        – неправомерных действий Пользователей, направленных на нарушения информационной безопасности или нормального
        функционирования Сайта;
      </p>
      <p>
        – сбоев в работе сайта, вызванных ошибками в коде, компьютерными вирусами и иными посторонними фрагментами кода
        в программном обеспечении сайта;
      </p>
      <p>
        – отсутствия (невозможности установления, прекращения и пр.) Интернет-соединений между сервером Пользователя и
        сервером сайта;
      </p>
      <p>
        – проведения государственными и муниципальными органами, а также иными организациями мероприятий в рамках
        Системы оперативно-розыскных мероприятий;
      </p>
      <p>
        – установления государственного регулирования (или регулирования иными организациями) хозяйственной деятельности
        коммерческих организаций в сети Интернет и/или установления указанными субъектами разовых ограничений,
        затрудняющих или делающих невозможным исполнение Соглашения;
      </p>
      <p>
        – других случаев, связанных с действиями (бездействием) Пользователей и/или других субъектов, направленными на
        ухудшение общей ситуации с использованием сети Интернет и/или компьютерного оборудования, существовавшей на
        момент заключения Соглашения, также любых других действий, направленных на сайте и на третьих лиц.
      </p>
      <p>
        6.6. За нарушение положений настоящего Регламента доступ Пользователя к Сайту или его отдельным сервисам может
        быть временно приостановлен или заблокирован на неопределенный срок. Доступ пользователя к Сайту (его отдельным
        сервисам) восстанавливается на усмотрение Администрации по письменному заявлению Пользователя.
      </p>
      <p>
        6.7. В случае неоднократного или грубого нарушения Пользователем положений Соглашения его учетная запись может
        быть удалена.
      </p>
      <p>
        6.8. За исключением случаев, прямо предусмотренных законом, Владелец Сайта не несет ответственности за убытки,
        возникшие у Пользователей по причинам, связанным с техническими сбоями аппаратного или программного обеспечения,
        а также за убытки, возникшие в результате действий Пользователей, явно не соответствующих обычным правилам
        работы с информацией в сети Интернет, аппаратными средствами или программным обеспечением.
      </p>
      <p>
        6.9. В случае наступления форс-мажорных обстоятельств, а также аварий или сбоев в программно-аппаратных
        комплексах третьих лиц, сотрудничающих с Владельцем Сайта, или действий (бездействий) третьих лиц, направленных
        на приостановку или прекращение функционирования сайта, возможна приостановка работы Сайта без предварительного
        уведомления Пользователя.
      </p>
      <p>
        6.10. Ни одна из Сторон не несет ответственности за полное или частичное неисполнение любой из своих
        обязанностей, если неисполнение является следствием таких обстоятельств, как наводнение, пожар, землетрясение,
        другие стихийные бедствия, война или военные действия и другие обстоятельства непреодолимой силы, возникшие
        после заключения Соглашения и независящие от воли Сторон.
      </p>
      <h4>7. Порядок разрешения споров и урегулирования претензий</h4>
      <p>
        7.1. В случае возникновения споров между Пользователем и Владельцем Сайта по вопросам, связанным с исполнением
        Соглашения, Стороны примут все меры к разрешению их путем переговоров между собой. Претензионный порядок
        разрешения споров обязателен. Претензии Пользователей по предоставляемым Услугам принимаются и рассматриваются
        Владельцем Сайта только в письменном виде и в порядке, предусмотренном настоящим Соглашением и действующим
        законодательством РФ.
      </p>
      <p>
        7.2. Для разрешения споров, возникших между Пользователем и Владельцем Сайта в результате использования Услуг,
        применяется следующий претензионный порядок: Пользователь, считающий, что его права нарушены из-за действий
        Владельца Сайта, направляет последнему претензию, содержащую суть предъявляемого требования, обоснование его
        предъявления, а также все данные Пользователя. Претензия также направляется Владельцу Сайта в письменном виде
        посредством отправки по почте или по факсу;
      </p>
      <p>
        в течение 10 (десяти) рабочих дней со дня получения претензии Владелец Сайта обязан изложить свою позицию по
        указанным в ней принципиальным вопросам и направить свой ответ по адресу электронной почты или почтовому адресу,
        указанному в претензии Пользователя;
      </p>
      <p>
        в случае недостижения разрешения спора путем претензионного порядка спор подлежит рассмотрению в соответствии с
        п. 7.4 Соглашения;
      </p>
      <p>
        Владельцем Сайта не рассматриваются анонимные претензии или претензии, не позволяющие идентифицировать
        Пользователя на основе предоставленных им при регистрации данных, или претензии, не содержащие данных, указанных
        в настоящем пункте настоящего Соглашения.
      </p>
      <p>
        7.3. Для решения технических вопросов при определении вины Пользователя в результате его неправомерных действий
        при пользовании сетью Интернет и Сайта в частности, Владелец Сайта вправе самостоятельно привлекать компетентные
        организации в качестве экспертов. В случае установления вины Пользователя, последний обязан возместить затраты
        на проведение экспертизы.
      </p>
      <p>
        7.4. При недостижении согласия между Сторонами путем переговоров, спор, вытекающий из настоящего Соглашения,
        подлежит рассмотрению в суде по месту нахождения Владельца сайта.
      </p>
      <h4>8. Прочие условия</h4>
      <p>
        8.1. Настоящее Соглашение вступает в силу с момента акцепта настоящей оферты Пользователем и заключается на
        неопределенный срок.
      </p>
      <p>
        8.2. Настоящее Соглашение является офертой и в силу действующего гражданского законодательства РФ Владелец имеет
        право на отзыв оферты в соответствии со ст. 436 ГК РФ. В случае отзыва настоящего Соглашения Владельцем
        настоящее Соглашение считается прекращенным с момента отзыва. Отзыв осуществляется путем размещения
        соответствующей информации на Сайте.
      </p>
      <p>
        8.3. Если Пользователь не согласен с условиями настоящего Соглашения, то он должен немедленно удалить свой
        Профиль из Сайта, в противном случае продолжение использования Пользователем сайта означает, что Пользователь
        согласен с условиями Соглашения.
      </p>
      <p>
        8.4. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством
        Российской Федерации.
      </p>
      <h4>9. Реквизиты Владельца:</h4>
      <p>ООО «ВИВАМЕД»</p>
      <p>АДРЕС 675000, Российская Федерация, Амурская область, г. Благовещенск, ул. Горького, 235/2 офис 107</p>
      <p>ИНН 2801193943 КПП 280101001</p>
      <p>ОГРН 1142801002145</p>
      <p>р/сч 40702810503000004628</p>
      <p>БАНК Дальневосточный банк ПАО «Сбербанк России» г. Хабаровск</p>
      <p>к/ сч 30101810600000000608</p>
      <p>БИК 040813608</p>
      <p>Тел. 8416777709; </p>
      <p>e-mail: info@persolab.ru</p>
      <h4>
        Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения и безусловно принимает их.
      </h4>
    </div>
  )
}
