import { Avatar } from '@components/Avatar'
import React from 'react'
import Styles from './styles.module.scss'
import clip from '@icons/chat-paperclip.svg'
// import search from '@icons/chat-search.svg'
import backArrow from '@icons/chat-back-arrow.svg'
import { useAppSelector } from '@app/redux/hooks'

export const Recipient = ({ setCurrentPage }) => {
  const recipient = useAppSelector((state) => state.chat.currentDialogueMember)
  const handleClipClick = () => {
    setCurrentPage(3)
  }
  const handleBackClick = () => {
    setCurrentPage(1)
  }
  return (
    <div className={Styles.container}>
      <img src={backArrow} alt='Назад' className={Styles.backArrow} onClick={handleBackClick} />
      <Avatar
        avatar={recipient?.avatar}
        firstName={recipient?.first_name}
        lastName={recipient?.last_name}
        size='44px'
        fontSize='20px'
      />
      <div className={Styles.info}>
        <div className={Styles.name}>{recipient?.first_name}</div>
        <div className={Styles.status}>Online</div>
      </div>
      {/* <img src={search} alt='search' className={Styles.icon} /> */}
      <img src={clip} alt='clip' className={Styles.icon} onClick={handleClipClick} />
    </div>
  )
}
