/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import cartIcon from '@icons/cart.svg'

import Styles from './style.module.scss'

import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getMyCart, patchCartId, postCart } from '../../../../pages/cart/redux/cartSlicer'
import { Link } from 'react-router-dom'

interface CatalogCartButtonProps {
  product: {
    id: number
    in_cart: number
  }
  column?: boolean
  type: string
}

export const CatalogCartButton: React.FC<CatalogCartButtonProps> = ({ product, column, type }) => {
  const dispatch = useAppDispatch()

  const thisObject = () => {
    if (type === 'analyzes') {
      return {
        cartObject: product,
        postObject: { analysis: product.id },
      }
    }
    if (type === 'analysis_sets') {
      return {
        cartObject: product,
        postObject: { analysis_set: product.id },
      }
    }
  }

  const object = thisObject()

  const cartData = useAppSelector((state) => state.cart.cartData)
  const wrapperClassName = column ? `${Styles.counter_wrap} ${Styles.counter_wrap_column}` : `${Styles.counter_wrap}`

  const [inCart, setInCart] = useState(object.cartObject.in_cart)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setInCart(cartData[type] && cartData[type]?.find((el) => el.id === product.id)?.quantity)
  }, [cartData, object.cartObject.id])

  const addProduct = (move) => {
    setIsLoading(true)
    if (move === 'add') {
      dispatch(postCart(object.postObject)).then(() => {
        dispatch(getMyCart())
        setIsLoading(false)
      })
    } else {
      dispatch(
        patchCartId({
          ...object.postObject,
          quantity: move === 'plus' ? inCart + 1 : inCart - 1,
        }),
      ).then(() => {
        dispatch(getMyCart())
        setIsLoading(false)
      })
    }
  }
  return (
    <div className={Styles.content}>
      {inCart > 0 ? (
        <div className={wrapperClassName}>
          <div className={Styles.counter__container}>
            <button
              onClick={() => addProduct('minus')}
              className={`${Styles.counter} ${Styles.counter_minus}`}
              disabled={isLoading}
            />
            <span>{inCart < 10 ? `0${inCart}` : inCart}</span>
            <button
              onClick={() => addProduct('plus')}
              className={`${Styles.counter} ${Styles.counter_plus}`}
              disabled={isLoading}
            />
          </div>
          <Link to='/cart' className={Styles.link}>
            <button className={Styles.green}>
              <img src={cartIcon} alt='Иконка' />
              <span>В корзине</span>
            </button>
          </Link>
        </div>
      ) : (
        <button className={Styles.cart} onClick={() => addProduct('add')} disabled={isLoading}>
          <img src={cartIcon} alt='Иконка' />
          <span>{isLoading ? 'Загрузка...' : 'В корзину'}</span>
        </button>
      )}
    </div>
  )
}
