import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '../../../../../../../../helpers/useWindowSizeHook'
import { setIsDrawer, setFilterView, setAnalyzesProps } from '../../../../../../redux/catalogSlicer'
import Styles from './style.module.scss'

// import { FilterWrapper } from '../../../../../CatalogFilter/components/FilterWrapper'
import { FilterCheckbox } from '../../../../../CatalogFilter/components/FilterCheckbox'

import { MOBILE_BREAKPOINT } from '../../../../../../../../global-constants'

export const FilterAnalysesModal = ({ filterView, title }) => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const analyzesFilterList = useAppSelector((state) => state.catalog.analyzesFilterList)
  const analyzesFilter = useAppSelector((state) => state.catalog.analyzesFilter)
  const [filter, setFilter] = useState({ ...analyzesFilter })

  const closeModal = () => {
    dispatch(setIsDrawer(false))
    dispatch(setFilterView('all'))
  }

  const setExactFilter = () => {
    dispatch(setAnalyzesProps(filter))
    closeModal()
  }
  const clearExactFilter = () => {
    dispatch(setAnalyzesProps({ [filterView]: '' }))
    setFilter({ [filterView]: '' })
  }

  return (
    <>
      <div className={Styles.filter}>
        {MOBILE_BREAKPOINT < width ? (
          <div className={Styles.header}>
            <span className={Styles.title}>{title}</span>
          </div>
        ) : (
          <div className={`${Styles.header} ${Styles.header_mobile}`}>
            <button className={Styles.back}>{}</button>
            <span className={Styles.title}>{title}</span>
            <button className={Styles.close} onClick={closeModal}>
              {}
            </button>
          </div>
        )}
        <div className={Styles.body}>
          {/* <div className={Styles.search}>
            <input onChange={() => {}} type='text' value='' placeholder='Поиск органа или системы' />
            <button>{}</button>
          </div> */}
          {filterView === 'categories' && (
            <>
              <div className={Styles.container}>
                {analyzesFilterList.categories &&
                  analyzesFilterList.categories.map((category) => {
                    return (
                      <FilterCheckbox
                        key={category.index}
                        title={category.name}
                        count={category.products}
                        setFilter={setFilter}
                        filter={filter}
                        filterKey='categories'
                      />
                    )
                  })}
              </div>
            </>
          )}
          {filterView === 'body_system' && (
            <>
              <div className={Styles.container}>
                {analyzesFilterList.body_systems &&
                  analyzesFilterList.body_systems.map((category) => {
                    return (
                      <FilterCheckbox
                        key={category.index}
                        title={category.name}
                        count={category.products}
                        setFilter={setFilter}
                        filter={filter}
                        filterKey='body_system'
                      />
                    )
                  })}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={Styles.confirm}>
        <button className={Styles.clear} onClick={clearExactFilter}>
          Очистить все
        </button>
        <button className={Styles.view} onClick={setExactFilter}>
          Показать
        </button>
      </div>
    </>
  )
}
