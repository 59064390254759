import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import moment from 'moment'
import { Price } from '@components/ui/Price'
import useWindowSize from '@helpers/useWindowSizeHook'
import { Link } from 'react-router-dom'
import { TooltipComponent } from '@components/TooltipComponent'
import { formateDate } from '../../../../../../utils/formateDate'
import { NOTIFICATION_OF_MINUTES } from '../../../../../doctor/constants'
import timeIcon from '@icons/time.svg'

export const AppointmentCard = (props) => {
  const [notification, setNotification] = useState(null)
  const dateFomat = moment(props.item.day_reception, 'YYYY-MM-DD').format('D MMMM')
  const { width } = useWindowSize()
  const desktop = width >= 829
  const { avatar } = props.item.doctor
  const handleStartAppointment = (e) => {
    e.stopPropagation()
    window.open(props.item.meeting_link)
  }
  useEffect(() => {
    if (formateDate(new Date(), 'YYYY-MM-DD') === formateDate(new Date(props.item.day_reception), 'YYYY-MM-DD')) {
      const currentTime = new Date().getTime()
      const receptionTime = new Date(`${props.item.day_reception} ${props.item.time_reception}`).getTime()

      if (currentTime < receptionTime) {
        const diffMinutes = receptionTime - currentTime
        const minutes = diffMinutes / 60000

        if (minutes <= NOTIFICATION_OF_MINUTES) {
          setNotification(Math.ceil(minutes))
        }
      }
    }
  }, [])
  return (
    <>
      {props.item && (
        <>
          <div className={Styles.record}>
            <h2 className={Styles.title__card}>Запись на прием {dateFomat}</h2>
            <div className={`${Styles.header} ${!props.item.is_online && Styles.offline}`}>
              <div className={Styles.container}>
                <span className={Styles.time}>{props.item.time_reception}</span>
                <span className={Styles.online}>{props.item.is_online ? 'Онлайн прием' : 'Личный прием'}</span>
              </div>
              {notification && (
                <div className={Styles.notification}>
                  <img src={timeIcon} alt='Иконка' />
                  <span className={Styles.across}>Через {notification} минут</span>
                </div>
              )}
              {props.item.is_online && desktop && props.item.meeting_link && props.item.status !== 'done' && (
                <button className={Styles.start} onClick={handleStartAppointment}>
                  Подключиться
                </button>
              )}
            </div>
            <div className={Styles.doctor_wrap}>
              <img className={Styles.img} src={avatar} alt='avatar' />
              <div className={Styles.desc}>
                <h6 className={Styles.name}>{`${props.item.doctor.first_name} ${props.item.doctor.last_name}`}</h6>
                <p className={Styles.text}>{props.item.doctor.specialty}</p>
              </div>
            </div>
            <div className={Styles.block}>
              <span className={Styles.title}>Консультация</span>
              <p className={Styles.description}>{props.item?.consultation_type?.direction?.title}</p>
            </div>
            {desktop ? (
              <div className={Styles.block}>
                <span className={Styles.title}>Стоимость</span>
                {props.item.is_free ? (
                  <div className={Styles.wrap}>
                    <span className={Styles.description}>Бесплатный прием</span>
                  </div>
                ) : (
                  <div className={Styles.wrap}>
                    <Price value={props.item.consultation_type.price} className={Styles.description} />
                  </div>
                )}
              </div>
            ) : (
              props.item.is_online &&
              props.item.meeting_link && (
                <button className={Styles.start} onClick={handleStartAppointment}>
                  Подключиться
                </button>
              )
            )}
            {props.item.is_free && !desktop && (
              <div className={Styles.wrap}>
                <span className={Styles.description}>Бесплатный прием</span>
              </div>
            )}
            {!props.item.is_free && !props.item.is_pay && (
              <>
                <span className={Styles.payment}>
                  Не оплачено <TooltipComponent text='Без оплаты прием не состоится' className={Styles.payment_img} />
                </span>
              </>
            )}
            <Link className={Styles.link} to={`/my-appointment/${props.item.id}`} />
          </div>
        </>
      )}
    </>
  )
}
