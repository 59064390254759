import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { formateDate } from '../../../../../../../../utils/formateDate'

interface DoctorRecordMonthProps {
  onClickDay: any
  countAppointment?: number
  day: string
  isAvailable: boolean
  receptions: any
}

export const DoctorRecordMonth: React.FC<DoctorRecordMonthProps> = (props) => {
  const { onClickDay, day, isAvailable, receptions } = props

  const today = formateDate(new Date(), 'YYYY-MM-DD') === formateDate(new Date(day), 'YYYY-MM-DD')

  const selectedDayMonth = useAppSelector((state) => state.doctor.selectedDayMonth)
  const [dayMonth, setDayMonth] = useState(null)
  const [containIsOnline, setContainIsOnline] = useState(false)
  const [containIsOffline, setContainIsOffline] = useState(false)

  useEffect(() => {
    setDayMonth(day)

    if (receptions) {
      receptions.forEach((reception) => {
        if (reception.appointment) {
          if (reception.appointment.is_online === true) {
            setContainIsOnline(true)
          }

          if (reception.appointment.is_online === false) {
            setContainIsOffline(true)
          }
        }
      })
    }
  }, [])

  return (
    <>
      {isAvailable ? (
        <div
          className={dayMonth === selectedDayMonth ? `${Styles.cell} ${Styles.cell_selected}` : `${Styles.cell}`}
          onClick={() => onClickDay({ day, receptions })}
        >
          {today ? (
            <div className={`${Styles.number} ${Styles.number_mod}`}>
              <span>{formateDate(day, 'DD')}</span>
            </div>
          ) : (
            <div className={Styles.number}>
              <span>{formateDate(day, 'DD')}</span>
            </div>
          )}
          <div className={Styles.rec}>
            {containIsOffline && <i className={`${Styles.round} ${Styles.round_offline}`}>{}</i>}
            {containIsOnline && <i className={Styles.round}>{}</i>}
          </div>
        </div>
      ) : (
        <div className={`${Styles.cell} ${Styles.cell_disabled}`} onClick={() => onClickDay({ day, receptions })}>
          {today ? (
            <div className={`${Styles.number} ${Styles.number_mod}`}>
              <span>{formateDate(day, 'DD')}</span>
            </div>
          ) : (
            <div className={Styles.number}>
              <span>{formateDate(day, 'DD')}</span>
            </div>
          )}
          <div className={Styles.rec}>
            {containIsOffline && <i className={`${Styles.round} ${Styles.round_offline}`}>{}</i>}
            {containIsOnline && <i className={Styles.round}>{}</i>}
          </div>
        </div>
      )}
    </>
  )
}
