import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setDisplayProfileContent } from '../../../../../../redux/doctorSlicer'
import { handleLogout } from '../../../../../../../authorization/redux/authSlice'
import Styles from './style.module.scss'

import { DoctorCanceledDays } from '../../../PersonalInfo/components/DoctorCanceledDays'

// import editIcon from '@icons/edit-round.svg'
import coinIcon from '@icons/coin.svg'
import arrowIcon from '@icons/arrow-mobile.svg'
import { MobileProfileAvatar } from '@components/Avatar/MobileProfileAvatar'

interface PersonalInfoProps {
  reopenCancel?: any
  reopenConfirm: any
}

export const PersonalInfo: React.FC<PersonalInfoProps> = ({ reopenCancel }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const user = useAppSelector((state) => state.auth.user.info)
  const blackList = useAppSelector((state) => state.doctor.blackList)
  const [displayedBlackList, setDisplayedBlackList] = useState([])

  const handleOutClick = () => {
    dispatch(handleLogout())
    history.push('/')
  }

  useEffect(() => {
    setDisplayedBlackList(blackList.slice(0, 3))
  }, [blackList])

  return (
    <div className={Styles.profile}>
      <div className={Styles.container}>
        <MobileProfileAvatar firstName={user.first_name} lastName={user.last_name} avatar={user.avatar} />
        {/* <div className={Styles.avatar}>
          {user.avatar ? (
            <div className={Styles.cap}>
              <img className={Styles.image} src={user.avatar} alt='Аватар' />
              <img className={Styles.edit} src={editIcon} alt='Иконка' />
            </div>
          ) : (
            <div className={Styles.cap}>
              <span className={Styles.abbreviation}>
                {user.first_name.substring(0, 1)}
                {user.last_name && user.last_name.substring(0, 1)}
              </span>
              <img className={Styles.edit} src={editIcon} alt='Иконка' />
            </div>
          )}
          <span className={Styles.name}>
            {user.first_name} {user.last_name && `${user.last_name.substring(0, 1)}.`}
          </span>
        </div> */}
        <ul className={Styles.list}>
          <li className={Styles.item}>
            <div className={Styles.wrapper} onClick={() => dispatch(setDisplayProfileContent('edit'))}>
              <div className={Styles.wrap}>
                <img src={coinIcon} alt='Иконка' />
                <div className={Styles.block}>
                  <span className={Styles.text}>Личные данные</span>
                </div>
              </div>
              <img src={arrowIcon} alt='Иконка' />
            </div>
          </li>
          <li className={Styles.item}>
            {blackList.length === 0 ? (
              <div className={Styles.wrapper} onClick={() => dispatch(setDisplayProfileContent('cancel'))}>
                <div className={Styles.wrap}>
                  <img src={coinIcon} alt='Иконка' />
                  <div className={Styles.block}>
                    <span className={Styles.text}>Отменить дни приема</span>
                    <p className={Styles.description}>
                      Вы можете выбрать дни, которые будут недоступны для записи пациентам
                    </p>
                  </div>
                </div>
                <img src={arrowIcon} alt='Иконка' />
              </div>
            ) : (
              <div className={Styles.cancel}>
                <span className={Styles.title}>Прием отменен</span>
                <ul className={Styles.dates}>
                  {displayedBlackList?.map((cancel) => (
                    <DoctorCanceledDays key={cancel.id} cancel={cancel} reopenCancel={reopenCancel} />
                  ))}
                </ul>
                {blackList.length - displayedBlackList.length > 0 && (
                  <button className={Styles.more} onClick={() => dispatch(setDisplayProfileContent('canceled'))}>
                    Смотреть еще {blackList.length - displayedBlackList.length} даты
                  </button>
                )}
                <button className={Styles.more} onClick={() => dispatch(setDisplayProfileContent('cancel'))}>
                  Отменить
                </button>
              </div>
            )}
          </li>
        </ul>
      </div>
      <button className={Styles.logout} onClick={handleOutClick}>
        Выйти из аккаунта
      </button>
    </div>
  )
}
