import React, { useEffect, useRef } from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import { AppointmentPagination } from './components/AppointmentPagination'
import { ConsultationFull } from './components/ConsultationFull'
import { TypeOfConsultation } from './components/TypeOfConsultation'
import { DateOfConsultation } from './components/DateOfConcultation'
import { PersonalData } from './components/PersonalData'
import sctollToTop from '@helpers/scrollToTop'
import useWindowSize from '@helpers/useWindowSizeHook'

export const AppointmentPage = () => {
  const list = useAppSelector((state) => state.choieConsultationPage.list)
  const loggedIn = useAppSelector((state) => state.auth.user.isLoggedIn)

  const step0 = list[0].isActive
  const step1 = list[1].isActive
  const step2 = list[2].isActive
  const step3 = list[3].isActive
  const component = useRef<HTMLDivElement>(null)
  const { width } = useWindowSize()
  const desktop = width >= 829
  const topSize = desktop ? 0 : 70
  useEffect(() => {
    sctollToTop(component, topSize)
  }, [list])

  return (
    <div className={`${Styles.main_container} ${loggedIn && Styles.main_auth}`} ref={component}>
      <AppointmentPagination />
      {step0 && <ConsultationFull />}
      {step1 && <TypeOfConsultation />}
      {step2 && <DateOfConsultation />}
      {step3 && <PersonalData />}
    </div>
  )
}
