import React, { useEffect, useState } from 'react'
import closeButton from '@icons/close.svg'
import { DrawerPanel } from '@components/Drawer'
import Styles from './style.module.scss'
import Parser from 'html-react-parser'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getNutraceuticalById } from '../../../../pages/authorization/redux/authSlice'
import { BorderLine } from '@components/ui/BorderLine'
import declination from '../../../../helpers/declination'
import { LinkToCatalog } from '@components/Conclusion/ui/LinkToCatalog'
import { getProductById } from '../../../../pages/catalog/redux/productSlice'
import { getMyCart, postCart, setDataSmallCatalog } from '../../../../pages/cart/redux/cartSlicer'
import { ProductDrawer } from '../ProductDrawer'
import { clearPlannedDataProdactsToBy } from '../../../../pages/my-appointment/redux/appointmentByIdSlicer'
import useWindowSize from '@helpers/useWindowSizeHook'

export const NutraceuticalsDrawer = (props) => {
  const dispatch = useAppDispatch()
  const plannedDataProdactsToBy = useAppSelector((state) => state.myAppointmentById.plannedDataProdactsToBy)
  const dataProduct = useAppSelector((state) => state.cart.dataSmallCatalog)
  const { width } = useWindowSize()
  const desktop = width >= 829
  const [data, setData] = useState([])
  const [total, setTotal] = useState({ price: 0, discountPrise: 0 })
  const [showDetail, setShowDetail] = useState(false)
  const [detailItem, setDetailItem] = useState(null)
  const [detailNutrInfo, setDetailNutrInfo] = useState(null)
  const [showDrawerCatalog, setShowDrawerCatalog] = useState(false)
  const [inCart, setInCart] = useState(false)
  const getProduct = () => {
    props.data.map((item) => {
      if (item.nutraceutical.product) {
        dispatch(getProductById(item.nutraceutical.product)).then((res) => dispatch(setDataSmallCatalog(res.payload)))
      }
    })
  }
  const openDetails = (e) => {
    dispatch(getNutraceuticalById(e.target.id)).then((res) => {
      setShowDetail(true)
      setDetailItem(res.payload.data)
    })
    const item = props.data.find((item) => item.nutraceutical.id === Number(e.target.id))
    setDetailNutrInfo(item)
  }
  useEffect(() => {
    if (dataProduct.length === 0) {
      getProduct()
    }
  }, [])
  const [isActive, setIsActive] = useState('all')
  const [list, setList] = useState([])
  const setFilterNutraceutical = () => {
    const collection = props.data.map((item) => {
      return item.periodicity.title
    })
    const a = new Set(collection)
    const b = Array.from(a)

    const filterNutraceutical = b.map((key) => {
      return { id: key, arr: [] }
    })

    props.data.map((item) => {
      filterNutraceutical.map((i) => {
        if (i.id === item.periodicity.title) {
          return i.arr.push(item)
        }
      })
    })
    setList(filterNutraceutical)

    filterNutraceutical.map((item) => {
      if (isActive === item.id) {
        setData(item.arr)
      } else if (isActive === 'all') {
        setData(props.data)
      }
    })
  }

  useEffect(() => {
    setFilterNutraceutical()
  }, [isActive])

  useEffect(() => {
    if (dataProduct.length > 0) {
      setTotal({
        price: dataProduct.reduce((acc, curr) => acc + curr.variants[0].modifications[0].price, 0),
        discountPrise: dataProduct.reduce((acc, curr) => acc + curr.variants[0].modifications[0].discount_price, 0),
      })
    }
  }, [dataProduct])
  const addProduct = () => {
    if (plannedDataProdactsToBy.length > 0) {
      plannedDataProdactsToBy.map((item) => {
        if (item.checked) {
          dispatch(
            postCart({
              product: item.prodact,
              quantity: item.quantity,
            }),
          ).then(() => setInCart(true))
        }
      })
      dispatch(clearPlannedDataProdactsToBy())
      dispatch(getMyCart())
      if (desktop) {
        setShowDrawerCatalog(false)
      }
    }
  }

  const countTotal = (items) => items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0)
  const countTotalDiscount = (items) => items.reduce((acc, curr) => acc + curr.quantity * curr.discount_price, 0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalDiscountPrice, setDiscountTotalPrice] = useState(0)
  useEffect(() => {
    if (plannedDataProdactsToBy.length > 0) {
      setTotalPrice(countTotal(plannedDataProdactsToBy))
      setDiscountTotalPrice(countTotalDiscount(plannedDataProdactsToBy))
    }
  })

  return (
    <>
      <div className={Styles.button__container}>
        {dataProduct && dataProduct.length > 0 && (
          <LinkToCatalog
            price={total.price}
            discountPrice={total.discountPrise}
            quantity={declination(dataProduct.length, 'товар', 'товара', 'товаров')}
            onClick={() => setShowDrawerCatalog(true)}
            context='товары'
            fixed
          />
        )}
        <div
          className={`${Styles.button} ${isActive === 'all' && Styles.button_active}`}
          onClick={() => setIsActive('all')}
        >
          <input type='button' value='Все' className={Styles.button__filter} />
          <span>{props.data.length}</span>
        </div>
        {list.map((item, i) => (
          <div
            className={`${Styles.button} ${isActive === item.id && Styles.button_active}`}
            onClick={() => setIsActive(item.id)}
            key={i}
          >
            <input type='button' value={item.id} className={Styles.button__filter} />
            <span>{item.arr.length}</span>
          </div>
        ))}
      </div>
      {data.map((item) => (
        <div key={item.id}>
          <div className={Styles.container}>
            <h2 className={Styles.title}>{item.nutraceutical.name}</h2>
            <div>
              <h5 className={Styles.key}>Прием</h5>
              <div className={Styles.value}>{`${item.reception.title}, ${item.meal.title}`}</div>
            </div>
            <div>
              <h5 className={Styles.key}>Количество и периодичность</h5>
              <div className={Styles.value}>{`${declination(item.amount, 'таблетка', 'таблетки', 'таблеток')} ${
                item.periodicity.title
              }`}</div>
            </div>
            <div>
              <h5 className={Styles.key}>Курс</h5>
              <div className={Styles.value}>{`${declination(item.course_duration, 'день', 'дня', 'дней')}`}</div>
            </div>
            {item.remark && (
              <div>
                <h5 className={Styles.key}>Заметка</h5>
                <div className={Styles.value}>{item.remark}</div>
              </div>
            )}
            <input
              className={Styles.input}
              id={item.nutraceutical.id}
              value='Смотреть состав и показания'
              onClick={openDetails}
              type='button'
            />
            <BorderLine />
          </div>
        </div>
      ))}
      <ProductDrawer
        setShowDrawerCatalog={setShowDrawerCatalog}
        showDrawerCatalog={showDrawerCatalog}
        activeCatalog='product'
        dataProduct={dataProduct}
        addProduct={addProduct}
        totalPrice={totalPrice}
        totalDiscountPrice={totalDiscountPrice}
        plannedDataProdactsToBy={plannedDataProdactsToBy}
        inCart={inCart}
      />
      {detailItem && (
        <DrawerPanel
          width='100%'
          anchor='right'
          open={showDetail}
          onClose={() => {
            setShowDetail((isOpen) => !isOpen)
          }}
        >
          <div className={Styles.drawer}>
            <div className={Styles.title__container}>
              <button
                className={Styles.button__arrow}
                onClick={() => {
                  setShowDetail(false)
                }}
              />
              <h1 className={Styles.title}>{detailItem.name}</h1>
              <img
                src={closeButton}
                alt='img'
                className={Styles.closeButton}
                onClick={() => {
                  setShowDetail(false)
                }}
              />
            </div>
            <div className={Styles.container__details}>
              <div>
                <h5 className={Styles.key}>Прием</h5>
                <div className={Styles.value}>{`${detailNutrInfo.reception.title}, ${detailNutrInfo.meal.title}`}</div>
              </div>
              <div>
                <h5 className={Styles.key}>Количество и периодичность</h5>
                <div className={Styles.value}>{`${declination(
                  detailNutrInfo.amount,
                  'таблетка',
                  'таблетки',
                  'таблеток',
                )} ${detailNutrInfo.periodicity.title}`}</div>
              </div>
              <div>
                <h5 className={Styles.key}>Курс</h5>
                <div className={Styles.value}>{`${declination(
                  detailNutrInfo.course_duration,
                  'день',
                  'дня',
                  'дней',
                )}`}</div>
              </div>
              {detailNutrInfo.remark && (
                <div>
                  <h5 className={Styles.key}>Заметка</h5>
                  <div className={Styles.value}>{detailNutrInfo.remark}</div>
                </div>
              )}
              <div>
                <h4 className={`${Styles.title} ${Styles.title_mr}`}>Состав</h4>
                <div className={Styles.description}>{Parser(detailItem.description)}</div>
                <h4 className={Styles.title}>Показания</h4>
                <p className={Styles.text}>{detailItem.small_description}</p>
              </div>
            </div>
          </div>
        </DrawerPanel>
      )}
    </>
  )
}
