import React, { useState } from 'react'
import Styles from './style.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import { DeleteComponent } from '../DeleteComponent'
import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import useWindowSize from '@helpers/useWindowSizeHook'
import { DrawerMobile } from '@components/ui/DrawerMobile'
import { DeleteButton } from '../../ui/DeleteButton'
import { CounterResult } from '../CounterResult'
import { Link } from 'react-router-dom'

export const ProductItem = ({ item, changeSelectVariant }) => {
  const { width } = useWindowSize()
  const desktop = width >= 829
  const [showModal, setShowModal] = useState(false)
  const [activeDeleteComponent, setActiveDeleteComponent] = useState(false)
  const [activeVariant, setActiveVariant] = useState(null)

  return (
    <div className={Styles.container}>
      <div className={Styles.wrap}>
        <Link to={`/catalog/nutraceutical/${item.product_variants.id}`} className={Styles.link}>
          <img className={Styles.img_main} src={`${item.image}`} alt={item.product_variants.name} />
        </Link>
        <div className={Styles.description__container}>
          <Link to={`/catalog/nutraceutical/${item.product_variants.id}`} className={Styles.link}>
            <h3>{item.product_variants.name}</h3>
          </Link>
          {(item.product_variants.variants.length > 1 ||
            item.product_variants.variants.some((item) => item.mods.length > 1)) && (
            <div>
              {desktop ? (
                <p className={Styles.description}>
                  {item.variant_name}: <span>{item.variant_value}</span>
                </p>
              ) : (
                <div className={Styles.button__container}>
                  <p className={Styles.description}>{item.variant_name}:</p>
                  <ButtonTransparent
                    value={item.variant_value}
                    after
                    onClick={() => {
                      setActiveVariant(item.variant_name)
                      setShowModal(true)
                    }}
                    styleImg={Styles.arrow}
                    styleButton={Styles.button_mobile}
                  />
                </div>
              )}
              {desktop && (
                <div className={Styles.variants}>
                  {item.product_variants.variants.map(
                    (variant, id) =>
                      variant.mods.every((item) => item.in_stock) && (
                        <button
                          key={id}
                          className={`${Styles.variant} ${
                            variant.value === item.variant_value && Styles.variant_active
                          }`}
                          onClick={() => changeSelectVariant(variant.value, item, 'variant_value', variant)}
                        >
                          <img className={Styles.image} src={variant.image} alt='img' />
                        </button>
                      ),
                  )}
                </div>
              )}
              {desktop ? (
                <p className={Styles.description}>
                  {item.mod_name}: <span>{item.mod_value}</span>
                </p>
              ) : (
                <div className={Styles.button__container}>
                  <p className={Styles.description}>{item.mod_name}:</p>
                  <ButtonTransparent
                    value={item.mod_value}
                    after
                    onClick={() => {
                      setActiveVariant(item.mod_name)
                      setShowModal(true)
                    }}
                    styleImg={Styles.arrow}
                    styleButton={Styles.button_mobile}
                  />
                </div>
              )}

              {desktop && (
                <div className={Styles.variants}>
                  {item.product_variants.variants.map((variant, id) => (
                    <div key={id}>
                      {variant.value === item.variant_value && (
                        <>
                          {variant.mods.map(
                            (mod, id) =>
                              mod.in_stock && (
                                <button
                                  key={id}
                                  className={`${Styles.variant} ${
                                    mod.value === item.mod_value && Styles.variant_active
                                  }`}
                                  onClick={() => changeSelectVariant(mod.value, item, 'value', mod)}
                                >
                                  {mod.value}
                                </button>
                              ),
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {item.in_stock ? (
        <CounterResult arrNamePatch='product' item={item} />
      ) : (
        <div className={Styles.availability}>
          <span>Нет в наличии</span>
        </div>
      )}
      <DeleteButton onClick={() => setActiveDeleteComponent(true)} />
      {activeDeleteComponent && (
        <DeleteComponent
          name={item.product_variants.name}
          setActiveDeleteComponent={setActiveDeleteComponent}
          // deletedItem={deletedItem}
          id={item.id}
          arrNameDelete='product'
        />
      )}
      <DrawerMobile
        width='100%'
        anchor='bottom'
        open={showModal}
        onClose={() => {
          setShowModal((isOpen) => !isOpen)
        }}
      >
        <div className={Styles.selecting}>
          <h2 className={Styles.selecting__title}>{`Выберите ${
            activeVariant === item.variant_name ? item.variant_name : item.mod_name
          }`}</h2>
          <Swiper slideClass={Styles.slider__item} slidesPerView={3}>
            {item.product_variants.variants.map((variant, id) => (
              <div key={id}>
                {activeVariant === item.variant_name && variant.mods.every((item) => item.in_stock) && (
                  <SwiperSlide key={id} style={{ width: 'auto' }}>
                    <button
                      onClick={() => changeSelectVariant(variant.value, item, 'variant_value', variant)}
                      className={`${Styles.variant} ${Styles.select__type} ${
                        variant.value === item.variant_value && Styles.variant_active
                      }`}
                    >
                      <img className={Styles.image} src={variant.image} alt='img' />
                      <h6>{variant.value}</h6>
                    </button>
                  </SwiperSlide>
                )}
                {activeVariant === item.mod_name && variant.value === item.variant_value && (
                  <>
                    {variant.mods.map((mod, id) => (
                      <button
                        key={id}
                        className={`${Styles.variant} ${mod.value === item.mod_value && Styles.variant_active}`}
                        onClick={() => changeSelectVariant(mod.value, item, 'value', mod)}
                      >
                        {mod.value}
                        <div>{mod.discount_price} ₽</div>
                      </button>
                    ))}
                  </>
                )}
              </div>
            ))}
          </Swiper>
        </div>
      </DrawerMobile>
    </div>
  )
}
