import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { updateNotifications } from '../../../pages/authorization/redux/authSlice'
import { API_CONFIG } from '@app/api'

let socket: WebSocket = null

export const useNotifications = () => {
  const dispatch = useAppDispatch()
  const { access } = useAppSelector((state) => state.auth.user.token)

  useEffect(() => {
    const connect = () => {
      if (!socket && access) {
        socket = new WebSocket(`wss://${API_CONFIG.hostURL}/ws/notifications/?token=${access}`)
      }
    }
    connect()
  }, [access])
  if (socket) {
    socket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data)
      dispatch(updateNotifications(data))
    }
  }
  return useNotifications
}
