import { ButtonWithBorder } from '@components/ui/ButtonWithBorder'
import useWindowSize from '@helpers/useWindowSizeHook'
import React, { useState } from 'react'
import Styles from './style.module.scss'
import closeButton from '@icons/close.svg'

export const LinkToCatalog = ({ price, discountPrice, quantity, onClick, context = '', fixed = false }) => {
  const { width } = useWindowSize()
  const desktop = width >= 829
  const [visible, setVisible] = useState(true)
  return (
    <>
      {visible && (
        <div className={`${Styles.marketing__block} ${fixed && Styles.fixed}`}>
          {fixed && (
            <img
              src={closeButton}
              alt='img'
              className={Styles.closeButton}
              onClick={() => {
                setVisible(false)
              }}
            />
          )}
          <div>
            <p className={Styles.text}>{`Всего ${quantity}`}</p>
            <div className={Styles.prise_container}>
              <span className={Styles.prise_sale}>{discountPrice} ₽</span>
              <span className={Styles.price}>{price} ₽</span>
            </div>
            {desktop && <div className={Styles.discount}>– {price - discountPrice} ₽</div>}
          </div>
          {desktop ? (
            <ButtonWithBorder value={`Выбрать ${quantity}`} onClick={onClick} />
          ) : (
            <ButtonWithBorder value={`Выбрать ${context}`} onClick={onClick} />
          )}
        </div>
      )}
    </>
  )
}
