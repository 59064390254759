import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import arrow from '@icons/arrow-large-blue.svg'
import close from '@icons/close-blue.svg'
import { Link, useParams } from 'react-router-dom'
import { DrawerPanel } from '@components/Drawer'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import warning from '@icons/warning.svg'
import pencil from '@icons/pencil.svg'
import { AppointmentInformation } from '../AppointmentInformation'
import { TotalInformation } from '../AppointmentDetails/components/TotalInformation'
import { DoctorInformation } from '../../../appointment-page/components/TypeOfConsultation/components/DoctorInfo'
import { TotalDetails } from '../AppointmentDetails/components/TotalDetails'
import { BorderLine } from '@components/ui/BorderLine'
import { ButtonColor } from '@components/ui/ButtonColor'
import { addAppointmentFile, editAppointmentById } from '../../../appointment-page/redux/consultationSlicer'
import { getAppointmentById, setMedicalReportId } from '../../redux/appointmentByIdSlicer'
import { getMedicalReportById } from '../../../user/redux/userSpaceSlicer'
import moment from 'moment'
import { ReminderFormComponent } from '@components/ReminderFormComponent'
import { LoadRender } from '@components/LoadRender'
import { noop } from '../../../../utils/function'

export const DrawerMobileById = () => {
  const dispatch = useAppDispatch()
  const idAppointment = useAppSelector((state) => state.myAppointmentById.selectById)
  const [showEditDrawer, setShowEditDrawer] = useState(false)
  const [data, setDataById] = useState(null)
  const [description, setDescription] = useState(data?.description)
  const [medRepId, setMedRepId] = useState(null)
  const { id } = useParams<{ id: string }>()
  const [show, setShow] = useState(false)
  const [allFiles, setAllFiles] = useState(null)
  const [file, setFile] = useState(null)

  useEffect(() => {
    dispatch(getAppointmentById(id))
      .then((res) => {
        setDataById(res.payload)

        setAllFiles(res.payload.files)

        setDescription(res.payload.description)
        if (res.payload.medical_report) {
          dispatch(setMedicalReportId(res.payload.medical_report.id))
          setMedRepId(res.payload.medical_report.id)
        }
      })
      .then(() => {
        setShow(true)
      })
      .catch((error) => {
        throw new Error(error)
      })
  }, [id])

  useEffect(() => {
    if (medRepId) {
      dispatch(getMedicalReportById(medRepId)).then((res) => setDataMedicalReport(res.payload))
    }
  }, [medRepId])
  useEffect(() => {}, [description])
  const updateAppointment = () => {
    dispatch(editAppointmentById({ id: data.id, data: { description } })).then((res) => {
      setDescription(res.payload.description)
    })
    setShowEditDrawer(false)
  }

  const [dataMedicalReport, setDataMedicalReport] = useState(null)

  const dateFomat = moment(data?.day_reception, 'YYYY-MM-DD').format('D MMMM')
  const [error, setError] = useState(false)
  const sendFile = (body) => {
    dispatch(addAppointmentFile({ id: data.id, data: body }))
      .then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          setError(true)
        }
        dispatch(getAppointmentById(id))
          .then((res) => {
            setDataById(res.payload)
            setAllFiles(res.payload.files)
            setDescription(res.payload.description)
            if (res.payload.medical_report) {
              dispatch(setMedicalReportId(res.payload.medical_report.id))
              setMedRepId(res.payload.medical_report.id)
            }
          })
          .then(() => {
            setShow(true)
          })
          .catch((error) => {
            throw new Error(error)
          })
      })
      .catch(() => noop)
  }
  return (
    <>
      {data && (
        <>
          <DrawerPanel width='100%' anchor='left' open={show} onClose={() => setShow(true)}>
            <div className={Styles.container}>
              <div className={Styles.navigation}>
                <Link className={Styles.arrow} to='/my-appointment'>
                  <img src={arrow} alt='arrow' />
                </Link>

                <h3 className={Styles.title}>Запись на прием</h3>
                <Link className={Styles.close} to='/my-appointment'>
                  <img src={close} alt='close' />
                </Link>
              </div>
              <div className={Styles.content}>
                <AppointmentInformation data={data} />
                {data?.status !== 'done' && <ReminderFormComponent appointmentId={idAppointment} />}
                <TotalInformation data={data} dataMedicalReport={dataMedicalReport} />
                {!(data?.status === 'done' && !description) && (
                  <div className={Styles.details__container}>
                    <h4 className={Styles.subtitle}>Ваше сообщение к консультации</h4>
                    <div className={Styles.input_container}>
                      <p className={Styles.input}>
                        {description === ''
                          ? 'Расскажите о своей проблеме подробнее или прикрепите результаты обследования. Врач получит эту информацию до приема'
                          : description}
                      </p>
                    </div>
                    <BorderLine />
                    {allFiles && <LoadRender editLoadDescription={false} data={allFiles} setFile={setFile} />}
                    {(data?.status === 'planned' || data?.status === 'waiting_paying') && (
                      <div>
                        <div className={Styles.edit__container}>
                          <p className={Styles.text__warning}>
                            <img src={warning} alt='img' />
                            Вы можете изменить свое сообщение или прикрепить файлы до {dateFomat} {data?.time_reception}
                          </p>

                          <button onClick={() => setShowEditDrawer(true)} className={Styles.edit__button}>
                            <img src={pencil} alt='img' />
                            Редактировать
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <DoctorInformation
                  name={`${data?.doctor?.first_name} ${data?.doctor?.last_name}`}
                  prof={data?.doctor?.specialty}
                  expir={data?.doctor?.years_experience}
                  count={data?.doctor?.number_conducted_consultations}
                  avatar={data?.doctor?.avatar}
                />
                <TotalDetails data={data} />
                {/* (data?.status === 'planned' || data?.status === 'waiting_paying') && (
                  <p className={Styles.mark}>
                    Изменить дату и время приема можно, написав в техническую поддержку сервиса
                  </p>
                ) */}
              </div>
            </div>
          </DrawerPanel>
          <DrawerPanel width='100%' anchor='left' open={showEditDrawer} onClose={() => setShowEditDrawer(false)}>
            <div className={Styles.container__edit}>
              <div className={Styles.navigation}>
                <button className={Styles.arrow_edit} onClick={() => setShowEditDrawer(false)}>
                  <img src={arrow} alt='img' />
                </button>

                <h3 className={Styles.title}>Сообщение к консультации</h3>
                {/* <button className={Styles.close} onClick={() => setShowEditDrawer(false)}>
                  <img src={close} alt='close' />
              </button> */}
              </div>
              <div className={Styles.content}>
                <textarea
                  className={`${Styles.input} ${Styles.input_edit}`}
                  value={description}
                  placeholder='Расскажите о своей проблеме подробнее или прикрепите результаты обследования. Врач получит эту информацию до приема'
                  onChange={(e) => setDescription(e.target.value)}
                />
                <LoadRender editLoadDescription data={allFiles} setFile={setFile} />
                <div className={Styles.button__container}>
                  <ButtonColor
                    value='Сохранить'
                    onClick={() => {
                      updateAppointment()
                      sendFile(file)
                    }}
                  />
                  {error && (
                    <span className={Styles.error}>
                      При отправке файла произошла ошибка, пожалуйста, попробуйте еще раз
                    </span>
                  )}
                </div>
              </div>
            </div>
          </DrawerPanel>
        </>
      )}
    </>
  )
}
