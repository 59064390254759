import { Nutrition } from '@components/Conclusion/components/Nutrition'
import React from 'react'
import Styles from './style.module.scss'

export const NutritionDrawer = (props) => {
  return (
    <div className={Styles.container}>
      <Nutrition data={props.data} />
    </div>
  )
}
