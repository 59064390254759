import moment from 'moment'
import React from 'react'
import Styles from './style.module.scss'

export const WelcomeId = (props) => {
  const today = moment().format('DD MMMM')
  return (
    <div className={Styles.container}>
      <h2 className={Styles.title}>Привет, {props.name} 👋</h2>
      <p className={Styles.text}>
        Сегодня {today}
        {props.reportId ? ', наши планы на эту неделю' : null}
      </p>
    </div>
  )
}
