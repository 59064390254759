import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getAppointment, setSelectedDayMonth } from '../../../../redux/doctorSlicer'
import Styles from './style.module.scss'

import { DoctorRecordMonth } from './components/DoctorRecordMonth'
import { DoctorToday } from './components/DoctorToday'
import { DoctorRecord } from '../DoctorDay/components/DoctorRecord'

export const DoctorMonth: React.FC = () => {
  const dispatch = useAppDispatch()
  const appointmentsMonth = useAppSelector((state) => state.doctor.appointmentsMonth)
  const [selectedDay, setSelectedDay] = useState(false)
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [dataDay, setDataDay] = useState({})

  const onClickDay = (data) => {
    dispatch(setSelectedDayMonth(data.day))
    setSelectedDay(true)
    setSelectedAppointment(null)
    setDataDay(data)
  }

  const onClickReception = async (appointmentId) => {
    const response = await dispatch(getAppointment(appointmentId))
    setSelectedAppointment(response.payload.data)
  }

  useEffect(() => {
    dispatch(setSelectedDayMonth(null))
  }, [])

  return (
    <div className={Styles.schedule}>
      <div className={Styles.wrapper}>
        <div className={Styles.content}>
          <div className={Styles.daytime}>
            <span className={Styles.day}>Пн</span>
            <span className={Styles.day}>Вт</span>
            <span className={Styles.day}>Ср</span>
            <span className={Styles.day}>Чт</span>
            <span className={Styles.day}>Пт</span>
            <span className={Styles.day}>Сб</span>
            <span className={Styles.day}>Вс</span>
          </div>
          <div className={Styles.cells}>
            {appointmentsMonth.map((monthDay) => (
              <DoctorRecordMonth
                key={monthDay.day}
                onClickDay={onClickDay}
                countAppointment={monthDay.count_appointment}
                day={monthDay.day}
                isAvailable={monthDay.is_available}
                receptions={monthDay.receptions}
              />
            ))}
          </div>
        </div>
        <div className={Styles.signs}>
          <div className={Styles.sign}>
            <i className={Styles.icon}>{}</i>
            <span className={Styles.denotation}>— онлайн прием</span>
          </div>
          <div className={Styles.sign}>
            <i className={`${Styles.icon} ${Styles.icon_mod}`}>{}</i>
            <span className={Styles.denotation}>— личный прием</span>
          </div>
        </div>
      </div>
      <div className={Styles.info}>
        {selectedDay && <DoctorToday dataDay={dataDay} onClickReception={onClickReception} />}
        {selectedAppointment && <DoctorRecord appointment={selectedAppointment} className={Styles.record_month} />}
      </div>
    </div>
  )
}
