import React, { useState, useEffect, FC } from 'react'
import Styles from './style.module.scss'
import OtpInput from 'react-otp-input'
import { registerUserNumber, tokenObtaining } from '../../redux/authSlice'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { SERVER_ERROR, SUCCESS } from '@app/HTTP_CODES'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router'
import useWindowSize from '@helpers/useWindowSizeHook'
import { MOBILE_BREAKPOINT } from '../../../../global-constants'

interface InputSmsFormProps {
  setCurrentPage: (number) => void
  values: any
}

export const InputSmsForm: FC<InputSmsFormProps> = ({ setCurrentPage, values }) => {
  const isNewUser = useAppSelector((state) => state.auth.user.isNewUser)
  const history = useHistory()
  const { width } = useWindowSize()
  const isDesktop = width >= MOBILE_BREAKPOINT
  const [otpValue, setOtpValue] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userNumber, setUserNumber] = useState(values.phone)
  const [isTimeIsUp, setIsTimeIsUp] = useState(false)
  const [isError, setIsError] = useState(false)

  const [seconds, setSeconds] = React.useState(60)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const tick = () => setSeconds(seconds - 1)
    let timeout
    if (seconds > 0) {
      timeout = setTimeout(tick, 1000)
    } else {
      setIsTimeIsUp(true)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [seconds])

  const secondsConverter = (seconds) => {
    // eslint-disable-next-line no-unused-expressions
    return seconds >= 10 ? `00:${seconds}` : `00:0${seconds}`
  }

  const handleOtpChange = (otp) => {
    setIsError(false)
    setOtpValue(otp)
    if (otp.length === 4) {
      dispatch(tokenObtaining({ phone: userNumber, code: otp }))
        .then((res) => {
          if (res.payload.status === SUCCESS) {
            isNewUser ? setCurrentPage(3) : location.pathname === '/login' && history.push('/')
          } else if (res.payload.status === SERVER_ERROR) {
            const message = `Сервер недоступен, повторите попытку позже`
            const type = 'error'
            enqueueSnackbar(
              { message, type },
              {
                autoHideDuration: 1500,
              },
            )
          } else {
            const message = `Неверный  код`
            const type = 'error'
            enqueueSnackbar(
              { message, type },
              {
                autoHideDuration: 1500,
              },
            )
            setIsError(true)
          }
        })
        .catch(() => {
          const message = `Что-то пошло не так. Проверьте соединение с интернетом`
          const type = 'error'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        })
    }
  }

  const getNewCode = (e) => {
    e.preventDefault()
    setIsError(false)
    dispatch(registerUserNumber({ phone: userNumber }))
      .then((res) => {
        if (res.payload.status === SUCCESS) {
          setSeconds(60)
          setIsTimeIsUp(false)
        } else if (res.payload.status === SERVER_ERROR) {
          const message = `Сервер недоступен, повторите попытку позже`
          const type = 'error'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        } else {
          const message = `Неверный номер телефона`
          const type = 'error'
          enqueueSnackbar(
            { message, type },
            {
              autoHideDuration: 1500,
            },
          )
        }
      })
      .catch(() => {
        const message = `Что-то пошло не так. Проверьте соединение с интернетом`
        const type = 'error'
        enqueueSnackbar(
          { message, type },
          {
            autoHideDuration: 1500,
          },
        )
      })
  }

  return (
    <>
      <h3 className={Styles.form__title}>Введите код из смс</h3>
      <p className={isDesktop ? `${Styles.form__info}` : `${Styles.form__info} ${Styles.form__info_centered}`}>
        Мы отправили код подтверждения на номер <span className={Styles.form__phone}>{userNumber}</span>
        {isDesktop && (
          <button className={Styles.form__blueButton} onClick={() => setCurrentPage((prev) => prev - 1)}>
            Изменить
          </button>
        )}
      </p>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <OtpInput
        value={otpValue}
        onChange={handleOtpChange}
        numInputs={4}
        containerStyle={Styles.otp__container}
        inputStyle={Styles.otp__input}
        isInputNum
        placeholder='    '
        shouldAutoFocus
      />
      {isError && <p className={Styles.form__error}>Неверный код подтверждения</p>}
      <div className={Styles.codeWrapper}>
        {isTimeIsUp ? (
          <button className={Styles.form__blueButton} onClick={getNewCode}>
            Получить новый код
          </button>
        ) : (
          <p className={Styles.form__paragraph}>Получить новый код можно через {secondsConverter(seconds)}</p>
        )}
      </div>
      {!isDesktop && (
        <button
          className={`${Styles.form__blueButton} ${Styles.form__blueButton_place_bottom}`}
          onClick={() => setCurrentPage((prev) => prev - 1)}
        >
          Изменить номер
        </button>
      )}
    </>
  )
}
