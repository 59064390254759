import React from 'react'
import Styles from './style.module.scss'
import closeButton from '@icons/close.svg'
import { DrawerPanel } from '@components/Drawer'
import { ProductItemSmall } from '../ProductItemSmall'
import cartIcon from '@icons/cart.svg'
import { useAppDispatch } from '@app/redux/hooks'
import { clearPlannedDataProdactsToBy } from '../../../../pages/my-appointment/redux/appointmentByIdSlicer'
import { NotificationInCart } from '@components/Conclusion/ui/NotificationInCart'

export const ProductDrawer = ({
  setShowDrawerCatalog,
  showDrawerCatalog,
  activeCatalog,
  dataProduct,
  addProduct,
  totalPrice,
  totalDiscountPrice,
  plannedDataProdactsToBy,
  inCart = false,
}) => {
  const dispatch = useAppDispatch()
  return (
    <DrawerPanel
      width='100%'
      anchor='right'
      open={showDrawerCatalog}
      onClose={() => {
        setShowDrawerCatalog((isOpen) => !isOpen)
        dispatch(clearPlannedDataProdactsToBy())
      }}
    >
      <div className={Styles.container}>
        {inCart && <NotificationInCart />}

        <div className={Styles.title__container}>
          {activeCatalog === 'product' && (
            <h1 className={Styles.title}>
              Выбрать товары<span>{dataProduct.length}</span>
            </h1>
          )}
          <img
            src={closeButton}
            alt='img'
            className={Styles.closeButton}
            onClick={() => {
              setShowDrawerCatalog(false)
              dispatch(clearPlannedDataProdactsToBy())
            }}
          />
        </div>
        <div className={Styles.content__container}>
          <div>{dataProduct && dataProduct.map((item, idx) => <ProductItemSmall key={idx} item={item} />)}</div>
        </div>
        <div className={Styles.fixed__bottom}>
          {plannedDataProdactsToBy.length > 0 && (
            <div className={Styles.block}>
              <span className={Styles.old}>{totalPrice} ₽</span>

              <span className={Styles.price}>{totalDiscountPrice} ₽</span>
              <div className={Styles.discount}>– {totalPrice - totalDiscountPrice} ₽</div>
            </div>
          )}
          <button className={Styles.cart} onClick={addProduct} disabled={plannedDataProdactsToBy.length === 0}>
            <img src={cartIcon} alt='Иконка' />
            <span>В корзину</span>
          </button>
        </div>
      </div>
    </DrawerPanel>
  )
}
