/* eslint-disable camelcase */
import React, { FC } from 'react'
import styles from './style.module.scss'
import { formateDate } from '../../../../../../utils/formateDate'

interface OrderShippingInfoProps {
  targetOrder: any
}

export const OrderShippingInfo: FC<OrderShippingInfoProps> = ({ targetOrder }) => {
  const { to_address, delivery_dates, pvz_address } = targetOrder
  const upperTitle = pvz_address === '' ? 'Доставка курьером' : 'Доставка в пункт выдачи'
  const address = pvz_address === '' ? to_address : pvz_address
  return (
    <div className={styles.orderShippingInfo}>
      <h4 className={styles.title}>{upperTitle}</h4>
      <div className={styles.infoBlock}>
        <p className={styles.label}>Адрес</p>
        <p className={styles.value}>{address}</p>
        <p className={styles.label}>Дата доставки</p>
        <p className={styles.value}>
          {delivery_dates &&
            `${formateDate(delivery_dates.from, 'DD MMMM')} - ${formateDate(delivery_dates.to, 'DD MMMM')}`}
        </p>
        <p className={styles.label}>Стоимость</p>
        <p className={styles.value}>{targetOrder.total_cost && targetOrder.total_cost.toLocaleString()} ₽</p>
      </div>
    </div>
  )
}
