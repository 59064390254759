import React from 'react'
import Styles from './style.module.scss'
import { API_IMAGE_URL } from '@app/api'

interface AvatarProps {
  avatar?: string
  firstName?: string
  lastName?: string
  size?: string
  fontSize?: string
}

export const Avatar: React.FC<AvatarProps> = ({ avatar, firstName, lastName, size, fontSize }) => {
  const avatarSrc = avatar?.startsWith('h') ? avatar : `${API_IMAGE_URL}${avatar}`
  return avatar ? (
    <img src={avatarSrc} alt='аватар' className={Styles.avatar} style={{ width: size, height: size }} />
  ) : (
    <div className={Styles.initials} style={{ width: size, height: size, fontSize }}>
      {`${firstName?.substring(0, 1).toUpperCase() || ''}${lastName?.substring(0, 1).toUpperCase() || ''}`}
    </div>
  )
}
