import React, { useState } from 'react'
import Styles from './style.module.scss'

type DropdownInfoProps = {
  heading: string
  description: string[]
}

export const DropdownInfo: React.FC<DropdownInfoProps> = ({ heading, description }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const handleDropdownClick = () => {
    setIsDropdownOpen((prev) => !prev)
  }
  return (
    <div className={Styles.container}>
      <h1 className={Styles.heading}>{heading}</h1>
      <div className={isDropdownOpen ? `${Styles.dropdown} ${Styles.dropdown_open}` : `${Styles.dropdown}`}>
        {description.map((item, id) => (
          <p key={id} className={Styles.paragraph}>
            {item}
          </p>
        ))}
      </div>
      <span className={Styles.dropdownTrigger} onClick={handleDropdownClick}>
        {isDropdownOpen ? 'Скрыть' : 'Раскрыть'}
      </span>
    </div>
  )
}
