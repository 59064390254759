import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import {
  deleteResearchReport,
  getMedicalReportById,
  getResearch,
  getResearchSettings,
  patchResearchReport,
  postResearchReport,
} from '../redux/reportsSlice'
import { FormStorage } from '../../../utils/form-storage'
import Styles from './style.module.scss'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { useField } from 'react-final-form'
import { arrayUniqueBy } from '@helpers/arrayUniqueBy'
import { ResearchItem } from '../components/ResearchItem'
import { Hint } from '../components/ResearchItem/components/Hint'
import { ResearchForm } from '../components/ResearchForm'

export const FieldValue = ({ name, children }: any) => {
  const value = useFieldValue(name)
  return <>{typeof children === 'function' ? children(value) : value}</>
}

export function useFieldValue(name: string) {
  const { input } = useField(name, { subscription: { value: true } })
  return input.value
}

export const ResearchReport = ({ setStatusReport }) => {
  const medicalReportId = useAppSelector((state) => state.reports.medicalReportId)
  const [savedData, setSavedData] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useAppDispatch()
  const research = useAppSelector((state) => state.reports.research)
  const [initialState, setInitialState] = useState(null)
  useEffect(() => {
    dispatch(getMedicalReportById(medicalReportId)).then((res) => {
      setInitialState(res.payload.data.research_reports)
    })
  }, [])

  const onSubmit = (formValues) => {
    const filterArr = formValues.research.filter((item) => item.selected)
    const filterDelete = formValues.research.filter((item) => !item.selected)
    const filterArrPost = filterArr.filter((item) => !item.update)
    const filterArrPatch = filterArr.filter((item) => item.update)

    if (filterArrPost.length > 0) {
      filterArrPost.map((item) => {
        dispatch(postResearchReport({ medical_report: medicalReportId, research_ids: [item.id] })).then((res) => {
          if (res.payload.status < 400) {
            setStatusReport('control-reception')
            dispatch(
              patchResearchReport({
                id: res.payload.data[0].id,
                data: { remark: item.remark || '', name: item.name, short_name: item.short_name || '' },
              }),
            )
          }
        })
      })
    }
    if (filterDelete.length > 0) {
      filterDelete.map((item) => {
        if (item.update) {
          dispatch(deleteResearchReport(item.id)).then((res) => {
            if (res.payload.status < 400) {
              setStatusReport('control-reception')
            }
          })
        }
      })
    }
    if (filterArrPatch.length > 0) {
      filterArrPatch.map((item) => {
        dispatch(
          patchResearchReport({
            id: item.id,
            data: { remark: item.remark || '', name: item.name, short_name: item.short_name || '' },
          }),
        ).then((res) => {
          if (res.payload.status < 400) {
            setStatusReport('control-reception')
          }
        })
      })
    }

    setStatusReport('control-reception')
  }
  useEffect(() => {
    dispatch(getResearch())
    dispatch(getResearchSettings())
  }, [])

  useEffect(() => {
    if (research) {
      const a = [...research].map((item) => {
        return Object.assign({}, item, {
          medical_report: medicalReportId,
          selected: false,
          short_name: item.short_name,
          remark: item.comment,
        })
      })
      if (initialState) {
        const b = [...initialState].map((item) => {
          return Object.assign({}, item, {
            selected: true,
            update: true,
            short_name: item?.short_name,
            remark: item.remark,
          })
        })

        const arrConcatData = arrayUniqueBy(b.concat(a), (item) => item.name)
        arrConcatData.map((item) => {
          a.map((res) => {
            if (res.name === item.name) {
              item.short_name = res.short_name
            }
            return res
          })
          return item
        })
        setSavedData(arrConcatData)
      } else {
        setSavedData(a)
      }
    }
  }, [research, initialState])
  const initial = {
    research: savedData,
  }

  return (
    <>
      {savedData && (
        <FormStorage
          formId='research'
          onSubmit={onSubmit}
          initialValues={initial}
          mutators={{
            ...arrayMutators,
          }}
        >
          <div className={Styles.container}>
            <div className={Styles.column}>
              <h2 className={Styles.heading}>Поиск обследований</h2>
              <input
                className={Styles.search}
                type='search'
                placeholder='Начните вводить название'
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <div>
                <FieldArray name='research'>
                  {({ fields }) =>
                    fields.map((name, index) => {
                      return (
                        <FieldValue key={name} name={name}>
                          {(value) => {
                            if (value?.id && value.name.match(new RegExp(searchValue, 'i'))) {
                              return (
                                <ResearchItem key={index} item={`${name}`} selected={value.selected}>
                                  <Hint content={`${name}`} />
                                </ResearchItem>
                              )
                            }
                          }}
                        </FieldValue>
                      )
                    })
                  }
                </FieldArray>
              </div>
            </div>
            <div className={Styles.column}>
              <h2 className={Styles.heading}>Выбранные обследования</h2>

              <FieldArray name='research'>
                {({ fields }) =>
                  fields.map((name, index) => {
                    return (
                      <FieldValue key={name} name={name}>
                        {(value) => {
                          return (
                            <div key={index}>
                              {value.selected && (
                                <ResearchForm name={`${name}`}>
                                  <ResearchItem item={`${name}`} index={index} selected={value.selected} />
                                </ResearchForm>
                              )}
                            </div>
                          )
                        }}
                      </FieldValue>
                    )
                  })
                }
              </FieldArray>
            </div>
          </div>
        </FormStorage>
      )}
    </>
  )
}
