import { api } from '@app/api'

type Token = {
  access: string
  refresh: string
}

export const setToken: (token: Token) => void = (token) => {
  localStorage.setItem('token', JSON.stringify(token))
  api.setUserToken(token.access)
}

export const removeToken: () => void = () => {
  localStorage.removeItem('token')
  api.setUserToken()
}
