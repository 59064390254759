import { useAppDispatch } from '@app/redux/hooks'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Styles from './style.module.scss'
import useWindowSize from '@helpers/useWindowSizeHook'
import warning from '@icons/warning.svg'
import pencil from '@icons/pencil.svg'
import { TotalDetails } from './components/TotalDetails'
import { DoctorInformation } from '../../../appointment-page/components/TypeOfConsultation/components/DoctorInfo'
import { TotalInformation } from './components/TotalInformation'
import { ButtonColor } from '@components/ui/ButtonColor'
import { Conclusion } from '@components/Conclusion'
import { AppointmentInformation } from '../AppointmentInformation'
import { getAppointmentById, setMedicalReportId } from '../../redux/appointmentByIdSlicer'
import { addAppointmentFile, editAppointmentById } from '../../../appointment-page/redux/consultationSlicer'
import { getMedicalReportById } from '../../../user/redux/userSpaceSlicer'
import 'react-dropzone-uploader/dist/styles.css'
import moment from 'moment'
import { ReminderFormComponent } from '@components/ReminderFormComponent'
import arrow from '@icons/arrow-right-blue.svg'
import { LoadRender } from '@components/LoadRender'
import { noop } from '../../../../utils/function'

export const AppointmentDetails = () => {
  const { width } = useWindowSize()
  const desktop = width >= 829
  const dispatch = useAppDispatch()
  const [editLoadDescription, setEditLoadDescription] = useState(false)
  const [data, setDataById] = useState(null)
  const [description, setDescription] = useState(null)
  const [dataMedicalReport, setDataMedicalReport] = useState(null)
  const [medRepId, setMedRepId] = useState(null)
  const { id } = useParams<{ id: string }>()

  const updateAppointment = () => {
    dispatch(editAppointmentById({ id: data.id, data: { description } }))
    setEditLoadDescription(false)
  }

  useEffect(() => {
    dispatch(getAppointmentById(id))
      .then((res) => {
        setDataById(res.payload)
        setAllFiles(res.payload.files)
        setDescription(res.payload.description)
        if (res.payload.medical_report) {
          dispatch(setMedicalReportId(res.payload.medical_report.id))
          setMedRepId(res.payload.medical_report.id)
        }
      })
      .catch(() => {
        // console.log(err)
      })
  }, [id])
  useEffect(() => {
    if (medRepId) {
      dispatch(getMedicalReportById(medRepId)).then((res) => setDataMedicalReport(res.payload))
    }
  }, [medRepId])
  const dateFormat = moment(data?.day_reception, 'YYYY-MM-DD').format('D MMMM')
  const [error, setError] = useState(false)
  const sendFile = (body) => {
    dispatch(addAppointmentFile({ id: data.id, data: body }))
      .then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          setError(true)
        } else {
          setError(false)
        }
      })
      .catch(() => noop)
  }

  const [allFiles, setAllFiles] = useState(null)
  const [file, setFile] = useState(null)
  return (
    <>
      {data && (
        <>
          {desktop && (
            <div className={Styles.button_back}>
              <Link className={Styles.link} to='/my-appointment'>
                <img src={arrow} alt='Назад' />
                Назад на главную
              </Link>
            </div>
          )}
          <h1 className={Styles.title}>Запись на прием</h1>
          <div className={Styles.main__conteiner}>
            {data?.consultation_type && (
              <div className={Styles.wrapper__container}>
                {data?.status === 'done' && dataMedicalReport && data?.medical_report?.is_report_done && (
                  <div className={Styles.wrap}>
                    <Conclusion date={data?.day_reception} data={dataMedicalReport} />
                  </div>
                )}
                <AppointmentInformation data={data} />
                {data?.status !== 'done' && <ReminderFormComponent appointmentId={data.id} />}
                {!(data?.status === 'done' && !description) && (
                  <div className={Styles.details__container}>
                    <h4 className={Styles.subtitle}>Ваше сообщение к консультации</h4>
                    <textarea
                      readOnly={!editLoadDescription}
                      className={`${Styles.input} ${editLoadDescription && Styles.input_edit}`}
                      placeholder='Расскажите о своей проблеме подробнее или прикрепите результаты обследований. Врач получит эту информацию до приема'
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                    <div className={Styles.load__container}>
                      <LoadRender editLoadDescription={editLoadDescription} data={allFiles} setFile={setFile} />
                      {(data?.status === 'planned' || data?.status === 'waiting_paying') && (
                        <div>
                          {!editLoadDescription ? (
                            <div className={Styles.edit__container}>
                              <p className={Styles.text__warning}>
                                <img src={warning} alt='img' />
                                Вы можете изменить свое сообщение или прикрепить файлы до {dateFormat}{' '}
                                {data?.time_reception}
                              </p>

                              <button onClick={() => setEditLoadDescription(true)} className={Styles.edit__button}>
                                <img src={pencil} alt='img' />
                                Редактировать
                              </button>
                            </div>
                          ) : (
                            <div className={Styles.button_wrap}>
                              <ButtonColor
                                value='Сохранить'
                                onClick={() => {
                                  updateAppointment()
                                  setEditLoadDescription(false)
                                  sendFile(file)
                                }}
                              />
                            </div>
                          )}
                          {error && (
                            <span className={Styles.error}>
                              При отправке файла произошла ошибка, пожалуйста, попробуйте еще раз
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {data.consultation_type && desktop && (
              <div className={Styles.total}>
                <TotalInformation data={data} />
                <DoctorInformation
                  name={`${data?.doctor?.first_name} ${data?.doctor?.last_name}`}
                  prof={data?.doctor?.specialty}
                  expir={data?.doctor?.years_experience}
                  count={data?.doctor?.number_conducted_consultations}
                  avatar={data?.doctor?.avatar}
                />
                <TotalDetails data={data} />
                {/* (data?.status === 'planned' || data?.status === 'waiting_paying') && (
                  <p className={Styles.mark}>
                    Изменить дату и время приема можно, написав в техническую поддержку сервиса
                  </p>
                ) */}
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}
