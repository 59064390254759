import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { loadPatient } from '../../index'

import { DoctorContainer } from '../DoctorContainer'
import { DoctorPatient } from '../DoctorPatient'
import { Loader } from '../UI/Loader'
import { MedicalReportsModal } from '@components/MedicalReportsModal'

export const DoctorPagePatient: React.FC = () => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector((state) => state.doctor.loading)
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    loadPatient(id, dispatch)
  }, [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <DoctorContainer title='' back='Назад ко всем пациентам' to='/patients'>
            <DoctorPatient />
          </DoctorContainer>
          <MedicalReportsModal />
        </>
      )}
    </>
  )
}
