import React, { useState } from 'react'
import { FormDataCart } from '../FormDataCart'
import Styles from './style.module.scss'
// import arrow from '@icons/arrow-right.svg'
// import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import SwiperCore, { Navigation, Pagination } from 'swiper'
// import useWindowSize from '@helpers/useWindowSizeHook'
// import { SelectComponent } from '@components/SelectComponent'
import { ButtonColor } from '@components/ui/ButtonColor'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setDataDelivery } from '../../redux/deliverySlice'
SwiperCore.use([Pagination, Navigation])

interface CourierDeliveryProps {
  goToPay: () => void
  analysis: number
  goToResearch: () => void
  savedDataDelivery?: () => void
}

export const CourierDelivery: React.FC<CourierDeliveryProps> = ({
  goToPay,
  analysis,
  goToResearch,
  savedDataDelivery,
}) => {
  // const { width } = useWindowSize()
  // const desktop = width >= 829
  // const navigationPrevRef = useRef(null)
  // const navigationNextRef = useRef(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [valueSelect, setValueSelect] = useState('')

  const { dataDelivery } = useAppSelector((state) => state.delivery)
  const dispatch = useAppDispatch()
  const handleChange = (event) => {
    const { name, value } = event.target
    dispatch(setDataDelivery({ ...dataDelivery, [name]: value }))
  }
  // const [activeDay, setActiveDay] = useState(null)
  return (
    <>
      <FormDataCart courier />
      {/* <h4 className={Styles.title}>Выберите удобную дату и интервал доставки</h4>

      <Swiper
        className={Styles.slider}
        breakpoints={{
          1200: {
            slidesPerView: 4.7,
          },
          830: {
            slidesPerView: 5,
          },
          410: {
            slidesPerView: 3.7,
          },
          320: {
            slidesPerView: 3,
          },
        }}
        navigation={{ prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            swiper.params.navigation.prevEl = navigationPrevRef.current
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            swiper.params.navigation.nextEl = navigationNextRef.current
            swiper.navigation.destroy()
            swiper.navigation.init()
            swiper.navigation.update()
            desktop ? swiper.pagination.destroy() : swiper.pagination.init()
          })
        }}
      >
        <button
          className={`prev-card ${`${Styles.slider__button} ${Styles.slider__button_prev}`}`}
          ref={navigationPrevRef}
        >
          <img src={arrow} alt='arrow' />
        </button>
        {data.map((item, i) => (
          <SwiperSlide key={i}>
            <button
              className={`${Styles.button} ${i === activeDay && Styles.button_active}`}
              onClick={() => setActiveDay(i)}
            >
              {item}
              <span>вт</span>
            </button>
          </SwiperSlide>
        ))}

        <button className={`next-card ${Styles.slider__button}`} ref={navigationNextRef}>
          <img src={arrow} alt='arrow' />
        </button>
      </Swiper>
      {desktop ? (
        <SelectComponent
          setValueSelect={setValueSelect}
          value1='10:00 – 13:00'
          value2='13:00 – 14:00'
          value3='14:30 – 16:00'
          value4='17:00 – 20:00'
        />
      ) : (
        <button className={Styles.button__interval}>10:00 – 13:00</button>
      )} */}
      <h4 className={Styles.title}>Комментарий к доставке</h4>
      <textarea
        onChange={handleChange}
        value={dataDelivery.comment}
        className={Styles.optional__input}
        placeholder='Введите комментарий'
        name='comment'
      />
      <ButtonColor
        className={Styles.main__button}
        onClick={() => {
          savedDataDelivery()
          analysis > 0 ? goToResearch() : goToPay()
        }}
        value={analysis > 0 ? 'Перейти к выбору лаборатории' : 'Перейти к оплате'}
      />
    </>
  )
}
