import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '@app/redux/hooks'
import { getAppointment } from '../../../../../../../../redux/doctorSlicer'
import Styles from './style.module.scss'

import { formateDate } from '../../../../../../../../../../utils/formateDate'

interface DoctorTodayMobileProps {
  day: string
  receptions: any
  setSelectedAppointment: any
}

export const DoctorTodayMobile: React.FC<DoctorTodayMobileProps> = ({ day, receptions, setSelectedAppointment }) => {
  const dispatch = useAppDispatch()
  const [currentReceptions, setCurrentReceptions] = useState([])

  const openAppointment = async (appointmentId) => {
    const response = await dispatch(getAppointment(appointmentId))
    setSelectedAppointment(response.payload.data)
  }

  useEffect(() => {
    const futureReception = []
    const pastReception = []

    receptions.forEach((reception) => {
      if ((reception.is_past && reception.appointment) || (reception.is_past && !reception.appointment)) {
        pastReception.push(reception)
      }

      if ((!reception.is_past && reception.appointment) || (!reception.is_past && !reception.appointment)) {
        futureReception.push(reception)
      }
    })

    const futureSortedArray = futureReception.sort((a, b) => a.time.localeCompare(b.time))
    const pastSortedArray = pastReception.sort((a, b) => a.time.localeCompare(b.time))

    setCurrentReceptions([...futureSortedArray, ...pastSortedArray])
  }, [receptions])

  return (
    <div className={Styles.today}>
      <span className={Styles.date}>{formateDate(day, 'DD MMMM')}</span>
      <div className={Styles.schedule}>
        {currentReceptions.map((reception) => (
          <>
            {!reception.is_past && !reception.is_blacklist && (
              <>
                {reception.appointment ? (
                  <div
                    key={reception.id}
                    className={`${Styles.reception} ${Styles.reception_existing}`}
                    onClick={() => openAppointment(reception.appointment.id)}
                  >
                    <i
                      className={
                        reception.appointment.is_online ? `${Styles.icon}` : `${Styles.icon} ${Styles.icon_offline}`
                      }
                    >
                      {}
                    </i>
                    <span className={Styles.time}>{reception.time}</span>
                  </div>
                ) : (
                  <div key={reception.id} className={`${Styles.reception} ${Styles.reception_free}`}>
                    <span className={Styles.time}>{reception.time}</span>
                  </div>
                )}
              </>
            )}
            {reception.is_past && (
              <>
                {reception.appointment ? (
                  <div
                    key={reception.id}
                    className={`${Styles.reception} ${Styles.reception_past}`}
                    onClick={() => openAppointment(reception.appointment.id)}
                  >
                    <i
                      className={
                        reception.appointment.is_online ? `${Styles.icon}` : `${Styles.icon} ${Styles.icon_offline}`
                      }
                    >
                      {}
                    </i>
                    <span className={Styles.time}>{reception.time}</span>
                  </div>
                ) : (
                  <div key={reception.id} className={`${Styles.reception} ${Styles.reception_past}`}>
                    <span className={Styles.time}>{reception.time}</span>
                  </div>
                )}
              </>
            )}
          </>
        ))}
      </div>
    </div>
  )
}
