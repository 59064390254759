import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.scss'

import { OrderProductItemDetails } from './components/OrderProductItemDetails'
import { OrderTestItemDetails } from './components/OrderTestItemDetails'
import { ButtonColor } from '@components/ui/ButtonColor'
import { ShadowedContainer } from '@components/Containers/ShadowedContainer'
import { OrderTotalInfo } from './components/OrderTotalInfo'
import { Spinner } from '@components/Spinner'
import { OrderUserData } from './components/orderUserData'
import { OrderShippingInfo } from './components/orderShippingInfo'
import { OrderDeliveryStatus } from '../OrderDeliveryStatus'
import { Tag } from '@components/ui/Tag'

import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames/bind'

import { useAppDispatch } from '@app/redux/hooks'
import useWindowSize from '@helpers/useWindowSizeHook'
import { getOrder } from '../../redux/ordersSlice'
import { ordersTypes } from '../../redux/orderTypes'
import { formatDate } from '../../utils/formatDateWithT'
import { getTagBackgroundClassname } from '../../utils/getTagBackgroundClassname'
import { formatPriceWithRuble } from '../../utils/formatPriceWithRuble'
import { postPaymentOrder } from '../../../cart/redux/cartSlicer'
import copyIcon from '@icons/copyIcon.svg'
import backIcon from '@icons/arrow-left-blue.svg'

export const OrderDetails = () => {
  const cx = classNames.bind(styles)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const trackNumber = useRef<HTMLInputElement | null>()
  const { width } = useWindowSize()
  const [copyTooltipText, setCopyTooltipText] = useState('Скопировать')

  const [loading, setLoading] = useState(true)
  const [targetOrder, setTargetOrder] = useState(null)

  const goToPay = async () => {
    try {
      localStorage.setItem('createdIdOrder', targetOrder.id)

      const resPayment = await dispatch(postPaymentOrder({ order: targetOrder.id }))

      window.location.replace(resPayment.payload.url)
    } catch (error) {
      // console.log(error)
    }
  }
  const goToTrack = () => {
    // переход по ссылке с бека
    // console.log('tracking')
  }

  const copyTrackNumber = () => {
    navigator.clipboard.writeText(trackNumber.current.textContent).then(() => {
      setCopyTooltipText('Скопировано')
      setTimeout(() => {
        setCopyTooltipText('Скопировать')
      }, 3000)
    })
  }

  const getTargetOrder = useCallback(() => {
    const splited = location.pathname.split('/')
    const id = Number(splited[splited.length - 1])

    dispatch(getOrder(id)).then((res: ordersTypes.TGetOrderResponse) => {
      if (res.payload) {
        if (res.payload.id) {
          setTargetOrder(res.payload)
          setLoading(false)
        }
      }
    })
  }, [])

  useEffect(() => {
    getTargetOrder()
  }, [getTargetOrder])

  return loading ? (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  ) : (
    <ShadowedContainer customStyle={styles.container}>
      <header className={styles.header}>
        <Link to='/orders' className={styles.back}>
          <img src={backIcon} />
        </Link>

        <div className={styles.header_texts}>
          <h3 className={styles.order_number}>Заказ № {targetOrder.id}</h3>
          <p className={styles.order_date}>от {formatDate(targetOrder.created_at)}</p>
        </div>

        <div className={styles.header_status}>
          <p className={styles.header_total}>{formatPriceWithRuble(targetOrder.total_cost)}</p>
          <p className={styles.header_unpaid}>
            {targetOrder.status !== 'not_paid' && width > 1263
              ? null
              : targetOrder.status === 'not_paid'
              ? 'Заказ не оплачен'
              : 'Оплачено'}
          </p>
        </div>

        {targetOrder.is_paid && (
          <div className={styles.header_track}>
            <p className={styles.track_text}>Код отслеживания:</p>

            <div className={styles.track_block}>
              {/* TODO: код отслеживания */}
              <p ref={trackNumber} className={styles.track_number}>
                35005145009747
              </p>
              <div data-tooltip={copyTooltipText} className={styles.copyToClipboard}>
                <img src={copyIcon} alt='Скопировать в буфер обмена' onClick={copyTrackNumber} />
              </div>
            </div>
          </div>
        )}
        {targetOrder.status === 'not_paid' && (
          <ButtonColor value='Оплатить' className={styles.paymentButton} onClick={() => goToPay()} />
        )}
        {targetOrder.tracking && (
          <ButtonColor value='Отследить' className={styles.trackButton} onClick={() => goToTrack()} />
        )}
        {/* <ButtonColor
          value={targetOrder.status === 'not_paid' ? 'Оплатить' : 'Отследить'}
          className={targetOrder.status === 'not_paid' ? styles.paymentButton : styles.trackButton}
          onClick={targetOrder.status === 'not_paid' ? () => goToPay() : () => goToTrack()}
        /> */}
      </header>

      <div className={styles.content}>
        <div className={styles.shipping}>
          <OrderUserData phone={targetOrder.phone} name={targetOrder.full_name} email={targetOrder.email} />
          <OrderShippingInfo targetOrder={targetOrder} />
          <OrderTotalInfo
            isPaid={targetOrder.status !== 'not_paid'}
            productsNumber={targetOrder.products_in_order.length}
            productsTotalCost={targetOrder.products_total}
            analysisNumber={targetOrder.analyzes_in_order.length}
            analysisTotalCost={targetOrder.analyzes_total}
            total={targetOrder.total_cost}
            discountPrice={targetOrder.discount_total}
            deliveryPrice={targetOrder.delivery_sum}
            goToPay={goToPay}
          />
        </div>

        <div className={styles.products}>
          <div>
            {/* Фейковая доставка для статуса */}
            <OrderDeliveryStatus deliveryInfo={targetOrder} />

            <div className={styles.orderGoodsList}>
              {targetOrder.products_in_order &&
                targetOrder.products_in_order.length !== 0 &&
                targetOrder.products_in_order.map((product: ordersTypes.IProductInOrder) => {
                  return <OrderProductItemDetails item={product} key={product.id} />
                })}
            </div>
          </div>

          {(targetOrder.analyzes_in_order.length > 0 || targetOrder.analysis_sets_in_order.length > 0) && (
            <div className={styles.analysis}>
              <div className={styles.analysis_header}>
                <p className={styles.analysis_title}>Отправка на E-mail</p>
                <Tag value='Отправлен' customStyle={cx(styles.tag, getTagBackgroundClassname('Отправлен'))} />
              </div>
              <p className={styles.analysis_subtitle}>E-mail: {targetOrder.delivery.email}</p>

              <div className={styles.analysis_list}>
                {targetOrder.analyzes_in_order &&
                  targetOrder.analyzes_in_order.length !== 0 &&
                  targetOrder.analyzes_in_order.map((analysis: ordersTypes.IAnalysisInOrder) => {
                    return <OrderTestItemDetails item={analysis} key={analysis.id} />
                  })}
              </div>

              <div className={styles.analysis_list}>
                {targetOrder.analysis_sets_in_order &&
                  targetOrder.analysis_sets_in_order.length !== 0 &&
                  targetOrder.analysis_sets_in_order.map((analysis: ordersTypes.IAnalysisInOrder) => {
                    return <OrderTestItemDetails item={analysis} key={analysis.id} complex />
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </ShadowedContainer>
  )
}
