/* eslint-disable camelcase */
import React, { FC } from 'react'
import styles from './style.module.scss'

import { OutlinedContainer } from '@components/Containers/OutlinedContainer'
import { Tag } from '@components/ui/Tag'

import { ordersTypes } from '../../../../redux/orderTypes'

interface OrderProductItemDetailsProps {
  item: ordersTypes.IProductInOrder
}

export const OrderProductItemDetails: FC<OrderProductItemDetailsProps> = ({ item }) => {
  const { discount_price, price, image, quantity, title, variant_value } = item

  return (
    <div className={styles.orderProductItemDetails}>
      <OutlinedContainer customStyle={styles.outlinedContainer}>
        <img src={`${image}`} alt={title} className={styles.image} />

        <div className={styles.productDetails}>
          <div className={styles.info}>
            <h4 className={styles.title}>{title}</h4>

            <div className={styles.options}>
              <div className={styles.option}>
                <p className={styles.optionKey}>Вкус:</p>
                <p className={styles.optionValue}>{variant_value}</p>
              </div>
              <div className={styles.option}>
                <p className={styles.optionKey}>Количество:</p>
                <p className={styles.optionValue}>{quantity} штук</p>
              </div>
            </div>
          </div>

          <div className={styles.prices}>
            <Tag value={`-${price - discount_price} ₽`} customStyle={styles.discountTag} />

            <p className={styles.price}>
              <span className={styles.basePrice}>{price} ₽</span>
              <span className={styles.finalPrice}>&nbsp;{discount_price} ₽</span>
            </p>

            <p className={styles.totalItemPrice}>
              {quantity} шт. × {discount_price} ₽
            </p>
          </div>
        </div>
      </OutlinedContainer>
    </div>
  )
}
