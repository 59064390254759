export const compareDate = (firstDate, secondDate) => {
  const date1 = new Date(firstDate)
  const date2 = new Date(secondDate)

  const oneDay = 1000 * 60 * 60 * 24
  const diffInTime = date1.getTime() - date2.getTime()
  const diffInDays = Math.round(diffInTime / oneDay)

  return diffInDays
}
