import moment from 'moment'
import React from 'react'
import Styles from './style.module.scss'
import downloadIcon from '@icons/chat-download-arrow.svg'
import { ClickableImage } from '../ClickableImage'
import FileSaver from 'file-saver'

type AttachedFileProps = {
  date: number
  name: string
  size: number
  downloadable?: boolean
  file?: string
  type?: number
}

export const AttachedFile: React.FC<AttachedFileProps> = ({ date, name, size, downloadable, file, type }) => {
  const API_URL = new URL(process.env.REACT_APP_API)
  const API_FILE_URL = API_URL.origin
  const downloadFile = () => {
    FileSaver(`${API_FILE_URL}${file}`, name)
  }
  return (
    <div className={Styles.attachment}>
      {type === 1 ? (
        <div className={Styles.image}>
          <ClickableImage src={file} filename={name} />
        </div>
      ) : (
        <div className={Styles.icon}>{name.split('.').pop()}</div>
      )}
      <div className={Styles.info}>
        <p className={Styles.filename}>{name}</p>
        <div>
          <p className={Styles.fileinfo}>
            {downloadable && (
              <img src={downloadIcon} alt='download' className={Styles.downloadArrow} onClick={downloadFile} />
            )}
            {`${size / 1000} KB | ${moment(date).format('DD MMMM, YYYY г, HH:mm')}`}
          </p>
        </div>
      </div>
    </div>
  )
}
