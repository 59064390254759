import React, { useState } from 'react'
import Styles from './style.module.scss'
import breakfast from '@icons/mealtime-breakfast.svg'
import lunch from '@icons/mealtime-lunch.svg'
import dinner from '@icons/mealtime-dinner.svg'
import food from '@icons/mealtime-food.svg'
import mark from '@icons/mark.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'

export const Nutrition = (props) => {
  const [idActive, setIdActive] = useState('Завтрак')
  const isActive = (e) => {
    setIdActive(e.currentTarget.id)
  }
  const getFormatedText = (text) => {
    return text.split('\n').map((str, i) => <div key={`div_${i}`}>{str}</div>)
  }

  return (
    <div className={Styles.nutrition__container}>
      <Swiper spaceBetween={8} slidesPerView={2.5} style={{ margin: '0 -20px 0 0px' }}>
        <SwiperSlide
          onClick={isActive}
          id='Завтрак'
          className={
            idActive === 'Завтрак'
              ? `${Styles.mealtime__button} ${Styles.mealtime__button_active}`
              : Styles.mealtime__button
          }
        >
          <img src={breakfast} alt='img' />
          <h5 className={Styles.mealtime__title}>Завтрак</h5>
        </SwiperSlide>
        <SwiperSlide
          onClick={isActive}
          id='Обед'
          className={
            idActive === 'Обед'
              ? `${Styles.mealtime__button} ${Styles.mealtime__button_active}`
              : Styles.mealtime__button
          }
        >
          <img src={lunch} alt='img' />
          <h5 className={Styles.mealtime__title}>Обед</h5>
        </SwiperSlide>
        <SwiperSlide
          onClick={isActive}
          id='Ужин'
          className={
            idActive === 'Ужин'
              ? `${Styles.mealtime__button} ${Styles.mealtime__button_active}`
              : Styles.mealtime__button
          }
        >
          <img src={dinner} alt='img' />
          <h5 className={Styles.mealtime__title}>Ужин</h5>
        </SwiperSlide>
        <SwiperSlide
          onClick={isActive}
          id='Продукты'
          className={
            idActive === 'Продукты'
              ? `${Styles.mealtime__button} ${Styles.mealtime__button_active}`
              : Styles.mealtime__button
          }
        >
          <img src={food} alt='img' />
          <h5 className={Styles.mealtime__title}>Рекомендуемые&nbsp;продукты</h5>
        </SwiperSlide>
      </Swiper>

      {props.data.map((item) => (
        <div className={Styles.nutrition__desc} key={item.id}>
          {item.title === idActive && (
            <div className={Styles.wrap}>
              <div className={Styles.description}>{getFormatedText(item.description)}</div>
              {item.remark && (
                <div className={Styles.mark__container}>
                  <img src={mark} alt='mark' />
                  <p className={Styles.mark}>{item.remark}</p>
                </div>
              )}
              {item.products_exclusions && (
                <div className={Styles.mark__container}>
                  <p className={Styles.mark}>Продукты исключения: {item.products_exclusions}</p>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
