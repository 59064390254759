import React, { useState } from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'

import { DoctorRecordMonthMobile } from '../DoctorRecordMonth/components/DoctorRecordMonthMobile'
import { DoctorTodayMobile } from '../DoctorToday/components/DoctorTodayMobile'
import { DoctorPopupMobile } from '../../../../../DoctorModal/components/DoctorPopupMobile'
import { DoctorRecordInfo } from '../../../DoctorRecordInfo'

export const DoctorMonthMobile: React.FC = () => {
  const appointmentsMonth = useAppSelector((state) => state.doctor.appointmentsMonth)
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [selectedDay, setSelectedDay] = useState(null)

  return (
    <div className={Styles.schedule}>
      <div className={Styles.calendar}>
        <div className={Styles.days}>
          <span className={Styles.day}>Пн</span>
          <span className={Styles.day}>Вт</span>
          <span className={Styles.day}>Ср</span>
          <span className={Styles.day}>Чт</span>
          <span className={Styles.day}>Пт</span>
          <span className={Styles.day}>Сб</span>
          <span className={Styles.day}>Вс</span>
        </div>
        <div className={Styles.content}>
          {appointmentsMonth.map((monthDay) => (
            <DoctorRecordMonthMobile
              key={monthDay.day}
              monthDay={monthDay}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
            />
          ))}
        </div>
      </div>
      {selectedDay && (
        <DoctorTodayMobile
          day={selectedDay.day}
          receptions={selectedDay.receptions}
          setSelectedAppointment={setSelectedAppointment}
        />
      )}
      {selectedAppointment && (
        <DoctorPopupMobile closePopup={() => setSelectedAppointment(null)}>
          <DoctorRecordInfo selectedAppointment={selectedAppointment} />
        </DoctorPopupMobile>
      )}
    </div>
  )
}
