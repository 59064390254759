/* eslint-disable no-useless-escape */
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import warning from '@icons/warning-white.svg'
import steps from '@icons/steps.svg'
import classNames from 'classnames'
import { FormStorage } from '../../../../../utils/form-storage'
import { Field } from 'react-final-form'
import PhoneInput from 'react-phone-number-input/input'
import { setDataOrder } from '../../../redux/cartSlicer'
import { changeUserInfo } from '../../../../../pages/authorization/redux/authSlice'
import { setCity, setCityCode } from '../DeliveryCart/redux/deliverySlice'

export const PersonalDataCart = () => {
  const cx = classNames.bind(Styles)
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.auth.user.info)
  const { dataOrder } = useAppSelector((state) => state.cart)
  const [switchToggle, setSwitchToggle] = useState(false)
  const cartData = useAppSelector((state) => state.cart.cartData)
  const analysis = [...cartData?.analyzes, ...cartData?.analysis_sets]
  const switchStyle = cx(Styles.switch, {
    [`${Styles.switch_active}`]: switchToggle,
  })

  const onSubmit = async (values) => {
    dispatch(setDataOrder({ ...values, phone: switchToggle ? values.otherPhone : user.phone }))
    dispatch(changeUserInfo({ last_name: values.last_name, email: values.email }))
  }
  const required = (value) => (value ? undefined : 'Это обязательное поле')

  const regExpPhone = (value) =>
    value?.match(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/) ? undefined : 'Неверный формат'

  const regEmail = (value) =>
    value.match(/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/) ? undefined : 'Неверный формат'

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce((error, validator) => error || validator(value), undefined)

  const PhoneField = ({ input, meta }) => {
    return (
      <div className={Styles.label__container}>
        <label className={Styles.label}>Другой номер</label>
        <PhoneInput
          className={`${Styles.input} ${Styles.input_width}`}
          placeholder='+7'
          value={input.value.value}
          onChange={(value) => input.onChange(value)}
          minLength={2}
          maxLength={17}
          defaultCountry='RU'
          withCountryCallingCode
          {...input}
        />
        {meta.touched && meta.error && <span className={Styles.error}>{meta.error}</span>}
      </div>
    )
  }

  useEffect(() => {
    dispatch(
      setDataOrder({
        ...dataOrder,
        name: user?.first_name,
        email: user?.email,
        phone: user.phone,
        last_name: user.last_name,
      }),
    )
  }, [user])
  useEffect(() => {
    if (user.city_code) {
      dispatch(setCity(user.city))
      dispatch(setCityCode(user.city_code))
    }
  }, [user])
  return (
    <>
      <div className={Styles.container}>
        <h3 className={Styles.title_mobile}>Введите данные получателя</h3>
        <label className={Styles.label} htmlFor='telSave'>
          Телефон&#42;
        </label>

        <input className={Styles.input_save} value={user.phone} readOnly />
        <span className={Styles.hint}>Отправим подтверждение после оплаты</span>
        <FormStorage formId='personal_data' onSubmit={onSubmit} initialValues={dataOrder}>
          <div className={Styles.form}>
            <div className={Styles.switch__container}>
              <div className={switchStyle} onClick={() => setSwitchToggle(!switchToggle)} />
              <span>Хочу использовать другой номер</span>
            </div>
            {switchToggle && <Field name='otherPhone' component={PhoneField} validate={regExpPhone} />}
            <Field name='name' validate={required}>
              {({ input, meta }) => (
                <div className={`${Styles.label__container} ${Styles.label__container_name}`}>
                  <label className={Styles.label}>Имя&#42;</label>
                  <input {...input} placeholder='Введите ваше имя' className={Styles.input} />
                  {meta.error && meta.touched && <span className={Styles.error}>{meta.error}</span>}
                </div>
              )}
            </Field>
            <Field name='last_name' validate={required}>
              {({ input, meta }) => (
                <div className={`${Styles.label__container} ${Styles.label__container_name}`}>
                  <label className={Styles.label}>Фамилия&#42;</label>
                  <input {...input} placeholder='Введите вашу фамилию' className={Styles.input} />
                  {meta.error && meta.touched && <span className={Styles.error}>{meta.error}</span>}
                </div>
              )}
            </Field>
            <div className={Styles.hint}>На это имя будет оформлен заказ</div>
            {analysis.length > 0 && (
              <div className={Styles.email__container}>
                <Field name='email' validate={composeValidators(required, regEmail)}>
                  {({ input, meta }) => (
                    <div className={`${Styles.label__container} ${Styles.label__container_email}`}>
                      <label className={Styles.label}>Email&#42;</label>
                      <input {...input} placeholder='Введите ваш email' className={Styles.input} />
                      {meta.error && meta.touched && <span className={Styles.error}>{meta.error}</span>}
                    </div>
                  )}
                </Field>
                <img alt='img' src={steps} className={Styles.hint__img} />
                <div className={Styles.email__hint}>
                  <img alt='img' className={Styles.hint__icon} src={warning} />
                  <p className={Styles.hint__text}>На этот адрес отправим номер заказа лабораторных обследований</p>
                </div>
              </div>
            )}
          </div>
        </FormStorage>
      </div>
    </>
  )
}
