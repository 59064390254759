import React from 'react'
import Styles from './style.module.scss'
import icon1 from '@icons/icon1.svg'
import icon2 from '@icons/icon2.svg'
import { BorderLine } from '@components/ui/BorderLine'
import { FragmentUi } from '@components/ui/FragmentUi'
import declination from '../../../../../../helpers/declination'

export const DoctorInformation = (props) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.wrap}>
        <div>
          <img className={Styles.img} src={props.avatar} alt='avatar' />
        </div>

        <div className={Styles.desc}>
          <h6 className={Styles.name}>{props.name}</h6>
          <p className={Styles.text}>{props.prof}</p>
        </div>
      </div>
      <BorderLine />
      <div className={Styles.wrap2}>
        <FragmentUi src={icon1} text='опыт работы' count={declination(props.expir, 'год', 'года', 'лет')} />
        <FragmentUi src={icon2} text='консультаций' count={props.count} />
      </div>
    </div>
  )
}
