import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useWindowSize from '@helpers/useWindowSizeHook'
import plusIcon from '@icons/plus-icon.svg'
import calendarIcon from '@icons/calendar.svg'
import { MOBILE_BREAKPOINT } from '../../../../global-constants'
import 'swiper/swiper.scss'
import Styles from './style.module.scss'
import { Tooltip } from '../../../doctor/components/UI/Tooltip'
import { CounterResult } from '../CounterResult'
import { DeleteButton } from '../../ui/DeleteButton'
import { DeleteComponent } from '../DeleteComponent'
import declination from '@helpers/declination'
import { Link } from 'react-router-dom'

interface AnalysisItemProps {
  item: any
  // changeCount: any
  set?: boolean
  // deletedItem: any
}
export const AnalysisItem: React.FC<AnalysisItemProps> = ({ item, set }) => {
  const { width } = useWindowSize()
  const [activeDeleteComponent, setActiveDeleteComponent] = useState(false)
  return (
    <div className={`${Styles.item} ${set && Styles.item_set}`}>
      <div className={Styles.block}>
        {set ? (
          <div className={Styles.wrap}>
            <span className={Styles.title}>{item.title}</span>
          </div>
        ) : (
          <Link to={`/catalog/research/${item.id}`} className={Styles.wrap}>
            <span className={Styles.title}>{item.title}</span>
          </Link>
        )}

        {set && (
          <div className={Styles.list}>
            {item.analyzes_in_set.map((item, id) => (
              <div key={id} className={Styles.wrapper}>
                {id !== 0 && <img className={Styles.plus} src={plusIcon} alt='Иконка' />}
                <Link to={`/catalog/research/${item.id}`} className={Styles.product} key={id}>
                  <span className={Styles.symbol}>{item.short_title}</span>
                  <Tooltip className={Styles.tooltip}>{item.title}</Tooltip>
                </Link>
              </div>
            ))}
          </div>
        )}

        {width <= MOBILE_BREAKPOINT && (
          <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={8}>
            <SwiperSlide className={Styles.slide}>
              <div className={Styles.date}>
                <img src={calendarIcon} alt='Иконка' />
                Срок исполнения до{` `}
                {declination(item.execution_time, 'дня', 'дней', 'дней')}
              </div>
            </SwiperSlide>
            {item.labs.map((tag, id) => (
              <SwiperSlide className={Styles.slide} key={id}>
                <div className={Styles.image}>
                  <img src={`${tag}`} alt='Иконка' />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {width > MOBILE_BREAKPOINT && (
          <div className={Styles.wrap}>
            <div className={Styles.date}>
              <img src={calendarIcon} alt='Иконка' />
              <span>
                Срок исполнения до{` `}
                {declination(item.execution_time, 'дня', 'дней', 'дней')}
              </span>
            </div>
            <div className={Styles.performers}>
              {item.labs.map((tag, id) => (
                <div className={Styles.image} key={id}>
                  <img src={`${tag}`} alt='Иконка' />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className={Styles.counter}>
        <CounterResult
          // changeCount={changeCount}
          // arrName={set ? 'analysis_set' : 'analyzes'}
          arrNamePatch={set ? 'analysis_set' : 'analysis'}
          item={item}
        />
      </div>
      {set && (
        <div className={Styles.badge}>
          <span>Комплекс анализов</span>
        </div>
      )}
      <DeleteButton onClick={() => setActiveDeleteComponent(true)} />
      {activeDeleteComponent && (
        <DeleteComponent
          name={item.title}
          setActiveDeleteComponent={setActiveDeleteComponent}
          // deletedItem={deletedItem}
          id={item.id}
          arrNameDelete={set ? 'analysis_set' : 'analysis'}
        />
      )}
    </div>
  )
}
