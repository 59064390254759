import { configureStore } from '@reduxjs/toolkit'
import userSlice from './userSlice'
import userCardHistorySlice from '../../pages/home/redux/userCardHistory'
// import userPageSlice from '../../pages/user/redux/userPageSlicer'
import doctorSlicer from '../../pages/doctor/redux/doctorSlicer'
import consultationSlice from '../../pages/appointment-page/redux/consultationSlicer'
import choieConsultationSlice from '../../pages/appointment-page/redux/choiсeConsultationSlicer'
import authSlice from '../../pages/authorization/redux/authSlice'
import profileSlice from '@components/ProfileModal/redux/profileSlice'
import consultationsListSlice from '../../pages/appointment-page/redux/consultationsListSlicer'
import consultationsDateSlice from '../../pages/appointment-page/redux/consultationDateSliser'
import paymentSlice from '../../pages/appointment-page/redux/paymentSlice'
import reportsSlice from '@components/MedicalReportsModal/redux/reportsSlice'
import appointmentSlice from '../../pages/my-appointment/redux/appointmentSlicer'
import appointmentByIdSlicer from '../../pages/my-appointment/redux/appointmentByIdSlicer'
import userSpaceSlice from '../../pages/user/redux/userSpaceSlicer'
import catalogSlicer from '../../pages/catalog/redux/catalogSlicer'
import chatSlice from '../../components/Chat/redux/chatSlice'
import quizSlice from '../../components/Quiz/redux/quizSlicer'
import cartSlice from '../../pages/cart/redux/cartSlicer'
import deliverySlice from '../../pages/cart/GoCheckout/components/DeliveryCart/redux/deliverySlice'
import productSlice from '../../pages/catalog/redux/productSlice'
import ordersSlice from '../../pages/orders/redux/ordersSlice'
import blogSlice from '../../pages/blog/redux/blogSlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    userCard: userCardHistorySlice,
    // userPage: userPageSlice,
    doctor: doctorSlicer,
    consultationPage: consultationSlice,
    choieConsultationPage: choieConsultationSlice,
    consultationsList: consultationsListSlice,
    consultationCalendar: consultationsDateSlice,
    auth: authSlice,
    profile: profileSlice,
    reports: reportsSlice,
    myAppointment: appointmentSlice,
    myAppointmentById: appointmentByIdSlicer,
    userSpace: userSpaceSlice,
    payment: paymentSlice,
    catalog: catalogSlicer,
    chat: chatSlice,
    quiz: quizSlice,
    cart: cartSlice,
    delivery: deliverySlice,
    product: productSlice,
    orders: ordersSlice,
    blog: blogSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
