import { useAppSelector } from '@app/redux/hooks'
import React, { useEffect, useState } from 'react'
import { DialoguesPage } from './DialoguesPage'
import { FilesPage } from './FilesPage'
import { MessageField } from './MessageField'
import { MessageInput } from './MessageInput'
import { Recipient } from './Recipient'
import Styles from './style.module.scss'
import { UnavailableChat } from './UnavailableChat'
import { useChat } from './utils/useChat'

export const Chat = () => {
  const userRole = useAppSelector((state) => state.auth.user.info.role)
  const isChatUnavailable = useAppSelector((state) => state.chat.dialogues.length === 0)
  const currentSlug = useAppSelector((state) => state.chat.currentSlug)
  const [currentPage, setCurrentPage] = useState(1)
  const socketStatus = useAppSelector((state) => state.chat.status)
  const { getGroupMessages } = useChat()

  useEffect(() => {
    if (socketStatus === 'connected') {
      getGroupMessages(currentSlug, 1)
    }
  }, [socketStatus, currentSlug])
  return (
    <div className={Styles.container}>
      {isChatUnavailable && userRole === 'patient' ? (
        <UnavailableChat />
      ) : (
        <div className={Styles.content}>
          {currentPage === 1 && <DialoguesPage setCurrentPage={setCurrentPage} />}
          {currentPage === 2 && (
            <>
              <Recipient setCurrentPage={setCurrentPage} />
              <MessageField />
              <MessageInput />
            </>
          )}
          {currentPage === 3 && <FilesPage setCurrentPage={setCurrentPage} />}
        </div>
      )}
    </div>
  )
}
