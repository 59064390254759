import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiTypes } from '@app/apiTypes'

/* export const getQuizList: any = createAsyncThunk('quiz/getQuizList', async function () {
  const response = await api.get<apiTypes.ConsultationsData>(`not_answered_questions/10/ы`)
  return response.data
}) */

export const postQuiz: any = createAsyncThunk('quiz/postQuiz', async (data: any) => {
  const response = await api.post<any>('question_answer/', data)
  return response as any
})

export const getRecommendation: any = createAsyncThunk('quiz/getRecommendation', async function () {
  const response = await api.get<apiTypes.ConsultationsData>(`quiz/recommendations/`)
  return response.data
})

const quizSlice = createSlice({
  name: 'quiz',
  initialState: {
    data: [],
  },
  reducers: {
    getDataById(state) {
      return state
    },
  },

  extraReducers: () => {},
})

export const { getDataById } = quizSlice.actions

export default quizSlice.reducer
