import React from 'react'
import Styles from './style.module.scss'

import { Spinner } from '@components/Spinner'

export const Loader: React.FC = () => {
  return (
    <div className={Styles.loader}>
      <Spinner />
    </div>
  )
}
