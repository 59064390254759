import React, { useState } from 'react'
import Styles from './style.module.scss'
import { BorderLine } from '@components/ui/BorderLine'
import { Link } from 'react-router-dom'
import question from '@icons/question.svg'
import { DrawerPanel } from '@components/Drawer'
import closeButton from '@icons/close.svg'
import { DrawerMobile } from '@components/ui/DrawerMobile'
import useWindowSize from '@helpers/useWindowSizeHook'
import { useAppSelector } from '@app/redux/hooks'

interface TotalProps {
  activeField?: number
  button?: boolean
  done?: boolean
  order?: boolean
  createdOrder?: any
}

export const Total: React.FC<TotalProps> = ({
  // activeField,
  button,
  done,
  order,
  createdOrder,
}) => {
  const [showDrawer, setShowDrawer] = useState(false)
  const [activeTab, setActiveTab] = useState('products')
  const cartDataRedux = useAppSelector((state) => state.cart.cartData)
  const cartData = done ? createdOrder : cartDataRedux
  const products = done ? [...createdOrder?.products_in_order] : [...cartData?.products, ...cartData?.product_sets]
  const analyzes = done
    ? [...createdOrder?.analyzes_in_order, ...createdOrder?.analysis_sets_in_order]
    : [...cartData?.analyzes, ...cartData?.analysis_sets]
  const productInStock = done ? products : products.filter((item) => item.in_stock)
  const { width } = useWindowSize()
  const desktop = width >= 829

  return (
    <>
      <div className={`${Styles.container} ${done && Styles.container_done}`}>
        <h3 className={Styles.title}>{order || done ? 'Ваш заказ' : 'Итого'}</h3>

        <ul className={Styles.wrap}>
          <li>
            <span className={Styles.item__title}>
              Товары
              <img
                src={question}
                alt='?'
                onClick={() => {
                  setShowDrawer(true)
                  setActiveTab('products')
                }}
              />
            </span>
            <span className={Styles.item__price}>{done ? createdOrder.products_total : cartData.products_total} ₽</span>
          </li>
        </ul>
        <ul className={Styles.wrap}>
          <li>
            <span className={Styles.item__title}>
              Лаб.обследования
              <img
                src={question}
                alt='?'
                onClick={() => {
                  setShowDrawer(true)
                  setActiveTab('analyzes')
                }}
              />
            </span>
            <span className={Styles.item__price}>{done ? createdOrder.analyzes_total : cartData.analyzes_total} ₽</span>
          </li>
        </ul>
        <BorderLine />
        <ul className={Styles.wrap}>
          <li>
            <span className={Styles.item__title}>Сумма заказа</span>
            <span className={Styles.item__price}>{done ? createdOrder.order_summ : cartData.order_summ} ₽</span>
          </li>
        </ul>
        <ul className={Styles.wrap}>
          <li>
            <span className={Styles.item__title}>Скидка</span>
            <span className={Styles.item__price}>
              {Math.round(done ? createdOrder.discount_total : cartData.discount_total)} ₽
            </span>
          </li>
        </ul>
        {done && (
          <ul className={Styles.wrap}>
            <li>
              <span className={Styles.item__title}>Доставка в пункт выдачи</span>
              <span className={Styles.item__price}>{createdOrder.delivery_sum} ₽</span>
            </li>
          </ul>
        )}
        <div className={Styles.total}>
          <h3 className={Styles.title}>Общая стоимость</h3>
          <span className={Styles.item__price}>{done ? createdOrder.total_cost : cartData.total_cost} ₽</span>
        </div>
        {button && (
          <Link to='/cart/gocheckout' className={Styles.button__link}>
            <button className={Styles.button}>Перейти к оформлению</button>
          </Link>
        )}
      </div>
      {desktop ? (
        <DrawerPanel
          width='100%'
          anchor='right'
          open={showDrawer}
          onClose={() => {
            setShowDrawer((isOpen) => !isOpen)
          }}
        >
          <div className={Styles.drawer_container}>
            <h1 className={Styles.title}>Ваш заказ</h1>
            <img
              src={closeButton}
              alt='img'
              className={Styles.closeButton}
              onClick={() => {
                setShowDrawer(false)
              }}
            />
            <div className={Styles.navigation}>
              <button
                className={`${Styles.button__drawer} ${activeTab === 'products' && Styles.button__drawer_active}`}
                onClick={() => setActiveTab('products')}
              >
                Товары<span>{productInStock.length}</span>
              </button>
              <button
                className={`${Styles.button__drawer} ${activeTab === 'analyzes' && Styles.button__drawer_active}`}
                onClick={() => setActiveTab('analyzes')}
              >
                Лабораторные обследования<span>{analyzes.length}</span>
              </button>
            </div>
            {activeTab === 'products' &&
              productInStock.map((item, id) => (
                <div key={id}>
                  <ul className={`${Styles.wrap} ${Styles.drawer__wrap}`}>
                    <li>
                      <span className={`${Styles.item__title} ${Styles.drawer__item}`}>
                        {item.title || 'Комплексный товар'}
                      </span>
                      <span className={Styles.item__price}>{item.discount_price * item.quantity} ₽</span>
                    </li>
                  </ul>
                  <div className={Styles.count}>
                    <div>
                      <span>{item.quantity} шт. </span>x <span>{item.price} ₽</span>
                    </div>
                    <div className={Styles.prise__old}>{item.price * item.quantity} ₽</div>
                  </div>
                </div>
              ))}
            {activeTab === 'analyzes' &&
              analyzes.map((item, id) => (
                <div key={id}>
                  <ul className={`${Styles.wrap} ${Styles.drawer__wrap}`}>
                    <li>
                      <span className={`${Styles.item__title} ${Styles.drawer__item}`}>{item.title}</span>
                      <span className={Styles.item__price}>{item.discount_price * item.quantity} ₽</span>
                    </li>
                  </ul>
                  <div className={Styles.count}>
                    <div>
                      <span>{item.quantity} шт. </span>x <span>{item.price} ₽</span>
                    </div>
                    <div className={Styles.prise__old}>{item.price * item.quantity} ₽</div>
                  </div>
                </div>
              ))}
          </div>
        </DrawerPanel>
      ) : (
        <DrawerMobile
          width='100%'
          anchor='bottom'
          open={showDrawer}
          onClose={() => {
            setShowDrawer((isOpen) => !isOpen)
          }}
        >
          <div className={Styles.drawer_container}>
            <div className={Styles.navigation}>
              <button
                className={`${Styles.button__drawer} ${activeTab === 'products' && Styles.button__drawer_active}`}
                onClick={() => setActiveTab('products')}
              >
                Товары<span>{productInStock.length}</span>
              </button>
              <button
                className={`${Styles.button__drawer} ${activeTab === 'analyzes' && Styles.button__drawer_active}`}
                onClick={() => setActiveTab('analyzes')}
              >
                Лаб. обследования<span>{analyzes.length}</span>
              </button>
            </div>
            {activeTab === 'products' &&
              productInStock.map((item, id) => (
                <div key={id}>
                  <ul className={`${Styles.wrap} ${Styles.drawer__wrap}`}>
                    <li>
                      <span className={`${Styles.item__title} ${Styles.drawer__item}`}>
                        {item.title || 'Комплексный товар'}
                      </span>
                      <span className={Styles.item__price}>{item.discount_price * item.quantity} ₽</span>
                    </li>
                  </ul>
                  <div className={Styles.count}>
                    <div>
                      <span>{item.quantity} шт. </span>x <span>{item.price} ₽</span>
                    </div>
                    <div className={Styles.prise__old}>{item.price * item.quantity} ₽</div>
                  </div>
                </div>
              ))}
            {activeTab === 'analyzes' &&
              analyzes.map((item, id) => (
                <div key={id}>
                  <ul className={`${Styles.wrap} ${Styles.drawer__wrap}`}>
                    <li>
                      <span className={`${Styles.item__title} ${Styles.drawer__item}`}>{item.title}</span>
                      <span className={Styles.item__price}>{item.discount_price * item.quantity} ₽</span>
                    </li>
                  </ul>
                  <div className={Styles.count}>
                    <div>
                      <span>{item.quantity} шт. </span>x <span>{item.price} ₽</span>
                    </div>
                    <div className={Styles.prise__old}>{item.price * item.quantity} ₽</div>
                  </div>
                </div>
              ))}
          </div>
        </DrawerMobile>
      )}
    </>
  )
}
