import React, { useEffect, useState } from 'react'
import Styles from '../style.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import { MedServices } from '@components/LandingPage/MedServices'
import nextIcon from '@icons/nextIcon.svg'
import useWindowSize from '@helpers/useWindowSizeHook'
import { api } from '@app/api'
import { SUCCESS } from '@app/HTTP_CODES'

export const MedicalServicesSlider = ({ setShowAppointment }) => {
  const { width } = useWindowSize()
  const desktop = width >= 829
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const [problems, setProblems] = useState(null)
  const [error, setError] = useState('')
  const swiperBreakPoints: any = {
    320: {
      spaceBetween: 10,
      slidesPerView: 1,
      slidesPerColumn: 4,
      slidesPerColumnFill: 'row',
    },
    500: {
      spaceBetween: 10,
      slidesPerView: 1,
      slidesPerColumn: 4,
      slidesPerColumnFill: 'row',
    },
    830: {
      spaceBetween: 10,
      slidesPerView: 3.5,
      slidesPerColumn: 1,
      slidesPerColumnFill: 'row',
    },
    1050: {
      spaceBetween: 10,
      slidesPerView: 4,
      slidesPerColumn: 1,
      slidesPerColumnFill: 'row',
    },
    1164: {
      spaceBetween: 10,
      slidesPerView: 4.5,
      slidesPerColumn: 1,
      slidesPerColumnFill: 'row',
    },
    1250: {
      spaceBetween: 20,
      slidesPerView: 5,
      slidesPerColumn: 1,
      slidesPerColumnFill: 'row',
    },
    1920: {
      spaceBetween: 20,
      slidesPerView: 5,
      slidesPerColumn: 1,
      slidesPerColumnFill: 'row',
    },
  }

  useEffect(() => {
    api
      .get('problems/')
      .then((res) => {
        if (res.status === SUCCESS) {
          setProblems(res.data)
        } else {
          setError('Что-то пошло не так')
        }
      })
      .catch(() => {
        setError('Что-то пошло не так')
      })
    return () => {
      setProblems(null)
      setError('')
    }
  }, [])
  return (
    <div className={Styles.container}>
      <button
        className={desktop ? `${Styles.prevBtn}` : `${Styles.prevBtn} ${Styles.prevBtn_hide}`}
        ref={navigationPrevRef}
      >
        <img src={nextIcon} alt='→' />
      </button>
      <Swiper
        pagination={!desktop}
        breakpoints={swiperBreakPoints}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onSwiper={(swiper) => {
          setTimeout(() => {
            if (swiper.params?.navigation) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              swiper.params.navigation.prevEl = navigationPrevRef.current
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              swiper.params.navigation.nextEl = navigationNextRef.current
              swiper.navigation.destroy()
              swiper.navigation.init()
              swiper.navigation.update()
              desktop ? swiper.pagination.destroy() : swiper.pagination.init()
            }
          })
        }}
        style={{ position: 'relative', paddingBottom: 45 }}
      >
        <div className={Styles.main_container__height}>
          {problems
            ? Object.values(problems?.results)?.map((item: any, index) => (
                <SwiperSlide key={index}>
                  <MedServices
                    img={item.image}
                    paragraph={item.title}
                    consultationId={item.consultation}
                    setShowAppointment={setShowAppointment}
                  />
                </SwiperSlide>
              ))
            : error}
        </div>
      </Swiper>
      <button
        className={desktop ? `${Styles.nextBtn}` : `${Styles.nextBtn} ${Styles.nextBtn_hide}`}
        ref={navigationNextRef}
      >
        <img src={nextIcon} alt='→' />
      </button>
    </div>
  )
}
