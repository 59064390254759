/* eslint-disable camelcase */
import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getProductById: any = createAsyncThunk('product/getProductById', async (id, { rejectWithValue }) => {
  try {
    const products: any = await api.get(`products/${id}/`)
    if (products.status === 200) {
      return products.data
    } else {
      throw new Error(products)
    }
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const getAnalyzesById: any = createAsyncThunk('product/getAnalyzesById', async (id) => {
  try {
    const analyzes = await api.get(`analyzes/${id}/`)
    return analyzes.data
  } catch (error) {
    // console.error(error)
  }
})

export const postProductCalc: any = createAsyncThunk('cart/postCartCheckout', async function (data: any) {
  const response = await api.post(`products/1/delivery-calc/`, data)
  return response
})

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    product: null,
    selectedVariant: null,
    selectedModification: null,
    analyze: null,
    updateCount: false,
  } as unknown as any, // тут будет тип из apiTypes
  reducers: {
    setSelectedVariant: (state, { payload }) => {
      state.selectedVariant = state.product.variants[payload]
      state.selectedModification = state.product.variants[payload].modifications[0]
    },
    setSelectedModification: (state, { payload }) => {
      state.selectedModification = state.selectedVariant.modifications[payload]
    },
    setUpdateSelectedModification: (state, { payload }) => {
      state.selectedModification = payload
    },
    setUpdateSelectedVariant: (state, { payload }) => {
      state.selectedVariant = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProductById.fulfilled, (state, { payload }) => {
      state.loading = false

      state.product = payload
      state.selectedVariant = payload.variants[0]
      state.selectedVariant && state.selectedVariant.modifications
        ? (state.selectedModification = state.selectedVariant.modifications[0])
        : (state.selectedModification = null)
    })
    builder.addCase(getProductById.rejected, () => {
      // console.log(payload)
    })
    builder.addCase(getAnalyzesById.fulfilled, (state, { payload }) => {
      state.loading = false

      state.analyze = payload
    })
  },
})

const { actions, reducer } = productSlice

export const { setSelectedVariant, setSelectedModification, setUpdateSelectedModification, setUpdateSelectedVariant } =
  actions

export default reducer
