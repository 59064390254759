import React from 'react'
import Styles from './style.module.scss'

interface ICatalogDescriptionProps {
  description: string
}

export const CatalogDescription: React.FC<ICatalogDescriptionProps> = ({ description }) => {
  return (
    <div className={Styles.description} dangerouslySetInnerHTML={{ __html: description }} />
    // <div className={Styles.description}>
    //   <div className={Styles.block}>
    //     <p className={Styles.text}>
    //       Источником витамина В12 кишечная микрофлора, а также продукты животного происхождения. После поступления с
    //       пищей витамин В 12 в виде цианокобаломина проходит сложный путь трансформации в активную форму метилкобаламин.
    //     </p>
    //     <p className={Styles.text}>
    //       Нарушение любого из этих механизмов приводит к дефициту витамина и проявляется нарушением кроветворения
    //       (анемия), нейропатиями (полиневриты, поражение спинного мозга, психические нарушения), нарушение пролиферации
    //       (деления) различных клеток (язвенные и воспалительные поражения слизистой оболочки полости рта, языка, ЖКТ).
    //     </p>
    //     <p className={Styles.text}>
    //       Для того, чтобы развились признаки дефицита витамина В 12 организм должен его не получать в течение 5-6 лет.
    //       Поэтому важно следить за уровнем этого витамина в крови
    //     </p>
    //   </div>
    //   <div className={Styles.block}>
    //     <span className={Styles.title}>Для чего определяют уровень Витамина В12</span>
    //     <p className={Styles.text}>
    //       Исследование содержания витамина B12 в сыворотке крови используют для выявления причин анемии (при снижении
    //       числа эритроцитов и повышенном среднем объеме), нейропатии, в контроле лечения витамин B12- и
    //       фолиеводефицитной анемии
    //     </p>
    //   </div>
    // </div>
  )
}
