/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import useWindowSize from '@helpers/useWindowSizeHook'
import { CatalogRating } from '../../../CatalogRating'
import dotIcon from '@icons/dot.svg'
import cartIcon from '@icons/cart.svg'
import vectorActive from '@icons/vector-active.svg'
import fileIcon from '@icons/file.svg'
import Styles from './style.module.scss'
import { CatalogPrice } from '../../../../components/CatalogPrice'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getMyCart, patchCartId, postCart, searchCity } from '../../../../../../pages/cart/redux/cartSlicer'
import { Link, useParams } from 'react-router-dom'
import {
  getProductById,
  postProductCalc,
  setUpdateSelectedModification,
  setUpdateSelectedVariant,
} from '../../../../redux/productSlice'
import declination from '@helpers/declination'
import { MOBILE_BREAKPOINT } from '../../../../../../global-constants'
import { changeUserInfo } from '../../../../../authorization/redux/authSlice'

interface CatalogCardProps {
  product?: {
    id: number
    brand: string
    name: string
    rating: number
    reviews: number
    variants: any
    discount: string
    discount_prices: number[]
    prices: number[]
    is_available: boolean
    in_cart: number
  }
}

export const CatalogCard: React.FC<CatalogCardProps> = ({ product }) => {
  const dispatch = useAppDispatch()
  const selectedVariant = useAppSelector((state) => state.product.selectedVariant) || null
  const selectedModification = useAppSelector((state) => state.product.selectedModification)
  const { brand, name, rating, reviews } = product
  const { width } = useWindowSize()
  type Params = {
    id: string
  }
  const { id } = useParams<Params>()

  const [userLocationInput, setUserLocationInput] = useState(false)
  const [regionInputValue, setRegionInputValue] = useState('')
  const [isRegionsListOpen, setIsRegionsListOpen] = useState(false)
  const [filteredRegions, setFilteredRegions] = useState([])
  const [calcInfoDelivery, setCalcInfoDelivery] = useState(null)
  const user = useAppSelector((state) => state.auth.user.info)

  const addProduct = (move) => {
    if (move === 'add') {
      dispatch(postCart({ product: selectedModification.id }))
        .then(() => dispatch(getProductById(id)))
        .then((res) => {
          res.payload.variants.map((item) => {
            if (item.id === selectedVariant.id) {
              dispatch(setUpdateSelectedVariant(item))
              dispatch(getMyCart())
            }
            item.modifications.map((mod) => {
              if (mod.id === selectedModification.id) {
                dispatch(setUpdateSelectedModification(mod))
                dispatch(getMyCart())
              }
            })
          })
        })
    } else {
      dispatch(
        patchCartId({
          product: selectedModification.id,
          quantity: move === 'plus' ? selectedModification.in_cart + 1 : selectedModification.in_cart - 1,
        }),
      )
        .then(() => dispatch(getProductById(id)))
        .then((res) => {
          res.payload.variants.map((item) => {
            if (item.id === selectedVariant.id) {
              dispatch(setUpdateSelectedVariant(item))
              dispatch(getMyCart())
            }
            item.modifications.map((mod) => {
              if (mod.id === selectedModification.id) {
                dispatch(setUpdateSelectedModification(mod))
                dispatch(getMyCart())
              }
            })
          })
        })
      dispatch(getMyCart())
    }
  }

  const regionInputFocusHandler = () => {
    setIsRegionsListOpen(true)
  }
  const regionClickHandler = (region) => {
    setIsRegionsListOpen(false)
    setRegionInputValue(region.title)
    setUserLocationInput(false)
    dispatch(
      postProductCalc({
        location_code: region.code,
        mod_id: selectedModification.id,
        quantity: selectedModification.in_cart > 0 ? selectedModification.in_cart : 1,
      }),
    ).then((res) => {
      if (res.payload.status < 300) {
        setCalcInfoDelivery(res.payload.data)
        dispatch(changeUserInfo({ city: region.title, city_code: region.code }))
      }
    })
  }

  const reserchCity = (e) => {
    setRegionInputValue(e.target.value)
    if (e.target.value.length > 0) {
      dispatch(searchCity(e.target.value)).then((res) => setFilteredRegions(res.payload))
    }
  }
  useEffect(() => {
    if (user.city_code) {
      dispatch(
        postProductCalc({
          location_code: user.city_code,
          mod_id: selectedModification.id,
          quantity: selectedModification.in_cart > 0 ? selectedModification.in_cart : 1,
        }),
      ).then((res) => {
        if (res.payload.status < 300) {
          setCalcInfoDelivery(res.payload.data)
        }
      })
    }
  }, [user.city_code])

  return (
    <div className={Styles.card}>
      {width > 830 && (
        <span className={Styles.produced}>
          Произведено&nbsp;
          <strong>{brand}</strong>
        </span>
      )}
      <h3 className={Styles.title}>
        {brand}, {name}, {selectedVariant?.value}, {selectedModification?.value}.
      </h3>
      {width > 830 && <CatalogRating rating={rating} comment={reviews} />}
      <div className={Styles.prices}>
        <CatalogPrice discount_price={selectedModification?.discount_price} price={selectedModification?.price} />
        {selectedModification?.in_stock ? (
          <div className={Styles.availability}>
            <img src={dotIcon} alt='Иконка' />
            <span>В наличии</span>
          </div>
        ) : (
          <div className={Styles.availability}>
            <span>Нет в наличии</span>
          </div>
        )}
      </div>
      {selectedModification?.in_stock && (
        <>
          <div className={Styles.buttons}>
            {selectedModification && selectedModification.in_cart && selectedModification.in_cart > 0 ? (
              <>
                <div className={Styles.counter_wrap}>
                  <div className={Styles.counter__container}>
                    <button
                      onClick={() => addProduct('minus')}
                      className={`${Styles.counter} ${Styles.counter_minus}`}
                      disabled={selectedModification.in_cart === 1}
                    />
                    <span>
                      {selectedModification.in_cart < 10
                        ? `0${selectedModification.in_cart}`
                        : selectedModification.in_cart}
                    </span>
                    <button onClick={() => addProduct('plus')} className={`${Styles.counter} ${Styles.counter_plus}`} />
                  </div>
                  <Link to='/cart' className={Styles.link}>
                    <button className={Styles.green}>
                      {width > MOBILE_BREAKPOINT && <img src={cartIcon} alt='Иконка' />}
                      <span>В корзине {selectedModification.in_cart} шт</span>
                    </button>
                  </Link>
                </div>
              </>
            ) : (
              <button
                className={Styles.cart}
                onClick={() => addProduct('add')}
                disabled={!selectedModification?.in_stock}
              >
                <img src={cartIcon} alt='Иконка' />
                <span>В корзину</span>
              </button>
            )}
          </div>
          <div className={Styles.delivery}>
            {userLocationInput ? (
              <>
                <div className={Styles.search}>
                  <input
                    onChange={reserchCity}
                    type='text'
                    value={regionInputValue}
                    placeholder='Поиск региона'
                    onFocus={regionInputFocusHandler}
                  />
                  {regionInputValue ? (
                    <button className={Styles.clearBtn} onClick={() => setRegionInputValue('')} />
                  ) : null}
                  {filteredRegions && filteredRegions.length > 0 && isRegionsListOpen ? (
                    <>
                      <div className={Styles.outListClickCatcher} onClick={() => setIsRegionsListOpen(false)} />
                      <ul className={Styles.citiesList}>
                        {filteredRegions.map((region) => {
                          return (
                            <li className={Styles.citiesItem} key={region.region_code}>
                              <button className={Styles.citiesItemBtn} onClick={() => regionClickHandler(region)}>
                                {`${region.title} (${region.region})`}
                              </button>
                            </li>
                          )
                        })}
                      </ul>
                    </>
                  ) : (
                    <>
                      {filteredRegions.length === 0 && regionInputValue.length > 0 && isRegionsListOpen && (
                        <>
                          <div className={Styles.outListClickCatcher} onClick={() => setIsRegionsListOpen(false)} />
                          <div className={Styles.citiesList}>
                            <div className={Styles.citiesItem}>Ничего не найдено</div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <span>Получить в </span>
                <button className={Styles.caret} onClick={() => setUserLocationInput(true)}>
                  <span>{regionInputValue || user.city || 'Изменить'}</span>
                  <img src={vectorActive} alt='Иконка' />
                </button>
              </>
            )}
          </div>
          <div className={Styles.container}>
            {!userLocationInput &&
              calcInfoDelivery &&
              calcInfoDelivery.map((item) => (
                <div key={item.type} className={Styles.wrapper}>
                  {(item.type === 'cur' || item.type === 'pvz') && (
                    <>
                      {item.delivery_sum && item.period_min && (
                        <div className={Styles.wrapper}>
                          <div className={Styles.wrapper__image}>
                            <img src={fileIcon} alt='Иконка' />
                          </div>
                          <div className={Styles.wrapper__column}>
                            <div className={Styles.wrapper__row}>
                              <span className={Styles.wrapper__how}>
                                {
                                  (item.type === 'cur' && 'Курьером') || (item.type === 'pvz' && 'В пункт выдачи')
                                  // || (item.type === 'pst' && 'Постамат')
                                }
                              </span>
                              <span className={Styles.wrapper__border} />
                            </div>
                            <span className={Styles.wrapper__time}>
                              от {declination(item.period_min, 'дня', 'дней', 'дней')}
                            </span>
                          </div>
                          <span className={Styles.wrapper__price}>от {item.delivery_sum} ₽</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  )
}
