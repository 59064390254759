// eslint-disable-next-line camelcase
export const USER_ROlES = {
  admin: 'admin', // id = 1
  user: 'user', // id = 2
  doctor: 'doctor', // id = 3
}

export const MOBILE_BREAKPOINT = 830

const TOKEN_VALID_MINUTES = 10
const SHIFT = 0.95
export const UPDATE_TOKEN_INTERVAL = TOKEN_VALID_MINUTES * 60 * SHIFT * 1000
