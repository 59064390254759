import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getAppointmentById: any = createAsyncThunk('appointment/getAppointmentList', async function (id) {
  const response = await api.get(`appointments/${id}/`)
  return response.data
})

const appointmentByIdSlice = createSlice({
  name: 'appointmentById',
  initialState: {
    dataById: null,
    status: null,
    selectById: null,
    medicalReportId: null,
    plannedDataProdactsToBy: [],
    plannedDataAnalysisToBy: [],
  },
  reducers: {
    setSelectById(state, { payload }) {
      state.selectById = payload
    },
    setMedicalReportId(state, { payload }) {
      state.medicalReportId = payload
    },
    setPlannedDataProdactsToBy(state, { payload }) {
      if (state.plannedDataProdactsToBy.length === 0) {
        state.plannedDataProdactsToBy = [payload]
      } else {
        state.plannedDataProdactsToBy = [...state.plannedDataProdactsToBy, payload]
      }
    },
    deletePlannedDataProdactsToBy(state, { payload }) {
      const index = state.plannedDataProdactsToBy.findIndex((n) => n.id === payload.id)
      if (index !== -1) {
        state.plannedDataProdactsToBy.splice(index, 1)
      }
    },
    clearPlannedDataProdactsToBy(state) {
      state.plannedDataProdactsToBy = []
    },
    setPlannedDataAnalysisToBy(state, { payload }) {
      if (state.plannedDataAnalysisToBy.length === 0) {
        state.plannedDataAnalysisToBy = [payload]
      } else {
        state.plannedDataAnalysisToBy = [...state.plannedDataAnalysisToBy, payload]
      }
    },
    deletePlannedDataAnalysisToBy(state, { payload }) {
      const index = state.plannedDataAnalysisToBy.findIndex((n) => n.id === payload.id)
      if (index !== -1) {
        state.plannedDataAnalysisToBy.splice(index, 1)
      }
    },
    clearPlannedDataAnalysisToBy(state) {
      state.plannedDataAnalysisToBy = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAppointmentById.fulfilled, (state, action) => {
      state.dataById = action.payload
      state.status = 'success'
    })
    builder.addCase(getAppointmentById.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getAppointmentById.rejected, (state) => {
      state.status = 'error'
    })
  },
})

export const {
  setSelectById,
  setMedicalReportId,
  setPlannedDataProdactsToBy,
  deletePlannedDataProdactsToBy,
  clearPlannedDataProdactsToBy,
  setPlannedDataAnalysisToBy,
  deletePlannedDataAnalysisToBy,
  clearPlannedDataAnalysisToBy,
} = appointmentByIdSlice.actions

export default appointmentByIdSlice.reducer
