export function arrayUniqueBy<T>(array: T[], accessor: (T) => any): typeof array {
  const lookup = new Map<any, T>()
  array.forEach((entry) => {
    const key = accessor(entry)
    if (!lookup.has(key)) {
      lookup.set(key, entry)
    }
  })
  return Array.from(lookup.values())
}
