import React from 'react'
import Styles from './style.module.scss'
import { ButtonArrow } from '@components/ui/ButtonArrow'
import { TooltipComponent } from '@components/TooltipComponent'
import { Price } from '../Price'

export const CardContainer = (props) => {
  return (
    <div className={`${Styles.reference__container} ${props.className}`}>
      <div onClick={props.onClick} className={Styles.reference__wrap}>
        <header className={Styles.reference__header}>
          <img src={props.src} className={Styles.reference__img} alt='logo' />
          <h4 className={Styles.reference__title}>{props.title}</h4>
        </header>
        <div className={Styles.reference__content}>{props.content}</div>
      </div>
      <div className={Styles.reference__footer}>
        <div className={Styles.price}>
          {props.price && (
            <>
              <Price value={props.price} startOf={props.startOf} />
              <TooltipComponent text={props.tooptipText} />
            </>
          )}
        </div>
        <ButtonArrow lending={props.lending} onClick={props.main ? props.onClickMain : props.onClick} />
      </div>
    </div>
  )
}
