import React from 'react'
import Styles from './style.module.scss'
import navigation from '@icons/navigation.svg'

interface ButtonNavigationProps {
  onClick: (e: any) => void
}

export const ButtonNavigation: React.FC<ButtonNavigationProps> = (props) => {
  return (
    <button className={Styles.button} onClick={props.onClick}>
      <img src={navigation} alt='img' />
      Смотреть на карте
    </button>
  )
}
