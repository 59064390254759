import React, { useState } from 'react'
import Styles from './style.module.scss'

import { TransferSetup } from './components/TransferSetup'
import { TransferComplete } from './components/TransferComplete'

export const DoctorTransfer: React.FC = () => {
  const [completeTransferring, setCompleteTransferring] = useState(false)

  const completeTransfer = () => setCompleteTransferring(true)

  return (
    <div className={Styles.recording}>
      <div className={Styles.block}>
        <span className={Styles.caption}>Перенести прием</span>
      </div>
      {completeTransferring ? <TransferComplete /> : <TransferSetup completeTransfer={completeTransfer} />}
    </div>
  )
}
