import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import timeIcon from '@icons/time.svg'
import { formateDate } from '../../../../../../utils/formateDate'
import { NOTIFICATION_OF_MINUTES } from '../../../../constants'
import { DoctorAppointmentButton } from '../../../../../../pages/doctor/components/DoctorCabinet/components/DoctorAppointmentButton'

interface DoctorPatientRecordProps {
  className?: string
}

export const DoctorPatientRecord: React.FC<DoctorPatientRecordProps> = (props) => {
  const { className } = props

  const currentAppointment = useAppSelector((state) => state.doctor.currentAppointment)
  const [notification, setNotification] = useState(null)
  const [thisStatus, setThisStatus] = useState(currentAppointment.status)

  let classNameRecord =
    currentAppointment.status === 'done' ? `${Styles.record} ${Styles.record_complete}` : `${Styles.record}`
  let classNameHeader = currentAppointment.is_online ? `${Styles.header}` : `${Styles.header} ${Styles.header_mod}`

  if (className) {
    classNameRecord = `${classNameRecord} ${className}`
  }

  if (currentAppointment.status === 'done') {
    classNameHeader = `${classNameHeader} ${Styles.header_complete}`
  }

  useEffect(() => {
    if (
      formateDate(new Date(), 'YYYY-MM-DD') === formateDate(new Date(currentAppointment.day_reception), 'YYYY-MM-DD')
    ) {
      const currentTime = new Date().getTime()
      const appointmentTime = new Date(
        `${currentAppointment.day_reception} ${currentAppointment.time_reception}`,
      ).getTime()

      if (currentTime < appointmentTime) {
        const diffMinutes = appointmentTime - currentTime
        const minutes = diffMinutes / 60000

        if (minutes <= NOTIFICATION_OF_MINUTES) {
          setNotification(Math.ceil(minutes))
        }
      }
    }
  }, [])

  if (Object.keys(currentAppointment).length === 0) {
    return null
  }

  return (
    <div className={classNameRecord}>
      <div className={classNameHeader}>
        {notification && (
          <div className={Styles.notification}>
            <img src={timeIcon} alt='Иконка' />
            <span className={Styles.across}>Через {notification} минут</span>
          </div>
        )}
        <div className={Styles.container}>
          <span className={Styles.time}>{currentAppointment.time_reception}</span>
          <span className={Styles.type}>{currentAppointment.is_online ? 'Онлайн прием' : 'Личный прием'}</span>
        </div>
        <DoctorAppointmentButton
          appointment={currentAppointment}
          thisStatus={thisStatus}
          setThisStatus={setThisStatus}
        />
        {/* {currentAppointment.status !== 'done' && currentAppointment.is_online && (
          <button className={Styles.start}>Начать прием</button>
        )} */}
        {/* {currentAppointment.status === 'done' && !currentAppointment.medical_report && (
          <button className={`${Styles.start} ${Styles.start_create}`}>Создать заключение</button>
        )} */}
        {/* {currentAppointment.status === 'done' && currentAppointment.medical_report && (
          <div className={Styles.conclusion}>
            <img src={viewIcon} alt='Иконка' />
            <span className={Styles.text}>Заключение</span>
          </div>
        )} */}
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>Консультация</span>
        <p className={Styles.description}>{currentAppointment?.consultation_type?.direction.title}</p>
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>Тип консультации</span>
        <div className={Styles.wrap}>
          <p className={Styles.description}>{currentAppointment?.consultation_type?.title}</p>
        </div>
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>Дата и время</span>
        <p className={Styles.description}>
          {formateDate(currentAppointment.day_reception, 'DD MMMM')}, {currentAppointment.time_reception}
        </p>
      </div>
    </div>
  )
}
