import React, { useState } from 'react'
import Styles from './style.module.scss'
import { useAppDispatch } from '@app/redux/hooks'
import { getSurveysById } from '../../../../pages/authorization/redux/authSlice'
import { DoctorSurveysDrawerById } from '../DoctorSurveysDrawerById'

export const DoctorSurveys = (props) => {
  const dispatch = useAppDispatch()
  const [showDetail, setShowDetail] = useState(false)
  const [detailItem, setDetailItem] = useState(null)
  const openDetails = (e) => {
    dispatch(getSurveysById(e)).then((res) => {
      setShowDetail(true)
      setDetailItem(res.payload.data)
    })
  }

  return (
    <>
      {props.data &&
        props.data.map((item) => (
          <div key={item.id} className={Styles.block}>
            <button onClick={() => openDetails(item.research_id)} className={Styles.input}>
              {item.name}
            </button>
          </div>
        ))}
      {detailItem && (
        <DoctorSurveysDrawerById showDetail={showDetail} setShowDetail={setShowDetail} detailItem={detailItem} />
      )}
    </>
  )
}
