import React, { useState } from 'react'
import Styles from './style.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
import declination from '@helpers/declination'
import plusIcon from '@icons/plus-icon.svg'
import calendarIcon from '@icons/calendar.svg'
import { Tooltip } from '../../../../pages/doctor/components/UI/Tooltip'
import 'swiper/swiper.scss'
import { useAppDispatch } from '@app/redux/hooks'
import {
  deletePlannedDataAnalysisToBy,
  setPlannedDataAnalysisToBy,
} from '../../../../pages/my-appointment/redux/appointmentByIdSlicer'
import useWindowSize from '@helpers/useWindowSizeHook'
import { MOBILE_BREAKPOINT } from '../../../../global-constants'

const StyledCheckbox = styled(Checkbox)(() => ({
  color: '#C9DBFD',
  '&.Mui-checked': {
    color: '#7D9DFF',
  },
}))
const set = false

export const AnalysisItemSmall = ({ item }) => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const [checked, setChecked] = useState(false)
  const checkedProduct = (e) => {
    setChecked(!checked)
    if (e.target.checked) {
      dispatch(deletePlannedDataAnalysisToBy({ id: item.id }))
      dispatch(
        setPlannedDataAnalysisToBy({
          id: item.id,
          checked: e.target.checked,
          price: item.price,
          discount_price: item.discount_price,
          quantity: 1,
        }),
      )
    } else {
      dispatch(deletePlannedDataAnalysisToBy({ id: item.id }))
    }
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.block}>
        <div>
          {item.discount_price !== item.price && <span className={Styles.old}>{item.price} ₽</span>}
          <span className={`${Styles.price} ${!checked && Styles.text_disable}`}>{item.discount_price} ₽</span>
        </div>

        <StyledCheckbox color='default' name='name' onChange={checkedProduct} checked={checked} />
      </div>

      {set ? (
        <div className={Styles.wrap}>
          <span className={Styles.title}>{item.title}</span>
        </div>
      ) : (
        <Link to={`/catalog/research/${item.id}`} className={Styles.wrap}>
          <span className={Styles.title}>{item.title}</span>
        </Link>
      )}

      {set && (
        <div className={Styles.list}>
          {item.analyzes_in_set.map((item, id) => (
            <div key={id} className={Styles.wrapper}>
              {id !== 0 && <img className={Styles.plus} src={plusIcon} alt='Иконка' />}
              <Link to={`/catalog/research/${item.id}`} className={Styles.product} key={id}>
                <span className={Styles.symbol}>{item.short_title}</span>
                <Tooltip className={Styles.tooltip}>{item.title}</Tooltip>
              </Link>
            </div>
          ))}
        </div>
      )}

      {width > MOBILE_BREAKPOINT && (
        <div className={Styles.wrap}>
          <div className={Styles.date}>
            <img src={calendarIcon} alt='Иконка' />
            <span>
              Срок исполнения до{` `}
              {declination(item.execution_time, 'дня', 'дней', 'дней')}
            </span>
          </div>
          <div className={Styles.performers}>
            {item.labs.map((tag, id) => (
              <div className={Styles.image} key={id}>
                <img src={tag} alt='Иконка' />
              </div>
            ))}
          </div>
        </div>
      )}
      {width < MOBILE_BREAKPOINT && (
        <Swiper className={Styles.swiper} slidesPerView='auto' spaceBetween={8}>
          <SwiperSlide className={Styles.slide}>
            <div className={Styles.date}>
              <img src={calendarIcon} alt='Иконка' />
              Срок исполнения до{` `}
              {declination(item.execution_time, 'дня', 'дней', 'дней')}
            </div>
          </SwiperSlide>
          {item.labs.map((tag, id) => (
            <SwiperSlide className={Styles.slide} key={id}>
              <div className={Styles.image}>
                <img src={tag} alt='Иконка' />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}
