import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

import { DoctorPatientVisitMobile } from '../DoctorPatientVisit/components/DoctorPatientVisitMobile'

import { STEP_DISPLAY_APPOINTMENTS } from '../../../../../../constants'

export const DoctorPatientHistoryMobile: React.FC = () => {
  const currentAppointmentsPatient = useAppSelector((state) => state.doctor.currentAppointmentsPatient)
  const [displayedAppountments, setSisplayedAppountments] = useState([])
  const [currentStep, setCurrentStep] = useState(5)
  const [dispalyButton, setDispalyButton] = useState(false)

  const moreAppointments = () => setCurrentStep((prev) => prev + STEP_DISPLAY_APPOINTMENTS)

  useEffect(() => {
    if (currentAppointmentsPatient.length > STEP_DISPLAY_APPOINTMENTS) {
      const slicedAppointments = currentAppointmentsPatient.slice(0, currentStep)
      setSisplayedAppountments(slicedAppointments)

      if (currentAppointmentsPatient.length !== slicedAppointments.length) {
        setDispalyButton(true)
      } else {
        setDispalyButton(false)
      }
    } else {
      setSisplayedAppountments(currentAppointmentsPatient)
      setDispalyButton(false)
    }
  }, [currentStep])

  return (
    <div className={Styles.history}>
      <span className={Styles.caption}>Все записи пациента</span>
      <div className={Styles.slider}>
        <Swiper spaceBetween={10} slidesPerView={1} pagination={{ clickable: true }}>
          {displayedAppountments.map((appointment) => (
            <SwiperSlide key={appointment.id} className={Styles.slider__slide}>
              <DoctorPatientVisitMobile appointment={appointment} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {dispalyButton && (
        <button className={Styles.more} onClick={moreAppointments}>
          Смотреть еще 5 записей
        </button>
      )}
    </div>
  )
}
