/* eslint-disable camelcase */
import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getProducts: any = createAsyncThunk('catalog/getProducts', async (props: any) => {
  const {
    page,
    brand = '',
    rating = '',
    category = '',
    form = '',
    min_price = '',
    max_price = '',
    ordering = 'id',
  } = props

  const products = await api.get(
    `products/?page=${page}&brand=${brand}&rating=${rating}&category=${category}&form=${form}&min_price=${min_price}&max_price=${max_price}&ordering=${ordering}`,
  )
  if (products.status === 200) {
    return products.data
  } else {
    throw new Error()
  }
})

export const getAnalyzes: any = createAsyncThunk('catalog/getAnalyzes', async (props: any) => {
  const { page, body_system = '', categories = '', prescribed = false, analyzesOrdering = '' } = props
  const analyzes = await api.get(
    `analyzes/?page=${page}&body_system=${body_system}&category=${categories}${
      prescribed ? '&prescribed=true' : ''
    }&ordering=${analyzesOrdering}`,
  )
  // const analyzes = await api.get(`analyzes/`)
  if (analyzes.status === 200) {
    return analyzes.data
  } else {
    throw new Error()
  }
})

export const getComplexAnalyzes: any = createAsyncThunk('catalog/getComplexAnalyzes', async () => {
  const analyzes = await api.get(`analysis_sets/`)
  if (analyzes.status === 200) {
    return analyzes.data
  } else {
    throw new Error()
  }
})

export const getAnalyzesById: any = createAsyncThunk('catalog/getAnalyzesById', async (id) => {
  const analyzes = await api.get(`analyzes/${id}/`)
  return analyzes.data
})

export const getReviews: any = createAsyncThunk('catalog/getReviews', async (props: any) => {
  const { id, has_photo = false } = props
  const reviews = await api.get(`product_reviews/${id}/${has_photo ? '?has_photo=true' : ''}`)
  return reviews.data
})
export const postReview: any = createAsyncThunk('catalog/postReview', async (props: any) => {
  const { data, id } = props
  const reviews = await api.post(`product_reviews/${id}/new/`, data)
  return reviews
})
export const postVote: any = createAsyncThunk('catalog/postVote', async (props: any) => {
  const { productId, reviewId, direction } = props
  const reviews = await api.post(`product_reviews/${productId}/vote/?review=${reviewId}&${direction}`, null)
  return reviews
})

export const getFilterList: any = createAsyncThunk('catalog/getFilterList', async () => {
  const filters = await api.get('products/filter-list/')
  return filters.data
})

export const getAnalyzesFilterList: any = createAsyncThunk('catalog/getAnalyzesFilterList', async () => {
  const filters = await api.get('analyzes/filter-list/')
  return filters.data
})

export const getLastViewed: any = createAsyncThunk('catalog/getLastViewed', async () => {
  const viewed = await api.get('last-viewed-products/')
  return viewed.data
})

export const searchCatalog: any = createAsyncThunk('catalog/searchCatalog', async (key: string) => {
  const result = await api.get(`search/?key=${key}`)
  return result.data
})

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState: {
    status: null,
    loading: false,
    isDrawer: false,
    isPopup: false,
    popupContent: '',
    view: 'nutraceuticals',
    filterView: 'all',
    products: {
      count: 0,
      results: [],
    },
    productsPage: 1,
    productsLimit: 16,
    productsLeft: 0,
    currentProductsQuantity: 0,
    analyzes: {
      count: 0,
      results: [],
    },
    analyzesPage: 1,
    analyzesLimit: 16,
    analyzesLeft: 0,
    currentAnalyzesQuantity: 0,
    analysesSets: [],
    reviews: [],
    reviewsLoading: false,
    reviewsFilter: {
      has_photo: false,
    },
    filterList: [],
    productFilter: {
      brand: '',
      rating: '',
      category: '',
      form: '',
      min_price: '',
      max_price: '',
    },
    ordering: 'id',
    analyzesFilter: {
      body_system: '',
      categories: '',
      prescribed: null,
    },
    analyzesOrdering: 'discount_price',
    analyzesFilterList: [],
    listLastViewed: [],
    searchResults: {
      analysis_sets: [],
      analyzes: [],
      nutraceuticals: [],
      posts: [],
      products: [],
    },
  } as unknown as any, // тут будет тип из apiTypes
  reducers: {
    setStatus: (state, { payload }) => {
      state.status = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setIsDrawer: (state, { payload }) => {
      state.isDrawer = payload
    },
    setIsPopup: (state, { payload }) => {
      state.isPopup = payload
    },
    setPopupContent: (state, { payload }) => {
      state.popupContent = payload
    },
    setView: (state, { payload }) => {
      state.view = payload
    },
    setFilterView: (state, { payload }) => {
      state.filterView = payload
    },
    turnProductPage: (state, { payload }) => {
      state.productsPage += payload
    },
    turnAnalyzesPage: (state, { payload }) => {
      state.analyzesPage += payload
    },
    setCurrentProductQuantity: (state, { payload }) => {
      state.currentProductsQuantity = payload
    },
    setFilterProps: (state, { payload }) => {
      state.products = {
        count: 0,
        results: [],
      }
      state.productsPage = 1
      state.productFilter = { ...state.productFilter, ...payload }
    },
    setReviewsFilterProps: (state, { payload }) => {
      state.reviews = []
      state.reviewsFilter = { ...state.reviewsFilter, ...payload }
    },
    setOrdering: (state, { payload }) => {
      state.products = {
        count: 0,
        results: [],
      }
      // state.productsPage = 1
      state.ordering = payload
    },
    setAnalyzesProps: (state, { payload }) => {
      state.analyzes = {
        count: 0,
        results: [],
      }
      state.analyzesPage = 1
      state.analyzesFilter = { ...state.analyzesFilter, ...payload }
    },
    setAnalyzesOrdering: (state, { payload }) => {
      state.analyzes = {
        count: 0,
        results: [],
      }
      // state.productsPage = 1
      state.analyzesOrdering = payload
    },
    resetSearchResults: (state) => {
      state.searchResults = {
        analysis_sets: [],
        analyzes: [],
        nutraceuticals: [],
        posts: [],
        products: [],
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.loading = false

      state.products = { count: payload.count, results: [...state.products.results, ...payload.results] }

      if (state.products.count > state.productsLimit * state.productsPage) {
        state.currentProductsQuantity = state.productsLimit * state.productsPage
        state.productsLeft = state.products.count - state.currentProductsQuantity
      } else if (state.products.count < state.productsLimit * state.productsPage) {
        state.currentProductsQuantity =
          state.productsLimit * state.productsPage + (state.products.count - state.productsLimit * state.productsPage)
        state.productsLeft = state.products.count - state.currentProductsQuantity
      }
    })
    builder.addCase(getProducts.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getAnalyzes.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAnalyzes.fulfilled, (state, { payload }) => {
      state.loading = false

      state.analyzes = { count: payload.count, results: [...state.analyzes.results, ...payload.results] }

      if (state.analyzes.count > state.analyzesLimit * state.analyzesPage) {
        state.currentAnalyzesQuantity = state.analyzesLimit * state.analyzesPage
        state.analyzesLeft = state.analyzes.count - state.currentAnalyzesQuantity
      } else if (state.analyzes.count < state.analyzesLimit * state.analyzesPage) {
        state.currentAnalyzesQuantity =
          state.analyzesLimit * state.analyzesPage + (state.analyzes.count - state.analyzesLimit * state.analyzesPage)
        state.analyzesLeft = state.analyzes.count - state.currentAnalyzesQuantity
      }
    })
    builder.addCase(getAnalyzes.rejected, (state) => {
      state.loading = false
      // console.log(payload)
    })
    builder.addCase(getComplexAnalyzes.fulfilled, (state, { payload }) => {
      state.analysesSets = payload
    })
    builder.addCase(getReviews.pending, (state) => {
      state.reviewsLoading = true
    })
    builder.addCase(getReviews.fulfilled, (state, { payload }) => {
      state.reviews = payload
      state.reviewsLoading = false
    })
    builder.addCase(getReviews.rejected, (state) => {
      state.reviewsLoading = false
    })
    builder.addCase(getFilterList.fulfilled, (state, { payload }) => {
      state.loading = false
      state.filterList = payload
    })
    builder.addCase(getAnalyzesFilterList.fulfilled, (state, { payload }) => {
      state.loading = false
      state.analyzesFilterList = payload
    })
    builder.addCase(getLastViewed.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getLastViewed.fulfilled, (state, { payload }) => {
      state.loading = false
      state.listLastViewed = payload
    })
    builder.addCase(getLastViewed.rejected, (state) => {
      state.loading = false
      // console.log(payload)
    })
    builder.addCase(searchCatalog.pending, () => {
      // state.loading = true
    })
    builder.addCase(searchCatalog.fulfilled, (state, { payload }) => {
      // state.loading = false
      state.searchResults = payload
    })
    builder.addCase(searchCatalog.rejected, () => {
      // state.loading = false
      // console.log(payload)
    })
  },
})

const { actions, reducer } = catalogSlice

export const {
  setStatus,
  setLoading,
  setIsDrawer,
  setIsPopup,
  setPopupContent,
  setView,
  setFilterView,
  turnProductPage,
  turnAnalyzesPage,
  setFilterProps,
  setCurrentProductQuantity,
  setOrdering,
  setReviewsFilterProps,
  setAnalyzesProps,
  setAnalyzesOrdering,
  resetSearchResults,
} = actions

export default reducer
