import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setCurrentMessages,
  setDialogues,
  setDialogueUnreadedCount,
  setDialogueStatus,
  // setDialoguesLastMessage,
  setCurrentPage,
  setWholePages,
  setContent,
  setLoader,
  setCurrentSlug,
  setStatus,
} from '../redux/chatSlice'
import { ISendInterface } from './types'
import { useAppSelector } from '@app/redux/hooks'
import { tokenCheck, tokenRefresh } from '../../../pages/authorization/redux/authSlice'
import { API_CONFIG } from '@app/api'
// ERROR_OCCURRED = 0
//     GROUP_MESSAGE = 1
//     DIALOG_MESSAGE = 2
//     FETCH_GROUPS = 3
//     FETCH_GROUP_MESSAGES = 4
//     FETCH_DIALOG_MESSAGES = 5
//     MESSAGE_UPDATE = 6
//     MESSAGE_READ = 7
//     MESSAGE_READ_ALL_BEFORE_CURRENT = 8
//     UNREAD_MESSAGES_COUNT = 9
//     LEAVE_GROUP = 10
//     IS_TYPING = 11
//     ONLINE = 12
//     OFFLINE = 13

const actions = {
  sendGroupMessage: 1,
  // joinGroup: 2,
  sendDialogueMessage: 2,
  getGroups: 3,
  getGroupMessages: 4,
  getGroupsList: 5,
  sendLastReadedMessage: 9,
  getUnreadedCount: 10,
  checkDialogueExistence: 11,
  userCame: 12,
  userLeft: 13,
  // еще есть IS_TYPING
}
let socket: WebSocket = null

export const useChat = () => {
  const dispatch = useDispatch()
  const { access, refresh } = useAppSelector((state) => state.auth.user.token)

  const currentMessages = useAppSelector((state) => state.chat.currentMessages)
  // const currentSlug = useAppSelector((state) => state.chat.currentSlug)
  // const currentDialogueMember = useAppSelector((state) => state.chat.currentDialogueMember)
  const currentDialogues = useAppSelector((state) => state.chat.dialogues)
  // const currentGenChatMembers = useAppSelector((state) => state.chat.genChatMembers)
  const uploadedFile = useAppSelector((state) => state.chat.uploadedFile)

  useEffect(() => {
    const connect = () => {
      if (!socket) {
        socket = new WebSocket(`wss://${API_CONFIG.hostURL}/ws/chat/?token=${access}`)

        socket.onopen = () => {
          // console.log('websocket opened')
          dispatch(setContent(''))
          dispatch(setStatus('connected'))
        }

        socket.onerror = () => {
          // console.log('error', error)
          dispatch(setContent('error'))
        }

        socket.onclose = (ev) => {
          dispatch(setContent('loading'))
          // console.log('...websocket is closing', ev)
          if (ev.code === 1006) {
            dispatch(tokenCheck({ token: access })).then(
              () => {
                connect()
              },
              () => {
                dispatch(tokenRefresh({ refresh })).then(
                  () => {
                    connect()
                  },
                  () => {
                    window.location.reload()
                  },
                )
              },
            )
          }
        }
      }
    }
    connect()
  }, [])
  if (socket) {
    socket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data)
      // TODO: очень странное, но рабочее условие, надо бы переделать
      if (data.groups?.length === 0) {
        dispatch(setContent('no-content'))
      } else {
        if (data.groups) {
          dispatch(setDialogues(data.groups))
          dispatch(setCurrentSlug(data.groups[0].slug))
        }
      }
      if (data.action === 1) {
        const arr = []
        arr.unshift(data.message)
        dispatch(setCurrentMessages([...currentMessages, data.message]))
      }
      if (data.action === 2) {
        dispatch(setCurrentMessages([...currentMessages, data.message]))
      }

      if (data.action === 3) {
        // const isDialogueInList = currentDialogues.some((dialogue) => dialogue.slug === data.group.slug)

        // if (currentSlug === 'nonExistDialogue') {
        //   dispatch(setCurrentSlug(data.group.slug))
        // }

        // if (isDialogueInList) {
        //   dispatch(setDialoguesLastMessage(data))
        // } else {
        //   getGroupsList(1)
        // }

        // if (currentSlug === data.group.slug || currentDialogueMember.id === data.group.other_user.id) {
        //   const arr = []
        //   arr.push(data.message)
        //   dispatch(setCurrentMessages([...currentMessages, ...arr]))
        // }
        dispatch(setDialogues(data.result))
        // dispatch(setCurrentSlug(data.groups[0].slug))
      }

      if (data.action === 4) {
        dispatch(setCurrentMessages(data.result.sort((firstEl, secondEl) => firstEl.id - secondEl.id)))

        dispatch(setCurrentPage(data.page))
        dispatch(setWholePages(data.num_pages))
        dispatch(setLoader(false))
      }

      if (data.action === 5) {
        dispatch(setDialogues(data.result))
      }

      if (data.action === 10) {
        dispatch(setDialogueUnreadedCount(data))
      }

      if (data.action === 11) {
        data.result.length === 0 ? dispatch(setCurrentMessages([])) : dispatch(setCurrentMessages(data.result))
      }

      if (data.action === 12 || data.action === 13) {
        const dataToDispatch = { id: data.user_pk, status: data.action === 12 ? 1 : 0 }
        const isExistingDialogue = currentDialogues.some((dialogue) => {
          if (dialogue.other_user) {
            return dialogue.other_user.id === data.user_pk
          }
        })
        if (isExistingDialogue) {
          dispatch(setDialogueStatus(dataToDispatch))
        }
      }
    }
  }

  const send = (data: ISendInterface) => {
    socket.send(JSON.stringify(data))
  }

  const sendGroupMessage = (groupName: string, message: string) => {
    const dataToSend: any = {
      action: actions.sendGroupMessage,
      group: groupName,
    }
    if (message && message !== '') {
      dataToSend.message = message
    }
    if (!message && message === '') {
      dataToSend.message = ' '
    }

    if (uploadedFile && uploadedFile.length !== 0) {
      dataToSend.files_pk = uploadedFile
    }

    send(dataToSend)
  }

  const joinGroup = () => {
    //   action: actions.joinGroup,
    //   group: groupName,
    // }
    // send(dataToSend)
  }

  const sendDialogueMessage = (recipient: number, message: string) => {
    const dataToSend1 = {
      action: actions.sendDialogueMessage,
      recipient,
      message: message || ' ',
      files_pk: uploadedFile,
    }

    const dataToSend2 = {
      action: actions.sendDialogueMessage,
      recipient,
      message,
    }

    uploadedFile.length === 0 ? send(dataToSend2) : send(dataToSend1)
  }

  const sendLastReadedMessage = (messageId: number, groupName: string) => {
    const dataToSend = {
      action: actions.sendLastReadedMessage,
      message_pk: messageId,
      group: groupName,
    }

    send(dataToSend)
  }

  const getGroupMessages = (groupName: string, page: number) => {
    dispatch(setLoader(true))
    const dataToSend = {
      action: actions.getGroupMessages,
      group: groupName,
      page,
    }

    setTimeout(() => {
      send(dataToSend)
    })
  }

  const getGroupsList = (page: number) => {
    const dataToSend = {
      action: actions.getGroupsList,
      page,
    }

    send(dataToSend)
  }

  const checkDialogueExistence = (userId: number) => {
    const dataToSend = {
      action: actions.checkDialogueExistence,
      user_pk: userId,
    }

    send(dataToSend)
  }

  const getGroups = () => {
    send({ action: actions.getGroups })
  }

  return {
    sendGroupMessage,
    joinGroup,
    sendDialogueMessage,
    sendLastReadedMessage,
    getGroupMessages,
    getGroupsList,
    checkDialogueExistence,
    getGroups,
  }
}
