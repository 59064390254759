/* eslint-disable camelcase */
import React from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'

export const SearchNutraceutical = ({ item, resetValue }) => {
  const imageSource = item.variants[0]?.images[0]?.image ? `${item.variants[0]?.images[0]?.image}` : ``
  const { price } = item.variants[0]?.modifications[0]
  const { discount_price } = item.variants[0]?.modifications[0]

  return (
    <Link key={item.id} className={Styles.link} to={`/catalog/nutraceutical/${item.id}`} onClick={resetValue}>
      <li className={Styles.item}>
        <div className={Styles.image}>
          <img src={imageSource} alt='Фото' />
        </div>
        <div className={Styles.column}>
          <span className={Styles.name}>
            {item.brand}, {item.name}
          </span>
          <div className={Styles.prices}>
            <span className={Styles.current}>{discount_price} ₽</span>
            <span className={Styles.old}>{price} ₽</span>
          </div>
        </div>
      </li>
    </Link>
  )
}
