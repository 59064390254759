import React from 'react'
import Styles from './style.module.scss'

interface TooltipProps {
  className?: string
}

export const Tooltip: React.FC<TooltipProps> = ({ className, children }) => {
  const cls = [`${Styles.tooltip}`]

  if (className) {
    cls.push(className)
  }

  return (
    <div className={cls.join(' ')}>
      <span>{children}</span>
    </div>
  )
}
