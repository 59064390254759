import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import { Total } from '../Total'
import safety from '@icons/safety.svg'
import list from '@icons/list.svg'
import { BorderLine } from '@components/ui/BorderLine'
import { Link } from 'react-router-dom'
import { useAppSelector } from '@app/redux/hooks'

interface CartSidebarProps {
  activeField?: number
  button?: boolean
}

export const CartSidebar: React.FC<CartSidebarProps> = ({ activeField, button }) => {
  const cartData = useAppSelector((state) => state.cart.cartData)
  const preparation = [...cartData?.analyzes, ...cartData?.analysis_sets]
  const [prepActive, setPrepActive] = useState(false)
  useEffect(() => {
    preparation.map((item) => {
      if (item.preparation_needed) {
        setPrepActive(true)
      }
    })
  }, [preparation])
  return (
    <div className={Styles.sidebar}>
      <Total button={button} activeField={activeField} />
      <div className={Styles.safety}>
        <img src={safety} alt='logo' />
        <div className={Styles.safety__wrap}>
          <h4 className={Styles.safety__title}>Мы гарантируем безопасность</h4>
          <p className={Styles.safety__text}>Платежи c VISA, MasterCard и МИР.</p>
        </div>
      </div>
      {prepActive && (
        <div className={Styles.memo}>
          <div className={Styles.memo__wrap}>
            <img src={list} alt='logo' />
            <div className={Styles.safety__wrap}>
              <h4 className={Styles.safety__title}>Не забудьте о подготовке</h4>
              <p className={Styles.safety__text}>
                При несоблюдении правил подготовки результаты обследования могут быть неверными и врач не сможет сделать
                заключение
              </p>
            </div>
          </div>
          <BorderLine />
          <div>
            <ul>Анализы из корзины требующие подготовки</ul>
            {preparation.map((item, id) => (
              <div key={item.id}>
                {item.preparation_needed && (
                  <li key={id}>
                    <Link className={Styles.memo__link} to={`/catalog/research/${item.id}`}>
                      {item.title}
                    </Link>
                  </li>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
