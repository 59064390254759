import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '../../../../helpers/useWindowSizeHook'
import Styles from './style.module.scss'
import { DoctorConsequence } from './components/DoctorConsequence'
import { DoctorPatientInformation } from './components/DoctorPatientInformation'
import { DoctorPatientHistory } from './components/DoctorPatientHistory'
import { DoctorPatientHistoryMobile } from './components/DoctorPatientHistory/components/DoctorPatientHistoryMobile'
import { DoctorPatientRecord } from './components/DoctorPatientRecord'
import { DoctorPatientRecordMobile } from './components/DoctorPatientRecord/components/DoctorPatientRecordMobile'
import { DoctorPatientInfo } from './components/DoctorPatientInfo'
import { setIsTransferModal, setTransferRecord, cancelAppointment, getAppointment } from '../../redux/doctorSlicer'
import { MOBILE_BREAKPOINT } from '../../constants'
import { TransitionsModal } from '@components/Modal'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import { ButtonWithBorder } from '@components/ui/ButtonWithBorder'

import { ButtonColor } from '@components/ui/ButtonColor'
import { MobileModal } from '@components/ui/MobileModal'
import { editAppointmentById } from '../../../../pages/appointment-page/redux/consultationSlicer'

export const DoctorNotation: React.FC = () => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const currentAppointment = useAppSelector((state) => state.doctor.currentAppointment)
  const [openModalReset, setOpenModalReset] = useState(false)
  const [successReset, setSuccessReset] = useState(false)
  const [absence, setAbsence] = useState(false)
  const [notPaid, setNotPaid] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState(false)
  const inputHandler = (e) => {
    setInputValue(e.target.value)
  }

  const onCancelAppointment = () => {
    dispatch(
      editAppointmentById({ id: currentAppointment.id, data: { absence, not_paid: notPaid, detail: inputValue } }),
    ).then(() => {
      dispatch(cancelAppointment(currentAppointment.id)).then((res) => {
        window.location.reload()
        if (res.payload.status < 300) {
          setOpenModalReset(false)
          setSuccessReset(true)
        }
        setError(true)
      })
    })
  }

  const openRecordTransfer = () => {
    dispatch(setIsTransferModal(true))
    dispatch(setTransferRecord(currentAppointment))
  }

  return (
    <>
      {width > MOBILE_BREAKPOINT ? (
        <div className={Styles.notation}>
          <div className={Styles.block}>
            {currentAppointment.medical_report && <DoctorConsequence />}
            <DoctorPatientInformation />
            <DoctorPatientHistory />
          </div>
          <div className={Styles.block}>
            <div className={Styles.block_wrap}>
              <DoctorPatientRecord className={Styles.mr} />
              <DoctorPatientInfo />
            </div>
            <div className={Styles.buttons}>
              {(currentAppointment.is_pay || currentAppointment.is_free) &&
                (currentAppointment.status !== 'done' ||
                  currentAppointment.status !== 'cancel' ||
                  currentAppointment.status !== 'started') && (
                  <button className={Styles.transfer} onClick={openRecordTransfer}>
                    Поменять время записи
                  </button>
                )}
            </div>
            {(currentAppointment.status !== 'done' ||
              currentAppointment.status !== 'cancel' ||
              currentAppointment.status !== 'started') && (
              <button className={Styles.button__reset} onClick={() => setOpenModalReset(true)}>
                Отменить запись
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className={Styles.notation}>
          <div className={Styles.block}>
            <DoctorPatientInformation />
            {currentAppointment.status !== 'done' ||
              currentAppointment.status !== 'cancel' ||
              (currentAppointment.status !== 'started' && (
                <button className={Styles.button__reset} onClick={() => setOpenModalReset(true)}>
                  Отменить запись
                </button>
              ))}
            <div className={Styles.buttons}>
              {(currentAppointment.is_pay || currentAppointment.is_free || currentAppointment.status !== 'started') &&
                (currentAppointment.status !== 'done' || currentAppointment.status !== 'cancel') && (
                  <button className={Styles.transfer} onClick={openRecordTransfer}>
                    Поменять время записи
                  </button>
                )}
            </div>
            <DoctorPatientHistoryMobile />
          </div>
          <div className={Styles.block}>
            <DoctorPatientRecordMobile />
            <DoctorPatientInfo />
          </div>
        </div>
      )}
      {width > MOBILE_BREAKPOINT ? (
        <TransitionsModal isOpenModal={openModalReset} setModalOpened={() => setOpenModalReset((isOpen) => !isOpen)}>
          <div className={Styles.container}>
            <button className={Styles.close__icon} onClick={() => setOpenModalReset(false)} />
            <h2>Причина отмены записи</h2>
            <div className={Styles.buttons}>
              <ButtonWithBorder
                value='Пациент не пришел'
                onClick={() => setAbsence((prev) => !prev)}
                className={absence && Styles.button_active}
              />
              <ButtonWithBorder
                value='Прием не оплачен'
                onClick={() => setNotPaid((prev) => !prev)}
                className={notPaid && Styles.button_active}
              />
            </div>
            <h4>Своя причина</h4>
            <textarea
              onChange={inputHandler}
              value={inputValue}
              className={Styles.optional__input}
              placeholder='Введите свой вариант причины отказа'
            />
            <div className={Styles.button__container}>
              <ButtonColor className={Styles.button__final} value='Отменить прием' onClick={onCancelAppointment} />
            </div>
            {error && <span className={Styles.error}>Произошла ошибка</span>}
          </div>
        </TransitionsModal>
      ) : (
        <MobileModal isOpenModal={openModalReset} setModalOpened={() => setOpenModalReset((isOpen) => !isOpen)}>
          <div className={Styles.container}>
            <h2>Причина отмены записи</h2>
            <Swiper spaceBetween={10} slidesPerView={1.8}>
              <SwiperSlide>
                <ButtonWithBorder value='Пациент не пришел' />
              </SwiperSlide>
              <SwiperSlide>
                <ButtonWithBorder value='Прием не оплачен' />
              </SwiperSlide>
            </Swiper>
            <h4>Своя причина</h4>
            <textarea
              onChange={inputHandler}
              value={inputValue}
              className={Styles.optional__input}
              placeholder='Введите свой вариант причины отказа'
            />
            <div className={Styles.button__container}>
              <ButtonColor className={Styles.button__final} value='Отменить прием' onClick={onCancelAppointment} />
            </div>
            {error && <span className={Styles.error}>Произошла ошибка</span>}
          </div>
        </MobileModal>
      )}
      <TransitionsModal isOpenModal={successReset} setModalOpened={() => setSuccessReset((isOpen) => !isOpen)}>
        <div className={Styles.container}>
          <button className={Styles.close__icon} onClick={() => setSuccessReset(false)} />
          <h3>Запись отменена</h3>
        </div>
      </TransitionsModal>
    </>
  )
}
