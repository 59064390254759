import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { MobileProfileAvatar } from '@components/Avatar/MobileProfileAvatar'
import { handleLogout } from '../../pages/authorization/redux/authSlice'
import { DrawerPanel } from '@components/Drawer'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import Styles from './style.module.scss'
import backIcon from '@icons/mobile-profile-back.svg'
import crossIcon from '@icons/mobile-profile-cross.svg'
// import personalIcon from '@icons/mobile-profile-personal.svg'
// import contactsIcon from '@icons/mobile-profile-contacts.svg'
import { EditProfileForm } from '@components/EditProfileForm'
import { ProfileModalInput } from '@components/ProfileModal/components/ProfileModalInput'
import moment from 'moment'
import { ProfileCart } from '../profile/components/ProfileCart'
import { Link } from 'react-router-dom'

export const ProfilePage = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const user = useAppSelector((state) => state.auth.user.info)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const toggleDrawer = () => setIsDrawerOpen((prev) => !prev)
  const handleTabClick = (tabId) => {
    toggleDrawer()
    setActiveTab(tabId)
  }
  const handleLogoutClick = () => {
    dispatch(handleLogout())
    history.push('/')
  }
  return (
    <>
      <div className={Styles.container}>
        <MobileProfileAvatar firstName={user.first_name} lastName={user.last_name} avatar={user.avatar} />
        <ProfileCart />
        <div className={Styles.tabs}>
          <div className={Styles.tabs__item} onClick={() => handleTabClick(0)}>
            {/* <img className={Styles.tabs__icon} src={personalIcon} alt='icon' /> */}
            <span className={Styles.tabs__title}>Личные данные</span>
          </div>
          <div className={Styles.tabs__item} onClick={() => handleTabClick(1)}>
            {/* <img className={Styles.tabs__icon} src={contactsIcon} alt='icon' /> */}
            <span className={Styles.tabs__title}>Контактные данные</span>
          </div>
        </div>
        <div className={Styles.tabs}>
          <Link to='/orders' className={Styles.tabs__item}>
            {/* <img className={Styles.tabs__icon} src={personalIcon} alt='icon' /> */}
            <span className={Styles.tabs__title}>Мои заказы</span>
          </Link>
          <Link to='/catalog' className={Styles.tabs__item}>
            {/* <img className={Styles.tabs__icon} src={personalIcon} alt='icon' /> */}
            <span className={Styles.tabs__title}>Каталог</span>
          </Link>
          <Link to='/blog' className={Styles.tabs__item}>
            {/* <img className={Styles.tabs__icon} src={personalIcon} alt='icon' /> */}
            <span className={Styles.tabs__title}>Блог</span>
          </Link>
        </div>
        <span className={Styles.logout} onClick={handleLogoutClick}>
          Выйти из аккаунта
        </span>
      </div>
      <DrawerPanel width='100%' anchor='right' open={isDrawerOpen} onClose={toggleDrawer}>
        <div className={Styles.header}>
          <img className={Styles.header__icon} src={backIcon} alt='back' onClick={toggleDrawer} />
          <div className={Styles.header__title}>{activeTab === 0 ? 'Личные данные' : 'Контактные данные'}</div>
          <img className={Styles.header__icon} src={crossIcon} alt='X' onClick={toggleDrawer} />
        </div>
        {activeTab === 0 && (
          <div className={Styles.content}>
            <EditProfileForm>
              <ProfileModalInput
                placeholder={user.first_name}
                labelName='Имя*'
                inputName='first_name'
                inputType='text'
              />
              <ProfileModalInput
                placeholder={user.last_name}
                labelName='Фамилия'
                inputName='last_name'
                inputType='text'
              />
              <ProfileModalInput
                placeholder={user.birthday ? moment(user.birthday).format('DD.MM.YYYY') : '__.__.____'}
                labelName='Дата рождения*'
                inputName='birthday'
                inputType='date'
                defaultValue={user.birthday}
              />
            </EditProfileForm>
          </div>
        )}
        {activeTab === 1 && (
          <div className={Styles.content}>
            <EditProfileForm>
              <ProfileModalInput placeholder={user.phone} labelName='Телефон*' inputName='phone' inputType='tel' />
              <ProfileModalInput placeholder={user.email} labelName='Email' inputName='email' inputType='email' />
            </EditProfileForm>
          </div>
        )}
      </DrawerPanel>
    </>
  )
}
