import React from 'react'
import Styles from './style.module.scss'
import { Counter } from '../../ui/Counter'

interface CounterResultProps {
  item: any
  arrNamePatch: string
}

export const CounterResult: React.FC<CounterResultProps> = ({ arrNamePatch, item }) => {
  const { quantity, price, discount_price: discountPrise } = item
  const discount = (price - discountPrise) * quantity
  const sum = quantity * price
  const currSum = quantity * discountPrise

  return (
    <div className={Styles.result__container}>
      <Counter arrNamePatch={arrNamePatch} item={item} />
      {discount > 0 && <span className={Styles.discount}>– {Math.round(discount)} ₽</span>}
      <div className={Styles.block}>
        {currSum !== sum && <span className={Styles.old}>{sum} ₽</span>}
        <span className={Styles.price}>{currSum} ₽</span>
      </div>
      {quantity > 1 && (
        <div className={Styles.result__count}>
          {quantity} шт. &times; {discountPrise} ₽
        </div>
      )}
    </div>
  )
}
