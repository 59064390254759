import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setFilter } from '../../../../../../pages/blog/redux/blogSlice'
import React from 'react'
import Styles from './style.module.scss'

export const FilterItem = ({ title, value }) => {
  const dispatch = useAppDispatch()
  const filter = useAppSelector((state) => state.blog.filter)
  const isActive = filter.scope === value
  const className = isActive ? `${Styles.container} ${Styles.container_active}` : `${Styles.container}`

  const handleFilter = () => {
    !isActive && dispatch(setFilter(value))
  }

  return (
    <div className={className} onClick={handleFilter}>
      {title}
    </div>
  )
}
