import React, { useEffect } from 'react'
import { CalendarSmall } from '@components/CalendarSmall'
import { useForm } from 'react-final-form'

export function CalendarWrapper(props) {
  const form = useForm()
  useEffect(() => {
    form.change(props.label, props.dayOfBirth)
  }, [props.dayOfBirth])

  return (
    <CalendarSmall
      birth={props.birth}
      changeShowCalendar={props.changeShowCalendar}
      setValues={props.setValues}
      values={props.values}
    />
  )
}
