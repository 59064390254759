import React from 'react'
import Styles from './style.module.scss'

import removeIcon from '@icons/remove-blue.svg'

interface FilterAllItemProps {
  title?: string
  rating?: number
  select?: boolean
  setFilter: any
}

export const FilterAllItem: React.FC<FilterAllItemProps> = (props) => {
  const { title, rating, select, setFilter } = props

  const renderRating = () => {
    if (rating === 1) {
      return (
        <>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={Styles.star}>{}</span>
          <span className={Styles.star}>{}</span>
          <span className={Styles.star}>{}</span>
          <span className={Styles.star}>{}</span>
        </>
      )
    }

    if (rating === 2) {
      return (
        <>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={Styles.star}>{}</span>
          <span className={Styles.star}>{}</span>
          <span className={Styles.star}>{}</span>
        </>
      )
    }

    if (rating === 3) {
      return (
        <>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={Styles.star}>{}</span>
          <span className={Styles.star}>{}</span>
        </>
      )
    }

    if (rating === 4) {
      return (
        <>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={Styles.star}>{}</span>
        </>
      )
    }

    if (rating === 5) {
      return (
        <>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
        </>
      )
    }
  }

  return (
    <div
      className={select ? `${Styles.item} ${Styles.item_select}` : `${Styles.item}`}
      onClick={() => {
        !select &&
          setFilter((prev) => ({
            ...prev,
            rating: (prev.rating + rating.toString()).replace(/,/g, '').split('').join(','),
          }))
      }}
    >
      {title && <span className={Styles.title}>{title}</span>}
      {rating && renderRating()}
      {select ? (
        <img
          className={Styles.remove}
          src={removeIcon}
          alt='Иконка'
          onClick={() => {
            setFilter((prev) => ({
              ...prev,
              rating: prev.rating.replace(`${rating.toString()}`, '').replace(/,/g, '').split('').join(','),
            }))
          }}
        />
      ) : (
        <span className={Styles.count} />
      )}
    </div>
  )
}
