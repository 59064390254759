import React from 'react'
import { useAppDispatch } from '@app/redux/hooks'
import Styles from './style.module.scss'
import declination from '@helpers/declination'

interface ICatalogPaginationProps {
  items: {
    count: number
  }
  currentQuantity: number
  itemsLeft: number
  turnPage: any
}

export const CatalogPagination: React.FC<ICatalogPaginationProps> = ({
  items,
  currentQuantity,
  itemsLeft,
  turnPage,
}) => {
  const dispatch = useAppDispatch()

  const loadMore = () => {
    dispatch(turnPage(1))
  }

  return (
    <div className={Styles.progress}>
      <div className={Styles.info}>
        <span>Вы просмотрели&nbsp;</span>
        <strong>
          {currentQuantity} из {items.count} товаров
        </strong>
      </div>
      <div className={Styles.bar}>
        <div className={Styles.inner} style={{ width: `${100 * (currentQuantity / items.count)}%` }} />
      </div>
      {currentQuantity !== items.count && (
        <button className={Styles.button} onClick={loadMore}>
          Показать еще {declination(itemsLeft, 'товар', 'товара', 'товаров')}
        </button>
      )}
    </div>
  )
}
