import React from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setIsDrawer, setFilterView, turnAnalyzesPage } from '../../redux/catalogSlicer'
import Styles from './style.module.scss'

import { DrawerComponent } from '../../../../components/DrawerComponent'
import { CatalogResearchFilter } from './components/CatalogResearchFilter'
import { CatalogResearchItem } from './components/CatalogResearchItem'
import { CatalogResearchFilters } from './components/CatalogResearchFilters'
import { CatalogPagination } from '../CatalogPagination'
import { CatalogComplexItem } from './components/CatalogComplexItem'

export const CatalogResearch: React.FC = () => {
  const dispatch = useAppDispatch()
  const isDrawer = useAppSelector((state) => state.catalog.isDrawer)
  const analyzes = useAppSelector((state) => state.catalog.analyzes)
  const currentAnalyzesQuantity = useAppSelector((state) => state.catalog.currentAnalyzesQuantity)
  const analyzesLeft = useAppSelector((state) => state.catalog.analyzesLeft)
  const analysesSets = useAppSelector((state) => state.catalog.analysesSets)
  const analyzesFilter = useAppSelector((state) => state.catalog.analyzesFilter)

  const filterActive = analyzesFilter.body_system || analyzesFilter.categories || analyzesFilter.prescribed !== null

  const closeModal = () => {
    dispatch(setIsDrawer(false))
    dispatch(setFilterView('all'))
  }

  return (
    <div className={Styles.research}>
      <CatalogResearchFilter />
      {analyzes.results.length ? (
        <>
          <ul className={Styles.list}>
            {analysesSets.results.length > 0 &&
              !filterActive &&
              analysesSets.results.map((analyze) => <CatalogComplexItem key={analyze.id} analyze={analyze} />)}
            {analyzes.results.map((analyze) => {
              return <CatalogResearchItem key={analyze.id} analyze={analyze} />
            })}
          </ul>
          <CatalogPagination
            items={analyzes}
            currentQuantity={currentAnalyzesQuantity}
            itemsLeft={analyzesLeft}
            turnPage={turnAnalyzesPage}
          />
        </>
      ) : (
        'Товаров, соответствующих вашему выбору не обнаружено, попробуйте изменить запрос'
      )}
      {isDrawer && (
        <DrawerComponent open={isDrawer} closeModal={closeModal}>
          <CatalogResearchFilters />
        </DrawerComponent>
      )}
    </div>
  )
}
