import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '@app/redux/hooks'
import Styles from './style.module.scss'

import createIcon from '@icons/create.svg'
import viewIcon from '@icons/view.svg'

import { setPhaseFunc, setNotificationFunc, createConclusion } from '../../../../../../index'
import { formateDate } from '../../../../../../../../utils/formateDate'
import { GetPdfButton } from '@components/GetPdfButton'

interface DoctorPatientVisitProps {
  appointmentId: number
  consultationType: any
  dayReception: string
  isOnline: boolean
  medicalReport: any
  patient?: any
  status: string
  timeReception: string
}

export const DoctorPatientVisit: React.FC<DoctorPatientVisitProps> = (props) => {
  const { appointmentId, consultationType, dayReception, isOnline, medicalReport, status, timeReception } = props

  const dispatch = useAppDispatch()
  const [phase, setPhase] = useState(null)
  const [notification, setNotification] = useState('')
  const [expired, setExpired] = useState(null)

  const classNameNotification = expired
    ? `${Styles.notification} ${Styles.notification_expired}`
    : `${Styles.notification}`

  useEffect(() => {
    setPhaseFunc(dayReception, setPhase)
    setNotificationFunc(status, medicalReport, dayReception, setNotification, setExpired)
  }, [])

  return (
    <li className={Styles.visit}>
      <div className={Styles.block}>
        <span className={`${Styles.title} ${Styles.title_mod}`}>{`«${consultationType.direction.title}»`}</span>
        <span className={Styles.subtitle}>
          {consultationType.title}, {isOnline ? 'Онлайн' : 'Личный'}
        </span>
      </div>
      <div className={Styles.block}>
        {phase ? (
          <span className={Styles.title}>
            {phase}, {formateDate(dayReception, 'DD MMMM')}
          </span>
        ) : (
          <span className={Styles.title}>{formateDate(dayReception, 'DD MMMM')}</span>
        )}
        <span className={Styles.subtitle}>{timeReception}</span>
      </div>
      <div className={Styles.block}>
        {medicalReport ? (
          <>
            <Link className={Styles.conclusion} to={`/appointments/${appointmentId}`}>
              <img src={viewIcon} alt='Иконка' />
              <span className={Styles.text}>Смотреть заключение</span>
            </Link>
            {medicalReport.is_report_done && <GetPdfButton medicalReportId={medicalReport.id} />}
          </>
        ) : (
          <>
            {status === 'waiting_paying' && <span className={Styles.cancel}>Прием не оплачен</span>}
            {status === 'planned' && (
              <Link className={Styles.conclusion} to={`/appointments/${appointmentId}`}>
                <span className={Styles.text}>Смотреть детально</span>
              </Link>
            )}
            {(status === 'not_payed' || status === 'cancel') && <span className={Styles.cancel}>Прием отменен</span>}
            {status === ('done' || 'started') && (
              <button className={Styles.conclusion}>
                <img src={createIcon} alt='Иконка' />
                <span className={Styles.text} onClick={() => createConclusion(appointmentId, dispatch)}>
                  Создать заключение
                </span>
              </button>
            )}
          </>
        )}
        {notification && <span className={classNameNotification}>{notification}</span>}
      </div>
    </li>
  )
}
