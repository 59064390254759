import React, { useState } from 'react'
import Styles from './style.module.scss'
import timeIcon from '@icons/time.svg'
import moment from 'moment'
import useWindowSize from '@helpers/useWindowSizeHook'
import { ButtonWithBorder } from '@components/ui/ButtonWithBorder'
import viewIcon from '@icons/view-black.svg'
import { ConclusionMobile } from '@components/Conclusion/ConclusionMobile'

export const TotalInformation = (props) => {
  const dateFomat = moment(props.data.day_reception, 'YYYY-MM-DD').format('D MMMM')
  const { width } = useWindowSize()
  const desktop = width >= 829
  const [showDrawer, setShowDrawer] = useState(false)

  return (
    <>
      {props.data && (
        <div className={Styles.record}>
          <h2 className={Styles.title__card}>Запись на прием {dateFomat}</h2>
          <div className={`${Styles.header} ${!props.data.is_online && Styles.offline}`}>
            {/* props.data.status === 'planned' && (
              <div className={Styles.notification}>
                <img src={timeIcon} alt='Иконка' />
                <span className={Styles.across}>Через 10 минут</span>
              </div>
            ) */}
            {props.data.status === 'done' && props.data.medical_report === null && (
              <div className={Styles.notification}>
                <img src={timeIcon} alt='Иконка' />
                <span className={Styles.across}>Врач готовит заключение</span>
              </div>
            )}
            <div className={Styles.container}>
              <span className={Styles.time}>{props.data?.time_reception}</span>
              <span className={Styles.online}>{props.data?.is_online ? 'Онлайн прием' : 'Личный прием'}</span>
            </div>

            {/* props.data?.is_online && desktop && <button className={Styles.start}>Подключиться</button> */}
          </div>

          <div className={Styles.block}>
            <span className={Styles.title}>Консультация</span>
            <p className={Styles.description}>{props.data?.consultation_type?.direction?.title}</p>
          </div>
          <div className={Styles.block}>
            <span className={Styles.title}>Тип консультации</span>
            <div className={Styles.wrap}>
              <div className={Styles.description}>{props.data?.consultation_type?.title}</div>
            </div>
          </div>
          {!desktop &&
            props.data?.status === 'done' &&
            props.data.medical_report &&
            props?.data?.medical_report?.is_report_done && (
              <ButtonWithBorder
                value='Заключение'
                className={Styles.button}
                before
                src={viewIcon}
                onClick={() => setShowDrawer(true)}
              />
            )}
          {!desktop && props.dataMedicalReport && props?.data?.medical_report?.is_report_done && (
            <ConclusionMobile
              showDrawer={showDrawer}
              setShowDrawer={setShowDrawer}
              date={props.data.day_reception}
              data={props.dataMedicalReport}
            />
          )}
        </div>
      )}
    </>
  )
}
