import styles from './styles.module.scss'

export const getTagBackgroundClassname = (status: string) => {
  switch (status) {
    case 'Получен':
      return styles.received
    case 'Ожидает получения':
      return styles.waitingForReceiving
    case 'in_work':
      return styles.waitingForReceiving
    case 'Ожидает оплаты':
      return styles.waitingForPayment
    case 'not_paid':
      return styles.waitingForPayment
    case 'Отправлен':
      return styles.sent
    default:
      return styles.received
  }
}

export const getStatusValue = (status: string) => {
  switch (status) {
    case 'in_work':
      return 'Ожидает получения'
    case 'not_paid':
      return 'Ожидает оплаты'
    default:
      return status
  }
}
