import question from '@icons/report-question.svg'
import React from 'react'
import { Field } from 'react-final-form'
import Styles from './style.module.scss'

export const Hint = ({ content }) => {
  return (
    <div className={Styles.dropdown}>
      <img src={question} alt='Краткая запись' />
      <Field name={`${content}.short_name`}>{({ input }) => <span className={Styles.cloud}>{input.value}</span>}</Field>
    </div>
  )
}
