import React from 'react'
import { Field, useForm } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import Styles from './style.module.scss'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const NutraceuticalItem = ({ item, selected, index }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fields } = useFieldArray('nutraceutical')

  const form = useForm()
  const add = () => {
    form.change(`${item}.selected`, true)
  }
  const del = () => {
    form.change(`${item}.selected`, false)
  }
  return (
    <div className={selected ? `${Styles.listItem} ${Styles.listItem_selected}` : `${Styles.listItem}`}>
      <span>{item.name}</span>
      <Field name={`${item}.name`}>
        {({ input }) => (
          <div>
            <label>{input.value}</label>
          </div>
        )}
      </Field>
      {selected ? (
        <button type='button' className={Styles.deleteButton} onClick={del} />
      ) : (
        <button type='button' className={Styles.button} onClick={add} />
      )}
    </div>
  )
}
