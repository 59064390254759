/* eslint-disable camelcase */
import React from 'react'
import useWindowSize from '@helpers/useWindowSizeHook'
import { CatalogTogetherItem } from './components/CatalogTogetherItem'
import cartIcon from '@icons/cart-blue.svg'
import cartIconWhite from '@icons/cart.svg'
import cartBlackIcon from '@icons/cart-black.svg'
import Styles from './style.module.scss'
import declination from '@helpers/declination'
import { Loader } from '../../../../../../pages/doctor/components/UI/Loader'
import { useAppDispatch } from '@app/redux/hooks'
import { getMyCart, patchCartId, postCart } from '../../../../../../pages/cart/redux/cartSlicer'
import { getProductById } from '../../../../../../pages/catalog/redux/productSlice'
import { Link } from 'react-router-dom'
import { MOBILE_BREAKPOINT } from '../../../../../../global-constants'

export const CatalogTogether = ({ product }) => {
  const { width } = useWindowSize()
  const dispatch = useAppDispatch()
  const { id, in_set, price, discount_price, in_stock, in_cart } = product.set_info
  const productId = product.id

  const addProduct = (move) => {
    if (move === 'add') {
      dispatch(postCart({ product_set: id }))
        .then(() => dispatch(getProductById(productId)))
        .then(() => dispatch(getMyCart()))
      // .then((res) => {
      //   res.payload.variants.map((item) => {
      //     if (item.id === selectedVariant.id) {
      //       dispatch(setUpdateSelectedVariant(item))
      //       dispatch(getMyCart())
      //     }
      //     item.modifications.map((mod) => {
      //       if (mod.id === selectedModification.id) {
      //         dispatch(setUpdateSelectedModification(mod))
      //         dispatch(getMyCart())
      //       }
      //     })
      //   })
      // })
    } else {
      dispatch(
        patchCartId({
          product_set: id,
          quantity: move === 'plus' ? in_cart + 1 : in_cart - 1,
        }),
      ).then(() => dispatch(getProductById(productId)))
      // .then((res) => {
      //   res.payload.variants.map((item) => {
      //     if (item.id === selectedVariant.id) {
      //       dispatch(setUpdateSelectedVariant(item))
      //       dispatch(getMyCart())
      //     }
      //     item.modifications.map((mod) => {
      //       if (mod.id === selectedModification.id) {
      //         dispatch(setUpdateSelectedModification(mod))
      //         dispatch(getMyCart())
      //       }
      //     })
      //   })
      // })
      dispatch(getMyCart())
    }
  }

  return in_set ? (
    <div className={Styles.together}>
      <div className={Styles.top}>
        {in_set.map((item) => {
          return (
            <div key={item.id} className={Styles.wrapper}>
              <CatalogTogetherItem item={item} />
            </div>
          )
        })}
      </div>
      <div className={Styles.bottom}>
        <div className={Styles.prices}>
          <span className={Styles.discount}>– {price - discount_price} ₽</span>
          <div className={Styles.block}>
            <div className={Styles.wrap}>
              <span className={Styles.price}>{discount_price} ₽</span>
              <span className={Styles.old}>{price} ₽</span>
            </div>
          </div>
        </div>
        {/* <button className={Styles.cart}>
          {width > 830 ? <img src={cartIcon} alt='Иконка' /> : <img src={cartBlackIcon} alt='Иконка' />}
          {width > 830 ? (
            <span>Добавить {declination(in_set.length, 'товар', 'товара', 'товаров')} в корзину</span>
          ) : (
            <span>Добавить {declination(in_set.length, 'товар', 'товара', 'товаров')} </span>
          )}
        </button> */}
        <div className={Styles.buttons}>
          {in_cart > 0 ? (
            <>
              <div className={Styles.counter_wrap}>
                <div className={Styles.counter__container}>
                  <button
                    onClick={() => addProduct('minus')}
                    className={`${Styles.counter} ${Styles.counter_minus}`}
                    disabled={in_cart === 1}
                  />
                  <span>{in_cart < 10 ? `0${in_cart}` : in_cart}</span>
                  <button onClick={() => addProduct('plus')} className={`${Styles.counter} ${Styles.counter_plus}`} />
                </div>
                <Link to='/cart' className={Styles.link}>
                  <button className={Styles.green}>
                    {width > MOBILE_BREAKPOINT && <img src={cartIconWhite} alt='Иконка' />}
                    <span>В корзине {in_cart} шт</span>
                  </button>
                </Link>
              </div>
            </>
          ) : (
            // <button className={Styles.cart} onClick={() => addProduct('add')} disabled={!in_stock}>
            //   <img src={cartIcon} alt='Иконка' />
            //   <span>В корзину</span>
            // </button>
            <button className={Styles.cart} onClick={() => addProduct('add')} disabled={!in_stock}>
              {width > 830 ? <img src={cartIcon} alt='Иконка' /> : <img src={cartBlackIcon} alt='Иконка' />}
              {width > 830 ? (
                <span>Добавить {declination(in_set.length, 'товар', 'товара', 'товаров')} в корзину</span>
              ) : (
                <span>Добавить {declination(in_set.length, 'товар', 'товара', 'товаров')} </span>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  )
}
