import sctollToTop from '@helpers/scrollToTop'
import React, { useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import { ReturnButton } from '@components/ui/ReturnButton'
import { ArticleHeader } from './ArticleHeader'
import { useParams } from 'react-router'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getArticle } from '../../pages/blog/redux/blogSlice'
import { Spinner } from '@components/Spinner'
import Parser from 'html-react-parser'
import share from '@icons/share.svg'
import { TransitionsModal } from '@components/Modal'
import moment from 'moment'

export const ArticlePage = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const loggedIn = useAppSelector((state) => state.auth.user.isLoggedIn)
  const component = useRef<HTMLDivElement>(null)

  useEffect(() => {
    sctollToTop(component, 90)
  }, [])

  const [dataArticle, setDataArticle] = useState(null)
  const { slug } = useParams<{ slug: string }>()
  useEffect(() => {
    dispatch(getArticle(slug)).then((res) => {
      setDataArticle(res.payload.data)
      setTitleArticle(res.payload.data.title)
    })
  }, [])
  const pagePath = document.location.href

  const [isOpenModal, setModalOpened] = useState(false)
  const [titleArticle, setTitleArticle] = useState('')
  const dateConvert = (day) => {
    return moment(day, 'YYYY-MM-DD').format('D MMMM, YYYY ')
  }
  return (
    <div className={`${Styles.container} ${loggedIn && Styles.wrapper_loggedIn}`} ref={component}>
      <>
        {dataArticle ? (
          <>
            <ReturnButton buttonName='Назад в блог' path='/blog' />
            <ArticleHeader
              title={dataArticle.title}
              date={dataArticle.created_at}
              readingTime={dataArticle.est_read_time}
              tags={dataArticle.tags}
              mainPicture={dataArticle.header_image}
            />
            <div className={Styles.content__container}>
              <div className={Styles.share__container}>
                <button onClick={() => setModalOpened(true)}>
                  <img src={share} alt='share' className={Styles.share__img} />
                  <div>Поделиться</div>
                </button>
              </div>
              <div className={Styles.content}>
                <div> {Parser(dataArticle.body)}</div>
                <div className={Styles.footer}>
                  <div>
                    <p>{dateConvert(dataArticle.created_at)}</p>
                    {dataArticle.tags.map((item, idx) => (
                      <span key={idx}>{item}</span>
                    ))}
                  </div>

                  <div className={Styles.share__container}>
                    <button onClick={() => setModalOpened(true)}>
                      <img src={share} alt='share' className={Styles.share__img} />
                      <div>Поделиться</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Spinner />
        )}
        <TransitionsModal
          onClose
          isOpenModal={isOpenModal}
          setModalOpened={setModalOpened}
          className={Styles.modal__container}
        >
          <div className={Styles.share}>
            <div className={`${Styles.share__icon} ${Styles.share__tg}`}>
              <a
                href={`https://t.me/share/url?url=${pagePath}&text=${titleArticle}`}
                target='_blank'
                rel='noreferrer'
              />
            </div>
            <div className={`${Styles.share__icon} ${Styles.share__vk}`}>
              <a
                href={`https://vk.com/share.php?url=${pagePath}&text=${titleArticle}`}
                target='_blank'
                rel='noreferrer'
              />
            </div>
          </div>
        </TransitionsModal>
      </>
    </div>
  )
}
