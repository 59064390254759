import React, { useState } from 'react'
import Styles from './style.module.scss'
import { Link } from 'react-router-dom'
import { DeleteButton } from '../../ui/DeleteButton'
import { DeleteComponent } from '../DeleteComponent'
import { Counter } from '../../ui/Counter'

interface SetProductItemProps {
  item: any
  // changeCount: any
  // deletedItem: any
}

export const SetProductItem: React.FC<SetProductItemProps> = ({ item }) => {
  const [activeDeleteComponent, setActiveDeleteComponent] = useState(false)

  return (
    <div className={Styles.together}>
      <div className={Styles.top}>
        {item.products_in_set.map((set, i) => (
          <div key={i} className={Styles.wrapper}>
            <div className={Styles.item}>
              <Link className={Styles.link} to={`/catalog/nutraceutical/${set.mod_id}`}>
                <div className={Styles.container}>
                  <div className={Styles.image}>
                    <img src={set.variant_image} alt='img' />
                  </div>
                </div>
                <div className={Styles.content}>
                  <span className={Styles.discount}>– {set.discount_price} ₽</span>
                  <div className={Styles.block}>
                    <span className={Styles.price}>{set.price - set.discount_price} ₽</span>
                    <span className={Styles.old}>{set.price} ₽</span>
                  </div>
                  <p className={Styles.description}>{set.variant_title}</p>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className={Styles.bottom}>
        <div className={Styles.prices}>
          <span className={Styles.discount}>– {(item.price - item.discount_price) * item.quantity} ₽</span>
          <div className={Styles.block}>
            <div className={Styles.wrap}>
              <span className={Styles.price}>{item.discount_price * item.quantity} ₽</span>
              <span className={Styles.old}>{item.price * item.quantity} ₽</span>
            </div>
          </div>
        </div>

        {item.in_stock ? (
          <Counter arrNamePatch='product_set' item={item} />
        ) : (
          <div className={Styles.availability}>
            <span>Нет в наличии</span>
          </div>
        )}
        <DeleteButton onClick={() => setActiveDeleteComponent(true)} />
      </div>
      {activeDeleteComponent && (
        <DeleteComponent
          name={item.title}
          setActiveDeleteComponent={setActiveDeleteComponent}
          // deletedItem={deletedItem}
          id={item.id}
          arrNameDelete='product_set'
        />
      )}
    </div>
  )
}
