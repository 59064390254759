import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { InputCustom } from '@components/InputCustom'
import { SelectComponent } from '@components/SelectComponent'
import { ButtonTransparent } from '@components/ui/ButtonTransparent'
import {
  getNotificationsAppointment,
  patchNotificationsAppointment,
  postNotificationsAppointment,
} from '../../pages/authorization/redux/authSlice'
import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import bell from '@icons/bell-black.svg'
import bellRing from '@icons/bell-ring.svg'

interface ReminderFormComponentProps {
  appointmentId: number
}

export const ReminderFormComponent: React.FC<ReminderFormComponentProps> = ({ appointmentId }) => {
  const dispatch = useAppDispatch()
  const [valueSelect, setValueSelect] = useState('')
  const isValid = useAppSelector((state) => state.auth.isValidInput.email)

  const userData = useAppSelector((state) => state.auth.user.info)
  const { phone } = userData
  const [isNotification, setIsNotification] = useState(null)
  const [showSuccessContainer, setShowSuccessContainer] = useState(!!isNotification)
  const user = useAppSelector((state) => state.auth.user.info)
  const [email, setEmail] = useState(user.email)
  useEffect(() => {
    dispatch(getNotificationsAppointment(appointmentId)).then((res) => {
      setIsNotification(res.payload.data.id)
    })
  }, [showSuccessContainer])

  useEffect(() => {
    setShowSuccessContainer(!!isNotification)
  }, [isNotification])

  const handleChange = (e) => {
    setEmail(e.target.value)
  }
  const sendReminderEmail = (e) => {
    e.preventDefault()
    if (isValid || user.email) {
      isNotification
        ? dispatch(
            patchNotificationsAppointment(isNotification, {
              remind_way: 0,
            }),
          ).then((res) => {
            if (res.payload.status) {
              setValueSelect('По почте')
            }
          })
        : dispatch(
            postNotificationsAppointment({
              appointment: appointmentId,
              remind_way: 0,
              phone: user.phone,
              email,
            }),
          ).then((res) => {
            if (res.payload.status) {
              setValueSelect('По почте')
            }
          })
      setShowSuccessContainer(true)
    }
  }

  const sendReminderSMS = (e) => {
    e.preventDefault()

    isNotification
      ? dispatch(
          patchNotificationsAppointment(isNotification, {
            remind_way: 1,
          }),
        ).then((res) => {
          if (res.payload.status) {
            setValueSelect('В СМС')
          }
        })
      : dispatch(
          postNotificationsAppointment({
            appointment: appointmentId,
            remind_way: 1,
            phone: user.phone,
          }),
        ).then((res) => {
          if (res.payload.status) {
            setValueSelect('В СМС')
          }
        })
    setShowSuccessContainer(true)
  }

  return (
    <div className={`${Styles.wrapper} ${Styles.wrapper_bg}`}>
      <img src={showSuccessContainer ? bellRing : bell} alt='bell' />
      <div className={Styles.description}>
        <h6 className={Styles.subtitle}>Напомнить о приеме?</h6>
        {!showSuccessContainer && <p className={Styles.description__text}>Напомним за день до даты приема</p>}
        {showSuccessContainer && (
          <p className={Styles.description__text}>
            Пришлем напоминание за день до приема на{' '}
            <span className={Styles.text_bold}>{valueSelect === 'По почте' ? email : phone}</span>
            <ButtonTransparent
              value='Изменить'
              styleButton={Styles.buttot_transparent}
              onClick={() => setShowSuccessContainer(false)}
            />
          </p>
        )}
        {!showSuccessContainer && (
          <div className={Styles.select__wrapper}>
            <SelectComponent
              setValueSelect={setValueSelect}
              value1='Выбрать способ'
              value2='В СМС'
              value3='По почте'
              // value4='В личном кабинете'
            />
            {valueSelect === 'По почте' && (
              <>
                <form className={Styles.input__container} noValidate>
                  <InputCustom
                    inputType='email'
                    inputName='email'
                    placeholder='Введите ваш email'
                    onChange={handleChange}
                    inputValue={email}
                  />
                </form>
                <ButtonTransparent
                  value='Напомнить'
                  onClick={sendReminderEmail}
                  styleButton={Styles.buttot_transparent}
                />
              </>
            )}
            {valueSelect === 'В СМС' && (
              <ButtonTransparent value='Напомнить' onClick={sendReminderSMS} styleButton={Styles.buttot_transparent} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
