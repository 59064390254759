import React from 'react'
import { NavLink } from 'react-router-dom'
import Styles from './style.module.scss'

interface MobileMenuItemProps {
  icon: string
  text: string
  url: string
}

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({ icon, text, url }) => {
  return (
    <NavLink to={url} exact className={Styles.item} activeClassName={Styles.linkActive}>
      <img className={Styles.icon} src={icon} alt='иконка' />
      <p className={Styles.text}>{text}</p>
    </NavLink>
  )
}
