import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '@app/redux/hooks'
import Styles from './style.module.scss'

import createIcon from '@icons/create.svg'
import viewIcon from '@icons/view.svg'

import { createConclusion, setPhaseFunc, setNotificationFunc } from '../../../../../../index'
import { formateDate } from '../../../../../../../../utils/formateDate'
import { GetPdfButton } from '@components/GetPdfButton'

interface DoctorPatientPersonaMobileProps {
  patientId: number
  avatar: any
  birthday: string
  firstName: string
  lastName: string
  phone: string
  lastAppointment: any
}

export const DoctorPatientPersonaMobile: React.FC<DoctorPatientPersonaMobileProps> = (props) => {
  const { patientId, avatar, birthday, firstName, lastName, phone, lastAppointment } = props

  const dispatch = useAppDispatch()
  const [phase, setPhase] = useState(null)
  const [notification, setNotification] = useState('')
  const [expired, setExpired] = useState(null)
  const [yearsPatient, setYearsPatient] = useState(null)

  const classNameNotification = expired
    ? `${Styles.notification} ${Styles.notification_expired}`
    : `${Styles.notification}`

  useEffect(() => {
    if (birthday) {
      setYearsPatient(new Date().getFullYear() - new Date(birthday).getFullYear())
    }

    setPhaseFunc(lastAppointment.day_reception, setPhase)
    setNotificationFunc(
      lastAppointment.status,
      lastAppointment.medical_report,
      lastAppointment.day_reception,
      setNotification,
      setExpired,
    )
  }, [])

  return (
    <li className={Styles.visit}>
      <div className={Styles.patient}>
        <div className={Styles.info}>
          <div className={Styles.avatar}>
            {/* <span className={Styles.count}>1</span> */}
            {avatar ? (
              <img className={Styles.image} src={avatar} alt='Аватар' />
            ) : (
              <div className={Styles.cap}>
                <span>
                  {lastName?.substring(0, 1)}
                  {firstName?.substring(0, 1)}
                </span>
              </div>
            )}
          </div>
          <div className={Styles.name}>
            <Link className={Styles.secondname} to={`/patients/${patientId}`}>
              {lastName}
            </Link>
            <span className={Styles.firstname}>
              {firstName}
              {birthday && `, ${yearsPatient} лет`}
            </span>
          </div>
        </div>
        <Link className={Styles.link} to={`/appointments/${lastAppointment.id}`}>
          {}
        </Link>
      </div>
      <div className={Styles.wrapper}>
        <div className={Styles.wrap}>
          <div className={Styles.block}>
            <span className={Styles.label}>Дата рождения</span>
            {birthday ? (
              <span className={Styles.title}>{formateDate(birthday, 'DD.MM.YYYY')}</span>
            ) : (
              <span className={Styles.title}>Дата рождения не указана</span>
            )}
          </div>
          <div className={Styles.block}>
            <span className={Styles.label}>Номер телефона</span>
            <span className={Styles.title}>{phone}</span>
          </div>
        </div>
        <div className={Styles.block}>
          <span className={Styles.label}>Последнее обращение</span>
          {Object.keys(lastAppointment).length !== 0 && (
            <>
              {phase ? (
                <span className={Styles.title}>
                  {phase}, {formateDate(lastAppointment.day_reception, 'DD MMMM')}
                </span>
              ) : (
                <span className={Styles.title}>{formateDate(lastAppointment.day_reception, 'DD MMMM')}</span>
              )}
            </>
          )}
        </div>
        <span className={Styles.designation}>{lastAppointment.consultation_type.direction.title}</span>

        {Object.keys(lastAppointment).length !== 0 && lastAppointment.medical_report ? (
          <>
            <Link className={Styles.conclusion} to={`/appointments/${lastAppointment.id}`}>
              <img src={viewIcon} alt='Иконка' />
              <span className={Styles.text}>Смотреть заключение</span>
            </Link>
            {lastAppointment.medical_report.is_report_done && (
              <GetPdfButton medicalReportId={lastAppointment.medical_report.id} />
            )}
          </>
        ) : (
          <>
            {lastAppointment.status === 'done' || lastAppointment.medical_report === null ? (
              <button className={Styles.conclusion}>
                <img src={createIcon} alt='Иконка' />
                <span className={Styles.text} onClick={() => createConclusion(lastAppointment.id, dispatch)}>
                  Создать заключениe
                </span>
              </button>
            ) : (
              <> {lastAppointment.status === 'cancel' && <span className={Styles.cancel}>Прием отменен</span>}</>
            )}
          </>
        )}

        {/* {Object.keys(lastAppointment).length !== 0 && lastAppointment.status !== 'done' && (
          <button className={Styles.conclusion}>
            <img src={bellIcon} alt='Иконка' />
            <span className={Styles.text}>Напомнить о приеме</span>
          </button>
        )} */}
      </div>
      {notification && <span className={classNameNotification}>{notification}</span>}
    </li>
  )
}
