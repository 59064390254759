import React from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'

export const SearchPatient = ({ item, resetValue }) => {
  return (
    <Link className={Styles.link} to={`/patients/${item.id}`} onClick={resetValue}>
      <li className={Styles.item}>
        {item.avatar ? (
          <div className={Styles.avatar}>
            <img src={item.avatar} alt='Фото' />
          </div>
        ) : (
          <div className={Styles.cap}>
            <span>
              {item.last_name?.substring(0, 1)}
              {item.first_name?.substring(0, 1)}
            </span>
          </div>
        )}
        <div className={Styles.column}>
          <span className={Styles.name}>{item.last_name}</span>
          <span className={Styles.initials}>{item.first_name}</span>
        </div>
      </li>
    </Link>
  )
}
