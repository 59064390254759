import React from 'react'
import { CatalogPDF } from '../CatalogPDF'
import calendarIcon from '@icons/calendar-black.svg'
import Styles from './style.module.scss'
import { CatalogPrice } from '../../../../components/CatalogPrice'
import declination from '@helpers/declination'
import { CatalogCartButton } from '../../../../../../pages/catalog/components/CatalogCartButton'

export const CatalogCard = ({ analyze }) => {
  return (
    <div className={Styles.card}>
      <div className={Styles.content}>
        <h3 className={Styles.title}>{analyze.title}</h3>
        <div className={Styles.info}>
          <CatalogPrice discount_price={analyze.discount_price} price={analyze.price} />
          {/* <span className={Styles.price}>{analyze.price} ₽</span> */}
          {analyze.price_details.map((detail) => {
            return (
              <span key={detail.index + detail.title} className={Styles.item}>
                {detail.title}
                <strong>{detail.amount} ₽</strong>
              </span>
            )
          })}
          {/* <span className={Styles.item}>
            Анализ:
            <strong>1 960 ₽</strong>
          </span>
          <span className={Styles.item}>
            Взятие крови из вены:
            <strong>220 ₽</strong>
          </span> */}
        </div>
        <CatalogCartButton product={analyze} type='analyzes' />
        <div className={Styles.wrapper}>
          <div className={Styles.block}>
            <div className={Styles.image}>
              <img src={calendarIcon} alt='Иконка' />
            </div>
          </div>
          <div className={Styles.block}>
            <span className={Styles.term}>Срок исполнения</span>
            <span className={Styles.description}>
              До {declination(analyze.execution_time, 'дня', 'дней', 'дней')},&nbsp;
              <span>без учета дня взятия биоматериала</span>
            </span>
          </div>
        </div>
      </div>
      {analyze.example_file && <CatalogPDF file={analyze.example_file} />}
    </div>
  )
}
