import React from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '../../../../helpers/useWindowSizeHook'
import { setModal } from '../../redux/doctorSlicer'
import Styles from './style.module.scss'

import { DoctorModal } from '../DoctorModal'
import { DoctorRecording } from '../DoctorRecording'
import { DoctorRecordingMobile } from '../DoctorRecording/components/DoctorRecordingMobile'
import { DoctorPatientHistory } from '../DoctorNotation/components/DoctorPatientHistory'
import { DoctorPatientHistoryMobile } from '../DoctorNotation/components/DoctorPatientHistory/components/DoctorPatientHistoryMobile'
import { DoctorPatientLastRecord } from './components/DoctorPatientLastRecord'
import { DoctorPatientData } from './components/DoctorPatientData'

import { MOBILE_BREAKPOINT } from '../../constants'

export const DoctorPatient: React.FC = () => {
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  const displayModal = useAppSelector((state) => state.doctor.displayModal)
  const currentPatient = useAppSelector((state) => state.doctor.currentPatient)

  const openModal = () => dispatch(setModal(true))

  if (Object.keys(currentPatient).length === 0) {
    return null
  }

  return (
    <>
      {displayModal && (
        <DoctorModal>
          {width > MOBILE_BREAKPOINT ? <DoctorRecording /> : <DoctorRecordingMobile title='Запись на прием' />}
        </DoctorModal>
      )}
      {width > MOBILE_BREAKPOINT ? (
        <div className={Styles.patient}>
          <div className={Styles.block}>
            <div className={Styles.info}>
              <div className={Styles.wrapper}>
                <div className={Styles.wrap}>
                  {currentPatient.avatar ? (
                    <div className={Styles.avatar}>
                      <img src={currentPatient.avatar} alt='Фото' />
                    </div>
                  ) : (
                    <div className={Styles.cap}>
                      <span>
                        {currentPatient?.last_name?.substring(0, 1)}
                        {currentPatient?.first_name?.substring(0, 1)}
                      </span>
                    </div>
                  )}
                  <div className={Styles.name}>
                    <span className={Styles.secondname}>{currentPatient.last_name}</span>
                    <span className={Styles.firstname}>{currentPatient.first_name}</span>
                  </div>
                </div>
                <div className={Styles.connection}>
                  <button className={Styles.reception} onClick={openModal}>
                    Записать на прием
                  </button>
                  {/* <button className={Styles.connect}>
                    <img src={cloudIcon} alt='Иконка' />
                    <span className={Styles.write}>Написать</span>
                    <span className={Styles.count}>1</span>
                  </button> */}
                </div>
              </div>
            </div>
            <DoctorPatientHistory />
          </div>
          <div className={Styles.block}>
            <DoctorPatientLastRecord />
            <DoctorPatientData />
          </div>
        </div>
      ) : (
        <div className={Styles.patient}>
          <div className={Styles.block}>
            <div className={Styles.info}>
              <div className={Styles.wrapper}>
                <div className={Styles.wrap}>
                  {currentPatient.avatar ? (
                    <div className={Styles.avatar}>
                      <img className={Styles.photo} src={currentPatient.avatar} alt='Фото' />
                    </div>
                  ) : (
                    <div className={Styles.cap}>
                      <span>
                        {currentPatient?.last_name?.substring(0, 1)}
                        {currentPatient?.first_name?.substring(0, 1)}
                      </span>
                    </div>
                  )}
                  <div className={Styles.name}>
                    <span className={Styles.secondname}>{currentPatient.last_name}</span>
                    <span className={Styles.firstname}>{currentPatient.first_name}</span>
                  </div>
                </div>
              </div>
              <button className={Styles.reception} onClick={openModal}>
                Записать на прием
              </button>
            </div>
            <DoctorPatientData />
          </div>
          <div className={Styles.block}>
            <DoctorPatientLastRecord />
            <DoctorPatientHistoryMobile />
          </div>
        </div>
      )}
    </>
  )
}
