import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'

import { setPhaseFunc, setNotificationFunc } from '../../../../../../index'
import { formateDate } from '../../../../../../../../utils/formateDate'

interface DoctorVisitMobileProps {
  appointment: any
}

export const DoctorVisitMobile: React.FC<DoctorVisitMobileProps> = ({ appointment }) => {
  const [phase, setPhase] = useState(null)
  const [notification, setNotification] = useState('')
  const [expired, setExpired] = useState(null)

  const classNameNotification = expired
    ? `${Styles.notification} ${Styles.notification_expired}`
    : `${Styles.notification}`

  useEffect(() => {
    setPhaseFunc(appointment.day_reception, setPhase)
    setNotificationFunc(
      appointment.status,
      appointment.medical_report,
      appointment.day_reception,
      setNotification,
      setExpired,
    )
  }, [])

  return (
    <div className={Styles.visit}>
      <div className={Styles.wrapper}>
        <div className={Styles.wrap}>
          {appointment.patient.avatar ? (
            <img className={Styles.avatar} src={appointment.patient.avatar} alt='Аватар' />
          ) : (
            <div className={Styles.cap}>
              <span>
                {appointment.patient.last_name?.substring(0, 1)}
                {appointment.patient.first_name?.substring(0, 1)}
              </span>
            </div>
          )}
          <div className={Styles.name}>
            <Link className={Styles.secondname} to={`/patients/${appointment.patient.id}`}>
              {appointment.patient.last_name}
            </Link>
            <span className={Styles.firstname}>{appointment.patient.first_name}</span>
          </div>
          {appointment.status === 'cancel' && <span className={Styles.cancel}>Прием отменен</span>}
        </div>
        <Link className={Styles.link} to={`/appointments/${appointment.id}`}>
          {}
        </Link>
      </div>
      <div className={Styles.block}>
        <span className={Styles.label}>Консультация</span>
        <span className={Styles.text}>{appointment.consultation_type.direction.title}</span>
      </div>
      <div className={Styles.block}>
        <span className={Styles.label}>Дата и время</span>
        {phase ? (
          <span className={Styles.text}>
            {phase}, {formateDate(appointment.day_reception, 'DD MMMM')}
          </span>
        ) : (
          <span className={Styles.text}>{formateDate(appointment.day_reception, 'DD MMMM')}</span>
        )}
      </div>
      {appointment.status === 'done' && !appointment.medical_report && (
        <div className={Styles.conclusion}>
          <i className={Styles.icon}>{}</i>
          <span className={Styles.warning}>Требуется заключение</span>
        </div>
      )}
      {notification && <span className={classNameNotification}>{notification}</span>}
    </div>
  )
}
