import React from 'react'
import { Link } from 'react-router-dom'
import { Total } from '../components/Total'
import Styles from './style.module.scss'
import note from '@icons/note-black.svg'
import { formateDate } from '../../../utils/formateDate'

export const CreatedOrder = ({ createdOrder }) => {
  return (
    <>
      <h1 className={Styles.title}>Спасибо за заказ</h1>
      <div className={Styles.container}>
        <div className={Styles.content}>
          <header>
            <h2>Заказ № {createdOrder?.id} создан</h2>
            <p>
              Следите за статусом в разделе{' '}
              <Link to='/orders' className={Styles.button__link}>
                Мои заказы
              </Link>
            </p>
          </header>
          {createdOrder?.analyzes_qty > 0 && (
            <div className={Styles.notify}>
              <img alt='note' src={note} />
              <div className={Styles.notify__desc}>
                <h4>Лабораторные обследования</h4>
                <p>
                  Мы уже отправили номер заказа для лабораторного обследования на <span>{createdOrder?.email}</span>
                </p>
              </div>
            </div>
          )}
          {createdOrder?.delivery?.to_address && <h3>Доставка курьером</h3>}
          {createdOrder?.delivery?.to_pvz && <h3>Доставка товаров в пункт выдачи</h3>}
          {/* <h3>Доставка почтой</h3> */}
          {createdOrder?.products_qty > 0 && (
            <>
              <label className={Styles.label}>Имя</label>
              <p className={Styles.text}>{createdOrder?.full_name}</p>
              <label className={Styles.label}>Адрес</label>
              <p className={Styles.text}>
                {createdOrder?.delivery?.to_pvz
                  ? createdOrder?.delivery?.pvz_address
                  : createdOrder?.delivery?.to_address}
              </p>
              <label className={Styles.label}>Дата доставки</label>
              <p className={Styles.text}>{`${formateDate(
                createdOrder?.delivery?.delivery_dates?.from,
                'DD MMMM',
              )} - ${formateDate(createdOrder?.delivery?.delivery_dates?.to, 'DD MMMM')}`}</p>
              <label className={Styles.label}>Стоимость</label>
              <p className={Styles.text}>{createdOrder?.delivery_sum} ₽</p>
            </>
          )}
        </div>
        <Total order done createdOrder={createdOrder} />
      </div>
    </>
  )
}
