import React from 'react'
import Styles from './style.module.scss'
import { PastAppointment } from './components/PastAppointment'
import { useAppSelector } from '@app/redux/hooks'
import { AppointmentHistoryMobile } from './components/AppointmentHistoryMobile'

export const AppointmentHistory = (props) => {
  const statusDone = useAppSelector((state) => state.myAppointment.statusDone)

  return (
    <>
      <div className={Styles.table}>
        <div className={Styles.header}>
          <div className={Styles.wrap}>
            <span className={Styles.head}>Консультация</span>
          </div>
          <div className={Styles.wrap}>
            <span className={Styles.head}>Дата</span>
          </div>
          <div className={Styles.wrap}>
            <span className={`${Styles.head} ${Styles.head_mod}`}>Мед. заключение</span>
          </div>
        </div>
        {statusDone === 'success' && (
          <ul className={Styles.content}>
            {props?.dataDone?.map((item) => (
              <PastAppointment
                key={item.id + 1000}
                id={item.id}
                title={item?.consultation_type?.direction?.title}
                subtitle={`${item.consultation_type.title}, ${item.is_online ? 'Онлайн' : 'Лично'}`}
                date={item.day_reception}
                conclusionIsReady={item.medical_report}
                time={item.time_reception}
                status={item.status}
                detail={item.detail}
                absence={item.absence}
                notPaid={!item.is_pay}
              />
            ))}
          </ul>
        )}
        {/* paginationDone && props.dataDone.length > 20 && (
          <div className={Styles.button__container}>
            <ButtonColor value='Показать еще' onClick={showMore} />
          </div>
        ) */}
      </div>
      <div className={`${Styles.table} ${Styles.table_mobile}`}>
        <AppointmentHistoryMobile dataDone={props.dataDone} statusDone={statusDone} />
      </div>
    </>
  )
}
