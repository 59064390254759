import React from 'react'
import Styles from './style.module.scss'

import caretIcon from '@icons/caret.svg'

import { formateDate } from '../../../../../../utils/formateDate'

interface CalendarMobileProps {
  currentMonthDate: any
  currentMonth: any
  changeMonth: any
  selectedDate: any
  onClickDay: any
}

export const CalendarMobile: React.FC<CalendarMobileProps> = (props) => {
  const { currentMonthDate, currentMonth, changeMonth, selectedDate, onClickDay } = props

  const directionChange = (direction) => {
    if (direction === 'backward') {
      const date = new Date(currentMonthDate)
      const prevDate = formateDate(date.setMonth(date.getMonth() - 1), 'YYYY-MM-DD')
      changeMonth(prevDate)
    }

    if (direction === 'forward') {
      const date = new Date(currentMonthDate)
      const nextDate = formateDate(date.setMonth(date.getMonth() + 1), 'YYYY-MM-DD')
      changeMonth(nextDate)
    }
  }

  return (
    <div className={Styles.cancel}>
      <div className={Styles.calendar}>
        <div className={Styles.head}>
          <div className={Styles.shell}>
            <span className={Styles.daytime}>Пн</span>
            <span className={Styles.daytime}>Вт</span>
            <span className={Styles.daytime}>Ср</span>
            <span className={Styles.daytime}>Чт</span>
            <span className={Styles.daytime}>Пт</span>
            <span className={Styles.daytime}>Сб</span>
            <span className={Styles.daytime}>Вс</span>
          </div>
        </div>
        <div className={Styles.wrapper}>
          <div className={Styles.select}>
            <img
              className={`${Styles.caret} ${Styles.caret_rotate}`}
              src={caretIcon}
              alt='Иконка'
              onClick={() => directionChange('backward')}
            />
            <span className={Styles.month}>{formateDate(currentMonth[15]?.day, 'MMMM YYYY')}</span>
            <img className={Styles.caret} src={caretIcon} alt='Иконка' onClick={() => directionChange('forward')} />
          </div>
        </div>
        <div className={Styles.days}>
          {currentMonth?.map((date) => {
            const inPeriod = formateDate(date.day, 'YYYY-MM') === formateDate(currentMonth[15].day, 'YYYY-MM')

            return (
              <>
                {!date.is_available || inPeriod === false ? (
                  <div key={date.day} className={`${Styles.day} ${Styles.day_disabled}`}>
                    <span className={Styles.inner}>{formateDate(date.day, 'DD')}</span>
                  </div>
                ) : (
                  <div
                    key={date.day}
                    className={
                      selectedDate?.day === date.day ? `${Styles.day} ${Styles.day_selected}` : `${Styles.day}`
                    }
                  >
                    <span className={Styles.inner} onClick={() => onClickDay(date)}>
                      {formateDate(date.day, 'DD')}
                    </span>
                  </div>
                )}
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}
