import { useAppDispatch } from '@app/redux/hooks'
import { patchCartId } from '../../../../pages/cart/redux/cartSlicer'
import React from 'react'
import Styles from './style.module.scss'

interface CounterProps {
  item: any
  arrNamePatch: string
}

export const Counter: React.FC<CounterProps> = ({ arrNamePatch, item }) => {
  const dispatch = useAppDispatch()
  const { quantity } = item
  const changeCount = (value, arrNamePatch) => {
    const obj = {}
    obj[arrNamePatch] = item.id
    dispatch(patchCartId({ ...obj, quantity: value })) // .then((res) => {
    // setCartData(res.payload)
    // dispatch(getMyCart()).then((res) => {
    //   updateTotal(res)
    //  })
    // })
  }
  return (
    <div className={Styles.counter__container}>
      <button
        onClick={() => changeCount(quantity - 1, arrNamePatch)}
        className={`${Styles.counter} ${Styles.counter_minus}`}
        disabled={quantity === 1}
      />
      <span>{quantity < 10 ? `0${quantity}` : quantity}</span>
      <button
        onClick={() => changeCount(quantity + 1, arrNamePatch)}
        className={`${Styles.counter} ${Styles.counter_plus}`}
      />
    </div>
  )
}
