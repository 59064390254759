import React from 'react'
import { useAppDispatch } from '@app/redux/hooks'
import { setIsPopup, setPopupContent } from '../../../../../../redux/catalogSlicer'
import arrowIcon from '@icons/arrow-right-big.svg'
import Styles from './style.module.scss'
import { Loader } from '../../../../../../../../pages/doctor/components/UI/Loader'

export const CatalogProductSelect = ({ selectedVariant, selectedModification }) => {
  const dispatch = useAppDispatch()

  const clickTaste = () => {
    dispatch(setIsPopup(true))
    dispatch(setPopupContent('taste'))
  }

  const clickCount = () => {
    dispatch(setIsPopup(true))
    dispatch(setPopupContent('count'))
  }

  return selectedVariant && selectedModification ? (
    <div className={Styles.select}>
      <div className={Styles.block} onClick={clickTaste}>
        <div className={Styles.info}>
          <strong>{selectedVariant.name}</strong>
          <span>{selectedVariant.value}</span>
        </div>
        <div className={Styles.column}>
          <div className={Styles.image}>
            <img src={selectedVariant.images[0].image} alt='Изображение' />
          </div>
          <i className={Styles.icon}>
            <img src={arrowIcon} alt='Изображение' />
          </i>
        </div>
      </div>
      <div className={Styles.block} onClick={clickCount}>
        <div className={Styles.info}>
          <strong>{selectedModification.name}</strong>
          <span>{selectedModification.value}</span>
        </div>
        <div className={Styles.column}>
          <span className={Styles.price}>{selectedModification.discount_price} ₽</span>
          <i className={Styles.icon}>
            <img src={arrowIcon} alt='Изображение' />
          </i>
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  )
}
