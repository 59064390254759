import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import useWindowSize from '../../../../../../../../helpers/useWindowSizeHook'
import { setIsDrawer, setFilterView, setFilterProps } from '../../../../../../redux/catalogSlicer'
import Styles from './style.module.scss'

// import { FilterWrapper } from '../../../../../CatalogFilter/components/FilterWrapper'
import { FilterCheckbox } from '../../../../../CatalogFilter/components/FilterCheckbox'

import { MOBILE_BREAKPOINT } from '../../../../../../../../global-constants'
import { FilterPrice } from '../FilterAll/components/FilterPrice'

interface FilterModalProps {
  title: string
  filterView: string
}

export const FilterModal: React.FC<FilterModalProps> = ({ title, filterView }) => {
  const dispatch = useAppDispatch()
  const filterList = useAppSelector((state) => state.catalog.filterList)
  const { width } = useWindowSize()
  const productFilter = useAppSelector((state) => state.catalog.productFilter)
  const [filter, setFilter] = useState({ ...productFilter })
  // const displaySelected = true

  const closeModal = () => {
    dispatch(setIsDrawer(false))
    dispatch(setFilterView('all'))
  }

  const changeFilter = (view) => dispatch(setFilterView(view))

  const setExactFilter = () => {
    dispatch(setFilterProps(filter))
    closeModal()
  }
  const clearExactFilter = () => {
    if (filterView === 'price') {
      dispatch(setFilterProps({ max_price: '', min_price: '' }))
      setFilter({ max_price: '', min_price: '' })
    } else {
      dispatch(setFilterProps({ [filterView]: '' }))
      setFilter({ [filterView]: '' })
    }
  }

  return (
    <>
      <div className={Styles.filter}>
        {MOBILE_BREAKPOINT < width ? (
          <div className={Styles.header}>
            <button className={Styles.back} onClick={() => changeFilter('all')}>
              {}
            </button>
            <span className={Styles.title}>{title}</span>
          </div>
        ) : (
          <div className={`${Styles.header} ${Styles.header_mobile}`}>
            <button className={Styles.back} onClick={() => changeFilter('all')}>
              {}
            </button>
            <span className={Styles.title}>{title}</span>
            <button className={Styles.close} onClick={closeModal}>
              {}
            </button>
          </div>
        )}
        <div className={Styles.body}>
          {filterView === 'price' && <FilterPrice setFilter={setFilter} filter={filter} />}
          {filterView === 'brand' && (
            <>
              {/* <div className={Styles.search}>
            <input onChange={() => {}} type='text' value='' placeholder='Поиск бренда' />
            <button>{}</button>
          </div> */}
              {/* {displaySelected && (
                <div className={Styles.selected}>
                  <FilterCheckbox title='Gerber' count={32} />
                  <FilterCheckbox title='Natrol' count={32} />
                  <FilterCheckbox title='California Gold Nutrition' count={32} />
                </div>
              )} */}
              <div className={Styles.container}>
                {filterList.brands &&
                  filterList.brands.map((brand) => {
                    return (
                      <FilterCheckbox
                        key={brand.index}
                        title={brand.name}
                        count={brand.products}
                        setFilter={setFilter}
                        filter={filter}
                        filterKey='brand'
                      />
                    )
                  })}
                {/* <FilterWrapper letter='A'>
                  <FilterCheckbox title='Gerber' count={32} />
                </FilterWrapper>
                <FilterWrapper letter='B'>
                  <FilterCheckbox title='Now Foods' count={32} />
                  <FilterCheckbox title='Natrol' count={32} />
                </FilterWrapper>
                <FilterWrapper letter='C'>
                  <FilterCheckbox title='California Gold Nutrition' count={32} />
                  <FilterCheckbox title='Gerber' count={32} />
                  <FilterCheckbox title='Now Foods' count={32} />
                </FilterWrapper> */}
              </div>
            </>
          )}
          {filterView === 'category' && (
            <>
              <div className={Styles.container}>
                {filterList.categories &&
                  filterList.categories.map((category) => {
                    return (
                      <FilterCheckbox
                        key={category.index}
                        title={category.name}
                        count={category.products}
                        setFilter={setFilter}
                        filter={filter}
                        filterKey='category'
                      />
                    )
                  })}
              </div>
            </>
          )}
          {filterView === 'form' && (
            <>
              <div className={Styles.container}>
                {filterList.formtypes &&
                  filterList.formtypes.map((form) => {
                    return (
                      <FilterCheckbox
                        key={form.index}
                        title={form.name}
                        count={form.products}
                        setFilter={setFilter}
                        filter={filter}
                        filterKey='form'
                      />
                    )
                  })}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={Styles.confirm}>
        <button className={Styles.clear} onClick={clearExactFilter}>
          Очистить {title.toLowerCase()}
        </button>
        {MOBILE_BREAKPOINT < width ? (
          <button className={Styles.view} onClick={setExactFilter}>
            Показать
          </button>
        ) : (
          <button className={Styles.view} onClick={setExactFilter}>
            Показать
          </button>
        )}
      </div>
    </>
  )
}
