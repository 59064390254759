import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import {
  getAppointmentListDone,
  getAppointmentListPlannedMore,
  getAllAppointments,
} from '../../redux/appointmentSlicer'
import React, { useEffect, useState } from 'react'
import { AppointmentCard } from './components/AppointmentCard'
import Styles from './style.module.scss'
import { AppointmentHistory } from './components/AppointmentHistory'
import { ButtonColor } from '@components/ui/ButtonColor'
// import { ButtonPagination } from './components/ButtonPagination'
import moment from 'moment'
import { Spinner } from '@components/Spinner'
import { ButtonPagination } from './components/ButtonPagination'

export const AppointmentsList = () => {
  const dispatch = useAppDispatch()
  const [today, setToday] = useState(moment())
  const [dataPlanned, setDataPlanned] = useState(null)
  const [dataDone, setDataDone] = useState([])

  const notifications = useAppSelector((state) => state.auth.notifications)
  useEffect(() => {
    dispatch(
      getAppointmentListDone({
        after: today.startOf('month').format('YYYY-MM-DD'),
        before: today.endOf('month').format('YYYY-MM-DD'),
      }),
    )
      .then((res) => {
        setDataDone(
          res.payload.results?.filter(
            (item) => item.status === 'done' || item.status === 'cancel' || item.status === 'not_payed',
          ),
        )
      })
      .catch((error) => {
        throw new Error(error)
      })
  }, [today, notifications])

  useEffect(() => {
    dispatch(getAllAppointments())
      .then((res) => {
        setDataPlanned(
          res.payload.results?.filter(
            (item) =>
              item.status === 'planned' ||
              item.status === 'waiting_paying' ||
              item.status === 'delay' ||
              item.status === 'started',
          ),
        )
      })
      .catch((error) => {
        throw new Error(error)
      })
  }, [notifications])
  const page = useAppSelector((state) => state.myAppointment.page)
  const showMore = () => {
    dispatch(getAppointmentListPlannedMore(page))
  }
  const paginationPlanned = useAppSelector((state) => state.myAppointment.paginationPlanned)

  return (
    <div className={Styles.wrapper}>
      {dataPlanned ? (
        <>
          <h1 className={Styles.title}>Мои приемы</h1>
          {dataPlanned.length > 0 ? (
            <div className={Styles.container}>
              {dataPlanned?.map((item) => (
                <AppointmentCard key={item.id} item={item} />
              ))}
            </div>
          ) : (
            <p className={Styles.text}>Нет запланированных записей</p>
          )}
          {paginationPlanned && (
            <div className={Styles.button__container}>
              <ButtonColor value='Показать еще' onClick={showMore} />
            </div>
          )}
        </>
      ) : (
        <Spinner />
      )}
      {dataDone ? (
        <div className={Styles.doneAppoinment}>
          <h3>Прошедшие записи</h3>
          <ButtonPagination setToday={setToday} today={today} />

          {dataDone.length > 0 ? (
            <>
              <AppointmentHistory setToday={setToday} today={today} dataDone={dataDone} />
            </>
          ) : (
            <p className={Styles.text}>Нет прошедших записей</p>
          )}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}
