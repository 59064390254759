import React, { useState } from 'react'
import Styles from './style.module.scss'
import { Rectangle } from './ui/Rectangle'
import { DoctorInfo } from './ui/DoctorInfo'
import { HowItWorks1 } from './ui/HowItWorks1'
import { HowItWorks2 } from './ui/HowItWorks2'
import { HowItWorks3 } from './ui/HowItWorks3'
import { HowItWorks4 } from './ui/HowItWorks4'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { AppointmentLending } from './AppointmentLending'
import { MedicalServicesSlider } from './MedicalServicesSlider'

SwiperCore.use([Pagination, Navigation])

export const LandingPage = () => {
  const [showAppointment, setShowAppointment] = useState(false)
  return (
    <>
      <div className={Styles.main_container}>
        <Rectangle />
        <div className={Styles.main_container__MedServices}>
          <h1 className={Styles.main_container__heading}>Мы поможем тебе</h1>
          <MedicalServicesSlider setShowAppointment={setShowAppointment} />
        </div>
        <DoctorInfo />
        <HowItWorks1 />
        <HowItWorks2 />
        <HowItWorks3 />
        <HowItWorks4 />
        <h1 className={Styles.main_container__tariffsHeading} id='consultations'>
          Попробовать сейчас
        </h1>
        <AppointmentLending showAppointment={showAppointment} setShowAppointment={setShowAppointment} />
      </div>
    </>
  )
}
