/* eslint-disable camelcase */
import React from 'react'
import { NavLink } from 'react-router-dom'
import useWindowSize from '@helpers/useWindowSizeHook'
import { CatalogRating } from '../../../CatalogRating'
import Styles from './style.module.scss'
import noImg from '../../../../../../assets/images/spareProductImg.png'
import { CatalogPrice } from '../../../CatalogPrice'

interface CatalogNutraceuticalsItemProps {
  product: {
    id: number
    image: string
    brand: string
    name: string
    prices: number[]
    discount_prices: number[]
    rating: number
    reviews: number
    popularity: number
  }
}

export const CatalogNutraceuticalsItem: React.FC<CatalogNutraceuticalsItemProps> = ({ product }) => {
  const { width } = useWindowSize()
  const imageSource = product.image ? `${product.image}` : ``

  return (
    <div className={Styles.item}>
      <NavLink className={Styles.link} to={`/catalog/nutraceutical/${product.id}`}>
        <div className={Styles.image}>
          <img src={imageSource || noImg} alt='Иконка' />
        </div>

        <CatalogPrice discount_price={product.discount_prices[0]} price={product.prices[0]} />
        {width > 830 ? (
          <CatalogRating rating={product.rating} comment={product.reviews} />
        ) : (
          <CatalogRating rating={product.rating} comment={product.reviews} short />
        )}
        <p className={Styles.description}>
          {product.brand}, {product.name}
        </p>
      </NavLink>
    </div>
  )
}
