import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppDispatch } from '@app/redux/hooks'
import Styles from './style.module.scss'

import createIcon from '@icons/create.svg'
import viewIcon from '@icons/view.svg'

import { setNotificationFunc, createConclusion } from '../../../../index'
import { formateDate } from '../../../../../../utils/formateDate'
import { setCurrentPatient } from '../../../../../../pages/doctor/redux/doctorSlicer'
import { GetPdfButton } from '@components/GetPdfButton'

interface DoctorVisitProps {
  appointmentId: number
  consultationType: any
  dayReception: string
  isOnline: boolean
  medicalReport: any
  patient: any
  status: string
  timeReception: string
}

export const DoctorVisit: React.FC<DoctorVisitProps> = (props) => {
  const { appointmentId, consultationType, dayReception, isOnline, medicalReport, patient, status, timeReception } =
    props
  useEffect(() => {
    dispatch(setCurrentPatient(patient))
  }, [])
  const dispatch = useAppDispatch()
  const [notification, setNotification] = useState('')
  const [expired, setExpired] = useState(null)

  const classNameNotification = expired
    ? `${Styles.notification} ${Styles.notification_expired}`
    : `${Styles.notification}`

  useEffect(() => {
    setNotificationFunc(status, medicalReport, dayReception, setNotification, setExpired)
  }, [])

  return (
    <li className={Styles.visit}>
      <div className={Styles.block}>
        <span className={`${Styles.title} ${Styles.title_mod}`}>{`«${consultationType.direction.title}»`}</span>
        <span className={Styles.subtitle}>
          {consultationType.title}, {isOnline ? 'Онлайн' : 'Личный'}
        </span>
      </div>
      <div className={`${Styles.block} ${Styles.block_mod}`}>
        {patient.avatar ? (
          <img className={Styles.avatar} src={patient.avatar} alt='Аватар' />
        ) : (
          <div className={Styles.cap}>
            <span>
              {patient?.last_name?.substring(0, 1)}
              {patient?.first_name?.substring(0, 1)}
            </span>
          </div>
        )}
        <div className={Styles.patient}>
          <span className={Styles.title}>{patient.last_name}</span>
          <span className={Styles.subtitle}>{patient.first_name}</span>
        </div>
      </div>
      <div className={Styles.block}>
        <span className={Styles.title}>
          {formateDate(dayReception, 'DD MMMM')}, {formateDate(dayReception, 'dddd')}
        </span>
        <span className={Styles.subtitle}>{timeReception}</span>
      </div>
      <div className={Styles.block}>
        {medicalReport ? (
          <>
            <Link className={Styles.conclusion} to={`/appointments/${appointmentId}`}>
              <img src={viewIcon} alt='Иконка' />
              <span className={Styles.text}>Смотреть заключение</span>
            </Link>
            {medicalReport.is_report_done && <GetPdfButton medicalReportId={medicalReport.id} />}
          </>
        ) : (
          <>
            {status === 'cancel' && <span className={Styles.cancel}>Прием отменен</span>}
            {status === 'planned' && (
              <Link className={Styles.conclusion} to={`/appointments/${appointmentId}`}>
                <span className={Styles.text}>Смотреть детально</span>
              </Link>
            )}
            {status === 'not_payed' && <span className={Styles.cancel}>Прием не оплачен</span>}
            {status === ('done' || 'started') && (
              <button className={Styles.conclusion}>
                <img src={createIcon} alt='Иконка' />
                <span className={Styles.text} onClick={() => createConclusion(appointmentId, dispatch)}>
                  Создать заключение
                </span>
              </button>
            )}
          </>
        )}
        {notification && <span className={classNameNotification}>{notification}</span>}
      </div>
    </li>
  )
}
