import React, { FC } from 'react'
import styles from './style.module.scss'
import { RegistryLine } from '../RegistryLine'
import { ButtonColor } from '@components/ui/ButtonColor'
import { formatPriceWithRuble } from '../../../../utils/formatPriceWithRuble'

interface OrderTotalInfoProps {
  isPaid: boolean
  productsNumber: number
  productsTotalCost: number
  analysisNumber: number
  analysisTotalCost: number
  total: number
  discountPrice: number
  deliveryPrice: number
  goToPay: any
}

export const OrderTotalInfo: FC<OrderTotalInfoProps> = ({
  isPaid,

  productsNumber,
  productsTotalCost,
  total,
  analysisNumber,
  analysisTotalCost,
  discountPrice,
  deliveryPrice,
  goToPay,
}) => {
  return (
    <div className={styles.orderTotalInfo}>
      <h3 className={styles.title}>Ваш заказ</h3>
      {(productsNumber !== 0 || analysisNumber !== 0) && (
        <div className={styles.goods}>
          {productsNumber !== 0 && (
            <RegistryLine name={`Товары (${productsNumber})`} value={formatPriceWithRuble(productsTotalCost)} />
          )}
          {analysisNumber !== 0 && (
            <RegistryLine
              name={`Лаб. обследования (${analysisNumber})`}
              value={formatPriceWithRuble(analysisTotalCost)}
            />
          )}
        </div>
      )}

      <div className={styles.btl}>
        <RegistryLine name='Сумма заказа' value={formatPriceWithRuble(productsTotalCost + analysisTotalCost)} />
        <RegistryLine name='Скидка' value={formatPriceWithRuble(discountPrice)} />
        <RegistryLine name='Доставка в пункт выдачи' value={formatPriceWithRuble(deliveryPrice)} />
        <RegistryLine name='Общая стоимость' bold value={formatPriceWithRuble(total)} />
      </div>

      {!isPaid && <ButtonColor value='Оплатить' className={styles.orderTotalInfo} onClick={goToPay} />}
    </div>
  )
}
