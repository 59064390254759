import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import { FormStorage } from '../../../utils/form-storage'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import { TimeSelect } from './components/TimeSelect'
import { getMedicalReportById, patchLifestyleReport, postLifestyleReport } from '../redux/reportsSlice'
import { arrayUniqueBy } from '@helpers/arrayUniqueBy'

export const LifestyleReport = ({ setActiveContent }) => {
  const dispatch = useAppDispatch()
  const exerciseList = useAppSelector((state) => state.reports.exerciseList)
  const medicalReportId = useAppSelector((state) => state.reports.medicalReportId)
  const [initialState, setInitialState] = useState(null)

  const a = [...exerciseList].map((item) => {
    return Object.assign({}, item, {
      time: null,
      medical_report: medicalReportId,
      exercise: item.id,
      gift: Boolean(item?.time?.length),
      option: 'мин',
      id: null,
    })
  })
  useEffect(() => {
    dispatch(getMedicalReportById(medicalReportId)).then((res) => {
      if (res.payload.data.lifestyle_reports && res.payload.data.lifestyle_reports.length > 0) {
        const b = [...res.payload.data.lifestyle_reports].map((item) => {
          return Object.assign({}, item, {
            title: item.exercise.title,
            image: item.exercise.image,
            gift: Boolean(item?.time?.length),
            option: item.option,
            exercise: item.exercise.id,
            medical_report: medicalReportId,
            id: item.id,
          })
        })
        setInitialState(arrayUniqueBy(b.concat(a), (item) => item.title))
      } else {
        setInitialState(a)
      }
    })
  }, [])

  const initial = {
    lifeStyle: initialState,
  }

  const onSubmit = async (values) => {
    const a = values.lifeStyle.filter((item) => item.time && item.gift)

    const post = a.filter((item) => !item.id)
    const b = a.filter((item) => item.id)
    const patch = b.filter((item) => item)
    dispatch(patchLifestyleReport(patch))
      .then((res) => {
        if (res.payload.status < 400) {
          setActiveContent('nutraceutical')
        }
      })
      .then(() => {
        if (post.length > 0) {
          const val = post.map((item) => {
            delete item.id
            return item
          })
          dispatch(postLifestyleReport(val)).then((res) => {
            if (res.payload.status < 400) {
              setActiveContent('nutraceutical')
            }
          })
        }
      })

    if (post.length === 0 && patch.length === 0) {
      setActiveContent('nutraceutical')
    }
  }

  return (
    <>
      <FormStorage
        formId='lifeStyle'
        onSubmit={onSubmit}
        initialValues={initial}
        mutators={{
          ...arrayMutators,
        }}
      >
        <div className={Styles.form}>
          <FieldArray name='lifeStyle'>
            {({ fields }) =>
              fields.map((name, index) => (
                <>
                  <div key={index}>
                    <TimeSelect name={`${name}`} />
                  </div>
                </>
              ))
            }
          </FieldArray>
        </div>
      </FormStorage>
    </>
  )
}
