import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { OrderSummary } from './components/OrderSummary'
import { CatalogContent } from '../catalog/components/CatalogContent'
import { CatalogList } from '../catalog/components/CatalogList'
import { Spinner } from '@components/Spinner'
import { ShadowedContainer } from '@components/Containers/ShadowedContainer'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import {
  getMoreOrders,
  getOrders,
  setCurrentPage,
  getOrdersNotPaid,
  getOrdersReceived,
  getOrdersCancelled,
  getOrdersInWork,
} from './redux/ordersSlice'
import { ordersTypes } from './redux/orderTypes'
import declination from '@helpers/declination'
import { Swiper, SwiperSlide } from 'swiper/react'

const ORDERS_PER_PAGE = 20

export const Orders = () => {
  const dispatch = useAppDispatch()
  const { listLastViewed } = useAppSelector((state) => state.catalog)
  const { orders, queryInfo, countNotPaid, countReceived, countCancelled, countInWork } = useAppSelector(
    (state) => state.orders,
  )
  const [loading, setLoading] = useState(false)
  const [tabLoading, setTabLoading] = useState(false)
  const [getMoreOrdersLoading, setGetMoreOrdersLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('')
  const [isNoOrders, setIsNoOrders] = useState(false)

  const countLeftItems = (): number => {
    if (queryInfo.count - ORDERS_PER_PAGE * queryInfo.count < ORDERS_PER_PAGE) {
      return queryInfo.count - ORDERS_PER_PAGE * queryInfo.currentPage
    } else {
      return ORDERS_PER_PAGE
    }
  }

  const getMoreUserOrders = () => {
    setGetMoreOrdersLoading(true)

    const newPage = queryInfo.currentPage + 1

    dispatch(getMoreOrders({ newPage, status: activeTab })).then(() => {
      setGetMoreOrdersLoading(false)
      dispatch(setCurrentPage(newPage))
    })
  }

  const getUserOrders = useCallback(
    (activeTab) => {
      setTabLoading(true)
      dispatch(getOrders(activeTab)).then(() => setTabLoading(false))
    },
    [activeTab],
  )

  const loadOrders = async () => {
    setLoading(true)
    try {
      const res: any = await dispatch(getOrders(''))

      if (res.payload.results.length === 0) {
        setLoading(false)
        setIsNoOrders(true)
      } else {
        await dispatch(getOrdersNotPaid())
        await dispatch(getOrdersReceived())
        await dispatch(getOrdersCancelled())
        await dispatch(getOrdersInWork())
        await dispatch(getOrders(''))
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      // console.log(error)
    }
  }

  useEffect(() => {
    loadOrders()
  }, [])

  useEffect(() => {
    dispatch(setCurrentPage(1))
    getUserOrders(activeTab)
  }, [activeTab])

  return (
    <main className={styles.orders}>
      <h1 className={styles.title}>Мои заказы</h1>
      {loading ? (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      ) : isNoOrders ? (
        <>
          <div className={styles.emptyOrdersPage}>
            <p className={styles.emptyOrdersText}>
              У вас нет ни одного заказа. Вы можете посмотреть что-нибудь в&nbsp;
              <Link to='/catalog' className={styles.emptyOrdersLink}>
                каталоге
              </Link>
              &nbsp;или воспользоваться поиском
            </p>
          </div>
          {listLastViewed.length > 0 && (
            <div className={styles.wrapper}>
              <CatalogContent title='Вы недавно смотрели'>
                <CatalogList items={listLastViewed} />
              </CatalogContent>
            </div>
          )}
        </>
      ) : (
        <>
          <Swiper spaceBetween={8} slidesPerView='auto' className={styles.sort}>
            <SwiperSlide className={styles.slide}>
              <button
                className={`${styles.button} ${activeTab === '' && styles.button_active}`}
                onClick={() => setActiveTab('')}
              >
                Все
              </button>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <button
                className={`${styles.button} ${activeTab === 'not_paid' && styles.button_active}`}
                onClick={() => setActiveTab('not_paid')}
              >
                {`Не оплачены ${countNotPaid}`}
              </button>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <button
                className={`${styles.button} ${activeTab === 'received' && styles.button_active}`}
                onClick={() => setActiveTab('received')}
              >
                {`Получены ${countReceived}`}
              </button>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <button
                className={`${styles.button} ${activeTab === 'cancelled' && styles.button_active}`}
                onClick={() => setActiveTab('cancelled')}
              >
                {`Отменены ${countCancelled}`}
              </button>
            </SwiperSlide>
            <SwiperSlide className={styles.slide}>
              <button
                className={`${styles.button} ${activeTab === 'in_work' && styles.button_active}`}
                onClick={() => setActiveTab('in_work')}
              >
                {`В работе ${countInWork}`}
              </button>
            </SwiperSlide>
          </Swiper>
          <div className={styles.orderBlock}>
            {tabLoading ? (
              <Spinner />
            ) : (
              orders.length !== 0 &&
              orders
                .map((order: ordersTypes.IOrder, index) => {
                  return <OrderSummary order={order} key={order.id + index} />
                })
                .reverse()
            )}
          </div>
          {tabLoading ? (
            <Spinner />
          ) : (
            <ShadowedContainer customStyle={styles.more}>
              {getMoreOrdersLoading ? (
                <div className={styles.spinner}>
                  <Spinner />
                </div>
              ) : (
                <div className={styles.more_wrapper}>
                  {orders.length ? (
                    <div className={styles.more_info}>
                      <span>Вы просмотрели&nbsp;</span>
                      <strong>
                        {orders.length} из {queryInfo.count} заказов
                      </strong>
                    </div>
                  ) : (
                    <span>Нет заказов</span>
                  )}

                  <div className={styles.more_bar}>
                    <div
                      className={styles.more_inner}
                      style={{ width: `${100 * (orders.length / queryInfo.count)}%` }}
                    />
                  </div>
                  {orders.length !== queryInfo.count && (
                    <button className={styles.more_button} onClick={getMoreUserOrders}>
                      Показать еще {declination(countLeftItems(), 'заказ', 'заказа', 'заказов')}
                    </button>
                  )}
                </div>
              )}
            </ShadowedContainer>
          )}
        </>
      )}
    </main>
  )
}
