import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import { getLabs, searchCity } from '../../../redux/cartSlicer'
import { ResearchFragment } from '../../../ui/ReaserchFragment'
import { setDataDelivery } from '../DeliveryCart/redux/deliverySlice'
import { ButtonColor } from '@components/ui/ButtonColor'
import { ButtonWithBorder } from '@components/ui/ButtonWithBorder'
import { TooltipComponent } from '@components/TooltipComponent'

interface AnalysisCartProps {
  goToPay?: () => void
  deleteAnalysis: () => void
}

export const AnalysisCart: React.FC<AnalysisCartProps> = ({ goToPay, deleteAnalysis }) => {
  const dispatch = useAppDispatch()
  const cityCode = useAppSelector((state) => state.delivery.cityCode)
  const city = useAppSelector((state) => state.delivery.city)
  const { dataDelivery } = useAppSelector((state) => state.delivery)
  const [value, setValue] = useState(city)
  const [labsList, setLabsList] = useState(null)
  const [foundCities, setFoundCities] = useState([])
  const [isCitiesList, setIsCitiesList] = useState(false)
  const choosePointHandler = (point) => {
    dispatch(
      setDataDelivery({
        ...dataDelivery,
        lab: point.id,
        lab_adress: point.address,
      }),
    )
  }
  useEffect(() => {
    if (cityCode) {
      dispatch(getLabs(cityCode)).then((res) => {
        setLabsList(res.payload)
      })
    }
  }, [])

  const showCitiesList = () => {
    setIsCitiesList(true)
  }

  const hideCitiesList = () => {
    setIsCitiesList(false)
  }

  const getCities = async (value) => {
    try {
      const res = await dispatch(searchCity(value))
      setFoundCities(res.payload)
    } catch (error) {
      // console.log(error)
    }
  }
  const handlerChangeCity = async (e) => {
    const { value } = e.target
    setValue(value)
    dispatch(
      setDataDelivery({
        ...dataDelivery,
        lab: null,
      }),
    )
    if (value.length > 1) {
      getCities(value)
    }
  }
  const handlerFocusCity = () => {
    showCitiesList()
  }

  const handlerClear = () => {
    setValue('')
    setFoundCities([])
  }

  const handlerChoose = async (city) => {
    setValue(city.title)
    hideCitiesList()
    dispatch(getLabs(city.code)).then((res) => {
      setLabsList(res.payload)
    })
  }

  return (
    <div className={Styles.container}>
      <label className={Styles.label}>Населенный пункт</label>
      <div className={Styles.search}>
        <input
          onChange={handlerChangeCity}
          onFocus={handlerFocusCity}
          type='text'
          value={value}
          placeholder='Поиск города'
        />
        {value ? (
          <button className={Styles.clearBtn} onClick={handlerClear}>
            {}
          </button>
        ) : (
          <button className={Styles.searchBtn}>{}</button>
        )}
        {foundCities.length !== 0 && isCitiesList && (
          <>
            <div className={Styles.outListClickCatcher} onClick={hideCitiesList}>
              {}
            </div>
            <ul className={Styles.citiesList}>
              {foundCities.map((city) => (
                <li key={city.code} className={Styles.citiesItem}>
                  <button className={Styles.citiesItemBtn} onClick={() => handlerChoose(city)}>
                    {`${city.title} (${city.region})`}
                  </button>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className={Styles.point__container}>
        {labsList && labsList.length > 0 ? (
          <>
            <header>
              <span className={Styles.headerItem}>Адрес</span>
              <span className={Styles.headerItem}>Режим работы</span>
              <span className={Styles.headerItem}>Телефон</span>
            </header>
            <div className={Styles.point__list}>
              {labsList.map((point) => {
                return (
                  <div key={point.code} className={Styles.point} onClick={() => choosePointHandler(point)}>
                    <input
                      name='delivery-fragment'
                      id={point.code}
                      type='radio'
                      onChange={() => choosePointHandler(point)}
                      checked={dataDelivery.lab === point.id}
                    />
                    <label htmlFor={point.code} />
                    <div className={Styles.point__wrapper}>
                      <ResearchFragment
                        address={point.address}
                        workTimeFrom={point.work_time_from}
                        workTimeTo={point.work_time_to}
                        phones={point.phones}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <div className={Styles.absence}>
            <div className={Styles.absence__wrapper}>
              <span className={Styles.absence__text}>
                В вашем городе нет лабораторий-партнеров, обратитесь в любую доступную лабораторию вашего города
              </span>
              <TooltipComponent
                text='Для продолжения оформления заказа удалите, пожалуйста, обследования или выберите лабораторию в другом населенном пункте'
                marginTop
              />
            </div>
            <ButtonWithBorder value='Удалить обследование из корзины' onClick={deleteAnalysis} />
          </div>
        )}
        <div className={Styles.buttons__container}>
          <ButtonColor
            className={Styles.button}
            onClick={goToPay}
            value='Перейти к оплате'
            disabled={!dataDelivery.lab}
          />
        </div>
      </div>
    </div>
  )
}
