import React from 'react'
import Styles from './style.module.scss'

import { CancelHoursReception } from './components/CancelHoursReception'

import caretIcon from '@icons/caret.svg'

import { formateDate } from '../../../../../../../../utils/formateDate'

interface CancelHoursProps {
  currentMonthDate: any
  currentMonth: any
  changeMonth: any
  selectedDate: any
  onClickDay: any
  handlerChange: any
}

export const CancelHours: React.FC<CancelHoursProps> = (props) => {
  const { currentMonthDate, currentMonth, changeMonth, selectedDate, onClickDay, handlerChange } = props

  const directionChange = (direction) => {
    if (direction === 'backward') {
      const date = new Date(currentMonthDate)
      const prevDate = formateDate(date.setMonth(date.getMonth() - 1), 'YYYY-MM-DD')
      changeMonth(prevDate)
    }

    if (direction === 'forward') {
      const date = new Date(currentMonthDate)
      const nextDate = formateDate(date.setMonth(date.getMonth() + 1), 'YYYY-MM-DD')
      changeMonth(nextDate)
    }
  }

  return (
    <div className={Styles.cancel}>
      <div className={Styles.calendar}>
        <div className={Styles.select}>
          <img
            className={`${Styles.caret} ${Styles.caret_rotate}`}
            src={caretIcon}
            alt='Иконка'
            onClick={() => directionChange('backward')}
          />
          <span className={Styles.month}>{formateDate(currentMonth[15]?.day, 'MMMM YYYY')}</span>
          <img className={Styles.caret} src={caretIcon} alt='Иконка' onClick={() => directionChange('forward')} />
        </div>
        <div className={Styles.days}>
          {currentMonth.map((date) => (
            <>
              {date.is_available ? (
                <div
                  key={date.day}
                  className={selectedDate?.day === date.day ? `${Styles.day} ${Styles.day_selected}` : `${Styles.day}`}
                >
                  <span className={Styles.inner} onClick={() => onClickDay(date)}>
                    {formateDate(date.day, 'DD')}
                  </span>
                </div>
              ) : (
                <div key={date.day} className={`${Styles.day} ${Styles.day_disabled}`}>
                  <span className={Styles.inner}>{formateDate(date.day, 'DD')}</span>
                </div>
              )}
            </>
          ))}
        </div>
        {selectedDate && (
          <div className={Styles.oneday}>
            <span className={Styles.title}>{formateDate(selectedDate.day, 'DD MMMM')}</span>
            <div className={Styles.visits}>
              {selectedDate.receptions.map((reception) => (
                <CancelHoursReception key={reception.id} reception={reception} handlerChange={handlerChange} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
