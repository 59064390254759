import { createSlice } from '@reduxjs/toolkit'

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    modal: {
      isModalOpen: false,
      isAvatarPopupOpen: false,
    },
  },
  reducers: {
    closeProfileModal(state) {
      state.modal.isModalOpen = false
    },
    openProfileModal(state) {
      state.modal.isModalOpen = !state.modal.isModalOpen
    },
    openAvatarPopup(state, { payload }) {
      state.modal.isAvatarPopupOpen = payload
    },
  },
  extraReducers: {},
})

export const {
  actions: { closeProfileModal, openProfileModal, openAvatarPopup },
} = profileSlice

export default profileSlice.reducer
