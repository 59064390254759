import React from 'react'
import Styles from './style.module.scss'
import { BorderLine } from '@components/ui/BorderLine'

export const LifeStyle = (props) => {
  return (
    <div className={Styles.mealtime__container}>
      {props.data.map((item) => (
        <div key={item.id} className={Styles.container}>
          {!props.main && <img src={item.exercise.image} alt='logo' />}
          <div className={Styles.description}>
            <h5 className={Styles.title}>{item?.exercise?.title}</h5>
            <div className={Styles.time}>{`${item.time} ${item.option}`}</div>
          </div>
          <BorderLine />
        </div>
      ))}
    </div>
  )
}
