import { useAppSelector } from '@app/redux/hooks'
import { Avatar } from '@components/Avatar'
import moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { MessageAttachment } from '../MessageAttachment'
import { IMessageInterface } from '../utils/types'
import Styles from './style.module.scss'

export const MessageField = () => {
  const bottomRef: any = useRef()
  const currentMessages = useAppSelector((state) => state.chat.currentMessages)
  let currentMessenger = ''

  useEffect(() => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [currentMessages])
  return (
    <ul className={Styles.container}>
      {currentMessages.length ? (
        currentMessages?.map((msg: IMessageInterface) => {
          let nameHasBeenChanged = false
          if (currentMessenger === msg.user.first_name) {
            nameHasBeenChanged = false
          } else {
            nameHasBeenChanged = true
          }
          currentMessenger = msg.user.first_name
          return nameHasBeenChanged ? (
            <li className={Styles.message} key={msg.id}>
              <Avatar
                firstName={msg.user.first_name}
                lastName={msg.user.last_name}
                avatar={msg.user.avatar}
                size='44px'
              />
              <div className={Styles.messageContent}>
                <div className={Styles.messageHeader}>
                  <div className={Styles.name}>{msg.user.first_name}</div>
                  <span>{moment(msg.created_at).format('HH:mm')}</span>
                </div>
                <p className={Styles.messageText}>{msg.text}</p>
                <MessageAttachment message={msg} noMargin />
              </div>
            </li>
          ) : (
            <li className={Styles.message} key={msg.id}>
              <div className={Styles.messageContent}>
                <div className={Styles.messageBody}>
                  <p className={Styles.messageText}>{msg.text}</p>
                  <span>{moment(msg.created_at).format('HH:mm')}</span>
                </div>
                <MessageAttachment message={msg} />
              </div>
            </li>
          )
        })
      ) : (
        <div className={Styles.noMessages}>
          <h2>Сообщений пока нет...</h2>
          <p>Пожалуйста, не забывайте, что у врача много пациентов и будьте терпеливы &#x1F64C;</p>
        </div>
      )}
      <div ref={bottomRef} />
    </ul>
  )
}
