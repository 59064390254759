import React from 'react'
import { Link } from 'react-router-dom'
import { CatalogRating } from '../../../../../CatalogRating'
import Styles from './style.module.scss'

export const CatalogTogetherItem = ({ item }) => {
  return (
    <div className={Styles.item}>
      <Link className={Styles.link} to={`/catalog/nutraceutical/${item.id}`}>
        {/* <span className={Styles.new}>Новинка</span> */}
        <div className={Styles.container}>
          <div className={Styles.image}>
            <img src={item.image} alt='Иконка' />
          </div>
        </div>
        <div className={Styles.content}>
          <span className={Styles.discount}>– {item.price - item.discount_price} ₽</span>
          <div className={Styles.block}>
            <span className={Styles.price}>{item.discount_price} ₽</span>
            <span className={Styles.old}>{Math.round(+item.price)} ₽</span>
          </div>
          <CatalogRating rating={item.rating} comment={item.reviews} short />
          <p className={Styles.description}>{item.name}</p>
        </div>
      </Link>
      {/* <div className={Styles.selecting}>
        {selecting.map((select) => (
          <span
            key={select}
            className={select === selectActive ? `${Styles.select} ${Styles.select_active}` : `${Styles.select}`}
            onClick={() => setSelectActive(select)}
          >
            {select}
          </span>
        ))}
      </div> */}
    </div>
  )
}
