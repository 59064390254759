import React from 'react'
import Styles from './style.module.scss'
import pushPin from '@icons/push-pin.svg'
import arrow from '@icons/arrow-right-large.svg'
import moment from 'moment'
import { Link } from 'react-router-dom'

export const MedicalReport = (props) => {
  const date = props.medicalReports?.done_date
  return (
    <div className={Styles.container}>
      <Link className={Styles.button} to={`/my-appointment/${props.id}`}>
        <img src={pushPin} className={Styles.image} alt='img' />
        <div className={Styles.desc}>
          <h5 className={Styles.title}>Медицинское заключение</h5>
          <p className={Styles.text}>{date ? moment(date).format('DD.MM.YYYY') : ''}</p>
        </div>
        <img className={Styles.arrow} src={arrow} alt='arrow' />
      </Link>
    </div>
  )
}
