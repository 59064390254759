import React from 'react'
import { useAppSelector } from '@app/redux/hooks'
import { Container } from '../../components/Container'
import { CatalogView } from './components/CatalogView'
import { CatalogNutraceuticals } from './components/CatalogNutraceuticals'
import { CatalogResearch } from './components/CatalogResearch'
import Styles from './style.module.scss'

export const CatalogPage = () => {
  const view = useAppSelector((state) => state.catalog.view)

  return (
    <div className={Styles.wrapper}>
      <Container title='Каталог'>
        <CatalogView />
        {view === 'nutraceuticals' ? <CatalogNutraceuticals /> : <CatalogResearch />}
      </Container>
    </div>
  )
}
