/* eslint-disable camelcase */
import React from 'react'
import Styles from './style.module.scss'

interface CatalogPriceProps {
  discount_price: number
  price: number
  bigText?: boolean
}

export const CatalogPrice: React.FC<CatalogPriceProps> = ({ discount_price, price, bigText }) => {
  return price ? (
    discount_price !== price ? (
      <div className={Styles.container}>
        <span className={Styles.discount}>– {Math.round(price - discount_price)} ₽</span>
        <div className={Styles.block}>
          <span style={bigText && { fontSize: '26px' }} className={Styles.price}>
            {discount_price} <span>₽</span>
          </span>
          <span style={bigText && { fontSize: '26px' }} className={Styles.old}>
            {price} <span>₽</span>
          </span>
        </div>
      </div>
    ) : (
      <div className={Styles.container}>
        <span className={Styles.discount} style={{ opacity: '0' }}>
          -
        </span>
        <div className={Styles.block}>
          <span style={bigText && { fontSize: '26px' }} className={Styles.price}>
            {price} <span>₽</span>
          </span>
          {/* <span className={Styles.old}>{price} ₽</span> */}
        </div>
      </div>
    )
  ) : (
    <div className={Styles.container}>
      <span className={Styles.discount} style={{ opacity: '0' }}>
        -
      </span>
      <div className={Styles.block}>
        <span className={Styles.price}>{price}</span>
        {/* <span className={Styles.old}>{price} ₽</span> */}
      </div>
    </div>
  )
}
