// eslint-disable-next-line import/named
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ordersTypes } from './orderTypes'
import { api } from '@app/api'

export const getOrders = createAsyncThunk('orders/getOrders', async (status: string) => {
  if (status) {
    const response = await api.get<ordersTypes.IGetOrders>(`orders/?status=${status}`)
    return response.data
  } else {
    const response = await api.get<ordersTypes.IGetOrders>('orders/')
    return response.data
  }
})

export const getMoreOrders = createAsyncThunk('orders/getMoreOrders', async (data: any) => {
  if (data.status) {
    const response = await api.get<ordersTypes.IGetOrders>(`orders/?status=${data.status}&page=${data.newPage}`)
    return response.data
  } else {
    const response = await api.get<ordersTypes.IGetOrders>(`orders/?page=${data.newPage}`)
    return response.data
  }
})

export const getOrdersNotPaid = createAsyncThunk('orders/getOrdersNotPaid', async () => {
  const response = await api.get<ordersTypes.IGetOrders>(`orders/?status=not_paid`)
  return response.data
})

export const getOrdersReceived = createAsyncThunk('orders/getOrdersReceived', async () => {
  const response = await api.get<ordersTypes.IGetOrders>(`orders/?status=received`)
  return response.data
})

export const getOrdersCancelled = createAsyncThunk('orders/getOrdersCancelled', async () => {
  const response = await api.get<ordersTypes.IGetOrders>(`orders/?status=cancelled`)
  return response.data
})

export const getOrdersInWork = createAsyncThunk('orders/getOrdersInWork', async () => {
  const response = await api.get<ordersTypes.IGetOrders>(`orders/?status=in_work`)
  return response.data
})

export const getOrder = createAsyncThunk('orders/getOrder', async (id: number) => {
  const response = await api.get<ordersTypes.IOrder>(`orders/${id}/`)
  return response.data
})

const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    queryInfo: {
      count: 0,
      next: null,
      prev: null,
      currentPage: 1,
    } as ordersTypes.IQueryInfo,
    orders: [] as ordersTypes.IOrder[],
    countNotPaid: null,
    countReceived: null,
    countCancelled: null,
    countInWork: null,
  },
  reducers: {
    setOrders(state, { payload }) {
      state.orders = payload
    },
    setCurrentPage(state, { payload }) {
      state.queryInfo.currentPage = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.fulfilled, (state, action: PayloadAction<ordersTypes.IGetOrders>) => {
      const { count, next, prev, results } = action.payload
      const queryInfo = {
        ...state.queryInfo,
        count,
        next,
        prev,
      }

      state.queryInfo = queryInfo
      state.orders = [...results]
    })
    builder.addCase(getMoreOrders.fulfilled, (state, action: PayloadAction<ordersTypes.IGetOrders>) => {
      const { count, next, prev, results } = action.payload
      const queryInfo = {
        ...state.queryInfo,
        count,
        next,
        prev,
      }

      state.queryInfo = queryInfo
      state.orders = [...state.orders, ...results]
    })
    builder.addCase(getOrdersNotPaid.fulfilled, (state, action: PayloadAction<ordersTypes.IGetOrders>) => {
      const { count, next, prev } = action.payload
      const queryInfo = {
        ...state.queryInfo,
        count,
        next,
        prev,
      }

      state.queryInfo = queryInfo
      state.countNotPaid = count
    })
    builder.addCase(getOrdersReceived.fulfilled, (state, action: PayloadAction<ordersTypes.IGetOrders>) => {
      const { count, next, prev } = action.payload
      const queryInfo = {
        ...state.queryInfo,
        count,
        next,
        prev,
      }

      state.queryInfo = queryInfo
      state.countReceived = count
    })
    builder.addCase(getOrdersCancelled.fulfilled, (state, action: PayloadAction<ordersTypes.IGetOrders>) => {
      const { count, next, prev } = action.payload
      const queryInfo = {
        ...state.queryInfo,
        count,
        next,
        prev,
      }

      state.queryInfo = queryInfo
      state.countCancelled = count
    })
    builder.addCase(getOrdersInWork.fulfilled, (state, action: PayloadAction<ordersTypes.IGetOrders>) => {
      const { count, next, prev } = action.payload
      const queryInfo = {
        ...state.queryInfo,
        count,
        next,
        prev,
      }

      state.queryInfo = queryInfo
      state.countInWork = count
    })
  },
})

export const { setOrders, setCurrentPage } = ordersSlice.actions

export default ordersSlice.reducer
