import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Styles from './style.module.scss'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import { AppointmentHistoryCardMobile } from '../AppointmentHistoryCardMobile'

interface CardsProps {
  statusDone: string
  dataDone: any
}
export const AppointmentHistoryMobile: React.FC<CardsProps> = (props) => {
  return (
    <div className={Styles.history}>
      <div className={Styles.slider}>
        <Swiper spaceBetween={10} slidesPerView={1} pagination={{ clickable: true }} style={{ width: '100%' }}>
          {props?.dataDone?.map((item) => (
            <>
              <SwiperSlide key={item.id}>
                <AppointmentHistoryCardMobile
                  title={item.consultation_type?.direction?.title}
                  date={item.day_reception}
                  conclusion={item.medical_report}
                  time={item.time_reception}
                  id={item.id}
                  status={item.status}
                  detail={item.detail}
                  absence={item.absence}
                  notPaid={!item.is_pay}
                />
              </SwiperSlide>
            </>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
