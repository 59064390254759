import React, { useState } from 'react'
import Styles from './style.module.scss'
import { SideMenuBody } from '@components/SideMenu/SideMenuBody'
import { Stories } from '@components/Stories'
import useWindowSize from '@helpers/useWindowSizeHook'
import { MOBILE_BREAKPOINT } from '../../../../../../global-constants'

export const ForYou = () => {
  const { width } = useWindowSize()
  const desktop = width > MOBILE_BREAKPOINT
  const [forYouActive, setForYouActive] = useState(false)
  const [eventActive, setEventActive] = useState(true)
  const isActive = () => {
    setEventActive(!eventActive)
    setForYouActive(!forYouActive)
  }
  return (
    <div className={Styles.container}>
      {desktop ? (
        <>
          <h2 className={Styles.title}>Для вас</h2>

          <Stories vertical />
        </>
      ) : (
        <>
          <h2 className={Styles.title}>Для вас</h2>
          <div className={Styles.button__container_mobile}>
            <button
              onClick={isActive}
              className={forYouActive ? `${Styles.button} ${Styles.button_active}` : Styles.button}
            >
              Для вас
            </button>
            <button
              onClick={isActive}
              className={eventActive ? `${Styles.button} ${Styles.button_active}` : Styles.button}
            >
              События
            </button>
          </div>
          {forYouActive && (
            <div className={Styles.events_mobile}>
              <Stories vertical />
            </div>
          )}
          {eventActive && (
            <div className={Styles.events_mobile}>
              <SideMenuBody activeContent='bell' />
            </div>
          )}
        </>
      )}
    </div>
  )
}
