import React from 'react'
import Styles from './style.module.scss'

interface FilterCheckboxProps {
  title: string
  count?: number
  setFilter: any
  filter: any
  filterKey: string
}

export const FilterCheckbox: React.FC<FilterCheckboxProps> = (props) => {
  const { title, count, setFilter, filter, filterKey } = props

  const handleChange = (e) => {
    e.target.checked
      ? setFilter((prev) => ({
          ...prev,
          [filterKey]: `${prev[filterKey] && prev[filterKey] + ','}${title}`,
        }))
      : setFilter((prev) => ({
          ...prev,
          [filterKey]: prev[filterKey]
            .split(',')
            .filter((item) => item !== title)
            .join(','),
        }))
  }

  return (
    <label className={Styles.label}>
      <input type='checkbox' onChange={handleChange} checked={filter[filterKey].includes(title)} />
      <span className={Styles.checkbox}>{}</span>
      {title}
      <span className={Styles.count}>{count}</span>
    </label>
  )
}
