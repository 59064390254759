import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { deleteBlackList, getBlackLists, setDisplayProfileContent } from '../../../../redux/doctorSlicer'

import { PersonalInfo } from './components/PersonalInfo'
import { EditData } from './components/EditData'
import { CanceledVisit } from './components/CanceledVisit'
import { CancelVisits } from './components/CancelVisits'
import { DoctorPopupMobileVH } from '../../../DoctorModal/components/DoctorPopupMobileVH'
import { DoctorPopupMobile } from '../../../DoctorModal/components/DoctorPopupMobile'
import { Confirm } from './components/Confirm'

export const DoctorProfileMobile: React.FC = () => {
  const dispatch = useAppDispatch()
  const displayProfileContent = useAppSelector((state) => state.doctor.displayProfileContent)
  const [displayConfirm, setDisplayConfirm] = useState(false)
  const [selectedCancel, setSelectedCancel] = useState(null)

  const closePopupVH = () => dispatch(setDisplayProfileContent('info'))
  const closePopup = () => setDisplayConfirm(false)

  const reopenCancel = (cancel) => {
    setSelectedCancel(cancel)
    setDisplayConfirm(true)
  }

  const reopenConfirm = async (selectedCancelId) => {
    try {
      await dispatch(deleteBlackList(selectedCancelId))
      await dispatch(getBlackLists())

      closePopup()
    } catch (error) {
      throw new Error(error)
    }
  }

  return (
    <>
      {displayProfileContent === 'info' && <PersonalInfo reopenCancel={reopenCancel} reopenConfirm={reopenConfirm} />}
      {displayProfileContent === 'edit' && (
        <DoctorPopupMobileVH title='Личные данные' backPopup={closePopupVH} closePopup={closePopupVH}>
          <EditData />
        </DoctorPopupMobileVH>
      )}
      {displayProfileContent === 'canceled' && (
        <DoctorPopupMobileVH title='Прием отменен' backPopup={closePopupVH} closePopup={closePopupVH}>
          <CanceledVisit
            reopenCancel={reopenCancel}
            displayConfirm={displayConfirm}
            closePopup={closePopup}
            selectedCancel={selectedCancel}
            reopenConfirm={reopenConfirm}
          />
        </DoctorPopupMobileVH>
      )}
      {displayProfileContent === 'cancel' && (
        <DoctorPopupMobileVH title='Отменить дни приема' backPopup={closePopupVH} closePopup={closePopupVH}>
          <CancelVisits />
        </DoctorPopupMobileVH>
      )}
      {displayConfirm && (
        <DoctorPopupMobile closePopup={closePopup}>
          <Confirm selectedCancel={selectedCancel} reopenConfirm={reopenConfirm} closePopup={closePopup} />
        </DoctorPopupMobile>
      )}
    </>
  )
}
