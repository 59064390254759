import { api } from '@app/api'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getPaymentLink: any = createAsyncThunk('payment/getPaymentLink', async function (id) {
  const response = await api.post(`payment/appointment/`, id)
  return response.data
})

export const postDalailAboutAppointment: any = createAsyncThunk(
  'payment/postDalailAboutAppointment',
  async function () {
    const response = await api.get(`last-payed-appointment/`)
    return response.data
  },
)
const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    paymentLink: null,
    status: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentLink.fulfilled, (state, action) => {
      state.paymentLink = action.payload.results
      state.status = 'success'
    })
    builder.addCase(getPaymentLink.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getPaymentLink.rejected, (state) => {
      state.status = 'error'
    })
  },
})

export default paymentSlice.reducer
