import React from 'react'
import Styles from './style.module.scss'

export const PageNotFount = () => {
  return (
    <div className={Styles.found_container}>
      <h1>Страница не найдена</h1>
    </div>
  )
}
