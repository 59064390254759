import React, { useEffect, useRef } from 'react'
import Styles from './style.module.scss'
import { CardContainer } from '@components/ui/CardContainer'
import clock from '@icons/clock.svg'
import warning from '@icons/warning.svg'
import { BorderLine } from '@components/ui/BorderLine'
import {
  consultationActive,
  changeTitle,
  mobileState,
  mobileStatePagination,
} from '../../redux/choiсeConsultationSlicer'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setData, setSavingUserInput } from '../../redux/consultationSlicer'
import { getConsultationsList, getDataById } from '../../redux/consultationsListSlicer'
import { formatPrice } from '@helpers/formatPrice'
import moment from 'moment'
import { Tag } from '@components/ui/Tag'
import { MobileHeader } from '../MobileHeader'
import { Spinner } from '@components/Spinner'
import sctollToTop from '@helpers/scrollToTop'
import useWindowSize from '@helpers/useWindowSizeHook'

export const ConsultationFull = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getConsultationsList())
  }, [])
  const data = useAppSelector((state) => state.consultationsList.data)
  const status = useAppSelector((state) => state.consultationsList.status)
  const pagination = useAppSelector((state) => state.choieConsultationPage.list)
  const tooltipText = 'Точная стоимость появится после выбора типа приема и консультации'
  const dateConvert = (day) => {
    return moment(day, 'YYYY-MM-DD').format('DD MMMM')
  }
  const activeConsultation = useAppSelector((state) => state.consultationPage.data.savingUserInput)
  const saveUserDataInput = (id) => {
    if (!(id in activeConsultation)) {
      dispatch(setSavingUserInput({ key: id, value: {} }))
    }
  }

  const { width } = useWindowSize()
  const desktop = width >= 829
  const component = useRef<HTMLDivElement>(null)
  const topSize = desktop ? 0 : 70
  return (
    <div
      ref={component}
      className={`${Styles.reference__container} ${pagination[0].mobile && Styles.reference__mobile}`}
    >
      <MobileHeader
        title='Выбор консультации'
        onClick={() => {
          dispatch(mobileState({ id: 0, value: false }))
          dispatch(mobileStatePagination(false))
        }}
      />
      {status === 'success' &&
        data?.map((item) => (
          <CardContainer
            onClick={() => {
              dispatch(setData({ key: 'title', value: item.title }))
              dispatch(consultationActive(1))
              dispatch(changeTitle({ id: 0, value: item.title }))
              dispatch(getDataById(item.id))
              dispatch(mobileState({ id: 1, value: true }))
              sctollToTop(component, topSize)
              saveUserDataInput(item.id)
            }}
            className={Styles.card__container}
            title={item.title}
            src={item.image}
            key={item.id}
            price={formatPrice(item.types[0].price)}
            startOf
            tooptipText={tooltipText}
            content={
              <>
                <div className={Styles.wrapper}>
                  {item.types[0].duration && (
                    <div className={Styles.duration__container}>
                      <img src={clock} className={Styles.icon} alt='clock' />
                      <div className={Styles.duration}>{item.types[0].duration}</div>
                    </div>
                  )}
                  {item.mark && <div className={Styles.mark}>{item.mark}</div>}
                </div>
                <p className={Styles.text}>{item.description}</p>
                <BorderLine />
                <div className={Styles.reception}>Ближайший прием {dateConvert(item.available_time.day)}</div>
                {item?.available_time?.receptions?.map((tag, id) => (
                  <span key={id}>
                    <Tag value={tag} />
                  </span>
                ))}
                {item.warning && (
                  <>
                    <div className={Styles.warning}>
                      <img src={warning} className={Styles.warning__img} alt='warning' />
                      {item.warning}
                    </div>
                  </>
                )}
              </>
            }
          />
        ))}
      {status === 'error' && <div>Ошибка</div>}
      {status === 'loading' && <Spinner />}
    </div>
  )
}
