import moment from 'moment'

export const formateDate = (date, format) => {
  return moment(date).format(format)
}

export const getDay = (date) => {
  const day = moment(date).format('YYYY-MM-DD')

  return { day }
}

export const getCurrentWeek = (date) => {
  const firstDayWeek = moment(date).format('YYYY-MM-DD')
  const lastDayWeek = moment(date).add(7, 'days').format('YYYY-MM-DD')

  return { firstDayWeek, lastDayWeek }
}

export const getWeek = (date) => {
  const firstDayWeek = moment(date).startOf('isoWeek').format('YYYY-MM-DD')
  const lastDayWeek = moment(date).endOf('isoWeek').format('YYYY-MM-DD')

  return { firstDayWeek, lastDayWeek }
}

export const getCurrentMonth = () => {
  const firstDayMonth = moment().startOf('month').startOf('week').format('YYYY-MM-DD')
  const lastDayMonth = moment().endOf('month').endOf('week').format('YYYY-MM-DD')

  return { firstDayMonth, lastDayMonth }
}

export const getMonth = (date) => {
  const firstDayMonth = moment(date).startOf('month').startOf('week').format('YYYY-MM-DD')
  const lastDayMonth = moment(date).endOf('month').endOf('week').format('YYYY-MM-DD')

  return { firstDayMonth, lastDayMonth }
}

export const incrementDate = (increment, format) => {
  return moment().add(increment, 'days').format(format)
}
