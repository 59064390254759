import React from 'react'
import Styles from './style.module.scss'

import { CancelDaysDay } from './components/CancelDaysDay'

import { formateDate } from '../../../../../../../../utils/formateDate'

interface CancelDaysProps {
  currentHalfYears: any
  onSelectDay: any
  selectedDates: any
}

export const CancelDays: React.FC<CancelDaysProps> = (props) => {
  const { currentHalfYears, onSelectDay, selectedDates } = props

  return (
    <div className={Styles.months}>
      {currentHalfYears.map((month) => (
        <div key={month.date} className={Styles.month}>
          <span className={Styles.may}>{formateDate(month.date, 'MMMM YYYY')}</span>
          <div className={Styles.days}>
            {month.data.map((day) => (
              <CancelDaysDay
                key={day.day}
                day={day}
                month={month.date}
                onSelectDay={onSelectDay}
                selectedDates={selectedDates}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}
