import React, { useEffect, useRef, useState } from 'react'
import Styles from './style.module.scss'
import dropdownButton from '@icons/dropdown_button_profile.svg'
import { Link, useHistory } from 'react-router-dom'
import { Avatar } from '@components/Avatar'
import { TransitionsModal } from '@components/Modal'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { handleLogout } from '../../../pages/authorization/redux/authSlice'

interface ProfileButtonProps {
  avatar?: string
  firstName?: string
  lastName?: string
  loggedIn?: boolean
  onClick?: () => void
}

export const ProfileButton: React.FC<ProfileButtonProps> = ({ avatar, firstName, lastName, loggedIn, onClick }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const ref: any = useRef()
  const user = useAppSelector((state) => state.auth.user.info)
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isDropdownOpen])

  const handleSelectClick = () => {
    setIsDropdownOpen((prev) => !prev)
  }
  const handleLogoutClick = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }
  const handleOutClick = () => {
    closeModal()
    dispatch(handleLogout())
    history.push('/')
  }

  return loggedIn ? (
    <>
      <button className={Styles.button} onClick={handleSelectClick} ref={ref}>
        <Avatar avatar={avatar} firstName={firstName} lastName={lastName} />
        <div className={Styles.name}>
          {`${firstName || 'Профиль'} ${(lastName && lastName.substring(0, 1).concat('.')) || ''}`}
        </div>
        <img src={dropdownButton} alt='аватар' className={Styles.dropdownButton} />
        {isDropdownOpen && (
          <div className={Styles.dropdown}>
            {user.role === 'patient' && (
              <Link to='/orders' className={Styles.link}>
                <div className={Styles.option}>Мои заказы</div>
              </Link>
            )}
            <div className={Styles.option} onClick={onClick}>
              Профиль
            </div>
            <div className={Styles.option} onClick={handleLogoutClick}>
              Выход
            </div>
          </div>
        )}
      </button>
      <TransitionsModal isOpenModal={isModalOpen} setModalOpened={closeModal} onClose={closeModal}>
        <div className={Styles.modal}>
          <h2 className={Styles.heading}>Вы уверены, что хотите выйти?</h2>
          <button type='button' onClick={handleOutClick}>
            Выйти
          </button>
          <button className={Styles.cancelButton} type='button' onClick={closeModal}>
            Отмена
          </button>
        </div>
      </TransitionsModal>
    </>
  ) : (
    <Link to='/login' className={Styles.buttonLink} onClick={handleSelectClick}>
      Вход
    </Link>
  )
}
