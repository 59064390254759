import React from 'react'
import { Link } from 'react-router-dom'
import Styles from './style.module.scss'

export const NotificationInCart = () => {
  return (
    <div className={Styles.notification}>
      <Link to='/cart' className={Styles.link}>
        <button>
          <span>Товары добавлены в корзину</span>
          <span>Перейти</span>
        </button>
      </Link>
    </div>
  )
}
