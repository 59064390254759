import React from 'react'
import { useAppSelector } from '@app/redux/hooks'
import useWindowSize from '../../../../helpers/useWindowSizeHook'
import Styles from './style.module.scss'

import { DoctorVisit } from './components/DoctorVisit'
import { DoctorHistoryMobile } from './components/DoctorHistoryMobile'
import { Tooltip } from '../UI/Tooltip'

import questionIcon from '@icons/question.svg'

import { MOBILE_BREAKPOINT } from '../../constants'

export const DoctorHistory: React.FC = () => {
  const { width } = useWindowSize()
  const listAppointmentsDone = useAppSelector((state) => state.doctor.listAppointmentsDone)

  return (
    <>
      {width > MOBILE_BREAKPOINT ? (
        <div className={Styles.table}>
          <div className={Styles.header}>
            <div className={Styles.wrap}>
              <span className={Styles.head}>Консультация</span>
            </div>
            <div className={Styles.wrap}>
              <span className={Styles.head}>Пациент</span>
            </div>
            <div className={Styles.wrap}>
              <span className={Styles.head}>Дата</span>
            </div>
            <div className={Styles.wrap}>
              <span className={`${Styles.head} ${Styles.head_mod}`}>Мед. заключение</span>
              <div className={Styles.icon}>
                <img src={questionIcon} alt='Иконка' />
                <Tooltip className={Styles.tooltip}>Описание заключения</Tooltip>
              </div>
            </div>
          </div>
          <ul className={Styles.content}>
            {listAppointmentsDone.length !== 0 ? (
              <>
                {listAppointmentsDone.map((appointment) => (
                  <DoctorVisit
                    key={appointment.id}
                    appointmentId={appointment.id}
                    consultationType={appointment.consultation_type}
                    dayReception={appointment.day_reception}
                    isOnline={appointment.is_online}
                    medicalReport={appointment.medical_report}
                    patient={appointment.patient}
                    status={appointment.status}
                    timeReception={appointment.time_reception}
                  />
                ))}
              </>
            ) : (
              <span className={Styles.epmty}>Прошедших записей пока нет</span>
            )}
          </ul>
        </div>
      ) : (
        <div className={Styles.table}>
          <DoctorHistoryMobile />
        </div>
      )}
    </>
  )
}
