import React, { useState } from 'react'
import Styles from './style.module.scss'
import { TransitionsModal } from '@components/Modal'
import useWindowSize from '@helpers/useWindowSizeHook'
import { MapComponent } from '../Map/MapComponent'
// import { MapFragment } from '../../../../../ui/MapFragment'
import { DrawerPanel } from '@components/Drawer'
import { DeliveryFragment } from '../../../../../ui/DeliveryFragment'
import { useAppSelector } from '@app/redux/hooks'

export const MapModal = (props) => {
  const { width } = useWindowSize()
  const desktop = width >= 829
  const { dataDelivery } = useAppSelector((state) => state.delivery)
  const [activeTab, setActiveTab] = useState('map')
  // const [inputValue, setInputValue] = useState('')

  return (
    <>
      {desktop && (
        <TransitionsModal
          onClose
          isOpenModal={props.isOpenModal}
          setModalOpened={props.setModalOpened}
          className={Styles.modal__container}
        >
          <div className={Styles.map__container}>
            <div className={Styles.map__header}>
              <h2 className={Styles.map__title}>
                Выберите из <span>{dataDelivery.points.length}</span> пунктов выдачи
              </h2>
              {/* <MapFragment filter1={23} filter2={45} modal /> */}
            </div>
            <MapComponent setModalOpened={props.setModalOpened} />
          </div>
        </TransitionsModal>
      )}
      {!desktop && (
        <DrawerPanel
          width='100%'
          anchor='right'
          open={props.isOpenModal}
          onClose={() => props.setModalOpened((isOpen) => !isOpen)}
        >
          <div className={Styles.drawer__container}>
            <div className={Styles.navigation}>
              <div>
                <button
                  className={`${Styles.button} ${activeTab === 'map' && Styles.button_active}`}
                  onClick={() => setActiveTab('map')}
                >
                  На карте
                </button>
                <button
                  className={`${Styles.button} ${activeTab === 'list' && Styles.button_active}`}
                  onClick={() => setActiveTab('list')}
                >
                  Списком
                </button>
              </div>
              <button className={Styles.close} onClick={() => props.setModalOpened(false)} />
            </div>
            <div className={Styles.drawer__content}>
              <div className={Styles.header}>
                {/* <div className={Styles.search}>
                  <input
                    onChange={(e) => setInputValue(e.target.value)}
                    type='text'
                    value={inputValue}
                    placeholder='Введите адрес'
                  />
                  <button />
                </div>
                <button className={Styles.filter} /> */}
              </div>
              {activeTab === 'map' ? (
                <MapComponent setModalOpened={props.setModalOpened} />
              ) : (
                <div className={Styles.list}>
                  {dataDelivery.points.length !== 0 &&
                    dataDelivery.points.map((point) => (
                      <div key={point.id} className={Styles.list__item}>
                        <DeliveryFragment
                          address={point.location.address}
                          workTime={point.work_time}
                          periodCalc={props.periodCalc}
                        />
                        {/* <button className={Styles.list__arrow} /> */}
                        <button className={Styles.list__button} onClick={() => props.choosePointHandler(point)}>
                          Заберу здесь
                        </button>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </DrawerPanel>
      )}
    </>
  )
}
