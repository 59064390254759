import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getNutraceuticalById } from '../../../../pages/authorization/redux/authSlice'
import React, { useEffect, useState } from 'react'
import Styles from './style.module.scss'
import { DrawerPanel } from '@components/Drawer'
import closeButton from '@icons/close.svg'
import Parser from 'html-react-parser'
import { setFilterNutraceutical } from '../../../../pages/user/redux/userSpaceSlicer'
import declination from '../../../../helpers/declination'

export const Nutraceutical = (props) => {
  const dispatch = useAppDispatch()
  const [showDetail, setShowDetail] = useState(false)
  const [detailItem, setDetailItem] = useState(null)
  const [detailNutrInfo, setDetailNutrInfo] = useState(null)
  useEffect(() => {
    dispatch(setFilterNutraceutical(props.data))
  }, [])
  const filterData = useAppSelector((state) => state.userSpace.filterNutraceutical)

  const openDetails = (e) => {
    props.data.map((item) => {
      if (item.nutraceutical.name === e.target.id) {
        setDetailNutrInfo(item)
        dispatch(getNutraceuticalById(item.nutraceutical.id)).then((res) => {
          setShowDetail(true)
          setDetailItem(res.payload.data)
        })
      }
    })
  }

  return (
    <>
      <div className={Styles.reference__container}>
        {filterData.map((item, i) => (
          <div className={Styles.reference} key={i}>
            <h6 className={Styles.reference__title}>{item.id}</h6>
            <div className={Styles.reference__items}>
              {item.name.map((tag, i) => (
                <input key={i} onClick={openDetails} type='button' value={tag} id={tag} className={Styles.input} />
              ))}
            </div>
          </div>
        ))}
      </div>
      {detailItem && detailNutrInfo && (
        <DrawerPanel
          width='100%'
          anchor='right'
          open={showDetail}
          onClose={() => {
            setShowDetail((isOpen) => !isOpen)
          }}
        >
          <div className={Styles.drawer}>
            <div className={Styles.title__container}>
              <button
                className={Styles.button__arrow}
                onClick={() => {
                  setShowDetail(false)
                }}
              />
              <h1 className={Styles.title}>{detailItem.name}</h1>
              <img
                src={closeButton}
                alt='img'
                className={Styles.closeButton}
                onClick={() => {
                  setShowDetail(false)
                }}
              />
            </div>
            <div className={Styles.container__details}>
              <div>
                <h5 className={Styles.key}>Прием</h5>
                <div className={Styles.value}>{`${detailNutrInfo.reception.title}, ${detailNutrInfo.meal.title}`}</div>
              </div>
              <div>
                <h5 className={Styles.key}>Количество и периодичность</h5>
                <div className={Styles.value}>{`${declination(
                  detailNutrInfo.amount,
                  'таблетка',
                  'таблетки',
                  'таблеток',
                )} ${detailNutrInfo.periodicity.title}`}</div>
              </div>
              <div>
                <h5 className={Styles.key}>Курс</h5>
                <div className={Styles.value}>{`${declination(
                  detailNutrInfo.course_duration,
                  'день',
                  'дня',
                  'дней',
                )}`}</div>
              </div>
              {detailNutrInfo.remark && (
                <div>
                  <h5 className={Styles.key}>Заметка</h5>
                  <div className={Styles.value}>{detailNutrInfo.remark}</div>
                </div>
              )}
              <div>
                <h4 className={`${Styles.title} ${Styles.title_mr}`}>Состав</h4>
                <div className={Styles.description}>{Parser(detailItem.description)}</div>
                <h4 className={Styles.title}>Показания</h4>
                <p className={Styles.text}>{detailItem.small_description}</p>
              </div>
            </div>
          </div>
        </DrawerPanel>
      )}
    </>
  )
}
