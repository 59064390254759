import React, { useEffect, useMemo, useRef } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
// import ScrollToTop from 'react-router-scroll-top'
import { Wrapper } from '../Wrapper'
import { PageNotFount } from '../../pages/not-found'
// eslint-disable-next-line import/named
import { AccessDeniedPage } from '../../pages/access-denied'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import Styles from './style.module.scss'
import { ProtectedRoute } from '@components/Router/protected-route'
// eslint-disable-next-line camelcase
import { UPDATE_TOKEN_INTERVAL, USER_ROlES } from '../../global-constants'
import { Spinner } from '@components/Spinner'
import { LandingPage } from '../../pages/landing'
import { Header } from '@components/Header'
import { AuthorizationPage } from '../../pages/authorization'
import { User } from '../../pages/user'
import { SideMenu } from '@components/SideMenu'
import { AppointmentPage } from '../../pages/appointment-page'
import { MobileMenu } from '@components/MobileMenu'
import {
  tokenCheck,
  getUser,
  getMedicalReports,
  getNotifications,
  setRole,
  tokenRefresh,
} from '../../pages/authorization/redux/authSlice'
import { Onboarding } from '@components/Onboarding'
import { DoctorRoutes } from '../Router/DoctorRoutes'
import { MyAppointment } from '../../pages/my-appointment'
import { AppointmentDetails } from '../../pages/my-appointment/components/AppointmentDetails'
import { DrawerMobileById } from '../../pages/my-appointment/components/DrawerMobileById'
import { ConfidentialPage } from '@components/Footer/components/ConfidentialPage'
import { ContactsPage } from '@components/Footer/components/ContactsPage'
import { PayPage } from '@components/Footer/components/PayPage'
import { ReturnPage } from '@components/Footer/components/ReturnPage'
import { PersonalDataPage } from '@components/Footer/components/PersonalDataPage'
import { SuccessPage } from '../../pages/payment/Success'
import { UnsuccessfulPage } from '../../pages/payment/Unsuccessful'
import { CatalogPage } from '../../pages/catalog'
import { CatalogPageNutraceutical } from '../../pages/catalog/components/CatalogPageNutraceutical'
import { CatalogPageResearch } from '../../pages/catalog/components/CatalogPageResearch'
import { Cart } from '../../pages/cart'
import { GoCheckout } from '../../pages/cart/GoCheckout'
import { CreatedOrder } from '../../pages/cart/CreatedOrder'
import { useNotifications } from '@components/Notifications/utils/useNotifications'
import { ProfilePage } from '../../pages/profile'
import { Orders } from '../../pages/orders'
import { OrderDetails } from '../../pages/orders/components/OrderDetails'
import useWindowSize from '@helpers/useWindowSizeHook'
import { Footer } from '@components/Footer'
import { getLastViewed } from '../../pages/catalog/redux/catalogSlicer'
import { ArticlePage } from '../../pages/article'
import { BlogPage } from '../../pages/blog'
// import sctollToTop from '@helpers/scrollToTop'

const App = () => {
  const interval = useRef(null)
  const dispatch = useAppDispatch()
  const { width } = useWindowSize()
  useNotifications()
  const userRole = useAppSelector((state) => state.auth.user.info.role)
  const { isLoggedIn, token } = useAppSelector((state) => state.auth.user)

  const desktop = width > 830

  useEffect(() => {
    if (token.access) {
      dispatch(tokenCheck({ token: token.access }))
        .then(() => dispatch(getUser()))
        .then(() => dispatch(getMedicalReports()))
        .then(() => dispatch(getNotifications()))
        .then(() => dispatch(getLastViewed()))
        .catch(() => {
          dispatch(tokenRefresh({ refresh: token.refresh || '' }))
            .then(() => dispatch(getUser()))
            .then(() => dispatch(getMedicalReports()))
            .then(() => dispatch(getNotifications()))
        })
    } else {
      dispatch(setRole('guest'))
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isLoggedIn) {
      interval.current = setInterval(() => {
        dispatch(tokenRefresh({ refresh: token.refresh }))
      }, UPDATE_TOKEN_INTERVAL)
    }
    return () => {
      clearInterval(interval.current)
    }
  }, [isLoggedIn])

  return useMemo(() => {
    return (
      <Router>
        <div className={Styles.app_container}>
          {userRole ? (
            <>
              <section className={Styles.app_wrapper}>
                <SideMenu />
                <Header />
                <Wrapper>
                  <Switch>
                    {userRole === 'doctor' && <DoctorRoutes />}
                    {userRole === 'patient' && (
                      <ProtectedRoute
                        path='/'
                        exact
                        component={User}
                        allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                      />
                    )}
                    <ProtectedRoute
                      path='/appointment-page'
                      exact
                      component={AppointmentPage}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />
                    <ProtectedRoute
                      path='/my-appointment'
                      exact
                      component={MyAppointment}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />
                    <ProtectedRoute
                      path='/my-appointment/:id'
                      exact
                      component={desktop ? AppointmentDetails : DrawerMobileById}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />
                    <ProtectedRoute
                      path='/cart'
                      exact
                      component={Cart}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />
                    <ProtectedRoute
                      path='/cart/gocheckout'
                      exact
                      component={GoCheckout}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />
                    <ProtectedRoute
                      path='/catalog'
                      exact
                      component={CatalogPage}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />
                    <ProtectedRoute
                      path='/created-order'
                      exact
                      component={CreatedOrder}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />
                    <ProtectedRoute
                      path='/order/:id'
                      exact
                      component={OrderDetails}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />
                    <ProtectedRoute
                      path='/orders'
                      exact
                      component={Orders}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />
                    <ProtectedRoute
                      path='/catalog/nutraceutical/:id'
                      exact
                      component={CatalogPageNutraceutical}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />
                    <ProtectedRoute
                      path='/catalog/research/:id'
                      exact
                      component={CatalogPageResearch}
                      allowedUsersTypes={[USER_ROlES.doctor, USER_ROlES.admin, USER_ROlES.user]}
                    />

                    <Route path='/' exact component={LandingPage} />
                    <Route path='/profile' exact component={ProfilePage} />
                    <Route path='/confidential' exact component={ConfidentialPage} />
                    <Route path='/contacts' exact component={ContactsPage} />
                    <Route path='/pay' exact component={PayPage} />
                    <Route path='/return' exact component={ReturnPage} />
                    <Route path='/personal-data' exact component={PersonalDataPage} />
                    <Route path='/not-found' exact component={PageNotFount} />
                    <Route path='/access-denied' exact component={AccessDeniedPage} />
                    <Route path='/login' exact component={AuthorizationPage} />
                    <Route path='/onboarding' exact component={Onboarding} />
                    <Route path='/cart' exact component={Cart} />
                    <Route path='/cart/gocheckout' exact component={GoCheckout} />
                    <Route path='/success' exact component={SuccessPage} />
                    <Route path='/unsuccessful' exact component={UnsuccessfulPage} />
                    <Route path='/blog' exact component={BlogPage} />
                    <Route path='/blog/:slug' exact component={ArticlePage} />
                    <Route path='*'>
                      <Redirect to='/not-found' />
                    </Route>
                  </Switch>
                </Wrapper>
                <Footer />
                {isLoggedIn && <MobileMenu />}
              </section>
            </>
          ) : (
            <div className={Styles.spinner_container}>
              <Spinner />
            </div>
          )}
        </div>
      </Router>
    )
  }, [userRole])
}

export default App
