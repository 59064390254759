import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { setIsShowDrawer } from '../../../../../../redux/doctorSlicer'
import Styles from './style.module.scss'

import { DoctorItem } from '../DoctorItem'
import { DrawerPanel } from '@components/Drawer'
import { NutraceuticalsDrawer } from '@components/Conclusion/components/NutraceuticalsDrawer'

import closeButton from '@icons/close.svg'

export const DoctorNutraceuticals: React.FC = () => {
  const dispatch = useAppDispatch()
  const nutraceuticalsReports = useAppSelector((state) => state.doctor.nutraceuticalsReports)
  const isShowDrawer = useAppSelector((state) => state.doctor.isShowDrawer)
  const [displayedNutraceuticalsReports, setDisplayedNutraceuticalsReports] = useState([])

  const openDrawer = () => dispatch(setIsShowDrawer(true))
  const closeDrawer = () => dispatch(setIsShowDrawer(false))

  useEffect(() => {
    if (nutraceuticalsReports.length !== 0) {
      const uniqReception = [...new Set(nutraceuticalsReports.map((report) => report.reception.title))]

      const array = uniqReception.map((reception) => {
        const data = nutraceuticalsReports.filter((report) => reception === report.reception.title)

        return { reception, data }
      })

      setDisplayedNutraceuticalsReports(array)
    }
  }, [])

  return (
    <>
      {displayedNutraceuticalsReports.length !== 0 &&
        displayedNutraceuticalsReports.map((report, index) => {
          return (
            <div key={index} className={Styles.block}>
              {/* <span className={Styles.title}>{report.reception}</span> */}
              <ul className={Styles.list}>
                {report.data.length !== 0 &&
                  report.data.map((item) => <DoctorItem key={item.id}>{item.nutraceutical.name}</DoctorItem>)}
              </ul>
              <div className={Styles.wrap}>
                {displayedNutraceuticalsReports.length !== 0 && (
                  <button className={Styles.open} onClick={openDrawer}>
                    {}
                  </button>
                )}
              </div>
            </div>
          )
        })}
      <DrawerPanel width='100%' anchor='right' open={isShowDrawer} onClose={closeDrawer}>
        <div className={Styles.container}>
          <div className={Styles.header}>
            <h1 className={Styles.caption}>Нутрицевтики</h1>
            <button className={Styles.close} onClick={closeDrawer}>
              <img src={closeButton} alt='Закрыть' />
            </button>
          </div>
          <div className={Styles.content}>
            <NutraceuticalsDrawer data={nutraceuticalsReports} />
          </div>
        </div>
      </DrawerPanel>
    </>
  )
}
