import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { getAnalyzes, getComplexAnalyzes, getProducts } from '../../../pages/catalog/redux/catalogSlicer'
import { useEffect } from 'react'

export const useCatalog = () => {
  const dispatch = useAppDispatch()
  const productsPage = useAppSelector((state) => state.catalog.productsPage)
  const analyzesPage = useAppSelector((state) => state.catalog.analyzesPage)
  const productFilter = useAppSelector((state) => state.catalog.productFilter)
  const ordering = useAppSelector((state) => state.catalog.ordering)
  const analyzesFilter = useAppSelector((state) => state.catalog.analyzesFilter)
  const analyzesOrdering = useAppSelector((state) => state.catalog.analyzesOrdering)
  const loggedIn = useAppSelector((state) => state.auth.user.token)

  useEffect(() => {
    dispatch(getProducts({ page: productsPage, ...productFilter, ordering }))
  }, [productsPage, productFilter, ordering])

  useEffect(() => {
    if (loggedIn) {
      dispatch(getAnalyzes({ page: analyzesPage, ...analyzesFilter, analyzesOrdering }))
      dispatch(getComplexAnalyzes())
    }
  }, [analyzesPage, analyzesFilter, analyzesOrdering, loggedIn])

  return useCatalog
}
