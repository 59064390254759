import React from 'react'
import commentIcon from '@icons/comment.svg'
import Styles from './style.module.scss'
import { Rating } from '@mui/material'
import declination from '@helpers/declination'
import { makeAnchorScroll } from '../../../../../src/utils/makeAnchorScroll'

interface CatalogRatingProps {
  rating: number
  comment?: number
  short?: boolean
  withComments?: boolean
}

export const CatalogRating: React.FC<CatalogRatingProps> = (props) => {
  const { rating, comment, short, withComments = true } = props

  const scrollToReviews = () => {
    makeAnchorScroll('#feedback')
  }

  return (
    <div className={Styles.rating}>
      {short ? (
        <>
          <div className={Styles.block}>
            <span className={`${Styles.star} ${Styles.star_active}`}>{}</span>
          </div>
          <span className={Styles.count}>{rating}</span>

          {withComments && (
            <button className={Styles.button} onClick={scrollToReviews}>
              <img src={commentIcon} alt='Иконка' />
              <span>{comment}</span>
            </button>
          )}
        </>
      ) : (
        <>
          {/* <div className={Styles.block}>{renderRating()}</div> */}
          <div className={Styles.block}>
            <Rating name='rating' value={rating} precision={0.1} readOnly />
          </div>
          <span className={Styles.count}>{rating}</span>
          {withComments && (
            <button className={Styles.button} onClick={scrollToReviews}>
              <img src={commentIcon} alt='Иконка' />
              <span>{declination(comment, 'отзыв', 'отзыва', 'отзывов')}</span>
            </button>
          )}
        </>
      )}
    </div>
  )
}
